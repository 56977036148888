import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import '../styles/components/ModuleTypeInfo.scss';

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({})

class ModuleTypeInfo extends React.Component {
  render() {
    const { type, t } = this.props;
    let iconSrc = "/assets/img/modules/type-face-recognition.png";
    let text = "Record the face of the subject and analize it with our artificial inteligence to recognize her emotions";

    if (type === "recognition") {
      iconSrc = "/assets/img/modules/type-face-recognition.png";
      text = t("ModuleTypeInfo_Recognition_Text");
    } else if (type === "choose") {
      iconSrc = "/assets/img/modules/type-choose.png";
      text = t("ModuleTypeInfo_Choose_Text");
    } else if (type === "play") {
      iconSrc = "/assets/img/modules/type-play.png";
      text = t("ModuleTypeInfo_Play_Text");
    }

    return (
      <div className="module-type-info">
        <img
          className="module-type-icon"
          src={iconSrc}
          alt="module technology type icon"
        />
        <p>{text}</p>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(ModuleTypeInfo);
