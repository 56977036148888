import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { Spinner } from 'react-activity';
import { UserAccess, CampaignAccess, FileAccess, SounditiAuth } from '@sounditi/ft2-api';
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next';

import { setSession, setTestsData } from '../../reducers/user';
import { showNotification } from '../../reducers/notifications';
import { setRedirect } from '../../reducers/navigation';

import T from '../../components/Translate';
import Link from '../../components/Link';
import Input from '../../components/Input';

import { URL_WIZARD_LOGIN, URL_WIZARD_FILE } from '../../config/urls';

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth.api,
  tokenData: state.user.tokenData,
  userData: state.user.userData,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setSession: val => dispatch(setSession(val)),
  showNotification: val => dispatch(showNotification(val)),
  setRedirect: val => dispatch(setRedirect(val)),
  setTestsData: val => dispatch(setTestsData(val)),
  dispatch,
})

class WizardStart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    const { userData } = this.props;
    if (userData) {
      const userID = userData.id || undefined;

      if (userID) {
        window.Froged('set', {
          userID,
          hasUsedWizard: true
        });
      }
    }

    document.getElementById('body').className='wizard'
  }

  goWizardLogin() {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_WIZARD_LOGIN });
  }

  goWizardFile() {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_WIZARD_FILE });
  }

  render() {
    const { loading } = this.state;
    const { t } = this.props;

    return (
      <div className="app lite">
        <Helmet>
          <title>{t('Lite_File_Browser_Title')}</title>
        </Helmet>
        {loading && (
          <div className="loading-cover">
            <div className="cover"></div>
            <div>
              <Spinner speed={0.8} color="#00C7E7" size={20} />
            </div>
          </div>
        )}
        <div className="lite-topbar">
          <img className="logo" src="../assets/img/main-logo-c.png" alt="Company logo" />
        </div>
        <div className="screen-description">
          <h2><T text="WizardStart_Welcome" /></h2>
          <p><T text="WizardStart_Description" /></p>
        </div>
        <div className="fluid white">

        </div>
        <div className="fixed-bottom">
          <Link secondary className="full-width margin-bottom" onClick={() => this.goWizardLogin()}>
            <T text="WizardStart_Account_Button" />
          </Link>
          <Link main className="full-width" onClick={() => this.goWizardFile()}>
            <T text="WizardStart_Guest_Button" />
          </Link>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(WizardStart);
