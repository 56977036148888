import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { Spinner } from 'react-activity';
import { CampaignAccess, UserAccess } from '@sounditi/ft2-api';
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next';
import ContentHeader from '../../components/ContentHeader';
import TopBar from '../../components/TopBar';
import MobileTopBar from '../../components/MobileTopBar';
import PublishTest from '../../components/recopilator/PublishTest';
import AdquisitionMethod from '../../components/recopilator/AdquisitionMethod';

import { getURLParameter, removeURLParameter } from '../../utils/global';
import { URL_CAMPAIGNS } from '../../config/urls';
import { showNotification } from '../../reducers/notifications';
import { setRedirect } from '../../reducers/navigation';
import { setTestsData, setUserData } from '../../reducers/user';

import RecopilatorLinks from '../RecopilatorLinks';
import InviteAudience from '../InviteAudience';
import BuyAudience from './BuyAudience';

const mapStateToProps = (state, ownProps) => ({
  userData: state.user.userData,
  testsData: state.user.testsData,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setUserData: val => dispatch(setUserData(val)),
  setTestsData: val => dispatch(setTestsData(val)),
  setRedirect: val => dispatch(setRedirect(val)),
  showNotification: val => dispatch(showNotification(val)),
  dispatch
})

class Recopilator extends React.Component {
  _mount = true;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      campaignData: [],
      changesToApply: false,
      showAdquisitionMethods: false
    };
  }

  componentWillMount() {
    this.loadData();
    // this.refreshTestsData();

    if (getURLParameter('paidSuccess') !== null) {
      if (getURLParameter('sessionId')) {
        const sessionId = getURLParameter('sessionId');
        localStorage.setItem('sessionID-info', sessionId);
      }

      this.callbackFromStripe();
    }
  }

  componentDidMount() {
    if (this.refs.topbar)
      this.refs.topbar.sendEvent("control_enter_screen_recopilator", "Recopilator")
  }

  componentWillUnmount() {
    this._mount = false;
  }

  componentDidUpdate() {

  }

  callbackFromStripe() {
    const { showNotification } = this.props;

    if (getURLParameter('paidSuccess') === 'true') {
      showNotification('paymentSuccesfully');
    } else {
      showNotification('paymentError');
    }

    removeURLParameter("paidSuccess");
    removeURLParameter("sessionId");
  }

  async refreshTestsData() {
    const { setTestsData } = this.props;
    const campaignAccess = new CampaignAccess();
    const refreshedTestsData = await campaignAccess.getCampaigns();
    setTestsData(refreshedTestsData).then(()=> this.loadData());
  }

  async refreshUserData() {
    const { setUserData } = this.props;
    const userAccess = new UserAccess();
    const refreshedUserData = await userAccess.getUserData();
    setUserData(refreshedUserData);
  }

  async loadData() {
    if (this._mount) {
      const { testsData } = this.props;

      const campaignId = getURLParameter("id");
      const campaignData = testsData.filter(test => test.campaignId === campaignId)[0];

      if (!campaignId || !campaignData)
        this.goCampaigns();

      if (campaignData){
        this.setState({
          campaignData,
          changesToApply: false
        });

        this.setState({ loading: false });
      }
    }
  }

  goCampaigns() {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_CAMPAIGNS });
  }

  async chooseAudienceType(type) {
    const { showNotification } = this.props;
    const { campaignData } = this.state;
    try {
      this.setState({ loading: true });
      const campaignAccess = new CampaignAccess();
      await campaignAccess.updateChosenAudienceType(campaignData.campaignId, type);
      await this.refreshTestsData();

      this.setState({ showAdquisitionMethods: false })

      if (this.refs.topbar)
        this.refs.topbar.sendEvent("control_choose_audience_type", "DefineAudience", { campaignId: campaignData.campaignId })
    } catch(error) {
      console.log(error);
      showNotification("genericError");
    }

    this.setState({ loading: false });
  }

  render() {
    const {
      loading,
      campaignData,
      changesToApply,
      showAdquisitionMethods
    } = this.state;
    const { location, t} = this.props;

    const isCampaignPaid = campaignData.status === 'public' || (campaignData.status === 'processing' && campaignData.price !== undefined);
    const campaignName = campaignData.data ? campaignData.data.name : "";
    const chosenAudienceType = campaignData?.chosenAudienceType;

    let wrapperClasses = 'content-wrapper';

    if (!chosenAudienceType || showAdquisitionMethods) {
      wrapperClasses += ' recopilator big';
    } else {
      wrapperClasses += ' recopilator medium';
    }

    // console.log(campaignData)

    return (
      <div className="app screen-campaigns">
        <Helmet>
          <title>{t('Configuration_Browser_Title')}</title>
        </Helmet>
        <div className="main-wrapper">
          <TopBar ref="topbar" location={location} />
          <MobileTopBar
            subtitle={campaignName}
            title={t('ContentHeader_Get_Responses')}
            onClickBackButton={() => window.history.back()}
          />
          <div className='screen-content'>
            <ContentHeader
              location={location}
              campaignData={campaignData}
              changesToApply={changesToApply}
            />

            {/* {!showAdquisitionMethods && !loading && (chosenAudienceType === 'own' || chosenAudienceType === 'email' || chosenAudienceType === 'alyze') &&
              <PublishTest onShowAdquisitionMethod={() => this.setState({ showAdquisitionMethods: true })} />
            } */}

            {/* <div className={wrapperClasses}> */}
              {loading && (
                <div className="loading-cover have-topbar">
                  {/* <div className="cover design"></div> */}
                  <div>
                    <Spinner speed={0.8} color="#00C7E7" size={20} />
                  </div>
                </div>
              )}

              {(!loading && (!chosenAudienceType || showAdquisitionMethods)) && 
                <AdquisitionMethod onChooseAudienceType={(type) => this.chooseAudienceType(type)} />
              }

              {!showAdquisitionMethods && !loading && (chosenAudienceType === 'own' || chosenAudienceType === 'alyze') &&
                <RecopilatorLinks topbar={this.refs.topbar} onShowAdquisitionMethod={() => this.setState({ showAdquisitionMethods: true })} />
              }

              {!showAdquisitionMethods && !loading && chosenAudienceType === 'email' &&
                <InviteAudience topbar={this.refs.topbar} onShowAdquisitionMethod={() => this.setState({ showAdquisitionMethods: true })} />
              }
            {/* </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(Recopilator);
