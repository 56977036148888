import React from 'react';
import T from '../components/Translate';
import ModalDialogInfo from '../components/ModalDialogInfo';
import Gender from '../components/audience/Gender';
import AgeRange from '../components/audience/AgeRange';
import TopLocations from '../components/audience/TopLocations';
import WeekInteractions from '../components/audience/WeekInteractions';
import MonthInteractions from '../components/audience/MonthInteractions';


class GlobalSummary extends React.Component {
  _mount = true;

  constructor(props) {
    super(props);
    this.state = {
      showSummaryModal: false,
      typeModalSummary: '',
    };
  }

  componentWillMount() {

  }

  componentDidMount() {

  }

  componentWillUnmount() {
    this._mount = false;
  }

  showModalSummary(type) {
    this.setState({ showSummaryModal: true });
    this.setState({ typeModalSummary: type });
  }

  closeModalSummary() {
    this.setState({ showSummaryModal: false });
    this.setState({ typeModalSummary: '' });
  }

  render() {
    const {
      showSummaryModal,
      typeModalSummary,
    } = this.state;
    const { t, campaignData, globalSummary } = this.props;

    const checkData = (object, value) => {
      if (object
        && object.end_w_correct_interactions_details
        && object.end_w_correct_interactions_details[value]
        && object.end_w_correct_interactions_details[value] !== '') {
          return object.end_w_correct_interactions_details[value];
        } else {
          return value === 'number_of_testers' ? '0' : '-';
        }
    }

    const checkLocation = (object) => {
      let location = '-';
      let locationCity = '';
      let locationCountry = '';

      if (object && object.end_w_correct_interactions_details ) {
        if (
          object.end_w_correct_interactions_details.predominant_location_city
          && object.end_w_correct_interactions_details.predominant_location_city !== ''
        ) {
          locationCity = object.end_w_correct_interactions_details.predominant_location_city;
        }

        if (
          object.end_w_correct_interactions_details.predominant_location_country
          && object.end_w_correct_interactions_details.predominant_location_country !== ''
        ) {
          locationCountry = object.end_w_correct_interactions_details.predominant_location_country;
          locationCountry = locationCity !== '' ? `(${locationCountry})` : locationCountry;
        }

        if (locationCity !== '' || locationCountry !== '') {
          location = `${locationCity} ${locationCountry}`.trim();
        }
      }
      return location;
    }

    const locationSummary = checkLocation(globalSummary);

    return (
      <div className='global-summary-widget'>
        <h2><T text="Recopilator_GlobalSummary_Title" /></h2>
        <ModalDialogInfo
          name="Gender"
          showModal={showSummaryModal && typeModalSummary == 'gender'}
          closeModal={() => this.closeModalSummary()}
        >
          <Gender
            ref="gender"
            globalSummary={globalSummary}
            activeModule={true}
          />
        </ModalDialogInfo>
        <ModalDialogInfo
          name="AgeRange"
          showModal={showSummaryModal && typeModalSummary == 'ages'}
          closeModal={() => this.closeModalSummary()}
        >
          <AgeRange
            ref="ageRange"
            globalSummary={globalSummary}
            activeModule={true}
          />
        </ModalDialogInfo>
        <ModalDialogInfo
            name="TopLocations"
            showModal={showSummaryModal && typeModalSummary == 'locations'}
            closeModal={() => this.closeModalSummary()}
          >
          <TopLocations
            ref="topLocations"
            globalSummary={globalSummary}
            activeModule={true}
          />
        </ModalDialogInfo>
        <ModalDialogInfo
          name="Responses"
          showModal={showSummaryModal && typeModalSummary == 'responses'}
          closeModal={() => this.closeModalSummary()}
          largeModal={true}
        >
          <div className="responses-obtained-modal">
            <div className="responses-chart">
              <WeekInteractions
              ref="weekInteractions"
              activeModule={true}
            />
            </div>
            <div className="responses-chart">
              <MonthInteractions
              ref="monthInteractions"
              activeModule={true}
            />
            </div>
          </div>
        </ModalDialogInfo>
        <div className={`content-widget summary ${campaignData.features.publicAudienceStatistics ? '' : 'disabled'}`}>
          <div className="summary">
            <div className="col featured" /*onClick={() => this.showModalSummary('responses')}*/>
              <div className="summary-icon"><img src="../../assets/img/icon-responses.png" alt="Responses obtained" /></div>
              <div className="title"><T text="Recopilator_Metrics_Responses" /></div>
              <div className="value">{checkData(globalSummary, 'number_of_testers')}</div>
            </div>
            <div className="col" onClick={() => this.showModalSummary('locations')}>
              <div className="summary-icon"><img src="../../assets/img/icon-location.png" alt="Top location" /></div>
              <div className="title"><T text="Recopilator_Metrics_Location" /></div>
              <div className={`value ${locationSummary !== '-' ? "top-location" : ""}`}>{locationSummary}</div>
            </div>
            <div className="col" onClick={() => this.showModalSummary('gender')}>
              <div className="summary-icon"><img className="top-gender" src="../../assets/img/icon-gender.png" alt="Top gender" /></div>
              <div className="title"><T text="Recopilator_Metrics_Genre" /></div>
              <div className="value">{checkData(globalSummary, 'predominant_gender')}</div>
            </div>
            <div className="col" onClick={() => this.showModalSummary('ages')}>
              <div className="summary-icon"><img src="../../assets/img/icon-age.png" alt="Average age" /></div>
              <div className="title"><T text="Recopilator_Metrics_Age" /></div>
              <div className="value">{checkData(globalSummary, 'predominant_age_track')}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GlobalSummary;
