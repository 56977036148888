import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { Spinner } from 'react-activity';
import { ReactSortable } from "react-sortablejs";
import dragscroll from 'dragscroll';
import { CampaignAccess, BillingAccess } from '@sounditi/ft2-api';
import { Helmet } from 'react-helmet'
import ReactTooltip from "react-tooltip";
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

import { showNotification } from '../../reducers/notifications';
import { setRedirect, showVersionControl, setLoadingAutoSave } from '../../reducers/navigation';
import { setTestsData, setTemporalTestsData } from '../../reducers/user';

import T from '../../components/Translate';
import TopBar from '../../components/TopBar';
import ContentHeader from '../../components/ContentHeader';
import AddModulePanel from '../../components/panels/AddModulePanel';
import TextModulePanel from '../../components/panels/TextModulePanel';
import VideoModulePanel from '../../components/panels/VideoModulePanel';
import FilmModulePanel from '../../components/panels/FilmModulePanel';
import AudioModulePanel from '../../components/panels/AudioModulePanel';
import ImageModulePanel from '../../components/panels/ImageModulePanel';
import QAModulePanel from '../../components/panels/QAModulePanel';
import DesignMenu from '../../components/DesignMenu';
import DurationBar from '../../components/DurationBar';
import ModalDialog from '../../components/ModalDialog';
import VersionControl from '../../components/VersionControl';

import { getURLParameter, getCampaignDuration, generateModuleID } from '../../utils/global';
import { URL_PRICING } from '../../config/urls';

import '../../styles/components/ModulePanels.scss';
import { isArguments } from 'lodash';

const mapStateToProps = (state, ownProps) => ({
  userData: state.user.userData,
  testsData: state.user.testsData,
  temporalTestsData: state.user.temporalTestsData,
  openVersionControl: state.navigation.openVersionControl,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setTestsData: val => dispatch(setTestsData(val)),
  setRedirect: val => dispatch(setRedirect(val)),
  showVersionControl: val => dispatch(showVersionControl(val)),
  showNotification: val => dispatch(showNotification(val)),
  setTemporalTestsData: val => dispatch(setTemporalTestsData(val)),
  setLoadingAutoSave: val => dispatch(setLoadingAutoSave(val)),
  dispatch
})

class Modules extends React.Component {
  _mount = true;
  _defaultState = {
    rendered: false,
    loading: true,
    dataValidate: true,
    campaignData: [],
    campaignDataSaved: [],
    changesToApply: false,
    minFlowSize: 0,
    showAddModulePanel: false,
    showTextModulePanel: false,
    showVideoModulePanel: false,
    showFilmModulePanel: false,
    showAudioModulePanel: false,
    showImageModulePanel: false,
    showQAModulePanel: false,
    activeModule: {},
    campaignModules: [],
    coreModulesPre: [],
    coreModulesPost: [],
    deletedModules: [],
    showAddCard: true,
    showEmptyCard: true,
    cardsEmpty: false,
    dragginModule: false,
    mountedPanels: false,
    showUnpublishModal: false,
    saving: false,
    haveModulesInvalid: false,
    showModalPreview: false,
    loadingModalPreview: false,
    linkPreview: '',
    showConfirmSaveModalFromPreview: false,
    showModalMaxDuration: false,
    showModalDurationFilm: false,
    isFilmModuleLoading: false,
    testDurationUnlimited: false,
    testPrice: 0
  }
  _pricePerMinute = 100; // Cents
  _qaEstimatedDuration = 6;

  constructor(props) {
    super(props);
    this.state = this._defaultState;
    this.scrollbarRef = React.createRef();
  }

  componentWillMount() {
    this.loadData();
  }

  componentDidMount() {
    this.refreshCanvasSize();
    // this.spacebarDraggable();

    if (this.refs.topbar)
      this.refs.topbar.sendEvent("control_enter_screen_modules", "Modules")
  }

  componentWillUnmount() {
    this._mount = false;
  }

  async refreshTestsData(updatedDate=false) {
    const { setTestsData, setTemporalTestsData } = this.props;
    const campaignId = getURLParameter("id");
    const campaignAccess = new CampaignAccess();
    const refreshedTestsData = await campaignAccess.getCampaigns();

    const refreshedCurrentTest = Object.assign({}, refreshedTestsData.find(test => test.campaignId === campaignId));
    const refreshedTemporalTestsData = this.props.temporalTestsData.map(test => {
      let testCopy = Object.assign({}, test);
      if (test.campaignId === campaignId) {
        testCopy = refreshedCurrentTest;
        testCopy.data = updatedDate ? { ...test.data, updatedAt: updatedDate } : test.data;
      }
      return testCopy;
    });

    setTemporalTestsData(refreshedTemporalTestsData);
    setTestsData(refreshedTestsData);
  }

  componentDidUpdate(prevProps, prevState) {

  }

  refreshCanvasSize(noCenter) {
    const minFlowSize = document.querySelector('.screen-cards').offsetWidth;
    this.setState({ minFlowSize }, () => {
      if (!noCenter)
        this.centerScrollbar()
    });
  }

  spacebarDraggable() {
    document.addEventListener('keypress', event => {
      if (event.code === 'Space') {
        if (this._mount) {
          if (this.scrollbarRef.current && this.scrollbarRef.current.classList) {
            if (!this.scrollbarRef.current.classList.contains('dragscroll')) {
              this.scrollbarRef.current.classList.add('dragscroll');
              dragscroll.reset();
            }
          }
          if (!document.querySelector('.flow-canvas').classList.contains('grabbable')) {
            document.querySelector('.flow-canvas').classList.add('grabbable');
          }
        }
      }
    });

    document.addEventListener('keyup', event => {
      if (event.code === 'Space') {
        if (this._mount) {
          if (this.scrollbarRef.current && this.scrollbarRef.current.classList) {
            if (this.scrollbarRef.current.classList.contains('dragscroll')) {
              this.scrollbarRef.current.classList.remove('dragscroll');
              dragscroll.reset();
            }
          }
          if (document.querySelector('.flow-canvas').classList.contains('grabbable')) {
            document.querySelector('.flow-canvas').classList.remove('grabbable');
          }
        }
      }
    });
  }

  centerScrollbar() {
    /*
    const scrollView = document.querySelector('#scrollview');
    const scrollViewWidth = scrollView.scrollWidth;
    const scrollContentWidth = document.querySelector('.flow-canvas').scrollWidth;
    const scrollToCenter = (scrollContentWidth - scrollViewWidth) / 2;

    this.scrollbarRef.current.scrollLeft = scrollToCenter;
    */
  }

  isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  async loadData() {
    if (this._mount) {
      const { testsData, temporalTestsData } = this.props;
      let { activeModule } = this.state;

      this.setState({ loading: true });

      const campaignId = getURLParameter("id");
      const campaignDataSaved = testsData.filter(test => test.campaignId === campaignId)[0];
      const campaignData = temporalTestsData.find(test => test.campaignId === campaignId);

      // console.log(campaignData)

      if (!campaignId || !campaignData || !campaignData.data)
        this.goCampaigns();

      const { coreModulesPre } = campaignData?.data?.modules;
      let campaignModules = [...campaignData.data.modules.campaignModules, { type: "empty" }];
      const coreModulesPost = [{ type: "empty" }, ...campaignData.data.modules.coreModulesPost];

      campaignModules = campaignModules.map(moduleData => {
        if (moduleData.id === activeModule.id) {
          activeModule = moduleData;
        }

        return moduleData;
      });

      this.setState({ campaignData, campaignDataSaved });

      const testDurationUnlimited = campaignData?.features?.testDuration === -1 ? true : false;

      setTimeout(() =>
        this.setState({
          campaignData,
          campaignDataSaved,
          activeModule,
          coreModulesPre,
          coreModulesPost,
          campaignModules,
          loading: false,
          mountedPanels: true,
          haveModulesInvalid: this.checkEmptyModules(campaignModules) > 0,
          testDurationUnlimited,
        }, () => {
          this.getPrice();
          // this.refreshModuleOptions();
          this.checkCampaignModulesEmpty();
          this.refreshCanvasSize();

          if (this._mount) {
            const { activeModule } = this.state;

            if (activeModule.type === "qa" && this.refs.qaModulePanel)
              this.refs.qaModulePanel.loadData(activeModule);

            if (activeModule.type === "video" && this.refs.videoModulePanel)
              this.refs.videoModulePanel.loadData(activeModule);

            if (activeModule.type === "film" && this.refs.filmModulePanel)
              this.refs.filmModulePanel.loadData(activeModule);

            if (activeModule.type === "audio" && this.refs.audioModulePanel)
              this.refs.audioModulePanel.loadData(activeModule);

            if (activeModule.type === "image" && this.refs.imageModulePanel)
              this.refs.imageModulePanel.loadData(activeModule);

            this.setState({ changesToApply: false });
            this.refreshTestsData();

            setTimeout(() => {
              this.setState({ rendered: true });
            }, 500);
          }
        })
      , 200);
    }
  }

  async modifyCampaignAndPreview(url) {
    const { changesToApply, linkPreview, campaignData } = this.state;
    const { temporalTestsData, setLoadingAutoSave } = this.props;

    try {
      if (changesToApply) {
        if (campaignData.enrolledTesters < 1) {
          this.setState({ loadingModalPreview: true });
          setLoadingAutoSave(true);

          try {
            const temporalTest = temporalTestsData.find(test => test.campaignId === campaignData?.campaignId);
            const newData = { ...temporalTest.data, updatedAt: Date.now() };
            const campaignAccess = new CampaignAccess();
            const response = await campaignAccess.updateCampaign(campaignData.campaignId, newData);
            await this.refreshTestsData(response.data.updatedAt);

            this.setState({ loadingModalPreview: false,  showModalPreview: false });
            setLoadingAutoSave(false);
          } catch (error) {
            this.setState({ loadingModalPreview: false,  showModalPreview: false });
            setLoadingAutoSave(false);
          }
        } else {
          this.setState({ showModalPreview: false, showConfirmSaveModalFromPreview: true });
          return;
        }
      }
      const win = window.open(url || linkPreview, '_blank');
      win.focus();
    } catch (error) {}
  }

  goPreview(url) {
    const { changesToApply } = this.state;

    if (changesToApply) {
      this.setState({ showModalPreview: true, linkPreview: url });
    } else {
      this.modifyCampaignAndPreview(url);
    }
  }

  async modifyTestDataTemporal() {
    const { setTemporalTestsData, temporalTestsData } = this.props;
    const { coreModulesPre, campaignData } = this.state;
    const { campaignId, data } = campaignData;
    const campaignModules = this.state.campaignModules.filter(item => item.type !== "empty");
    const coreModulesPost = this.state.coreModulesPost.filter(item => item.type !== "empty");

    const modules = {
      ...data.modules,
      coreModulesPre,
      coreModulesPost,
      campaignModules
    };

    const newData = { ...data, modules };

    const temporalTestsDataUpdated = temporalTestsData.map(test => {
      const testCopy = Object.assign({}, test);
      if (test.campaignId === campaignId) {
        testCopy.data = newData;
      }
      return testCopy;
    });

    setTemporalTestsData(temporalTestsDataUpdated);
  }

  /*
  async modifyCampaign(callback) {
    const { showNotification, setTestsData, dispatch, testsData, userData } = this.props;
    const { coreModulesPre, campaignData, testDurationUnlimited, showModalMaxDuration } = this.state;
    const { campaignId, data } = campaignData;
    const campaignModules = this.state.campaignModules.filter(item => item.type !== "empty");
    const coreModulesPost = this.state.coreModulesPost.filter(item => item.type !== "empty");

    const modules = {
      ...data.modules,
      coreModulesPre,
      coreModulesPost,
      campaignModules
    };

    const newData = { ...data, modules };

    const newCampaignData = {
      ...campaignData,
      data: newData
    };

    //const planLimitSeconds = userData.subscription.test_duration * 60;
    const planLimitSeconds = campaignData.features.testDuration;
    const testDurationSeconds = Math.round(getCampaignDuration(newCampaignData, false));
    //const isFilm = (campaignData.data && campaignData.data.type === "film") ? true : false;
    const isFilm = campaignData.features.filmModule;

    if (!testDurationUnlimited && testDurationSeconds > planLimitSeconds) {
      this.setState({ showModalMaxDuration: true });
      return false;
    }

    if (this.checkEmptyModules(campaignModules) > 0) {
      this.setState({ haveModulesInvalid: true });
      showNotification("emptyModules");
      return false;
    }

    // if (isFilm) {
    //   const filmModule = newData.modules.campaignModules.find(module => module.type === 'film');
    //   if (filmModule && filmModule.videos[0].duration < 180) {
    //     this.setState({ showModalDurationFilm: true });
    //     return false;
    //   }
    // }

    try {
      this.setState({ loading: true });

      const campaignAccess = new CampaignAccess();

      if (newCampaignData.globalSummary?.start_details?.number_of_testers > 0) {
        const oldCampaignData = await campaignAccess.getCampaignbyID(campaignId);
        const oldCampaignModules = oldCampaignData.data.modules.campaignModules;

        this.updateIdModulePublishedCampaign(oldCampaignModules, newData.modules.campaignModules);
      }

      await campaignAccess.updateCampaign(campaignId, newData);

      const updatedTestsData = testsData.map(test => {
        if (test.campaignId === campaignId) {
          test.data = newData;
        }

        return test;
      });

      dispatch(setTestsData(updatedTestsData)).then(()=>{
        this.loadData();

        if (callback)
          callback();
      })
    } catch (error) {
      this.setState({ loading: false });
      showNotification("genericError");
    }
  }
  */

  updateIdModulePublishedCampaign(oldCampaignModules, newCampaignModules) {
    newCampaignModules.map( newModule => {
      let stimuli = '';
      switch (newModule.type) {
        case 'image':
          stimuli = 'images';
          break;
        case 'video':
          stimuli = 'videos';
          break;
        case 'film':
          stimuli = 'videos';
          break;
        case 'audio':
          stimuli = 'audios';
          break;
        case 'qa':
          stimuli = 'questions';
          break;
        default:
          break;
      }

      const oldModule = oldCampaignModules.find(old => old.id === newModule.id);

      if (oldModule && (newModule.id === oldModule.id)) {
        const areEqualModules = this.areEqualModules(newModule[stimuli], oldModule[stimuli], newModule.type);

        if (!areEqualModules) {
          const { activeModule } = this.state;
          const oldModuleId = newModule.id;

          newModule.id = generateModuleID();

          if (activeModule.id === oldModuleId) {
            activeModule.id = newModule.id;
            this.setState({ activeModule });
          }
        } else {
          // Check the answers
          if (newModule.type === 'qa') {
            newModule[stimuli].some(newQuestion => {
              const oldQuestion = oldModule[stimuli].find(oldQuestion => oldQuestion.name === newQuestion.name);
              if (oldQuestion && (newQuestion.name === oldQuestion.name)) {
                const areEqualModulesQA = this.areEqualModules(newQuestion.answers, oldQuestion.answers, newModule.type);

                if (!areEqualModulesQA) {
                  const { activeModule } = this.state;
                  const oldModuleId = newModule.id;

                  newModule.id = generateModuleID();

                  if (activeModule.id === oldModuleId) {
                    activeModule.id = newModule.id;
                    this.setState({ activeModule });
                  }
                  return true;
                }
              }
            })
          }
        }
      }
    });
  }

  getCampaignDurationSeconds(campaignModulesRaw) {
    let duration = 0;

    const campaignModules = campaignModulesRaw.filter(module => {
      // Filter empty modules
      if (
        (module.type === "film" && module.videos.length > 0)
        || (module.type === "video" && module.videos.length > 0)
        || (module.type === "audio" && module.audios.length > 0)
        || (module.type === "image" && module.images.length > 0)
        || (
            module.type === "qa" 
            && module.questions[0].name !== "" 
            && module.questions[0].answers.length > 0
            && module.questions[0].answers.filter(answers => answers.name !== "").length > 0
          ) 
      )
        return module
    });

    campaignModules.map(module => {
      let medias = []

      if (module.type === "film") {
          medias = module.videos;
      } else if (module.type === "image") {
          medias = module.images
      } else if (module.type === "video") {
          medias = module.videos;
      } else if (module.type === "audio") {
          medias = module.audios
      } else if (module.type === "qa") {
          medias = module.questions
      }

      medias.map(media => {
        if (module.type === "image") {
            if (module.tech === "choose") {
                duration += this._qaEstimatedDuration;
            } else {
                duration += +module.duration
            }
        } else if (module.type === "video" || module.type === "audio" || module.type === 'film') {
            duration += +media.duration;
        } else if (module.type === 'qa') {
            duration += this._qaEstimatedDuration;
        }
      })
    })

    return duration;
  }

  async getPrice() {
    const { campaignData, campaignModules } = this.state;

    if (campaignData?.campaignId) {
      //   const billingAccess = new BillingAccess();
      //   const requestTestersPrice = await billingAccess.requestTestersPrice(
      //     {
      //       "campaignId": campaignData.campaignId,
      //       "paidAudienceSegments": [
      //         {
      //           "numberOfPeople": 100,
      //           "country": "all",
      //           "toAge": 99,
      //           "gender": "all",
      //           "city": "all",
      //           "fromAge": 18,
      //           "segmentName": "New segment"
      //         }
      //       ]
      //     }
      //   )

      // console.log(requestTestersPrice)

      // const testPrice = requestTestersPrice.pricePerTester / 100;

      const pricePerSecond = this._pricePerMinute / 60
      const campaignDurationSeconds = this.getCampaignDurationSeconds(campaignModules)
      const price = (pricePerSecond * campaignDurationSeconds) / 100;
      const testPrice = Math.max(price, 1.00).toFixed(2)

      this.setState({ testPrice })
    }
  }

  areEqualModules(newModule, oldModule, type) {
    const oldModuleIDs = oldModule.map(stimuli => {
      if (type === 'qa') {
        return stimuli.name;
      } else {
        return stimuli.fileName;
      }
    });

    const newModuleIDs = newModule.map(stimuli => {
      if (type === 'qa') {
        return stimuli.name;
      } else {
        return stimuli.fileName;
      }
    });

    if (oldModuleIDs.length !== newModuleIDs.length) {
      return false;
    }

    if (type === 'qa') {
      let result = oldModuleIDs.every(function (element) {
        return newModuleIDs.includes(element);
      });
      return result;
    } else {
      if (JSON.stringify(oldModuleIDs.sort()) !== JSON.stringify(newModuleIDs.sort())) {
        return false;
      }
    }

    return true;
  }

  hideAllPanels() {
    const { isFilmModuleLoading } = this.state;
    this.setState({
      showTextModulePanel: false,
      showVideoModulePanel: false,
      showAudioModulePanel: false,
      showImageModulePanel: false,
      showQAModulePanel: false,
      showAddModulePanel: false,
      activeModule: {}
    });

    if (!isFilmModuleLoading) {
      this.setState({
        showFilmModulePanel: false
      });
    }

    if (this.refs.videoModulePanel)
      this.refs.videoModulePanel.stop();

    if (this.refs.audioModulePanel)
      this.refs.audioModulePanel.stop();

    /*
    if (!this.scrollbarRef.current.classList.contains('dragscroll')) {
      this.setState({
        showTextModulePanel: false,
        showVideoModulePanel: false,
        showAudioModulePanel: false,
        showImageModulePanel: false,
        showQAModulePanel: false,
        showAddModulePanel: false,
        activeModule: {}
      });

      if (this.refs.videoModulePanel)
        this.refs.videoModulePanel.stop();

      if (this.refs.audioModulePanel)
        this.refs.audioModulePanel.stop();
    }
    */
  }

  checkEmptyModules(campaignData) {
    let counter = 0;
    let campaignModules;

    if (campaignData.data) {
      campaignModules = campaignData.data.modules.campaignModules;
    } else {
      campaignModules = campaignData;
    }

    campaignModules.map(campaignModule => {
      switch (campaignModule.type) {
        case 'image':
          if (campaignModule.images.length <= 0) {
            counter++
          }
          break;
        case 'video':
          if (campaignModule.videos.length <= 0) {
            counter++
          }
          break;
        case 'film':
          if (campaignModule.videos.length <= 0 || campaignModule.filmName === '') {
            counter++
          }
          break;
        case 'audio':
          if (campaignModule.audios.length <= 0) {
            counter++
          }
          break;
        case 'qa':
          campaignModule.questions.map(question => {
            if (!question.name || question.name === '') {
              counter++
            }

            if (question.answers) {
              if (question.answers.length <= 0) {
                counter++
              } else {
                question.answers.map(answer => {
                  if (!answer.name || answer.name === '') {
                    counter++;
                  }
                })
              }
            }
          })
          break;
        default:
          break;
      }
    })
    return counter;
  }

  checkEmptyModule(campaignModule) {
    switch (campaignModule.type) {
      case 'image':
        if (campaignModule.images.length <= 0) {
          return true;
        }
        break;
      case 'video':
        if (campaignModule.videos.length <= 0) {
          return true;
        }
        break;
      case 'film':
        if (campaignModule.videos.length <= 0 || campaignModule.filmName === '') {
          return true;
        }
        break;
      case 'audio':
        if (campaignModule.audios.length <= 0) {
          return true;
        }
        break;
      case 'qa':
        if (campaignModule.questions.length <= 0) {
          return true;
        } else {
          let counter = 0;
          campaignModule.questions.map(question => {
            if (!question.name || question.name === '') {
              counter++;
            }

            if (question.answers.length <= 0) {
              counter++
            } else {
              question.answers.map(answer => {
                if (!answer.name || answer.name === '') {
                  counter++;
                }
              });
            }
          })
          if (counter > 0) {
            return true;
          }
        }
        break;
      default:
        break;
    }
    return false;
  }

  openModuleOptions(campaignModule, event) {
    // console.log("openModuleOptions")
    // console.log(campaignModule)
    const { activeModule, haveModulesInvalid } = this.state;

    event.stopPropagation();
    event.preventDefault();

    if (activeModule && activeModule.id === campaignModule.id) {
      this.hideAllPanels();
      return false;
    }

    if (campaignModule.type === "text" || campaignModule.type === "landing") {
      this.hideAllPanels();

      //setTimeout(() => {
        this.setState({ showTextModulePanel: true, activeModule: campaignModule });
        this.refs.textModulePanel.show(campaignModule);
      //}, 200);
    } else if (campaignModule.type === "film") {
      this.hideAllPanels();

      //setTimeout(() => {
        this.setState({ showFilmModulePanel: true, activeModule: campaignModule });
        this.refs.filmModulePanel.show(campaignModule);

        if (haveModulesInvalid && this.checkEmptyModule(campaignModule)) {
          if (campaignModule.filmName === '') {
            // this.refs.filmModulePanel.setFilmNameInvalid();
          }
          if (campaignModule.videos.length <= 0) {
            // this.refs.filmModulePanel.setVideoInvalid();
          }
        }
      //}, 200);
    } else if (campaignModule.type === "video") {
      this.hideAllPanels();

      //setTimeout(() => {
        this.setState({ showVideoModulePanel: true, activeModule: campaignModule });
        this.refs.videoModulePanel.show(campaignModule);
        if (haveModulesInvalid && this.checkEmptyModule(campaignModule)) {
          // this.refs.videoModulePanel.setVideoInvalid();
        }
      //}, 200);
    } else if (campaignModule.type === "audio") {
      this.hideAllPanels();

      //setTimeout(() => {
        this.setState({ showAudioModulePanel: true, activeModule: campaignModule });
        this.refs.audioModulePanel.show(campaignModule);
        if (haveModulesInvalid && this.checkEmptyModule(campaignModule)) {
          // this.refs.audioModulePanel.setAudioInvalid();
        }
      //}, 200);
    } else if (campaignModule.type === "image") {
      this.hideAllPanels();

      //setTimeout(() => {
        this.setState({ showImageModulePanel: true, activeModule: campaignModule });
        this.refs.imageModulePanel.show(campaignModule);
        if (haveModulesInvalid && this.checkEmptyModule(campaignModule)) {
          // this.refs.imageModulePanel.setImageInvalid();
        }
      //}, 200);
    } else if (campaignModule.type === "qa") {
      this.hideAllPanels();

      //setTimeout(() => {
        this.setState({ showQAModulePanel: true, activeModule: campaignModule });
        this.refs.qaModulePanel.show(campaignModule);
        if (haveModulesInvalid && this.checkEmptyModule(campaignModule)) {
          campaignModule.questions.map((question, i) => {
            if (!question.name || question.name === '') {
              // this.refs.qaModulePanel.setQuestionInvalid(i);
            }
            if (question.answers.length <= 0) {
              // this.refs.qaModulePanel.setAnswerInvalid(i);
            } else {
              let counter = 0;
              question.answers.map(answer => {
                if (!answer.name || answer.name === '') {
                  counter++;
                }
              });

              if (counter > 0) {
                // this.refs.qaModulePanel.setAnswerInvalid(i);
              }
            }
          });
        }
      //}, 200);
    }
  }

  refreshModuleOptions() {
    const allModules = [
      ...this.state.coreModulesPre,
      ...this.state.coreModulesPost,
      ...this.state.campaignModules
    ];

    if (this.state.activeModule.id) {
      // If some module its in editing mode
      // We need to refresh the activeModule state.
      const activeModule = allModules.filter(
        itemModule => itemModule.id === this.state.activeModule.id
      )[0];
      this.setState({ activeModule }, () =>
        this.refs.textModulePanel.show(activeModule)
      );
    }
  }

  showAddModulePanel(event) {
    event.stopPropagation();
    event.preventDefault();
    this.hideAllPanels();
    this.setState({ showAddModulePanel: true })
  }

  onOptionsChange(val) {
    const { activeModule, rendered } = this.state;

    if (rendered) {
      const coreModulesPre = this.state.coreModulesPre.map((coreModule, key) => {
        if (activeModule.id === coreModule.id) {
          return { ...coreModule, ...val };
        }
        return coreModule
      });

      const coreModulesPost = this.state.coreModulesPost.map((coreModule, key) => {
        if (activeModule.id === coreModule.id) {
          return { ...coreModule, ...val };
        }
        return coreModule
      });

      const campaignModules = this.state.campaignModules.map((campaignModule, key) => {
        if (activeModule.id === campaignModule.id) {
          return { ...campaignModule, ...val };
        }
        return campaignModule
      });

      this.setState({ coreModulesPre, coreModulesPost, campaignModules, changesToApply: true }, () => {
        this.getPrice();
        this.modifyTestDataTemporal();
      });
    }
  }

  goCampaigns() {
    const { setRedirect } = this.props;
    const link = `/tests/`;
    setRedirect({ route: link });
  }

  checkCampaignModulesEmpty() {
    const { campaignModules, cardsEmpty } = this.state;

    if (cardsEmpty !== (campaignModules.length < 2))
      this.setState({ cardsEmpty: campaignModules.length < 2 });
  }

  checkCampaignModulesEmptyOnChange() {
    const { campaignModules, cardsEmpty } = this.state;

    if (cardsEmpty !== (campaignModules.length < 3))
      this.setState({ cardsEmpty: campaignModules.length < 3 });
  }

  onTabChange() {
    this.setState(this._defaultState);
    // this.hideAllPanels();
  }

  addModule(newModule) {
    const { campaignModules } = this.state;

    campaignModules.push(newModule);
    this.refs.modulesSortable.props.setList(campaignModules);
  }

  goPreviewAndPublish() {
    const { setRedirect } = this.props;
    const campaignId = getURLParameter("id");
    const link = `/tests/preview-and-publish?id=${campaignId}`;
    setRedirect({ route: link });
  }

  goDesign() {
    const { setRedirect } = this.props;
    const campaignId = getURLParameter("id");
    const link = `/tests/design?id=${campaignId}`;
    setRedirect({ route: link });
  }

  goAudience() {
    const { setRedirect } = this.props;
    const campaignId = getURLParameter("id");
    const link = `/tests/audience?id=${campaignId}`;
    setRedirect({ route: link });
  }

  prevStep() {
    this.modifyTestDataTemporal(() => this.goDesign());
  }

  nextStep() {
    this.modifyTestDataTemporal(() => this.goAudience());
  }

  callbackConfirmSaveModal = () => {
    this.setState({ showConfirmSaveModalFromPreview: false });
 }

  changeCampaignVersionCallback = (data) => {
    const { showVersionControl } = this.props;

    showVersionControl(false);
    this.hideAllPanels();

    this.setState({
      campaignModules: data.modules.campaignModules, 
      changesToApply: true 
    });
  }

  render() {
    const {
      loading,
      campaignData,
      campaignDataSaved,
      changesToApply,
      minFlowSize,
      campaignModules,
      coreModulesPre,
      coreModulesPost,
      showAddModulePanel,
      showTextModulePanel,
      showVideoModulePanel,
      showFilmModulePanel,
      showAudioModulePanel,
      showImageModulePanel,
      showQAModulePanel,
      activeModule,
      deletedModules,
      showAddCard,
      dragginModule,
      mountedPanels,
      cardsEmpty,
      showEmptyCard,
      showUnpublishModal,
      userData,
      haveModulesInvalid,
      showModalPreview,
      loadingModalPreview,
      showConfirmSaveModalFromPreview,
      showModalMaxDuration,
      showModalDurationFilm,
      testDurationUnlimited,
      testPrice
    } = this.state;
    const { location, showNotification, t, openVersionControl } = this.props;

    const isCampaignPaid = campaignData?.status === 'public' || (campaignData?.status === 'processing' && campaignData?.price !== undefined);
    const moduleFilmsPermitedNumber = campaignData?.features?.numberOfFilmModules || 1;
    const numberOfModules = campaignData?.features?.numberOfModules || -1;
    const numberOfFilmModules = campaignData?.features?.numberOfFilmModules || 1;
    const numberOfAudioModules = campaignData?.features?.numberOfAudioModules || -1;
    const numberOfImageModules = campaignData?.features?.numberOfImageModules || -1;
    const numberOfQAModules = campaignData?.features?.numberOfQAModules || -1;
    const numberOfVideoModules = campaignData?.features?.numberOfVideoModules || -1;
    const testModules = campaignModules.filter(item => item.type !== "empty");
    const campaignModulesFiltered = testModules.filter(module => {
      // Filter empty modules
      if (
        (module.type === "film" && module.videos.length > 0)
        || (module.type === "video" && module.videos.length > 0)
        || (module.type === "audio" && module.audios.length > 0)
        || (module.type === "image" && module.images.length > 0)
        || (
            module.type === "qa" 
            && module.questions[0].name !== "" 
            && module.questions[0].answers.length > 0
            && module.questions[0].answers.filter(answers => answers.name !== "").length > 0
          ) 
      )
        return module
    });

    const maxModulesFeatures = {
      maxModulesReached: testModules?.length >= numberOfModules && numberOfModules !== -1,
      maxFilmModulesReached: testModules?.filter(module => module.type === 'film').length >= numberOfFilmModules && numberOfFilmModules !== -1,
      maxAudioModulesReached: testModules?.filter(module => module.type === 'audio').length >= numberOfAudioModules && numberOfAudioModules !== -1,
      maxVideoModulesReached: testModules?.filter(module => module.type === 'video').length >= numberOfVideoModules && numberOfVideoModules !== -1,
      maxImageModulesReached: testModules?.filter(module => module.type === 'image').length >= numberOfImageModules && numberOfImageModules !== -1,
      maxQaModulesReached: testModules?.filter(module => module.type === 'qa').length >= numberOfQAModules && numberOfQAModules !== -1,
    };

    const textSaveButton = changesToApply ? t('CampaignsConfiguration_Save_Changes_Button') : t('CampaignsConfiguration_Saved_Button');
    const testIsActive = campaignData.status === 'public' || (campaignData.status === 'processing' && campaignData.price !== undefined);

    let classScreenContentInner = "screen-content-inner";

    if (showEmptyCard)
      classScreenContentInner = `${classScreenContentInner} show-empty-card`;

    if (showAddCard)
      classScreenContentInner = `${classScreenContentInner} show-add-card`;

    if (cardsEmpty)
      classScreenContentInner = `${classScreenContentInner} cards-empty`;

    if (dragginModule)
      classScreenContentInner = `${classScreenContentInner} dragging-module`;

    if (showAddModulePanel)
      classScreenContentInner = `${classScreenContentInner} panel-open-add-screen`;

    if (showTextModulePanel)
      classScreenContentInner = `${classScreenContentInner} panel-open-text-screen show-right-panel`;

    if (showFilmModulePanel)
      classScreenContentInner = `${classScreenContentInner} panel-open-film-screen show-right-panel`;

    if (showVideoModulePanel)
      classScreenContentInner = `${classScreenContentInner} panel-open-video-screen show-right-panel`;

    if (showAudioModulePanel)
      classScreenContentInner = `${classScreenContentInner} panel-open-audio-screen show-right-panel`;

    if (showImageModulePanel)
      classScreenContentInner = `${classScreenContentInner} panel-open-image-screen show-right-panel`;

    if (showQAModulePanel)
      classScreenContentInner = `${classScreenContentInner} panel-open-qa-screen show-right-panel`;

    let classVersionControl = "version-panel";
    if (openVersionControl)
      classVersionControl = `${classVersionControl} version-panel-open`;

    const renderCampaignModules = campaignModules.map((campaignModule, key) => {
      let classModule = `screen-card have-options ${campaignModule.type}`;
      let showEmptyTag = false;

      if (haveModulesInvalid) {
        switch (campaignModule.type) {
          case 'image':
            if (campaignModule.images.length <= 0) {
              classModule = `${classModule} have-errors`;
              showEmptyTag = true;
            }
            break;
          case 'video':
            if (campaignModule.videos.length <= 0) {
              classModule = `${classModule} have-errors`;
              showEmptyTag = true;
            }
            break;
          case 'film':
            if (campaignModule.videos.length <= 0) {
              classModule = `${classModule} have-errors`;
              showEmptyTag = true;
            }
            break;
          case 'audio':
            if (campaignModule.audios.length <= 0) {
              classModule = `${classModule} have-errors`;
              showEmptyTag = true;
            }
            break;
          case 'qa':
            campaignModule.questions.map(v => {
              if (!v.name || v.name === '' || v.answers.length <= 0) {
                classModule = `${classModule} have-errors`;
                showEmptyTag = true;
              } else {
                let counter = 0;
                v.answers.map(answer => {
                  if (!answer.name || answer.name === '') {
                    counter++;
                  }
                });

                if (counter > 0) {
                  classModule = `${classModule} have-errors`;
                  showEmptyTag = true;
                }
              }
            })
            break;
          default:
            break;
        }
      }

      if (
        activeModule &&
        activeModule.id &&
        campaignModule &&
        campaignModule.id &&
        activeModule.id === campaignModule.id
      )
        classModule = `${classModule} active`;

      if (activeModule && activeModule.id)
        classModule = `${classModule} unsortable`;

      if (campaignModule && campaignModule.type === "film" && testIsActive)
        classModule = `${classModule} unsortable core`;

      if (isCampaignPaid) {
        classModule = `${classModule} unsortable`;
      }

      if (campaignModule && campaignModule.type === "empty") {
        return (
          <li
            key="empty"
            className="screen-card placeholder unsortable empty"
            onClick={e => this.showAddModulePanel(e)}
          >
          </li>
        );
      }

      let moduleName = t('Panels_Audio_Capital_Letter');

      if (campaignModule.type === "video") {
        moduleName = t('Panels_Video_Capital_Letter');
      } else if (campaignModule.type == "film") {
        moduleName = t('Panels_Large_Video_Capital_Letter');
      } else if (campaignModule.type == "image") {
        moduleName = t('Panels_Image_Capital_Letter');
      } else if (campaignModule.type == "qa") {
        moduleName = t('Panels_QA_Capital_Letter');
      }


      return (
        <li
          key={key}
          className={classModule}
          onClick={e => this.openModuleOptions(campaignModule, e)}
        >
          <div className={`module ${campaignModule.type}`}>
            <i className={`icon ${campaignModule.icon} module-type-icon`}></i>
            <h4>{campaignModule.name}</h4>
            <i className="icon icon-plus-5"></i>
          </div>
          <div className="card-header"><i className="icon icon-menu-1"></i>{moduleName}</div>
          <div className="card-body">
            <img
              src={`/assets/img/modules/${campaignModule.img}`}
              alt={`card ${campaignModule.type} icon`}
            />
          </div>
          <div className="hover-tag">
            <p><T text="Modules_Click_To_Edit" /></p>
          </div>
          <div className="editing-tag">
            <i className="icon icon-pencil-6"></i>
            <p><T text="Modules_Editing" /></p>
          </div>
          {showEmptyTag &&
            <div className="error-tag">
              <p className="error-color"><T text="Modules_Empty_Tag" /></p>
            </div>
          }
        </li>
      );
    });

    const renderCodeModulesPre = coreModulesPre.map((coreModule, key) => {
      let classModule = `screen-card core unsortable ${coreModule.type}`;

      if (coreModule && coreModule.options)
        classModule = `${classModule} have-options`;

      if (
        activeModule &&
        activeModule.id &&
        coreModule &&
        coreModule.id &&
        activeModule.id === coreModule.id
      )
        classModule = `${classModule} active`;

      return (
        <li
          key={key}
          className={classModule}
          onClick={e => this.openModuleOptions(coreModule, e)}
        >
          <div className="card-header">
            <div className="card-header-text">
              {t(`Modules_Title_${coreModule.name}`)}
            </div>
          </div>
          <div className="card-body">
            <img
              src={`/assets/img/modules/${coreModule.img}`}
              alt={`card ${coreModule.type} icon`}
            />
          </div>
          <div className="hover-tag">
            <p><T text="Modules_Click_To_Edit" /></p>
          </div>
          <div className="editing-tag">
            <i className="icon icon-pencil-6"></i>
            <p><T text="Modules_Editing" /></p>
          </div>
        </li>
      );
    });

    const renderCodeModulesPost = coreModulesPost.map((coreModule, key) => {
      let classModule = `screen-card core unsortable ${coreModule.type}`;

      if (coreModule && coreModule.options)
        classModule = `${classModule} have-options`;

      if (
        activeModule &&
        activeModule.id &&
        coreModule &&
        coreModule.id &&
        activeModule.id === coreModule.id
      )
        classModule = `${classModule} active`;

      if (coreModule && coreModule.type === "empty") {
        return (
          <li
            key="empty"
            className="screen-card placeholder empty-card unsortable"
            onClick={e => this.showAddModulePanel(e)}
          >
            <i className="icon icon-plus-5"></i>
          </li>
        );
      }

      return (
        <li
          key={key}
          className={classModule}
          onClick={e => this.openModuleOptions(coreModule, e)}
        >
          <div className="card-header">{t(`Modules_Title_${coreModule.name}`)}</div>
          <div className="card-body">
            <img
              src={`/assets/img/modules/${coreModule.img}`}
              alt={`card ${coreModule.type} icon`}
            />
          </div>
          <div className="hover-tag">
            <p><T text="Modules_Click_To_Edit" /></p>
          </div>
          <div className="editing-tag">
            <i className="icon icon-pencil-6"></i>
            <p><T text="Modules_Editing" /></p>
          </div>
        </li>
      );
    });


    return (
      <div className="app screen-campaigns">
        <Helmet>
          <title>{t('Modules_Browser_Title')}</title>
        </Helmet>
        <ReactTooltip place="bottom" effect="solid" backgroundColor="#f08c1e" />
        <ModalDialog
          name="Modules_Save_Campaign_To_Preview"
          showModal={showModalPreview}
          loading={loadingModalPreview}
          closeModal={() => { this.setState({ showModalPreview: false }) }}
          onCancel={() => { this.setState({ showModalPreview: false }) }}
          onConfirm={() => this.modifyCampaignAndPreview()}
        >
          <div className="">
            <p><T text="Modules_Save_Campaign_To_Preview_Text" /></p>
          </div>
        </ModalDialog>
        <ModalDialog
          name="Modules_Max_Duration_Test"
          showModal={showModalMaxDuration}
          closeModal={() => { this.setState({ showModalMaxDuration: false }) }}
          onConfirm={() => { this.setState({ showModalMaxDuration: false }) }}
        >
          <p>
            <T text="Modules_Max_Duration_Test_Text" />
            {/*
            <a target="_blank" href={URL_PRICING}>plan</a>.
            */}
          </p>
        </ModalDialog>
        <ModalDialog
          name="Modules_Duration_Film_Test"
          showModal={showModalDurationFilm}
          closeModal={() => { this.setState({ showModalDurationFilm: false }) }}
          onConfirm={() => { this.setState({ showModalDurationFilm: false }) }}
        >
          <T text="Modules_Duration_Film_Test_Text" />
        </ModalDialog>
        {/*loading && (
          <div className="loading-cover">
            <div>
              <Spinner speed={0.8} color="#ffffff" size={20} />
            </div>
          </div>
        )*/}
        <div className="main-wrapper">
          <TopBar ref="topbar" location={location} />
          {/*
          <div className={classVersionControl}>
            <VersionControl
              campaignData={campaignData}
              changeCampaignVersionCallback={this.changeCampaignVersionCallback}
            />
          </div>
          */}
          <div className="screen-content modules">
            <ContentHeader
              location={location}
              campaignData={campaignDataSaved}
              changesToApply={changesToApply}
              onClickRevert={() => {
                this.setState({ activeModule: {} }, () => {
                  this.hideAllPanels();
                  this.loadData();
                });
              }}
              onClickApply={() => this.modifyTestDataTemporal()}
              callbackConfirmSaveModal={() => this.callbackConfirmSaveModal()}
              showConfirmSaveModalFromPreview={showConfirmSaveModalFromPreview}
            />
            <DesignMenu location={location} campaignData={campaignData} />
            <div className="section-modules">
              <div className="section-title">
                <h2><T text="Modules_Title" /></h2>
                <p><T text="Modules_Subtitle" /></p>
              </div>
              <div className="add-modules">
                <AddModulePanel
                  onClickAdd={newModule => this.addModule(newModule)}
                  onChoose={() => this.setState({ showAddCard: false })}
                  onUnchoose={() => this.setState({ showAddCard: true, showEmptyCard: true })}
                  testType={campaignData.data.type || ''}
                  existModuleFilm={campaignModules.filter(module => module.type === 'film').length >= moduleFilmsPermitedNumber && moduleFilmsPermitedNumber > 0}
                  features={campaignData.features}
                  isCampaignPaid={isCampaignPaid}
                  maxModulesFeatures={maxModulesFeatures}
                />
              </div>
            </div>
            <div className={classScreenContentInner}>
              {loading && (
                <div className="loading-cover">
                  {/* <div className="cover"></div> */}
                  <div className="cover design"></div>
                  <div>
                    <Spinner speed={0.8} color="#00C7E7" size={20} />
                  </div>
                </div>
              )}
              <DurationBar
                testPrice={testPrice}
                campaignData={campaignData}
                coreModulesPre={coreModulesPre}
                campaignModules={campaignModulesFiltered}
                coreModulesPost={coreModulesPost}
              />
              <div className={`sortable-deleted ${isCampaignPaid ? "hidden" : ""}`} data-tip={t('Modules_Drag_To_Remove_Tooltip')} data-place="right">
                <i className="icon icon-trash-1"></i>
                <p><T text="Modules_Drag_To_Remove" /></p>
                <ReactSortable
                  id="sortable-deleted"
                  tag="ul"
                  list={deletedModules}
                  setList={deletedModules => {
                    this.setState({ deletedModules }, () =>
                      this.checkCampaignModulesEmpty()
                    );
                  }}
                  group="shared"
                  sort={false}
                  onChange={e => {
                    this.checkCampaignModulesEmptyOnChange();
                  }}
                />
              </div>
              <div className="flow-canvas-wrapper">
                <div
                  className="flow-canvas"
                  style={{ minWidth: `${minFlowSize}px` }}
                  onClick={() => this.hideAllPanels()}
                >
                  <div className="screen-cards">
                    {!loading && (
                      <>
                        <ul>
                          {renderCodeModulesPre}
                        </ul>
                          <ReactSortable
                            ref="modulesSortable"
                            id="sortable"
                            tag="ul"
                            list={campaignModules}
                            setList={newState => {
                              const newStateCopy = newState.map(campaignModule => {
                                if (campaignModule.type === "empty")
                                  return { type: campaignModule.type }

                                let returnState = {
                                  id: campaignModule.id ? campaignModule.id : generateModuleID(),
                                  type: campaignModule.type,
                                  name: campaignModule.name,
                                  icon: campaignModule.icon,
                                  img: campaignModule.img
                                };

                                if (campaignModule.type === "audio") {
                                  returnState.audios = campaignModule.audios ? campaignModule.audios : [];
                                  returnState.tech = campaignModule.tech ? campaignModule.tech : "recognition";
                                  returnState.randomize = campaignModule.randomize ? campaignModule.randomize : "true";
                                } else if (campaignModule.type === "video") {
                                  returnState.videos = campaignModule.videos ? campaignModule.videos : [];
                                  returnState.tech = campaignModule.tech ? campaignModule.tech : "recognition";
                                  returnState.randomize = campaignModule.randomize ? campaignModule.randomize : "true";
                                } else if (campaignModule.type === "film") {
                                  returnState.videos = campaignModule.videos ? campaignModule.videos : [];
                                  returnState.tech = campaignModule.tech ? campaignModule.tech : "recognition";
                                  returnState.randomize = campaignModule.randomize ? campaignModule.randomize : "true";
                                  returnState.filmName = campaignModule.filmName ? campaignModule.filmName : "";
                                } else if (campaignModule.type === "image") {
                                  returnState.images = campaignModule.images ? campaignModule.images : [];
                                  returnState.tech = campaignModule.tech ? campaignModule.tech : "recognition";
                                  returnState.randomize = campaignModule.randomize ? campaignModule.randomize : "true";
                                  returnState.duration = campaignModule.duration ? campaignModule.duration : 6;
                                } else if (campaignModule.type === "qa") {
                                  returnState.questions = campaignModule.questions ? campaignModule.questions : [
                                    {
                                      "name": "",
                                      "answers": [],
                                      "questionID": 1589129861350
                                    }
                                  ];
                                  returnState.randomize = campaignModule.randomize ? campaignModule.randomize : "true";
                                }

                                return returnState;
                              }).filter(item => item.type !== "empty");

                              newStateCopy.push({ type: "empty" });

                              // let changesToApply = this.state.changesToApply === true ? true : false;

                              const campaignModulesFiltered = campaignModules.map(item => {
                                const itemCopy = Object.assign({}, item);
                                itemCopy.chosen = undefined;
                                return itemCopy;
                              });
                              const newStateCopyFiltered = newStateCopy.map(item => {
                                const itemCopy = Object.assign({}, item);
                                itemCopy.chosen = undefined;
                                return itemCopy;
                              });

                              let changesToApply = !_.isEqual(campaignModulesFiltered, newStateCopyFiltered);

                              this.setState({ campaignModules: newStateCopy, changesToApply }, () => {
                                this.checkCampaignModulesEmpty();
                                this.refreshCanvasSize(true);

                                if (changesToApply) {
                                  this.getPrice();
                                  this.modifyTestDataTemporal();
                                }
                              });
                            }}
                            group="shared"
                            handle="li"
                            draggable="li:not(.unsortable)"
                            filter='.unsortable'
                            ghostClass="placeholder"
                            removeCloneOnHide={false}
                            onChange={e => {
                              if (this.state.showEmptyCard)
                                this.setState({ showEmptyCard: false });
                            }}
                            onStart={e => {
                              this.setState({ dragginModule: true });
                            }}
                            onEnd={e => {
                              this.getPrice();
                              this.modifyTestDataTemporal();
                              this.setState({ dragginModule: false });
                            }}
                          >
                            {renderCampaignModules}
                          </ReactSortable>
                        <ul className="last">
                          {renderCodeModulesPost}
                        </ul>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {mountedPanels && (
                <>
                  <TextModulePanel
                    ref="textModulePanel"
                    onCancel={() => this.hideAllPanels()}
                    onInputChange={val => this.onOptionsChange(val)}
                    campaignData={campaignData}
                    showNotification={showNotification}
                    goPreview={url => this.goPreview(url)}
                    changesToApply={changesToApply}
                    isCampaignPaid={isCampaignPaid}
                  />
                  <FilmModulePanel
                    ref="filmModulePanel"
                    onCancel={() => this.hideAllPanels()}
                    onInputChange={val => this.onOptionsChange(val)}
                    campaignData={campaignData}
                    showNotification={showNotification}
                    goPreview={url => this.goPreview(url)}
                    changesToApply={changesToApply}
                    onChangeLoading={isFilmModuleLoading => this.setState({ isFilmModuleLoading })}
                    isCampaignPaid={isCampaignPaid}
                  />
                  <VideoModulePanel
                    ref="videoModulePanel"
                    onCancel={() => this.hideAllPanels()}
                    onInputChange={val => this.onOptionsChange(val)}
                    campaignData={campaignData}
                    showNotification={showNotification}
                    goPreview={url => this.goPreview(url)}
                    changesToApply={changesToApply}
                    isCampaignPaid={isCampaignPaid}
                  />
                  <AudioModulePanel
                    ref="audioModulePanel"
                    onCancel={() => this.hideAllPanels()}
                    onInputChange={val => this.onOptionsChange(val)}
                    campaignData={campaignData}
                    showNotification={showNotification}
                    goPreview={url => this.goPreview(url)}
                    changesToApply={changesToApply}
                    isCampaignPaid={isCampaignPaid}
                  />
                  <ImageModulePanel
                    ref="imageModulePanel"
                    onCancel={() => this.hideAllPanels()}
                    onInputChange={val => this.onOptionsChange(val)}
                    campaignData={campaignData}
                    showNotification={showNotification}
                    goPreview={url => this.goPreview(url)}
                    changesToApply={changesToApply}
                    isCampaignPaid={isCampaignPaid}
                  />
                  <QAModulePanel
                    ref="qaModulePanel"
                    onCancel={() => this.hideAllPanels()}
                    onInputChange={val => this.onOptionsChange(val)}
                    campaignData={campaignData}
                    showNotification={showNotification}
                    goPreview={url => this.goPreview(url)}
                    changesToApply={changesToApply}
                    isCampaignPaid={isCampaignPaid}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(Modules);
