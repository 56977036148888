import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { Spinner } from 'react-activity';
import { UserAccess, CampaignAccess, FileAccess, SounditiAuth } from '@sounditi/ft2-api';
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next';

import { setSession, setTestsData } from '../../reducers/user';
import { showNotification } from '../../reducers/notifications';
import { setRedirect } from '../../reducers/navigation';

import T from '../../components/Translate';
import Link from '../../components/Link';
import Input from '../../components/Input';

import { URL_CAMPAIGNS, URL_WIZARD_FILE } from '../../config/urls';

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth.api,
  tokenData: state.user.tokenData,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setSession: val => dispatch(setSession(val)),
  showNotification: val => dispatch(showNotification(val)),
  setRedirect: val => dispatch(setRedirect(val)),
  setTestsData: val => dispatch(setTestsData(val)),
  dispatch,
})

class WizardLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loginLoading: false,
      email: "",
      password: "",
      dataValidated: false,
    };
  }

  componentDidMount() {
    document.getElementById('body').className='wizard'
  }

  validateData() {
    const { password, email } = this.state;
    const dataValidated = (password !== '' && email !== '');
    this.setState({ dataValidated });
  }

  onInputChange(val) {
    this.setState(val, () => {
      this.validateData();
    });
  }

  async onLoginClick() {
    const { setRedirect, setSession, showNotification, auth, dispatch } = this.props;
    const { dataValidated, email, password } = this.state;

    const userAccess = new UserAccess();

    if (dataValidated) {
      try {
        this.setState({ loading: true });
        const hydratedLogin = await userAccess.hydratedLogin(email, password, auth);
        hydratedLogin.user = await userAccess.getUserData();

        dispatch(setSession(hydratedLogin)).then(() => {
          this.goCampaigns();
        })
      } catch (error) {
        this.setState({ loading: false });

        if (error.message === "Password dont match" || error.message === "User not found") {
          showNotification("invalidLoginData");
          this.refs.email.setInvalid();
          this.refs.password.setInvalid();
        } else if (error.message === "Email not verified") {
          showNotification("emailNotVerified");
        } else {
          showNotification("genericError");
        }
      }
    } else {
      if (!email) {
        this.refs.email.setInvalid();
      }

      if (!password) {
        this.refs.password.setInvalid();
      }

      showNotification("invalidData");
    }
  }

  goCampaigns() {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_CAMPAIGNS });
  }

  goWizardFile() {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_WIZARD_FILE });
  }

  render() {
    const { loading, loginLoading, email, password } = this.state;
    const { t } = this.props;

    return (
      <div className="app lite">
        <Helmet>
          <title>{t('Lite_File_Browser_Title')}</title>
        </Helmet>
        {loading && (
          <div className="loading-cover">
            <div className="cover"></div>
            <div>
              <Spinner speed={0.8} color="#00C7E7" size={20} />
            </div>
          </div>
        )}
        <div className="lite-topbar">
          <img className="logo" src="../assets/img/main-logo-c.png" alt="Company logo" />
        </div>
        <div className="screen-description">
          <h2><T text="WizardLogin_Title" /></h2>
          <Input
            className="margin-top-8"
            readOnly={loginLoading}
            placeholder={t('Lite_User_Email_Input_Email_Placeholder')}
            name="email"
            label={t('Lite_User_Email_Input_Email')}
            value={email}
            ref="email"
            onChange={val => this.onInputChange(val)}
          />
          <Input
            readOnly={loginLoading}
            type="password"
            placeholder={t('Lite_User_Email_Input_Password_Placeholder')}
            name="password"
            label={t('Lite_User_Email_Input_Password')}
            value={password}
            ref="password"
            onChange={val => this.onInputChange(val)}
          />
          <Link main className="full-width margin-top-8" onClick={() => this.onLoginClick()}>
            <T text="WizardLogin_Login_Button" />
          </Link>
          <Link secondary className="full-width" onClick={() => this.goWizardFile()}>
            <T text="WizardLogin_Register_Button" />
          </Link>
        </div>
        <div className="fluid white">

        </div>
        <div className="fixed-bottom">

        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(WizardLogin);
