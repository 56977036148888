import React from 'react';

import '../styles/components/Input.scss';

class Select extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      value: '',
      invalid: false,
    }
    this.handleChange = this.handleChange.bind(this);
  }

  setInvalid() {
    this.setState({ invalid: true });
    setTimeout(() => {
      this.setState({ invalid: false });
    }, 3000)
  }

  componentWillMount() {
    const { value } = this.props;
    if (value)
      this.setState({value});
  }

  handleChange (e) {
    const { onChange } = this.props;
    const returnObj = {};

    returnObj[e.target.name] = e.target.value;

    this.setState({ value: e.target.value, invalid: false });

    if (onChange)
      onChange(returnObj);
  }

  refresh() {
    const { value } = this.props;
    if (value)
      this.setState({value});
  }

  render() {
    const { name, label, last, options, small, disabled, readOnly } = this.props;
    const { value, invalid } = this.state;
    let inputClass = "field";

    if (small)
      inputClass = "input-wrapper select small-select";

    if (disabled)
      inputClass = `${inputClass} disabled`;

    if (invalid)
      inputClass = `${inputClass} invalid`;

    if (last)
      inputClass = `${inputClass} last`;

    let renderOptions = options.map(option => {
      const disabledOption = option?.disabled !== undefined ? option.disabled : false;
      return (
        <option key={option.value} value={option.value} disabled={disabledOption}>{option.label}</option>
      );
    });

    if (options?.segments) {
      const renderOptionsSegments = options.segments.map(option => {
        const disabledOption = option?.disabled !== undefined ? option.disabled : false;
        return (
          <option key={option.value} value={option.value} disabled={disabledOption}>{option.label}</option>
        );
      });

      renderOptions = (
        <>
          {renderOptions}
          <optgroup label="Audience segments">
            {renderOptionsSegments}
          </optgroup>
        </>
      )
    }

    if (options?.genders) {
      const renderOptionsGenders = options.genders.map(option => {
        const disabledOption = option?.disabled !== undefined ? option.disabled : false;
        return (
          <option key={option.value} value={option.value} disabled={disabledOption}>{option.label}</option>
        );
      });

      renderOptions = (
        <>
          {renderOptions}
          <optgroup label="Genders">
            {renderOptionsGenders}
          </optgroup>
        </>
      )
    }

    if (small)
      return (
        <div className={inputClass}>
          <select readOnly={readOnly} dir="rtl" name={name} value={value} onChange={this.handleChange} disabled={disabled}>
            {renderOptions}
          </select>
        </div>
      )

    return (
      <div className={inputClass}>
        <label htmlFor={name}>{label}</label>
        <div className="input-wrapper select">
          <select readOnly={readOnly} name={name} value={value} onChange={this.handleChange} disabled={disabled}>
            {renderOptions}
          </select>
        </div>
      </div>
    );
  }
}

export default Select