const initState = {
  showNotification: undefined,
};

export const showNotification = payload => Promise.resolve({
  type: 'SHOW_NOTIFICATION',
  payload,
});

export const clearNotification = () => Promise.resolve({
  type: 'CLEAR_NOTIFICATION',
});

const notifications = (state = initState, action) => {
  switch (action.type) {
    case 'SHOW_NOTIFICATION': {
      // console.log(action.payload);
      return { ...state, showNotification: action.payload }
    }
    case 'CLEAR_NOTIFICATION': {
      return { ...state, showNotification: undefined }
    }
    default:
      return state
  }
}

export default notifications