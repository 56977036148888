// COMPONENTS
import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { Spinner } from 'react-activity';
import { CampaignAccess, PanelAccess } from '@sounditi/ft2-api';
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next';
import T from '../../components/Translate';
import Link from '../../components/Link';
import Input from '../../components/Input';
import Select from '../../components/Select';
import MobileTopBar from '../../components/MobileTopBar';
import CampaignsTableV2 from '../../components/CampaignsTableV2';
import TopBar from '../../components/TopBar';

// ACTIONS
import { showNotification } from '../../reducers/notifications';
import { setRedirect } from '../../reducers/navigation';
import { setTestsData, setTemporalTestsData } from '../../reducers/user';
import { postActionGTMCampaing } from '../../utils/events';

// TYPES
import { newCampaignTemplateES } from '../../config/campaignTemplates';
import { newCampaignTemplateEN } from '../../config/campaignTemplates';
import { URL_CAMPAIGN_MODULES_ID } from '../../config/urls';

const mapStateToProps = (state, ownProps) => ({
  userData: state.user.userData,
  campaignsData: state.user.testsData,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setTestsData: val => dispatch(setTestsData(val)),
  setRedirect: val => dispatch(setRedirect(val)),
  showNotification: val => dispatch(showNotification(val)),
  setTemporalTestsData: val => dispatch(setTemporalTestsData(val)),
})

class NewTest extends React.Component {
  _mount = true;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      createTestLoading: false,
      planIvalid: false,
      campaignsFeatures: [],
      newTestPlan: "",
      newTestLang: "en",
      newTestName: ""
    };
  }

  componentWillMount() {
    this.loadData();

    // const lang = localStorage.getItem('lang') || "en";
    // const campaignTemplates = lang === "es" ? newCampaignTemplateES : newCampaignTemplateEN;
  }

  componentWillUnmount() {
    this._mount = false;
  }

  async loadData() {
    if (this._mount) {
      this.setState({ loading: true });

      const campaignAccess = new CampaignAccess();
      const campaignsFeatures = await campaignAccess.getCampaignFeatures();

      this.setState({ campaignsFeatures, loading: false });
    }
  }

  async sendEvent(action, screen) {
    const panelAccess = new PanelAccess();

    try {
      const createdAt = new Date().getTime();
      const sessionId = localStorage.getItem('sessionID-info');
      const browser = localStorage.getItem('browser-info');
      const device = localStorage.getItem('device-info');
      // type, device, browser, screen, sessionId, createdAt
      await panelAccess.postAction(action, screen, device, browser, sessionId, createdAt);
      // console.log({ postAction: { type: action, screen, device, browser, sessionId, createdAt } });

    } catch(error) {
      // console.log(error);
    };
  }

  setPlanInvalid() {
    this.setState({ planIvalid: true });
    setTimeout(() => {
      this.setState({ planIvalid: false });
    }, 3000)
  }

  async createCampaign() {
    const { userData, showNotification } = this.props;
    const { newTestName, newTestLang, newTestPlan } = this.state;

    if (newTestName === "") {
      showNotification('invalidData');
      this.refs.newTestName.setInvalid();
      return false;
    }

    if (newTestPlan === "business" || newTestPlan === "studio" || newTestPlan === "") {
      this.setPlanInvalid();
      showNotification('invalidPlan');
      return false;
    }

    try {
      this.setState({ createTestLoading: true });
      const lang = newTestLang || "en";
      const campaignTemplates = lang === "es" ? { ...newCampaignTemplateES } : { ...newCampaignTemplateEN };
      const subscriptionID = userData?.subscription?.subscriptionId || '8e1eae68-8b88-45bd-a526-c386b1ae3b56';

      campaignTemplates.name = newTestName;
      campaignTemplates.lang = newTestLang;
      campaignTemplates.showPromoButton = subscriptionID === '8e1eae68-8b88-45bd-a526-c386b1ae3b56' ? true : false;

      const campaignAccess = new CampaignAccess();
      const newCampaign = await campaignAccess.createCampaign(campaignTemplates, newTestPlan);

      postActionGTMCampaing("GTM_new_campaign", newCampaign.campaignId, userData);
      this.sendEvent("control_create_test", undefined, { campaignId: newCampaign.campaignId });

      await this.refreshTestsData();

      this.goCampaignModules(newCampaign);
      // this.setState({ createTestLoading: false });
    } catch (error) {
      console.log(error);
      this.setState({ createTestLoading: false });
      showNotification("genericError");
    }
  }

  async refreshTestsData() {
    const { setTestsData, setTemporalTestsData } = this.props;
    const campaignAccess = new CampaignAccess();
    const refreshedTestsData = await campaignAccess.getCampaigns();
    setTestsData(refreshedTestsData);
    setTemporalTestsData(refreshedTestsData);
  }

  onInputChange(val) {
    this.setState(val);
  }

  goCampaignModules(campaign) {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_CAMPAIGN_MODULES_ID.replace('[var1]', campaign.campaignId) });
  }

  printTestPlan(planDetails) {
    const { t } = this.props;

    /* 
      Basic - fdb20869-9210-4b9a-98fd-4687ec4b5ea6
      Business Lite - 7ab5cfed-a17c-43f5-b7f5-f5670471f594
      Business Plus - 8ac549b9-a8f0-40df-8f0a-5b2e7a3a096f
      Studio Lite - e414ab51-3735-4192-9e51-e888f08ea665
      Studio Plus - 6f67e322-7927-457e-9bb2-2d0c50144664
      Custom - 99e07ef6-5251-4472-ba87-cb85c855956b
    */

    const lang = localStorage.getItem('lang') || "en";
    const isBusiness = planDetails.planId === "business";
    const isStudio = planDetails.planId === "studio";

    if (isBusiness)
      planDetails.planId = "7ab5cfed-a17c-43f5-b7f5-f5670471f594"

    if (isStudio)
      planDetails.planId = "e414ab51-3735-4192-9e51-e888f08ea665"

    const { newTestPlan, campaignsFeatures } = this.state;
    const plan = campaignsFeatures.filter(plan => plan.featuresId === planDetails.planId)[0];

    let tagClass = "plan-tag";

    if (planDetails.planId === "fdb20869-9210-4b9a-98fd-4687ec4b5ea6")
      tagClass = tagClass + ' free';

    if (planDetails.planId === "99e07ef6-5251-4472-ba87-cb85c855956b")
      tagClass = tagClass + ' custom';

    return (
      <div className={`test-plan ${newTestPlan === plan.featuresId ? 'selected' : ''}`}>
        <div className="description">
          <div className="plan-title">{planDetails.title}</div>
          <div className="plan-name">{planDetails.name}</div>
          <div className={tagClass}>{planDetails.tag}</div>
          {planDetails.descriptionDetails !== undefined && planDetails.descriptionDetails === true
            ? <div className="plan-description"> <span dangerouslySetInnerHTML={{__html: plan[`descriptionDetails_${lang}`]}} /> </div>
            : <div className="plan-description"> <span dangerouslySetInnerHTML={{__html: plan[`description_${lang}`]}} /> </div>
          }
        </div>
        {/*
        {
          newTestPlan !== "business" &&
          newTestPlan !== "studio" &&
          newTestPlan !== "8ac549b9-a8f0-40df-8f0a-5b2e7a3a096f" && 
          newTestPlan !== "7ab5cfed-a17c-43f5-b7f5-f5670471f594" && 
          newTestPlan !== "e414ab51-3735-4192-9e51-e888f08ea665" && 
          newTestPlan !== "6f67e322-7927-457e-9bb2-2d0c50144664" && 
          ( isBusiness || isStudio ) 
        && (
          <div className="plan-price-from"><T text="NewTest_Button_Starts_With" /></div>
        )}
        {planDetails.planId !== "99e07ef6-5251-4472-ba87-cb85c855956b"
          ? <div className="plan-price">${plan.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div>
          : <div className="plan-price-from plan-price-custom"><span dangerouslySetInnerHTML={{__html: plan[`priceText_${lang}`]}} /></div>
        }
        */}
        {newTestPlan === plan.featuresId ? (
          <Link inline onClick={() => {}}>
            <T text="NewTest_Button_Selected" />
          </Link>
        ) : (
          <Link secondary onClick={() => {
            if (planDetails.planId === "99e07ef6-5251-4472-ba87-cb85c855956b") {
              this.setState({ newTestPlan: "" })
              window.Froged('open','contact');
              return false;
            }

            if (isBusiness) {
              this.setState({ newTestPlan: "business" })
              return false;
            }

            if (isStudio) {
              this.setState({ newTestPlan: "studio" })
              return false;
            }
            
            this.setState({ newTestPlan: plan.featuresId })
          }}>
            {plan.featuresId === "99e07ef6-5251-4472-ba87-cb85c855956b" ? t("NewTest_Button_Contact") : t("NewTest_Button_Select_This")}
          </Link>
        )}
      </div>
    )
  }

  render() {
    const {
      loading,
      campaignsFeatures,
      newTestName,
      newTestLang,
      newTestPlan,
      AddTestLoading,
      createTestLoading,
      planIvalid
    } = this.state;
    const { location, t } = this.props;

    // console.log(campaignsFeatures);

    return (
      <div className="app screen-campaigns">
        <Helmet>
          <title>{t("Campaigns_Browser_Title")}</title>
        </Helmet>
       
        <div className="main-wrapper">
          <TopBar ref="topbar" location={location} />
          <MobileTopBar title="Create test" />
          <div className={`fluid screen-content add-padding-top ${createTestLoading ? 'no-events' : ''}`}>
            <div className="content-wrapper create-test">
              {loading && (
                <div className="loading-cover">
                  <div>
                    <Spinner speed={0.8} color="#00C7E7" size={20} />
                  </div>
                </div>
              )}
              {!loading && (
                <>
                  <h2><T text="NewTest_Title" /></h2>
                  <div className="content-widget first">
                    <div className="row">
                    <div className="name-item">
                      <Input
                        readOnly={AddTestLoading}
                        placeholder=""
                        name="newTestName"
                        label={t('TopBar_Add_New_Input_Name')}
                        value={newTestName}
                        ref="newTestName"
                        onChange={val => this.onInputChange(val)}
                      />
                    </div>
                    <div className="language-item">
                      <Select
                        readOnly={AddTestLoading}
                        name="newTestLang"
                        label={t("TopBar_Add_New_Input_Lang")}
                        value={newTestLang}
                        ref="newTestLang"
                        onChange={val => this.onInputChange(val)}
                        options={[
                          { "value": "es", "label": t("Account_General_Lang_ES") },
                          { "value": "en", "label": t("Account_General_Lang_EN") },
                        ]}
                      />
                      </div>
                    </div>
                  </div>
                  <div className={`content-widget ${planIvalid ? 'invalid' : ''}`}>
                    {(newTestPlan === "business" || newTestPlan === "8ac549b9-a8f0-40df-8f0a-5b2e7a3a096f" || newTestPlan === "7ab5cfed-a17c-43f5-b7f5-f5670471f594") && (
                      <div className="test-plans bussiness">
                        <div className="back-buttom">
                          <Link inline onClick={() => this.setState({ newTestPlan: "" })}>
                            <i className="icon icon-left-open"></i>
                          </Link>
                        </div>
                        {this.printTestPlan({
                          planId: "7ab5cfed-a17c-43f5-b7f5-f5670471f594", 
                          title: "For Business", 
                          name: "Alyze Business Lite", 
                          tag: "TRY IT FOR FREE", 
                          descriptionDetails: true,
                          description: "Clients or employees have a say about your relevant branding, marketing or advertising content. Understand their emotions and turn them into smart decisions."
                        })}
                        {this.printTestPlan({
                          planId: "8ac549b9-a8f0-40df-8f0a-5b2e7a3a096f", 
                          title: "For Business", 
                          name: "Alyze Business Plus", 
                          tag: "TRY IT FOR FREE", 
                          descriptionDetails: true,
                          description: "Clients or employees have a say about your relevant branding, marketing or advertising content. Understand their emotions and turn them into smart decisions."
                        })}  
                      </div>
                    )}
                    {(newTestPlan === "studio" || newTestPlan === "e414ab51-3735-4192-9e51-e888f08ea665" || newTestPlan === "6f67e322-7927-457e-9bb2-2d0c50144664") && (
                      <div className="test-plans bussiness">
                        <div className="back-buttom">
                          <Link inline onClick={() => this.setState({ newTestPlan: "" })}>
                            <i className="icon icon-left-open"></i>
                          </Link>
                        </div>
                        {this.printTestPlan({
                          planId: "e414ab51-3735-4192-9e51-e888f08ea665", 
                          title: "For Entertainers", 
                          name: "Alyze Studio Lite", 
                          tag: "TRY IT FOR FREE", 
                          descriptionDetails: true,
                          description: "Make the best edition of your footage and make smart decisions focused on the launch and promotion of your movie. Analyze the emotions of your target audience before launching your movie and detect attention peaks and valleys."
                        })}
                        {this.printTestPlan({
                          planId: "6f67e322-7927-457e-9bb2-2d0c50144664", 
                          title: "For Entertainers", 
                          name: "Alyze Studio Plus", 
                          tag: "TRY IT FOR FREE", 
                          descriptionDetails: true,
                          description: "Make the best edition of your footage and make smart decisions focused on the launch and promotion of your movie. Analyze the emotions of your target audience before launching your movie and detect attention peaks and valleys."
                        })}  
                      </div>
                    )}
                    {
                      newTestPlan !== "business" &&
                      newTestPlan !== "studio" &&
                      newTestPlan !== "8ac549b9-a8f0-40df-8f0a-5b2e7a3a096f" && 
                      newTestPlan !== "7ab5cfed-a17c-43f5-b7f5-f5670471f594" && 
                      newTestPlan !== "e414ab51-3735-4192-9e51-e888f08ea665" && 
                      newTestPlan !== "6f67e322-7927-457e-9bb2-2d0c50144664" && 
                    (
                      <div className="test-plans">
                        {/* this.printTestPlan({
                          planId: "fdb20869-9210-4b9a-98fd-4687ec4b5ea6", 
                          title: "For Individuals", 
                          name: "Alyze Basic", 
                          tag: "FREE", 
                          description: "Understand the emotional reaction of your audience to your audiovisual content. It’s simple, easy and free. Adapt your contents to get the attention of your followers."
                        }) */}
                        {this.printTestPlan({
                          planId: "business", 
                          title: "For Business", 
                          name: "Alyze Business", 
                          tag: "TRY IT FOR FREE", 
                          description: "Clients or employees have a say about your relevant branding, marketing or advertising content. Understand their emotions and turn them into smart decisions."
                        })}
                        {this.printTestPlan({
                          planId: "studio", 
                          title: "For Entertainers", 
                          name: "Alyze Studio", 
                          tag: "TRY IT FOR FREE", 
                          description: "Make the best edition of your footage and make smart decisions focused on the launch and promotion of your movie. Analyze the emotions of your target audience before launching your movie and detect attention peaks and valleys."
                        })}
                        {this.printTestPlan({
                          planId: "99e07ef6-5251-4472-ba87-cb85c855956b", 
                          title: "For Everyone", 
                          name: "Alyze Custom", 
                          tag: "TELL US MORE!", 
                          description: "Have a business where emotions are key to drive sales and engagement. Contact us and tell us more about your needs. Our team will build a custom proposal for you."
                        })}
                      </div>
                    )}
                  </div>
                  <div className="create-test-note">
                    <T text="NewTest_Note" />
                  </div>
                  <div className="create-test-button">
                    <Link main disabled={createTestLoading} onClick={() => this.createCampaign()}>
                      {createTestLoading && (
                        <Spinner speed={0.8} color="#ffffff" size={16} />
                      )}
                      <T text="TopBar_Create_Test" />
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(NewTest);
