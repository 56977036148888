import {
  LOCATION_PROFILE,
  LOCATION_TOKEN,
  LOCATION_TOKEN_EXPIRATION,
  LOCATION_CAMPAIGNS
} from '../config/global';
import {
  initString,
  initObject,
  initTokenExpiration,
} from '../utils/userReducerUtils';

const initState = {
  testsData: initObject(LOCATION_CAMPAIGNS),
  userData: initObject(LOCATION_PROFILE),
  tokenData: initString(LOCATION_TOKEN),
  tokenExpiration: initString(LOCATION_TOKEN_EXPIRATION),
  tokenExpirated: initTokenExpiration(initString(LOCATION_TOKEN_EXPIRATION)),
  signupData: undefined,
  temporalTestsData: initObject(LOCATION_CAMPAIGNS),
};

export const setSignupData = payload => Promise.resolve({
  type: 'SET_SIGNUP_DATA',
  payload,
});

export const clearSignupData = () => Promise.resolve({
  type: 'CLEAR_SIGNUP_DATA',
});

export const setTestsData = payload => Promise.resolve({
  type: 'SET_TESTS_DATA',
  payload,
});

export const setTemporalTestsData = payload => Promise.resolve({
  type: 'SET_TEMPORAL_TEST_DATA',
  payload,
});

export const setUserData = payload => Promise.resolve({
  type: 'SET_USER_DATA',
  payload,
});

export const setSession = payload => Promise.resolve({
  type: 'SET_SESSION',
  payload,
});

export const clearSession = () => Promise.resolve({
  type: 'CLEAR_SESSION',
});

export const checkTokenExpiration = payload => Promise.resolve({
  type: 'CHECK_LOCATION_TOKEN_EXPIRATION',
  payload,
});

const user = (state = initState, action) => {
  switch (action.type) {
    case 'SET_SIGNUP_DATA': {
      const email = action.payload.email.toLowerCase();
      const signupData = { ...action.payload, email };
      return { ...state, signupData }
    }
    case 'CLEAR_SIGNUP_DATA': {
      return { ...state, signupData: undefined }
    }
    case 'SET_TESTS_DATA': {
      const { userData } = state;
      const testsData = action.payload;
      localStorage.setItem(LOCATION_CAMPAIGNS, JSON.stringify(testsData));

      const userID = userData.id || undefined;
      const testsCreated = testsData.length || 0;
      const testsWithResponses = testsData.filter(test => test.enrolledTesters > 0 && test.campaignId !== "e6d325b7-07f6-408c-a044-a8ed09f428cb").length;

      //const filmTests = testsData.filter(test => test.data.type === "film").length;
      //const filmTestsWithResponses = testsData.filter(test => test.data.type === "film" && test.enrolledTesters > 0).length;
      const filmTests = testsData.filter(test => test?.features?.filmModule === true).length;
      const filmTestsWithResponses = testsData.filter(test => test?.features?.filmModule === true && test?.enrolledTesters > 0).length;

      if (userID) {
        window.Froged('set', {
          userId: userID,
          testsCreated,
          testsWithResponses,
          filmTests,
          filmTestsWithResponses
        });
      }

      return { ...state, testsData }
    }
    case 'SET_TEMPORAL_TEST_DATA': {
      const temporalTestsData = action.payload;
      return { ...state, temporalTestsData }
    }
    case 'SET_USER_DATA': {
      const userData = action.payload;
      localStorage.setItem(LOCATION_PROFILE, JSON.stringify(userData));

      const userID = userData.id || undefined;
      const name = userData.data.name || userData.name || "";
      const email = userData.data.email || userData.email || "";

      if (userID) {
        window.Froged('set', {
          userId: userID,
          email,
          name,
        });
      }

      return { ...state, userData }
    }
    case 'SET_SESSION': {
      const userData = action.payload.user;
      const tokenData = action.payload.token;
      const tokenExpiration = action.payload.token_expiration;

      localStorage.setItem(LOCATION_PROFILE, JSON.stringify(userData));
      localStorage.setItem(LOCATION_TOKEN, tokenData);
      localStorage.setItem(LOCATION_TOKEN_EXPIRATION, tokenExpiration);

      return { ...state, userData, tokenData, tokenExpiration }
    }
    case 'CLEAR_SESSION': {
      // console.log("clear session");
      const userData = undefined;
      const tokenData = undefined;
      const tokenExpiration = undefined;

      localStorage.removeItem(LOCATION_PROFILE);
      localStorage.removeItem(LOCATION_TOKEN);
      localStorage.removeItem(LOCATION_TOKEN_EXPIRATION);

      return { ...state, userData, tokenData, tokenExpiration }
    }
    case 'CHECK_LOCATION_TOKEN_EXPIRATION': {
      const { tokenExpiration } = state;
      const tokenExpirated = new Date().getTime() >  1000 * tokenExpiration;

      return { ...state, tokenExpirated }
    }
    default:
      return state
  }
}

export default user