import {
  LOCATION_TABS,
} from '../config/global';

import {
  initObject,
} from '../utils/userReducerUtils';

const initState = {
  tabs: initObject(LOCATION_TABS),
};

export const refreshTabs = payload => Promise.resolve({
  type: 'REFRESH_TABS',
  payload,
});

export const openTab = payload => Promise.resolve({
  type: 'OPEN_TAB',
  payload,
});

export const closeTab = payload => Promise.resolve({
  type: 'CLOSE_TAB',
  payload,
});

const tabs = (state = initState, action) => {
  switch (action.type) {
    case 'OPEN_TAB': {
      if (!action.payload.campaignId || !action.payload.data.name)
        return { ...state }

      const tabs = state.tabs;
      const campaign = {
        "id": action.payload.campaignId,
        "name": action.payload.data.name
      };

      if (!tabs.filter(tab => tab.id === campaign.id).length > 0) {
        tabs.push(campaign);
      }

      localStorage.setItem(LOCATION_TABS, JSON.stringify(tabs));
      return { ...state, tabs }
    }
    case 'CLOSE_TAB': {
      const tabs = state.tabs.filter(tab => {
        return tab.id !== action.payload
      });

      localStorage.setItem(LOCATION_TABS, JSON.stringify(tabs));
      return { ...state, tabs }
    }
    case 'REFRESH_TABS': {
      const tabs = action.payload;

      localStorage.setItem(LOCATION_TABS, JSON.stringify(tabs));
      return { ...state, tabs }
    }
    default:
      return state
  }
}

export default tabs