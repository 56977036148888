import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { AnalysisAccess } from '@sounditi/ft2-api';
import { Spinner } from 'react-activity';
import { ResponsivePie } from '@nivo/pie'
import { withTranslation } from 'react-i18next';

import T from '../Translate';

import { getURLParameter } from '../../utils/global';
import { parseGlobalSummary, parseLinks } from '../../utils/metrics';

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({})

class Gender extends React.Component {
  _mount = true;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
    };
  }

  componentWillMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this._mount = false;
  }

  async loadData() {
    const { activeModule, t, currentModalLink, globalSummary } = this.props;
    // If it's "global" activeModule will be "false"

    this.setState({ loading: true });

    let data = []
    try {
      const analysisAccess = new AnalysisAccess();
      const campaignId = getURLParameter("id");
      // console.log(campaignId, activeModule);

      /*if (activeModule != false) {
        const moduleAudienceSummary = await analysisAccess.moduleAudienceSummary({ moduleId: activeModule.id });
        // console.log('moduleAudienceSummary', moduleAudienceSummary.audience)
        data = analysisAccess.parseAudienceGender(moduleAudienceSummary.audience)
      } else {
        const campaignAudienceSummary = await analysisAccess.campaignAudienceSummary({ campaignId });
        // console.log('campaignAudienceSummary', campaignAudienceSummary)
        data = analysisAccess.parseAudienceGender(campaignAudienceSummary)
      }

      data.forEach((item, key) => {
        data[key].label = t(`Gender_${data[key].label.replace(" ", "_")}`);
        data[key].id = t(`Gender_${data[key].id.replace(" ", "_")}`);
      });*/

      if (currentModalLink) {
        data = parseLinks(currentModalLink, 'genders');
      } else {
        data = parseGlobalSummary(globalSummary, 'genders');
      }

    } catch (error) {
      // console.log(error);

      // TODO: Add a error message in widged with button to reload data
    }

    if (this._mount) {
      this.setState({ data, loading: false });
    }
  }

  render() {
    const { data, loading } = this.state;

    if (loading) {
      return (
        <div className="responsive-chart recopilator-graph">
          <div className="loading-cover">
            <div className="cover"></div>
            <div>
              <Spinner speed={0.8} color="#00C7E7" size={20} />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="responsive-chart recopilator-graph">
        <div className="flex">
        {
          data.length > 0 ?
              <ResponsivePie
              data={data}
              margin={{ top: 20, right: 80, bottom: 80, left: 80 }}
              innerRadius={0.5}
              padAngle={0.7}
              cornerRadius={3}
              colors={{ scheme: 'spectral' }}
              //colors={(datum) => datum.color }
              borderWidth={3}
              borderColor={'#ffffff'}         
              tooltip={({ id, value, number, color }) => (
                <div className="recopilator-tooltip">
                  <span className="recopilator-tooltip-interaction" style={{backgroundColor: color}}></span>
                  <span>{id}: <strong>{value}% ({number})</strong></span>
                </div>
              )}
              radialLabelsSkipAngle={10}
              radialLabelsTextXOffset={6}
              radialLabelsTextColor="#1C1D2E"
              radialLabelsLinkOffset={0}
              radialLabelsLinkDiagonalLength={16}
              radialLabelsLinkHorizontalLength={10}
              radialLabelsLinkStrokeWidth={2}
              radialLabelsLinkColor={{ from: 'color' }}
              slicesLabelsSkipAngle={10}
              slicesLabelsTextColor="#EDEDF1"
              sliceLabel={d => `${d.value}%`}
              animate={true}
              motionStiffness={90}
              motionDamping={15}
              theme={{
                labels: {
                  text: {
                    fontWeight: 600,
                    fontSize: 14
                  }
                },
                legends: {
                  text: {
                    fontSize: 13
                  }
                }
              }}
              legends={[
                {
                  anchor: 'bottom',
                  direction: 'row',
                  translateY: 56,
                  translateX: 15,
                  itemWidth: 100,
                  itemHeight: 18,
                  itemTextColor: '#777782',
                  symbolSize: 18,
                  symbolShape: 'square'
                }
              ]}
            />
          : <div className="no-results-msg">
              <img src="../assets/img/no-campaigns-img.png" alt="No campaigns" />
              <h2><T text="Metrics_No_Results" /></h2>
              <p><T text="Metrics_No_Results_Text" /></p>
            </div>
        }
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation('translations', { withRef: true }),
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(Gender);
