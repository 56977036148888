// COMPONENTS
import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import T from '../components/Translate';
import Link from '../components/Link';

// ACTIONS
import { setRedirect } from '../reducers/navigation';

// TYPES
import { URL_PRICING } from '../config/urls';

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
   setRedirect: val => dispatch(setRedirect(val)),
})

class SubscriptionWidget extends React.Component {
  getFormatDate(inputDate) {
    const date = new Date(inputDate);

    let month = date.getMonth() + 1;
    let day = date.getDate();
    const year = date.getFullYear();

    month = (month < 10 ? "0" : "") + month;
    day = (day < 10 ? "0" : "") + day;

    return `${day}/${month}/${year}`;
  }

  goPricing() {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_PRICING });
  }

  render() {
    const {
      userData,
      onClickChangePlan,
      t
    } = this.props;

    if (!userData.data)
      return false;

    let availableTests = userData.subscription.total_test - userData.subscription.current_test;
    let availableSends = userData.subscription.total_send - userData.subscription.current_send;

    if (availableTests < 0)
      availableTests = 0;

    if (availableSends < 0)
      availableSends = 0;

    const planTests = userData.subscription.total_test;
    const planSends = userData.subscription.total_send;

    const consumedTests = userData.subscription.current_test;
    const consumedSends = userData.subscription.current_send;
    const consumedFilms = userData.subscription.current_films;

    const subscriptionFilms = userData.subscription.filmsNumber;
    const subscriptionTests = userData.subscription.total_test;
    const subscriptionSends = userData.subscription.total_send;
    const subscriptionTime = userData.subscription.test_duration;
    const subscriptionCicleStart = this.getFormatDate(userData.subscription.start_cycle);
    const subscriptionCicleEnds = this.getFormatDate(userData.subscription.end_cycle);
    const subscriptionEnds = this.getFormatDate(userData.subscription.end_subscription);
    const subscriptionStart = this.getFormatDate(userData.subscription.start_subscription);
    const subscriptionName = userData.subscription.name;
    const subscriptionCicle = userData.subscription.is_year_paid ? t('SubscriptionWidget_Yearly') : t('SubscriptionWidget_Montly');
    const subscriptionButton = subscriptionName === "Free" ? t('SubscriptionWidget_Btn_Subscribe_Plan') : t('SubscriptionWidget_Btn_Change_Plan');
    const pricePerMonth = userData.subscription.price_per_month;
    const isActive = userData.subscription.status === "active";
    const price = userData.subscription.is_year_paid === true ? userData.subscription.price_per_year_month : userData.subscription.price_per_month;

    const subscriptionTestsFormated = subscriptionTests >= 0 ? `${subscriptionTests} ${t('SubscriptionWidget_Tests_Number')}` : t('SubscriptionWidget_Tests_Unlimited');
    const isSubscribed = ( subscriptionName === "Free" || subscriptionName === "" ) ? false : true;

    // console.log(userData.subscription);

    return (
      <div className="content-widget">
        {/* <div className="float-right">
          <Link main className="featured" onClick={() => this.goPricing()}>
            <T text="SubscriptionWidget_Btn_Change_Plan" />
          </Link>
        </div> */}
        <div className="account-item">
          <div className="title"><T text="SubscriptionWidget_Plan_Type" /></div>
          <div className="value">{subscriptionName}</div>
          {(subscriptionName !== "Free" && isActive) && (
            <div className="subtitle">
              <T
                text="SubscriptionWidget_Plan_Info_Renovation"
                var1={subscriptionCicle}
                var2={subscriptionEnds}
              />
            </div>
          )}
          {(subscriptionName !== "Free" && !isActive) && (
            <div className="subtitle cancelled">
              <T
                text="SubscriptionWidget_Plan_Info_Ends"
                var1={subscriptionCicle}
                var2={subscriptionEnds}
              />
              <i className="icon icon-attention-4"></i>
            </div>
          )}
        </div>
        {planTests >= 0 && (
          <div className="account-item">
            <div className="title"><T text="SubscriptionWidget_Plan_Usage" /></div>
            <div className="plan-progress">
              <div className="title"><T text="SubscriptionWidget_Legend_Tests" /></div>
              <div className="progress-bar">
                <div className="progress"  style={{ width: Math.round((consumedTests / planTests) * 100) + "%" }}></div>
              </div>
              <div className="subtitle">
                <T text="SubscriptionWidget_Consumed_Tests" var1={consumedTests.toString()} var2={subscriptionTests.toString()} />
                {/* <div className="float-right">
                  <T text="SubscriptionWidget_Plan_Renovation" var1={subscriptionEnds} />
                </div> */}
              </div>
            </div>
          </div>
        )}
        {subscriptionFilms >= 0 && (
          <div className="account-item">
            <div className="title"><T text="SubscriptionWidget_Plan_Usage" /></div>
            <div className="plan-progress">
              <div className="title"><T text="SubscriptionWidget_Legend_Films" /></div>
              <div className="progress-bar">
                <div className="progress"  style={{ width: Math.round((consumedFilms / subscriptionFilms) * 100) + "%" }}></div>
              </div>
              <div className="subtitle">
                <T text="SubscriptionWidget_Consumed_Film_Tests" var1={consumedFilms.toString()} var2={subscriptionFilms.toString()} />
                {/* <div className="float-right">
                  <T text="SubscriptionWidget_Plan_Renovation" var1={subscriptionEnds} />
                </div> */}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(SubscriptionWidget);
