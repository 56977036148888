// COMPONENTS
import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Link from './Link';
import T from './Translate';

// ACTIONS
import { showNotification } from '../reducers/notifications';
import { setRedirect } from '../reducers/navigation';

// TYPES
import {
  URL_CAMPAIGN_CONFIGURATION,
  URL_CAMPAIGN_DESIGN,
  URL_CAMPAIGN_MODULES,
  URL_CAMPAIGN_CONFIGURATION_ID,
  URL_CAMPAIGN_DESIGN_ID,
  URL_CAMPAIGN_MODULES_ID,
} from '../config/urls';

const mapStateToProps = (state, ownProps) => ({
  userData: state.user.userData,
  testsData: state.user.testsData,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setRedirect: val => dispatch(setRedirect(val)),
  showNotification: val => dispatch(showNotification(val)),
})

class DesignMenu extends React.Component {
  goCampaignDesign(campaign) {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_CAMPAIGN_DESIGN_ID.replace('[var1]', campaign.campaignId) });
  }

  goCampaignModules(campaign) {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_CAMPAIGN_MODULES_ID.replace('[var1]', campaign.campaignId) });
  }

  goCampaignConfiguration(campaign) {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_CAMPAIGN_CONFIGURATION_ID.replace('[var1]', campaign.campaignId) });
  }

  render() {
    const { campaignData, location } = this.props;

    return (
      <div className="design-menu">
        <div className="sections-menu">
          <div className="sections">
            <div
              className={location.pathname === URL_CAMPAIGN_MODULES ? "button active" : "button"}
              onClick={() => this.goCampaignModules(campaignData)}
            >
              <i className="icon icon-popup"></i>
            </div>
            <div 
              className={location.pathname === URL_CAMPAIGN_DESIGN ? "button active" : "button"}
              onClick={() => this.goCampaignDesign(campaignData)}
            >
              <i className="icon icon-brush-1"></i>
            </div>
            <div
              className={location.pathname === URL_CAMPAIGN_CONFIGURATION ? "button active" : "button"}
              onClick={() => this.goCampaignConfiguration(campaignData)}
            >
              <i className="icon icon-cog-1"></i>
            </div>
          </div>
          <div className="button toggle-sections">
            <i className="icon icon-left-open-1"></i>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation('translations', { withRef: true }),
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(DesignMenu);
