// COMPONENTS
import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { Spinner } from 'react-activity';
import Scrollbar from 'smooth-scrollbar';
import { UserAccess } from '@sounditi/ft2-api';
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next';
import TopBar from '../../components/TopBar';
import ContentHeader from '../../components/ContentHeader';
import T from '../../components/Translate';
import Link from '../../components/Link';
import Input from '../../components/Input';
import InputUploadFile from '../../components/InputUploadFile';

// ACTIONS
import { showNotification } from '../../reducers/notifications';
import { setRedirect } from '../../reducers/navigation';

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setRedirect: val => dispatch(setRedirect(val)),
  showNotification: val => dispatch(showNotification(val)),
})

class SupportAndHelp extends React.Component {
  _mount = true;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userID: "",
      name: "",
      email: "",
      company: "",
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
      profilePicture: "",
      dataValidate: true,
      userData: [],
      changesToApply: false,
    };
  }

  componentWillMount() {
    this.loadData();
  }

  componentDidMount() {
    if (document.querySelector('#scrollview'))
      Scrollbar.init(document.querySelector('#scrollview'), {
        alwaysShowTracks: true
      });

    if (this.refs.topbar)
      this.refs.topbar.sendEvent("control_enter_screen_supportAndHelp", "SupportAndHelp")
  }

  componentWillUnmount() {
    this._mount = false;
  }

  async loadData() {
    if (this._mount) {
      const userAccess = new UserAccess();
      const userData = await userAccess.getUserData();

      if (userData && userData.name) {
        const userID = userData.id || "";
        const name = userData.data.name || "";
        const email = userData.email || "";
        const company = userData.data.company || "";
        const profilePicture = userData.data.profilePicture || "";

        this.setState({
          userData,
          userID,
          name,
          email,
          company,
          profilePicture,
          changesToApply: false
        }, () => {
          if (this.refs.name)
            this.refs.name.refresh();

          if (this.refs.email)
            this.refs.email.refresh();

          if (this.refs.company)
            this.refs.company.refresh();

          if (this.refs.profilePicture)
            this.refs.profilePicture.refresh();
        });
      }
    }
  }

  async modifyCampaign() {
    const { showNotification } = this.props;
    const {
      name,
      company,
      profilePicture,
      userData
    } = this.state;
    const { data } = userData;

    const newData = {
      ...data,
      name: name,
      company: company,
      profilePicture: profilePicture
    };

    try {
      this.setState({ loading: true });

      const userAccess = new UserAccess();
      await userAccess.updateUserData(newData);

      this.loadData();
      this.refs.userInfo.loadData();
      this.setState({
        loading: false,
        changesToApply: false
      });
    } catch (error) {
      this.setState({ loading: false });
      showNotification("genericError");
    }
  }

  validateData() {
    this.setState({ dataValidated: true, changesToApply: true });
  }

  onInputChange(val) {
    this.setState(val, () => {
      this.validateData();
    });
  }

  changePassword() {
    const { showNotification } = this.props;
    const {
      oldPassword,
      newPassword,
      newPasswordConfirm,
      userData
    } = this.state;

    if (oldPassword !== "" && newPassword !== "" && newPasswordConfirm !== "" && newPasswordConfirm === newPassword) {
      this.setState({ loading: true });

    } else {
      if (!oldPassword)
        this.refs.oldPassword.setInvalid();

      if (!newPassword)
        this.refs.newPassword.setInvalid();

      if (!newPasswordConfirm)
        this.refs.newPasswordConfirm.setInvalid();

      if (newPasswordConfirm !== newPassword) {
        this.refs.newPassword.setInvalid();
        this.refs.newPasswordConfirm.setInvalid();
      }
    }

  }

  render() {
    const {
      loading,
      userID,
      name,
      email,
      company,
      profilePicture,
      changesToApply,
      userData
    } = this.state;
    const { location, t } = this.props;

    // console.log(campaignData);

    return (
      <div className="app screen-campaigns">
        <Helmet>
          <title>{t('SupportAndHelp_Browser_Title')}</title>
        </Helmet>
        {/*loading && (
          <div className="loading-cover">
            <div className="cover"></div>
            <div>
              <Spinner speed={0.8} color="#ffffff" size={20} />
            </div>
          </div>
        )*/}

        <div className="main-wrapper">
          <TopBar ref="topbar" location={location} />
          <div className="screen-content">
            <ContentHeader location={location} title={<T text= "SupportAndHelp_Title" />} account />

            <div className="content-wrapper small">
              {/* <div className="interes-links">
                <a href="https://byalyze.com/tutoriales" target="_blank" className="tutorials">
                  <div>
                    <i className="icon icon-info-circled"></i>
                  </div>
                  <p><T text="SupportAndHelp_Tutorials" /></p>
                </a>
                <a href="https://byalyze.com/faq" target="_blank" className="faq">
                  <div>
                    <i className="icon icon-help-circled"></i>
                  </div>
                  <p><T text="SupportAndHelp_FAC" /></p>
                </a>
              </div> */}

              <h2><T text="SupportAndHelp_Contact" /></h2>
              <div className="content-widget">
                <div className="account-item">
                  <div className="title"><T text="SupportAndHelp_Email" /></div>
                  <div className="value">
                    <a href="mailto:support@alyze.us">support@alyze.us</a>
                  </div>
                </div>
                <div className="account-item">
                  <div className="title"><T text="SupportAndHelp_Realtime_Chat" /></div>
                  <div className="value">
                    <Link main onClick={() => { window.Froged('open','contact') }}><T text="SupportAndHelp_Open_Chat_Button" /></Link>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(SupportAndHelp);
