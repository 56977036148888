import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { Spinner } from 'react-activity';
import { CampaignAccess, AnalysisAccess, UserAccess } from '@sounditi/ft2-api';
import { withTranslation } from 'react-i18next';
import _ from 'underscore';
import ReactTooltip from "react-tooltip";

import ModalDialog from '../components/ModalDialog';
import ModalDialogInfo from '../components/ModalDialogInfo';
import Gender from '../components/audience/Gender';
import AgeRange from '../components/audience/AgeRange';
import TopLocations from '../components/audience/TopLocations';
import WeekInteractions from '../components/audience/WeekInteractions';
import MonthInteractions from '../components/audience/MonthInteractions';
import Link from '../components/Link';
import Input from '../components/Input';
import T from '../components/Translate';
import GlobalSummary from '../components/GlobalSummary';
import PublishTest from '../components/recopilator/PublishTest';

import { getURLParameter, removeURLParameter } from '../utils/global';
import { showNotification } from '../reducers/notifications';
import { setRedirect } from '../reducers/navigation';
import { setTestsData, setUserData } from '../reducers/user';

import { URL_ENVIRONMENT } from '../config/global';
import { URL_PRICING, URL_CAMPAIGNS } from '../config/urls';

const mapStateToProps = (state, ownProps) => ({
  userData: state.user.userData,
  testsData: state.user.testsData,
  isPaidLinks: state.navigation.isPaidLinks,
  isPublicLinks: state.navigation.isPublicLinks,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setUserData: val => dispatch(setUserData(val)),
  setTestsData: val => dispatch(setTestsData(val)),
  setRedirect: val => dispatch(setRedirect(val)),
  showNotification: val => dispatch(showNotification(val)),
  dispatch
})
class RecopilatorLinks extends React.Component {
  _mount = true;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      refreshingData: false,
      loadingLinks: [],
      links: [],
      globalSummary: {},
      name: "",
      url: "",
      lang: "es",
      audience: "",
      dateEnds: "",
      dateStarts: "",
      dataValidate: true,
      campaignData: [],
      changesToApply: false,
      showUnpublishModal: false,
      showAddNewModal: false,
      showEditModal: false,
      showDeleteModal: false,
      modalLoading: false,
      modalDeleteLoading: false,
      newLinkName: "New link",
      editLink: {},
      editLinkName: "",
      deleteLink: {},
      showLinkModal: false,
      typeModalLink: '',
      currentModalLink: {},
      ShowTestsReachedModal: false,
      initialEventsSended: false
    };
  }

  componentWillMount() {
    this.loadData();
    // this.refreshTestsData();
  }

  componentWillUnmount() {
    this._mount = false;
  }

  componentDidUpdate() {
    const { initialEventsSended, campaignData } = this.state;
    const { isPaidLinks, isPublicLinks } = this.props;
    const chosenAudienceType = campaignData?.chosenAudienceType;

    if (this.props.topbar && !initialEventsSended) {
      if (isPublicLinks || chosenAudienceType === 'own') {
        this.props.topbar.sendEvent("control_enter_screen_recopilator_publicLinks", "Recopilator Public Links")
      } else if (isPaidLinks || chosenAudienceType === 'alyze') {
        this.props.topbar.sendEvent("control_enter_screen_recopilator_paidLinks", "Recopilator Paid Links")
      }

      if (this._mount)
        this.setState({ initialEventsSended: true })
    }
  }

  async refreshTestsData() {
    const { setTestsData, dispatch } = this.props;

    try {
      const campaignAccess = new CampaignAccess();
      const refreshedTestsData = await campaignAccess.getCampaigns();

      dispatch(setTestsData(refreshedTestsData));

      const campaignId = getURLParameter("id");
      const campaignData = refreshedTestsData.filter(test => test.campaignId === campaignId)[0];

      this.getTesters(campaignData);
      if (this._mount)
        this.setState({ campaignData });
    } catch (error) { }
  }

  async refreshUserData() {
    const { setUserData } = this.props;
    const userAccess = new UserAccess();
    const refreshedUserData = await userAccess.getUserData();
    setUserData(refreshedUserData);
  }

  async loadData(campaignDataIn = undefined) {
    if (this._mount) {
      const { testsData } = this.props;

      const campaignId = getURLParameter("id");
      const campaignData = campaignDataIn ? campaignDataIn : testsData.filter(test => test.campaignId === campaignId)[0];
      const chosenAudienceType = campaignData?.chosenAudienceType;

      if (!campaignId || !campaignData)
        this.goCampaigns();

      if (campaignData){
        let links = [];
        if (chosenAudienceType) {
          try {
            const campaignAccess = new CampaignAccess();
            links = await campaignAccess.getLinks(campaignId);
          } catch {}
        }

        let globalSummary = {};
        if (chosenAudienceType && campaignData.features.publicAudienceStatistics) {
          globalSummary = campaignData.globalSummary;
        }

        if (this._mount)
          this.setState({
            campaignData,
            links,
            globalSummary,
            changesToApply: false,
            loading: false
          });
      }
    }
  }

  validateData() {
    if (this._mount)
      this.setState({ dataValidated: true, changesToApply: true });
  }

  onInputChange(val) {
    if (this._mount)
      this.setState(val, () => {
        this.validateData();
      });
  }

  goCampaigns() {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_CAMPAIGNS });
  }

  copyToClipboard(text) {
    const { showNotification } = this.props;

    let tempInput = document.createElement("input");
    tempInput.value = text;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);

    window.Froged("track", "onCopyLink");

    showNotification("copyClipboard");
  }

  openEditLink(editLink) {
    if (this._mount)
      this.setState({ editLink, showEditModal: true, editLinkName: editLink.alias });
  }

  openNewLink() {
    if (this._mount)
      this.setState({ newLinkName: "New link", showAddNewModal: true });
  }

  async onToggleActive(link) {
    const { campaignData, loadingLinks } = this.state;
    const updatedLink = { ...link, active: !link.active };

    if (['draft', 'ready', 'processing'].includes(campaignData.status))
      return false;

    const links = this.state.links.map(currentLinks => {
      if (currentLinks.linkId === link.linkId)
        return updatedLink;

      return currentLinks;
    });

    try {
      loadingLinks.push(link.linkId);
      
      if (this._mount)
        this.setState({ modalLoading: true, loadingLinks, links });

      const campaignAccess = new CampaignAccess();
      await campaignAccess.updateLink(updatedLink);

      await this.loadData();
    } catch(error) {
      showNotification("genericError");
    }
    
    const itemIndex = loadingLinks.indexOf(link.linkId);
    if (itemIndex > -1) {
      loadingLinks.splice(itemIndex, 1); 
    }

    if (this._mount)
      this.setState({ modalLoading: false, loadingLinks });
  }

  async saveLink() {
    const { editLink, editLinkName } = this.state;

    try {
      if (this._mount)
        this.setState({ modalLoading: true });

      const updatedLink = { ...editLink, alias: editLinkName };

      const campaignAccess = new CampaignAccess();
      await campaignAccess.updateLink(updatedLink);

      await this.loadData();
    } catch(error) {
      showNotification("genericError");
    }

    if (this._mount)
      this.setState({ modalLoading: false, showEditModal: false });
  }

  async createLink() {
    const { newLinkName, campaignData } = this.state;
    const { showNotification } = this.props;

    try {
      if (this._mount)
        this.setState({ modalLoading: true });

      const newLink = {
        active: !['draft', 'ready', 'processing'].includes(campaignData.status),
        alias: newLinkName,
        campaignId: campaignData.campaignId,
        isLink: true
      };

      const campaignAccess = new CampaignAccess();
      await campaignAccess.createLink(newLink);

      await this.loadData();
      this.refreshUserData();

      if (this.props.topbar)
        this.props.topbar.sendEvent("control_create_public_link", "Recopilator", { campaignId: campaignData.campaignId })
    } catch(error) {
      showNotification("genericError");
    }

    if (this._mount)
      this.setState({ modalLoading: false, showAddNewModal: false, newLinkName: "" });
  }

  async deleteLink() {
    const { showNotification } = this.props;
    const { deleteLink, campaignData } = this.state;

    try {
      if (this._mount)
        this.setState({ modalDeleteLoading: true });

      const campaignAccess = new CampaignAccess();
      await campaignAccess.deleteLink(deleteLink);

      await this.loadData();
      this.refreshUserData();

      showNotification("linkDeletedSuccessfully");

      if (this.props.topbar)
        this.props.topbar.sendEvent("control_delete_public_link", "Recopilator", { campaignId: campaignData.campaignId })
    } catch(error) {
      showNotification("genericError");
    }

    if (this._mount)
      this.setState({ modalDeleteLoading: false, showDeleteModal: false });
  }

  showModalLink(type, link) {
    if (this._mount)
      this.setState({ showLinkModal: true, typeModalLink: type, currentModalLink: link });
  }

  closeModalLink() {
    if (this._mount)
      this.setState({ showLinkModal: false, typeModalLink: '', currentModalLink: {} });
  }

  goPricing() {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_PRICING });
  }

  render() {
    const {
      loading,
      refreshingData,
      loadingLinks,
      campaignData,
      links,
      globalSummary,
      showAddNewModal,
      showEditModal,
      modalLoading,
      newLinkName,
      editLinkName,
      showLinkModal,
      typeModalLink,
      currentModalLink,
    } = this.state;
    const { t, onShowAdquisitionMethod } = this.props;
    const chosenAudienceType = campaignData?.chosenAudienceType;

    // console.log(campaignData)

    const checkData = (object, value) => {
      if (object
        && object.end_w_correct_interactions_details
        && object.end_w_correct_interactions_details[value]
        && object.end_w_correct_interactions_details[value] !== '') {
          return object.end_w_correct_interactions_details[value];
        } else {
          return value === 'number_of_testers' ? '0' : '-';
        }
    }

    const checkLocation = (object) => {
      let location = '-';
      let locationCity = '';
      let locationCountry = '';

      if (object && object.end_w_correct_interactions_details ) {
        if (
          object.end_w_correct_interactions_details.predominant_location_city
          && object.end_w_correct_interactions_details.predominant_location_city !== ''
        ) {
          locationCity = object.end_w_correct_interactions_details.predominant_location_city;
        }

        if (
          object.end_w_correct_interactions_details.predominant_location_country
          && object.end_w_correct_interactions_details.predominant_location_country !== ''
        ) {
          locationCountry = object.end_w_correct_interactions_details.predominant_location_country;
          locationCountry = locationCity !== '' ? `(${locationCountry})` : locationCountry;
        }

        if (locationCity !== '' || locationCountry !== '') {
          location = `${locationCity} ${locationCountry}`.trim();
        }
      }
      return location;
    }

    const renderLinks = links.filter(link => link.isLink === true).map(link => {
      const url = `${URL_ENVIRONMENT}/${link.linkUrl}`;
      const locationLinks = checkLocation(link.linkStatistics);

      let toggleActiveTooltip = link.active ? 'Desactivar enlace' : 'Activar enlace';

      return (
        <div className={`content-widget link ${(link.active && loadingLinks.indexOf(link.linkId) === -1) ? "active" : "disabled"}`} key={link.linkId}>
          {loadingLinks.indexOf(link.linkId) !== -1 && (
            <div className="loading-cover">
              <div>
                <Spinner speed={0.8} color="#00C7E7" size={20} />
              </div>
            </div>
          )}
          <div className="alias">
            <div className="alias-text">
              <div className="alias-name" onClick={() => this.openEditLink(link)}>
                <span onClick={() => this.openEditLink(link)} ref="alias">{link.alias}</span>
                <i className="icon icon-pencil-1"></i>
              </div>
              {/*<div className="alias-buttons" onClick={() => this.setState({ showDeleteModal: true, deleteLink: link })}>
                <i className="icon icon-trash-1"></i>
              </div>*/}
            </div>
            <div className="active">
              <div className="label"><T text="Recopilator_Links_Toggle" /></div>
                <div 
                  className={link.active ? 'button-toggle active' : 'button-toggle'} 
                  onClick={() => this.onToggleActive(link)}
                  data-tip={toggleActiveTooltip}
                >
                <div className="handle"></div>
              </div>
            </div>
          </div>
          <div className="link">
            <i className="icon icon-link-1"></i>
            <input
              placeholder="..."
              readOnly
              name="linkUrl"
              value={url}
              ref="linkUrl"
              onChange={val => () => {}}
            />
            <Link
              secondary
              onClick={() => this.copyToClipboard(url)}
            >
              <T text="Recopilator_Links_Copy" />
            </Link>
          </div>
          <div className="metrics">
            <div className="col featured">
              <div className="title"><T text="Recopilator_Metrics_Responses" /></div>
              <div className="value">{checkData(link.linkStatistics, 'number_of_testers')}</div>
            </div>
            <div className="col" onClick={() => this.showModalLink('locations', link)}>
              <div className="title"><T text="Recopilator_Metrics_Location" /></div>
              <div className={`value ${locationLinks !== '-' ? "top-location" : ""}`}>{locationLinks}</div>
            </div>
            <div className="col" onClick={() => this.showModalLink('gender', link)}>
              <div className="title"><T text="Recopilator_Metrics_Genre" /></div>
            <div className="value">{checkData(link.linkStatistics, 'predominant_gender')}</div>
            </div>
            <div className="col" onClick={() => this.showModalLink('ages', link)}>
              <div className="title"><T text="Recopilator_Metrics_Age" /></div>
            <div className="value">{checkData(link.linkStatistics, 'predominant_age_track')}</div>
            </div>
          </div>
        </div>
      );
    });

    // console.log(campaignData)
    // console.log(campaignData?.data?.segments)

    const renderLinksPaid = campaignData?.data?.segments.map(link => {
      const totalPaidResponses = link?.numberOfPeople;
      const genre = link?.gender;
      const country = link?.city !== "all" ? `${link?.city} (${link?.country})` : link?.country;
      // const country_truncated = country?.length && country.length > 30 ? country.slice(0,30).trim().concat('...') : country;
      const fromAge = link?.fromAge;
      const toAge = link?.toAge;

      let age = `${fromAge} - ${toAge}`;
      if (fromAge === toAge) {
        age = fromAge;
      }

      return (
        <div className={`content-widget link link-paid active`} key={link.linkId}>
          {loadingLinks.indexOf(link.linkId) !== -1 && (
            <div className="loading-cover">
              <div>
                <Spinner speed={0.8} color="#00C7E7" size={20} />
              </div>
            </div>
          )}
          <div className="alias">
            <div className="alias-text">
              <div className="alias-name">
                <span ref="alias">{link.segmentName}</span>
              </div>
              {/*<div className="alias-buttons" onClick={() => this.setState({ showDeleteModal: true, deleteLink: link })}>
                <i className="icon icon-trash-1"></i>
              </div>*/}
            </div>
          </div>
          <div className="metrics">
            <div className="col featured">
              <div className="title"><T text="Recopilator_Metrics_Responses" /></div>
              <div className="value">{link.numberOfPeopleThatHasParticipated} <span>/ {totalPaidResponses}</span></div>
            </div>
            <div className="col">
              <div className="title"><T text="Recopilator_Links_Paid_Location" /></div>
              <div className={`value top-location`}>{country}</div>
            </div>
            <div className="col">
              <div className="title"><T text="Recopilator_Links_Paid_Genre" /></div>
            <div className="value">{genre}</div>
            </div>
            <div className="col">
              <div className="title"><T text="Recopilator_Links_Paid_Age" /></div>
            <div className="value">{age}</div>
            </div>
          </div>
        </div>
      );
    });

    return (
      <>
        <ReactTooltip place="bottom" effect="solid" backgroundColor="#f08c1e" />
        <ModalDialog
          name="Recopilator_Add_Link"
          showModal={showAddNewModal}
          loading={modalLoading}
          closeModal={e => { this.setState({ showAddNewModal: false }) }}
          onConfirm={() => { this.createLink() }}
        >
          <Input
            readOnly={modalLoading}
            placeholder=""
            name="newLinkName"
            label={t('Recopilator_Add_Link_Input_Name')}
            value={newLinkName}
            ref="newLinkName"
            onChange={val => this.onInputChange(val)}
          />
        </ModalDialog>
        <ModalDialog
          name="Recopilator_Edit_Link"
          showModal={showEditModal}
          loading={modalLoading}
          closeModal={e => { this.setState({ showEditModal: false }) }}
          onConfirm={() => { this.saveLink() }}
        >
          <Input
            readOnly={modalLoading}
            placeholder=""
            name="editLinkName"
            label={t('Recopilator_Edit_Link_Input_Name')}
            value={editLinkName}
            ref="editLinkName"
            onChange={val => this.onInputChange(val)}
          />
        </ModalDialog>

        {loading && (
          <div className="loading-cover have-topbar">
            {/* <div className="cover design"></div> */}
            <div>
              <Spinner speed={0.8} color="#00C7E7" size={20} />
            </div>
          </div>
        )}  
        
        {!loading && (
          <>
            <PublishTest 
              onRefreshTestData={campaignData => this.loadData(campaignData)}
              onShowAdquisitionMethod={() => onShowAdquisitionMethod()} 
              campaignData={campaignData} 
            />
            <div className={`content-wrapper two-columns recopilator medium`}>
              {campaignData.status === 'processing' && (
                <div className="message-warning processing-test-warning">
                  <i className="icon icon-warning-empty"></i>
                  <div>
                    <h3>
                      <T text="Recopilator_Status_Processing_Warning_Title" />
                      <i className="icon icon-spin1 animate-spin"></i>
                    </h3>
                    <p><T text="Recopilator_Status_Processing_Warning_Text" /></p>
                  </div>
                </div>
              )}
            </div>
            <div className={`content-wrapper two-columns recopilator medium ${['draft', 'ready', 'processing'].includes(campaignData.status) ? 'draft' : ''}`}>
              <div className='content-column'>
                <ModalDialogInfo
                  name="Gender"
                  nameElement={currentModalLink.alias}
                  showModal={showLinkModal && typeModalLink == 'gender'}
                  closeModal={() => this.closeModalLink()}
                >
                  <Gender
                    ref="gender"
                    currentModalLink={currentModalLink}
                  />
                </ModalDialogInfo>
                <ModalDialogInfo
                  name="AgeRange"
                  nameElement={currentModalLink.alias}
                  showModal={showLinkModal && typeModalLink == 'ages'}
                  closeModal={() => this.closeModalLink()}
                >
                  <AgeRange
                    ref="ageRange"
                    currentModalLink={currentModalLink}
                  />
                </ModalDialogInfo>
                <ModalDialogInfo
                  name="TopLocations"
                  nameElement={currentModalLink.alias}
                  showModal={showLinkModal && typeModalLink == 'locations'}
                  closeModal={() => this.closeModalLink()}
                >
                  <TopLocations
                    ref="topLocations"
                    currentModalLink={currentModalLink}
                  />
                </ModalDialogInfo>

                {!['draft', 'ready', 'processing'].includes(campaignData.status) &&
                  <GlobalSummary
                    globalSummary={globalSummary}
                    campaignData={campaignData}
                  />
                }
                {chosenAudienceType === 'own' && (
                  <>
                    <h2><T text="Recopilator_Links_Title" /></h2> 
                    <p className='text-with-contact-link'><T text="Recopilator_Links_InnerText" var1="window.Froged('open','contact')" /></p>
                    {renderLinks}
                    <div className="content-widget link add-new" onClick={() => this.openNewLink()}>
                      <div className="add-new-wrapper">
                        <div className="add-new-label">
                          <div className="icon-wrapper">
                            <i className="icon icon-plus-1"></i>
                          </div>
                          <div className="label"><T text="Recopilator_Links_Add" /></div>
                        </div>
                        <div className="alias">
                        <div className="alias-text">
                          <div className="alias-name">
                            <span>Link name ...</span>
                          </div>
                          </div>
                          <div className="active">
                            <div className="label"><T text="Recopilator_Links_Toggle" /></div>
                              <div className="button-toggle active">
                              <div className="handle"></div>
                            </div>
                          </div>
                        </div>
                        <div className="link">
                          <i className="icon icon-link-1"></i>
                          <input value="https:// ..." onChange={e => {}} />
                          <Link main><T text="Recopilator_Links_Copy" /></Link>
                        </div>
                        <div className="metrics">
                          <div className="col featured">
                          </div>
                          <div className="col">
                          </div>
                          <div className="col">
                          </div>
                          <div className="col">
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {chosenAudienceType === 'alyze' && (
                  <>
                    <h2><T text="Recopilator_Links_Paid_Title" /></h2>
                    <p><T text="Recopilator_Links_Paid_Text" /></p>
                    {renderLinksPaid}
                  </>
                )}
              </div>
              <div className='ads-column right-column'>
                <div className='content-widget contact-us'>
                  <div className='image'>
                    <img src="../../assets/img/contact-us.png" />
                  </div>
                  <h3><T text="ContactUsWidget_Title" /></h3>
                  <p><T text="ContactUsWidget_Text" /></p>

                  <Link main className="featured" onClick={() => window.Froged('open','contact')}>
                    <span><T text="ContactUsWidget_Button_Contact_Us" /></span>
                  </Link>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(RecopilatorLinks);
