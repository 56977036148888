import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { Spinner } from 'react-activity';
import { CampaignAccess } from '@sounditi/ft2-api';
import Dialog from 'rc-dialog';
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next';

import { getURLParameter } from '../../utils/global';
import { showNotification } from '../../reducers/notifications';
import { setRedirect, showVersionControl, setLoadingAutoSave } from '../../reducers/navigation';
import { setTestsData, setTemporalTestsData } from '../../reducers/user';

import T from '../../components/Translate';
import Link from '../../components/Link';
import Input from '../../components/Input';
import Select from '../../components/Select';
import Sidebar from '../../components/Sidebar';
import Tabs from '../../components/Tabs';
import UserInfo from '../../components/UserInfo';
import DesignMenu from '../../components/DesignMenu';
import ContentHeader from '../../components/ContentHeader';
import TopBar from '../../components/TopBar';
import VersionControl from '../../components/VersionControl';
import ModalDialog from '../../components/ModalDialog';

import { newCampaignTemplateES } from '../../config/campaignTemplates';
import { newCampaignTemplateEN } from '../../config/campaignTemplates';
import { URL_ENVIRONMENT } from '../../config/global';

const mapStateToProps = (state, ownProps) => ({
  userData: state.user.userData,
  testsData: state.user.testsData,
  temporalTestsData: state.user.temporalTestsData,
  openVersionControl: state.navigation.openVersionControl,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setTestsData: val => dispatch(setTestsData(val)),
  setRedirect: val => dispatch(setRedirect(val)),
  showVersionControl: val => dispatch(showVersionControl(val)),
  showNotification: val => dispatch(showNotification(val)),
  setTemporalTestsData: val => dispatch(setTemporalTestsData(val)),
  setLoadingAutoSave: val => dispatch(setLoadingAutoSave(val)),
  dispatch
})

class Configuration extends React.Component {
  _mount = true;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      name: "",
      url: "",
      lang: "en",
      audience: "",
      dateEnds: "",
      dateStarts: "",
      dataValidate: true,
      campaignData: [],
      campaignDataSaved: [],
      changesToApply: false,
      showUnpublishModal: false,
      showLangModal: false,
      newLang: "en",
      langModalLoading: false
    };
  }

  componentWillMount() {
    this.loadData();
  }

  componentDidMount() {
    if (this.refs.topbar)
      this.refs.topbar.sendEvent("control_enter_screen_configuration", "Configuration")
  }

  componentWillUnmount() {
    this._mount = false;
  }

  componentDidUpdate() {

  }

  async refreshTestsData(updatedDate=false) {
    const { setTestsData, setTemporalTestsData } = this.props;
    const campaignId = getURLParameter("id");
    const campaignAccess = new CampaignAccess();
    const refreshedTestsData = await campaignAccess.getCampaigns();

    const refreshedCurrentTest = Object.assign({}, refreshedTestsData.find(test => test.campaignId === campaignId));
    const refreshedTemporalTestsData = this.props.temporalTestsData.map(test => {
      let testCopy = Object.assign({}, test);
      if (test.campaignId === campaignId) {
        testCopy = refreshedCurrentTest;
        testCopy.data = updatedDate ? { ...test.data, updatedAt: updatedDate } : test.data;
      }
      return testCopy;
    });

    setTemporalTestsData(refreshedTemporalTestsData);
    setTestsData(refreshedTestsData);
  }

  async loadData() {
    if (this._mount) {
      const { testsData, temporalTestsData } = this.props;

      const campaignId = getURLParameter("id");
      const campaignDataSaved = testsData.filter(test => test.campaignId === campaignId)[0];
      const campaignData = temporalTestsData.find(test => test.campaignId === campaignId);

      if (!campaignId || !campaignData)
        this.goCampaigns();

      if (campaignData){
        const name = campaignData.data.name || "";
        const lang = campaignData.data.lang || "";
        const url = campaignData.data.url || "";
        const audience = campaignData.data.audience || "";
        const dateEnds = campaignData.data.dateEnds || "";
        const dateStarts = campaignData.data.dateStarts || "";
        const status = campaignData.data.status || "";

        this.setState({
          campaignData,
          campaignDataSaved,
          name,
          lang,
          audience,
          url,
          dateEnds,
          dateStarts,
          status,
          changesToApply: false
        }, () => {
          if (this._mount) {
            if (this.refs.lang)
              this.refs.lang.refresh();

            if (this.refs.name)
              this.refs.name.refresh();

            if (this.refs.status)
              this.refs.status.refresh();

            if (this.refs.url)
              this.refs.url.refresh();

            if (this.refs.audience)
              this.refs.audience.refresh();

            if (this.refs.dateEnds)
              this.refs.dateEnds.refresh();

            if (this.refs.dateStarts)
              this.refs.dateStarts.refresh();

            this.refreshTestsData();
          }
        });

        setTimeout(() => {
          this.setState({ loading: false });
        }, 1000);
      }
    }
  }

  async modifyTestDataTemporal() {
    const { showNotification, setTemporalTestsData, temporalTestsData, setLoadingAutoSave } = this.props;
    const {
      name,
      lang,
      campaignData
    } = this.state;
    const { campaignId, data } = campaignData;

    const isLangChanged = lang !== data.lang;
    const campaignTemplates = lang === "es" ? { ...newCampaignTemplateES } : { ...newCampaignTemplateEN };

    const newData = { ...data, name, lang };
    if (isLangChanged) {
      newData.modules = {
        ...newData['modules'],
        coreModulesPre: campaignTemplates.modules.coreModulesPre,
        coreModulesPost: campaignTemplates.modules.coreModulesPost
      }
    }

    const temporalTestsDataUpdated = temporalTestsData.map(test => {
      const testCopy = Object.assign({}, test);
      if (test.campaignId === campaignId) {
        testCopy.data = newData;
      }
      return testCopy;
    });

    setTemporalTestsData(temporalTestsDataUpdated);

    if (isLangChanged) {
      setLoadingAutoSave(true);
      this.setState({ loading: true });

      try {
        const campaignAccess = new CampaignAccess();
        const response = await campaignAccess.updateCampaign(campaignId, {...newData, updatedAt: Date.now()});
        await this.refreshTestsData(response.data.updatedAt);
        await this.loadData();

        const iframe = document.getElementById('iframepreview');
        const content = iframe.innerHTML;
        iframe.innerHTML = content;

        this.setState({ langModalLoading: false, showLangModal: false });
        setLoadingAutoSave(false);
      } catch(error) {
        this.setState({ langModalLoading: false, showLangModal: false, loading: false });
        setLoadingAutoSave(false);
        showNotification("genericError");
      }
    }
  }

  /*
  async modifyCampaign(callback) {
    const { showNotification, setTestsData, dispatch, testsData } = this.props;
    const {
      name,
      lang,
      campaignData
    } = this.state;
    const { campaignId, data } = campaignData;

    const campaignTemplates = lang === "es" ? { ...newCampaignTemplateES } : { ...newCampaignTemplateEN };

    const newData = { ...data, name, lang };

    if (lang !== data.lang) {
      newData.modules = {
        ...newData['modules'],
        coreModulesPre: campaignTemplates.modules.coreModulesPre,
        coreModulesPost: campaignTemplates.modules.coreModulesPost
      }
    }

    try {
      this.setState({ loading: true });

      const campaignAccess = new CampaignAccess();
      await campaignAccess.updateCampaign(campaignId, newData);

      const updatedTestsData = testsData.map(test => {
        if (test.campaignId === campaignId) {
          test.data = newData;
        }

        return test;
      });

      dispatch(setTestsData(updatedTestsData)).then(()=>{
        this.loadData();

        if (callback)
          callback();
      })
    } catch(error) {
      this.setState({ loading: false });
      showNotification("genericError");
    }
  }
  */

  validateData() {
    this.setState({ dataValidated: true, changesToApply: true });
  }


  onInputChange(val) {
    this.setState(val, ()=>{
      this.validateData();
      this.modifyTestDataTemporal();
    });
  }

  goCampaigns() {
    const { setRedirect } = this.props;
    const link = `/tests/`;
    setRedirect({ route: link });
  }

  goDesign() {
    const { setRedirect } = this.props;
    const campaignId = getURLParameter("id");
    const link = `/tests/design?id=${campaignId}`;
    setRedirect({ route: link });
  }

  nextStep() {
    this.modifyTestDataTemporal(() => this.goDesign());
  }

  getPreview() {
    const {
      campaignData,
      lang
    } = this.state;
    const { campaignId, userId } = campaignData;

    const jwt = require('jwt-simple');
    const payload = {
      "sub": userId,
      "name": "",
      "iat": 0,
      "iss": "",
      "aud": "",
      "exp": 0,
      "forcePreview": true,
      "campaignId": campaignId
    };
    const secret = "super-tester-secret"
    const token = jwt.encode(payload, secret);

    const link = `${URL_ENVIRONMENT}?id=${campaignId}&token=${token}&s=core-landing&l=${lang}&livepreview=false&nc=true`;
    return link;

    // const win = window.open(link, '_blank');
    // win.focus();
  }

  changeCampaignVersionCallback = (data) => {
    const { showVersionControl } = this.props;

    showVersionControl(false);

    this.setState({
      name: data.name, 
      lang: data.lang,
      changesToApply: true
    }, () => {
      if (this._mount) {
        if (this.refs.lang)
          this.refs.lang.refresh();

        if (this.refs.name)
          this.refs.name.refresh();

        this.refreshTestsData();
      }
    });
  }

  closeLangModal() {
    if (this.refs.lang) {
      this.refs.lang.refresh();
    }
    this.setState({ showLangModal: false });
  }

  render() {
    const {
      loading,
      name,
      audience,
      dateEnds,
      dateStarts,
      url,
      lang,
      campaignData,
      campaignDataSaved,
      changesToApply,
      showUnpublishModal,
      showLangModal,
      newLang,
      langModalLoading
    } = this.state;
    const { location, t, openVersionControl } = this.props;

    const textSaveButton = changesToApply ? t('Configuration_Btn_Save_Changes') : t('Configuration_Btn_Saved');
    const isCampaignPaid = campaignData?.status === 'public' || (campaignData?.status === 'processing' && campaignData?.price !== undefined);

    // console.log(campaignData);

    let classVersionControl = "version-panel";
    if (openVersionControl)
      classVersionControl = `${classVersionControl} version-panel-open`;

    return (
      <div className="app screen-campaigns">
        <Helmet>
          <title>{t('Configuration_Browser_Title')}</title>
        </Helmet>
        <ModalDialog
          name="Configuration_Change_Lang"
          showModal={showLangModal}
          loading={langModalLoading}
          closeModal={() => { this.closeLangModal() }}
          onCancel={() => { this.closeLangModal() }}
          onConfirm={() => { this.onInputChange(newLang); this.setState({ langModalLoading: true }) }}
        >
          <div>
            <p><T text="Configuration_Change_Lang_Modal_Text" /></p>
            <ul>
              <li><T text="Modules_Title_Landing" /></li>
              <li><T text="Modules_Title_Introduction" /></li>
              <li><T text="Modules_Title_Finish" /></li>
            </ul>
            <p><T text="Configuration_Change_Lang_Modal_Text2" /></p>
          </div>
        </ModalDialog>
        <div className="main-wrapper">
          <TopBar ref="topbar" location={location} />
          {/*
          <div className={classVersionControl}>
            <VersionControl
              campaignData={campaignData}
              changeCampaignVersionCallback={this.changeCampaignVersionCallback}
            />
          </div>
          */}
          <div className="screen-content modules">
            <ContentHeader
              location={location}
              campaignData={campaignDataSaved}
              changesToApply={changesToApply}
              onClickRevert={() => this.loadData()}
              onClickApply={() => this.modifyTestDataTemporal()}
              haveDesignChanges={true}
            />
            <DesignMenu location={location} campaignData={campaignData} />
            <div className="section-modules">
              <div className="section-title">
                <h2><T text="Configuration_Title" /></h2>
                <p><T text="Configuration_Subtitle" /></p>
              </div>
              <div className="design-options sidebar-options">
                <Input
                  placeholder="..."
                  name="name"
                  label={t('Configuration_Input_Label_Name')}
                  value={name}
                  ref="name"
                  onChange={val => this.onInputChange(val)}
                />
                <Select
                  name="lang"
                  label={t('Configuration_Input_Label_Language')}
                  value={lang}
                  ref="lang"
                  onChange={val => this.setState({ newLang: val, showLangModal: true })}
                  options={[
                    { "value": "en", "label": t('Configuration_Input_Label_Language_EN') },
                    { "value": "es", "label": t('Configuration_Input_Label_Language_ES') },
                  ]}
                  disabled={isCampaignPaid}
                />
              </div>
            </div>
            <div className="screen-content-inner">
              {/*
              <div className="content-wrapper first"></div>
                <div className="row two-columns">
                  <div className="col">
                    <div className="content-widget">
                      <h2 className="widget-title"><T text="Configuration_Widget_Title_General" /></h2>

                      <Input
                        placeholder="..."
                        icon="icon-tag-6"
                        name="name"
                        label={t('Configuration_Input_Label_Name')}
                        value={name}
                        ref="name"
                        onChange={val => this.onInputChange(val)}
                      />

                      <Input
                        placeholder="..."
                        icon="icon-target-4"
                        name="audience"
                        label={t('Configuration_Input_Label_Audience_Limit')}
                        value={audience}
                        ref="audience"
                        type="text"
                        pattern="[0-9]*"
                        onChange={val => this.onInputChange(val)}
                      />

                      <Select
                        name="lang"
                        label={t('Configuration_Input_Label_Language')}
                        value={lang}
                        ref="lang"
                        onChange={val => this.onInputChange(val)}
                        options={[
                          { "value": "en", "label": t('Configuration_Input_Label_Language_EN') },
                          { "value": "es", "label": t('Configuration_Input_Label_Language_ES') },
                        ]}
                      />

                      <Input
                        readOnly
                        disabled
                        placeholder="..."
                        text=".byalyze.com"
                        name="url"
                        label={t('Configuration_Input_Label_Custom_Campaign_Link')}
                        value={url}
                        ref="url"
                        onChange={val => this.onInputChange(val)}
                      />

                      <div className="field disabled">
                        <label><T text="Configuration_Input_Label_Schedule" /></label>
                        <div className="row">
                          <div className="col">
                            <Input
                              readOnly
                              placeholder="..."
                              icon="icon-calendar-7"
                              name="dateStarts"
                              label={t('Configuration_Input_Label_Starts')}
                              value={dateStarts}
                              ref="dateStarts"
                              onChange={val => this.onInputChange(val)}
                            />
                          </div>
                          <div className="col">
                            <Input
                              readOnly
                              placeholder="..."
                              icon="icon-calendar-7"
                              name="dateEnds"
                              label={t('Configuration_Input_Label_Ends')}
                              value={dateEnds}
                              ref="dateEnds"
                              onChange={val => this.onInputChange(val)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {(campaignData.status === "draft" ||
                      campaignData.status === "preview") && (
                      <div className="right-content right-bottom-content">
                        <Link main onClick={() => { this.nextStep() }}>
                          <T text="Configuration_Btn_Next_Step" />
                          <i className="icon icon-right-open-4"></i>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              */}

              {loading && (
                <div className="loading-cover">
                  <div className="cover design"></div>
                  <div>
                    <Spinner speed={0.8} color="#00C7E7" size={20} />
                  </div>
                </div>
              )}
              {/* <iframe id="iframepreview" title="preview" frameBorder="0" src={this.getPreview()}
                onLoad={() => setTimeout(() => this.setState({ loading: false }), 500)}
              /> */}
              <object id="iframepreview" data={this.getPreview()}>
                Your browser don't support this feature. Please use the lastest version of Google Chrome.
              </object>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(Configuration);
