import { combineReducers } from 'redux'
import auth from './auth'
import navigation from './navigation'
import user from './user'
import notifications from './notifications'
import tabs from './tabs'

const experienceApp = combineReducers({
  auth,
  navigation,
  user,
  notifications,
  tabs,
})

export default experienceApp