import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux';
import promise from 'redux-promise';
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/react';
import experienceApp from './reducers'
import App from './App';
import "./i18n";
import * as serviceWorker from './serviceWorker';
import history from "./history";

import { ENVIRONMENT } from './config/global';
import { isLocalhost } from './utils/global';

// if (ENVIRONMENT === "production") {

// }

let store = createStore(experienceApp, applyMiddleware(thunk, promise));

history.listen((location) => {
  const page = location.pathname + location.search;
  window.gtag('config', 'UA-171846092-2', {
    'page_title': document.title,
    'page_path': page
  });
});

if (!isLocalhost())
  Sentry.init({
    dsn: "https://f6e9fdf089d24265b300d815b5ff4b63@o427254.ingest.sentry.io/5503181",
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    environment: ENVIRONMENT,
  });

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App history={history} />
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
