import { SounditiAuth as AuthApi } from '@sounditi/ft2-api';

const initState = {
  api: new AuthApi('https://8kf72e54kl.execute-api.eu-central-1.amazonaws.com/prod')
};

const auth = (state = initState, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default auth