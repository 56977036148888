export default {
    translations: {
        'Inputs_Action_Copy_Clipboard': 'Copy to clipboard',
        /* PolicyCheck */
        'PolicyCheck_Text_1': 'I have read and agree to the ',
        'PolicyCheck_Link_Privacy': 'privacy policy',
        'PolicyCheck_Text_2': ' and ',
        'PolicyCheck_Link_Terms': 'terms of use',
        /* Configuration */
        'Configuration_Browser_Title': 'Alyze | General - Test Configuration',
        'Configuration_Title': 'Settings',
        'Configuration_Subtitle': 'Setup your test\'s name and your audience\'s login language',
        'Configuration_Btn_Revert_Changes': 'Revert changes',
        'Configuration_Btn_Save_Changes': 'Save changes',
        'Configuration_Btn_Saved': 'Saved',
        'Configuration_Btn_Next_Step': 'Next step',
        'Configuration_Widget_Title_General': 'General',
        'Configuration_Input_Label_Name': 'Name',
        'Configuration_Input_Label_Audience_Limit': 'Audience limit',
        'Configuration_Input_Label_Language': 'Audience\'s language',
        'Configuration_Input_Label_Custom_Campaign_Link': 'Custom Test Link',
        'Configuration_Input_Label_Schedule': 'Calendar',
        'Configuration_Input_Label_Starts': 'Start',
        'Configuration_Input_Label_Ends': 'End',
        'Configuration_Input_Label_Language_ES': 'Spanish',
        'Configuration_Input_Label_Language_EN': 'English',
        'Configuration_Change_Lang_Modal_Title': 'Change your audience\'s language',
        'Configuration_Change_Lang_Modal_Subtitle': 'The current texts will be overwritten',
        'Configuration_Change_Lang_Modal_Cancel': 'Cancel',
        'Configuration_Change_Lang_Modal_Confirm': 'Yes, change language',
        'Configuration_Change_Lang_Modal_Text': 'The texts of the following modules will be overwritten:',
        'Configuration_Change_Lang_Modal_Text2': 'These texts will be replaced by the default texts of the language you choose.',
        /* AgeRange */
        'AgeRange_Title': 'Age range',
        'AgeRange_Left_Legend': 'interactions',
        'AgeRange_Bottom_Legend': 'Age range',
        'AgeRange_Modal_Info_Title': 'Average age',
        'AgeRange_Modal_Info_Subtitle': 'Percentage of users by age range',
        /* Gender */
        'Gender_Title': 'Gender',
        'Gender_Bottom_Legend': 'interactions',
        'Gender_Left_Legend': 'gender identity range',
        'Gender_Male': 'Male',
        'Gender_Female': 'Female',
        'Gender_No_binaire': 'Prefer not to answer',
        'Gender_Modal_Info_Title': 'Top Gender',
        'Gender_Modal_Info_Subtitle': 'Percentage of users by gender',
        /* GlobalSummary */
        'GlobalSummary_Title': 'Global Summary',
        'GlobalSummary_Label_Interactions': 'Interactions',
        'GlobalSummary_Label_Users': 'Users',
        'GlobalSummary_Label_Top_Location': 'Top Location',
        'GlobalSummary_Label_Top_Genre': 'Top Genre',
        'GlobalSummary_Label_Average_Age': 'Average Age',
        'GlobalSummary_Label_Audience_Percentage': 'of your audience\'s age is between',
        /* MonthInteractions */
        'MonthInteractions_Title': 'Interactions in the last 30 days',
        'MonthInteractions_Bottom_Legend': 'days of month',
        'MonthInteractions_Left_Legend': 'interactions',
        /* TopLocations */
        'TopLocations_Title': 'Top Locations',
        'TopLocations_Left_Legend': 'interactions',
        'TopLocations_Bottom_Legend': 'Locations',
        'TopLocations_Modal_Info_Title': 'Top Locations',
        'TopLocations_Modal_Info_Subtitle': 'Percentage of users by city',
        /* WeekInteractions */
        'WeekInteractions_Title': 'Weekly Interactions',
        'WeekInteractions_Bottom_Legend': 'weekdays',
        'WeekInteractions_Left_Legend': 'interactions',
        /* Responses obtained */
        'Responses_Modal_Info_Title': 'Responses obtained',
        'Responses_Modal_Info_Subtitle': 'User interactions per week and per month',
        /* Metrics */
        'Metrics_Total_Activation': 'Total activation',
        'Metrics_Activation': 'Activation',
        'Metrics_Engagement': 'Engagement',
        'Metrics_Interest': 'Interest',
        'Metrics_Satisfaction': 'Satisfaction',
        'Metrics_Audio_Lowercase': 'audio',
        'Metrics_vídeo_Lowercase': 'video',
        'Metrics_Image_Lowercase': 'image',
        'Metrics_QA_Lowercase': 'Q&A',
        'Metrics_Audio_Capital_Letter': 'Audio',
        'Metrics_Video_Capital_Letter': 'Video',
        'Metrics_Large_Video_Capital_Letter': 'Film',
        'Metrics_Image_Capital_Letter': 'Image',
        'Metrics_QA_Capital_Letter': 'Q&A',
        'Metrics_All_Capital_Letter': 'All of them',
        'Metrics_Time_Point': 'time',
        'Metrics_Duration': 'duration',
        'Metrics_Legend_Time': 'time (seconds)',
        'Metrics_Legend_Answers': 'responses',
        'Metrics_Legend_Answered': 'answered',
        'Metrics_Cancel_Selection_Button': 'Cancel selection',
        'Metrics_Test_Without_Modules_Title': 'This test has no modules yet',
        'Metrics_Test_Without_Modules_Text': 'Please go to "Design your test" to add your first module.',
        'Metrics_Test_Without_Modules_Link': 'Go to Design your test',
        'Metrics_Test_Without_Responses_Title': 'This test has no results yet',
        'Metrics_Test_Without_Responses_Text': 'Please invite testers or wait until at least one of them participates.',
        'Metrics_Test_Without_Responses_Link': 'Refresh data',
        'Metrics_Test_Unpaid_Title': 'This test is not paid yet',
        'Metrics_Test_Unpaid_Text': 'You have to pay for your test to be able to invite audience and get results.',
        'Metrics_Test_Unpaid_Link': 'Go to Review test and pay',
        'Metrics_Details_Emotions_Title': 'Emotions',
        'Metrics_Details_Emotions_Text': 'Here you can see the overall level of activation of each emotion generated by this content',
        'Metrics_Details_Activation_Title': 'Activation',
        'Metrics_Details_Activation_Text': 'Sum of all positive and negative emotions throughout your content',
        'MetricsModule_Score_Label_No_Results': 'No results',
        'MetricsModule_Score_Label_Poor': 'Poor',
        'MetricsModule_Score_Label_Regular': 'Regular',
        'MetricsModule_Score_Label_Good': 'Good',
        'MetricsModule_Score_Label_Very_Good': 'Very good',
        'MetricsModule_Score_Legend': 'Average score<br/>based on <b>[var1] responses</b>',
        'MetricsModule_Responses_Count': 'responses',
        'Metrics_Desktop_Promo_Title': 'Do you already know what Alyze can do?',
        'Metrics_Desktop_Promo_List1_Title': 'Customise your test completely',
        'Metrics_Desktop_Promo_List1_Text': 'You can add your logo and colours to your test as well as customize the introduction and end texts.',
        'Metrics_Desktop_Promo_List2_Title': 'Ask questions!',
        'Metrics_Desktop_Promo_List2_Text1': 'Add quizzes with questions and answers to your test.',
        'Metrics_Desktop_Promo_List2_Text2': 'Log in from a computer to use the <b>full version</b>',
        'Metrics_Share_Link_Title': 'Share link',
        'Metrics_Share_Link_Button_Copy': 'Copy',
        'Metrics_Share_Link_Text': 'This is the link to participate in your test, share it with whoever you want. The more people who complete the test, the more accurate your results will be.',
        'Metrics_Share_Link_Buy_Audience_Title': 'Choose your audience and we\'ll take care of everything for you',
        'Metrics_Share_Link_Buy_Audience_Button': 'Buy audience',
        'Metrics_Download_Button': 'Download metrics',
        'Metrics_Download_Button_Averages_Csv': 'Export tester averages (CSV)',
        'Metrics_Download_Button_Aggregate_Csv': 'Export aggregate (CSV)',
        'Metrics_Warning_Pay_Test_Title_A': 'Showing 5 of [var1] results',
        'Metrics_Warning_Pay_Test_Text_A': 'We give you the first 5 responses for free',
        'Metrics_Warning_Pay_Test_Button_A': 'PAY AND GET THE COMPLETE RESULTS NOW',
        'Metrics_Warning_Pay_Test_Title_B': 'Try before pay',
        'Metrics_Warning_Pay_Test_Text_B': 'Pay your test when you want to get the complete results',
        'Metrics_Warning_Pay_Test_Button_B': 'PAY NOW',
        /* PublishTest */
        'PublishTest_Title': 'Publish your test to start collecting responses',
        'PublishTest_Text': 'Review the preview of your test with attention and publish it when you are ready to start collecting responses. <b>When test is published you can not edit her content.</b>',
        'PublishTest_Button_Publish': 'Publish test',
        'PublishTest_Button_Recopilation_Method': 'Recopilation method',
        'PublishTest_Button_Recopilation_Method_Email': 'Email invitation',
        'PublishTest_Button_Recopilation_Method_Link': 'Access by link',
        /* PayTest */
        'PayTest_Title': 'Pay and get all the results now',
        'PayTest_Text': 'Your test has [var1] responses, <b>we will give you the first 5 for free</b>.<br/>After you pay, the test will be active until it reaches the number of answers you paid for.',
        'PayTest_Responses_Selector_Title': 'How many responses you need?',
        'PayTest_Responses_Selector_Subtitle': 'The minimum of responses is the number of responses already collected',
        'PayTest_Invoice_Test': 'x Test',
        'PayTest_Invoice_Unit_Duration': 'Unit duration',
        'PayTest_Invoice_Unit_Price': 'Unit price',
        'PayTest_Invoice_Free_Test': '5 x FREE Test',
        'PayTest_Invoice_Total': 'Total',
        'PayTest_Invoice_VAT': 'VAT included',
        'PayTest_Invoice_Button_Pay': 'PAY AND GET RESULTS',
        'PayTest_Featured_Clients_Title': 'Featured clients',
        'PayTest_Link_Back': 'Back to results',
        /* MetricsFilms */
        'MetricsFilm_Flashes_Title': 'Flashes',
        'MetricsFilm_Flashes_max-activation-peak': 'Emotional peak',
        'MetricsFilm_Flashes_max-activation-bin': 'Most exciting moment',
        'MetricsFilm_Flashes_max-interest-bin': 'Most interesting moment',
        'MetricsFilm_Flashes_little-activation': 'Low emotion',
        'MetricsFilm_Flashes_loss-of-attention': 'Loss of attention',
        'MetricsFilm_Flashes_a-lot-of-activation': 'High emotion',
        'MetricsFilm_Flashes_no-data': 'No data',
        'MetricsFilm_Audience_Title': 'Audience',
        'MetricsFilm_Audience_RealizedTests': 'Completed tests',
        'MetricsFilm_Audience_MostInterestedAges': 'Most interested ages',
        'MetricsFilm_Audience_MostInterestedGender': 'Most interested gender',
        'MetricsFilm_Audience_MostInterestedLocation': 'Most interested location',
        'MetricsFilm_Audience_LessInterestedAges': 'Less interested ages',
        'MetricsFilm_Emotions_Title': 'Emotions',
        'MetricsFilm_Emotions_EmotionOverPercentage': 'Emotion > 65%',
        'MetricsFilm_Emotions_LossOfAttention': 'Loss of attention',
        'MetricsFilm_Emotions_DataAccuracy': 'Data quality',
        'MetricsFilm_Emotions_PredominantEmotion': 'Predominant emotion',
        'MetricsFilm_Emotions_SecondaryEmotion': 'Secondary emotion',
        'MetricsFilm_Emotions_DataQuality_Legend_Excellent': 'Congrats! your data quality its excellent',
        'MetricsFilm_Emotions_DataQuality_Legend_Get_Excellent': 'Get [var1] more answers to achieve excellent quality',
        'MetricsFilm_Emotions_DataQuality_Legend_Get_High': 'Get [var1] more answers to achieve high quality',
        'MetricsFilm_Emotions_DataQuality_Legend_Get_Medium': 'Get [var1] more answers to achieve medium quality',
        'MetricsFilm_Metrics_Title': 'Metrics',
        'MetricsFilm_Metrics_Play_Message': 'Click to play',
        'MetricsFilm_Metrics_Timeline_Attention': 'Attention',
        'MetricsFilm_Metrics_Timeline_Activation': 'Emotional Activation',
        'MetricsFilm_Metrics_Timeline_Emotion_Happiness': 'Happiness',
        'MetricsFilm_Metrics_Timeline_Emotion_Surprise': 'Surprise',
        'MetricsFilm_Metrics_Timeline_Emotion_Sadness': 'Sadness',
        'MetricsFilm_Metrics_Timeline_Emotion_Fear': 'Fear',
        'MetricsFilm_Metrics_Timeline_Emotion_Anger': 'Anger',
        'MetricsFilm_Metrics_Timeline_Emotion_Disgust': 'Disgust',
        'MetricsFilm_Survey_Title': 'Survey',
        /* AverageEmotions */
        'AverageEmotions_Emotion_Happiness': 'Happiness',
        'AverageEmotions_Emotion_Surprise': 'Surprise',
        'AverageEmotions_Emotion_Sadness': 'Sadness',
        'AverageEmotions_Emotion_Fear': 'Fear',
        'AverageEmotions_Emotion_Anger': 'Anger',
        'AverageEmotions_Emotion_Disgust': 'Disgust',
        /* EmotionalOverview */
        'EmotionalOverview_Title': 'Emotional summary',
        'EmotionalOverview_Bottom_Legend': 'time (seconds)',
        'EmotionalOverview_Left_Legend': 'emotional activation (%)',
        'EmotionalOverview_Total_Activation': 'Emotional summary',
        /* EmotionalPeaks */
        'EmotionalPeaks_Title': 'Emotional peak',
        'EmotionalPeaks_Emotion_Reached': 'emotion (% reached)',
        /* EngagementOverview */
        'EngagementOverview_Title': 'Engagement summary',
        'EngagementOverview_Left_Legend': 'engagement level (%)',
        /* InterestOverview */
        'InterestOverview_Title': 'Interest summary',
        'InterestOverview_Left_Legend': 'interest level (%)',
        /* MetricsPeaks */
        'MetricsPeaks_Title': 'Metric Peaks',
        'MetricsPeaks_Emotion_Reached': '% reached',
        /* MetricsSummary */
        'MetricsSummary_Title': 'Metrics summary',
        'MetricsSummary_Left_Legend': 'activation level (%)',
        /* SatisfactionOverview */
        'SatisfactionOverview_Title': 'Satisfaction summary',
        'SatisfactionOverview_Left_Legend': 'satisfaction level (%)',
        /* Panels */
        'Panels_Audio_Lowercase': 'audio',
        'Panels_vídeo_Lowercase': 'video',
        'Panels_Image_Lowercase': 'image',
        'Panels_QA_Lowercase': 'q&a',
        'Panels_Audio_Capital_Letter': 'Audio',
        'Panels_Video_Capital_Letter': 'Video',
        'Panels_Large_Video_Capital_Letter': 'Film',
        'Panels_Image_Capital_Letter': 'Image',
        'Panels_QA_Capital_Letter': 'Q&A',
        'Panels_Audio_Uploading_File': 'Uploading audio',
        'Panels_Video_Uploading_File': 'Uploading video',
        'Panels_Image_Uploading_File': 'Uploading image',
        'Panels_Large_Video_Uploading_File': 'Uploading film',
        'Panels_Randomize_Order': 'Random order',
        'Panels_Play_Order': 'Show in order',
        'Panels_Show_Order': 'Show in order',
        'Panels_Technology': 'Technology',
        'Panels_Facial_Recognition': 'Emotional facial recognition',
        'Panels_Choose_One': 'Choice',
        'Panels_Just_Play': 'Just play',
        'Panels_Landing_Title': 'Landing',
        'Panels_Introduction_Title': 'Introduction',
        'Panels_Finish_Title': 'Finalize',
        'Panels_Btn_Preview': 'Preview',
        'Panels_Btn_Cancel_Upload': 'Cancel upload',
        'Panels_Film_Genre_Action': 'Action',
        'Panels_Film_Genre_Adventure': 'Adventure',
        'Panels_Film_Genre_Comedy': 'Comedy',
        'Panels_Film_Genre_Horror': 'Horror',
        'Panels_Film_Genre_Thriller': 'Thriller',
        'Panels_Film_Genre_Romance': 'Romance',
        'Panels_Film_Genre_Documentary': 'Documentary',
        'Panels_Module_Not_Included': 'Not included in this test',
        'Panels_Only_One_Film_Module': 'Only one film module per test',
        'Panels_Max_Number_Of_Modules_Reached': 'Maximum of modules reached',
        'Panels_Max_Number_Of_Type_Modules_Reached': 'Maximum of [var1] modules reached',
        /* ModuleTypeInfo */
        'ModuleTypeInfo_Recognition_Text': 'Analyze the user\'s face with our artificial intelligence and emotional recognition technology',
        'ModuleTypeInfo_Choose_Text': 'Asks the user to choose a single clip from all the available',
        'ModuleTypeInfo_Play_Text': 'Just play the content without collecting any information from the tester',
        /* AddModulePanel */
        'AddModulePanel_Title': 'Add module',
        'AddModulePanel_Subtitle': 'Click or drag <br /> to add a new module',
        /* AudioModulePanel */
        'AudioModulePanel_Add_Audio_Button': 'Add audio',
        'AudioModulePanel_Add_Audio_Button_Tooltip': 'Maximum [var1] audios',
        'AudioModulePanel_Audios': 'Audios',
        /* ImageModulePanel */
        'ImageModulePanel_Image_Duration': 'Show each image for',
        'ImageModulePanel_Time': 'seconds',
        'ImageModulePanel_Add_Image_Button': 'Add image',
        'ImageModulePanel_Add_Image_Button_Tooltip': 'Maximum [var1] images',
        'ImageModulePanel_Audios': 'Images',
        /* QAModulePanel */
        'QAModulePanel_Add_Question_Button': 'Add question',
        'QAModulePanel_Remove_Question_Button': 'Remove question',
        'QAModulePanel_Question': 'Question',
        'QAModulePanel_Write_Question': 'Write your question here...',
        'QAModulePanel_Answers': 'Answers',
        'QAModulePanel_Write_Answer': 'Write an answer here...',
        'QAModulePanel_Add_Answer_Button': 'Add answers',
        'QAModulePanel_Add_Answer_Button_Tooltip': 'Maximum [var1] answers',
        /* videoModulePanel */
        'VideoModulePanel_Add_Video_Button': 'Add video',
        'VideoModulePanel_Add_Video_Button_Tooltip': 'Maximum [var1] videos',
        'VideoModulePanel_Videos': 'videos',
        /* filmModulePanel */
        'FilmModulePanel_Film_Name': 'Content name',
        'FilmModulePanel_Film_Name_Placeholder': 'Name',
        'FilmModulePanel_Film_Genre': 'Genre',
        'FilmModulePanel_Videos': 'File',
        'FilmModulePanel_Videos_Test_Has_Invited_Testers': 'It is not possible to change the file when testers have been invited.',
        'FilmModulePanel_Add_Video_Button': 'Upload content',
        /* TextModulePanel */
        'TextModulePanel_Title': 'Title',
        'TextModulePanel_Text': 'Text',
        'TextModulePanel_Button': 'Button',
        'TextModulePanel_ExternalLink_Title': 'Add external link',
        'TextModulePanel_ExternalLink_Button': 'Button',
        'TextModulePanel_ExternalLink_Link': 'Link',
        /* PhonePreview */
        'PhonePreview_Press_Here_Text': 'Click here to<br/>to start the preview',
        /* PhonePreviewDesign */
        'PhonePreviewDesign_Title': 'Lorem Ipsum',
        'PhonePreviewDesign_Text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat',
        'PhonePreviewDesign_Button': 'Lorem Ipsum',
        /* CampaignsTable */
        'CampaignsTable_Column_1': 'Name',
        'CampaignsTable_Column_2': 'State',
        'CampaignsTable_Column_3': 'Responses',
        'CampaignsTable_Column_4': 'Analyzed',
        'CampaignsTable_Status_paid': 'Paid',
        'CampaignsTable_Status_draft': 'Draft',
        'CampaignsTable_Status_preview': 'Preview',
        'CampaignsTable_Status_inreview': 'In review',
        'CampaignsTable_Status_public': 'Public',
        'CampaignsTable_Status_processing': 'Processing',
        'CampaignsTable_Status_finished': 'Finished',
        'CampaignsTable_Status_finish': 'Finished',
        'CampaignsTable_Button_1_OnMouseOver': 'Copy Test link',
        'CampaignsTable_Button_2_OnMouseOver': 'View Test metrics',
        'CampaignsTable_Button_3_OnMouseOver': 'Edit Test',
        'CampaignsTable_Button_4_OnMouseOver': 'Clone Test',
        'CampaignsTable_Button_5_OnMouseOver': 'Delete Test',
        'CampaignsTable_Button_Share': 'Share / Invite audience',
        'CampaignsTable_Button_Edit': 'Edit test',
        'CampaignsTable_Button_More': 'Other actions',
        'CampaignsTable_Responses_Info': 'Tests finished / Tests started',
        /* InputUploadFile_Upload_Image*/
        'InputUploadFile_Upload_Image': 'Change',
        /* Notifications */
        'Notifications_PublishTest_Processing': 'Your test is processing some files now, wait it to ends before to publish',
        'Notifications_PublishTest_Test_Empty': 'Your test needs to have one or more modules before to be published',
        'Notifications_Created_Test': 'Test created correctly!',
        'Notifications_Changes_Applied': 'The changes have been saved correctly',
        'Notifications_Check_Data': 'Please review all the information before continuing',
        'Notifications_Ivalid_Test_Plan': 'Please choose a test plan before continue',
        'Notifications_Incorrect_Email': 'The email you entered is incorrect',
        'Notifications_Conexion_Error': 'There is no Internet conection. Please try again in a few minutes',
        'Notifications_Unexpected_Error': 'Unexpected error. Please try again in a few minutes',
        'Notifications_Invalid_Code': 'Invalid access code',
        'Notifications_Session_Expired': 'The session has expired',
        'Notifications_Incorrect_Credentials': 'Incorrect email or password',
        'Notifications_Check_Email': 'Check your email to verify your account before logging in',
        'Notifications_Registered_Email': 'This email address is already registered',
        'Notifications_Link_Clipboard': 'Link copied to clipboard!',
        'Notifications_Account_Activated': 'Your account has been successfully activated!',
        'Notifications_Password_Updated': 'Your password has been updated correctly!',
        'Notifications_Check_Inbox': 'Please check your inbox to retrieve your password',
        'Notifications_Contact_ASAP': 'We will contact you shortly',
        'Notifications_Create_Account': 'Please create an account',
        'Notifications_Publish_Before_Proceed': 'You must publish your Test before continuing',
        'Notifications_Empty_Modules': 'One or more modules are empty',
        'Notifications_Film_Duration': 'The length of the film must be less than 180 minutes',
        'Notifications_Film_Size': 'Film size must be less than 7gb',
        'Notifications_Video_Resolution': 'Video resolution must be less than 1280px',
        'Notifications_Video_Duration': 'The length of the video must be less than 5 minutes',
        'Notifications_Video_Cutter': 'online-vídeo-cutter.com',
        'Notifications_Video_Size': 'Video size must be less than 500MB',
        'Notifications_Video_Invalid_Format': 'The file it corrupted or incompatible',
        'Notifications_Audio_Duration': 'The duration of the audio must be less than 5 minutes',
        'Notifications_Audio_Size': 'Audio size must be less than 50MB',
        'Notifications_Image_Resolution': 'Image width and height cannot exceed 6000px',
        'Notifications_Image_Size': 'Image size must be less than 10MB',
        'Notifications_Added_Credit': 'Credit has been added to your wallet',
        'Notifications_User_Exists': 'An account already exists with this email address. Enter your password to continue',
        'Notifications_BuyAudience_Payment_Succesfull': 'Your audience has been added',
        'Notifications_BuyAudience_Payment_Error': 'The payment could not be processed. Please try again in a few minutes',
        'Notifications_InviteAudience_Emails_Added_Successfull': 'Emails have been added correctly',
        'Notifications_InviteAudience_Emails_Added_Invalid': 'There are invalid emails',
        'Notifications_InviteAudience_Emails_Added_Empty': 'Should be at least one valid email address',
        'Notifications_InviteAudience_Emails_Added_Max_Testers_Reached': 'You have reached the maximum number of testers',
        'Notifications_InviteAudience_Emails_Sent_Film_Successfull': 'The invitation(s) will be sent when the file(s) is watermarked',
        'Notifications_InviteAudience_Emails_Sent_Successfull': 'Invitations have been sent successfully',
        'Notifications_InviteAudience_Emails_Sent_Max_FilmTest_Reached': 'You have reached the maximum number of film type tests',
        'Notifications_InviteAudience_Testers_Deleted_Successfull': 'Tester(s) deleted correctly',
        'Notifications_Recopilator_Link_Deleted_Successfully': 'Link deleted successfully',
        'Notifications_Account_Deleted_Successfully': 'Your Alyze account has been deleted',
        'Notifications_DefineAudience_Segment_Deleted_Successfully': 'Sample deleted successfully',
        'Notifications_DefineAudience_Segment_Added_Max_Segments_Reached': 'You have reached the maximum number of audience segments',
        'Notifications_ReviewTestAndPay_Payment_Succesfull': 'Payment successfully. Your test has been published.',
        'Notifications_ReviewTestAndPay_Payment_Error': 'The payment could not be processed. Please try again in a few minutes',
        /* Account */
        'Account_Browser_Title': 'Alyze | Account',
        'Account_Title': 'My account',
        'Account_Title_Plan_Details': 'Account details',
        'Account_Title_Session_Details': 'Login details',
        'Account_Title_General_Options': 'General preferences',
        'Account_Input_Label_Name': 'Name',
        'Account_Input_Label_Username': 'Username',
        'Account_Input_Label_Password': 'Password',
        'Account_Input_Label_Email': 'Email',
        'Account_Input_Label_Language': 'Language',
        'Account_Button_Edit': 'Edit',
        'Account_General_Name': 'Name',
        'Account_General_Name_Modal_Title': 'Change your name',
        'Account_General_Name_Modal_Subtitle': 'We respect your privacy',
        'Account_General_Name_Modal_Cancel': 'Cancel',
        'Account_General_Name_Modal_Confirm': 'Rename',
        'Account_General_Lang': 'Language',
        'Account_General_Lang_Modal_Title': 'Change language',
        'Account_General_Lang_Modal_Subtitle': '',
        'Account_General_Lang_Modal_Cancel': 'Cancel',
        'Account_General_Lang_Modal_Confirm': 'Change language',
        'Account_General_Lang_ES': 'Spanish',
        'Account_General_Lang_EN': 'English',
        'Account_General_Username': "Username",
        'Account_General_Username_Modal_Title': 'Change your username',
        'Account_General_Username_Modal_Subtitle': 'We respect your privacy',
        'Account_General_Username_Modal_Cancel': 'Cancel',
        'Account_General_Username_Modal_Confirm': 'Confirm your username',
        'Account_Change_Password': 'Password',
        'Account_Change_Password_Subtitle': 'Change password',
        'Account_Change_Password_Current_Password': 'Current password',
        'Account_Change_Password_New_Password': 'New password',
        'Account_Change_Password_Retype_New_Password': 'Confirm the new password',
        'Account_Change_Password_Modal_Title': 'Change password',
        'Account_Change_Password_Modal_Subtitle': 'We respect your privacy',
        'Account_Change_Password_Modal_Cancel': 'Cancel',
        'Account_Change_Password_Modal_Confirm': 'Change password',
        'Account_DeleteAccount': 'Delete my account',
        'Account_DeleteAccount_Modal_Title': 'Delete my account',
        'Account_DeleteAccount_Modal_Subtitle': 'This action is <b>irreversible</b>',
        'Account_DeleteAccount_Modal_Content_Title': 'Are you sure you want to <b>permanently delete your account</b>?',
        'Account_DeleteAccount_Modal_Content_List_1': 'All your personal data will be deleted.',
        'Account_DeleteAccount_Modal_Content_List_2': 'You will lose all information related to your tests.',
        'Account_DeleteAccount_Modal_Content_List_3': 'You will no longer be able to access Alyze.',
        'Account_DeleteAccount_Modal_Content_List_4': 'This action cannot be undone.',
        'Account_DeleteAccount_Modal_Content_Label': 'Type <b>DELETE</b> to confirm',
        'Account_DeleteAccount_Modal_Cancel': 'Cancel',
        'Account_DeleteAccount_Modal_Confirm': 'Yes, delete my account',
        /* Campaigns */
        'Campaigns_Browser_Title': 'Alyze | Tests',
        'Campaigns_Title': 'Tests',
        'Campaigns_Button_View_Metrics': 'VIEW METRICS',
        'Campaigns_Status_Active': 'Active',
        'Campaigns_Status_Paused': 'Paused',
        'Campaigns_Status_Processing': 'Processing film',
        'Campaigns_Status_Ready': 'Ready to send',
        'Campaigns_Creation_Date': 'Created on',
        'Campaigns_Review_Campaign_Text_1': 'Your Test "',
        'Campaigns_Review_Campaign_Text_2': '"will be reviewed by our team and published in a few minutes',
        'Campaigns_Close_Button': 'Close',
        'Campaigns_No_Campaigns_Found': 'No tests yet!',
        'Campaigns_Start_First_Campaig': 'What about creating your first test now?',
        'Campaigns_New_Campaign_Button': 'Create Test',
        'Campaigns_Remove_Modal_Title': 'Delete test',
        'Campaigns_Remove_Modal_Subtitle': 'This action is <b>irreversible</b>',
        'Campaigns_Remove_Modal_Cancel': 'Cancel',
        'Campaigns_Remove_Modal_Confirm': 'Delete test',
        'Campaigns_Remove_Campaign_Text_1': 'Are you sure you want to delete <b>[var1]</b> permanently?',
        /* SupportAndHelp */
        'SupportAndHelp_Browser_Title': 'Alyze | Support & Help',
        'SupportAndHelp_Title': 'Support & Help',
        'SupportAndHelp_Tutorials': 'Tutorials',
        'SupportAndHelp_FAC': 'Frequent Questions',
        'SupportAndHelp_Contact': 'Contact us',
        'SupportAndHelp_Email': 'Email',
        'SupportAndHelp_Email_Address': 'support@byalyze.com',
        'SupportAndHelp_Realtime_Chat': 'Real-time chat',
        'SupportAndHelp_Open_Chat_Button': 'Open chat',
        /* CampaignsConfiguration */
        'CampaignsConfiguration_Revert_Changes_Button': "Revert changes",
        'CampaignsConfiguration_Saved_Button': "Saved",
        'CampaignsConfiguration_Save_Changes_Button': "Save changes",
        'CampaignsConfiguration_Back_Button': "Return",
        'CampaignsConfiguration_Next_Step_Button': "Following",
        /* Design */
        'Design_Browser_Title': 'Alyze | Test Configuration - Design',
        'Design_Title': 'EDIT THE APPEARANCE',
        'Design_Subtitle': 'You can customize your test with the style of your brand',
        'Design_Tab_Cover': 'LANDING',
        'Design_Tab_General': 'GENERAL',
        'Design_General_Subtitle': 'General',
        'Design_Colors': 'Colors',
        'Design_Colors_Title': 'Títle',
        'Design_Text': 'Text',
        'Design_Background': 'Color',
        'Design_Logo': 'Logo',
        'Design_Dropdown_Image': 'Image',
        'Design_Dropdown_Text': 'Text',
        'Design_Dropdown_None': 'None',
        'Design_Dropdown_Solid': 'Solid',
        'Design_Dropdown_Border': 'Border',
        'Design_Background_Image': 'Background',
        'Design_Primary_Button': 'Buttons',
        'Design_Border_Radius': 'Corners',
        'Design_Secondary_Button': 'Secondary button',
        'Design_Landing_Subtitle': 'Landing',
        'Design_Not_Included': 'Not included in this test',
        /* Metrics */
        'Metrics_Browser_Title': 'Alyze | Test Results - Metrics',
        'Metrics_Title': 'Metrics',
        'Metrics_Realtime_Stats': 'Real-time statistics',
        'Metrics_Realtime_Stats_Text': 'Click on the content to see<br/> real-time statistics',
        'Metrics_No_Results': 'No results yet!',
        'Metrics_No_Results_Text': 'The results of the metrics will be available<br /> when the Testers have completed your Test<br/>and their data has been processed',
        'Metrics_No_Results_Lite': 'No responses yet',
        'Metrics_No_Results_Text_Lite': 'To get your results, remember to share the link we have sent to your email. The more responses you get, the better results you will get!',
        /* Modules */
        'Modules_Click_To_Edit': 'Click here to edit',
        'Modules_Editing': 'Editing...',
        'Modules_Empty_Tag': 'Empty module',
        'Modules_Browser_Title': 'Alyze | Test Configuration - Modules',
        'Modules_Title': 'ADD MODULES',
        'Modules_Subtitle': 'Click or drag to add a new module',
        'Modules_Drag_To_Remove': 'Drag the module here to delete it',
        'Modules_Drag_To_Remove_Tooltip': 'Drag&Drop module here to delete it',
        'Modules_Title_Finish': 'Finalize',
        'Modules_Title_Landing': 'Landing',
        'Modules_Title_Introduction': 'Introduction',
        'Modules_Title_Login': 'Login',
        'Modules_Save_Campaign_To_Preview_Modal_Title': 'Module preview',
        'Modules_Save_Campaign_To_Preview_Modal_Subtitle': 'Save and go to preview',
        'Modules_Save_Campaign_To_Preview_Modal_Cancel': 'Cancel',
        'Modules_Save_Campaign_To_Preview_Modal_Confirm': 'Save and preview',
        'Modules_Save_Campaign_To_Preview_Text': 'It is necessary to save the changes in order to preview the module.',
        'Modules_Max_Duration_Test_Modal_Title': 'Warning',
        'Modules_Max_Duration_Test_Modal_Subtitle': 'Maximum test duration reached',
        'Modules_Max_Duration_Test_Modal_Confirm': 'Continue editing',
        //'Modules_Max_Duration_Test_Text': 'You have exceeded the maximum time per test of your plan.<br/>Modify the duration of the modules to continue.<br/><br/>To increase your limit, upgrade your ',  
        'Modules_Max_Duration_Test_Text': 'You have exceeded the maximum time per test.<br/>Modify the duration of the modules to continue.',
        'Modules_Duration_Film_Test_Modal_Title': 'Warning',
        'Modules_Duration_Film_Test_Modal_Subtitle': 'The file doesn\'t reach the minimum duration',
        'Modules_Duration_Film_Test_Modal_Confirm': 'Continue editing',
        'Modules_Duration_Film_Test_Text': 'The file must be at least <strong>3 minutes long</strong>.<br/><br/> You can create a multimedia type test for shorter videos, using<br/> the <strong>video module</strong>.',
        /* Preview */
        'Preview_Browser_Title': 'Alyze | Preview',
        'Preview_Title': 'PREVIEW',
        'Preview_Subtitle': 'You can try and share a preview of your test before publishing it',
        'Preview_Try_Test_Title': 'Check your test',
        'Preview_Try_Test_Text': 'Try out your test exactly the way your users will see it',
        'Preview_Try_Test_CTA': 'See my test',
        'Preview_Publish_Title': 'Publish preview',
        'Preview_Publish_Text_A': 'Activate this option to keep online your preview. You\'ll activate the shareable link below.',
        'Preview_Publish_Text_B': 'Deactivate it at any time and no one will have access to the link afterward.',
        'Preview_Share_Title': 'Share preview',
        'Preview_Share_CTA': 'Copy link',
        'Preview_Share_Text': '<b>Copy and send this link to grant access</b> to the preview as long as you keep it published',
        /* Define audience */
        'DefineAudience_Browser_Title': 'Alyze | Test Configuration - Define your target audience',
        'DefineAudience_Choose_Audience_Type_Title': 'How do you want to collect your test responses? ',
        'DefineAudience_Choose_Audience_Type_Description': 'Once you invite the first tester, you will not be able to change the audience acquisition method.',
        'DefineAudience_Choose_Audience_Type_Box_Links_Tag': 'Public links',
        'DefineAudience_Choose_Audience_Type_Box_Links_Title': 'Create public links and share them',
        'DefineAudience_Choose_Audience_Type_Box_Emails_Tag': 'Email invitations',
        'DefineAudience_Choose_Audience_Type_Box_Emails_Title': 'Send email invitations with unique links ',
        'DefineAudience_Choose_Audience_Type_Box_Emails_Description': 'A unique link for each of them will be generated and sent by email, giving them access to your content.',
        'DefineAudience_Choose_Audience_Type_Box_Alyze_Audience_Tag': 'Alyze audience',
        'DefineAudience_Choose_Audience_Type_Box_Alyze_Audience_Title': 'Focus on the ideal people for your survey',
        'DefineAudience_Choose_Audience_Type_Note': 'NOTE: We do not offer "Alyze audience" to tests that include the film module.',
        'DefineAudience_Alyze_Audience_Title': 'Alyze audience sample size',
        'DefineAudience_Alyze_Audience_Description': 'The size of the sample will conditionate the data quality.',
        'DefineAudience_Alyze_Audience_Data_Quality': 'Data Quality Expected: ',
        'DefineAudience_Alyze_Audience_Data_Quality_Value': 'Medium (Error range ≤ [var1]%)',
        'DefineAudience_Add_Audience_Title': 'Define your audience',
        'DefineAudience_Add_Audience_Description': 'Select your sample in terms of age, gender, and location.<br/> Keep in mind that you can add one sample or compare between two or more groups, depending on your plan. If you want any additional segmentation features, please contact us.',
        'DefineAudience_Add_Audience_Add_Button': 'Add another sample group',
        'DefineAudience_Add_Audience_New_Group': 'NEW GROUP: Alias',
        'DefineAudience_Add_Audience_Sample_Name': 'New sample',
        'DefineAudience_Add_Audience_Sample_Size': 'Sample size',
        'DefineAudience_Delete_Segment_Modal_Title': 'Delete sample',
        'DefineAudience_Delete_Segment_Modal_Subtitle': 'You are about to remove a sample',
        'DefineAudience_Delete_Segment_Modal_Text': 'Are you sure you want to delete <b>[var1]</b>?',
        'DefineAudience_Delete_Segment_Modal_Cancel': 'No, cancel',
        'DefineAudience_Delete_Segment_Modal_Confirm': 'Yes, delete',
        'DefineAudience_Edit_Segment_Modal_Title': 'Edit sample name',
        'DefineAudience_Edit_Segment_Modal_Subtitle': 'The sample name will help you better identify your audience group',
        'DefineAudience_Edit_Segment_Modal_Cancel': 'Cancel',
        'DefineAudience_Edit_Segment_Modal_Confirm': 'Save changes',
        'DefineAudience_Edit_Segment_Input_Name': 'Sample name',
        'DefineAudience_Add_Segment_Modal_Title': 'Add sample',
        'DefineAudience_Add_Segment_Modal_Subtitle': 'Label your groups to keep track of them throughout the test',
        'DefineAudience_Add_Segment_Modal_Cancel': 'Cancel',
        'DefineAudience_Add_Segment_Modal_Confirm': 'Add sample',
        'DefineAudience_Add_Segment_Input_Name': 'Sample name',
        'DefineAudience_Segment_Alias': 'GROUP [var1]:',
        'DefineAudience_Segment_Location_Title': 'Location',
        'DefineAudience_Segment_Location_Text': 'Location',
        'DefineAudience_Segment_Location_Text_All': 'All',
        'DefineAudience_Segment_Gender_Title': 'Gender',
        'DefineAudience_Segment_Gender_Text': 'Gender',
        'DefineAudience_Segment_Gender_Text_Male': 'Man',
        'DefineAudience_Segment_Gender_Text_Female': 'Woman',
        'DefineAudience_Segment_Gender_Text_Both': 'Both',
        'DefineAudience_Segment_Age_Title': 'Age',
        'DefineAudience_Segment_Age_Text': 'Age',
        /* Review test and pay */
        'Review_Test_And_Pay_Browser_Title': 'Alyze | Review test and pay',
        'Review_Test_And_Pay_Audience_Groups_Title': 'Audience groups',
        'Review_Test_And_Pay_Audience_Groups_Total_Responses': 'Total responses',
        'Review_Test_And_Pay_Audience_Groups_Acquisition_Method': 'Acquisition method',
        'Review_Test_And_Pay_Audience_Groups_Acquisition_Method_UnChosen': '-',
        'Review_Test_And_Pay_Audience_Groups_Acquisition_Method_UnChosen_Warning': 'You must choose an audience acquisition method',
        'Review_Test_And_Pay_Audience_Groups_Acquisition_Method_No_Segment_Created_Warning': 'You must create at least one audience segment',
        'Review_Test_And_Pay_Test_Summary_Title': 'Test summary',
        'Review_Test_And_Pay_Test_Summary_Estimated_Duration': 'Estimated test duration',
        'Review_Test_And_Pay_Test_Summary_Estimated_Duration_Disclaimer': 'Your test might take 2 to 4 more minutes due to events beyond our control (Internet connection quality, data processing, etc.).',
        'Review_Test_And_Pay_Pay_And_Publish_Button': 'Pay & publish',
        'Review_Test_And_Pay_Free_Publish_Button': 'Publish',
        'Review_Test_And_Pay_Missing_Options_Modal_Title': 'Missing options to be configured',
        'Review_Test_And_Pay_Missing_Options_Modal_Subtitle': '',
        'Review_Test_And_Pay_Missing_Options_Modal_Confirm': 'Close',
        'Review_Test_And_Pay_Missing_Options_Modal_Missing_Text': 'To continue, please review the following options:',
        'Review_Test_And_Pay_Missing_Options_Modal_Missing_Policy': 'Accept <strong>privacy policy</strong>',
        'Review_Test_And_Pay_Missing_Options_Modal_Missing_Modules': 'At least one <strong>module</strong> in the test',
        'Review_Test_And_Pay_Missing_Options_Modal_Missing_Segments': 'At least one <strong>audience segment</strong>',
        'Review_Test_And_Pay_Missing_Options_Modal_Missing_Acquisition_Method': 'Choose an <strong>audience acquisition method</strong>',
        'Review_Test_And_Pay_Notice_Before_Payment_Modal_Title': 'Important notice',
        'Review_Test_And_Pay_Notice_Before_Payment_Modal_Subtitle': '',
        'Review_Test_And_Pay_Notice_Before_Payment_Modal_Text1': 'The test modules cannot be modified later.',
        'Review_Test_And_Pay_Notice_Before_Payment_Modal_Text2': 'You will be redirected to Stripe to proceed with the payment.',
        'Review_Test_And_Pay_Notice_Before_Payment_Modal_Confirm': 'Continue with payment',
        'Review_Test_And_Pay_Notice_Before_Payment_Modal_Confirm_Free': 'Publish',
        'Review_Test_And_Pay_Notice_Before_Payment_Modal_Cancel': 'Cancel',
        /* Recopilator */
        'Recopilator_Browser_Title': 'Alyze | Response Collectors',
        'Recopilator_GlobalSummary_Title': 'Collect responses global summary',
        'Recopilator_Links_Title': 'Public links',
        'Recopilator_Links_InnerText': 'Create public links and distribute them your way. While the test is active you can create as many links as you want to segment your audience between different group types.<br/><br/><b>In a hurry to get your answers? <a href="javascript:[var1]">Contact us</a> and we\'ll take care of everything for you.</b>',
        'Recopilator_Links_Text': 'Send your surveys by email, insert them on your website, share a link on your social media or anywhere you wish.',
        'Recopilator_Links_Add': 'Add another audience link',
        'Recopilator_Links_Copy': 'Copy link',
        'Recopilator_Links_Toggle': 'Accept responses from this link',
        'Recopilator_Links_Paid_Title': 'Hire real people for your survey',
        'Recopilator_Links_Paid_Text': 'Buy responses from your target audience. Real people around the world, carefully categorized to match your buyer\'s profile.',
        'Recopilator_Links_Paid_Add': 'Hire audience',
        'Recopilator_Links_Paid_Button': 'View features',
        'Recopilator_Links_Paid_Location': 'Location',
        'Recopilator_Links_Paid_Genre': 'Gender',
        'Recopilator_Links_Paid_Age': 'Age',
        'Recopilator_Metrics_Responses': 'Responses obtained',
        'Recopilator_Metrics_Location': 'Top Location',
        'Recopilator_Metrics_Genre': 'Top Gender',
        'Recopilator_Metrics_Age': 'Average age',
        'Recopilator_Edit_Link_Modal_Title': 'Edit link',
        'Recopilator_Edit_Link_Modal_Subtitle': 'The alias will help you better identify your audience',
        'Recopilator_Edit_Link_Modal_Cancel': 'Cancel',
        'Recopilator_Edit_Link_Modal_Confirm': 'Save changes',
        'Recopilator_Edit_Link_Input_Name': 'Alias',
        'Recopilator_Add_Link_Modal_Title': 'Create link',
        'Recopilator_Add_Link_Modal_Subtitle': 'Create as many links as you need to segment your audience. (E.g.: Create 3 different links to monitor social media, e-mailing and your website)',
        'Recopilator_Add_Link_Modal_Cancel': 'Cancel',
        'Recopilator_Add_Link_Modal_Confirm': 'Create link',
        'Recopilator_Add_Link_Input_Name': 'Alias',
        'Recopilator_Add_Link_Warning_Message': 'If you create your first link, this will be your audience acquisition method and cannot be changed.',
        'Recopilator_Delete_Link_Modal_Title': 'Delete link',
        'Recopilator_Delete_Link_Modal_Subtitle': 'You are about to remove a link',
        'Recopilator_Delete_Link_Modal_Text_1': '<b>Warning:</b> this link already has responses.',
        'Recopilator_Delete_Link_Modal_Text_2': 'By deleting the link, you\'ll lose all responses associated with it.',
        'Recopilator_Delete_Link_Modal_Text_3': 'It will no longer be possible to access the test from this link.',
        'Recopilator_Delete_Link_Modal_Text_4': 'Are you sure you want to delete <b>[var1]</b>?',
        'Recopilator_Delete_Link_Modal_Cancel': 'No, cancel',
        'Recopilator_Delete_Link_Modal_Confirm': 'Yes, delete',
        /*
        'Recopilator_User_Max_Tests_Reached_Modal_Title': 'Extend your plan to continue',
        'Recopilator_User_Max_Tests_Reached_Modal_Subtitle': 'You have exceeded this month\'s test limit',
        'Recopilator_User_Max_Tests_Reached_Modal_Cancel': 'Close',
        'Recopilator_User_Max_Tests_Reached_Modal_Confirm': 'View plans',
        'Recopilator_User_Max_Tests_Reached_Modal_Text_1': 'Your [var1] plan allows you to publish up to [var2] tests per month.',
        'Recopilator_User_Max_Tests_Reached_Modal_Text_2': 'To publish this test you must wait for the monthly period to renew or upgrade your account to a higher plan.',
        */
        'Recopilator_User_Max_Tests_Reached_Modal_Title': 'Limit reached',
        'Recopilator_User_Max_Tests_Reached_Modal_Subtitle': 'You have exceeded this month\'s test limit',
        'Recopilator_User_Max_Tests_Reached_Modal_Confirm': 'Ok',
        'Recopilator_User_Max_Tests_Reached_Modal_Text_1': 'You have reached the maximum number of monthly tests.',
        'Recopilator_User_Max_Tests_Reached_Modal_Text_2': 'To publish this test you must wait for the monthly period.',

        'Recopilator_Choose_Audience_Type_Title': 'How do you want to collect your test responses? ',
        'Recopilator_Choose_Audience_Type_Description': 'Once you invite the first tester, you will not be able to change the audience acquisition method.',
        'Recopilator_Choose_Audience_Type_Box_Links_Tag': 'Acccess by link',
        'Recopilator_Choose_Audience_Type_Box_Links_Title': 'Create public links and share them on your way',
        'Recopilator_Choose_Audience_Type_Box_Emails_Tag': 'Email invitations',
        'Recopilator_Choose_Audience_Type_Box_Emails_Title': 'Send email invitations with unique links ',
        'Recopilator_Choose_Audience_Type_Box_Emails_Description': 'A unique link for each of them will be generated and sent by email, giving them access to your content.',
        'Recopilator_Choose_Audience_Type_Box_Alyze_Audience_Tag': 'Alyze audience',
        'Recopilator_Choose_Audience_Type_Box_Alyze_Audience_Title': 'Focus on the ideal people for your survey',

        'Recopilator_Status_Processing_Warning_Title':'Processing test files',
        'Recopilator_Status_Processing_Warning_Text':'The links will not work until the test files has finished processing',
        'Recopilator_Interactions_Processing_Warning_Title':'Processing data',
        'Recopilator_Interactions_Processing_Warning_Text':'We are processing some data. That can will cause some inconsistent results. Wait until all the data are calculated to see the real results.',
        'Recopilator_Interactions_Processing_Warning_Button':'Refresh data',
        /* Buy audience */
        'BuyAudience_Go_Back': 'Back to test collectors',
        'BuyAudience_Title': 'Paid audience',
        'BuyAudience_Description': 'Don\'t have your own list of potential respondents? Alyze Audience allows you to send your test to the right people. Choose your target audience and we will send your test to real people in our global panel who matches your criteria.',
        'BuyAudience_Warning_Message': 'If you hire audience, this will be your audience acquisition method and cannot be changed.',
        'BuyAudience_Step1_Title': 'STEP 1: Who do you want answers from?',
        'BuyAudience_Step1_Description': 'Alyze is based on reliable, high quality data.',
        'BuyAudience_Step1_Link': 'Find out how the quality of our data helps you succeed.',
        'BuyAudience_Step1_Location_Title': 'Location',
        'BuyAudience_Step1_Location_Text': 'Location',
        'BuyAudience_Step1_Location_Text_All': 'All',
        'BuyAudience_Step1_Genre_Title': 'Genre',
        'BuyAudience_Step1_Genre_Text': 'Gender',
        'BuyAudience_Step1_Genre_Text_Male': 'Man',
        'BuyAudience_Step1_Genre_Text_Female': 'Woman',
        'BuyAudience_Step1_Genre_Text_Both': 'Both',
        'BuyAudience_Step1_Age_Title': 'Age',
        'BuyAudience_Step1_Age_Text': 'Age',
        'BuyAudience_Step2_Title': 'STEP 2: How many complete answers do you need?',
        'BuyAudience_Step2_Description': 'Margin of error ≤ 4%',
        'BuyAudience_Total_Cost_Title': 'Total cost',
        'BuyAudience_Total_Cost_Tax': 'VAT included',
        'BuyAudience_Total_Cost_Responses': 'Answers to be paid',
        'BuyAudience_Total_Cost_Responses_Total': 'Total answers',
        'BuyAudience_Total_Cost_Price': 'Price per response',
        'BuyAudience_Total_Cost_Duration': 'Test duration',
        'BuyAudience_Total_Cost_Rules': 'Segmentation rules',
        'BuyAudience_Total_Cost_Button': 'Go to payment',
        'BuyAudience_Promo': 'Promotion',
        'BuyAudience_Promo_Name': 'Promotion [var1]',
        'BuyAudience_Promo_Text': '2X (FREE)',
        'BuyAudience_Promo_Description': 'we double your paid number of answers for free.',
        'BuyAudience_Custom_Segments_Link': 'Please contact us ',
        'BuyAudience_Custom_Segments': 'for a more detailed segmentation.',
        /* Invite audience */
        'Settings_Modal_Title': 'Invitation settings',
        'Settings_Modal_Subtitle': 'Change the title and sender that will show on the invitations',
        'Settings_Modal_Confirm': 'Apply changes',
        'Settings_Modal_Cancel': 'Cancel',
        'InviteAudience_Send_All_Unsent_Title': 'Attention!',
        'InviteAudience_Send_All_Unsent_Text': 'There are one or more invitations waiting to send',
        'InviteAudience_Send_All_Unsent_Button': 'SEND ALL UNSENT INVITATIONS',
        'InviteAudience_Email_Configuration_Title': 'Settings',
        'InviteAudience_Email_Configuration_Description': 'Choose the test title and the sender that the testers will see in the invitation email. Once the first tester is invited, these fields cannot be changed.',
        'InviteAudience_Email_Configuration_Input_Test_Title': 'Default test title',
        'InviteAudience_Email_Configuration_Input_Sender': 'Default email sender',
        'InviteAudience_Email_Configuration_Button_Save': 'Apply changes',
        'InviteAudience_Title': 'Share your test',
        'InviteAudience_Description': 'Send e-mail invitations with unique link to access. You\'ll be able to block anyone, any time.',
        'InviteAudience_Button_Add': 'Add testers',
        'InviteAudience_Button_Reactivate': 'Resend',
        'InviteAudience_Button_Invite': 'Send',
        'InviteAudience_Button_Delete': 'Delete',
        'InviteAudience_Button_ImportCsv': 'Import CSV',
        'InviteAudience_Button_Configure_Email': 'Configure invitations',
        'InviteAudience_Actions_Send': 'Send test',
        'InviteAudience_Actions_Disable': 'Disable [var1] invitations',
        'InviteAudience_Actions_Enable': 'Enable [var1] invitations',
        'InviteAudience_Actions_Delete': 'Delete [var1] invitations',
        'InviteAudience_Actions_ExportCsv': 'Export to CSV',
        'InviteAudience_Actions': 'Actions',
        'InviteAudience_Table_Column1': 'Email',
        'InviteAudience_Table_Column2': 'Status',
        'InviteAudience_Table_Column3': 'Enable',
        'InviteAudience_Table_Column4': 'Actions',
        'InviteAudience_Table_Date': 'Added on [var1]',
        'InviteAudience_Table_Status_NoTracking': 'Not included in this test',
        'InviteAudience_Table_Status_Finished': 'Finished',
        'InviteAudience_Table_Status_Sent': 'Sent',
        'InviteAudience_Table_Status_Started': 'Started',
        'InviteAudience_Table_Status_Watermarking': 'Generating watermark...',
        'InviteAudience_Table_Status_Processing': 'Processing...',
        'InviteAudience_Table_Status_Added': 'Not sent yet',
        'InviteAudience_Table_Status_Left': 'Abandoned',
        'InviteAudience_Table_Status_Discarded': 'Discarded',
        'InviteAudience_Table_Loading_Sending': 'Sending email...',
        'InviteAudience_Table_Loading_Enabling': 'Enabling link...',
        'InviteAudience_Table_Loading_Disabling': 'Disabling link... ',
        'InviteAudience_ImportCsv_Modal_Title': 'Invite trusted testers to participate',
        'InviteAudience_ImportCsv_Modal_Subtitle': 'Add as many e-mails as you wish, then invite them and we\'ll send a link for you with the private access data.',
        'InviteAudience_ImportCsv_Modal_Csv_Example': 'You can download our <a href=[var1] download=[var2]>sample email list</a> in CSV format.',
        'InviteAudience_ImportCsv_Modal_Csv_Select_Column': 'Select the email column',
        'InviteAudience_ImportCsv_Modal_Confirm': 'Add testers',
        'InviteAudience_Modal_Title': 'Invite trusted testers to participate',
        'InviteAudience_Modal_Subtitle': 'Add as many e-mails as you wish and we\'ll send them a link for you with the private access data.',
        'InviteAudience_Modal_Input_Label_Email': 'Email',
        'InviteAudience_Modal_Button_Add_Email': 'Add another email',
        'InviteAudience_Modal_Confirm': 'Add testers',
        'InviteAudience_Modal_Warning_Message': 'If you add one or more emails, this will be your audience acquisition method and cannot be changed.',
        'InviteAudience_Empty_Test_Error_Modal_Title': 'Your test has no film',
        'InviteAudience_Empty_Test_Error_Modal_Subtitle': 'To share your test you need to add a film first.',
        'InviteAudience_Empty_Test_Error_Modal_Confirm': 'Continue',
        'InviteAudience_No_Films_Error_Modal_Title': 'Films limit reached',
        'InviteAudience_No_Films_Error_Modal_Subtitle': 'To create more tests, please contact us.',
        'InviteAudience_No_Films_Error_Modal_Confirm': 'Continue',
        // 'InviteAudience_First_Invitation_Notice_Modal_Title': 'Notice: The film cannot be changed',
        // 'InviteAudience_First_Invitation_Notice_Modal_Subtitle': 'The test will be published once the first invitation is sent and the film can no longer be edited.',
        'InviteAudience_First_Invitation_Notice_Modal_Title': 'Attention!',
        'InviteAudience_First_Invitation_Notice_Modal_Subtitle': 'The default settings has not been changed',
        'InviteAudience_First_Invitation_Notice_Modal_Text': 'The test will be send with the default test title (<b>[var1]</b>) and the default test sender (<b>[var2]</b>).<br/><br/>This settings can be changed before the invitation has been send.',
        'InviteAudience_First_Invitation_Notice_Modal_Confirm': 'Send',
        'InviteAudience_First_Invitation_Notice_Modal_Cancel': 'Cancel',
        'InviteAudience_DeleteTester_Modal_Title': 'Delete testers',
        'InviteAudience_DeleteTester_Modal_Subtitle': 'You can add them again later',
        'InviteAudience_DeleteTester_Modal_Text_Only': 'Are you sure you want to delete the tester <strong>[var1]</strong>?',
        'InviteAudience_DeleteTester_Modal_Text': 'Are you sure you want to delete <strong>[var1]</strong> testers?',
        'InviteAudience_DeleteTester_Modal_Confirm': 'Yes, delete',
        'InviteAudience_No_Testers_Yet_Title': 'No testers yet',
        'InviteAudience_No_Testers_Yet_Description': 'You can invite testers manually or upload a csv file.',
        /* Status */
        'Status_Browser_Title': 'Alyze | Test Results - Audience',
        'Status_Title': 'Audience',
        'Status_No_Audience': 'This Test does not yet have <br/> audience results',
        /* ContactUsWidget */
        'ContactUsWidget_Title': 'Any question or suggestion?',
        'ContactUsWidget_Text': 'If you need help or something specific, we are here to help you.',
        'ContactUsWidget_Button_Contact_Us': 'Contact us',
        /* Login */
        'Login_Browser_Title': 'Alyze | Log in',
        'Login_Email': 'Email',
        'Login_Email_Placeholder': 'Write your email',
        'Login_Password': 'Password',
        'Login_Password_Placeholder': 'Enter your password',
        'Login_Log_In_Button': 'Log in',
        'Login_Forgotten_Password': 'Have you forgotten your password?',
        'Login_Dont_Have_Account': 'Don\'t you have an account yet?',
        'Login_Create_Test_Button': 'CREATE YOUR FIRST TEST',
        'Login_Register_Button': 'REGISTER HERE',
        /* RememberPassword */
        'RememberPassword_Browser_Title': 'Alyze | Login',
        'RememberPassword_Title': 'Have you forgotten your password?',
        'RememberPassword_Send_Email_Button': 'Send',
        'RememberPassword_Back_To_Login_Button': 'Back to login',
        /* Singup */
        'Singup_Browser_Title': 'Alyze | Sign up',
        'Singup_Title': 'Create new account',
        'Singup_Name': 'Full name',
        'Singup_Name_Placeholder': 'Write your full name',
        'Singup_Code': 'Promotional Code (Optional)',
        'Singup_Code_Placeholder': '0000-0000-0000',
        'Singup_Create_Account_Button': 'CREATE NEW ACCOUNT',
        'Singup_Password': 'Password',
        'Singup_Password_Placeholder': 'Create your password',
        /* SingupConfirm */
        'SingupConfirm_Browser_Title': 'Alyze | Sign up confirmation',
        'SingupConfirm_Title': 'Activate your account!',
        'SingupConfirm_Text': 'Check your inbox to <br/><b>activate your account</b>',
        /* SubscriptionWidget */
        'SubscriptionWidget_Plan_Type': 'Account type',
        'SubscriptionWidget_Plan_Usage': 'Account usage',
        'SubscriptionWidget_Consumed_Tests': '<b>[var1]</b> / [var2] this month',
        'SubscriptionWidget_Consumed_Film_Tests': '<b>[var1]</b> / [var2]',
        'SubscriptionWidget_Btn_Subscribe_Plan': 'Upgrade plan',
        'SubscriptionWidget_Btn_Change_Plan': 'Change plan',
        'SubscriptionWidget_Yearly': 'Annual',
        'SubscriptionWidget_Montly': 'Monthly',
        'SubscriptionWidget_Tests_Number': 'tests',
        'SubscriptionWidget_Tests_Unlimited': 'UNLIMITED Tests',
        'SubscriptionWidget_Plan_Renovation': '[var1] will be restored',
        'SubscriptionWidget_Plan_Info_Renovation': 'Your subscription [var1] will be automatically renewed [var2]',
        'SubscriptionWidget_Plan_Info_Ends': 'Your subscription [var1] will end [var2]',
        'SubscriptionWidget_Legend_Tests': 'Tests',
        'SubscriptionWidget_Legend_Films': 'Film tests',
        /* SubscriptionPlan */
        'SubscriptionPlan_Featured_Badged': 'THE MOST POPULAR',
        'SubscriptionPlan_Price_Month': '/ MONTH',
        'SubscriptionPlan_Description': 'Publish <b>[var1] test</b> of up to <b>[var2] minutes</b> every month',
        'SubscriptionPlan_Description_Unlimited': 'Publish <b>all the tests you need</b> up to <b>[var1] minutes</b>',
        'SubscriptionPlan_Active_From': 'Active subscription<br/>since [var1]',
        'SubscriptionPlan_Active_To': 'Your subscription<br/>will end on [var1]',
        'SubscriptionPlan_Pay_Yearly': 'Annual payment',
        'SubscriptionPlan_Pay_Montly': 'Monthly payment',
        'SubscriptionPlan_Btn_Change_Plan': 'Change to [var1]',
        'SubscriptionPlan_Characteristics': '<p>Test up to <b>[var1] minutes</b></p>',
        'SubscriptionPlan_Montly_Tests': '<p><b>[var1] Tests</b> per month</p>',
        'SubscriptionPlan_Unlimited_Tests': '<p><b>UNLIMITED Tests </b></p>',
        'SubscriptionPlan_Btn_More_Details': ' See details',
        'SubscriptionPlan_Title': 'Choose your plan',
        'SubscriptionPlan_Subtitle_1': 'We offer you different plans so that you can choose the one that best suits your needs',
        'SubscriptionPlan_Subtitle_2': 'You can change it at any time',
        'SubscriptionPlan_Custom_Name': 'custom',
        'SubscriptionPlan_Custom_Description': 'Do you need a custom service?',
        'SubscriptionPlan_Btn_Contact': 'Contact',
        'SubscriptionPlan_Downgrade_Modal_Title': 'Cancel subscription',
        'SubscriptionPlan_Downgrade_Modal_Subtitle': 'Your current subscription will remain active for the paid period',
        'SubscriptionPlan_Downgrade_Text': 'Are you sure you want to cancel your subscription?',
        'SubscriptionPlan_Downgrade_Modal_Cancel': 'Cancel',
        'SubscriptionPlan_Downgrade_Modal_Confirm': 'Confirm',
        'SubscriptionPlan_Facturation_Montly': 'Monthly billing',
        'SubscriptionPlan_Facturation_Yearly': 'Annual billing',
        'SubscriptionPlan_Yearly_Discount': 'Save 16%',
        /* TopBar */
        'TopBar_My_Tests': 'My tests',
        'TopBar_Plans_And_Prices': 'Plans and prices',
        'TopBar_Change_Plan': 'Upgrade plan',
        'TopBar_Create_Test': 'Create test',
        'TopBar_Submenu_My_Account': 'My account',
        'TopBar_Submenu_Support_And_Help': 'Support and help',
        'TopBar_Submenu_Close_Session': 'Sign out',
        'TopBar_Add_New_Modal_Title': 'Create new test',
        'TopBar_Add_New_Modal_Subtitle': '',
        'TopBar_Add_New_Modal_Cancel': 'Cancel',
        'TopBar_Add_New_Modal_Confirm': 'Create test',
        'TopBar_Add_New_Film_Modal_Title': 'Create test',
        'TopBar_Add_New_Film_Modal_Subtitle': '',
        'TopBar_Add_New_Film_Modal_Cancel': 'Cancel',
        'TopBar_Add_New_Film_Modal_Confirm': 'Create test',
        'TopBar_Add_New_Input_Name': 'Name',
        'TopBar_Add_New_Input_Lang': 'Audience language',
        'TopBar_Add_New_Select_Type': 'What is the content of the test?',
        'TopBar_Add_New_Select_Type_Generic': 'Ads and marketing content',
        'TopBar_Add_New_Select_Type_Generic_Description': 'Test any type of multimedia content (image, audio and video) and get sales-oriented comparative metrics.<br/><br/>Ideal for <b>marketing and advertising</b>.',
        'TopBar_Add_New_Select_Type_Film': 'Movies or series with strict security needs',
        'TopBar_Add_New_Select_Type_Film_Description': 'Test long videos and get detailed emotional and attention metrics under the most <b>advanced anti-piracy protocols</b>.<br/><br/>Ideal for the <b>entertainment industry</b>.',
        'TopBar_Download_Box_Header': 'Downloading file(s)',
        'TopBar_Download_Box_File': 'File [var1]:',
        'TopBar_AutoSave_Title': 'Saving data',
        'TopBar_AutoSave_Text': 'Please don\'t close the window until data has been saved.',
        /* DurationBar */
        'DurationBar_Title': 'Estimated test duration',
        'DurationBar_Legend_Included': 'Included in your plan',
        'DurationBar_Legend_Non_Included': 'Not included in your plan',
        'DurationBar_Price_Response': '<b>[var1]</b> per response',
        'DurationBar_Price_Min_Info': 'Due to management and technical costs, the minimun price per response is $1',
         /* ContentHeader */
        'ContentHeader_Account': 'Account summary',
        'ContentHeader_Support_And_Help': 'Support and help',
        'ContentHeader_Design_Test': 'Design your test',
        'ContentHeader_Preview_And_Share': 'Generate a preview',
        'ContentHeader_Define_Audience': 'Define your target audience',
        'ContentHeader_Review_Test_And_Pay': 'Review test and pay',
        'ContentHeader_Get_Responses': 'Collect responses',
        'ContentHeader_Invite_Audience': 'Invite audience',
        'ContentHeader_Get_Results': 'Get the results',
        'ContentHeader_Saved_Button': "Saved",
        'ContentHeader_Save_Changes_Button': "Save Changes",
        'ContentHeader_Version_Control_Tooltip': 'Show version history',
        'ContentHeader_Edit_Published_Test_Tooltip': 'Cannot edit a published test',
        'ContentHeader_Enable_Demo': 'Enable demo',
        'ContentHeader_AutoSave_Tooltip_Saving': 'Saving...',
        'ContentHeader_AutoSave_Tooltip_Saved': 'Saved',
        /* Version control */
        'VersionControl_Title': 'Version history',
        'VersionControl_Version_Name': 'Version',
        'VersionControl_Versions_Empty': 'No version history yet',
        /* Save_Published_Campaign */
        'Save_Published_Campaign_Modal_Title': 'Edit a published test?',
        'Save_Published_Campaign_Modal_Subtitle': 'This test is already getting results',
        'Save_Published_Campaign_Modal_Cancel': 'No, cancel',
        'Save_Published_Campaign_Modal_Confirm': 'Yes, edit',
        'Save_Published_Campaign_Text': '<b>Warning:</b> you are editing one or more modules that already have answers.',
        'Save_Published_Campaign_Text_Error_Title': 'Editing a module',
        'Save_Published_Campaign_Text_Error': 'When you edit the content of a module, you will lose all the answers associated with it.',
        'Save_Published_Campaign_Text_Warning_Title': 'Reordering content',
        'Save_Published_Campaign_Text_Warning': 'If you change the order of the modules or stimuli you will not lose your answers, but it may alter the results.',
        'Save_Published_Campaign_Text_Ok_Title': 'Editing the appearance',
        'Save_Published_Campaign_Text_Ok': 'You can change the texts on the landing, introduction and finalize screens, as well as the appearance of your test without affecting your answers.',
        'Save_Styles_Published_Campaign_Modal_Title': 'Change the appearance of the test',
        'Save_Styles_Published_Campaign_Modal_Subtitle': 'This test is already getting results',
        'Save_Styles_Published_Campaign_Modal_Cancel': 'Cancel',
        'Save_Styles_Published_Campaign_Modal_Confirm': 'Save changes',
        'Save_Styles_Published_Campaign_Text':'These changes will not affect the results obtained.',
        /* Save_Published_Campaign */
        'Lite_User_Email_Modal_Title': 'Just a moment!',
        'Lite_User_Email_Modal_Subtitle': 'To save your results, we need an email address. (We will send you your password there in order to view your results)',
        'Lite_User_Email_Modal_Confirm': 'Save',
        'Lite_User_Email_Input_Email': 'Email',
        'Lite_User_Email_Input_Email_Placeholder': 'Enter your email',
        'Lite_User_Email_Input_Password': 'Password',
        'Lite_User_Email_Input_Password_Placeholder': 'Enter your password',
        'Lite_File_Browser_Title': 'Alyze Wizard | Select the content',
        'Lite_Link_Browser_Title': 'Alyze Wizard | Share the test',
        'Lite_Metrics_Browser_Title': 'Alyze Wizard | View results',
        'Lite_User_Login_Modal_Title': 'Enter your password',
        'Lite_User_Login_Modal_Subtitle': 'Enter your password to view your test results',
        'Lite_User_Login_Modal_Confirm': 'View results',
        'Lite_Test_Edit_Modal_Title': 'Editar test',
        'Lite_Test_Edit_Modal_Subtitle': 'Pon un título identificativo a tu test',
        'Lite_Test_Edit_Modal_Confirm': 'Guardar',
        'Lite_Test_Edit_Modal_Cancel': 'Cancelar',
        'Lite_Test_Edit_Modal_Input_Name': 'Título',
        'Lite_Test_Edit_Modal_Input_Name_Placeholder': 'Introduce un título ...',
        /*
        'Lite_User_Max_Tests_Reached_Modal_Title': 'Extend your plan to continue',
        'Lite_User_Max_Tests_Reached_Modal_Subtitle': 'You have exceeded this month\'s test limit',
        'Lite_User_Max_Tests_Reached_Modal_Confirm': 'Ok',
        'Lite_User_Max_Tests_Reached_Modal_Text_1': 'Your [var1] plan allows you to publish up to [var2] tests per month.',
        'Lite_User_Max_Tests_Reached_Modal_Text_2': 'To publish this test you must wait for the monthly period to renew or upgrade your account to a higher plan.'
        */
        'Lite_User_Max_Tests_Reached_Modal_Title': 'Limit reached',
        'Lite_User_Max_Tests_Reached_Modal_Subtitle': 'You have exceeded this month\'s test limit',
        'Lite_User_Max_Tests_Reached_Modal_Confirm': 'Ok',
        'Lite_User_Max_Tests_Reached_Modal_Text_1': 'You have reached the maximum number of monthly tests.',
        'Lite_User_Max_Tests_Reached_Modal_Text_2': 'To publish this test you must wait for the monthly period.',
        /* Wizard Login */
        'WizardLogin_Title': 'Login to your account and check your tests',
        'WizardLogin_Login_Button': 'Login to my account',
        'WizardLogin_Register_Button': 'I don\'t have an account yet',
        /* Wizard Start */
        'WizardStart_Welcome': 'Welcome to Alyze!',
        'WizardStart_Description': 'The first neuroscience tool that allows you to analyze the emotional response of your content to help you achieve your goals',
        'WizardStart_Account_Button': 'I have an account',
        'WizardStart_Guest_Button': 'Create your first test',
        /* Wizard File */
        'WizardFile_Creating_Test': 'Creating test',
        'WizardFile_Uploading_File': 'Uploading file',
        'WizardFile_Upload_Content_Title': 'Upload the content you would like to test',
        'WizardFile_Upload_Content_Description': 'Alyze analyzes the emotional response of any video or image',
        'WizardFile_Upload_Other_Content_Title_Video': 'Would you like to test it against another video?',
        'WizardFile_Upload_Other_Content_Title_Image': 'Would you like to test it against another image?',
        'WizardFile_Upload_Other_Content_Description': 'You can analyze your file or upload another one an compare them',
        'WizardFile_Test_Content': 'Test content',
        'WizardFile_Optional_Upload_Other_File': 'Upload another file to compare (Optional)',
        'WizardFile_Upload_Video_Button': 'Upload Video',
        'WizardFile_Upload_Image_Button': 'Upload Image',
        'WizardFile_Finish_Button': 'Finish',
        /* Wizard Link */
        'WizardLink_Publishing_Test': 'Publishing test',
        'WizardLink_Share_Title': 'Ready to share!',
        'WizardLink_Share_Text': 'Share this link with anybody and get the results in real time',
        'WizardLink_Link_Title': 'Test link',
        'WizardLink_Copy_Button': 'Copy',
        'WizardLink_Metrics_Button': 'See the results in real time',
        /* NewTest */
        'NewTest_Title': 'Create new test',
        'NewTest_Button_Contact': 'Contact us',
        'NewTest_Button_Select_This': 'Select this',
        'NewTest_Button_Selected': 'Selected',
        'NewTest_Button_Starts_With': 'Starting at',
        'NewTest_Note': 'NOTE: It is not necessary to pay for the test until the moment of its publication. Feel free to explore and try our different plans.'
    }
}