import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import T from '../Translate';
import Link from '../Link';
import Input from '../Input';
import TextArea from '../TextArea';

import { URL_ENVIRONMENT } from '../../config/global';

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({})

class TextModulePanel extends React.Component {
  _mount = true;
  _defaultState = {
    title: "",
    text: "",
    button: "",
    type: "text",
    buttonLink: "",
    buttonLinkText: "", 
    moduleData: [],
    dataValidated: true,
    showScrollbar: false,
    externalLink: false
  };
  _scrollbar = null;

  constructor(props) {
    super(props);
    this.state = this._defaultState;
  }

  componentDidMount() {

  }

  componentWillUnmount() {
    this._mount = false;
  }

  onInputChange(val) {
    const { onInputChange } = this.props;

    if (onInputChange)
      onInputChange(val);

    if (this._mount) {
      this.setState(val, ()=>{
        this.validateData();
      });
    }
  }

  resetData() {
    if (this._mount) {
      this.setState(this._defaultState, () => {
        this.refs.title.refresh();
        this.refs.text.refresh();
        this.refs.button.refresh();

        if (this.refs.buttonLink)
          this.refs.buttonLink.refresh();

        if (this.refs.buttonLinkText)
          this.refs.buttonLinkText.refresh();
      });
    }
  }

  validateData() {
    if (this._mount) {
      // const dataValidated = externalLink && buttonLinkText !== '' && externalLink !== '' || !externalLink;

      this.setState({ dataValidated: true });
    }
  }

  loadData(moduleData) {
    console.log(moduleData)
    const { onShow } = this.props;

    this.resetData();

    if (this._mount && moduleData) {
      this.setState({ moduleData });

      if (moduleData.name)
        this.setState({ name: moduleData.name });

      if (moduleData.title)
        this.setState({ title: moduleData.title }, () => this.refs.title.refresh());

      if (moduleData.text)
        this.setState({ text: moduleData.text }, () => this.refs.text.refresh());

      if (moduleData.button)
        this.setState({ button: moduleData.button }, () => this.refs.button.refresh());

      if (moduleData.type)
        this.setState({ type: moduleData.type });

      if (moduleData.externalLink)
        this.setState({ externalLink: moduleData.externalLink });
          
      if (moduleData.buttonLinkText)
        this.setState({ buttonLinkText: moduleData.buttonLinkText }, () => {
          if (this.refs.buttonLinkText)
            this.refs.buttonLinkText.refresh()
        });

      if (moduleData.buttonLink)
        this.setState({ buttonLink: moduleData.buttonLink }, () => {
          if (this.refs.buttonLink)
            this.refs.buttonLink.refresh()
        });

      if (onShow)
        onShow();
    }
  }

  show(moduleData) {
    this.loadData(moduleData);
  }

  getPreview() {
    const { campaignData } = this.props;
    const { moduleData } = this.state;
    const { campaignId, userId } = campaignData;

    const lang = localStorage.getItem('lang') || "en";

    const jwt = require('jwt-simple');
    const payload = {
      "sub": userId,
      "name": "",
      "iat": 0,
      "iss": "",
      "aud": "",
      "exp": 0,
      "forcePreview": true,
      "campaignId": campaignId
    };
    const secret = "super-tester-secret"
    const token = jwt.encode(payload, secret);

    return `${URL_ENVIRONMENT}?id=${campaignId}&token=${token}&s=${moduleData.id}&l=${lang}`;
  }

  toggleExternalLink() {
    const { externalLink } = this.state;

    this.onInputChange({ externalLink: !externalLink }, () => {
      if (this.refs.buttonLink)
        this.refs.buttonLink.refresh()

      if (this.refs.buttonLinkText)
        this.refs.buttonLinkText.refresh()
    })
  }

  render() {
    const { title, button, text, name, externalLink, buttonLinkText, buttonLink } = this.state;
    const { onCancel, t, goPreview, isCampaignPaid } = this.props;

    let moduleName = name;

    if (name === "Landing"){
      moduleName = t("Panels_Landing_Title");
    } else if (name === "Introduction"){
      moduleName = t("Panels_Introduction_Title");
    } else if (name === "Finish"){
      moduleName = t("Panels_Finish_Title");
    }

    let toggleClass = `button-toggle`;
    if (externalLink) {
      toggleClass = `${toggleClass} active`;
    }
    if (isCampaignPaid) {
      toggleClass = `${toggleClass} disabled`;
    }

    return (
      <div className="panel panel-text-screen">
        <div className="row flex">
          <div className="col">
            <h3 className="panel-title">
              <Link secondary className="no-border no-padding" onClick={() => { onCancel() }}>
                <i className="icon icon-right-open-1"></i>
              </Link>
              {moduleName}

              <div className="right">
                <Link secondary onClick={() => goPreview(this.getPreview())}>
                  <i className="icon icon-play"></i>
                  <T text="Panels_Btn_Preview" />
                </Link>
              </div>
            </h3>
            <div className="panel-inputs" id="panel-text-scrollview">
              <TextArea
                rows={2}
                placeholder=""
                name="title"
                label={t("TextModulePanel_Title")}
                value={title}
                ref="title"
                onChange={val => this.onInputChange(val)}
                disabled={isCampaignPaid}
              />
              <TextArea
                rows={8}
                placeholder=""
                name="text"
                label={t("TextModulePanel_Text")}
                value={text}
                ref="text"
                onChange={val => this.onInputChange(val)}
                disabled={isCampaignPaid}
              />
              <Input
                hidden={name === "Finish"}
                placeholder=""
                name="button"
                label={t("TextModulePanel_Button")}
                value={button}
                ref="button"
                onChange={val => this.onInputChange(val)}
                disabled={isCampaignPaid}
              />
              {name === "Finish" && (
                <div className="external-link">
                  <div className="row separator">
                    <label className="full-width"><T text="TextModulePanel_ExternalLink_Title" /></label>
                    <div className={toggleClass} onClick={() => this.toggleExternalLink()}>
                      <div className="handle"></div>
                    </div>
                  </div>
                  {externalLink && (
                    <div className="field">
                      <Input
                        placeholder=""
                        name="buttonLinkText"
                        label={t("TextModulePanel_ExternalLink_Button")}
                        value={buttonLinkText}
                        ref="buttonLinkText"
                        onChange={val => this.onInputChange(val)}
                        disabled={isCampaignPaid}
                      />
                      <Input
                        placeholder=""
                        name="buttonLink"
                        label={t("TextModulePanel_ExternalLink_Link")}
                        value={buttonLink}
                        ref="buttonLink"
                        onChange={val => this.onInputChange(val)}
                        disabled={isCampaignPaid}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation('translations', { withRef: true }),
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(TextModulePanel);