import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { Spinner } from 'react-activity';
import { withTranslation } from 'react-i18next';
import { AnalysisAccess } from '@sounditi/ft2-api';

// import MetricsModuleBins from '../../components/metrics/MetricsModuleBins';
import EmotionalOverview from '../../components/metrics/EmotionalOverview';
import AverageEmotions from '../../components/metrics/AverageEmotions';

import { generateRandomNumber } from '../../utils/global';
import { getMetricsFilter } from '../../utils/metrics';

import T from '../Translate';
import DownloadMetricsButtons from '../DownloadMetricsButtons';

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({})

class MetricsModule extends React.Component {
  _mount = true;
  _loadingBinsAnimation = undefined;

  constructor(props) {
    super(props);
    this.state = {
      showMediaPreview: true,
      showEmotionsDetails: false,
      showActivationDetails: false,
      currentStimuli: '',
      stimuliBinsA: undefined,
      stimuliBinsB: undefined,
      itemModuleWithBins: undefined
    };
  }

  componentWillMount() {
    // console.log('MetricsModule')
    const { itemModule } = this.props;

    // if (itemModule.tech === 'recognition')
    //   this.loadBins();
  }

  componentWillUnmount() {
    this._mount = false;
  }

  async loadBins() {
    // console.log('loadBins')
    const { itemModule, compareSegments, segmentAloaded, segmentBloaded } = this.props;

    const analysisAccess = new AnalysisAccess();
    const { campaignId, moduleId } = itemModule;
    let stimuliBinsA = undefined;
    let stimuliBinsB = undefined;

    let filterA = getMetricsFilter(segmentAloaded)
    let filterB = getMetricsFilter(segmentBloaded)

    if (itemModule.stimuli) {
      stimuliBinsA = await Promise.all(itemModule?.stimuli?.map(async (stimuli, key) => {
        const stimuliBins = await analysisAccess.getEmotionalEngagementBins(campaignId, moduleId, stimuli.id, filterA);
        return stimuliBins[0].emotionalEngagementBins;
      }));

      if (compareSegments) {
        stimuliBinsB = await Promise.all(itemModule?.metricsB?.stimuli?.map(async (stimuli, key) => {
          const stimuliBins = await analysisAccess.getEmotionalEngagementBins(campaignId, moduleId, stimuli.id, filterB);
          return stimuliBins[0].emotionalEngagementBins;
        }));
      }
    }

    this.setState({ stimuliBinsA, stimuliBinsB });
  }

  toggleMediaPreview() {
    const { showMediaPreview } = this.state;
    this.setState({ showMediaPreview: !showMediaPreview });
  }

  hideAllMetricsDetails() {
    this.setState({showEmotionsDetails: false});
    this.setState({showActivationDetails: false});
  }

  toggleEmotionsDetails(itemId) {
    const { showEmotionsDetails, currentStimuli } = this.state;

    if (currentStimuli !== '' && currentStimuli === itemId) {
      this.setState({showActivationDetails: false});
      this.setState({showEmotionsDetails: !showEmotionsDetails});
    } else {
      this.setState({ currentStimuli: itemId });
      this.setState({ showEmotionsDetails: true }); 
    }
  }

  toggleActivationDetails(itemId) {
    const { showActivationDetails, currentStimuli } = this.state;

    if (currentStimuli !== '' && currentStimuli === itemId) {
      this.setState({showEmotionsDetails: false});
      this.setState({showActivationDetails: !showActivationDetails});
    } else {
      this.setState({ currentStimuli: itemId });
      this.setState({ showActivationDetails: true }); 
    }
  }

  ModuleStimulisResults(stimuli, stimuliBins) {
    // console.log("ModuleStimulisResults")
    const {
      showEmotionsDetails,
      showActivationDetails,
      currentStimuli,
    } = this.state;
    // console.log(stimuliBins)
    
    let maxValue = 0;
    let parsedStimuliBins = stimuliBins;

    stimuliBins.forEach(stimuliBin => {
      if (parseInt(stimuliBin.percentage) > maxValue)
        maxValue = stimuliBin.percentage
    })

    parsedStimuliBins = stimuliBins.map(simuliBin => {
      simuliBin.percentageRelative = parseInt((100 * parseInt(simuliBin.percentage)) / maxValue);
      return simuliBin;
    });

    const { t, itemModule } = this.props;

    const testersNumber = stimuli?.metrics?.numberOfTesters;
    const scoreFormated = testersNumber < 1 ? "-" : stimuli?.metrics?.score;

    let scoreLabel = t('MetricsModule_Score_Label_Poor');
    let itemClass = "metric-content";

    if (testersNumber < 1) {
      scoreLabel = t('MetricsModule_Score_Label_No_Results');
    } else if (scoreFormated >= 8.5) {
      scoreLabel = t('MetricsModule_Score_Label_Very_Good');
      itemClass = itemClass + " good"
    } else if (scoreFormated >= 7) {
      scoreLabel = t('MetricsModule_Score_Label_Good');
      itemClass = itemClass + " good"
    } else if (scoreFormated >= 5) {
      scoreLabel = t('MetricsModule_Score_Label_Regular');
      itemClass = itemClass + " medium"
    } else {
      itemClass = itemClass + " bad"
    }
  
    return (
      <>
        <div className={itemClass} key={generateRandomNumber()}>
          <div className="score">{scoreFormated}</div>
          <div className="score-resume">
            <div className="score-title">{scoreLabel}</div>
            <div className="score-legend">
              <T text="MetricsModule_Score_Legend" var1={testersNumber} />
            </div>
          </div>
          <div className="testers-score">
            {/* <MetricsModuleBins bins={stimuliBins} /> */}
            {parsedStimuliBins && (
              <div className={`score-metric ${parsedStimuliBins ? '' : 'loading'}`}>
                <div className="bar-1" style={{ height: `${parsedStimuliBins[0].percentageRelative}%` }}></div>
                <div className="bar-2" style={{ height: `${parsedStimuliBins[1].percentageRelative}%` }}></div>
                <div className="bar-3" style={{ height: `${parsedStimuliBins[2].percentageRelative}%` }}></div>
                <div className="bar-4" style={{ height: `${parsedStimuliBins[3].percentageRelative}%` }}></div>
                <div className="bar-5" style={{ height: `${parsedStimuliBins[4].percentageRelative}%` }}></div>
                <div className="bar-6" style={{ height: `${parsedStimuliBins[5].percentageRelative}%` }}></div>
                <div className="bar-7" style={{ height: `${parsedStimuliBins[6].percentageRelative}%` }}></div>
                <div className="bar-8" style={{ height: `${parsedStimuliBins[7].percentageRelative}%` }}></div>
                <div className="bar-9" style={{ height: `${parsedStimuliBins[8].percentageRelative}%` }}></div>
                <div className="bar-10" style={{ height: `${parsedStimuliBins[9].percentageRelative}%` }}></div>
              </div>
            )}
          </div>
        </div>
        <div className="metric-details">
          <div className='metric-details-title'>
            <div
              className={showEmotionsDetails && (currentStimuli === stimuli.id) ? 'button-tab active' : 'button-tab'}
              onClick={ () => { this.hideAllMetricsDetails(); this.toggleEmotionsDetails(stimuli.id); }}
            >
              <T text="Metrics_Details_Emotions_Title" />
            </div>
            <div
              className={showActivationDetails && (currentStimuli === stimuli.id) ? 'button-tab active' : 'button-tab'}
              onClick={ () => { this.hideAllMetricsDetails(); this.toggleActivationDetails(stimuli.id); }}
            >
              <T text="Metrics_Details_Activation_Title" />
            </div>
          </div>
          {(currentStimuli === stimuli.id) && (
            <>
              <div className={showEmotionsDetails ? 'metric-details-content active' : 'metric-details-content'}>
                <T text="Metrics_Details_Emotions_Text" />
                {console.log(itemModule)}
                {console.log(stimuli.metrics.average_emotions)}
                {console.log(testersNumber)}
                <AverageEmotions
                  activeModule={itemModule}
                  activeModuleData={stimuli.metrics.average_emotions}
                  testersNumber={testersNumber}
                />
              </div>
              <div className={showActivationDetails ? 'metric-details-content active' : 'metric-details-content'}>
                <T text="Metrics_Details_Activation_Text" />
                <EmotionalOverview
                  ref="emotionalOverview"
                  activeModule={itemModule}
                  activeModuleTimeline={stimuli.metrics.timeline}
                  activeModuleFileName={stimuli.fileName}
                  metricDuration={itemModule.duration}
                />
              </div>
            </>
          )}
        </div>
      </>
    )
  }

  ModuleQAResults(stimuli) {
    const { showMediaPreview } = this.state;
    const { t, itemModule } = this.props;

    if (!stimuli)
      return false;

    let itemClass = "metrics-item metric-bars";
    let name = stimuli.answer || stimuli.name;

    if (stimuli.percentage >= 70) {
      itemClass = itemClass + " good"
    } else if (stimuli.percentage >= 50) {
      itemClass = itemClass + " medium"
    } else {
      itemClass = itemClass + " bad"
    }

    return (
      <div className={itemClass} key={generateRandomNumber()}>
        <div className="item-name">{name}<span className="responses-count">{stimuli.value || 0} <T text="MetricsModule_Responses_Count" /></span></div>
        {(itemModule.type === "video" || itemModule.type === "image") && (
          <div className={showMediaPreview ? "image" : "image hide"}>
            {!stimuli.thumbnail && (
              <div className="loader-widget">
                <Spinner speed={0.8} color="#00C7E7" size={20} />
              </div>
            )}
            <div className="media-type">
              <i className={`icon ${itemModule.type === "video" ? 'icon-video' : 'icon-picture'}`}></i>
            </div>
            {/* <div className="btn-play-media">Reproducir contenido</div> */}
            <img src={stimuli.thumbnail} />
            {/* <video controls>
              <source src={activeModuleFilesURIS[stimuli.fileName].file} type="video/mp4" />
            </video> */}
          </div>
        )}
        {(itemModule.type === "audio") && (
          <div className={showMediaPreview ? "audio" : "audio hide"}>
            {!stimuli.file && (
              <div className="loader-widget">
                <Spinner speed={0.8} color="#00C7E7" size={20} />
              </div>
            )}
            <div className="media-type">
              <i className={`icon icon-headphones`}></i>
            </div>
            <audio controls>
              <source src={stimuli.file} />
            </audio>
          </div>
        )}
        <div className="metric-bar">
          <div className="bar" style={{ width: `${stimuli.percentage || 0}%` }}>{stimuli.percentage || 0}%</div>
        </div>
      </div>
    )
  }

  ModuleStimulis() {
    // console.log("ModuleStimulis")
    const { showMediaPreview } = this.state;
    const { t, itemModule, compareSegments, segmentAName, segmentBName, } = this.props;

    let returnComponent = undefined;
    
    if (itemModule.stimuli) {
      returnComponent = itemModule?.stimuli?.map((stimuli, key) => {
        // console.log(stimuli);
        const stimuliB = compareSegments ? itemModule?.metricsB?.stimuli.filter(stimuliB => stimuliB.id === stimuli.id)[0] : undefined;
        const position = key + 1;
        const stimuliBinsA = stimuli?.emotionalEngagementBins ? stimuli.emotionalEngagementBins : undefined;
        const stimuliBinsB = stimuliB?.emotionalEngagementBins ? stimuliB.emotionalEngagementBins : undefined;

        // console.log(stimuliBinsA)

        return (
          <div className="metrics-item recognition" key={generateRandomNumber()}>
            <div className="stimulus-title">
              <div className="item-name"><span className="position">{position}º</span>{stimuli.fileName}</div>
              <DownloadMetricsButtons
                campaignId={itemModule?.campaignId}
                moduleId={itemModule?.moduleId}
                stimulusId={stimuli?.id}
                exportMetricsCsvCallback={this.props.exportMetricsCsvCallback}
              />
            </div>
            {(itemModule.type === "video" || itemModule.type === "image") && (
              <div className={showMediaPreview ? "image" : "image hide"}>
                {!stimuli.thumbnail && (
                  <div className="loader-widget">
                    <Spinner speed={0.8} color="#00C7E7" size={20} />
                  </div>
                )}
                <div className="media-type">
                  <i className={`icon ${itemModule.type === "video" ? 'icon-video' : 'icon-picture'}`}></i>
                </div>
                {/* <div className="btn-play-media">Reproducir contenido</div> */}
                <img src={stimuli.thumbnail} />
                {/* <video controls>
                  <source src={activeModuleFilesURIS[stimuli.fileName].file} type="video/mp4" />
                </video> */}
              </div>
            )}
            {(itemModule.type === "audio") && (
              <div className={showMediaPreview ? "audio" : "audio hide"}>
                {!stimuli.file && (
                  <div className="loader-widget">
                    <Spinner speed={0.8} color="#00C7E7" size={20} />
                  </div>
                )}
                <div className="media-type">
                  <i className={`icon icon-headphones`}></i>
                </div>
                <audio controls>
                  <source src={stimuli.file} />
                </audio>
              </div>
            )}
            {compareSegments ? (
              <div className="two-columns stimulis">
                <div className="column-a">
                  <div className="segment-name">{segmentAName}</div>
                  {this.ModuleStimulisResults(stimuli, stimuliBinsA)}
                </div>
                <div className="column-b">
                  <div className="segment-name">{segmentBName}</div>
                  {this.ModuleStimulisResults(stimuliB, stimuliBinsB)}
                </div>
              </div>
            ) : (
              this.ModuleStimulisResults(stimuli, stimuliBinsA)
            )}

            {/* this.ModuleStimulisResults(stimuli, key + "a") */}
            
          </div>
        );
      });
    } else {
      if (itemModule.tech && itemModule.tech === "play")
        return false;

      let items = itemModule.videos;
      let itemsB = itemModule?.videosB || undefined;

      if (itemModule.questions) {
        items = itemModule?.questions[0]?.answers;
        itemsB = itemModule?.questions[0]?.answersB || undefined;
      } else if (itemModule.images) {
        items = itemModule.images;
        itemsB = itemModule?.imagesB || undefined;
      } else if (itemModule.audios) {
        items = itemModule.audios;
        itemsB = itemModule?.audiosB || undefined;
      }

      // console.log({ itemModule, items });

      if (items && items.length)
        returnComponent = items.map((stimuli, key) => {
          const stimuliB = itemsB ? itemsB.filter(stimuliB => stimuliB.id === stimuli.id)[0] : undefined;

          // console.log(stimuli);
          // console.log(stimuliB);

          if (!compareSegments)
            return (
              <div key={generateRandomNumber()}>
                {this.ModuleQAResults(stimuli)}
              </div>
            )

          return (
            <div className="two-columns stimulis qa" key={generateRandomNumber()}>
              <div className="column-a">
                {key === 0 && (
                  <div className="segment-name">{segmentAName}</div>
                )}
                {this.ModuleQAResults(stimuli)}
              </div>
              <div className="column-b">
                {key === 0 && (
                  <div className="segment-name">{segmentBName}</div>
                )}
                {this.ModuleQAResults(stimuliB)}
              </div>
            </div>
          )
        });
    }

    return returnComponent;
  };

  render() {
    // console.log("Render")
    const { showMediaPreview } = this.state;
    const { t, position, itemModule } = this.props;

    // console.log(itemModule)

    const moduleName = itemModule.type === "qa" ? itemModule.questions[0].name : itemModule.name;
    const isMedia = itemModule.type === "qa" ? false : true;

    return (
      <div className={`module-wrapper`}>
        <div className="module-title">
          <span className="position">{position}</span>
          <h2>{moduleName} {itemModule?.type !== 'qa' && <span className="tech">{itemModule?.tech}</span>}</h2>
          {isMedia && (
            <div className="btn-icon btn-toggle-media-preview" onClick={() => this.toggleMediaPreview()}>
              <div className="label"><i className={`icon ${itemModule.type === "video" ? 'icon-video' : 'icon-picture'}`}></i></div>
              <div className={showMediaPreview ? 'button-toggle active' : 'button-toggle'}>
                <div className="handle"></div>
              </div>
            </div>
          )}
        </div>
        {this.ModuleStimulis()}
      </div>
    )
  }
}

export default compose(
  withTranslation('translations', { withRef: true }),
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(MetricsModule);
