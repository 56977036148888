import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { Spinner } from 'react-activity';
import { withTranslation } from 'react-i18next';

import EmotionalOverviewFilm from '../../components/metrics/EmotionalOverviewFilm';
import AverageEmotionsFilms from '../../components/metrics/AverageEmotionsFilms';

import T from '../Translate';
import DownloadMetricsButtons from '../DownloadMetricsButtons';

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({})

class FilmsMetricsModule extends React.Component {
  _mount = true;

  constructor(props) {
    super(props);
    this.state = {
      previewTime: 0,
      previewDuration: 0,
      timelinePositionMouseX: 0,
      timelinePositionData: [],
      timelinePositionMouseData: [],
    };
  }

  componentWillMount() {
    // const { itemModule } = this.props;
    // const { filmDataAllSegments } = this.state;

    // if (itemModule && !filmDataAllSegments)
    //   this.setState({ filmDataAllSegments: JSON.parse(JSON.stringify(itemModule)) });
  }

  componentWillUnmount() {
    this._mount = false;
  }

  initFilmPlayer() {
    const { allModulesMetrics, itemModule, position } = this.props;

    const filmData = itemModule;

    if (document.getElementById(`videoPreview_${position}`)) {
      const videoPreview = document.getElementById(`videoPreview_${position}`);
      
      videoPreview.ontimeupdate = () => {
        const playerPositionPercentage = 100 * videoPreview.currentTime / videoPreview.duration;
        const totalPoints = filmData.metrics.totalMetricsFR.timeline.length;
        const timePosition = parseInt(totalPoints / 100 * parseInt(playerPositionPercentage));
        const timelinePositionData = filmData.metrics.totalMetricsFR.timeline[timePosition];

        this.setState({
          previewTime: videoPreview.currentTime,
          previewDuration: videoPreview.duration,
          timelinePositionData
        });
      }
    }
  }

  renderTime(raw_seconds) {
    const minutes = Math.floor(parseInt(raw_seconds) / 60);
    const seconds = parseInt(raw_seconds) - minutes * 60;

    if (minutes < 1)
      return `${seconds}’’`;

    if (seconds < 1)
      return `${minutes}’`;

    return `${minutes}’  ${seconds}’’`;
  }

  onClickRewind() {
    const { position } = this.props;
    if (document.getElementById(`videoPreview_${position}`)) {
      const videoPreview = document.getElementById(`videoPreview_${position}`);
      videoPreview.currentTime = 0;
    }
  }

  onClickPlay() {
    const { position } = this.props;
    if (document.getElementById(`videoPreview_${position}`)) {
      const videoPreview = document.getElementById(`videoPreview_${position}`);

      if (videoPreview.paused) {
        videoPreview.play();
      } else {
        videoPreview.pause();
      }
    }
  }

  render() {
    const { 
      position,
      itemModule, 
      compareSegments, 
      segmentAloaded, 
      segmentBloaded,
      segmentAName,
      segmentBName,
      loadingFilm,
      t,
      filmDataAllSegments
    } = this.props;
    const { 
      previewTime,
      previewDuration,
      timelinePositionData,
      timelinePositionMouseX,
      timelinePositionMouseData
    } = this.state;

    const filmData = itemModule;

    let filmTimelineRows = [];
    let filmTimelineFlashes = [];
    let filmTimelineFlashesB = [];
    let filmItem = filmData ? filmData.stimuli[0] : undefined;
    let timePercentage = 100;
    let videoPreview = undefined;

    if (filmData && filmData.videos && filmData.videos.length > 0 && !filmItem) {
      filmItem = {
        duration: filmData.videos[0].duration,
        fileName: filmData.videos[0].fileName
      }
    }

    let dataQualityClass = "poor";
    let dataQualityWidth = filmDataAllSegments?.emotions?.dataQuality?.percentage.toFixed(2);
    let testForNextStep = filmDataAllSegments?.emotions?.dataQuality?.lowMediumBreakpoint - filmDataAllSegments?.audience?.realizedTests;
    let dataQualityLegend = <T text="MetricsFilm_Emotions_DataQuality_Legend_Get_Medium" var1={testForNextStep} />;

    if (dataQualityWidth > 0 && dataQualityWidth < 1) {
      dataQualityWidth = 1;
    }

    dataQualityWidth =  dataQualityWidth + "%";

    if (filmDataAllSegments?.audience?.realizedTests >= filmDataAllSegments?.emotions?.dataQuality?.maximumBreakpoint) {
      dataQualityLegend = <T text="MetricsFilm_Emotions_DataQuality_Legend_Excellent" />;
      dataQualityClass = "good";
    } else if (filmDataAllSegments?.audience?.realizedTests >= filmDataAllSegments?.emotions?.dataQuality?.mediumHighBreakpoint) {
      testForNextStep = filmDataAllSegments?.emotions?.dataQuality?.maximumBreakpoint - filmDataAllSegments?.audience?.realizedTests;
      dataQualityLegend = <T text="MetricsFilm_Emotions_DataQuality_Legend_Get_Excellent" var1={testForNextStep.toString()} />;
      dataQualityClass = "good";
    } else if (filmDataAllSegments?.audience?.realizedTests >= filmDataAllSegments?.emotions?.dataQuality?.lowMediumBreakpoint) {
      testForNextStep = filmDataAllSegments?.emotions?.dataQuality?.mediumHighBreakpoint - filmDataAllSegments?.audience?.realizedTests;
      dataQualityLegend = <T text="MetricsFilm_Emotions_DataQuality_Legend_Get_High" var1={testForNextStep.toString()} />;
      dataQualityClass = "regular";
    }

    if (document.getElementById(`videoPreview_${position}`))
      videoPreview = document.getElementById(`videoPreview_${position}`);

    const positionBarLeft = 100 * previewTime / previewDuration;

    const raw_seconds = parseInt(filmData.videos[0].duration);
    const minutes = Math.floor(raw_seconds / 60);
    const seconds = raw_seconds - minutes * 60;
    const featuredInterval = minutes > 30 ? 10 : 1;
    timePercentage = (100 * (minutes * 60)) / raw_seconds;

    for (var i = 0; i <= minutes; i++) {
      filmTimelineRows.push(
        <div key={i} className={(i % featuredInterval) == 0 ? 'featured' : ''}>
          {((i % featuredInterval) == 0) && (
            <div className="label">{i}’</div>
          )}
        </div>
      );
    }

    filmTimelineFlashes = filmData.flashes.map((flash, key) => {

      let startAt = 100 * flash.bin[0] / raw_seconds;
      let endsAt = undefined;
      let durationTime = `${this.renderTime(flash.bin[0])}`;
      let outsideLeft = false;
      let outsideRight = false;

      if (flash.bin[1]) {
        endsAt = (100 * parseInt(flash.bin[1]) / raw_seconds) - startAt;
        endsAt = endsAt > 100 ? 100 : endsAt;
        durationTime = `${durationTime} - ${this.renderTime(parseInt(flash.bin[1]))}`;
      }

      let left = startAt ? `${startAt.toFixed(1)}%` : "0px";
      let width = endsAt ? `${endsAt.toFixed(1)}%` : "5px";
      let type = "good";

      if (flash.label === "loss-of-attention") {
        type = "bad"
      } else if (flash.label === "little-activation") {
        type = "poor"
      } else if (flash.label === "max-activation-peak") {
        type = "peak"
      } else if (flash.label === "no-data") {
        type = "no-data"
      }

      const minute = Math.floor(flash.bin[0] / 60);

      return (
        <div key={key} className={`track ${type}`} style={{ left, width }}>
          <div className="popup">
            <div className="title">{t(`MetricsFilm_Flashes_${flash.label}`)}<br/>{durationTime}</div>
            {/* <div className="thumbnail"><img src={thumbnailBlob} /></div> */}
          </div>
        </div>
      );
    });

    if (compareSegments && filmData?.metricsB?.flashes) {
      filmTimelineFlashesB = filmData?.metricsB?.flashes.map((flash, key) => {

        let startAt = 100 * flash.bin[0] / raw_seconds;
        let endsAt = undefined;
        let durationTime = `${this.renderTime(flash.bin[0])}`;
        let outsideLeft = false;
        let outsideRight = false;

        if (flash.bin[1]) {
          endsAt = (100 * parseInt(flash.bin[1]) / raw_seconds) - startAt;
          endsAt = endsAt > 100 ? 100 : endsAt;
          durationTime = `${durationTime} - ${this.renderTime(parseInt(flash.bin[1]))}`;
        }

        let left = startAt ? `${startAt.toFixed(1)}%` : "0px";
        let width = endsAt ? `${endsAt.toFixed(1)}%` : "5px";
        let type = "good";

        if (flash.label === "loss-of-attention") {
          type = "bad"
        } else if (flash.label === "little-activation") {
          type = "poor"
        } else if (flash.label === "max-activation-peak") {
          type = "peak"
        } else if (flash.label === "no-data") {
          type = "no-data"
        }

        const minute = Math.floor(flash.bin[0] / 60);

        return (
          <div key={key} className={`track ${type}`} style={{ left, width }}>
            <div className="popup">
              <div className="title">{t(`MetricsFilm_Flashes_${flash.label}`)}<br/>{durationTime}</div>
              {/* <div className="thumbnail"><img src={thumbnailBlob} /></div> */}
            </div>
          </div>
        );
      });
    }

    return (
      <div className="module-wrapper">
        <div className="module-title">
          <span className="position">{position}</span>
          <h2>{itemModule.filmName}</h2>
          {/* <div className="btn-icon btn-toggle-media-preview">
            <div className="label"><i className="icon icon-picture"></i></div>
            <div className="button-toggle active">
              <div className="handle"></div>
            </div>
          </div> */}
          <DownloadMetricsButtons
            campaignId={itemModule?.campaignId}
            moduleId={itemModule?.moduleId}
            stimulusId={itemModule?.videos[0].fileName}
            exportMetricsCsvCallback={this.props.exportMetricsCsvCallback}
          />
        </div>
        <div className="metrics-item medium">

          <div className="content-wrapper recopilator">
            <h2><T text="MetricsFilm_Flashes_Title" /> {compareSegments ? segmentAName : ""}</h2>
            {compareSegments && (
              <div className="segment-name">
                {segmentAName}
              </div>
            )}
            {loadingFilm ? (
              <div className="loader-widget">
                <Spinner speed={0.8} color="#00C7E7" size={20} />
              </div>
            ) : (
              <>
                <div className="blink-timeline flashes">
                  <div className="timeline">
                    <div className="times" style={{ width: `${timePercentage}%` }}>
                      {filmTimelineRows}
                    </div>
                    {filmTimelineFlashes}
                  </div>
                </div>
              </>
            )}
            {compareSegments && (
              <>
                <div className="segment-name segment-b">
                  {segmentBName}
                </div>
                {loadingFilm ? (
                  <div className="loader-widget">
                    <Spinner speed={0.8} color="#00C7E7" size={20} />
                  </div>
                ) : (
                  <div className="blink-timeline flashes">
                    <div className="timeline">
                      <div className="times" style={{ width: `${timePercentage}%` }}>
                        {filmTimelineRows}
                      </div>
                      {filmTimelineFlashesB}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>

          <div className="content-wrapper recopilator">
            <h2><T text="MetricsFilm_Emotions_Title" /></h2>
            <div className="content-widget summary compact">
              {compareSegments ? (
                <>
                  <div className="summary">
                    <div className="col featured">
                      <div className="title"><T text="MetricsFilm_Audience_RealizedTests" /></div>
                      {console.log(filmDataAllSegments)}
                      <div className="value">{filmDataAllSegments?.audience?.realizedTests || "-"}</div>
                    </div>
                    <div className="segment-block">
                      <div className="segment-name">
                        {segmentAName}
                      </div>
                      <div className="segment-values">
                        <div className="col">
                          <div className="title"><T text="MetricsFilm_Emotions_EmotionOverPercentage" /></div>
                          <div className="value green">{this.renderTime(filmData?.emotions?.emotionOverPercentage)}
                          </div>
                        </div>
                        <div className="col">
                          <div className="title"><T text="MetricsFilm_Emotions_LossOfAttention" /></div>
                          <div className="value green">{this.renderTime(filmData?.emotions?.lossOfAttention)}
                          </div>
                        </div>
                        <div className="col">
                          <div className="title"><T text="MetricsFilm_Emotions_PredominantEmotion" /></div>
                          <div className="value green">{filmData?.emotions?.predominantEmotion}</div>
                        </div>
                        <div className="col">
                          <div className="title"><T text="MetricsFilm_Emotions_SecondaryEmotion" /></div>
                          <div className="value green">{filmData?.emotions?.secondaryEmotion}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="summary">
                    <div className="col data-quality">
                      <div className="title"><T text="MetricsFilm_Emotions_DataAccuracy" /></div>
                      <div className="value-meter">
                        <div className="quality-meter">
                          <div className={dataQualityClass} style={{ width: dataQualityWidth }}></div>
                        </div>
                        <div className={"label " + dataQualityClass}>
                          {filmDataAllSegments?.emotions?.dataQuality?.label}
                        </div>
                      </div>
                      <div className="legend">
                        {dataQualityLegend}
                      </div>
                    </div>
                    <div className="segment-block">
                      <div className="segment-name">
                        {segmentBName}
                      </div>
                      <div className="segment-values">
                        <div className="col">
                          <div className="title"><T text="MetricsFilm_Emotions_EmotionOverPercentage" /></div>
                          <div className="value green">{this.renderTime(filmData?.metricsB?.emotions?.emotionOverPercentage)}
                          </div>
                        </div>
                        <div className="col">
                          <div className="title"><T text="MetricsFilm_Emotions_LossOfAttention" /></div>
                          <div className="value green">{this.renderTime(filmData?.metricsB?.emotions?.lossOfAttention)}
                          </div>
                        </div>
                        <div className="col">
                          <div className="title"><T text="MetricsFilm_Emotions_PredominantEmotion" /></div>
                          <div className="value green">{filmData?.metricsB?.emotions?.predominantEmotion}</div>
                        </div>
                        <div className="col">
                          <div className="title"><T text="MetricsFilm_Emotions_SecondaryEmotion" /></div>
                          <div className="value green">{filmData?.metricsB?.emotions?.secondaryEmotion}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="summary">
                    <div className="col featured">
                      <div className="title"><T text="MetricsFilm_Audience_RealizedTests" /></div>
                      <div className="value">{filmData?.audience?.realizedTests || "-"}</div>
                    </div>
                    <div className="col">
                      <div className="title"><T text="MetricsFilm_Emotions_EmotionOverPercentage" /></div>
                      <div className="value green">{this.renderTime(filmData?.emotions?.emotionOverPercentage)}
                      </div>
                    </div>
                    <div className="col">
                      <div className="title"><T text="MetricsFilm_Emotions_LossOfAttention" /></div>
                      <div className="value green">{this.renderTime(filmData?.emotions?.lossOfAttention)}
                      </div>
                    </div>
                  </div>
                  <div className="summary">
                    <div className="col data-quality">
                      <div className="title"><T text="MetricsFilm_Emotions_DataAccuracy" /></div>
                      <div className="value-meter">
                        <div className="quality-meter">
                          <div className={dataQualityClass} style={{ width: dataQualityWidth }}></div>
                        </div>
                        <div className={"label " + dataQualityClass}>
                          {filmData?.emotions?.dataQuality?.label}
                        </div>
                      </div>
                      <div className="legend">
                        {dataQualityLegend}
                      </div>
                    </div>
                    <div className="col">
                      <div className="title"><T text="MetricsFilm_Emotions_PredominantEmotion" /></div>
                      <div className="value green">{filmData?.emotions?.predominantEmotion}</div>
                    </div>
                    <div className="col">
                      <div className="title"><T text="MetricsFilm_Emotions_SecondaryEmotion" /></div>
                      <div className="value green">{filmData?.emotions?.secondaryEmotion}</div>
                    </div>
                  </div>
                </>  
              )}
            </div>
          </div>

          <div className="content-wrapper recopilator film-metrics">
            <h2><T text="MetricsFilm_Metrics_Title" /></h2>
            {(loadingFilm || !(filmItem && filmItem.fileName && filmItem.duration)) && (
              <div className="loader-widget">
                <Spinner speed={0.8} color="#00C7E7" size={20} />
              </div>
            )}
            {(!loadingFilm && filmItem && filmItem.fileName && filmItem.duration) && (
              <div>
                <div className="controls">
                  {/*
                  <span className="rewind" onClick={() => this.onClickRewind()}>
                    <i className="icon-to-start-3"></i>
                  </span>
                  */}
                  <span className="play" onClick={() => this.onClickPlay()}>
                    {(videoPreview && videoPreview.paused || !videoPreview) && (
                      <i className="icon-play-4"></i>
                    )}
                    {(videoPreview && !videoPreview.paused) && (
                      <i className="icon-pause-4"></i>
                    )}
                  </span>
                </div>
                <div className="player">
                  {(filmItem?.file && (videoPreview && videoPreview.paused || !videoPreview)) && (
                    <div className="play-message"><T text="MetricsFilm_Metrics_Play_Message" /></div>
                  )}
                  {filmItem?.file ? (
                    <video onLoadedData={e => this.initFilmPlayer()} id={`videoPreview_${position}`} onClick={e => this.onClickPlay()} controls={false} preload="true" src={filmItem.file} />
                  ) : (
                    <div className="loader-widget">
                      <Spinner speed={0.8} color="#00C7E7" size={20} />
                    </div>
                  )}
                </div>
                <div
                  id="video-timeline"
                  className={`video-timeline ${compareSegments ? 'have-comparative' : ''}`}
                  onMouseMove={e => {
                    const rect = document.getElementById("video-timeline").getBoundingClientRect();
                    const x = e.clientX - rect.left;
                    const timelinePositionMouseX = 100 * x / rect.width;

                    const totalPoints = filmData.metrics.totalMetricsFR.timeline.length;
                    const timePosition = parseInt(totalPoints / 100 * parseInt(timelinePositionMouseX));
                    const timelinePositionMouseData = filmData.metrics.totalMetricsFR.timeline[timePosition];
                    this.setState({ timelinePositionMouseX, timelinePositionMouseData });
                  }}
                  onClick={e => {
                    const rect = document.getElementById("video-timeline").getBoundingClientRect();
                    const x = e.clientX - rect.left;
                    const timelinePositionMouseX = 100 * x / rect.width;

                    const totalPoints = filmData.metrics.totalMetricsFR.timeline.length;
                    const timePosition = parseInt(totalPoints / 100 * parseInt(timelinePositionMouseX));
                    const timelinePositionData = filmData.metrics.totalMetricsFR.timeline[timePosition];
                    if (document.getElementById(`videoPreview_${position}`)) {
                      const videoPreview = document.getElementById(`videoPreview_${position}`);
                      videoPreview.currentTime = timelinePositionData.second;
                    }
                    this.setState({ timelinePositionMouseX });
                  }}
                >
                  <div className="segment-name">
                    {segmentAName}
                  </div>
                  <div className="blink-timeline segment-a">
                    <div className="timeline">
                      <div className="times" style={{ width: `${timePercentage}%` }}>
                        {filmTimelineRows}
                      </div>
                      {filmTimelineFlashes}
                    </div>
                  </div>
                  <EmotionalOverviewFilm
                    ref="emotionalOverview"
                    activeModule={filmData}
                    activeModuleTimeline={filmData.metrics.totalMetricsFR.timeline}
                    activeModuleFileName={filmItem.fileName}
                    metricDuration={filmItem.duration}
                  />
                  <EmotionalOverviewFilm
                    ref="emotionalOverview"
                    activeModule={filmData}
                    activeModuleTimeline={filmData.metrics.totalMetricsFR.timeline}
                    activeModuleFileName={filmItem.fileName}
                    metricDuration={filmItem.duration}
                    attention
                  />
                  {compareSegments && (
                    <>
                      <div className="segment-name">
                        {segmentBName}
                      </div>
                      <div className="blink-timeline">
                        <div className="timeline">
                          <div className="times" style={{ width: `${timePercentage}%` }}>
                            {filmTimelineRows}
                          </div>
                          {filmTimelineFlashesB}
                        </div>
                      </div>
                      <EmotionalOverviewFilm
                        ref="emotionalOverview"
                        activeModule={filmData}
                        activeModuleTimeline={filmData?.metricsB?.metrics?.totalMetricsFR?.timeline}
                        activeModuleFileName={filmItem.fileName}
                        metricDuration={filmItem.duration}
                        comparativeModuleTimeline={filmData?.metricsB?.metrics?.totalMetricsFR?.timeline}
                      />
                      <EmotionalOverviewFilm
                        ref="emotionalOverview"
                        activeModule={filmData}
                        activeModuleTimeline={filmData?.metricsB?.metrics?.totalMetricsFR?.timeline}
                        activeModuleFileName={filmItem.fileName}
                        metricDuration={filmItem.duration}
                        comparativeModuleTimeline={filmData?.metricsB?.metrics?.totalMetricsFR?.timeline}
                        attention
                      />
                    </>
                  )}
                  {filmItem.file && (
                    <>
                      <div className="position-bar" style={{ left: `${positionBarLeft}%` }}>
                        <div className="time">
                          {this.renderTime(previewTime)}
                        </div>
                      </div>
                      <div className="position-bar mouse" style={{ left: `${timelinePositionMouseX}%` }}>
                        {timelinePositionMouseData?.second && (
                          <div className="time">
                            {this.renderTime(timelinePositionMouseData.second)}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  {timelinePositionData && timelinePositionData.emotions && (
                    <div className="tooltip-info">
                      <T text="MetricsFilm_Metrics_Timeline_Attention" />: {timelinePositionData.attention}%<br/>
                      <T text="MetricsFilm_Metrics_Timeline_Activation" />: {timelinePositionData.activation}%<br/><br/>
                      <T text="MetricsFilm_Metrics_Timeline_Emotion_Happiness" />: {timelinePositionData.emotions.happiness}%<br/>
                      <T text="MetricsFilm_Metrics_Timeline_Emotion_Surprise" />: {timelinePositionData.emotions.surprise}%<br/>
                      <T text="MetricsFilm_Metrics_Timeline_Emotion_Sadness" />: {timelinePositionData.emotions.sadness}%<br/>
                      <T text="MetricsFilm_Metrics_Timeline_Emotion_Fear" />: {timelinePositionData.emotions.fear}%<br/>
                      <T text="MetricsFilm_Metrics_Timeline_Emotion_Anger" />: {timelinePositionData.emotions.anger}%<br/>
                      <T text="MetricsFilm_Metrics_Timeline_Emotion_Disgust" />: {timelinePositionData.emotions.disgust}%<br/>
                    {/* <AverageEmotionsFilms
                      ref="averageEmotions"
                      activeModuleData={timelinePositionData.emotions}
                    /> */}
                  </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default compose(
  withTranslation('translations', { withRef: true }),
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(FilmsMetricsModule);
