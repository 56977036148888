import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { Spinner } from 'react-activity';
import { ResponsiveLine } from '@nivo/line'
import ReactTooltip from "react-tooltip";
import { AnalysisAccess } from '@sounditi/ft2-api';
import { withTranslation } from 'react-i18next';

import T from '../Translate';

import { getVideoAspect, getImageAspect } from '../../utils/global';
import { parseNivoEmotionalOverview, parseModule } from '../../utils/metrics';

import Link from '../../components/Link';

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({})

class EmotionalOverview extends React.Component {
  _mount = true;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      dataFiltered: []
    };
  }

  componentWillMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this._mount = false;
  }

  async loadData() {
    const { activeModuleTimeline, activeModuleFileName } = this.props;

    const dataFiltered = parseNivoEmotionalOverview(activeModuleFileName, activeModuleTimeline);

    this.setState({ dataFiltered, data: dataFiltered[0].data, loading: false });
  }

  selectInteraction(id, time) {
    const { dataFiltered } = this.state;
    const { activeModule } = this.props;

    if (activeModule && activeModule.type === "video" || activeModule.type === "audio" || activeModule.type === "film") {
      const updatedDataFiltered = dataFiltered.map((dataInteraction) => {
        const re = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/;
        let subst = 'rgba($1, $2, $3, 0.2)';

        if (id === "" || dataInteraction.id === id)
          subst = 'rgba($1, $2, $3, 1)';

        dataInteraction.color = dataInteraction.color.replace(re, subst);

        return dataInteraction;
      });

      this.setState({ dataFiltered: updatedDataFiltered });
      this.props.onClick(id, time);
    }
  }

  toggleView(interaction) {
    const { dataFiltered } = this.state;
    const { activeInteraction } = this.props;
    let isShowing = false;

    if (activeInteraction !== interaction.id) {
      let updatedDataFiltered = dataFiltered;

      dataFiltered.filter(dataInteraction => {
        if (dataInteraction.id === interaction.id)
          isShowing = true;
      });

      if (isShowing) {
        updatedDataFiltered = dataFiltered.filter(dataInteraction => dataInteraction.id !== interaction.id);
      } else {
        updatedDataFiltered.push(interaction);
      }

      this.setState({ dataFiltered: updatedDataFiltered });
      this.props.onToggleView(interaction);
    }
  }

  activateView(interactionID, time) {
    const { dataFiltered, data } = this.state;
    let isShowing = false;
    let updatedDataFiltered = dataFiltered;

    if (interactionID) {
      dataFiltered.filter(dataInteraction => {
        if (dataInteraction.id === interactionID)
          isShowing = true;
      });

      const interaction = data.filter(interaction => interaction.id === interactionID)[0];

      if (!isShowing) {
        updatedDataFiltered.push(interaction);
      }

      this.selectInteraction(interactionID, time);
      this.setState({ dataFiltered: updatedDataFiltered });
      this.props.onToggleView(interaction);
    }
  }

  diezmateArray(array, value) {
    // This operation if for getting the first and the last value
    let diezmateValue = (array.length - 1) / (value - 1);
    let diezmateArray = [];
    for (let i = 0; i < value; i++) {
      diezmateArray.push(array[Math.round(i * diezmateValue)])
    }
    return diezmateArray;
  }

  render() {
    const { data, loading, dataFiltered } = this.state;
    const { activeModule, previewTime, metricDuration, activeInteraction, t } = this.props;

    //const classActions = activeInteraction === "" ? "actions disabled" : "actions";

    let videoPreview = undefined;
    let videoFrame = undefined;
    let audioPreview = undefined;
    //let previewTimeInterpolated = previewTime * (100 / metricDuration);

    const dataTicks = this.diezmateArray(dataFiltered[0].data, 11);
    const dataTickValues = dataTicks.map(data => data?.x);

    if (document.getElementById("videoPreview")) {
      videoPreview = document.getElementById("videoPreview");
    }

    if (document.getElementById("videoFrame")) {
      videoFrame = document.getElementById("videoFrame");
    }

    if (document.getElementById("audioPreview")) {
      audioPreview = document.getElementById("audioPreview");
    }

    if (loading) {
      return (
        <div className="content-widget">
          {/*
          <ReactTooltip place="bottom" effect="solid" backgroundColor="#f08c1e" />
          <h2 className="widget-title">
            <T text="EmotionalOverview_Title" />
          </h2>
          */}
          <div className="responsive-chart emotional-overview">
            <div className="loading-cover">
              <div className="cover"></div>
              <div>
                <Spinner speed={0.8} color="#00C7E7" size={20} />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      data.length > 0 ? (
        <div className="responsive-chart emotional-overview">
          {/*
          <div className="answers-legend">
            <div className="box">
              <ul>
                {data && data.map((item, key) => {
                  const color = item.color;
                  let isShowing = false;

                  dataFiltered.filter(dataInteraction => {
                    if (dataInteraction.id === item.id)
                      isShowing = true;
                  });

                  const classLi = isShowing ? "active" : "inactive";

                  return (
                    <li key={key} className={classLi} style={{ borderColor: color }}>
                      <div className="color" style={{ borderColor: color }} onClick={() => this.toggleView(item)}>
                        <i className="icon-check-2" style={{ color: color }}></i>
                      </div>
                      <p style={{ color: color }} onClick={() => this.selectInteraction(item.id, previewTime)}>{item.id}</p>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          */}
          <div className="flex">
            {/*(activeModule.type === "video" || activeModule.type === "audio") && activeInteraction !== "" && (
              <div className="timeline-wrapper">
                <div className="time-position" style={{ left: `${previewTimeInterpolated}%` }}>
                  <div className="time-second">{previewTime.toFixed(2)}s</div>
                </div>
              </div>
            )*/}
            <ResponsiveLine
              data={dataFiltered}
              margin={{ top: 5, right: 20, bottom: 20, left: 20 }}
              xScale={{ type: 'point' }}
              yScale={{ type: 'linear', min: 0, max: 100, stacked: false }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: 'bottom',
                tickSize: 0,
                tickPadding: 10,
                tickRotation: 0,
                //legend: t('EmotionalOverview_Bottom_Legend'),
                legendOffset: 0,
                legendPosition: 'middle',
                tickValues: dataTickValues,
                format: v => `${v}s`
              }}
              axisLeft={{
                orient: 'left',
                tickSize: 0,
                tickPadding: 0,
                tickRotation: 0,
                //legend: t('EmotionalOverview_Left_Legend'),
                legendOffset: 0,
                legendPosition: 'middle',
                //format: v => `${v}%`,
                format: v => ''
              }}
              enableGridY={false}
              colors={d => '#00C7E7'}
              curve="monotoneX"
              enablePoints={false}
              useMesh={true}
              theme={{
                fontSize: 11,
                textColor: '#7f7d86',
                axis: {
                  domain: {
                    line: {
                      stroke: "#D4D6D7",
                      strokeWidth: 1
                    }
                  },
                  /*
                  ticks: {
                    line: {
                      stroke: "#7f7d86",
                    }
                  }
                  */
                },
                grid: {
                  line: {
                    stroke: "#D4D6D7",
                    strokeWidth: 1,
                  }
                },
                crosshair: {
                  line: {
                    stroke: '#ffffff',
                    strokeWidth: 2,
                    strokeOpacity: 0.45,
                  },
                },
                tooltip: {
                  container: {
                    transform: 'none',
                    border: '2px blue solid'
                  },
                },
              }}
              crosshairType="bottom-left"
              isInteractive={false}
              /*
              onClick={(point) => {
                this.selectInteraction(point.serieId, point.data.x);
              }}
              onMouseLeave={(point) => {
                this.props.onMouseLeave(point.serieId, point.data.x, point.data);
              }}
              onMouseMove={(point) => {
                this.props.onTooltip(point.serieId, point.data.x, point.data);
              }}
              tooltip={({ point }) => {
                const color = '#00C7E7';

                return (
                  <div className="metrics-tooltip">
                    <div className="interaction-name">
                      <span className="color" style={{ background: color }}></span>
                      {point.serieId} {/* <span className="sec">{point.data.x}s</span> *//*}
                    </div>
                    <div key={point.serieId} className="metrics-tooltip-interaction">
                      <div>
                        <p><T text="Metrics_Total_Activation" /> <b>{point.data.y > 0 ? "+" : ""}{Math.round(point.data.y)}%</b></p>
                        <hr />
                        <p><T text="Metrics_Engagement" /> <b>{point.data.engagement > 0 ? "+" : ""}{point.data.engagement}%</b></p>
                        <p><T text="Metrics_Satisfaction" /> <b>{point.data.satisfaction > 0 ? "+" : ""}{point.data.satisfaction}%</b></p>
                        <p><T text="Metrics_Interest" /> <b>{point.data.interest > 0 ? "+" : ""}{point.data.interest}%</b></p>
                      </div>
                    </div>
                  </div>
                )
              }}
              */
            />
          </div>
        </div>
      ) :
        <div className="no-results-msg">
          <img src="../assets/img/no-campaigns-img.png" alt="No campaigns" />
          <h2><T text="Metrics_No_Results" /></h2>
          <p><T text="Metrics_No_Results_Text" /></p>
        </div>
    );
  }
}

export default compose(
  withTranslation('translations', { withRef: true }),
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(EmotionalOverview);
