export function initString(data) {
  // console.log('INIT STRING',data,localStorage.getItem(data) ? localStorage.getItem(data) : undefined)
  return localStorage.getItem(data) ? localStorage.getItem(data) : undefined;
}

export function initObject(data) {
  const object = localStorage.getItem(data) ? localStorage.getItem(data) : undefined;

  if (object)
    return JSON.parse(object);

  return [];
}

export function initTokenExpiration(tokenExpiration) {
  // console.log("TOKEN EXPIRATION", tokenExpiration)
  if (!tokenExpiration)
  return true;

  const tokenExpirated = new Date().getTime() >  1000 * tokenExpiration;
  // console.log("TOKEN tokenExpirated", new Date().getTime(), tokenExpiration*1000)
  return tokenExpirated;
}