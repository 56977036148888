const urls = {
  URL_LOGIN: '/login',
  URL_SIGNUP: '/signup',
  URL_SIGNUP_CONFIRM: '/signup-confirm',
  URL_REMEMBER_PASSWORD: '/send-new-password',
  URL_ADD_WAITING_LIST: '/add-waiting-list',
  URL_CREATE_NEW: '/create-new',
  URL_CAMPAIGNS: '/tests',
  URL_ACCOUNT: '/account',
  URL_PRICING: '/pricing-and-plans',
  URL_CAMPAIGN_CONFIGURATION: '/tests/configuration',
  URL_CAMPAIGN_CONFIGURATION_ID: '/tests/configuration?id=[var1]',
  URL_CAMPAIGN_DESIGN: '/tests/design',
  URL_CAMPAIGN_DESIGN_ID: '/tests/design?id=[var1]',
  URL_CAMPAIGN_MODULES: '/tests/modules',
  URL_CAMPAIGN_MODULES_ID: '/tests/modules?id=[var1]',
  URL_CAMPAIGN_PREVIEW: '/tests/preview',
  URL_CAMPAIGN_PREVIEW_ID: '/tests/preview?id=[var1]',
  URL_CAMPAIGN_AUDIENCE: '/tests/audience',
  URL_CAMPAIGN_PREVIEW_AND_PUBLISH: '/tests/preview-and-publish',
  URL_CAMPAIGN_DEFINE_AUDIENCE: '/tests/define-audience',
  URL_CAMPAIGN_DEFINE_AUDIENCE_ID: '/tests/define-audience?id=[var1]',
  URL_CAMPAIGN_REVIEW_TEST_AND_PAY: '/tests/review-test-and-pay',
  URL_CAMPAIGN_REVIEW_TEST_AND_PAY_ID: '/tests/review-test-and-pay?id=[var1]',
  URL_CAMPAIGN_METRICS: '/tests/metrics',
  URL_CAMPAIGN_METRICS_ID: '/tests/metrics?id=[var1]',
  URL_CAMPAIGN_PAY: '/tests/pay',
  URL_CAMPAIGN_PAY_ID: '/tests/pay?id=[var1]',
  URL_CAMPAIGN_RECOPILATOR: '/tests/get-responses',
  URL_CAMPAIGN_RECOPILATOR_ID: '/tests/get-responses?id=[var1]',
  URL_CAMPAIGN_BUY_AUDIENCE: '/tests/get-responses/buy-audience',
  URL_CAMPAIGN_BUY_AUDIENCE_ID: '/tests/get-responses/buy-audience?id=[var1]',
  URL_CAMPAIGN_INVITE_AUDIENCE: '/tests/invite-audience',
  URL_CAMPAIGN_INVITE_AUDIENCE_ID: '/tests/invite-audience?id=[var1]',
  URL_CAMPAIGN_STATUS: '/tests/status',
  URL_SUPPORT_AND_HELP: '/support-and-help',
  URL_WIZARD: '/express/',
  URL_WIZARD_LOGIN: '/express/login',
  URL_WIZARD_FILE: '/express/select-files',
  URL_WIZARD_LINK: '/express/get-link',
};


module.exports = urls;
