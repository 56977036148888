import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { AnalysisAccess } from '@sounditi/ft2-api';
import { Spinner } from 'react-activity';
import { ResponsiveLine } from '@nivo/line'
import { withTranslation } from 'react-i18next';

import T from '../Translate';

import { getURLParameter } from '../../utils/global';

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({})

class MonthInteractions extends React.Component {
  _mount = true;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
    };
  }

  componentWillMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this._mount = false;
  }

  async loadData() {
    const { activeModule } = this.props;
    // If it's "global" activeModule will be "false"

    this.setState({ loading: true });

    // LOGIC TO LOAD DATA HERE
    let data = []
    const analysisAccess = new AnalysisAccess();
    try {
      /*if (activeModule != false) {
        const moduleAudienceSummary = await analysisAccess.module30daysSummary({ moduleId: activeModule.id });
        data = [analysisAccess.parseLastMonth(moduleAudienceSummary)]
      } else {
        const campaignId = getURLParameter("id");
        const campaignAudienceSummary = await analysisAccess.campaign30daysSummary({ campaignId });
        data = [analysisAccess.parseLastMonth(campaignAudienceSummary)]
      }*/
      const campaignId = getURLParameter("id");
      const campaignAudienceSummary = await analysisAccess.campaign30daysSummary({ campaignId });
      data = [analysisAccess.parseLastMonth(campaignAudienceSummary)];
    } catch (error) {
      // console.log(error);
    }

    if (this._mount) {
      this.setState({ data, loading: false });
    }
  }

  render() {
    const { data, loading } = this.state;
    const { t } = this.props;

    if (loading) {
      return (
        <div className="responsive-chart recopilator-graph total-responses">
          <div className="loading-cover">
            <div className="cover"></div>
            <div>
              <Spinner speed={0.8} color="#00C7E7" size={20} />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="responsive-chart recopilator-graph total-responses">
        <div className="flex">
          {
          data.length > 0 ?
            <ResponsiveLine
              data={data}
              margin={{ top: 20, right: 25, bottom: 50, left: 50 }}
              xScale={{ type: 'point' }}
              yScale={{ type: 'linear', min: 0, stacked: false }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: 'bottom',
                tickSize: 10,
                tickPadding: 5,
                tickRotation: -30,
                //legend: t('MonthInteractions_Bottom_Legend'),
                legendOffset: 40,
                legendPosition: 'middle'
              }}
              axisLeft={{
                orient: 'left',
                tickSize: 0,
                tickPadding: 8,
                tickRotation: 0,
                legend: t('MonthInteractions_Left_Legend'),
                legendOffset: -40,
                legendPosition: 'middle'
              }}
              colors={{ scheme: 'spectral' }}
              curve="monotoneX"
              pointSize={7}
              pointColor="#ffffff"
              pointBorderWidth={2}
              pointBorderColor={{ from: 'serieColor' }}
              pointLabel="y"
              pointLabelYOffset={-12}
              useMesh={true}
              theme={{
                fontSize: 11,
                textColor: '#7f7d86',
                axis: {
                  ticks: {
                    line: {
                      stroke: "#7f7d86"
                    }
                  }
                },
                grid: {
                  line: {
                    stroke: "#D4D6D7",
                    strokeWidth: 1,
                  }
                },
                crosshair: {
                  line: {
                    stroke: '#ffffff',
                    strokeWidth: 1,
                    strokeOpacity: 0.35,
                  },
                }
              }}
              enableSlices="x"
              sliceTooltip={({ slice }) => {
                return (
                  <div className="recopilator-tooltip recopilator-tooltip-line">
                    {slice.points.map((point, key) => (
                      <div key={point.serieId}>
                        <span className="recopilator-tooltip-interaction" style={{backgroundColor: point.serieColor}}></span>
                        <span>Day {point.data.xFormatted} (<strong>{point.data.yFormatted}</strong>)</span>
                      </div>
                    ))}
                  </div>
                )
              }}
            />
          : <div className="no-results-msg">
              <img src="../assets/img/no-campaigns-img.png" alt="No campaigns" />
              <h2><T text="Metrics_No_Results" /></h2>
              <p><T text="Metrics_No_Results_Text" /></p>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation('translations', { withRef: true }),
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(MonthInteractions);
