import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { Spinner } from 'react-activity';
import { CampaignAccess, FileAccess, SounditiAuth, AnalysisAccess } from '@sounditi/ft2-api';
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next';
import domtoimage from "dom-to-image";
import T from '../../components/Translate';
import Link from '../../components/Link';
import ModalDialogInfo from '../../components/ModalDialogInfo';
import ModalDialog from '../../components/ModalDialog';
import Input from '../../components/Input';
import TopBar from '../../components/TopBar';
import ContentHeader from '../../components/ContentHeader';
import MobileTopBar from '../../components/MobileTopBar';
import MetricsModule from '../../components/metrics/MetricsModule';
import FilmsMetricsModule from '../../components/metrics/FilmsMetricsModule';
import PayTest from '../../components/metrics/PayTest';
import Select from '../../components/Select';

import EmotionalOverviewFilm from '../../components/metrics/EmotionalOverviewFilm';
import AverageEmotionsFilms from '../../components/metrics/AverageEmotionsFilms';

import { setSession } from '../../reducers/user';
import { showNotification } from '../../reducers/notifications';
import { setRedirect, setDownloadPercent, setDownloadShowMsg  } from '../../reducers/navigation';

import { setTestsData, setTemporalTestsData } from '../../reducers/user';
import { getURLParameter, getVideoAspect, getImageAspect } from '../../utils/global';
import { getMetadata, getThumbnails } from 'video-metadata-thumbnails';
import { parseNivoRepliesQA, parseNivoReplies, getMetricsFilter } from '../../utils/metrics';

import { URL_WIZARD, URL_CAMPAIGNS, URL_CAMPAIGN_MODULES_ID, URL_CAMPAIGN_PAY_ID } from '../../config/urls';
import { URL_ENVIRONMENT, EXTERNAL_PROCESS_SOCKET_URL } from '../../config/global';

const uuidv4 = require("uuid/v4");

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth.api,
  userData: state.user.userData,
  testsData: state.user.testsData,
  loadingMetrics: state.navigation.loadingMetrics,
  downloadPercent: state.navigation.downloadPercent,
  downloadShowMsg: state.navigation.downloadShowMsg,
  loadingAutoSave: state.navigation.loadingAutoSave,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setTestsData: val => dispatch(setTestsData(val)),
  setSession: val => dispatch(setSession(val)),
  showNotification: val => dispatch(showNotification(val)),
  setRedirect: val => dispatch(setRedirect(val)),
  setDownloadPercent: val => dispatch(setDownloadPercent(val)),
  setDownloadShowMsg: val => dispatch(setDownloadShowMsg(val)),
  setTemporalTestsData: val => dispatch(setTemporalTestsData(val)),
  dispatch,
})

class Metrics extends React.Component {
  _mount = true;

  constructor(props) {
    super(props);
    this.state = {
      loadingCampaign: true,
      loading: true,
      refreshingData: false,
      userPassword: "",
      campaignName: "",
      campaignData: [],
      campaignModules: [],
      allModulesMetrics: [],
      allModulesMetricsAllSegments: false,
      showTestOptions: false,
      showSendLinkPanel: false,
      showEmailModal: false,
      showEditTestModal: false,
      modalLoading: false,
      showDesktopPromo: false,
      previewTime: 0,
      previewDuration: 0,
      timelinePositionMouseX: 0,
      timelinePositionData: [],
      timelinePositionMouseData: [],
      loadingFilm: true,
      selectedSegmentA: false,
      selectedSegmentB: false,
      segmentBloaded: false,
      segmentAloaded: false,
      filmDataAllSegments: false
    };
    this.exportMetricsCsvCallback = this.exportMetricsCsvCallback.bind(this);
  }

  componentWillMount() {
    this.loadData();
  }

  componentDidMount() {
    document.getElementById('body').className='metrics';

    // document.addEventListener('keypress', event => {
    //   if (event.code === 'Space' && this._mount && this.refs.topbar && !this.refs.topbar.isCreateNewShow()) {
    //     this.onClickPlay();

    //     event.stopPropagation();
    //     event.preventDefault();
    //   }
    // });

    if (this.refs.topbar)
      this.refs.topbar.sendEvent("control_enter_screen_metrics", "Metrics")
  }

  componentDidUpdate() {

  }

  componentWillUnmount(){
    // document.getElementById('body').className=''
  }

  toggleTestOptions() {
    const { showTestOptions } = this.state;
    this.setState({ showTestOptions: !showTestOptions });
  }

  loggout() {
    localStorage.clear();
    const auth = new SounditiAuth();
    auth.logOut();
    window.Froged('logout');
    window.location.reload();
  }

  async refreshTestsData() {
    const { setTestsData, dispatch, setTemporalTestsData, loadingAutoSave } = this.props;
    this.setState({ refreshingData: true });

    try {
      const campaignAccess = new CampaignAccess();
      const refreshedTestsData = await campaignAccess.getCampaigns();

      if (!loadingAutoSave) {
        setTemporalTestsData(refreshedTestsData);
      }
      dispatch(setTestsData(refreshedTestsData)).then(()=> this.loadData());

      this.setState({ refreshingData: false });
    } catch (error) {
      this.loggout();
    }
  }

  async loadData() {
    if (this._mount) {
      const { testsData } = this.props;

      const campaignId = getURLParameter("id") || undefined;

      if (!campaignId || !testsData)
        this.goCampaigns();

      let campaignData = undefined;
      let campaignName = undefined;

      if (testsData.filter(test => test.campaignId === campaignId).length > 0) {
        campaignData = testsData.filter(test => test.campaignId === campaignId)[0];
        campaignName = campaignData.data.name;
        this.setState({ campaignData, campaignName, loadingCampaign: false });
      } 

      const campaignAccess = new CampaignAccess();
      const updatedCampaignData = await campaignAccess.getCampaignbyID(campaignId, 'refresh');
      
      // To testing
      // updatedCampaignData.data.modules.campaignModules = [ updatedCampaignData.data.modules.campaignModules[2] ]

      campaignName = updatedCampaignData.data.name;
      const campaignModules = updatedCampaignData.data.modules.campaignModules.filter(module => {
        // Filter empty modules
        if (
          (module.type === "film" && module.videos.length > 0)
          || (module.type === "video" && module.videos.length > 0)
          || (module.type === "audio" && module.audios.length > 0)
          || (module.type === "image" && module.images.length > 0)
          || (
              module.type === "qa" 
              && module.questions[0].name !== "" 
              && module.questions[0].answers.length > 0
              && module.questions[0].answers.filter(answers => answers.name !== "").length > 0
          ) 
        )
          return module
      });

      this.setState({ 
        campaignData: updatedCampaignData, 
        campaignModules, 
        loadingCampaign: false, 
        campaignName 
      }, () => this.loadAllModules(campaignModules));
    }
  }

  async getFiles(itemModule, stimulis) {
    const fileAccess = new FileAccess();

    if (!stimulis)
      return [];

    const returnStimulis = await Promise.all(stimulis.map(async (stimuli, key) => {
      let fileName = stimuli.id || stimuli.fileName;

      // if the module have technology "choose", we need to get .fileName first
      if (itemModule.tech === "choose")
        fileName = stimuli.fileName || stimuli.id;

      let aspect = null;
      let thumbnail = null;
      let thumbnails = null;
      let metadata = null;
      let file;

      if (stimuli && stimuli.file)
        return stimuli;

      // console.log("LOAD FILE", itemModule.type);

      if (itemModule.type === "film") {
        const campaignId = getURLParameter("id");
        // file = await fileAccess.getOfflineFilmFile(fileName + "-compressed", progress => {
        file = await fileAccess.getOfflineFilmFile({
          fileName,
          compressed: true,
          campaignId,
        }, progress => {
          const percentage =  parseInt((progress.transferred * 100) / progress.total);
          // console.log(percentage);
          // resourcesLoadedPercentage[file.fileName] = percentage;
          // this.incrementLoadedPercent(resourcesLoadedPercentage);chargeUserForACampaign
        });
       
        // console.log("file");
        // console.log(file);
      } else {
        file = await fileAccess.getOfflineFile(fileName);
      }

      // log("FILE LOADED", itemModule.type);

      if (
        itemModule &&
        itemModule.type &&
        (
          itemModule.type === "film"
        )
      ) {
        aspect = await getVideoAspect(file.uri);
        // thumbnails = await getThumbnails(file.uri, { quality: 0.6, interval: 60 });
        // metadata = await getMetadata(file.uri);
        // thumbnail = URL.createObjectURL(thumbnails[parseInt(thumbnails.length / 2)].blob);
        // thumbnail = thumbnails[parseInt(thumbnails.length / 2)].blob ? URL.createObjectURL(thumbnails[parseInt(thumbnails.length / 2)].blob) : '';
      } else if (
        itemModule &&
        itemModule.type &&
        (
          itemModule.type === "video"
        )
      ) {
        aspect = await getVideoAspect(file.uri);
        thumbnails = await getThumbnails(file.uri, { quality: 0.6, interval: 1 });
        metadata = await getMetadata(file.uri);
        //thumbnail = URL.createObjectURL(thumbnails[parseInt(thumbnails.length / 2)].blob);
        thumbnail = (thumbnails.length > 0 && thumbnails[parseInt(thumbnails.length / 2)].blob) ? URL.createObjectURL(thumbnails[parseInt(thumbnails.length / 2)].blob) : '';
      }

      if (
        itemModule &&
        itemModule.type &&
        itemModule.type === "image"
      ) {
        aspect = await getImageAspect(file.uri);
        thumbnail = file.uri;
      }

      // console.log(thumbnails);  

      return { ...stimuli, file: file.uri, aspect, thumbnail, thumbnails, metadata };
    }));

    return returnStimulis;
  }

  async loadAllModules(campaignModules) {
    this.setState({ loading: true, loadingFilm: true });

    const selectedSegmentA = this.state.selectedSegmentA !== "false" && this.state.selectedSegmentA !== false ? this.state.selectedSegmentA : false;
    const selectedSegmentB = this.state.selectedSegmentB !== "false" && this.state.selectedSegmentB !== false ? this.state.selectedSegmentB : false;
   
    const testModules = this.initTestModules(campaignModules);
    const allModulesMetrics = await this.getModulesData(testModules, false);

    // console.log(allModulesMetrics)

    const allModulesMetricsAllSegments = this.state.allModulesMetricsAllSegments ? this.state.allModulesMetricsAllSegments : JSON.parse(JSON.stringify(allModulesMetrics));

    this.setState({
      allModulesMetrics,
      allModulesMetricsAllSegments,
      segmentBloaded: selectedSegmentB, 
      segmentAloaded: selectedSegmentA,
      loading: false, 
      loadingFilm: false
    }, async () => {
      // this.initFilmPlayer();

      const allModulesMetricsWithFiles = await this.getModulesData(allModulesMetrics, true);
      // console.log("allModulesMetricsWithFiles", allModulesMetricsWithFiles);
      // this.setState({ allModulesMetrics: JSON.parse(JSON.stringify(allModulesMetricsWithFiles)) }, () => this.initFilmPlayer());
      this.setState({ allModulesMetrics: JSON.parse(JSON.stringify(allModulesMetricsWithFiles)) });
    });
  }

  initTestModules(campaignModules) {
    const campaignModulesClone = JSON.parse(JSON.stringify(campaignModules));
    let allModulesMetrics = [];

    if (campaignModulesClone && campaignModulesClone.length)
      campaignModulesClone.map(item => {
        if (item.type === "qa") {
          item.questions.map((question) => {
            const qaModule = { ...item };
            qaModule.questions = [ question ]

            allModulesMetrics.push(qaModule);
          });
        } else if (item.tech !== 'play') {
          allModulesMetrics.push(item);
        }
      });

    return allModulesMetrics;
  }

  async getModulesData(allModulesMetricsRaw, loadFiles = false) {
    const { campaignData } = this.state;

    const selectedSegmentA = this.state.selectedSegmentA !== "false" && this.state.selectedSegmentA !== false ? this.state.selectedSegmentA : false;
    const selectedSegmentB = this.state.selectedSegmentB !== "false" && this.state.selectedSegmentB !== false ? this.state.selectedSegmentB : false;
    // console.log(selectedSegmentA);
    const analysisAccess = new AnalysisAccess();
    let allModulesMetrics = [];

    let filterA = getMetricsFilter(selectedSegmentA)
    let filterB = getMetricsFilter(selectedSegmentB)

    allModulesMetrics = await Promise.all(allModulesMetricsRaw.map(async itemModule => {

      if (itemModule.tech === "recognition") {
        let metrics = {};
        let metricsB = {};
        let stimuli = {};

        if (itemModule.type === "film") {
          metrics.stimuli = itemModule.videos;
          metricsB.stimuli = itemModule.videos;
        }

        if (itemModule.type === "film") {
          let allPromise = [];

          if (loadFiles) {
            allPromise[0] = itemModule;
            allPromise[1] = await this.getFiles(itemModule, metrics.stimuli);
          } else {
            allPromise[0] = await analysisAccess.moduleMetricsFR(campaignData.campaignId, itemModule.id, 30, 400, false, 10, filterA);
          }
        
          metrics = allPromise[0];
          stimuli = allPromise[1];

          metrics.stimuli = { ...metrics.stimuli, ...stimuli };

          if (selectedSegmentB) {
            let allPromiseB = [];

            if (loadFiles) {
              allPromiseB[0] = itemModule.metricsB;
              allPromiseB[1] = await this.getFiles(itemModule, metricsB.stimuli);
            } else {
              allPromiseB[0] = await analysisAccess.moduleMetricsFR(campaignData.campaignId, itemModule.id, 30, 400, false, 10, filterB);
            }

            metricsB = allPromiseB[0];
            stimuli = allPromiseB[1];

            metricsB.stimuli = { ...metricsB.stimuli, ...stimuli };
          }
        } else { 
          if (loadFiles) {
            metrics = itemModule;
            metrics.stimuli = await this.getFiles(itemModule, metrics.stimuli); 
          } else {
            metrics = await analysisAccess.moduleMetricsFR(campaignData.campaignId, itemModule.id, null, null, false, 10, filterA);
            //console.log(metrics);
            // metrics.stimuli = metrics.stimuli.map(stimuli => {
            //   return stimuli;
            // });
            //const metricsBins = await analysisAccess.getEmotionalEngagementBins(campaignData.campaignId, itemModule.id)
            //console.log(metricsBins);
          }

          if (selectedSegmentB) {
            if (loadFiles) {
              metricsB = itemModule.metricsB;
              metricsB.stimuli = await this.getFiles(itemModule, metricsB.stimuli); 
            } else {
              metricsB = await analysisAccess.moduleMetricsFR(campaignData.campaignId, itemModule.id, null, null, false, 10, filterB);
            }
          }
        }

        const returnObject = { ...itemModule, ...metrics, metricsB };

        if (selectedSegmentB && returnObject?.stimuli?.length > 0 && returnObject?.metricsB?.stimuli) {
          const stimulisOrder = returnObject.stimuli.map(stimuli => stimuli.id);
          const metricsBStimulis = Object.values(returnObject.metricsB.stimuli);

          metricsBStimulis.sort((a, b) => stimulisOrder.indexOf(a.id) - stimulisOrder.indexOf(b.id));

          returnObject.metricsB.stimuli = metricsBStimulis;
        }

        return returnObject;
      } else {
        let metrics = {};
        let metricsChoose = {};
        let questions = {};
        let metricsB = {};
        let metricsChooseB = {};


        if (loadFiles) {
          metrics = itemModule;
          metricsChoose = itemModule;
        } else {
          if (itemModule.type === "qa") {
            metrics = await analysisAccess.moduleRepliesSummary(itemModule.id, filterA);
          } else {
            metricsChoose = await analysisAccess.moduleMetricsChoose(campaignData.campaignId, itemModule.id, false, filterA);
          }
        }

        if (selectedSegmentB) {
          if (loadFiles) {
            metricsB = itemModule;
            metricsChooseB = itemModule;
          } else {
            if (itemModule.type === "qa") {
              metricsB = await analysisAccess.moduleRepliesSummary(itemModule.id, filterB);
            } else {
              metricsChooseB = await analysisAccess.moduleMetricsChoose(campaignData.campaignId, itemModule.id, false, filterB);
            }
          }
        }

        if (itemModule.type === "qa" && !loadFiles) {
          questions = itemModule.questions[0];
          questions.answers = await parseNivoRepliesQA(metrics, questions, filterA);
        } else if (itemModule.type === "image") {
          if (loadFiles) {
            itemModule.images = await this.getFiles(itemModule, itemModule.images);
          } else {
            itemModule.images = parseNivoReplies(itemModule.images, metricsChoose);
          }
        } else if (itemModule.type === "video") {
          if (loadFiles) {
            itemModule.videos = await this.getFiles(itemModule, itemModule.videos);
          } else {
            itemModule.videos = parseNivoReplies(itemModule.videos, metricsChoose);
          }
        } else if (itemModule.type === "audio") {
          if (loadFiles) {
            itemModule.audios = await this.getFiles(itemModule, itemModule.audios);
          } else {
            itemModule.audios = parseNivoReplies(itemModule.audios, metricsChoose);
          }
        }

        if (selectedSegmentB) {
          if (itemModule.type === "qa" && !loadFiles) {
            questions = itemModule.questions[0];
            questions.answersB = await parseNivoRepliesQA(metricsB, questions, filterB);
          } else if (itemModule.type === "image") {
            if (loadFiles) {
              itemModule.imagesB = await this.getFiles(itemModule, itemModule.imagesB);
            } else {
              itemModule.imagesB = parseNivoReplies(itemModule.images, metricsChooseB);
            }
          } else if (itemModule.type === "video") {
            if (loadFiles) {
              itemModule.videosB = await this.getFiles(itemModule, itemModule.videosB);
            } else {
              itemModule.videosB = parseNivoReplies(itemModule.videos, metricsChooseB);
            }
          } else if (itemModule.type === "audio") {
            if (loadFiles) {
              itemModule.audiosB = await this.getFiles(itemModule, itemModule.audiosB);
            } else {
              itemModule.audiosB = parseNivoReplies(itemModule.audios, metricsChooseB);
            }
          }
        }

        return itemModule;
      }
    }));

    return allModulesMetrics;
  }

  onInputChange(val) {
    this.setState(val);
  }

  goModules() {
    const { setRedirect } = this.props;
    const campaignId = getURLParameter("id");
    setRedirect({ route: URL_CAMPAIGN_MODULES_ID.replace('[var1]', campaignId) });
  }

  goCampaigns() {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_CAMPAIGNS });
  }

  goPay() {
    const { setRedirect } = this.props;
    const campaignId = getURLParameter("id");
    setRedirect({ route: URL_CAMPAIGN_PAY_ID.replace('[var1]', campaignId) });
  }

  onSegmentChange(segment) {
    this.setState(segment, () => {
      const { selectedSegmentA, selectedSegmentB } = this.state;  

      if (selectedSegmentA === selectedSegmentB) {
        this.setState({ selectedSegmentB: "false" }, () => {
          this.refs.selectedSegmentB.refresh();
        });
      }
    });
  }

  getSegmentName(segmentId) {
    if (!segmentId)
      return "All segments"

    if (segmentId === "female" || segmentId === "male")
      return segmentId;

    const { campaignData } = this.state;
    const segmentName = campaignData?.data?.segments.filter(segment => segment.segmentId === segmentId)[0].segmentName;

    return segmentName;
  }

  exportMetricsCsvCallback(type, campaignId, moduleId, stimulusId) {
    const { setDownloadPercent, setDownloadShowMsg } = this.props;

    const sessionId = uuidv4();
    const socket = new WebSocket(EXTERNAL_PROCESS_SOCKET_URL);

    socket.onopen = async(e) => {
      console.log('[connected]');

      socket.send(JSON.stringify({
        action: 'whoiam',
        sessionId
      }))

      setDownloadShowMsg(true);

      const analysisAccess = new AnalysisAccess();
      try {
        const { downloadPercent } = this.props;

        if (!downloadPercent[sessionId]) {
          downloadPercent[sessionId] = {id: sessionId, percent: 0, error: false}
          setDownloadPercent(Object.assign({}, downloadPercent));
        }

        if (type === 'testersAvg') {
          await analysisAccess.getTestersAvgCSV(sessionId, campaignId, moduleId, stimulusId, {});
        } else if (type === 'metrics') {
          await analysisAccess.getMetricsCSV(sessionId, campaignId, moduleId, stimulusId, {});
        }

      } catch (error) {
        console.log('error catch', sessionId, error);
        const { downloadPercent } = this.props;
        downloadPercent[sessionId].error = true;
        setDownloadPercent(Object.assign({}, downloadPercent));
        socket.close();
      }
    };

    socket.onmessage = async (event) => {
      //console.log(`[message] Data received from server ${sessionId}: ${event.data}, ${new Date().toLocaleTimeString()}`);
      const { downloadPercent } = this.props;
      const data = JSON.parse(event.data);

      const processPercent = data.progress;
      downloadPercent[sessionId].percent = processPercent;
      setDownloadPercent(Object.assign({}, downloadPercent));

      if (data.error) {
        console.log(`[error] ${data.error} (${sessionId})`);
        downloadPercent[sessionId].error = true;
        setDownloadPercent(Object.assign({}, downloadPercent));
        socket.close();
      }

      if (data.processed) {
        let link = document.createElement("a");
        link.setAttribute("href", data.url);
        document.body.appendChild(link);
        link.click();

        setTimeout(() => {
          link.remove();
          socket.close();
        }, 3000);
      }
    };

    socket.onclose = (event) => {
      if (event.wasClean) {
        console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
      } else {
        console.log(`[close] Connection died; code=${event.code}`);
      }
    };

    socket.onerror = (error) => {
      console.log(`[error] ${error.message}`);
      const { downloadPercent } = this.props;
      downloadPercent[sessionId].error = true;
      setDownloadPercent(Object.assign({}, downloadPercent));
      socket.close();
    };
  }

  exportToPng() {
    var node = document.getElementById('container');

    domtoimage
      .toPng(node)
      .then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        document.body.appendChild(img);
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  }

  render() {
    const {
      loading,
      refreshingData,
      showTestOptions,
      allModulesMetrics,
      campaignData,
      loadingFilm,
      selectedSegmentA,
      selectedSegmentB,
      segmentBloaded,
      segmentAloaded,
      allModulesMetricsAllSegments
    } = this.state;
    const { location, t } = this.props;

    // console.log(campaignData)

    const totalResults = campaignData?.globalSummary?.end_w_correct_interactions_details?.number_of_testers || 0;
    let campaignModules = [];
    let audienceSegments = [];
    let audienceBSegments = [];

    if (campaignData?.data?.modules)
      campaignModules = campaignData?.data?.modules?.campaignModules;

    audienceSegments.segments = campaignData?.data?.segments.map(segment => {
      return { "value": segment.segmentId, "label": segment.segmentName }
    });

    audienceSegments.genders = [
      { "value": "male", "label": "Male" },
      { "value": "female", "label": "Female" }
    ];
    
    // if (audienceSegments?.segments?.length && audienceSegments?.segments?.length > 0)
    audienceSegments.unshift({ "value": false, "label": "All" });

    audienceBSegments.segments = campaignData?.data?.segments.map(segment => {
      if (!selectedSegmentA || selectedSegmentA !== segment.segmentId)
        return { "value": segment.segmentId, "label": segment.segmentName }
    }).filter(segment => typeof segment !== 'undefined');

    audienceBSegments.genders = [
      { "value": "male", "label": "Male" },
      { "value": "female", "label": "Female" }
    ];

    // if (audienceBSegments?.segments?.length && audienceBSegments?.segments?.length > 0)
    audienceBSegments.unshift({ "value": false, "label": "-" });


    const campaignName = campaignData.data ? campaignData.data.name : "";
    const isCampaignPaid = campaignData.status === 'public' || (campaignData.status === 'processing' && campaignData.price !== undefined);
    const compareSegments = selectedSegmentB && segmentBloaded !== false;

    let results = []

    results = allModulesMetrics.map((itemModule, key) => {
      if (itemModule.type !== "film") {
        return (
          <MetricsModule
            key={key}
            position={key + 1}
            itemModule={itemModule}
            compareSegments={compareSegments}
            segmentAName={this.getSegmentName(segmentAloaded)}
            segmentBName={this.getSegmentName(segmentBloaded)}
            segmentAloaded={segmentAloaded}
            segmentBloaded={segmentBloaded}
            exportMetricsCsvCallback={this.exportMetricsCsvCallback}
          />
        )
      } else {
        return (
          <FilmsMetricsModule
            key={key}
            position={key + 1}
            campaignData={campaignData}
            filmDataAllSegments={allModulesMetricsAllSegments.filter(item => item.id === itemModule.id)[0]}
            itemModule={itemModule}
            compareSegments={compareSegments}
            segmentAloaded={segmentAloaded}
            segmentBloaded={segmentBloaded}
            segmentAName={this.getSegmentName(segmentAloaded)}
            segmentBName={this.getSegmentName(segmentBloaded)}
            loadingFilm={loadingFilm}
            exportMetricsCsvCallback={this.exportMetricsCsvCallback}
          />
        )
      }
    });

    return (
      <div className="app screen-metrics" id="container">
        <Helmet>
          <title>{t('Metrics_Browser_Title')}</title>
        </Helmet>
        <div className="main-wrapper metrics">
          {/* loadingCampaign && (
            <div className="loading-cover">
              <div className="cover"></div>
              <div>
                <Spinner speed={0.8} color="#ffffff" size={20} />
              </div>
            </div>
          ) */}
          <TopBar ref="topbar" location={location} />
          <MobileTopBar
            subtitle="Resultados"
            title={campaignName}
            onClickBackButton={() => this.refs.topbar.goTests()}
          >
            <div className="btn-icon btn-send-test" onClick={() => this.refreshTestsData()}>
              <i className={refreshingData ? 'icon icon-spin4 animate-spin' : 'icon icon-spin3'}></i>
            </div>
            <div className="btn-icon btn-send-test" onClick={() => this.refs.topbar.goRecopilator(campaignData)}>
              <i className='icon icon-paper-plane-1'></i>
            </div>
            <div className="btn-icon btn-send-test" onClick={() => this.toggleTestOptions()}>
              <i className='icon icon-dot-3'></i>
              {showTestOptions && (
                <div className="test-options-menu">
                  <div><Link inline onClick={() => this.setState({ showSendLinkPanel: true, modalLoading: false })}>Enviar test</Link></div>
                  {/* <div className="separator"></div>
                  <div><Link inline onClick={() => { this.loggout() }}><T text="TopBar_Submenu_Close_Session" /></Link></div> */}
                </div>
              )}
            </div>
          </MobileTopBar>
          <ContentHeader
            location={location}
            campaignData={campaignData}
          />

          {!loading && allModulesMetrics.length < 1 && (
            <div className="no-results-msg-wrapper">
              <div className="no-results-msg">
                <img src="../assets/img/no-campaigns-img.png" alt="No campaigns" />
                <h2><T text="Metrics_Test_Without_Modules_Title" /></h2>
                <p><T text="Metrics_Test_Without_Modules_Text" /></p>
                <Link main onClick={() => this.goModules()}>
                  <T text="Metrics_Test_Without_Modules_Link" />
                </Link>
              </div>
            </div>
          )}

          <div className="fluid screen-content">
            {/* <div className={`fluid-wrapper content-wrapper ${!isFilm ? "small" : "film"}`}> */}
            

            {loading && (
              <div className="loading-cover have-topbar">
                {/* <div className="cover design"></div> */}
                <div>
                  <Spinner speed={0.8} color="#00C7E7" size={20} />
                </div>
              </div>
            )}  

            {!loading && allModulesMetrics.length > 0 && (
              <>
                {(campaignData?.status !== "draft" && campaignData?.payments?.length < 1) && (
                  <div className='publish-test-message'>
                    <div className='content-wrapper'>
                      <i className="icon icon-warning-empty"></i>
                      <div className='content-text'>
                        {totalResults > 5 ? (
                          <>
                            <h3><T text="Metrics_Warning_Pay_Test_Title_A" var1={totalResults} /></h3>
                            <p><T text="Metrics_Warning_Pay_Test_Text_A" /></p>
                          </>
                        ) : (
                          <>
                            <h3><T text="Metrics_Warning_Pay_Test_Title_B" /></h3>
                            <p><T text="Metrics_Warning_Pay_Test_Text_B" /></p>
                          </>
                        )}
                      </div>

                      <Link secondary disabled={loading} onClick={() => this.goPay()}>
                        {loading && (
                          <Spinner speed={0.8} color="#ffffff" size={16} />
                        )}
                        {totalResults > 5 ? (
                          <span><T text="Metrics_Warning_Pay_Test_Button_A" /></span>
                        ) : (
                          <span><T text="Metrics_Warning_Pay_Test_Button_B" /></span>
                        )}
                        
                      </Link>
                    </div>  
                  </div>
                )}
                <div className={`fluid-wrapper content-wrapper film`}>
                  {campaignData?.interactionsProcessing && campaignData?.interactionsProcessing === true && (
                    <div className="message-warning processing-data-warning">
                      <h3>
                        <T text="Recopilator_Interactions_Processing_Warning_Title" />
                        <i className="icon icon-spin1 animate-spin"></i>
                      </h3>
                      <p><T text="Recopilator_Interactions_Processing_Warning_Text" /></p>
                    </div>
                  )}

                  {/* {!isCampaignPaid &&
                    <div className="no-results-msg">
                      <img src="../assets/img/no-campaigns-img.png" alt="No campaigns" />
                      <h2><T text="Metrics_Test_Unpaid_Title" /></h2>
                      <p><T text="Metrics_Test_Unpaid_Text" /></p>
                      <Link main onClick={() => this.refs.topbar.goReviewTestAndPay(campaignData)}>
                        <T text="Metrics_Test_Unpaid_Link" />
                      </Link>
                    </div>
                  } */}

                  {campaignData?.globalSummary?.end_w_correct_interactions_details?.number_of_testers < 1 && isCampaignPaid &&
                    <div className="no-results-msg">
                      <i className="icon icon-chart-bar"></i>
                      <h2><T text="Metrics_Test_Without_Responses_Title" /></h2>
                      <p><T text="Metrics_Test_Without_Responses_Text" /></p>
                      <Link main onClick={() => this.refreshTestsData()}>
                        <T text="Metrics_Test_Without_Responses_Link" />
                        <i className={refreshingData ? 'icon icon-spin4 animate-spin' : 'icon icon-spin3'}></i>
                      </Link>
                    </div>
                  }

                  {campaignData?.data?.segments?.length > 1 && campaignData?.globalSummary?.end_w_correct_interactions_details?.number_of_testers > 0 && (
                    <div className="module-wrapper">
                      <div className="module-title">
                        <h2>Audience segments</h2>
                      </div>
                      <div className="metrics-item medium">
                        <div className="two-columns">
                          <Select
                            label="Selected segment"
                            name="selectedSegmentA"
                            value={selectedSegmentA}
                            onChange={segment => this.onSegmentChange(segment)}
                            options={audienceSegments}
                          />
                          <Select
                            ref="selectedSegmentB"
                            label="Compare with"
                            name="selectedSegmentB"
                            value={selectedSegmentB}
                            onChange={segment => this.onSegmentChange(segment)}
                            options={audienceBSegments}
                          />
                        </div>
                        <div className="buttons-right">
                          <Link main onClick={e => this.loadAllModules(campaignModules)}>Apply</Link>
                        </div>
                      </div>
                      
                    </div>
                  )}

                  {results}
                </div> 
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(Metrics);
