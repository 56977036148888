import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import ReactTooltip from "react-tooltip";
import Link from '../components/Link';
import { withTranslation } from 'react-i18next';

import T from '../components/Translate';

const mapStateToProps = (state, ownProps) => ({
  temporalTestsData: state.user.temporalTestsData,
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

class CampaignsTable extends React.Component {
  _mount = true;

  constructor(props) {
    super(props);
    this.state = {
      optionsOpen: undefined
    };
  }

  componentDidMount() {
    window.addEventListener('click', (e) => {
      let closePopup = true;

      Array.from(document.getElementsByClassName('btn-open-popup')).forEach(element => {
        if (element.contains(e.target)){
          closePopup = false;
        }
      });

      if (this._mount && closePopup)
        this.setState({ optionsOpen: undefined });
    });
  }

  componentWillUnmount() {
    this._mount = false;
  }

  getFormatDate(inputDate) {
    const date = new Date(inputDate);

    let month = date.getMonth() + 1;
    let day = date.getDate();
    const year = date.getFullYear();

    month = (month < 10 ? "0" : "") + month;
    day = (day < 10 ? "0" : "") + day;

    return `${day}/${month}/${year}`;
  }

  render() {
    const {
      campaignsData,
      onClickMetrics,
      onClickEdit,
      onClickClone,
      onClickRemove,
      onClickSend,
      onClickCopy,
      t
    } = this.props;
    const { optionsOpen } = this.state;

    const printCampaignsRows = campaignsData.map((campaign, key) => {
      const open = campaign.status === "public" ? true : false;
      let status = open ? t("Campaigns_Status_Active") : t("Campaigns_Status_Paused");
      let statusClass = open ? "status-label open" : "status-label";
      const temporalTest = this.props.temporalTestsData.find(test => test.campaignId === campaign.campaignId);

      // console.log(campaign.status);

      if (campaign?.status === "processing") {
        // console.log("entra");
        statusClass = "status-label processing";
        status = t("Campaigns_Status_Processing");
      } else if (campaign?.status === "ready") {
        // console.log("entra");
        statusClass = "status-label";
        status = t("Campaigns_Status_Ready");
      }

      let classRow = '';

      if (optionsOpen === campaign.campaignId) {
        classRow = `${classRow} selected`;
      }

      const toggleOptions = campaign => {
        if (optionsOpen === campaign.campaignId) {
          this.setState({ optionsOpen: undefined });
        } else {
          this.setState({ optionsOpen: campaign.campaignId });
        }
      }

      return (
        <div className="test-item" key={key}>
          <div className="test-card">
            <div className={statusClass}>{status}</div>
            <div className="card-content">
              <div className="title-wrapper">
                <div className="title">{temporalTest.data.name}</div>
                <div className="creation-date"><T text="Campaigns_Creation_Date" /> {this.getFormatDate(campaign.createdAt)}</div>
              </div>
              <div className="buttons-wrapper">
                <div className="button-icon" onClick={() => { onClickSend(campaign) }} data-offset="{'top': 20}" data-tip={t('CampaignsTable_Button_Share')}>
                  <i className='icon icon-paper-plane-1'></i>
                </div>
                <div className="button-icon only-desktop" onClick={() => { onClickEdit(campaign) }} data-offset="{'top': 20}" data-tip={t('CampaignsTable_Button_Edit')}>
                  <i className='icon icon-pencil'></i>
                </div>
                <div className="button-icon only-desktop" onClick={() => toggleOptions(campaign)} data-offset="{'top': 20}" data-tip={t('CampaignsTable_Button_More')}>
                  <i className='icon icon-dot-3 btn-open-popup'></i>
                </div>
                {optionsOpen === campaign.campaignId && (
                  <>
                    <div className="test-item-options only-desktop">
                      {!campaign?.features?.filmModule && (
                        <div><Link inline onClick={() => { onClickClone(campaign) }}><i className="icon icon-docs-1"></i>Hacer una copia</Link></div>
                      )}
                      <div><Link inline onClick={() => { onClickRemove(campaign) }}><i className="icon icon-trash-7"></i>Eliminar</Link></div>
                    </div>
                    {/* <div className="hidden-trigger" onClick={() => this.setState({ optionsOpen: undefined })}></div> */}
                  </>
                )}
              </div>
            </div>
            <div className="card-footer">
              <div className="responses">
                <i className='icon icon-chart-bar'></i>
                <p data-tip={t('CampaignsTable_Responses_Info')}>{campaign.globalSummary?.end_w_correct_interactions_details?.number_of_testers || 0} <span>/ {campaign.globalSummary?.start_details?.number_of_testers || '-'}</span></p>
              </div>
              <div className="card-actions">
                <Link inline onClick={() => onClickMetrics(campaign)}>
                  <T text="Campaigns_Button_View_Metrics" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      )
    });

    return (
      <>
        <ReactTooltip place="bottom" effect="solid" backgroundColor="#f08c1e" />
        {printCampaignsRows}
      </>
    );
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(CampaignsTable);
