import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Dialog from 'rc-dialog';
import { UserAccess, FileAccess, BillingAccess } from '@sounditi/ft2-api';
import { loadStripe } from '@stripe/stripe-js';
import ReactTooltip from "react-tooltip";

import { showNotification } from '../reducers/notifications';

import T from '../components/Translate';
import Input from '../components/Input';
import Link from '../components/Link';

import { STRIPE_CODE } from '../config/global';

const stripePromise = loadStripe(STRIPE_CODE);

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  showNotification: val => dispatch(showNotification(val)),
})

class UserInfo extends React.Component {
  _mount = true;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      userData: [],
      profilePicture: "",
      showModalRechargeCredit: false,
      creditToAdd: 0
    };
  }

  componentWillMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this._mount = false;
  }

  async loadData() {
    if (this._mount) {
      const userAccess = new UserAccess();
      const userData = await userAccess.getUserData();

      let profilePicture = "";

      if (userData && userData.data && userData.data.profilePicture) {
        const profilePictureFile = userData.data.profilePicture.fileName || "";

        if (profilePictureFile) {
          const fileAccess = new FileAccess();
          profilePicture = await fileAccess.getFile(profilePictureFile);
        }
      }

      if (this._mount)
        this.setState({ userData, profilePicture, loading: false });

      if (userData && userData.data) {
        // console.log(userData);
        const name = userData.name || null;
        const email = userData.email || null;
        const company = userData.data.company || null;

        window.hj('identify', userData.email, {
          name,
          email,
          company,
        });
      }

      if (this.refs.creditToAdd)
        this.refs.creditToAdd.refresh();

    }
  }

  changeLanguage(lang) {
    const { i18n } = this.props;
    localStorage.setItem('lang', lang);
    i18n.changeLanguage(`${lang}`);
  }

  async addCredit() {
    const { creditToAdd } = this.state;

    if (creditToAdd && creditToAdd > 0){
      try {
        this.setState({ loading: true });
        const billingAccess = new BillingAccess();
        const userAccess = new UserAccess();
        const userData = await userAccess.getUserData();

        const { id: sessionId } = await billingAccess.rechargeWallet(userData, creditToAdd);
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({ sessionId });

        if (error) {
          // console.log(error);
          this.setState({ loading: false });
          showNotification("genericError");
        }
      } catch(error) {
        // console.log(error);
        this.setState({ loading: false });
        showNotification("genericError");
      }
    } else {
      if (this.refs.creditToAdd)
        this.refs.creditToAdd.setInvalid();
    }
  }

  onInputChange(val) {
    this.setState(val);
  }

  render() {
    const { t } = this.props;
    const { userData, profilePicture, showModalRechargeCredit, creditToAdd, loading } = this.state;
    const lang = localStorage.getItem('lang') || "en";

    if (!userData.data)
      return false

    const profilePictureFormated =
      profilePicture ?
      profilePicture.uri :
      `/assets/img/user-avatar-placeholder.png`;

    const company =
      userData.data.company ?
      userData.data.company :
      "No company";

    //const classAccountType = (userData.quota.type === "premium" || userData.quota.type === "super") ? "premium" : "";

    let availableTests = userData.subscription.total_test - userData.subscription.current_test;
    let availableSends = userData.subscription.total_send - userData.subscription.current_send;
    
    if (availableTests < 0)
      availableTests = 0;

    if (availableSends < 0)
      availableSends = 0;

    const consumedTests = userData.subscription.current_test;
    const consumedSends = userData.subscription.current_send;

    const planTests = userData.subscription.total_test;
    const planSends = userData.subscription.total_send;
    const subscriptionName = userData.subscription.name;

    return (
      <>
        <ReactTooltip place="bottom" effect="solid" backgroundColor="#f08c1e" />
        {/*
        <Dialog
          title={t('UserInfo_Credit_Modal_Title')}
          closeIcon={<i className="icon icon-cancel-6"></i>}
          visible={showModalRechargeCredit}
          onClose={() => this.setState({ showModalRechargeCredit: false })}
        >
          <p><T text="UserInfo_Credit_Modal_Text1" var1={userData.walletAmount.toFixed(2)} /></p>
          <h2><T text="UserInfo_Credit_Modal_Add_Credit_Title" /></h2>
          <p><T text="UserInfo_Credit_Modal_Add_Credit_Text1" /></p>
          <Input
            className="recharged-credit-input"
            placeholder="..."
            text="€"
            name="creditToAdd"
            ref="creditToAdd"
            value={creditToAdd}
            type="number"
            onChange={val => this.onInputChange(val)}
          />
          <div className="dialog-buttons">
            <Link secondary onClick={() => this.setState({ showModalRechargeCredit: false })}>
              <T text="UserInfo_Credit_Modal_Btn_Cancel" />
            </Link>
            <Link main onClick={() => this.addCredit()}>
              <T text="UserInfo_Credit_Modal_Btn_Add_Credit" />
            </Link>
          </div>
        </Dialog>
        {/*
        <div className="wallet" onClick={() => this.setState({ showModalRechargeCredit: true })}>
          <div className="title"><T text="UserInfo_Credit_Widget_Text1" var1={userData.walletAmount.toFixed(2)} /></div>
        </div>
        */}
        <Link inline to="/account">
          <div className={subscriptionName === "Free" ? 'user-info' : 'user-info have-subscription'}>
            <div className="avatar">
              <img src={profilePictureFormated} alt={`${userData.data.name} avatar`} />
            </div>
            <div className="suscription-info">
              <div className="suscription-info-item" data-tip={t('UserInfo_Available_Sends_Tooltip').replace("[var1]", availableSends)}>
                <div className="suscription-info-icon">
                  <img src="/assets/img/icon-sends.png" />
                </div>
                <div className="suscription-info-text">
                  <p><span className="current">{consumedSends}</span> / {planSends}</p>
                  <div className="progress-bar">
                    <div className="progress" style={{ width: Math.round((consumedSends / planSends) * 100) + "%" }}></div>
                  </div>
                </div>
              </div>
              {planTests >= 0 && (
                <div className="suscription-info-item" data-tip={t('UserInfo_Available_Tests_Tooltip').replace("[var1]", availableTests)}>
                  <div className="suscription-info-icon">
                    <img src="/assets/img/icon-tests.png" />
                  </div>
                  <div className="suscription-info-text">
                    <p><span className="current">{consumedTests}</span> / {planTests}</p>
                    <div className="progress-bar">
                      <div className="progress" style={{ width: Math.round((consumedTests / planTests) * 100) + "%" }}></div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/*
            <div className="name-and-company">
              <div className="subscription-type">{subscriptionName}</div>
              <T text="UserInfo_Available" /><br/>
              {availableTests >= 0 && (
                <>
                  <T text="UserInfo_Available_Tests" var1={availableTests} />
                </>
              )}
              <T text="UserInfo_Available_Sends" var1={availableSends} />
              {/*
                {userData.data.name}
                <span className={classAccountType}>{userData.quota.type === "normal" ? "basic plan" : userData.quota.type}</span>
              */}{/*
            </div>m
            {/* (userData.quota.type === "premium" || userData.quota.type === "super") && (
              <div className="subscription">
                <i className="icon icon-flash-3"></i>
              </div>
            )*/}
          </div>
        </Link>
        {/* <div className="lang-selector">
          <Link className={lang === "es" ? "active" : ""} inline onClick={() => this.changeLanguage("es")}>
            ES
          </Link>
          <span className="lang-separator">/</span>
          <Link className={lang === "en" ? "active" : ""} inline onClick={() => this.changeLanguage("en")}>
            EN
          </Link>
        </div> */}
      </>
    );
  }
}

export default compose(
  withTranslation('translations', { withRef: true }),
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(UserInfo);
