import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next';
import { Spinner } from 'react-activity';
import Slider from '@mui/material/Slider';
import { CampaignAccess, BillingAccess } from '@sounditi/ft2-api';
import { loadStripe } from '@stripe/stripe-js';

import TopBar from '../../components/TopBar';
import ContentHeader from '../../components/ContentHeader';
import PayTestWidget from '../../components/metrics/PayTest';
import Link from '../../components/Link';
import T from '../../components/Translate';

import { setRedirect } from '../../reducers/navigation';
import { showNotification } from '../../reducers/notifications';

import { getURLParameter, removeURLParameter, getCampaignDuration, getFormatTime } from '../../utils/global';
import { URL_CAMPAIGNS, URL_CAMPAIGN_METRICS_ID } from '../../config/urls';

import { STRIPE_CODE, ANSWER_TIME } from '../../config/global';
const stripePromise = loadStripe(STRIPE_CODE);

const mapStateToProps = (state, ownProps) => ({
  userData: state.user.userData,
  testsData: state.user.testsData,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setRedirect: val => dispatch(setRedirect(val)),
  dispatch
})

class PayTest extends React.Component {
  _mount = true;

  constructor(props) {
    super(props);
    this.state = { 
      loading: true,
      payLoading: false,
      numberOfTesters: 6,
      unitPrice: 0,
      allModules: [],
      showTestDuration: 0,
    };
  }

  componentWillMount() {
    this.loadData();
  }

  async loadData() {
    if (this._mount) {
      const { testsData } = this.props;

      const campaignId = getURLParameter("id") || undefined;

      if (!campaignId || !testsData)
        this.goCampaigns();

      let campaignData = undefined;
      let campaignName = undefined;
      

      if (testsData.filter(test => test.campaignId === campaignId).length > 0) {
        campaignData = testsData.filter(test => test.campaignId === campaignId)[0];
        campaignName = campaignData.data.name;
        const allModules = campaignData.data.modules.campaignModules;
        const testDurationSeconds = Math.round(getCampaignDuration(campaignData, false));
        const showTestDuration = getFormatTime(testDurationSeconds);

        this.setState({ 
          campaignData, 
          campaignName,
          allModules,
          showTestDuration
        });

        let totalResults = campaignData.globalSummary.end_w_correct_interactions_details.number_of_testers;

        if (totalResults < 6)
          totalResults = 6;

        const billingAccess = new BillingAccess();
        const requestTestersPrice = await billingAccess.requestTestersPrice(
          {
            "campaignId": campaignData.campaignId,
            "paidAudienceSegments": [
              {
                "numberOfPeople": totalResults,
                "country": "all",
                "toAge": 99,
                "gender": "all",
                "city": "all",
                "fromAge": 18,
                "segmentName": "New segment"
              }
            ]
          }
        )

        console.log(requestTestersPrice)

        const unitPrice = requestTestersPrice.pricePerTester / 100;

        this.setState({
          loading: false,
          numberOfTesters: totalResults,
          unitPrice
        });
      } 
    }
  }

  async goToPay() {
    const { userData } = this.props;
    const { campaignData, numberOfTesters } = this.state;
    const sessionId = localStorage.getItem('sessionID-info');

    this.setState({ payLoading: true });

    try {
      const billingAccess = new BillingAccess();
      const stripeSessionId = await billingAccess.payTesters(
        {
          "campaignId": campaignData.campaignId,
          "sessionId": sessionId,
          "email": userData.email,
          "userId": userData.id,
          "paidAudienceSegments": [
            {
              "numberOfPeople": numberOfTesters,
              "country": "all",
              "toAge": 99,
              "gender": "all",
              "city": "all",
              "fromAge": 18,
              "segmentName": "New segment"
            }
          ]
        }
      )

      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId: stripeSessionId });

      this.setState({ payLoading: false });
    } catch(error) {

    }
  }

  goCampaigns() {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_CAMPAIGNS });
  }

  goMetrics() {
    const { setRedirect } = this.props;
    const campaignId = getURLParameter("id") || undefined;
    setRedirect({ route: URL_CAMPAIGN_METRICS_ID.replace('[var1]', campaignId) });
  }

  showIcon(type) {
    switch (type) {
      case 'video':
        return <i className="icon icon-video-1"></i>
      case 'image':
        return <i className="icon icon-picture-1"></i>
      case 'audio':
        return <i className="icon icon-sound"></i>
      case 'qa':
        return <i className="icon icon-chat"></i>
      case 'film':
        return <i className="icon icon-video"></i>
      default:
        break;
    }
  }

  render() {
    const { 
      loading, 
      campaignData, 
      numberOfTesters, 
      unitPrice, 
      payLoading,
      allModules,
      showTestDuration
    } = this.state;
    const { location, t } = this.props;
    
    const totalResults = campaignData?.globalSummary?.end_w_correct_interactions_details?.number_of_testers || 0;
    const marks = [
      {
        value: 0,
        label: '0',
      },
      {
        value: 100,
        label: '100',
      },
      {
        value: 200,
        label: '200',
      },
      {
        value: 300,
        label: '300',
      },
      {
        value: 400,
        label: '400',
      },
      {
        value: 500,
        label: '500',
      },
    ];

    const renderAllModules = allModules.map((module, i) => {
      // console.log(module.tech === "choose");
      return (
        <div key={module.id} className="modules-container">
          <div className="modules">
            <div className="module-title">
              <span className="number-icon">{i+1}</span>
              <div className="title-tech">
                <h2>{module.name}</h2>
                {module.type !== 'qa' && !module.filmName &&
                  <span className="tech">{module.tech}</span>
                }
              </div>
              {this.showIcon(module.type)}
            </div>
            {module.videos && module.videos.length > 0 && (
              module.videos.map(video => (
                <div className="stimuli-container" key={video.fileName}>
                  <div className="stimuli">
                    <p>{module?.filmName || video.name}</p>
                    <p>{getFormatTime(video.duration)}</p>
                  </div>
                </div>
              ))
            )}
            {module.images && module.images.length > 0 && (
              module.images.map(image => (
                <div className="stimuli-container" key={image.fileName}>
                  <div className="stimuli">
                    <p>{image.name}</p>
                    <p>{getFormatTime(module.tech === "choose" ? ANSWER_TIME : module.duration)}</p>
                  </div>
                </div>
              ))
            )}
            {module.audios && module.audios.length > 0 && (
              module.audios.map(audio => (
                <div className="stimuli-container" key={audio.fileName}>
                  <div className="stimuli">
                    <p>{audio.name}</p>
                    <p>{getFormatTime(audio.duration)}</p>
                  </div>
                </div>
              ))
            )}
            {module.questions && module.questions.length > 0 && (
              <div className="stimuli-container">
                <div className="stimuli">
                  <p>{module.questions[0].name}</p>
                  <p style={{ marginTop: 0 }}>{getFormatTime(ANSWER_TIME)}</p>
                </div>
                <ul>
                  {module.questions[0].answers.map(answer => (
                    <li key={answer.id}>{answer.name}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="separator"></div>
        </div>
      );
    });

    return (
      <div className="app screen-metrics" id="container">
        <Helmet>
          <title>{t('Metrics_Browser_Title')}</title>
        </Helmet>
        <div className="main-wrapper metrics">
         
          <TopBar ref="topbar" location={location} />
          <ContentHeader
            location={location}
            campaignData={campaignData}
          />

          <div className="fluid screen-content">
            {loading && (
              <div className="loading-cover have-topbar">
                {/* <div className="cover design"></div> */}
                <div>
                  <Spinner speed={0.8} color="#00C7E7" size={20} />
                </div>
              </div>
            )}  

            {!loading && (
              <div className={`content-wrapper two-columns pay medium review-test-and-pay`}>
                <div className='content-column'>
                  {/* <Link inline className="link-back" disabled={payLoading} onClick={() => this.goMetrics()}>
                    <T text="PayTest_Link_Back" />
                  </Link> className='no-margin-top' */}
                  <h2><T text="PayTest_Title" /></h2>
                  <p><T text="PayTest_Text" var1={totalResults} /></p>
            
                  <div className='content-widget number-of-responses-wrapper'>
                    <h3><T text="PayTest_Responses_Selector_Title" /></h3>
                    <div className='number-of-responses'>
                      <div className="value">
                        <input
                          placeholder="0"
                          name="numberOfTesters"
                          value={numberOfTesters}
                          ref="numberOfTesters"
                          onChange={e => {
                            if (e.target.value > 500) {
                              this.setState({ numberOfTesters: 500 })
                            } else if (e.target.value < totalResults) {
                              this.setState({ numberOfTesters: totalResults })
                            } else if (e.target.value < 6) {
                              this.setState({ numberOfTesters: 6 })
                            } else {
                              this.setState({ numberOfTesters: e.target.value })
                            }
                          }}
                          onBlur={e => {
                            if (e.target.value < 1) {
                              if (totalResults > 6) {
                                this.setState({ numberOfTesters: totalResults })
                              } else {
                                this.setState({ numberOfTesters: 6 })
                              }
                            }
                          }}
                        />
                        <Slider 
                            aria-label="Custom marks"
                            defaultValue={100}
                            value={parseInt(numberOfTesters)}
                            min={0}
                            max={500}
                            valueLabelDisplay="auto"
                            marks={marks}
                            disabled={payLoading}
                            onChange={(e, value, activeThumb) => {
                              if (value < totalResults) {
                                this.setState({ numberOfTesters: totalResults })
                              } else if (value < 6) {
                                this.setState({ numberOfTesters: 6 })
                              } else {
                                this.setState({ numberOfTesters: value })
                              }
                            }}
                          />
                      </div>
                    </div>
                    <p className='small'><T text="PayTest_Responses_Selector_Subtitle" /></p>
                  </div>

                  <h2><T text="Review_Test_And_Pay_Test_Summary_Title" /></h2>
                  <div className="content-widget test-resume">
                    {allModules.length > 0
                      ? <>
                          {renderAllModules}
                          <p className="duration">
                            <T text="Review_Test_And_Pay_Test_Summary_Estimated_Duration" />
                            <span>{showTestDuration}</span>
                          </p>
                          <p className="duration-disclaimer">
                            <T text="Review_Test_And_Pay_Test_Summary_Estimated_Duration_Disclaimer" />
                          </p>
                        </>
                      : <div className="message-warning">
                          <i className="icon icon-warning-empty"></i>
                          <T text="Metrics_Test_Without_Modules_Title" />
                        </div>
                    }
                  </div>


                  {/* <PayTestWidget 
                    onRefreshTestData={campaignData => this.loadData(campaignData)}
                    campaignData={campaignData} 
                  /> */}
                </div> 
                <div className='right-column'>
                  <div className='content-widget pay-widget'>
                    <div className='responses-price details'>
                      <div className='legend'>
                        {numberOfTesters} <T text="PayTest_Invoice_Test" />
                      </div> 
                      <div className='value'>
                        ${(numberOfTesters * unitPrice).toFixed(2)}
                      </div>
                    </div>
                    <div className='details'>
                      <div className='legend'>
                        <T text="PayTest_Invoice_Unit_Duration" />
                      </div> 
                      <div className='value'>
                        {showTestDuration}
                      </div>
                    </div>
                    <div className='details'>
                      <div className='legend'>
                        <T text="PayTest_Invoice_Unit_Price" />
                      </div> 
                      <div className='value'>
                        ${unitPrice}
                      </div>
                    </div>
                    <div className='responses-price details'>
                      <div className='legend'>
                        <T text="PayTest_Invoice_Free_Test" />
                      </div> 
                      <div className='value'>
                        - ${(5 * unitPrice).toFixed(2)}
                      </div>
                    </div>

                    <div className='separator'></div>

                    <div className='total-price'>
                      <div className='legend'>
                        <T text="PayTest_Invoice_Total" />
                        <div className='sublegend'><T text="PayTest_Invoice_VAT" /></div>
                      </div> 
                      <div className='value'>
                        ${((numberOfTesters - 5) * unitPrice).toFixed(2)}
                        <div className='sublegend'>US</div>
                      </div>
                    </div>

                    <Link main className="button-with-loader" disabled={payLoading} onClick={() => this.goToPay()}>
                      {payLoading && (
                        <Spinner speed={0.8} color="#ffffff" size={16} />
                      )}
                      <T text="PayTest_Invoice_Button_Pay" />
                    </Link>
                  </div>
                  
                  <div className='content-widget contact-us'>
                    <div className='image'>
                      <img src="../../assets/img/contact-us.png" />
                    </div>
                    <h3><T text="ContactUsWidget_Title" /></h3>
                    <p><T text="ContactUsWidget_Text" /></p>

                    <Link main className="featured" onClick={() => window.Froged('open','contact')}>
                      <span><T text="ContactUsWidget_Button_Contact_Us" /></span>
                    </Link>
                  </div>

                  {/* <div className='content-widget featured-clients'>
                    <h3><T text="PayTest_Featured_Clients_Title" /></h3>
                    <div className='image'>
                      <img className='active' src="../../assets/img/redbull-logo.png" />
                      <img src="../../assets/img/redbull-logo.png" />
                      <img src="../../assets/img/redbull-logo.png" />
                    </div>
                    <p>"Estamos usando Alyze Audience principalmente para mantener un ritmo en la solidez de nuestra marca. Alyze nos permite entender a más personas de forma más instantánea."</p>
                    <div className='name-and-charge'>
                      <div className='name'>Julie Channing</div>
                      <div className='charge'>VP de marketing de Red Bull</div>
                    </div>
                  </div> */}


                  {/* <div className="content-widget ads">
                      <h2>Calidad de los datos</h2>
                      <div className="item">
                        <i className="icon icon-target-1"></i>
                        <div>
                          <h3>Lorem ipsum</h3>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                      </div>
                      <div className="item">
                        <i className="icon icon-block-1"></i>
                        <div>
                          <h3>Lorem ipsum</h3>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                      </div>
                      <div className="item">
                        <i className="icon icon-user-1"></i>
                        <div>
                          <h3>Lorem ipsum</h3>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                      </div>
                      <a href="#">Más información acerca de nuestro enfoque hacia la calidad de los datos.</a>
                      <div className="contact">
                        <h3>¡Estamos aquí para ayudar!</h3>
                        <p>Nuestro equipo está a tu disposición para responder tus preguntas. <a href="#">Contáctanos.</a></p>
                      </div>
                    </div> */}
                </div>    
              </div> 
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(PayTest);
