import React from 'react';
import { ReactSortable } from "react-sortablejs";
import TextArea from './TextArea';

import '../styles/components/DraggableList.scss';

class DraggableList extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      value: [],
      invalid: false,
    }
    this.handleChange = this.handleChange.bind(this);
  }

  setInvalid() {
    this.setState({ invalid: true });
    setTimeout(() => {
      this.setState({ invalid: false });
    }, 3000)
  }

  componentWillMount() {
    const { value } = this.props;
    if (value)
      this.setState({value});
  }

  handleChange(value) {
    const { name, onChange } = this.props;

    this.setState({ value }, () => {
      value.map((item, key) => {
        if (this.refs[`itemText${key}`] && this.refs[`itemText${key}`].refresh)
          this.refs[`itemText${key}`].refresh();

        return false;
      });

      if (onChange)
        onChange({ [name]: value });
      });
  }

  answersHandleChange(answer, key) {
    const { name, onChange } = this.props;
    const { value } = this.state;

    value[key].name = answer.answer;
    this.setState({ value });

    if (onChange)
      onChange({ [name]: value });
  }

  refresh(callback) {
    const { value } = this.props;

    if (value)
      this.setState({value}, () => {
        value.map((item, key) => {
          if (this.refs[`itemText${key}`] && this.refs[`itemText${key}`].refresh)
            this.refs[`itemText${key}`].refresh();

          return false;
        });

        if (callback)
          callback();
      });
  }

  focusLastItem() {
    const { value } = this.props;
    this.refs[`itemText${value.length-1}`].focus();
  }

  removeItem(item) {
    const { audio, video, image } = this.props;

    let value = this.state.value.filter(valueItem => valueItem.id !== item.id);

    if (audio || video || image)
      value = this.state.value.filter(valueItem => valueItem.fileName !== item.fileName);

    this.handleChange(value);
  }

  render() {
    const { audio, video, editable, placeholder, disabled } = this.props;
    const { value } = this.state;

    const renderList = value.map((item, key) => {
      let name = item.name || '';
      const classDisabled = disabled ? 'disabled' : '';

      if (audio || video)
        name = `${name} (${Math.ceil(item.duration, 1)}s)`;

      if (editable) {
        return (
          <li key={key} className={classDisabled}>
            <div className="button-drag">
              <i className="icon icon-menu-1"></i>
            </div>
            <TextArea
              ref={`itemText${key}`}
              rows={2}
              placeholder={placeholder}
              name="answer"
              value={name}
              onChange={name => this.answersHandleChange(name, key)}
              disabled={disabled}
            />
            <div className="button-close" onClick={() => this.removeItem(item)}>
              <i className="icon icon-cancel-1"></i>
            </div>
          </li>
        )
      }

      return (
        <li key={key} className={classDisabled}>
          <div className="button-drag">
            <i className="icon icon-menu-1"></i>
          </div>
          <p>{name}</p>
          <div className="button-close" onClick={() => this.removeItem(item)}>
            <i className="icon icon-cancel-1"></i>
          </div>
        </li>
      );
    });

    return (
      <ReactSortable
        className="draggable-list"
        ghostClass="placeholder"
        handle=".button-drag"
        dragClass="dragging"
        tag="ul"
        list={value}
        setList={this.handleChange}
      >
        {renderList}
      </ReactSortable>
    );
  }
}

export default DraggableList