// TODO: add callback and renovate enviroments... remove production until ready.
const dev = {
  API_HOST: 'sounditi-token-dev',
  LOCATION_TOKEN: 'sounditi-token-dev',
  LOCATION_PROFILE: 'sounditi-profile-dev',
  LOCATION_CAMPAIGNS: 'sounditi-campaigns-dev',
  LOCATION_TOKEN_EXPIRATION: 'snd-expiration-user',
  LOCATION_TABS: 'sounditi-tabs-dev',
  SESSION_TRACKER_COOKIE_NAME: '_snd_a-dev',
  GLOBAL_TRACKER_COOKIE_NAME: '_snd_g-dev',
  LEAD: '_snd_lead-dev',
  UPLOAD_EXPERIENCES: true,
  LOG_TRACKS: false,
  LOG_DISABLED: true,
  DEFAULT_CAMPAIGN: 'facetrack',
  STRIPE_CODE: 'pk_test_KLdJyubt1xNxzvf1h8KzSVLn00XaEJvuS2',
  // URL_ENVIRONMENT: 'https://localhost:3001',
  URL_ENVIRONMENT: 'https://tf2-client-dev.sounditi.com',
  URL_CONTROL_ENVIRONMENT: 'https://tf2-control-dev.sounditi.com',
  ENVIRONMENT: "development",
  ANSWER_TIME: 6,
  EXTERNAL_PROCESS_SOCKET_URL: 'wss://6kof2jwpr0.execute-api.eu-central-1.amazonaws.com/dev'
};

const pre = {
  LOCATION_TOKEN: 'sounditi-token-pre',
  LOCATION_PROFILE: 'sounditi-profile-pre',
  LOCATION_CAMPAIGNS: 'sounditi-campaigns-pre',
  LOCATION_TOKEN_EXPIRATION: 'sounditi-expiration-pre',
  LOCATION_TABS: 'sounditi-tabs-pre',
  SESSION_TRACKER_COOKIE_NAME : '_snd_a-pre',
  GLOBAL_TRACKER_COOKIE_NAME : '_snd_g-pre',
  LEAD: '_snd_lead-pre',
  UPLOAD_EXPERIENCES: true,
  LOG_TRACKS: false,
  LOG_DISABLED: false,
  DEFAULT_CAMPAIGN: 'facetrack',
  STRIPE_CODE: 'pk_test_KLdJyubt1xNxzvf1h8KzSVLn00XaEJvuS2',
  URL_ENVIRONMENT: 'https://tf2-client-pre.sounditi.com',
  URL_CONTROL_ENVIRONMENT: 'https://tf2-control-pre.sounditi.com',
  ENVIRONMENT: "preproduction",
  ANSWER_TIME: 6,
  EXTERNAL_PROCESS_SOCKET_URL: 'wss://nmqdch5bkh.execute-api.eu-central-1.amazonaws.com/pre'
};

const prod = {
  LOCATION_TOKEN: 'sounditi-token-prod',
  LOCATION_PROFILE: 'sounditi-profile-prod',
  LOCATION_CAMPAIGNS: 'sounditi-campaigns-prod',
  LOCATION_TOKEN_EXPIRATION: 'sounditi-expiration-prod',
  LOCATION_TABS: 'sounditi-tabs-prod',
  SESSION_TRACKER_COOKIE_NAME: '_snd_a-prod',
  GLOBAL_TRACKER_COOKIE_NAME: '_snd_g-prod',
  LEAD: '_snd_lead-prod',
  UPLOAD_EXPERIENCES: true,
  LOG_TRACKS: false,
  LOG_DISABLED: false,
  DEFAULT_CAMPAIGN: 'facetrack',
  STRIPE_CODE: 'pk_live_iXsJoA0onahkLrSv0ehCMNuc00V2rbgC8s',
  URL_ENVIRONMENT: 'https://test.alyzemetrics.es',
  URL_CONTROL_ENVIRONMENT: 'https://admin.byalyze.com',
  ENVIRONMENT: "production",
  ANSWER_TIME: 6,
  EXTERNAL_PROCESS_SOCKET_URL: 'wss://vptszttrnk.execute-api.eu-central-1.amazonaws.com/prod'
};

let config = dev;

switch (process.env.REACT_APP_STAGE) {
  case 'pre':
    config = pre;
    break;
  case 'prod':
    config = prod;
    break;
  default:
    break;
}

module.exports = config;
