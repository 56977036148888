// COMPONENTS
import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { CampaignAccess, SounditiAuth, PanelAccess } from '@sounditi/ft2-api';
import { Spinner } from 'react-activity';
import Link from './Link';
import ModalDialog from '../components/ModalDialog';
import Input from '../components/Input';
import Select from '../components/Select';
import T from '../components/Translate';

// ACTIONS
import { showNotification } from '../reducers/notifications';
import { setRedirect, clearDownload, setDownloadToggleBox } from '../reducers/navigation';
import { setTestsData, setTemporalTestsData } from '../reducers/user';
import { postActionGTMCampaing } from '../utils/events';

// TYPES
import { newCampaignTemplateES } from '../config/campaignTemplates';
import { newCampaignTemplateEN } from '../config/campaignTemplates';
import {
  URL_CAMPAIGNS,
  URL_CREATE_NEW,
  URL_PRICING,
  URL_CAMPAIGN_CONFIGURATION_ID,
  URL_CAMPAIGN_MODULES_ID,
  URL_ACCOUNT,
  URL_SUPPORT_AND_HELP,
  URL_CAMPAIGN_METRICS_ID,
  URL_WIZARD_FILE,
  URL_CAMPAIGN_RECOPILATOR,
  URL_CAMPAIGN_RECOPILATOR_ID,
  URL_CAMPAIGN_BUY_AUDIENCE_ID,
  URL_CAMPAIGN_INVITE_AUDIENCE_ID,
  URL_CAMPAIGN_DEFINE_AUDIENCE_ID,
  URL_CAMPAIGN_REVIEW_TEST_AND_PAY_ID,
} from '../config/urls';

const uuidv4 = require("uuid/v4");

const mapStateToProps = (state, ownProps) => ({
  userData: state.user.userData,
  testsData: state.user.testsData,
  downloadPercent: state.navigation.downloadPercent,
  downloadToggleBox: state.navigation.downloadToggleBox,
  downloadShowMsg: state.navigation.downloadShowMsg,
  loadingAutoSave: state.navigation.loadingAutoSave,
  hasContentHeader: state.navigation.hasContentHeader,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setTestsData: val => dispatch(setTestsData(val)),
  setTemporalTestsData: val => dispatch(setTemporalTestsData(val)),
  setRedirect: val => dispatch(setRedirect(val)),
  showNotification: val => dispatch(showNotification(val)),
  toggleDownloadBox: val => dispatch(setDownloadToggleBox(val)),
  clearDownload: () => dispatch(clearDownload()),
  dispatch
})

class TopBar extends React.Component {
  _mount = true;

  constructor(props) {
    super(props);
    this.state = {
      showUserOptions: false,
      showAddNewModal: false,
      showAddNewModalLoader: false,
      campaignsFeatures: [],
      AddTestLoading: false,
      newTestName: "",
      newTestLang: "en",
      newTestType: "generic",
      checkedFilm: true,
      checkedGeneric: false
    };
  }

  componentWillMount() {
    const lang = localStorage.getItem('lang') || "en";
    const campaignTemplates = lang === "es" ? newCampaignTemplateES : newCampaignTemplateEN;

    this.setState({ newTestName: campaignTemplates.name });

    //this.refreshTestsData();
  }

  componentDidMount() {
    window.addEventListener('click', (e) => {
      let closePopup = true;

      Array.from(document.getElementsByClassName('btn-open-popup')).forEach(element => {
        if (element.contains(e.target)){
          closePopup = false;
        }
      });

      if (this._mount && closePopup)
        this.setState({ showUserOptions: false });
    });
  }

  componentWillUnmount() {
    this._mount = false;
  }

  loggout() {
    // console.log("loggout");
    localStorage.clear();
    const auth = new SounditiAuth();
    auth.logOut();
    window.Froged('logout');
    // window.location.reload();

    window.location.href = '/';
  }

  async sendEvent(action, screen, data = null) {
    const panelAccess = new PanelAccess();

    try {
      const createdAt = new Date().getTime();
      const sessionId = localStorage.getItem('sessionID-info');
      const browser = localStorage.getItem('browser-info');
      const device = localStorage.getItem('device-info');
      // type, device, browser, screen, sessionId, createdAt, data
      await panelAccess.postAction(action, screen, device, browser, sessionId, createdAt, data);
      // console.log({ postAction: { type: action, screen, device, browser, sessionId, createdAt, data } });

    } catch(error) {
      // console.log(error);
    };
  }

  toggleUserOptions() {
    const { showUserOptions } = this.state;
    this.setState({ showUserOptions: !showUserOptions });
  }

  onInputChange(val) {
    this.setState(val);
  }

  changeTestType(newTestType) {
    this.setState({ newTestType });
  }

  isCreateNewShow() {
    const { showAddNewModal } = this.state;

    return showAddNewModal;
  }

  toggleDownloadBoxContent () {
    const { toggleDownloadBox, downloadToggleBox} = this.props;
    toggleDownloadBox(!downloadToggleBox);
  }

  closeDownloadBox () {
    const { clearDownload } = this.props;
    clearDownload();
  }

  async addNewTest() {
    this.setState({ showAddNewModal: true, showAddNewModalLoader: true });

    const campaignAccess = new CampaignAccess();
    const campaignsFeatures = await campaignAccess.getCampaignFeatures();

    this.setState({ showAddNewModalLoader: false, campaignsFeatures, newTestType: campaignsFeatures[0].featuresId });

    /*
    , () => {
      console.log(this.state.newTestName);
      if (this.refs.newTestName)
        this.refs.newTestName.refresh();

      if (this.refs.newTestLang)
        this.refs.newTestLang.refresh();
    }
    */
  }

  async createCampaign() {
    const { newTestName, newTestLang, newTestType } = this.state;
    const { userData, showNotification } = this.props;

    try {
      this.setState({ AddTestLoading: true });
      const lang = localStorage.getItem('lang') || "en";
      const campaignTemplates = lang === "es" ? { ...newCampaignTemplateES } : { ...newCampaignTemplateEN };
      // const subscriptionID = userData?.subscription?.subscriptionId || '8e1eae68-8b88-45bd-a526-c386b1ae3b56';

      campaignTemplates.name = newTestName;
      campaignTemplates.lang = newTestLang;
      // campaignTemplates.showPromoButton = subscriptionID === '8e1eae68-8b88-45bd-a526-c386b1ae3b56' ? true : false;

      const newSegmentId = uuidv4().split('-');
      campaignTemplates.segments = [
        {
          segmentId: newSegmentId[0],
          segmentName: 'default-segment',
          gender: 'all',
          country: 'all',
          city: 'all',
          toAge: 99,
          numberOfPeopleThatHasParticipated: 0,
          numberOfPeople: -1,
          fromAge: 18
        }
      ]

      const campaignAccess = new CampaignAccess();
      const newCampaign = await campaignAccess.createCampaign(campaignTemplates, "20dfb31d-abd6-4943-961b-ab74508c6cc1");

      postActionGTMCampaing("GTM_new_campaign", newCampaign.campaignId, userData);
      this.sendEvent("control_create_test", undefined, { campaignId: newCampaign.campaignId });

      await this.refreshTestsData();

      this.goCampaignModules(newCampaign);

      this.setState({ showAddNewModal: false, AddTestLoading: false, newTestName: newCampaignTemplateES.name, newTestLang });

      showNotification("testCreated");
    } catch (error) {
      this.setState({ AddTestLoading: false });
      showNotification("genericError");
    }
  }

  async refreshTestsData() {
    const { setTestsData, setTemporalTestsData } = this.props;
    const campaignAccess = new CampaignAccess();
    const refreshedTestsData = await campaignAccess.getCampaigns();
    setTestsData(refreshedTestsData);
    setTemporalTestsData(refreshedTestsData);
    // console.log("refreshed tests from topbar")
  }

  goCreateNewTest() {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_CREATE_NEW });
  }

  goSupport() {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_SUPPORT_AND_HELP });
  }

  goAccount() {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_ACCOUNT });
  }

  goTests() {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_CAMPAIGNS });
  }

  goPricing() {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_PRICING });
  }

  goRecopilator(campaign) {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_CAMPAIGN_RECOPILATOR_ID.replace('[var1]', campaign.campaignId) });
  }

  goBuyAudience(campaign) {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_CAMPAIGN_BUY_AUDIENCE_ID.replace('[var1]', campaign.campaignId) });
  }

  goInviteAudience(campaign) {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_CAMPAIGN_INVITE_AUDIENCE_ID.replace('[var1]', campaign.campaignId) });
  }

  goCampaignModules(campaign) {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_CAMPAIGN_MODULES_ID.replace('[var1]', campaign.campaignId) });
  }

  goCampaignConfiguration(campaign) {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_CAMPAIGN_CONFIGURATION_ID.replace('[var1]', campaign.campaignId) });
  }

  goMetrics(campaign) {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_CAMPAIGN_METRICS_ID.replace('[var1]', campaign.campaignId) });
  }

  goWizardFile() {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_WIZARD_FILE });
  }

  goDefineAudience(campaign) {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_CAMPAIGN_DEFINE_AUDIENCE_ID.replace('[var1]', campaign.campaignId) });
  }

  goReviewTestAndPay(campaign) {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_CAMPAIGN_REVIEW_TEST_AND_PAY_ID.replace('[var1]', campaign.campaignId) });
  }

  render() {
    const { userData, location, t, downloadPercent, downloadShowMsg, downloadToggleBox, loadingAutoSave, hasContentHeader } = this.props;
    const { 
      showUserOptions, 
      showAddNewModal, 
      showAddNewModalLoader,
      AddTestLoading, 
      newTestName, 
      newTestLang, 
      newTestType,
      campaignsFeatures 
    } = this.state;
    let username = userData && userData.email ? userData.email : "";
    const isSubscriptionFilm = userData?.subscription?.filmsNumber === 0 ? false : true;

    if (userData && userData.data && userData.data.username)
      username = userData.data.username;

    let addTestModalName = 'TopBar_Add_New';
    if (isSubscriptionFilm) {
      addTestModalName = 'TopBar_Add_New_Film';
    }

    const renderCampaignsFeatures = campaignsFeatures.map(campaignFeatures => {
      return (
        <div className={`test-type-box ${newTestType === campaignFeatures.featuresId ? 'active' : ''}`} key={campaignFeatures.featuresId} onClick={() => this.changeTestType(campaignFeatures.featuresId)}>
          <div className="test-type-box-content">
            <div className="content-option">
              <p>{campaignFeatures.name}</p>
              <p>{campaignFeatures.price}€</p>
            </div>
            {/* <p className="content-description"><T text="TopBar_Add_New_Select_Type_Film_Description"/></p> */}
          </div>
        </div>
      );
    });

    return (
      <>
        <ModalDialog
          name={addTestModalName}
          showModal={showAddNewModal}
          loading={AddTestLoading}
          closeModal={e => { this.setState({ showAddNewModal: false }) }}
          onConfirm={() => { this.createCampaign() }}
        >
          <Input
            readOnly={AddTestLoading}
            placeholder=""
            name="newTestName"
            label={t('TopBar_Add_New_Input_Name')}
            value={newTestName}
            ref="newTestName"
            onChange={val => this.onInputChange(val)}
          />
          <Select
            readOnly={AddTestLoading}
            name="newTestLang"
            label={t("TopBar_Add_New_Input_Lang")}
            value={newTestLang}
            ref="newTestLang"
            onChange={val => this.onInputChange(val)}
            options={[
              { "value": "es", "label": t("Account_General_Lang_ES") },
              { "value": "en", "label": t("Account_General_Lang_EN") },
            ]}
          />
          {/*
            <Select
              readOnly={AddTestLoading}
              name="newTestLang"
              label={t("TopBar_Add_New_Input_Lang")}
              value={newTestLang}
              ref="newTestLang"
              onChange={val => this.onInputChange(val)}
              options={[
                { "value": "es", "label": t("Account_General_Lang_ES") },
                { "value": "en", "label": t("Account_General_Lang_EN") },
              ]}
            />
            {isSubscriptionFilm &&
                <div className="create-test-modal">
                  <p><T text="TopBar_Add_New_Select_Type" /></p>
                  <div className="test-type">
                    <div className={`test-type-box ${activeClassFilm}`} onClick={() => this.changeTestType('film')}>
                      <div className="test-type-box-icon"><i className="icon icon-video"></i></div>
                      <div className="test-type-box-content">
                        <div className="content-option">
                          <p><T text="TopBar_Add_New_Select_Type_Film"/></p>
                        </div>
                        <p className="content-description"><T text="TopBar_Add_New_Select_Type_Film_Description"/></p>
                      </div>
                    </div>
                    <div className={`test-type-box ${activeClassGeneric}`} onClick={() => this.changeTestType('generic')}>
                      <div className="test-type-box-icon"><i className="icon icon-to-end-1"></i></div>
                      <div className="test-type-box-content">
                        <div className="content-option">
                          <p><T text="TopBar_Add_New_Select_Type_Generic"/></p>
                        </div>
                        <p className="content-description"><T text="TopBar_Add_New_Select_Type_Generic_Description"/></p>
                      </div>
                    </div>
                  </div>
                </div>
              }
            */}
          {/* {showAddNewModalLoader ? (
            <div className="loading-modal">
              <div className="cover"></div>
              <div>
                <Spinner speed={0.8} color="#00C7E7" size={20} />
              </div>
            </div>
          ) : (
            <div className="create-test-modal">
              <p><T text="TopBar_Add_New_Select_Type" /></p>
               <div className="test-type">
                {renderCampaignsFeatures}
              </div> 
            </div>
          )} */}
        </ModalDialog>
        <div className="screen-topbar">
          <div className="row">
            <div className="left-content one-line">
              <img className="logo" src="/assets/img/main-logo.svg" alt="Company logo" onClick={() => this.goTests()}/>
              <div className="main-links">
                <Link inline className={location.pathname === URL_CAMPAIGNS ? "active" : ""} onClick={() => this.goTests()}>
                  <T text="TopBar_My_Tests" />
                </Link>
                {/*
                <Link inline className={location.pathname === URL_PRICING ? "active" : ""} onClick={() => this.goPricing()}>
                  <T text="TopBar_Plans_And_Prices" />
                </Link>
                */}
              </div>
            </div>
            <div className="right-content">
              <div className="main-buttons">
                {/*
                <Link main className="featured" onClick={() => this.goPricing()}>
                  <T text="TopBar_Change_Plan" />
                </Link>
                */}
                <Link main className="white button-with-loader" onClick={() => this.addNewTest()}>
                  {/* 
                  disabled={AddTestLoading}
                  {AddTestLoading && (
                    <Spinner speed={0.8} color="#00C7E7" size={16} />
                  )} */}
                  <T text="TopBar_Create_Test" />
                </Link>
              </div>
              <div className="user-options">
                <Link className="btn-open-popup" inline onClick={() => this.toggleUserOptions()}>
                  <div className="user-email">{username}</div>
                  <i className="icon icon-down-open-1"></i>
                </Link>
                {showUserOptions && (
                  <div className="user-options-menu" ref={this.wrapperRef}>
                    <div><Link inline onClick={() => { this.goAccount() }}><T text="TopBar_Submenu_My_Account" /></Link></div>
                    <div><Link inline onClick={() => { this.goSupport() }}><T text="TopBar_Submenu_Support_And_Help" /></Link></div>
                    <div className="separator"></div>
                    <div><Link inline onClick={() => { this.loggout() }}><T text="TopBar_Submenu_Close_Session" /></Link></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {loadingAutoSave && !hasContentHeader &&
          <div className="content-wrapper small" style={{marginTop: '6rem', marginBottom: '-9rem'}}>
            <div className="message-warning processing-data-warning">
              <h3>
                <T text="TopBar_AutoSave_Title" />
                <i className="icon icon-spin1 animate-spin"></i>
              </h3>
              <p><T text="TopBar_AutoSave_Text" /></p>
            </div>
          </div>
        }

        {downloadShowMsg &&
          <div className="content-widget download-box">
            <div className="download-box-header">
              <T text="TopBar_Download_Box_Header" />
              <div>
                <i className="icon icon-down-open-3" onClick={ () => this.toggleDownloadBoxContent() }></i>
                <i className="icon icon-cancel-5" onClick={ () => this.closeDownloadBox() }></i>
              </div>
            </div>
            <div className={downloadToggleBox ? 'download-box-body hidden' : 'download-box-body'}>
              {Object.keys(downloadPercent)?.length > 0 && Object.values(downloadPercent).map((p, i) =>
                <div className="download-box-content" key={p.id}>
                  <T text="TopBar_Download_Box_File" var1={i+1} />
                  <div className="progress-bar" style={{
                      background: p.error
                        ? '#EF233C'
                        : `#00c7e7 linear-gradient(to right, #D4D6D7 0%, #00c7e780 0%, #00c7e780 ${p.percent}%, #D4D6D7 ${p.percent}%) no-repeat`
                    }}>
                  </div>
                  <div style={{minWidth: '35px'}}>
                    <span>{p.error ? 'Error' : `${p.percent}%`}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        }
      </>
    );
  }
}

export default compose(
  withTranslation('translations', { withRef: true }),
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(TopBar);
