import React from 'react';

import '../styles/components/Input.scss';

class Input extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      value: '',
      invalid: false,
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  setInvalid() {
    this.setState({ invalid: true });
    setTimeout(() => {
      this.setState({ invalid: false });
    }, 3000)
  }

  componentWillMount() {
    const { value } = this.props;
    if (value)
      this.setState({value});
  }

  handleChange(e) {
    const { onChange } = this.props;
    const returnObj = {};

    if (e.target.validity.valid) {
      returnObj[e.target.name] = e.target.value;
      this.setState({ value: e.target.value, invalid: false });

      if (onChange)
        onChange(returnObj);
    }
  }

  handleClick(e) {
    const { onClick } = this.props;

    if (onClick)
      onClick(e);
  }

  handleFocus() {
    const { onFocus } = this.props;

    if (onFocus)
      onFocus();
  }

  refresh() {
    const { value } = this.props;

    this.setState({ value });
  }

  onKeyDown(e) {
    const { onPressEnter } = this.props;

    if (e.keyCode === 13 && onPressEnter) {
      onPressEnter();
    }
  }

  render() {
    const { hidden, id, step, min, max, icon, placeholder, name, label, type, last, text, readOnly, disabled, pattern, className, autoFocus, noSelectable, maxLength } = this.props;
    const { value, invalid } = this.state;
    let iconClass = `icon ${icon}`;
    let inputClass = "field";

    if (className)
      inputClass = `${inputClass} ${className}`;

    if (hidden)
      inputClass = `${inputClass} hidden`;

    if (invalid)
      inputClass = `${inputClass} invalid`;

    if (last)
      inputClass = `${inputClass} last`;

    if (disabled)
      inputClass = `${inputClass} disabled`;

    if (noSelectable)
      inputClass = `${inputClass} no-selectable`;

    if (text && !icon) {
      inputClass = `${inputClass} text`;

      return (
        <div className={inputClass}>
          {label && (
            <label htmlFor={name}>{label}</label>
          )}
          <div className="input-wrapper">
            <input
              id={id}
              autoFocus={autoFocus}
              readOnly={readOnly}
              value={value}
              onChange={this.handleChange}
              onClick={this.handleClick}
              onFocus={() => this.handleFocus()}
              name={name}
              type={type}
              min={min}
              max={max}
              maxLength={maxLength}
              pattern={pattern}
              placeholder={placeholder}
              onKeyDown={(e) => this.onKeyDown(e)}
            />
            <div className="input-text">{text}</div>
          </div>
        </div>
      );
    }

    return (
      <div className={inputClass}>
        {label && (
          <label htmlFor={name}>{label}</label>
        )}
        <div className="input-wrapper">
          <input
            id={id}
            autoFocus={autoFocus}
            readOnly={readOnly}
            value={value}
            onChange={this.handleChange}
            onClick={this.handleClick}
            name={name}
            type={type}
            min={min}
            max={max}
            maxLength={maxLength}
            pattern={pattern}
            placeholder={placeholder}
            onKeyDown={(e) => this.onKeyDown(e)}
          />
          {icon && (
            <div className="input-icon"><i className={iconClass}></i></div>
          )}
          {text && (
            <span className="button-upload-image" onClick={this.handleClick}>{text}</span>
          )}
        </div>
      </div>
    );
  }
}

export default Input