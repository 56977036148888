import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { AnalysisAccess } from '@sounditi/ft2-api';
import { Spinner } from 'react-activity';
import { ResponsiveBar } from '@nivo/bar'
import { withTranslation } from 'react-i18next';

import T from '../Translate';

import { getURLParameter } from '../../utils/global';
import {parseGlobalSummary, parseLinks } from '../../utils/metrics';

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({})

class TopLocations extends React.Component {
  _mount = true;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
    };
  }

  componentWillMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this._mount = false;
  }

  async loadData() {
    const { activeModule, currentModalLink, globalSummary } = this.props;
    // If it's "global" activeModule will be "false"

    this.setState({ loading: true });

    // LOGIC TO LOAD DATA HERE
    let data =  []
    const analysisAccess = new AnalysisAccess();
    const campaignId = getURLParameter("id");
    try {
      /*if (activeModule != false) {
        const campaignAudienceSummary = await analysisAccess.moduleAudienceSummary({ moduleId: activeModule.id });
        data = analysisAccess.parseTopLocations(campaignAudienceSummary.audience)
      } else {
        const campaignId = getURLParameter("id");
        const campaignAudienceSummary = await analysisAccess.campaignAudienceSummary({campaignId});
        data = analysisAccess.parseTopLocations(campaignAudienceSummary)
      }*/
      if (currentModalLink) {
        data = parseLinks(currentModalLink, 'city_locations');
      } else {
        data = parseGlobalSummary(globalSummary, 'city_locations');
      }
    } catch(error) {
      // TODO: Add a error message in widged with button to reload data
    }

    if (this._mount) {
      this.setState({ data: data.reverse(), loading: false });
    }
  }

  render() {
    const { data, loading } = this.state;
    const { t } = this.props;

    if (loading) {
      return (
        <div className="responsive-chart recopilator-graph">
          <div className="loading-cover">
            <div className="cover"></div>
            <div>
              <Spinner speed={0.8} color="#00C7E7" size={20} />
            </div>
          </div>
        </div>
      );
    }

    return ( 
      <div className="responsive-chart recopilator-graph top-locations">
        <div className="flex">
          {
          data.length > 0 ?
            <ResponsiveBar
              data={data}
              margin={{ top: 20, right: 10, bottom: 60, left: 50 }}
              padding={0.3}
              minValue={0}
              //maxValue={100}
              layout="vertical"
              indexBy="id"
              colors={{ scheme: 'spectral' }}
              colorBy="value"
              enableGridY={true}
              enableLabel={true}
              labelTextColor="#EDEDF1"
              labelFormat={v => `${v}%`}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: 'bottom',
                tickSize: 10,
                tickPadding: 5,
                tickRotation: -30,
                legend: t('TopLocations_Bottom_Legend'),
                legendOffset: 50,
                legendPosition: 'middle'
              }}
              axisLeft={{
                orient: 'left',
                tickSize: 0,
                tickPadding: 5,
                tickRotation: 0,
                //legend: t('TopLocations_Left_Legend'),
                legendOffset: -95,
                legendPosition: 'middle',
                format: v => `${Math.abs(v)}%`,
              }}
              theme={{
                fontSize: 11,
                textColor: '#7f7d86',
                axis: {
                  ticks: {
                    line: {
                      stroke: "#7f7d86"
                    }
                  },
                  legend: {
                    text: {
                      fontSize: 15,
                      fontWeight: 600
                    }
                  }
                },
                grid: {
                  line: {
                    stroke: "#D4D6D7",
                    strokeWidth: 1,
                  }
                },
                crosshair: {
                  line: {
                      stroke: '#ffffff',
                      strokeWidth: 1,
                      strokeOpacity: 0.35,
                  },
                },
                labels: {
                  text: {
                    fontWeight: 600,
                    fontSize: 12
                  }
                }
              }}
              tooltip={({ data, color }) => {
                return (
                  <div className="recopilator-tooltip">
                    <span className="recopilator-tooltip-interaction" style={{backgroundColor: color}}></span>
                    <span>{data.id}: <strong>{data.value}% ({data.number})</strong></span>
                  </div>
                )
              }}
            />
            : <div className="no-results-msg">
                <img src="../assets/img/no-campaigns-img.png" alt="No campaigns" />
                <h2><T text="Metrics_No_Results" /></h2>
                <p><T text="Metrics_No_Results_Text" /></p>
              </div>
          }
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation('translations', { withRef: true }),
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(TopLocations);
