// COMPONENTS
import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { CampaignAccess, SounditiAuth } from '@sounditi/ft2-api';
import Link from './Link';
import ModalDialog from '../components/ModalDialog';
import Input from '../components/Input';
import Select from '../components/Select';
import T from '../components/Translate';

// ACTIONS
import { showNotification } from '../reducers/notifications';
import { setRedirect } from '../reducers/navigation';
import { setTestsData } from '../reducers/user';
import { postActionGTMCampaing } from '../utils/events';

// TYPES
import { newCampaignTemplateES } from '../config/campaignTemplates';
import { newCampaignTemplateEN } from '../config/campaignTemplates';
import {
  URL_CAMPAIGNS,
  URL_PRICING,
  URL_CAMPAIGN_CONFIGURATION_ID,
  URL_CAMPAIGN_MODULES_ID,
  URL_ACCOUNT,
  URL_SUPPORT_AND_HELP
} from '../config/urls';

const mapStateToProps = (state, ownProps) => ({
  userData: state.user.userData,
  testsData: state.user.testsData,
  loadingMetrics: state.navigation.loadingMetrics,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setTestsData: val => dispatch(setTestsData(val)),
  setRedirect: val => dispatch(setRedirect(val)),
  showNotification: val => dispatch(showNotification(val)),
  dispatch
})

class MobileTopBar extends React.Component {
  _mount = true;

  constructor(props) {
    super(props);
    this.state = {
      showUserOptions: false
    };
  }

  componentWillMount() {

  }

  componentDidMount() {
    window.addEventListener('click', (e) => {
      let closePopup = true;

      Array.from(document.getElementsByClassName('btn-user-options')).forEach(element => {
        if (element.contains(e.target)){
          closePopup = false;
        }
      });

      if (this._mount && closePopup)
        this.setState({ showUserOptions: false });
    });
  }

  componentWillUnmount() {
    this._mount = false;
  }

  loggout() {
    localStorage.clear();
    const auth = new SounditiAuth();
    auth.logOut();
    window.Froged('logout');
    window.location.reload();
  }

  toggleUserOptions() {
    const { showUserOptions } = this.state;
    this.setState({ showUserOptions: !showUserOptions });
  }

  goCampaigns() {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_CAMPAIGNS });
  }

  render() {
    const {
      userData,
      children,
      onClickToggleDemoData,
      showDemoData,
      location,
      t,
      title,
      subtitle,
      haveDemo,
      onClickBackButton,
      loadingMetrics,
    } = this.props;
    const { showUserOptions } = this.state;

    return (
      <div className="mobile-topbar">
        <div className="main-topbar">
          <div className="fluid-width">
            <img className="logo" src="/assets/img/main-logo.svg" alt="Company logo" onClick={() => this.goCampaigns()} />
          </div>
          <div className="topbar-actions">
            <div className="btn-icon btn-user-options" onClick={() => this.toggleUserOptions()}>
              <i className='icon icon-user'></i>
            </div>
            {showUserOptions && (
              <div className="user-options-menu" ref={this.wrapperRef}>
                {/* <div><Link inline onClick={() => { this.goAccount() }}><T text="TopBar_Submenu_My_Account" /></Link></div>
                <div><Link inline onClick={() => { this.goSupport() }}><T text="TopBar_Submenu_Support_And_Help" /></Link></div>
                <div className="separator"></div> */}
                <div><Link inline onClick={() => { this.loggout() }}><T text="TopBar_Submenu_Close_Session" /></Link></div>
              </div>
            )}
          </div>
        </div>
        <div className={`content-topbar ${onClickBackButton ? '' : 'add-padding'}`}>
          {onClickBackButton && (
            <div className="back-button" onClick={() => onClickBackButton()}>
              <i className='icon icon-left-open-1'></i>
            </div>
          )}
          <div className="fluid-width">
            {subtitle && (
              <div className="subtitle">{subtitle}</div>
            )}
            <div className="title">{title}</div>
          </div>
          {children}
        </div>
        {haveDemo && (
          <div className={showDemoData ? "content-topbar enable-demo show-demo-data" : "content-topbar enable-demo"}>
            <div className="fluid-width"></div>
            <div className="btn-icon btn-toggle-media-preview" onClick={ !loadingMetrics ? () => onClickToggleDemoData() : undefined }>
              <div className="label">Activar demostración</div>
              <div className={showDemoData ? 'button-toggle active' : 'button-toggle'}>
                <div className="handle"></div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  withTranslation('translations', { withRef: true }),
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(MobileTopBar);
