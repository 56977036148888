import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Link from './Link';
import T from './Translate';

import { generateRandomNumber } from '../utils/global';

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = (dispatch, ownProps) => ({
})

class DownloadMetricsButtons extends React.Component {
  _mount = true;

  constructor(props){
    super(props);
    this.state = {
      showDownloadOptions: false,
      componentId: '',
    }
  }

  componentDidMount() {
    const componentId = 'id' + generateRandomNumber().toString();
    this.setState({ componentId });
  }

  componentDidUpdate(prevProps, prevState) {
    const { componentId } = this.state;
    if (prevState.componentId === '' && this.state.componentId !== '') {
      window.addEventListener('click', (e) => {
        let closePopup = true;

        const element = document.querySelector(`#${componentId}`);
        if (element && element.contains(e.target)) {
          closePopup = false;
        }

        if (this._mount && closePopup) {
          this.setState({ showDownloadOptions: false });
        }
      });
    }
  }

  componentWillUnmount() {
    this._mount = false;
  }

  toggleDownloadOptions() {
    const { showDownloadOptions } = this.state;
    this.setState({ showDownloadOptions: !showDownloadOptions });
  }

  exportMetricsCsv(type, campaignId, moduleId, stimulusId) {
    this.props.exportMetricsCsvCallback(type, campaignId, moduleId, stimulusId);
  }


  render() {
    const { t, campaignId, moduleId, stimulusId } = this.props;
    const { showDownloadOptions, componentId } = this.state;


    return (
      <div className="metrics-download">
        <div className="metrics-download-button" id={componentId} onClick={ () => this.toggleDownloadOptions() }>
          <i className="icon icon-ellipsis-vert"></i>
        </div>
        {showDownloadOptions &&
          <div className="metrics-download-options">
            <Link inline onClick={ () => this.exportMetricsCsv('testersAvg', campaignId, moduleId, stimulusId) }>
              <i className='icon icon-download-5'></i><T text="Metrics_Download_Button_Averages_Csv" />
            </Link>
            <Link inline onClick={ () => this.exportMetricsCsv('metrics', campaignId, moduleId, stimulusId) }>
              <i className='icon icon-download-5'></i><T text="Metrics_Download_Button_Aggregate_Csv" />
            </Link>
          </div>
        }
      </div>
    );
  }
}

export default compose(
  withTranslation('translations', { withRef: true }),
  connect(mapStateToProps, mapDispatchToProps)
)(DownloadMetricsButtons);
