import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ResponsiveRadar } from '@nivo/radar';

import T from '../Translate';

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({})

class AverageEmotions extends React.Component {
  _mount = true;

  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  componentWillMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this._mount = false;
  }

  async loadData() {
    const { t, activeModuleData } = this.props;

    let data = [];

    //console.log(activeModuleData);

    for (const property in activeModuleData) {
      let emotion = '';
      switch (property) {
        case 'happiness':
          emotion = t('AverageEmotions_Emotion_Happiness');
          break;
        case 'surprise':
          emotion = t('AverageEmotions_Emotion_Surprise');
          break;
        case 'sadness':
          emotion = t('AverageEmotions_Emotion_Sadness');
          break;
        case 'fear':
          emotion = t('AverageEmotions_Emotion_Fear');
          break;
        case 'anger':
          emotion = t('AverageEmotions_Emotion_Anger');
          break;
        case 'disgust':
          emotion = t('AverageEmotions_Emotion_Disgust');
          break;
        default:
          break;
      }

      data.push({
        'emotion': emotion,
        'value': activeModuleData[property]
      })
    }

    if (this._mount) {
      this.setState({ data });
    }
  }


  render() {
    const { data } = this.state;

    return (
      <div className="responsive-chart emotional-overview">
        <ResponsiveRadar
          data={data}
          keys={['value']}
          indexBy="emotion"
          maxValue="auto"
          margin={{ top: 20, right: 70, bottom: 20, left: 70 }}
          curve="linearClosed"
          borderWidth={2}
          borderColor={{ from: 'color' }}
          gridLevels={6}
          gridShape="linear"
          gridLabelOffset={16}
          fillOpacity={0.6}
          blendMode="multiply"
          animate={true}
          motionConfig="wobbly"
          isInteractive={true}
          colors={d => '#00C7E7'}
          gridShape='linear'
          gridLevels={6}
          theme={{
            fontFamily: 'Montserrat, sans-serif',
            tooltip: {
              display: 'none'
            }
          }}
        />
      </div>
    );
  }
}

export default compose(
  withTranslation('translations', { withRef: true }),
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(AverageEmotions);
