import React from 'react';
import T from '../Translate';

class AdquisitionMethod extends React.Component {
  constructor(props){
    super(props);
    this.state = {}
  }

  render() {
    const { onChooseAudienceType } = this.props;

    return (
      <div className="content-wrapper recopilator big">
        <div className="recopilator-choose-wrapper">
          <div className="recopilator-text">
            <h2><T text="Recopilator_Choose_Audience_Type_Title" /></h2>
          </div>
          <div className="recopilator-boxes">
            <div className="recopilator-box" onClick={() => onChooseAudienceType('own')}>
              <span className="recopilator-box-tag">
                <T text="Recopilator_Choose_Audience_Type_Box_Links_Tag" />
              </span>
              <div className="recopilator-box-icon">
                <img className="logo" src="/assets/img/recopilator-options-link.png" alt="Link option image" />
              </div>
              <div className="recopilator-box-text">
                <h2><T text="Recopilator_Choose_Audience_Type_Box_Links_Title" /></h2>
                <p><T text="Recopilator_Links_Text" /></p>
              </div>
            </div>
            <div className="recopilator-box" onClick={() => onChooseAudienceType('email')}>
              <span className="recopilator-box-tag">
                <T text="Recopilator_Choose_Audience_Type_Box_Emails_Tag" />
              </span>
              <div className="recopilator-box-icon">
                <img className="logo" src="/assets/img/recopilator-options-email.png" alt="Email option image" />
              </div>
              <div className="recopilator-box-text">
                <h2><T text="Recopilator_Choose_Audience_Type_Box_Emails_Title" /></h2>
                <p><T text="Recopilator_Choose_Audience_Type_Box_Emails_Description" /></p>
              </div>
            </div>
            <div className="recopilator-box featured" onClick={() => window.Froged('open','contact')}>
              <span className="recopilator-box-tag">
                <T text="Recopilator_Choose_Audience_Type_Box_Alyze_Audience_Tag" />
              </span>
              <div className="recopilator-box-icon">
                <img className="logo" src="/assets/img/recopilator-options-alyze.png" alt="Alyze audience option image" />
              </div>
              <div className="recopilator-box-text">
                <h2><T text="Recopilator_Choose_Audience_Type_Box_Alyze_Audience_Title" /></h2>
                <p><T text="Recopilator_Links_Paid_Text" /></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdquisitionMethod