import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { Spinner } from 'react-activity';
import Scrollbar from 'smooth-scrollbar';
import Dialog from 'rc-dialog';
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next';
import { CampaignAccess } from '@sounditi/ft2-api';

import { getURLParameter } from '../../utils/global';
import { showNotification } from '../../reducers/notifications';
import { setRedirect } from '../../reducers/navigation';
import { setTestsData, setTemporalTestsData } from '../../reducers/user';
import { postActionGTMCampaing } from '../../utils/events';

import T from '../../components/Translate';
import Link from '../../components/Link';
import InputColor from '../../components/InputColor';
import InputUploadFile from '../../components/InputUploadFile';
import Input from '../../components/Input';
import Select from '../../components/Select';
import DesignMenu from '../../components/DesignMenu';
import ContentHeader from '../../components/ContentHeader';
import TopBar from '../../components/TopBar';

import { URL_ENVIRONMENT } from '../../config/global';

const mapStateToProps = (state, ownProps) => ({
  userData: state.user.userData,
  testsData: state.user.testsData,
  loadingAutoSave: state.navigation.loadingAutoSave,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setTestsData: val => dispatch(setTestsData(val)),
  setRedirect: val => dispatch(setRedirect(val)),
  showNotification: val => dispatch(showNotification(val)),
  setTemporalTestsData: val => dispatch(setTemporalTestsData(val)),
  dispatch
})

class Preview extends React.Component {
  _mount = true;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
	    loadingToggle: false,
      previewURL: "",
      status: "",
      isPreviewActive : false,
      dataValidate: true,
      campaignData: [],
      changesToApply: false,
      showUnpublishModal: false,
      showModalSubscriptions: false
    };
  }

  componentWillMount() {
    this.loadData();
  }

  componentDidMount() {
    if (this.refs.topbar)
      this.refs.topbar.sendEvent("control_enter_screen_preview", "Preview")
  }

  componentWillUnmount() {
    this._mount = false;
  }

  componentDidUpdate() {

  }

  async refreshTestsData() {
    const { setTestsData, setTemporalTestsData, loadingAutoSave } = this.props;
    const campaignAccess = new CampaignAccess();
    const refreshedTestsData = await campaignAccess.getCampaigns();

    if (!loadingAutoSave) {
      setTemporalTestsData(refreshedTestsData);
    }
    setTestsData(refreshedTestsData);
  }

  async loadData() {
    if (this._mount) {
      const { testsData } = this.props;

      const campaignId = getURLParameter("id");
      const campaignData = testsData.filter(test => test.campaignId === campaignId)[0];

      if (!campaignId || !campaignData)
        this.goCampaigns()


      if (campaignData){
        let url = `${campaignData.previewUrl}?id=${campaignData.campaignId}&token=${campaignData.previewToken}`;

        const status = campaignData.status || "";
        const isPreviewActive = campaignData.isPreviewActive || false;
        const previewURL = isPreviewActive ? url : "";

        this.setState({
          campaignData,
          previewURL,
          status,
          isPreviewActive,
          changesToApply: false,
        }, () => {
          if (this._mount) {
            if (this.refs.previewURL)
              this.refs.previewURL.refresh();

            this.refreshTestsData();

            setTimeout(() => {
              this.setState({ loading: false });
            }, 1500);
          }
        });
      }
    }
  }

  async launchPreview(callback) {
    const { testsData, dispatch, showNotification } = this.props;
    const { campaignData, loading } = this.state;
    const { campaignId, userId } = campaignData;

    try {
      if (this._mount) {
        this.setState({ loadingToggle: true });
      }
      const campaignAccess = new CampaignAccess();
      const newData = await campaignAccess.changeStatus(campaignId, true);

      this.publishOrUnpublishPreviewGAEvent();

      const updatedTestsData = testsData.map(test => {
        if (test.campaignId === campaignId) {
          test.status = newData.status;
          test.isPreviewActive = newData.isPreviewActive;
          test.previewUrl = newData.previewUrl;
          test.previewToken = newData.previewToken;
          test.previewPassword = newData.previewPassword;
        }

        return test;
      });

      dispatch(setTestsData(updatedTestsData)).then(()=>{
        if (this._mount) {
          this.setState({ loadingToggle: false });
        }
        this.loadData();

        if (callback)
          callback();
      })
    } catch(error) {
      this.setState({ loadingToggle: false });
      showNotification("genericError");
    }
  }

  async publishOrUnpublishPreviewGAEvent() {
    const { userData } = this.props;
    const { campaignData } = this.state;
    const { campaignId } = campaignData;

    if (campaignId && campaignId !== "") {
      try {
        if (campaignData.isPreviewActive === true) {
          postActionGTMCampaing("GTM_publish_preview_campaign", campaignId, userData);
        } else {
          postActionGTMCampaing("GTM_unpublish_preview_campaign", campaignId, userData);
        }
      } catch(error) {}
    }
  }

  validateData() {
    this.setState({ dataValidated: true, changesToApply: true });
  }

  onInputChange(val) {
    this.setState(val, () => {
      this.validateData();
      if (this._mount) {
        // this.refs.phonePreviewGeneral.loadData();
        // this.refs.phonePreviewLanding.loadData();
      }
    });
  }

  goCampaigns() {
    const { setRedirect } = this.props;
    const link = `/tests/`;
    setRedirect({ route: link });
  }

  goModules() {
    const { setRedirect } = this.props;
    const campaignId = getURLParameter("id");
    const link = `/tests/modules?id=${campaignId}`;
    setRedirect({ route: link });
  }

  goConfiguration() {
    const { setRedirect } = this.props;
    const campaignId = getURLParameter("id");
    const link = `/tests/configuration?id=${campaignId}`;
    setRedirect({ route: link });
  }

  prevStep() {
    this.modifyCampaign(() => this.goConfiguration());
  }

  nextStep() {
    this.modifyCampaign(() => this.goModules());
  }

  getPreview(noCamera, livePreviewEmbedded) {
    const { campaignData } = this.state;
    const { campaignId, userId } = campaignData;

    if (!campaignData.data)
      return "";

    const lang = campaignData.data.lang || "en";

    const jwt = require('jwt-simple');
    const payload = {
      "sub": userId,
      "name": "",
      "iat": 0,
      "iss": "",
      "aud": "",
      "exp": 0,
      "forcePreview": true,
      "campaignId": campaignId
    };
    const secret = "super-tester-secret"
    const token = jwt.encode(payload, secret);

    const link = `${URL_ENVIRONMENT}?id=${campaignId}&token=${token}&s=core-landing&l=${lang}&livepreview=false&nc=${noCamera}&livepreviewembedded=${livePreviewEmbedded}`;
    return link;

    // const win = window.open(link, '_blank');
    // win.focus();
  }

  redirectToPreview() {
    const previewURL = this.getPreview(false);
    window.open(previewURL, '_blank');
  }

  copyToClipboard() {
    const { showNotification } = this.props;

    const input = document.getElementById('preview-url');
    input.select();
    document.execCommand("copy");
    window.getSelection().removeAllRanges();

    showNotification("copyClipboard");
  }

  render() {
    const {
      loading,
	    loadingToggle,
      campaignData,
      changesToApply,
      previewURL,
      status,
      isPreviewActive
    } = this.state;
    const { location, t } = this.props;

    return (
      <div className="app screen-campaigns">
        <Helmet>
          <title>{t('Design_Browser_Title')}</title>
        </Helmet>
        <div className="main-wrapper">
          <TopBar ref="topbar" location={location} />
          <div className="screen-content preview">
            <ContentHeader
              location={location}
              campaignData={campaignData}
              changesToApply={changesToApply}
            />
            <div className="section-modules">
              {loadingToggle && (
                <div className="loading-cover">
                  <div className="cover"></div>
                  <div>
                    <Spinner speed={0.8} color="#00C7E7" size={20} />
                  </div>
                </div>
              )}
              <div className="section-title">
                <h2><T text="Preview_Title" /></h2>
                <p><T text="Preview_Subtitle" /></p>
              </div>
              <div className="design-options sidebar-options">
                <div>
                  <div className="field">
                    <label><T text="Preview_Try_Test_Title" /></label>
                    <p><T text="Preview_Try_Test_Text" /></p>
                    <Link
                      main
                      className="full-width"
                      onClick={() => this.redirectToPreview()}
                    >
                      <T text="Preview_Try_Test_CTA" />
                    </Link>
                  </div>
                  <div className="field">
                    <div className="row">
                      <label className="full-width"><T text="Preview_Publish_Title" /></label>
                      <div className={isPreviewActive ? 'button-toggle active' : 'button-toggle'} onClick={() => this.launchPreview()}>
                        <div className="handle"></div>
                      </div>
                    </div>
                    <p><T text="Preview_Publish_Text_A" /></p>
                    <p><T text="Preview_Publish_Text_B" /></p>
                  </div>
                  <div className="field">
                    <label><T text="Preview_Share_Title" /></label>
                    <Input
                      id="preview-url"
                      readOnly
                      disabled={!isPreviewActive}
                      placeholder="..."
                      name="previewURL"
                      value={previewURL}
                      ref="previewURL"
                      onChange={val => this.onInputChange(val)}
                    />
                    <Link
                      secondary
                      className="full-width margin-bottom-4"
                      onClick={() => this.copyToClipboard()}
                      disabled={!isPreviewActive}
                    >
                      <T text="Preview_Share_CTA" />
                    </Link>
                    <p><T text="Preview_Share_Text" /></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="screen-content-inner">
              {loading && (
                <div className="loading-cover">
                  <div className="cover design"></div>
                  <div>
                    <Spinner speed={0.8} color="#00C7E7" size={20} />
                  </div>
                </div>
              )}
              {/*
              <iframe id="iframepreview" title="preview" frameBorder="0" src={this.getPreview(true)}
                onLoad={() => setTimeout(() => this.setState({ loading: false }), 500)}
              />
              */}
              <object id="iframepreview" data={this.getPreview(true, true)}>
                Your browser don't support this feature. Please use the lastest version of Google Chrome.
              </object>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(Preview);
