import React from 'react';
import { connect } from 'react-redux';
import { setRedirect } from '../reducers/navigation';

import '../styles/components/Link.scss';

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setRedirect: val => dispatch(setRedirect(val))
})

class Link extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pathname: '',
    };
  }

  componentWillMount() {
    const new_pathname = window.location.pathname;
    this.setState({ pathname: new_pathname });
  }

  componentDidUpdate() {
    const { pathname } = this.state;
    const new_pathname = window.location.pathname;

    if (new_pathname !== pathname)
      this.setState({ pathname: new_pathname });
  }

  onClick(e) {
    const { onClick, to, setRedirect } = this.props;
    const redirect = {
      route: to
    };

    if (onClick) {
      onClick(e);
      if (!to)
        return false;
    }

    setRedirect(redirect);
  }

  render() {
    const {
      children,
      disabled,
      main,
      secondary,
      sidebar,
      to,
      icon,
      step,
      inline,
      className
    } = this.props;
    const { pathname } = this.state;
    const toParsed = to ? to.split("?")[0] : "";
    const active = pathname === toParsed || pathname === `${toParsed}/`;

    let buttonClass = "text-button";
    const iconClass = `icon ${icon}`;

    if (main)
      buttonClass = "button main-button";

    if (secondary)
      buttonClass = "button secondary";

    if (inline)
      buttonClass = "inline";

    if (active)
      buttonClass = buttonClass + " active";

    if (className)
      buttonClass = `${buttonClass} ${className}`;

    if (sidebar && step)
      return (
        <button
          disabled={disabled}
          type="button"
          className={buttonClass}
          onClick={e => this.onClick(e)}
        >
          <div className="icon-container">
            <span className="step">{step}</span>
          </div>
          <p>{children}</p>
        </button>
      );

    if (sidebar)
      return (
        <button
          disabled={disabled}
          type="button"
          className={buttonClass}
          onClick={e => this.onClick(e)}
        >
          <div className="icon-container">
            <i className={iconClass}></i>
          </div>
          <p>{children}</p>
        </button>
      );

    return (
      <button
        disabled={disabled}
        type="button"
        className={buttonClass}
        onClick={e => this.onClick(e)}
      ><div className="button-text">{children}</div></button>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Link)