import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { Spinner } from 'react-activity';
import { Helmet } from 'react-helmet'
import ReactTooltip from "react-tooltip";
import { withTranslation } from 'react-i18next';
import { CampaignAccess } from '@sounditi/ft2-api';

import { getURLParameter } from '../../utils/global';
import { showNotification } from '../../reducers/notifications';
import { setRedirect, showVersionControl } from '../../reducers/navigation';
import { setTestsData, setTemporalTestsData } from '../../reducers/user';

import T from '../../components/Translate';
import Link from '../../components/Link';
import InputColor from '../../components/InputColor';
import InputUploadFile from '../../components/InputUploadFile';
import Input from '../../components/Input';
import Select from '../../components/Select';
import DesignMenu from '../../components/DesignMenu';
import ContentHeader from '../../components/ContentHeader';
import TopBar from '../../components/TopBar';
import VersionControl from '../../components/VersionControl';

import { URL_ENVIRONMENT } from '../../config/global';

const mapStateToProps = (state, ownProps) => ({
  userData: state.user.userData,
  testsData: state.user.testsData,
  temporalTestsData: state.user.temporalTestsData,
  openVersionControl: state.navigation.openVersionControl,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setTestsData: val => dispatch(setTestsData(val)),
  setRedirect: val => dispatch(setRedirect(val)),
  showVersionControl: val => dispatch(showVersionControl(val)),
  showNotification: val => dispatch(showNotification(val)),
  setTemporalTestsData: val => dispatch(setTemporalTestsData(val)),
  dispatch
})

class Design extends React.Component {
  _mount = true;
  _refreshTimeout = undefined;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      dataValidate: true,
      campaignData: [],
      campaignDataSaved: [],
      changesToApply: false,
      showLandingTab: true,
      generalColorsTitle: '',
      generalColorsText: '',
      generalColorsBackground: '',
      generalLogoType: '',
      generalLogoText: '',
      generalLogoURL: '',
      generalBackgroundType: '',
      generalBackgroundURL: '',
      generalPrimaryBtnType: '',
      generalPrimaryBtnBackground: '',
      generalPrimaryBtnText: '',
      generalPrimaryBtnBorderRadius: '',
      generalSecondaryBtnType: '',
      generalSecondaryBtnBackground: '',
      generalSecondaryBtnText: '',
      generalSecondaryBtnBorderRadius: '',
      landingColorsTitle: '',
      landingColorsText: '',
      landingColorsBackground: '',
      landingLogoType: '',
      landingLogoText: '',
      landingLogoURL: '',
      landingBackgroundType: '',
      landingBackgroundURL: '',
      landingPrimaryBtnType: '',
      landingPrimaryBtnBackground: '',
      landingPrimaryBtnText: '',
      landingPrimaryBtnBorderRadius: '',
      landingSecondaryBtnType: '',
      landingSecondaryBtnBackground: '',
      landingSecondaryBtnText: '',
      landingSecondaryBtnBorderRadius: '',
      showUnpublishModal: false,
      livePreviewRunning: false,
      temporalUpdatedDate: false,
    };
  }

  componentWillMount() {
    this.loadData();
  }

  componentDidMount() {
    if (this.refs.topbar)
      this.refs.topbar.sendEvent("control_enter_screen_design", "Design")
  }

  componentWillUnmount() {
    this._mount = false;
  }

  componentDidUpdate() {

  }

  async refreshTestsData() {
    const { setTestsData, setTemporalTestsData } = this.props;
    const { temporalUpdatedDate } = this.state;
    const campaignId = getURLParameter("id");
    const campaignAccess = new CampaignAccess();
    const refreshedTestsData = await campaignAccess.getCampaigns();

    const refreshedCurrentTest = Object.assign({}, refreshedTestsData.find(test => test.campaignId === campaignId));
    const refreshedTemporalTestsData = this.props.temporalTestsData.map(test => {
      let testCopy = Object.assign({}, test);
      if (test.campaignId === campaignId) {
        testCopy = refreshedCurrentTest;
        testCopy.data = temporalUpdatedDate ? { ...test.data, updatedAt: temporalUpdatedDate } : test.data;
      }
      return testCopy;
    });

    this.setState({ temporalUpdatedDate: false });
    setTemporalTestsData(refreshedTemporalTestsData);
    setTestsData(refreshedTestsData);
  }

  async loadData() {
    if (this._mount) {
      const { testsData, temporalTestsData } = this.props;

      const campaignId = getURLParameter("id");
      const campaignDataSaved = testsData.filter(test => test.campaignId === campaignId)[0];
      const campaignData = temporalTestsData.find(test => test.campaignId === campaignId);

      if (!campaignId || !campaignData)
        this.goCampaigns()

      // if (!campaignData.data.designPreview)
      //    campaignData.data.designPreview = campaignData.data.design;

      const {
        generalColorsTitle,
        generalColorsText,
        generalColorsBackground,
        generalLogoType,
        generalLogoText,
        generalLogoURL,
        generalBackgroundType,
        generalBackgroundURL,
        generalPrimaryBtnType,
        generalPrimaryBtnBackground,
        generalPrimaryBtnText,
        generalPrimaryBtnBorderRadius,
        generalSecondaryBtnType,
        generalSecondaryBtnBackground,
        generalSecondaryBtnText,
        generalSecondaryBtnBorderRadius,
        landingColorsTitle,
        landingColorsText,
        landingColorsBackground,
        landingLogoType,
        landingLogoText,
        landingLogoURL,
        landingBackgroundType,
        landingBackgroundURL,
        landingPrimaryBtnType,
        landingPrimaryBtnBackground,
        landingPrimaryBtnText,
        landingPrimaryBtnBorderRadius,
        landingSecondaryBtnType,
        landingSecondaryBtnBackground,
        landingSecondaryBtnText,
        landingSecondaryBtnBorderRadius
      } = campaignData.data.design;

      this.setState({
        campaignData,
        campaignDataSaved,
        generalColorsTitle,
        generalColorsText,
        generalColorsBackground,
        generalLogoType,
        generalLogoText,
        generalLogoURL,
        generalBackgroundType,
        generalBackgroundURL,
        generalPrimaryBtnType,
        generalPrimaryBtnBackground,
        generalPrimaryBtnText,
        generalPrimaryBtnBorderRadius,
        generalSecondaryBtnType,
        generalSecondaryBtnBackground,
        generalSecondaryBtnText,
        generalSecondaryBtnBorderRadius,
        landingColorsTitle,
        landingColorsText,
        landingColorsBackground,
        landingLogoType,
        landingLogoText,
        landingLogoURL,
        landingBackgroundType,
        landingBackgroundURL,
        landingPrimaryBtnType,
        landingPrimaryBtnBackground,
        landingPrimaryBtnText,
        landingPrimaryBtnBorderRadius,
        landingSecondaryBtnType,
        landingSecondaryBtnBackground,
        landingSecondaryBtnText,
        landingSecondaryBtnBorderRadius,
        changesToApply: false,
      }, () => {
        if (this._mount) {
          if (this.refs.generalColorsTitle)
            this.refs.generalColorsTitle.refresh();

          if (this.refs.generalColorsText)
            this.refs.generalColorsText.refresh();

          if (this.refs.generalColorsBackground)
            this.refs.generalColorsBackground.refresh();

          if (this.refs.generalLogoType)
            this.refs.generalLogoType.refresh();

          if (this.refs.generalLogoText)
            this.refs.generalLogoText.refresh();

          if (this.refs.generalLogoURL)
            this.refs.generalLogoURL.refresh();

          if (this.refs.generalBackgroundType)
            this.refs.generalBackgroundType.refresh();

          if (this.refs.generalBackgroundURL)
            this.refs.generalBackgroundURL.refresh();

          if (this.refs.generalPrimaryBtnType)
            this.refs.generalPrimaryBtnType.refresh();

          if (this.refs.generalPrimaryBtnBackground)
            this.refs.generalPrimaryBtnBackground.refresh();

          if (this.refs.generalPrimaryBtnText)
            this.refs.generalPrimaryBtnText.refresh();

          if (this.refs.generalPrimaryBtnBorderRadius)
            this.refs.generalPrimaryBtnBorderRadius.refresh();

          if (this.refs.generalSecondaryBtnType)
            this.refs.generalSecondaryBtnType.refresh();

          if (this.refs.generalSecondaryBtnBackground)
            this.refs.generalSecondaryBtnBackground.refresh();

          if (this.refs.generalSecondaryBtnText)
            this.refs.generalSecondaryBtnText.refresh();

          if (this.refs.generalSecondaryBtnBorderRadiu)
            this.refs.generalSecondaryBtnBorderRadiu.refresh();

          if (this.refs.landingColorsTitle)
            this.refs.landingColorsTitle.refresh();

          if (this.refs.landingColorsText)
            this.refs.landingColorsText.refresh();

          if (this.refs.landingColorsBackground)
            this.refs.landingColorsBackground.refresh();

          if (this.refs.landingLogoType)
            this.refs.landingLogoType.refresh();

          if (this.refs.landingLogoText)
            this.refs.landingLogoText.refresh();

          if (this.refs.landingLogoURL)
            this.refs.landingLogoURL.refresh();

          if (this.refs.landingBackgroundType)
            this.refs.landingBackgroundType.refresh();

          if (this.refs.landingBackgroundURL)
            this.refs.landingBackgroundURL.refresh();

          if (this.refs.landingPrimaryBtnType)
            this.refs.landingPrimaryBtnType.refresh();

          if (this.refs.landingPrimaryBtnBackground)
            this.refs.landingPrimaryBtnBackground.refresh();

          if (this.refs.landingPrimaryBtnText)
            this.refs.landingPrimaryBtnText.refresh();

          if (this.refs.landingPrimaryBtnBorderRadius)
            this.refs.landingPrimaryBtnBorderRadius.refresh();

          if (this.refs.landingSecondaryBtnType)
            this.refs.landingSecondaryBtnType.refresh();

          if (this.refs.landingSecondaryBtnBackground)
            this.refs.landingSecondaryBtnBackground.refresh();

          if (this.refs.landingSecondaryBtnText)
            this.refs.landingSecondaryBtnText.refresh();

          if (this.refs.landingSecondaryBtnBorderRadiu)
            this.refs.landingSecondaryBtnBorderRadiu.refresh();

          this.refreshRealtimePrevieCampaign();
          this.refreshTestsData();
        }
      });

      clearTimeout(this._refreshTimeout);
      this._refreshTimeout = setTimeout(() => {
        this.setState({ loading: false });
      }, 1000);
    }
  }

  async modifyTestDataTemporal(callback) {
    const { showNotification, setTestsData, dispatch, testsData, setTemporalTestsData, temporalTestsData } = this.props;
    const {
      campaignData,
      generalColorsTitle,
      generalColorsText,
      generalColorsBackground,
      generalLogoType,
      generalLogoText,
      generalLogoURL,
      generalBackgroundType,
      generalBackgroundURL,
      generalPrimaryBtnType,
      generalPrimaryBtnBackground,
      generalPrimaryBtnText,
      generalPrimaryBtnBorderRadius,
      generalSecondaryBtnType,
      generalSecondaryBtnBackground,
      generalSecondaryBtnText,
      generalSecondaryBtnBorderRadius,
      landingColorsTitle,
      landingColorsText,
      landingColorsBackground,
      landingLogoType,
      landingLogoText,
      landingLogoURL,
      landingBackgroundType,
      landingBackgroundURL,
      landingPrimaryBtnType,
      landingPrimaryBtnBackground,
      landingPrimaryBtnText,
      landingPrimaryBtnBorderRadius,
      landingSecondaryBtnType,
      landingSecondaryBtnBackground,
      landingSecondaryBtnText,
      landingSecondaryBtnBorderRadius
    } = this.state;
    const { campaignId, data } = campaignData;

    const design = {
      ...data.design,
      generalColorsTitle,
      generalColorsText,
      generalColorsBackground,
      generalLogoType,
      generalLogoText,
      generalLogoURL,
      generalBackgroundType,
      generalBackgroundURL,
      generalPrimaryBtnType,
      generalPrimaryBtnBackground,
      generalPrimaryBtnText,
      generalPrimaryBtnBorderRadius,
      generalSecondaryBtnType,
      generalSecondaryBtnBackground,
      generalSecondaryBtnText,
      generalSecondaryBtnBorderRadius,
      landingColorsTitle,
      landingColorsText,
      landingColorsBackground,
      landingLogoType,
      landingLogoText,
      landingLogoURL,
      landingBackgroundType,
      landingBackgroundURL,
      landingPrimaryBtnType,
      landingPrimaryBtnBackground,
      landingPrimaryBtnText,
      landingPrimaryBtnBorderRadius,
      landingSecondaryBtnType,
      landingSecondaryBtnBackground,
      landingSecondaryBtnText,
      landingSecondaryBtnBorderRadius
    };

    const newData = { ...data, design, designPreview: design };

    const temporalTestsDataUpdated = temporalTestsData.map(test => {
      const testCopy = Object.assign({}, test);
      if (test.campaignId === campaignId) {
        testCopy.data = newData;
      }
      return testCopy;
    });

    setTemporalTestsData(temporalTestsDataUpdated);

    /*
    try {
      this.setState({ loading: true });

      const campaignAccess = new CampaignAccess();
      await campaignAccess.updateCampaign(campaignId, newData);

      const updatedTestsData = testsData.map(test => {
        if (test.campaignId === campaignId) {
          test.data = newData;
        }

        return test;
      });

      dispatch(setTestsData(updatedTestsData)).then(()=>{
        this.loadData();

        if (callback)
          callback();
      })
    } catch (error) {
      // console.log(error);
      this.setState({ loading: false });
      showNotification("genericError");
    }
    */
  }

  async refreshRealtimePrevieCampaign(callback) {
    const { showNotification, temporalTestsData} = this.props;
    const { campaignData } = this.state;
    const { campaignId } = campaignData;
    const temporalTest = temporalTestsData.find(test => test.campaignId === campaignId);
    const newData = { ...temporalTest.data, updatedAt: Date.now() };

    try {
      this.setState({ loading: true });

      const campaignAccess = new CampaignAccess();
      const response = await campaignAccess.updateCampaign(campaignId, newData, false);
      this.setState({ temporalUpdatedDate: response.data.updatedAt });

      this.setState({ livePreviewRunning: true }, () => {
        const iframe = document.getElementById('iframepreview');
        // iframe.src = iframe.src;
        const content = iframe.innerHTML;
        iframe.innerHTML= content;

        clearTimeout(this._refreshTimeout);
        this._refreshTimeout = setTimeout(() => {
          this.setState({ loading: false });
        }, 1000);
      });

      if (callback)
        callback();
    } catch (error) {
      this.setState({ loading: false });
      showNotification("genericError");
    }
  }

  validateData() {
    this.setState({ dataValidated: true, changesToApply: true });
  }

  onInputChange(val) {
    this.setState(val, () => {
      this.validateData();
      if (this._mount) {
        // this.refs.phonePreviewGeneral.loadData();
        // this.refs.phonePreviewLanding.loadData();

        this.modifyTestDataTemporal().then(() => this.refreshRealtimePrevieCampaign());
      }
    });
  }

  goCampaigns() {
    const { setRedirect } = this.props;
    const link = `/tests/`;
    setRedirect({ route: link });
  }

  onShowPicker() {
    if (this.refs.generalColorsTitle)
      this.refs.generalColorsTitle.hidePicker();

    if (this.refs.generalColorsText)
      this.refs.generalColorsText.hidePicker();

    if (this.refs.generalColorsBackground)
      this.refs.generalColorsBackground.hidePicker();

    if (this.refs.generalPrimaryBtnBackground)
      this.refs.generalPrimaryBtnBackground.hidePicker();

    if (this.refs.generalPrimaryBtnText)
      this.refs.generalPrimaryBtnText.hidePicker();

    if (this.refs.generalSecondaryBtnBackground)
      this.refs.generalSecondaryBtnBackground.hidePicker();

    if (this.refs.generalSecondaryBtnText)
      this.refs.generalSecondaryBtnText.hidePicker();

    if (this.refs.landingColorsTitle)
      this.refs.landingColorsTitle.hidePicker();

    if (this.refs.landingColorsText)
      this.refs.landingColorsText.hidePicker();

    if (this.refs.landingColorsBackground)
      this.refs.landingColorsBackground.hidePicker();

    if (this.refs.landingPrimaryBtnBackground)
      this.refs.landingPrimaryBtnBackground.hidePicker();

    if (this.refs.landingPrimaryBtnText)
      this.refs.landingPrimaryBtnText.hidePicker();

    if (this.refs.landingSecondaryBtnBackground)
      this.refs.landingSecondaryBtnBackground.hidePicker();

    if (this.refs.landingSecondaryBtnText)
      this.refs.landingSecondaryBtnText.hidePicker();
  }

  goModules() {
    const { setRedirect } = this.props;
    const campaignId = getURLParameter("id");
    const link = `/tests/modules?id=${campaignId}`;
    setRedirect({ route: link });
  }

  goConfiguration() {
    const { setRedirect } = this.props;
    const campaignId = getURLParameter("id");
    const link = `/tests/configuration?id=${campaignId}`;
    setRedirect({ route: link });
  }

  prevStep() {
    this.modifyTestDataTemporal(() => this.goConfiguration());
  }

  nextStep() {
    this.modifyTestDataTemporal(() => this.goModules());
  }

  getPreview() {
    const {
      campaignData,
      livePreviewRunning,
      showLandingTab
    } = this.state;
    const { campaignId, userId } = campaignData;

    const lang = campaignData.data.lang || "en";

    const jwt = require('jwt-simple');
    const payload = {
      "sub": userId,
      "name": "",
      "iat": 0,
      "iss": "",
      "aud": "",
      "exp": 0,
      "forcePreview": true,
      "campaignId": campaignId
    };
    const secret = "super-tester-secret"
    const token = jwt.encode(payload, secret);

    const link = `${URL_ENVIRONMENT}?id=${campaignId}&token=${token}&s=${showLandingTab ? "core-landing" : "core-introduction"}&l=${lang}&livepreview=${livePreviewRunning}&nc=true`;
    return link;

    // const win = window.open(link, '_blank');
    // win.focus();
  }

  changeCampaignVersionCallback = (data) => {
    const { showVersionControl } = this.props;

    showVersionControl(false);

    const {
      generalColorsTitle,
      generalColorsText,
      generalColorsBackground,
      generalLogoType,
      generalLogoText,
      generalLogoURL,
      generalBackgroundType,
      generalBackgroundURL,
      generalPrimaryBtnType,
      generalPrimaryBtnBackground,
      generalPrimaryBtnText,
      generalPrimaryBtnBorderRadius,
      generalSecondaryBtnType,
      generalSecondaryBtnBackground,
      generalSecondaryBtnText,
      generalSecondaryBtnBorderRadius,
      landingColorsTitle,
      landingColorsText,
      landingColorsBackground,
      landingLogoType,
      landingLogoText,
      landingLogoURL,
      landingBackgroundType,
      landingBackgroundURL,
      landingPrimaryBtnType,
      landingPrimaryBtnBackground,
      landingPrimaryBtnText,
      landingPrimaryBtnBorderRadius,
      landingSecondaryBtnType,
      landingSecondaryBtnBackground,
      landingSecondaryBtnText,
      landingSecondaryBtnBorderRadius
    } = data.design;

    this.setState({
      generalColorsTitle,
      generalColorsText,
      generalColorsBackground,
      generalLogoType,
      generalLogoText,
      generalLogoURL,
      generalBackgroundType,
      generalBackgroundURL,
      generalPrimaryBtnType,
      generalPrimaryBtnBackground,
      generalPrimaryBtnText,
      generalPrimaryBtnBorderRadius,
      generalSecondaryBtnType,
      generalSecondaryBtnBackground,
      generalSecondaryBtnText,
      generalSecondaryBtnBorderRadius,
      landingColorsTitle,
      landingColorsText,
      landingColorsBackground,
      landingLogoType,
      landingLogoText,
      landingLogoURL,
      landingBackgroundType,
      landingBackgroundURL,
      landingPrimaryBtnType,
      landingPrimaryBtnBackground,
      landingPrimaryBtnText,
      landingPrimaryBtnBorderRadius,
      landingSecondaryBtnType,
      landingSecondaryBtnBackground,
      landingSecondaryBtnText,
      landingSecondaryBtnBorderRadius,
      changesToApply: true,
    }, () => {
      if (this._mount) {
        if (this.refs.generalColorsTitle)
          this.refs.generalColorsTitle.refresh();

        if (this.refs.generalColorsText)
          this.refs.generalColorsText.refresh();

        if (this.refs.generalColorsBackground)
          this.refs.generalColorsBackground.refresh();

        if (this.refs.generalLogoType)
          this.refs.generalLogoType.refresh();

        if (this.refs.generalLogoText)
          this.refs.generalLogoText.refresh();

        if (this.refs.generalLogoURL)
          this.refs.generalLogoURL.refresh();

        if (this.refs.generalBackgroundType)
          this.refs.generalBackgroundType.refresh();

        if (this.refs.generalBackgroundURL)
          this.refs.generalBackgroundURL.refresh();

        if (this.refs.generalPrimaryBtnType)
          this.refs.generalPrimaryBtnType.refresh();

        if (this.refs.generalPrimaryBtnBackground)
          this.refs.generalPrimaryBtnBackground.refresh();

        if (this.refs.generalPrimaryBtnText)
          this.refs.generalPrimaryBtnText.refresh();

        if (this.refs.generalPrimaryBtnBorderRadius)
          this.refs.generalPrimaryBtnBorderRadius.refresh();

        if (this.refs.generalSecondaryBtnType)
          this.refs.generalSecondaryBtnType.refresh();

        if (this.refs.generalSecondaryBtnBackground)
          this.refs.generalSecondaryBtnBackground.refresh();

        if (this.refs.generalSecondaryBtnText)
          this.refs.generalSecondaryBtnText.refresh();

        if (this.refs.generalSecondaryBtnBorderRadiu)
          this.refs.generalSecondaryBtnBorderRadiu.refresh();

        if (this.refs.landingColorsTitle)
          this.refs.landingColorsTitle.refresh();

        if (this.refs.landingColorsText)
          this.refs.landingColorsText.refresh();

        if (this.refs.landingColorsBackground)
          this.refs.landingColorsBackground.refresh();

        if (this.refs.landingLogoType)
          this.refs.landingLogoType.refresh();

        if (this.refs.landingLogoText)
          this.refs.landingLogoText.refresh();

        if (this.refs.landingLogoURL)
          this.refs.landingLogoURL.refresh();

        if (this.refs.landingBackgroundType)
          this.refs.landingBackgroundType.refresh();

        if (this.refs.landingBackgroundURL)
          this.refs.landingBackgroundURL.refresh();

        if (this.refs.landingPrimaryBtnType)
          this.refs.landingPrimaryBtnType.refresh();

        if (this.refs.landingPrimaryBtnBackground)
          this.refs.landingPrimaryBtnBackground.refresh();

        if (this.refs.landingPrimaryBtnText)
          this.refs.landingPrimaryBtnText.refresh();

        if (this.refs.landingPrimaryBtnBorderRadius)
          this.refs.landingPrimaryBtnBorderRadius.refresh();

        if (this.refs.landingSecondaryBtnType)
          this.refs.landingSecondaryBtnType.refresh();

        if (this.refs.landingSecondaryBtnBackground)
          this.refs.landingSecondaryBtnBackground.refresh();

        if (this.refs.landingSecondaryBtnText)
          this.refs.landingSecondaryBtnText.refresh();

        if (this.refs.landingSecondaryBtnBorderRadiu)
          this.refs.landingSecondaryBtnBorderRadiu.refresh();

        this.refreshTestsData();

        this.refreshRealtimePrevieCampaign();
      }
    });
  }

  render() {
    const {
      loading,
      campaignData,
      campaignDataSaved,
      changesToApply,
      generalColorsTitle,
      generalColorsText,
      generalColorsBackground,
      generalLogoType,
      generalLogoText,
      generalLogoURL,
      generalBackgroundType,
      generalBackgroundURL,
      generalPrimaryBtnType,
      generalPrimaryBtnBackground,
      generalPrimaryBtnText,
      generalPrimaryBtnBorderRadius,
      landingColorsTitle,
      landingColorsText,
      landingColorsBackground,
      landingLogoType,
      landingLogoText,
      landingLogoURL,
      landingBackgroundType,
      landingBackgroundURL,
      landingPrimaryBtnType,
      landingPrimaryBtnBackground,
      landingPrimaryBtnText,
      landingPrimaryBtnBorderRadius,
      showUnpublishModal,
      showLandingTab
    } = this.state;
    const { location, t, openVersionControl } = this.props;
    const campaignId = getURLParameter("id");
    const isCampaignPaid = campaignData?.status === 'public' || (campaignData?.status === 'processing' && campaignData?.price !== undefined);

    let classVersionControl = "version-panel";
    if (openVersionControl)
      classVersionControl = `${classVersionControl} version-panel-open`;

    let notIncludedTooltipDesign = '';
    let notIncludedTooltipLogo = '';
    const notIncludedTooltip = {
      'data-tip': t("Design_Not_Included"),
      'data-place':"right",
      'data-background-color':"#ccc"
    };

    if (!campaignData.features.design) {
      notIncludedTooltipDesign = notIncludedTooltip;
    }

    if (!campaignData.features.logo) {
      notIncludedTooltipLogo = notIncludedTooltip;
    }


    return (
      <div className="app screen-campaigns">
        <Helmet>
          <title>{t('Design_Browser_Title')}</title>
        </Helmet>
        {/*loading && (
          <div className="loading-cover">
            <div className="cover"></div>
            <div>
              <Spinner speed={0.8} color="#ffffff" size={20} />
            </div>
          </div>
        )*/}
        <div className="main-wrapper">
          <TopBar ref="topbar" location={location} />
          {/*
          <div className={classVersionControl}>
            <VersionControl
              campaignData={campaignData}
              changeCampaignVersionCallback={this.changeCampaignVersionCallback}
            />
          </div>
          */}
          <div className="screen-content modules">
            <ContentHeader
              location={location}
              campaignData={campaignDataSaved}
              changesToApply={changesToApply}
              onClickRevert={() => this.loadData()}
              onClickApply={() => this.modifyTestDataTemporal()}
              haveDesignChanges={true}
            />
            <DesignMenu location={location} campaignData={campaignData} />
            <ReactTooltip place="right" effect="solid" backgroundColor="#f08c1e" />
            <div className="section-modules">
              <div className="section-title">
                <h2><T text="Design_Title" /></h2>
                <p><T text="Design_Subtitle" /></p>
              </div>
              <div className="content-header">
                <div className="content-header-menu">
                  <div className="content-menu">
                    <Link
                      inline
                      className={showLandingTab ? "active" : ""}
                      onClick={() => this.setState({ showLandingTab: true })}
                    >
                      <T text="Design_Tab_Cover" />
                    </Link>
                    <Link
                      inline
                      className={!showLandingTab ? "active" : ""}
                      onClick={() => this.setState({ showLandingTab: false })}
                    >
                      <T text="Design_Tab_General" />
                    </Link>
                  </div>
                </div>
              </div>
              {showLandingTab && (
                <div className="design-options sidebar-options">
                  <div>
                    <div className="field" {...notIncludedTooltipDesign}>
                      <label><T text="Design_Colors" /></label>
                      <InputColor
                        disabled={!campaignData.features.design}
                        name="landingColorsTitle"
                        label={t('Design_Colors_Title')}
                        value={landingColorsTitle}
                        onChange={val => this.onInputChange(val)}
                        onShowPicker={() => this.onShowPicker()}
                        ref="landingColorsTitle"
                        readOnly={isCampaignPaid}
                      />
                      <InputColor
                        disabled={!campaignData.features.design}
                        name="landingColorsText"
                        label={t('Design_Text')}
                        value={landingColorsText}
                        onChange={val => this.onInputChange(val)}
                        onShowPicker={() => this.onShowPicker()}
                        ref="landingColorsText"
                        readOnly={isCampaignPaid}
                      />
                    </div>
                    <div className="field" {...notIncludedTooltipLogo}>
                      <label htmlFor="landingLogoType"><T text="Design_Logo" /></label>
                      <Select
                        disabled={!campaignData.features.logo || isCampaignPaid}
                        small
                        name="landingLogoType"
                        value={landingLogoType}
                        ref="landingLogoType"
                        onChange={val => this.onInputChange(val)}
                        options={[
                          { "value": "image", "label": t('Design_Dropdown_Image') },
                          { "value": "text", "label": t('Design_Dropdown_Text') },
                          { "value": "none", "label": t('Design_Dropdown_None') },
                        ]}
                      />
                      {landingLogoType === 'image' && (
                        <InputUploadFile
                          disabled={!campaignData.features.logo || isCampaignPaid}
                          publicFile
                          placeholder="..."
                          name="landingLogoURL"
                          value={landingLogoURL}
                          ref="landingLogoURL"
                          onChange={val => this.onInputChange(val)}
                          uniqueID={campaignId}
                        />
                      )}
                      {landingLogoType === 'text' && (
                        <Input
                          disabled={!campaignData.features.logo || isCampaignPaid}
                          placeholder="..."
                          name="landingLogoText"
                          value={landingLogoText}
                          ref="landingLogoText"
                          icon="icon-language-1"
                          onChange={val => this.onInputChange(val)}
                        />
                      )}
                    </div>
                    <div className="field" {...notIncludedTooltipDesign}>
                      <label htmlFor="landingBackgroundType"><T text="Design_Background_Image" /></label>
                      <Select
                        disabled={!campaignData.features.design || isCampaignPaid}
                        small
                        name="landingBackgroundType"
                        value={landingBackgroundType}
                        ref="landingBackgroundType"
                        onChange={val => this.onInputChange(val)}
                        options={[
                          { "value": "image", "label": t('Design_Dropdown_Image') },
                          { "value": "none", "label": t('Design_Dropdown_Solid') },
                        ]}
                      />
                      {landingBackgroundType === 'image' && (
                        <InputUploadFile
                          disabled={!campaignData.features.design || isCampaignPaid}
                          publicFile
                          placeholder="..."
                          name="landingBackgroundURL"
                          value={landingBackgroundURL}
                          ref="landingBackgroundURL"
                          onChange={val => this.onInputChange(val)}
                          uniqueID={campaignId}
                        />
                      )}
                      {landingBackgroundType === 'none' && (
                        <InputColor
                          disabled={!campaignData.features.design}
                          name="landingColorsBackground"
                          label={t('Design_Background')}
                          value={landingColorsBackground}
                          onChange={val => this.onInputChange(val)}
                          onShowPicker={() => this.onShowPicker()}
                          ref="landingColorsBackground"
                          readOnly={isCampaignPaid}
                        />
                      )}
                    </div>

                    <div className="field" {...notIncludedTooltipDesign}>
                      <label><T text="Design_Primary_Button" /></label>
                      <Select
                        disabled={!campaignData.features.design || isCampaignPaid}
                        small
                        name="landingPrimaryBtnType"
                        value={landingPrimaryBtnType}
                        ref="landingPrimaryBtnType"
                        onChange={val => this.onInputChange(val)}
                        options={[
                          { "value": "solid", "label": t('Design_Dropdown_Solid') },
                          { "value": "border", "label": t('Design_Dropdown_Border') },
                          { "value": "text", "label": t('Design_Dropdown_Text') },
                        ]}
                      />
                      <InputColor
                        disabled={landingPrimaryBtnType !== 'solid' || !campaignData.features.design}
                        name="landingPrimaryBtnBackground"
                        label={t('Design_Background')}
                        value={landingPrimaryBtnBackground}
                        onChange={val => this.onInputChange(val)}
                        onShowPicker={() => this.onShowPicker()}
                        ref="landingPrimaryBtnBackground"
                        readOnly={isCampaignPaid}
                      />
                      <InputColor
                        disabled={!campaignData.features.design}
                        name="landingPrimaryBtnText"
                        label={t('Design_Text')}
                        value={landingPrimaryBtnText}
                        onChange={val => this.onInputChange(val)}
                        onShowPicker={() => this.onShowPicker()}
                        ref="landingPrimaryBtnText"
                        readOnly={isCampaignPaid}
                      />
                      <Input
                        disabled={landingPrimaryBtnType === 'text' || !campaignData.features.design || isCampaignPaid}
                        name="landingPrimaryBtnBorderRadius"
                        label={t('Design_Border_Radius')}
                        value={landingPrimaryBtnBorderRadius}
                        text="px"
                        onChange={val => this.onInputChange(val)}
                        onFocus={() => this.onShowPicker()}
                        ref="landingPrimaryBtnBorderRadius"
                      />
                    </div>

                  </div>
                </div>
              )}
              {!showLandingTab && (
                <div className="design-options sidebar-options">
                  <div>
                    <div className="field" {...notIncludedTooltipDesign}>
                      <label><T text="Design_Colors" /></label>
                      <InputColor
                        disabled={!campaignData.features.design}
                        name="generalColorsTitle"
                        label={t('Design_Colors_Title')}
                        value={generalColorsTitle}
                        onChange={val => this.onInputChange(val)}
                        onShowPicker={() => this.onShowPicker()}
                        ref="generalColorsTitle"
                        readOnly={isCampaignPaid}
                      />
                      <InputColor
                        disabled={!campaignData.features.design}
                        name="generalColorsText"
                        label={t('Design_Text')}
                        value={generalColorsText}
                        onChange={val => this.onInputChange(val)}
                        onShowPicker={() => this.onShowPicker()}
                        ref="generalColorsText"
                        readOnly={isCampaignPaid}
                      />
                    </div>
                    <div className="field" {...notIncludedTooltipLogo}>
                      <label htmlFor="generalLogoType"><T text="Design_Logo" /></label>
                      <Select
                        disabled={!campaignData.features.logo || isCampaignPaid}
                        small
                        name="generalLogoType"
                        value={generalLogoType}
                        ref="generalLogoType"
                        onChange={val => this.onInputChange(val)}
                        options={[
                          { "value": "image", "label": t('Design_Dropdown_Image') },
                          { "value": "text", "label": t('Design_Dropdown_Text') },
                          { "value": "none", "label": t('Design_Dropdown_None') },
                        ]}
                      />
                      {generalLogoType === 'image' && (
                        <InputUploadFile
                          disabled={!campaignData.features.logo || isCampaignPaid}
                          publicFile
                          placeholder="..."
                          name="generalLogoURL"
                          value={generalLogoURL}
                          ref="generalLogoURL"
                          onChange={val => this.onInputChange(val)}
                          uniqueID={campaignId}
                        />
                      )}
                      {generalLogoType === 'text' && (
                        <Input
                          disabled={!campaignData.features.logo || isCampaignPaid}
                          placeholder="..."
                          name="generalLogoText"
                          value={generalLogoText}
                          ref="generalLogoText"
                          icon="icon-language-1"
                          onChange={val => this.onInputChange(val)}
                        />
                      )}
                    </div>
                    <div className="field" {...notIncludedTooltipDesign}>
                      <label htmlFor="generalBackgroundType"><T text="Design_Background_Image" /></label>
                      <Select
                        disabled={!campaignData.features.design || isCampaignPaid}
                        small
                        name="generalBackgroundType"
                        value={generalBackgroundType}
                        ref="generalBackgroundType"
                        onChange={val => this.onInputChange(val)}
                        options={[
                          { "value": "image", "label": t('Design_Dropdown_Image') },
                          { "value": "none", "label": t('Design_Dropdown_Solid') },
                        ]}
                      />
                      {generalBackgroundType === 'image' && (
                        <InputUploadFile
                          disabled={!campaignData.features.design || isCampaignPaid}
                          publicFile
                          placeholder="..."
                          name="generalBackgroundURL"
                          value={generalBackgroundURL}
                          ref="generalBackgroundURL"
                          onChange={val => this.onInputChange(val)}
                          uniqueID={campaignId}
                        />
                      )}
                      {generalBackgroundType === 'none' && (
                        <InputColor
                          disabled={!campaignData.features.design}
                          name="generalColorsBackground"
                          label={t('Design_Background')}
                          value={generalColorsBackground}
                          onChange={val => this.onInputChange(val)}
                          onShowPicker={() => this.onShowPicker()}
                          ref="generalColorsBackground"
                          readOnly={isCampaignPaid}
                        />
                      )}
                    </div>

                    <div className="field" {...notIncludedTooltipDesign}>
                      <label><T text="Design_Primary_Button" /></label>
                      <Select
                        disabled={!campaignData.features.design || isCampaignPaid}
                        small
                        name="generalPrimaryBtnType"
                        value={generalPrimaryBtnType}
                        ref="generalPrimaryBtnType"
                        onChange={val => this.onInputChange(val)}
                        options={[
                          { "value": "solid", "label": t('Design_Dropdown_Solid') },
                          { "value": "border", "label": t('Design_Dropdown_Border') },
                          { "value": "text", "label": t('Design_Dropdown_Text') },
                        ]}
                      />
                      <InputColor
                        disabled={generalPrimaryBtnType !== 'solid' || !campaignData.features.design}
                        name="generalPrimaryBtnBackground"
                        label={t('Design_Background')}
                        value={generalPrimaryBtnBackground}
                        onChange={val => this.onInputChange(val)}
                        onShowPicker={() => this.onShowPicker()}
                        ref="generalPrimaryBtnBackground"
                        readOnly={isCampaignPaid}
                      />
                      <InputColor
                        disabled={!campaignData.features.design}
                        name="generalPrimaryBtnText"
                        label={t('Design_Text')}
                        value={generalPrimaryBtnText}
                        onChange={val => this.onInputChange(val)}
                        onShowPicker={() => this.onShowPicker()}
                        ref="generalPrimaryBtnText"
                        readOnly={isCampaignPaid}
                      />
                      <Input
                        disabled={generalPrimaryBtnType === 'text' || !campaignData.features.design || isCampaignPaid}
                        name="generalPrimaryBtnBorderRadius"
                        label={t('Design_Border_Radius')}
                        value={generalPrimaryBtnBorderRadius}
                        text="px"
                        onChange={val => this.onInputChange(val)}
                        onFocus={() => this.onShowPicker()}
                        ref="generalPrimaryBtnBorderRadius"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="screen-content-inner">
              {loading && (
                <div className="loading-cover">
                  <div className="cover design"></div>
                  <div>
                    <Spinner speed={0.8} color="#00C7E7" size={20} />
                  </div>
                </div>
              )}
              {/* <iframe id="iframepreview" title="preview" frameBorder="0" src={this.getPreview()}
                onLoad={() => setTimeout(() => this.setState({ loading: false }), 500)}
              /> */}
              <object id="iframepreview" data={this.getPreview()}>
                Your browser don't support this feature. Please use the lastest version of Google Chrome.
              </object>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(Design);
