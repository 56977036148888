// COMPONENTS
import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Dialog from 'rc-dialog';
import { Spinner } from 'react-activity';
import Link from './Link';
import T from './Translate';


// ACTIONS
import { showNotification } from '../reducers/notifications';
import { setRedirect } from '../reducers/navigation';

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setRedirect: val => dispatch(setRedirect(val)),
  showNotification: val => dispatch(showNotification(val)),
})

class ModalDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserOptions: false,
    };
  }

  render() {
    const {
      name,
      showModal,
      closeModal,
      children,
      t,
      onConfirm,
      onCancel,
      invertButtons,
      loading,
      fullCTA,
      smallSize,
      separateCloseAndCancel,
      alternativeConfirmText,
    } = this.props;

    if (!showModal)
      return false;

    let classModal = '';
    if (loading) {
      classModal = `${classModal} loading`;
    }

    if (smallSize) {
      classModal = `${classModal} small-modal`;
    }

    if (invertButtons) {
      classModal = `${classModal} invert-buttons`;
    }

    return (
      <Dialog
        title={t(`${name}_Modal_Title`)}
        closeIcon={<i className="icon icon-cancel-7"></i>}
        visible={showModal}
        closable={closeModal !== undefined}
        onClose={() => {
          if (closeModal)
            closeModal();

          if (onCancel && !separateCloseAndCancel)
            onCancel();
        }}
        className={classModal}
      >
        <p className="modal-subtitle"><T text={`${name}_Modal_Subtitle`} /></p>
        {children && (
          <div className="modal-body">
            {children}
          </div>
        )}
        <div className="dialog-buttons">
          {!invertButtons && (
            <>
              {onCancel && (
                <Link secondary disabled={loading} onClick={() => {
                  if (closeModal && !separateCloseAndCancel)
                    closeModal();

                  onCancel();
                }}>
                  <T text={`${name}_Modal_Cancel`} />
                </Link>
              )}
              <Link main disabled={loading} className={fullCTA ? "full-width" : ""} onClick={() => {
                if (onConfirm)
                  onConfirm();
              }}>
                {loading && (
                  <Spinner speed={0.8} color="#ffffff" size={16} />
                )}
                <T text={alternativeConfirmText ? `${name}_Modal_Confirm_${alternativeConfirmText}` : `${name}_Modal_Confirm`} />
              </Link>
            </>
          )}

          {invertButtons && (
            <>
              <Link secondary onClick={() => {
                if (onConfirm)
                  onConfirm();
              }}>
                {loading && (
                  <Spinner speed={0.8} color="#00C7E7" size={16} />
                )}
                <T text={alternativeConfirmText ? `${name}_Modal_Confirm_${alternativeConfirmText}` : `${name}_Modal_Confirm`} />
              </Link>
              <Link main onClick={() => {
                closeModal();

                if (onCancel && !separateCloseAndCancel)
                  onCancel();
              }}>
                <T text={`${name}_Modal_Cancel`} />
              </Link>
            </>
          )}
        </div>
      </Dialog>
    );
  }
}

export default compose(
  withTranslation('translations', { withRef: true }),
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(ModalDialog);
