import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { Spinner } from 'react-activity';
import { UserAccess, PanelAccess } from '@sounditi/ft2-api';
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next';

import { showNotification } from '../reducers/notifications';
import { setRedirect } from '../reducers/navigation';

import T from '../components/Translate';
import Link from '../components/Link';
import Input from '../components/Input';

import { URL_LOGIN } from '../config/urls';

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  showNotification: val => dispatch(showNotification(val)),
  setRedirect: val => dispatch(setRedirect(val)),
  dispatch,
})

class RememberPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataValidated: false,
      email: "",
      loading: false,
    };
  }

  componentDidMount() {
    this.sendEvent("control_enter_screen_rememberPassword", "RememberPassword")
  }

  async sendEvent(action, screen) {
    const panelAccess = new PanelAccess();

    try {
      const createdAt = new Date().getTime();
      const sessionId = localStorage.getItem('sessionID-info');
      const browser = localStorage.getItem('browser-info');
      const device = localStorage.getItem('device-info');
      // type, device, browser, screen, sessionId, createdAt
      await panelAccess.postAction(action, screen, device, browser, sessionId, createdAt);
      // console.log({ postAction: { type: action, screen, device, browser, sessionId, createdAt } });

    } catch(error) {
      // console.log(error);
    };
  }

  async onSendClick() {
    const { showNotification } = this.props;
    const { dataValidated, email } = this.state;

    const userAccess = new UserAccess();

    if (dataValidated) {
      try {
        this.setState({ loading: true });
        await userAccess.requestNewPassword(email);
        this.goLogin();
        setTimeout(() => showNotification("newPasswordSended"), 500);
      } catch (error) {
        this.setState({ loading: false });
        showNotification("genericError");
      }
    } else {
      if (!email) {
        this.refs.email.setInvalid();
      }

      showNotification("invalidData");
    }
  }

  validateData() {
    const { email } = this.state;
    const dataValidated = (email !== '');
    this.setState({ dataValidated });
  }

  onInputChange(val) {
    this.setState(val, ()=>{
      this.validateData();
    });
  }

  onCheckboxChange(val) {
    this.setState({ [val.target.name]: val.target.checked });
  }

  goLogin() {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_LOGIN });
  }

  render() {
    const { loading, email } = this.state;
    const { t } = this.props;

    if (loading){
      return (
        <div className="loading-cover">
          <div className="cover"></div>
          <div>
            <Spinner speed={0.8} color="#00C7E7" size={20} />
          </div>
        </div>
      );
    }

    return (
      <div className="app screen-login">
        <Helmet>
          <title>{t('RememberPassword_Browser_Title')}</title>
        </Helmet>
        <div className="login-box signup">
          <img className="logo" src="../assets/img/main-logo-c.png" alt="Company logo" />
          <h2><T text="RememberPassword_Title" /></h2>
          <Input
            last
            placeholder={t('Login_Email_Placeholder')}
            name="email"
            label={t('Login_Email')}
            value={email}
            ref="email"
            type="text"
            onChange={val => this.onInputChange(val)}
          />
          <Link main onClick={e => this.onSendClick(e)}><T text="RememberPassword_Send_Email_Button" /></Link>
          <Link secondary inline to="/login"><T text="RememberPassword_Back_To_Login_Button" /></Link>
        </div>
      </div>
    );
  }
}


export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(RememberPassword);
