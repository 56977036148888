export const newCampaignTemplateES = {
    audience: "100",
    date_ends: null,
    date_starts: null,
    design: {
        generalBackgroundType: "none",
        generalBackgroundURL: {},
        generalColorsBackground: "#ffffff",
        generalColorsText: "#1C1D2E",
        generalColorsTitle: "#00CDEE",
        generalLogoText: "Alyze",
        generalLogoType: "image",
        generalLogoURL: {
            "name":"templateLogo.png",
            "fileName":"templateLogo.png"
        },
        generalPrimaryBtnBackground: "#00CDEE",
        generalPrimaryBtnBorderRadius: "2",
        generalPrimaryBtnText: "#ffffff",
        generalPrimaryBtnType: "solid",
        generalSecondaryBtnBackground: "#1C1D2E",
        generalSecondaryBtnBorderRadius: "0",
        generalSecondaryBtnText: "#ffffff",
        generalSecondaryBtnType: "text",
        landingBackgroundType: "none",
        landingBackgroundURL: {},
        landingColorsBackground: "#1C1D2E",
        landingColorsText: "#ffffff",
        landingColorsTitle: "#ffffff",
        landingLogoText: "Alyze",
        landingLogoType: "image",
        landingLogoURL: {
            "name":"templateLogo.png",
            "fileName":"templateLogo.png"
        },
        landingPrimaryBtnBackground: "#ffffff",
        landingPrimaryBtnBorderRadius: "2",
        landingPrimaryBtnText: "#00CDEE",
        landingPrimaryBtnType: "solid",
        landingSecondaryBtnBackground: "#1C1D2E",
        landingSecondaryBtnBorderRadius: "0",
        landingSecondaryBtnText: "#ffffff",
        landingSecondaryBtnType: "text"
    },
    modules: {
        coreModulesPre: [
            {
                id: "core-landing",
                type: "landing",
                name: "Landing",
                img: "card-text.png",
                title: "¡Hola!",
                text: "Queremos conocer tu opinión respecto a varios contenidos de nuestra empresa",
                button: "Siguiente",
                options: true
            },
            {
                id: "core-introduction",
                type: "text",
                name: "Introduction",
                img: "card-text.png",
                title: "",
                text: "Por favor, busca un espacio tranquilo y luminoso.",
                button: "Empezar",
                options: true
            },
            {
                id: "core-login",
                type: "login",
                name: "Login",
                img: "card-login.png",
                options: false
            }
        ],
        coreModulesPost: [
            {
                id: "core-finish",
                type: "text",
                name: "Finish",
                img: "card-text.png",
                title: "¡Gracias por participar!",
                text: "Tu opinión nos ayuda a ser mejores",
                button: "Finalizar",
                options: true,
                externalLink: false,
                buttonLinkText: 'Ir a Alyze',
                buttonLink: 'https://alyze.us'
            }
        ],
        campaignModules: [],
    },
    interactions: "0",
    name: "Test",
    status: "draft",
    url: null,
    lang: "es",
    segments: []
}

export const newCampaignTemplateEN = {
    audience: "100",
    date_ends: null,
    date_starts: null,
    design: {
        generalBackgroundType: "none",
        generalBackgroundURL: {},
        generalColorsBackground: "#ffffff",
        generalColorsText: "#1C1D2E",
        generalColorsTitle: "#00CDEE",
        generalLogoText: "Alyze",
        generalLogoType: "image",
        generalLogoURL: {
            "name":"templateLogo.png",
            "fileName":"templateLogo.png"
        },
        generalPrimaryBtnBackground: "#00CDEE",
        generalPrimaryBtnBorderRadius: "2",
        generalPrimaryBtnText: "#ffffff",
        generalPrimaryBtnType: "solid",
        generalSecondaryBtnBackground: "#1C1D2E",
        generalSecondaryBtnBorderRadius: "0",
        generalSecondaryBtnText: "#ffffff",
        generalSecondaryBtnType: "text",
        landingBackgroundType: "none",
        landingBackgroundURL: {},
        landingColorsBackground: "#1C1D2E",
        landingColorsText: "#ffffff",
        landingColorsTitle: "#ffffff",
        landingLogoText: "Alyze",
        landingLogoType: "image",
        landingLogoURL: {
            "name":"templateLogo.png",
            "fileName":"templateLogo.png"
        },
        landingPrimaryBtnBackground: "#ffffff",
        landingPrimaryBtnBorderRadius: "2",
        landingPrimaryBtnText: "#00CDEE",
        landingPrimaryBtnType: "solid",
        landingSecondaryBtnBackground: "#1C1D2E",
        landingSecondaryBtnBorderRadius: "0",
        landingSecondaryBtnText: "#ffffff",
        landingSecondaryBtnType: "text"
    },
    modules: {
        coreModulesPre: [
            {
                id: "core-landing",
                type: "landing",
                name: "Landing",
                img: "card-text.png",
                title: "Hello!",
                text: "Welcome to the test.",
                button: "Next",
                options: true
            },
            {
                id: "core-introduction",
                type: "text",
                name: "Introduction",
                img: "card-text.png",
                title: "",
                text: "Please find a quiet and bright space.",
                button: "I am ready",
                options: true
            },
            {
                id: "core-login",
                type: "login",
                name: "Login",
                img: "card-login.png",
                options: false
            }
        ],
        coreModulesPost: [
            {
                id: "core-finish",
                type: "text",
                name: "Finish",
                img: "card-text.png",
                title: "Thank you for participating!",
                text: "We hope you've enjoyed the test.",
                button: "Close",
                options: true,
                externalLink: false,
                buttonLinkText: 'Go to Alyze',
                buttonLink: 'https://alyze.us'
            }
        ],
        campaignModules: [],
    },
    interactions: "0",
    name: "Test",
    status: "draft",
    url: null,
    lang: "en",
    segments: []
}

export const newCampaignTemplateExpress = {
    audience: "100",
    date_ends: null,
    date_starts: null,
    design: {
        generalBackgroundType: "none",
        generalBackgroundURL: {},
        generalColorsBackground: "#ffffff",
        generalColorsText: "#1C1D2E",
        generalColorsTitle: "#00CDEE",
        generalLogoText: "Alyze",
        generalLogoType: "image",
        generalLogoURL: {
            "name":"templateLogo.png",
            "fileName":"templateLogo.png"
        },
        generalPrimaryBtnBackground: "#00CDEE",
        generalPrimaryBtnBorderRadius: "2",
        generalPrimaryBtnText: "#ffffff",
        generalPrimaryBtnType: "solid",
        generalSecondaryBtnBackground: "#1C1D2E",
        generalSecondaryBtnBorderRadius: "0",
        generalSecondaryBtnText: "#ffffff",
        generalSecondaryBtnType: "text",
        landingBackgroundType: "none",
        landingBackgroundURL: {},
        landingColorsBackground: "#1C1D2E",
        landingColorsText: "#ffffff",
        landingColorsTitle: "#ffffff",
        landingLogoText: "Alyze",
        landingLogoType: "image",
        landingLogoURL: {
            "name":"templateLogo.png",
            "fileName":"templateLogo.png"
        },
        landingPrimaryBtnBackground: "#ffffff",
        landingPrimaryBtnBorderRadius: "2",
        landingPrimaryBtnText: "#00CDEE",
        landingPrimaryBtnType: "solid",
        landingSecondaryBtnBackground: "#1C1D2E",
        landingSecondaryBtnBorderRadius: "0",
        landingSecondaryBtnText: "#ffffff",
        landingSecondaryBtnType: "text"
    },
    modules: {
        coreModulesPre: [
            {
                id: "core-landing",
                type: "landing",
                name: "Landing",
                img: "card-text.png",
                title: "¡Hola!",
                text: "Bienvenido al test.",
                button: "Siguiente",
                options: true
            },
            {
                id: "core-introduction",
                type: "text",
                name: "Introduction",
                img: "card-text.png",
                title: "",
                text: "Es un test de última tecnología con reconocimiento emocional\n\nTe recomiendo utilizar auriculares",
                button: "Empezar",
                options: true
            },
            {
                id: "core-login",
                type: "login",
                name: "Login",
                img: "card-login.png",
                options: false
            }
        ],
        coreModulesPost: [
            {
                id: "core-finish",
                type: "text",
                name: "Finish",
                img: "card-text.png",
                title: "¡Gracias por participar!",
                text: "Esperamos que hayas disfrutado del test.",
                button: "Finalizar",
                options: true,
                externalLink: false,
                buttonLinkText: 'Ir a Alyze',
                buttonLink: 'https://alyze.us'
            }
        ],
        campaignModules: [],
    },
    interactions: "0",
    name: "Test",
    status: "draft",
    url: null,
    lang: "es",
    segments: []
}