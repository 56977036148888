import React from 'react';
import { FileAccess } from '@sounditi/ft2-api';
import { Spinner } from 'react-activity';

import T from '../components/Translate';

import { generateModuleID } from '../utils/global';

import '../styles/components/Input.scss';

class InputUploadfile extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      value: {
        name: "",
        fileName: ""
      },
      invalid: false,
      uploading: false
    }
    this.handleChange = this.handleChange.bind(this);
  }

  setInvalid() {
    this.setState({ invalid: true });
    setTimeout(() => {
      this.setState({ invalid: false });
    }, 3000)
  }

  componentWillMount() {
    const { value } = this.props;
    if (value)
      this.setState({value});
  }

  handleChange() {

    const { value } = this.state;
    const { name, onChange } = this.props;
    const returnObj = {};
    returnObj[name] = value;

    if (onChange)
      onChange(returnObj);
  }

  handleFocus() {
    const { onFocus } = this.props;

    if (onFocus)
      onFocus();
  }

  refresh() {
    const { value } = this.props;
    if (value)
      this.setState({value});
  }

  async uploadImage(event) {
    try {
      const { uniqueID, publicFile } = this.props;
      this.setState({ uploading: true });

      const rawFile = event.target.files[0];

      const fileName = rawFile.name;
      // const fileSize = rawFile.size;
      const fileType = rawFile.type; /* image/png image/jpg image/jpeg */
      const fileBlob = rawFile.slice(0, rawFile.size, rawFile.type);

      const generatedID = generateModuleID();
      const uniqueFileName = publicFile ? `ROOT-${uniqueID}-${generatedID}` : `${uniqueID}-${generatedID}`;
      const uniqueFile = new File([fileBlob], uniqueFileName, {type: fileType});

      const fileAccess = new FileAccess();
      await fileAccess.upload(uniqueFile);

      const userFiles = await fileAccess.listFiles();
      const isFileFound = userFiles.blobs.filter(blob => blob.name === uniqueFileName).length > 0;
      // console.log(isFileFound);
      if (isFileFound) {
        const value = {
          name: fileName,
          fileName: uniqueFileName
        };
        this.setState({ value }, () => this.handleChange());  
      }

      this.fileInput.value = '';
      this.setState({ uploading: false });
    } catch(error) {
      this.setState({ uploading: false });
      // showNotification("genericError");
    }
  }

  render() {
    const { placeholder, name, last, disabled, label } = this.props;
    const { value, invalid, uploading } = this.state;
    let inputClass = "field upload";

    const valueFormated = value.name ? value.name : "";

    if (invalid)
      inputClass = `${inputClass} invalid`;

    if (last)
      inputClass = `${inputClass} last`;

    if (disabled)
      inputClass = `${inputClass} disabled`;

    return (
      <div className={inputClass}>
        {label && (
          <label htmlFor={name}>{label}</label>
        )}
        <div className="input-wrapper">
          <input 
            className="hidden"
            name={`${name}File`}
            type="file"
            onChange={file => this.uploadImage(file)}
            ref={fileInput => this.fileInput = fileInput}
            accept="image/*"
          />
          <input
            readOnly
            value={valueFormated}
            onChange={this.handleChange}
            name={name}
            type="text"
            placeholder={placeholder}
          />
          {/* <div className="input-icon"><i className="icon icon-picture-4"></i></div> */}
          <span className={`button-upload-image ${uploading ? 'loading' : ''}`} onClick={() => this.fileInput.click()}>
            <Spinner speed={0.8} color="#ffffff" size={12} />
            <T text="InputUploadFile_Upload_Image" />
          </span>
        </div>
      </div>
    );
  }
}

export default InputUploadfile