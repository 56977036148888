import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { Spinner } from 'react-activity';
import { withTranslation } from 'react-i18next';
import { AnalysisAccess, CampaignAccess } from '@sounditi/ft2-api';

import { setRedirect } from '../reducers/navigation';
import { showNotification } from '../reducers/notifications';
import { setTestsData, setTemporalTestsData } from '../reducers/user';

import ModalDialog from '../components/ModalDialog';
//import TopBar from '../../components/TopBar';
//import ContentHeader from '../../components/ContentHeader';
import Link from '../components/Link';
import Input from '../components/Input';
import T from '../components/Translate';
//import MobileTopBar from '../../components/MobileTopBar';
import GlobalSummary from '../components/GlobalSummary';
import Select from '../components/Select';
import PublishTest from '../components/recopilator/PublishTest';

import { URL_CAMPAIGNS } from '../config/urls';
import { getURLParameter } from '../utils/global';

import '../styles/components/InviteAudience.scss';

const mapStateToProps = (state, ownProps) => ({
  userData: state.user.userData,
  testsData: state.user.testsData,
  loadingAutoSave: state.navigation.loadingAutoSave,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setRedirect: val => dispatch(setRedirect(val)),
  setTestsData: val => dispatch(setTestsData(val)),
  showNotification: val => dispatch(showNotification(val)),
  setTemporalTestsData: val => dispatch(setTemporalTestsData(val)),
  dispatch
})

class InviteAudience extends React.Component {
  _mount = true;

  constructor(props) {
    super(props);
    this.state = {
      campaignData: [],
      testers: [],
      loadingTesters: false,
      loadingTable: false,
      loading: true,
      showInviteModal: false,
      showSettingsModal: false,
      showImportCsvModal: false,
      showEmptyTestError: false,
      showNoFilmsError: false,
      showFirstInvitationNotice: false,
      emailsToSendOnConfirm: {},
      showDeleteTestersModal: false,
      loadingModal: false,
      loadingToggle: false,
      loadingStatus: false,
      selectedTester: '',
      deleteTester: '',
      emailsToAdd: [''],
      emailsToAddCsv: [],
      disableActionButton: true,
      disableActionEnable: true,
      disableActionDisable: true,
      maxTesters: 100,
      showActionOptions: false,
      initialEventsSended: false,
      globalSummary: {},
      emailConfigurationLoading: false,
      emailTestTitle: '',
      emailSender: '',
      emailTestTitleDefault: '',
      emailSenderDefault: '',
      disableApplyButton: true,
      columnHeadersOptionsCsv: []
    };
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.checkAll = this.checkAll.bind(this);
  }

  componentWillMount() {
    this.loadData();
  }

  componentDidMount() {
    window.addEventListener('click', (e) => {
      let closeActions = true;

      Array.from(document.getElementsByClassName('btn-open-actions')).forEach(element => {
        if (element.contains(e.target)){
          closeActions = false;
        }
      });

      if (this._mount && closeActions)
        this.setState({ showActionOptions: false });
    });

  }

  componentDidUpdate() {
    const { initialEventsSended } = this.state;

    if (this.props.topbar && !initialEventsSended) {
      this.props.topbar.sendEvent("control_enter_screen_recopilator_inviteAudienceEmail", "InviteAudience");
      
      if (this._mount)
        this.setState({ initialEventsSended: true });
    }
  }

  componentWillUnmount() {
    this._mount = false;
  }

  async loadData(audienceTypeUpdated) {
    if (this._mount) {
      const { testsData, userData } = this.props;

      const campaignId = getURLParameter("id");
      const campaignData = audienceTypeUpdated ? audienceTypeUpdated : testsData.filter(test => test.campaignId === campaignId)[0];

      if (!campaignId || !campaignData)
        this.goCampaigns();

      if (campaignData) {
        // this.setState({
        //   campaignData,
        //   loading: false,
        // });

        const emailSenderDefault = userData?.data?.username ? userData?.data?.username : userData?.email;
        const emailTestTitleDefault = campaignData?.data?.name;

        const emailTestTitle = campaignData?.data?.emailTestTitle || emailTestTitleDefault;
        const emailSender = campaignData?.data?.emailSender || emailSenderDefault;

        if (this._mount)
          this.setState({ campaignData, emailTestTitle, emailSender, emailTestTitleDefault, emailSenderDefault });

        if (campaignData?.chosenAudienceType && campaignData?.features?.publicAudienceStatistics) {
          const globalSummary = campaignData.globalSummary;

          if (this._mount)
            this.setState({ globalSummary });
        }

        if (campaignData.features.numberOfResponses) {
          if (this._mount)
            this.setState({ maxTesters: campaignData.features.numberOfResponses });
        }

        if (campaignData.testers.length > 0) {
          if (this._mount)
            this.setState({ loadingTesters: true, loading: false });
        } else {
          if (this._mount)
            this.setState({ loading: false });
        }

        this.refreshTestsData();
      }
    }
  }

  async refreshTestsData(callback = () => {}) {
    const { setTestsData, dispatch, setTemporalTestsData, loadingAutoSave } = this.props;

    try {
      this.setState({ loadingTable: true });
      const campaignAccess = new CampaignAccess();
      const refreshedTestsData = await campaignAccess.getCampaigns();

      if (!loadingAutoSave) {
        setTemporalTestsData(refreshedTestsData);
      }
      dispatch(setTestsData(refreshedTestsData));

      const campaignId = getURLParameter("id");
      const campaignData = refreshedTestsData.filter(test => test.campaignId === campaignId)[0];

      this.getTesters(campaignData);

      if (this._mount)(
        this.setState({ campaignData, loadingTable: false }, () => callback())
      );
    } catch (error) { console.log(error) }
  }

  async getTesters(campaignData) {
    const testers = campaignData?.testers || [];

    if (testers.length > 0) {
      try {
        const campaignAccess = new CampaignAccess();
        const links = await campaignAccess.getLinks(campaignData.campaignId, false);

        testers.map(tester => {
          links.filter(link => {
            if (link.email === tester.email) {
              return tester.isActiveLink = link.active;
            }
          });
        });
      } catch (error) { }

      if (this._mount)
        this.setState({ loadingTesters: false, loading: false });
    }

    if (this._mount)
      this.setState({ testers });
  }

  goCampaigns() {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_CAMPAIGNS });
  }

  removeItem(key) {
    let newList = this.state.emailsToAdd;
    newList.splice(key, 1);

    if (this._mount)
      this.setState({emailsToAdd: newList}, () => {
        newList.map((val,key) => {
          if (this._mount && this.refs[`email${key}`] && this.refs[`email${key}`].refresh) {
            this.refs[`email${key}`].refresh();
          }
        });
      });
  }

  handleChange(val, key) {
    const newEmail = this.state.emailsToAdd;

    newEmail[key] = val[Object.keys(val)].trim();
    if (this._mount) {
      this.setState({ emailsToAdd: newEmail });
    }
  }

  checkMailRegex(mail) {
    const r = /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    return r.test(mail.toLowerCase());
  }

  validateEmailConfiguration(emailTestTitle, emailSender) {
    const { showNotification } = this.props;
    let isValid = true;

    if (emailTestTitle.trim() === '' && this.refs && this.refs.emailTestTitle) {
      this.refs.emailTestTitle.setInvalid();
      isValid = false;
    }
    if (emailSender.trim() === '' && this.refs && this.refs.emailSender) {
      this.refs.emailSender.setInvalid();
      isValid = false;
    }

    !isValid && showNotification('invalidData');
    return isValid;
  }

  validateEmails() {
    const { emailsToAdd } = this.state;
    let invalidEmails = [];
    emailsToAdd.map((email, key) => {
      if (email !== '' && this.checkMailRegex(email) === false) {
        invalidEmails.push('email'+key);
      }
    });

    return invalidEmails;
  }

  addInputEmail() {
    if (this._mount)
      this.setState({
        emailsToAdd: [...this.state.emailsToAdd, '']
      })
  }

  async addEmails(csv = false) {
    const { showNotification } = this.props;
    const { campaignData, emailsToAdd, testers, maxTesters } = this.state;
    let emailsAdd = [];

    if (csv) {
      emailsAdd = this.selectedEmails(true);
      emailsAdd = emailsAdd.filter(email => email.trim() !== '' && this.checkMailRegex(email) !== false);
    } else {
      emailsAdd = emailsToAdd.filter(email => email.trim() !== '');
      let emptyEmails = []
       emailsToAdd.map((email, key) =>  {
        if (email.trim() === '') {
          emptyEmails.push('email'+key)
        }
      });

      if (emptyEmails.length === emailsToAdd.length) {
        emptyEmails.map( inputName => {
          if (this.refs && this.refs[inputName]) {
            this.refs[inputName].setInvalid();
          }
        });
        showNotification("inviteAudienceAddEmailsEmpty");
        return;
      }

      const invalidEmails = this.validateEmails();
      if (invalidEmails.length > 0) {
        invalidEmails.map( inputName => {
          if (this.refs && this.refs[inputName]) {
            this.refs[inputName].setInvalid();
          }
        });
        showNotification("inviteAudienceAddEmailsInvalid");
        return;
      }
    }

    if (emailsAdd.length > 0) {
      if (((emailsAdd.length + testers.length) <= maxTesters) || maxTesters === -1) {
        console.log("A")
        if (this._mount)
          this.setState({ loadingModal: true });

        try {
          const campaignAccess = new CampaignAccess();
          await campaignAccess.addTestersToCampaign(emailsAdd, campaignData.campaignId);
          await this.refreshTestsData(() => {
            // setTimeout(() => {
              if (this._mount)
                this.setState({ 
                  loadingModal: false, 
                  showInviteModal: false, 
                  showImportCsvModal: false, 
                  emailsToAdd: [''], 
                  emailsToAddCsv: [], 
                  columnHeadersOptionsCsv: [],
                  loadingTesters: true
                });
            // }, 200)
          });
          console.log("B")

          showNotification("inviteAudienceAddEmailsSuccesfully");
          this.setState({ loadingTesters: false });

          if (this.props.topbar)
            this.props.topbar.sendEvent("control_add_audience", "InviteAudience", { campaignId: campaignData.campaignId })
        } catch (error) {
          showNotification("genericError");
          //this.setState({ loadingTesters: false });
        }
      } else {
        showNotification("inviteAudienceAddEmailsMaxTestersReached");
      }
    } else {
      showNotification("inviteAudienceAddEmailsEmpty");
    }
  }

  async sendInvites(emails = [], selectedTester = false) {
    const { showNotification, userData } = this.props;
    const { campaignData, emailsToSendOnConfirm, testers } = this.state;

    const testersSended = testers.filter(tester => tester.status !== "added");

    // if (testersSended.length <= 0 && Object.keys(emailsToSendOnConfirm).length <= 0) {
    //   if (this._mount)
    //     this.setState({ showFirstInvitationNotice: true, emailsToSendOnConfirm: { emails, selectedTester } });

    //   return false;
    // }

    if (emails.length > 0) {
      // console.log(emails);
      if (selectedTester) {
        if (this._mount)
          this.setState({ loadingStatus: true, selectedTester: selectedTester });
      } else {
        if (this._mount)
          this.setState({ loadingTable: true });
      }

      this.uncheckAll();

      try {
        const campaignAccess = new CampaignAccess();
        await campaignAccess.inviteTestersToCampaign(emails, campaignData.campaignId);
        await this.refreshTestsData();

        if (campaignData?.data?.modules?.campaignModules.find(module => module.type === 'film')) {
          showNotification("inviteAudienceSendEmailsFilmSuccesfully");
        } else {
          showNotification("inviteAudienceSendEmailsSuccesfully");
        }

        if (this.props.topbar)
          this.props.topbar.sendEvent("control_send_invitations", "InviteAudience", { campaignId: campaignData.campaignId })
      } catch (error) {
        if (error.message === 'Number of films limit reached') {
          showNotification("inviteAudienceSendEmailsMaxFilmTestReached");
        } else {
          showNotification("genericError");
        }
      }

      if (selectedTester) {
        if (this._mount)
          this.setState({ loadingStatus: false });
      } else {
        if (this._mount)
          this.setState({ loadingTable: false });
      }
    } else {
      showNotification("inviteAudienceAddEmailsEmpty");
    }
  }

  async toggleLink(tester) {
    const { showNotification } = this.props;
    const { campaignData } = this.state;
    if (this._mount)
      this.setState({selectedTester: tester.email});

    try {
      if (this._mount)
        this.setState({ loadingToggle: true });

      this.uncheckAll();

      const campaignAccess = new CampaignAccess();
      const links = await campaignAccess.getLinks(campaignData.campaignId);

      let link = links.find(link => tester.email === link.email);
      tester.isActiveLink = !link.active;
      await campaignAccess.enableDisableTesters([tester.email], campaignData.campaignId, !link.active);
      await this.refreshTestsData();

      if (this._mount)
        this.setState({ loadingToggle: false });
    } catch (error) {
      showNotification("genericError");
    }
  }

  async enableDisableTesters(enabled) {
    const { showNotification } = this.props;
    const { campaignData } = this.state;

    if (this.selectedEmails().length > 0) {
      try {
        if (this._mount)
          this.setState({loadingTable: true});

        const campaignAccess = new CampaignAccess();
        await campaignAccess.enableDisableTesters(this.selectedEmails(), campaignData.campaignId, enabled);
        await this.refreshTestsData();

        if (this._mount)
          this.setState({loadingTable: false});
      } catch (error) {
        showNotification("genericError");
      }
    } else {
      showNotification("inviteAudienceAddEmailsEmpty");
    }
  }

  async resendLink(emails = [], selectedTester = false) {
    const { showNotification } = this.props;
    const { campaignData } = this.state;

    if (selectedTester) {
      this.uncheckAll();
      if (this._mount)
        this.setState({
          selectedTester: selectedTester,
          loadingStatus: true
        });
    } else {
      if (this._mount)
        this.setState({ loadingTable: true });
    }

    try {
      const campaignAccess = new CampaignAccess();
      await campaignAccess.resendTestersInvitation(emails, campaignData.campaignId);
      await this.refreshTestsData();

      if (campaignData?.data?.modules?.campaignModules.find(module => module.type === 'film')) {
        showNotification("inviteAudienceSendEmailsFilmSuccesfully");
      } else {
        showNotification("inviteAudienceSendEmailsSuccesfully");
      }
    } catch (error) {
      showNotification("genericError");
    }

    if (selectedTester) {
      if (this._mount)
        this.setState({ loadingStatus: false });
    } else {
      if (this._mount)
        this.setState({ loadingTable: false });
    }
  }

  async deleteTesters(emails = []) {
    const { showNotification } = this.props;
    const { campaignData } = this.state;

    if (this._mount)
      this.setState({ loadingModal: true });

    try {
      const campaignAccess = new CampaignAccess();
      await campaignAccess.deleteTesters(emails, campaignData.campaignId);
      await this.refreshTestsData();
      showNotification("inviteAudienceDeleteTestersSuccesfully");
    } catch (error) {
      showNotification("genericError");
    }

    if (this._mount)
      this.setState({ loadingModal: false, showDeleteTestersModal: false });
  }

  toggleActionOptions() {
    const { showActionOptions } = this.state;
    
    if (this._mount)
      this.setState({ showActionOptions: !showActionOptions });
  }

  actionsChange(action) {
    const { testers } = this.state;

    if (this._mount)
      this.setState({ disableActionButton: true, disableActionEnable: true, disableActionDisable: true });

    switch (action) {
      case 'send':
        let sendEmail = [];
        let resendEmail = [];
        const testersSelected = testers.filter(tester => this.selectedEmails().includes(tester.email));
        testersSelected.map(tester => {
          if (tester.status === "added") {
            sendEmail.push(tester.email);
          } else {
            resendEmail.push(tester.email);
          }
        });

        sendEmail.length > 0 && this.sendInvites(sendEmail);
        resendEmail.length > 0 && this.resendLink(resendEmail);
      break;
      case 'enable':
        this.enableDisableTesters(true);
      break;
      case 'disable':
        this.enableDisableTesters(false);
      break;
      case 'delete':
        this.setState({ showDeleteTestersModal: true});
      break;
      case 'export':
        this.exportTestersCsv();
      break;
      default:
        break;
    }

    this.toggleActionOptions();

    if (action !== 'delete' && action !== 'send') {
      this.uncheckAll();
    }
  }

  selectedEmails(modal = false) {
    const all = modal ? document.querySelectorAll('.emailCheckModal') : document.querySelectorAll('.emailCheck');
    const selectedEmails = [];

    for (let i = 0; i < all.length; i++) {
      if (all[i].checked === true) {
        selectedEmails.push(all[i].value);
      }
    }
    
    return selectedEmails;
  }

  checkTestersSelected() {
    const testersSelected = this.selectedEmails();
    if (testersSelected.length > 0) {
      if (this._mount)
        this.setState({ disableActionButton: false });
    } else {
      if (this._mount)
        this.setState({ disableActionButton: true });
    }
    this.checkEnableDisable();
  }

  checkEnableDisable() {
    const { testers } = this.state;
    const testersSelected = testers.filter(tester => this.selectedEmails().includes(tester.email));

    const testersEnabled = testersSelected.find(tester => tester.isActiveLink === true);
    const testersDisabled = testersSelected.find(tester => tester.isActiveLink === false);

    if (testersEnabled && testersDisabled === undefined) {
      if (this._mount)
        this.setState({disableActionEnable: true, disableActionDisable: false});
    } else if (testersEnabled === undefined && testersDisabled) {
      if (this._mount)
        this.setState({disableActionEnable: false, disableActionDisable: true});
    } else if (testersEnabled === undefined && testersDisabled === undefined) {
      if (this._mount)
        this.setState({disableActionEnable: true, disableActionDisable: true});
    }  else {
      if (this._mount)
        this.setState({disableActionEnable: false, disableActionDisable: false});
    }
  }

  onCheckboxChange(e) {
    const allId = e.target.className === 'emailCheck' ? 'selectAll' : 'selectAllModal';
    const selectAll = document.querySelector(`#${allId}`);
    if (e.target.checked === false) {
      selectAll.checked = false;
    }
    this.checkTestersSelected();
  }

  checkAll(e) {
    const emailClass = e.target.id === 'selectAll' ? 'emailCheck' : 'emailCheckModal';
    const all = document.querySelectorAll(`.${emailClass}`);
    for (let i = 0; i < all.length; i++) {
      all[i].checked = e.target.checked;
    }
    this.checkTestersSelected();
  }

  uncheckAll() {
    if (document.querySelector('#selectAll')) {
      let selectAll = document.querySelector('#selectAll');
      selectAll.checked = false;

      let all = document.querySelectorAll('.emailCheck');
      for (let i = 0; i < all.length; i++) {
        all[i].checked = false;
      }

      if (this._mount)
        this.setState({ disableActionButton: true, disableActionEnable: true, disableActionDisable: true });
    }
  }

  importTestersCsvValidation(selectedColumn=false) {
    const csvFile = document.getElementById("csvFile").files[0];
    if (!csvFile) return;
    const reader = new FileReader();
    const _this = this;

    reader.onload = function (e) {
      const text = e.target.result;
      const addImportedEmails = text.split(/\n|\r\n/);
      const importedEmails = addImportedEmails
        .filter(email => email.trim() !== '')
        .map(email => email.trim().toLowerCase() );
      //console.log('onload',importedEmails);
      const columnHeadersCsv = importedEmails[0].split(',');
      if (columnHeadersCsv?.length > 1 && isNaN(parseInt(selectedColumn))) {
        const columnHeaders = columnHeadersCsv.map((column,i) => { return {value: i, label: column} })
        _this.setState({columnHeadersOptionsCsv: columnHeaders});
        _this.importTestersCsvValidation(0);
      } else {
        if (!isNaN(parseInt(selectedColumn))) {
          const importColumn = importedEmails
            .map(email => {
              const column = email.split(',');
              return column[parseInt(selectedColumn)]
            })
            .filter(email => email);
          _this.importTestersCsv(importColumn);
        } else {
          _this.importTestersCsv(importedEmails);
        }
      }
    };

    reader.readAsText(csvFile);
  }

  importTestersCsv(importedEmails) {
    if (this._mount)
      this.setState({emailsToAddCsv: importedEmails});

    if (document.querySelector('#selectAllModal')) {
      const all = document.querySelectorAll('.emailCheckModal');
      for (let i = 0; i < all.length; i++) {
        all[i].checked = true;
      }
      document.querySelector('#selectAllModal').checked = true;
    }
  }

  exportTestersCsv() {
    const { campaignData } = this.state;
    const selectedEmailsJoin = this.selectedEmails().join('\n');

    const fileName = `${campaignData.data?.name}-Alyze_testers.csv`;
    let csvContent = "data:text/csv;charset=utf-8," + selectedEmailsJoin;
    const encodedUri = encodeURI(csvContent);

    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  }

  getFormatDate(inputDate) {
    const date = new Date(inputDate);

    const year = date.getFullYear();
    let month = date.toLocaleString('default', { month: 'short' });
    let day = date.getDate();
    day = (day < 10 ? "0" : "") + day;

    return `${month} ${day}, ${year}`;
  }

  onInputChange(val) {
    const { disableApplyButton, campaignData, emailTestTitle, emailSender, emailTestTitleDefault, emailSenderDefault } = this.state;
    const emailTestTitleSaved = campaignData?.data?.emailTestTitle;
    const emailSenderSaved = campaignData?.data?.emailSender;
    const emailTestTitleNew = val?.emailTestTitle;
    const emailSenderNew = val?.emailSender;

    if (disableApplyButton) {
      if (this._mount)
        this.setState({ disableApplyButton: false });
    } else {
      if (((emailTestTitleSaved && (emailTestTitleSaved === emailTestTitleNew || (!emailTestTitleNew && emailTestTitleSaved === emailTestTitle)))
          && (emailSenderSaved && (emailSenderSaved === emailSenderNew || (!emailSenderNew && emailSenderSaved === emailSender))) )
        || ((!emailTestTitleSaved && (emailTestTitleDefault === emailTestTitleNew || (!emailTestTitleNew && emailTestTitleDefault === emailTestTitle)))
          && (!emailSenderSaved && (emailSenderDefault === emailSenderNew || (!emailSenderNew &&emailSenderDefault === emailSender))))
      ) {
        if (this._mount)
          this.setState({ disableApplyButton: true });
      }
    }

    if (this._mount)
      this.setState(val);
  }

  async saveEmailConfiguration() {
    const { showNotification } = this.props;
    const { campaignData, emailTestTitle, emailSender } = this.state;
    const { campaignId, data } = campaignData;
    const dataValidated = this.validateEmailConfiguration(emailTestTitle, emailSender);

    if (dataValidated) {
      if (this._mount)
        this.setState({ emailConfigurationLoading: true });

      try {
        const newData = {
          ...data,
          emailTestTitle,
          emailSender
        };

        const campaignAccess = new CampaignAccess();
        await campaignAccess.updateCampaign(campaignId, newData);
        await this.refreshTestsData();
      } catch(error) {
        showNotification("genericError");
      }

      if (this._mount)
        this.setState({ emailConfigurationLoading: false, showSettingsModal: false }, () => {
          showNotification("changesApplied");
        });
    }
  }

  sendAll() {
    const { testers } = this.state;

    const testersdToSend = testers.map(tester => {
      if (tester.status === "added")
        return tester.email;
    });

    testersdToSend.length > 0 && this.sendInvites(testersdToSend);
  }


  getSelectedEmailsEnabled() {
    const { testers } = this.state;
    const selectedEmailsEnabled = this.selectedEmails().filter(email => {
      const actualTester = testers.filter(tester => tester.email === email)[0];

      if (actualTester?.isActiveLink === true)
        return email;
    });

    return selectedEmailsEnabled;
  }

  getSelectedEmailsDisabled() {
    const { testers } = this.state;
    const selectedEmailsDisabled = this.selectedEmails().filter(email => {
      const actualTester = testers.filter(tester => tester.email === email)[0];

      if (actualTester?.isActiveLink === false)
        return email;
    });

    return selectedEmailsDisabled;
  }

  getSelectedEmailsNotSend() {
    const { testers } = this.state;
    const filteredEmailsNotSend = this.selectedEmails().filter(email => {
      const actualTester = testers.filter(tester => tester.email === email)[0];

      if (actualTester?.status === "added")
        return email;
    });

    return filteredEmailsNotSend;
  }

  getAllEmailsNotSend() {
    const { testers } = this.state;
    const allEmailsNotSend = testers.filter(tester => {
      if (tester?.status === "added")
        return tester.email;
    });

    return allEmailsNotSend;
  }

  render() {
    const {
      campaignData,
      testers,
      loading,
      loadingTesters,
      loadingTable,
      showInviteModal,
      showImportCsvModal,
      showEmptyTestError,
      showNoFilmsError,
      showFirstInvitationNotice,
      emailsToSendOnConfirm,
      showDeleteTestersModal,
      showSettingsModal,
      loadingModal,
      loadingToggle,
      loadingStatus,
      selectedTester,
      deleteTester,
      emailsToAdd,
      emailsToAddCsv,
      disableActionButton,
      disableActionEnable,
      disableActionDisable,
      showActionOptions,
      globalSummary,
      emailConfigurationLoading,
      emailTestTitle,
      emailSender,
      emailTestTitleDefault,
      emailSenderDefault,
      disableApplyButton,
      columnHeadersOptionsCsv,
    } = this.state;
    const { t, onShowAdquisitionMethod } = this.props;

    const selectedEmails = this.selectedEmails();

    const campaignName = campaignData.data ? campaignData.data.name : "";
    const chosenAudienceType = campaignData?.chosenAudienceType;
    const haveInvitationToSend = testers.filter(tester => tester.status === "added").length > 0;

    let disableButtonsTable = '';
    if (loadingToggle || loadingStatus) {
      disableButtonsTable = 'disabled';
    }

    const printTestersRows = testers.map( tester => {
      const { campaignData } = this.state;
      const { email, createdAt, data } = tester;
      let { isActiveLink } = tester; 
      const session = data.sessions[data.sessions.length -1];
      const formattedDate = this.getFormatDate(createdAt);

      // console.log(data.sessions)

      let status = 'Sent';
      let statusT = t('InviteAudience_Table_Status_Sent');
      let statusTnoTracking = t('InviteAudience_Table_Status_NoTracking');
      let disableButtonResend = false;
      let disableToggleLink = false;
      let tagClass = "secondary";

      if (['draft', 'ready', 'processing'].includes(campaignData.status) || tester.status === "added" || campaignData.published === false)
        isActiveLink = false;

      if (tester.status === "discarted" || tester.status === "discarded") {
        status = 'Discarded';
        statusT = t('InviteAudience_Table_Status_Discarded');
        tagClass = "error";
      } else if (tester.status === "waiting" && campaignData?.features?.testWatermark === false) {
        status = 'Processing';
        statusT = t('InviteAudience_Table_Status_Processing');
        tagClass = "featured";
      } else if (tester.status === "waiting" || tester.status === "watermarking") {
        status = 'Watermarking';
        statusT = t('InviteAudience_Table_Status_Watermarking');
        tagClass = "featured";
      } else if (tester.status === "added") {
        status = 'Added';
        statusT = t('InviteAudience_Table_Status_Added');
        tagClass = "";
      } else if (tester.status === "left") {
        status = 'Left';
        statusT = t('InviteAudience_Table_Status_Left');
        tagClass = "error";
      } else if (session?.testStarted && session.testStarted === true) {
        if (session.testFinished === true) {
          status = 'Finished';
          statusT = t('InviteAudience_Table_Status_Finished');
          disableButtonResend = true;
          disableToggleLink = true;
          tagClass = "good";
        } else {
          status = 'Started';
          statusT = t('InviteAudience_Table_Status_Started');
        }
      }

      let toggleClass = `button-toggle`;
      if (isActiveLink) {
        toggleClass = `${toggleClass} active`;
      } else {
        disableButtonResend = true;
      }

      if (disableToggleLink) {
        toggleClass = `${toggleClass} disabled`;
      }

      let loadingRow = '';
      let loadingText = '';

      if ((loadingToggle || loadingStatus) && (selectedTester === email)) {
        loadingRow = 'loading-row';
        disableButtonResend = true;

        if (loadingStatus) {
          loadingText = <T text="InviteAudience_Table_Loading_Sending" />
        }

        if (loadingToggle) {
          if (isActiveLink) {
            loadingText = <T text="InviteAudience_Table_Loading_Disabling" />
          } else {
            loadingText = <T text="InviteAudience_Table_Loading_Enabling" />
          }
        }
      }

      return (
        <div key={email} className={`invite-audience-table-row ${loadingRow}`}>
          {(loadingStatus || loadingToggle) && (selectedTester === email) && (
            <div className="loading-cover loading-column">
              <div className="loading-column-cover">
                {loadingText}<Spinner speed={0.8} color="#ffffff" size={15} />
              </div>
            </div>
          )}
          <div className="invite-audience-table-cell check">
            <label className="container">
              <input
                value={email}
                type="checkbox"
                className="emailCheck"
                onChange={this.onCheckboxChange}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div className="invite-audience-table-cell email">
            <p>{email}</p>
            <p className="date"><T text="InviteAudience_Table_Date" var1={formattedDate} /></p>
          </div>
          {campaignData.features.emailTracking
            ? <div className={status === 'Finished' ? 'invite-audience-table-cell finished' : 'invite-audience-table-cell'}>
                <div className={`tag ${tagClass}`}>{statusT}</div>
              </div>
            : <div className="invite-audience-table-cell noTracking">
                <div>{statusTnoTracking}</div>
              </div>
          }
          <div className="invite-audience-table-cell toggle-link">
          <span className="only-mobile"><T text="InviteAudience_Table_Column3" /></span>
            {status !== 'Added' && (
            <div className="btn-icon btn-toggle-media-preview">
              <div className={toggleClass} onClick={ () => this.toggleLink(tester) }>
                <div className="handle"></div>
              </div>
            </div>
            )}
          </div>
          <div className="invite-audience-table-cell">
            {status === 'Added'
              ? 
                <Link disabled={campaignData.published !== true} main onClick={() => this.sendInvites([email], email)}>
                  <T text="InviteAudience_Button_Invite" />
                </Link>
              : 
                <Link secondary disabled={disableButtonResend} onClick={() => this.resendLink([email], email)}>
                  <T text="InviteAudience_Button_Reactivate" />
                </Link>
            }

            {/* {status !== 'Added' && (
              <Link secondary disabled={disableButtonResend} onClick={() => this.resendLink([email], email)}>
                <i className="icon icon-cw-1"></i>
                <T text="InviteAudience_Button_Reactivate" />
              </Link>
            )} */}
            <Link secondary className="delete" onClick={() => this.setState({showDeleteTestersModal: true, deleteTester: email})}>
              <T text="InviteAudience_Button_Delete" />
            </Link>
          </div>
          {/* <div className="invite-audience-table-cell">
            {status === 'Added'
              ? 
                <Link disabled={campaignData.status === "draft"} secondary onClick={() => this.sendInvites([email], email)}>
                  <T text="InviteAudience_Button_Invite" />
                </Link>
              : 
                <Link secondary disabled={disableButtonResend} onClick={() => this.resendLink([email], email)}>
                  <T text="InviteAudience_Button_Reactivate" />
                </Link>
            }
            <Link secondary className="delete" onClick={() => this.setState({showDeleteTestersModal: true, deleteTester: email})}>
              <T text="InviteAudience_Button_Delete" />
            </Link>
          </div> */}
        </div>
      );
    });

    return (
      <>
        <ModalDialog
          name="Settings"
          showModal={showSettingsModal}
          loading={emailConfigurationLoading}
          onCancel={() => { this.setState({ showSettingsModal: false }) }}
          closeModal={() => { this.setState({ showSettingsModal: false }) }}
          onConfirm={() => { this.saveEmailConfiguration() }}
        >
          <Input
            placeholder=""
            name="emailTestTitle"
            label={t('InviteAudience_Email_Configuration_Input_Test_Title')}
            value={emailTestTitle}
            ref="emailTestTitle"
            onChange={val => this.onInputChange(val)}
            readOnly={emailConfigurationLoading}
          />
        
          <Input
            placeholder=""
            name="emailSender"
            label={t('InviteAudience_Email_Configuration_Input_Sender')}
            value={emailSender}
            ref="emailSender"
            onChange={val => this.onInputChange(val)}
            readOnly={emailConfigurationLoading}
          />
        </ModalDialog>
        <ModalDialog
          name="InviteAudience"
          showModal={showInviteModal}
          loading={loadingModal}
          closeModal={() => { this.setState({ showInviteModal: false, emailsToAdd: [''] }) }}
          onConfirm={() => { this.addEmails() }}
        >
          {emailsToAdd.map( (email, key) => {
            return (
              <div className="input-email" key={key}>
                <Input
                  placeholder={`user${key+1}@example.com`}
                  name={`email_${key}`}
                  ref={`email${key}`}
                  label={key === 0 ? t('InviteAudience_Modal_Input_Label_Email') : ''}
                  value={this.state.emailsToAdd[key]}
                  onChange={val => this.handleChange(val, key)}
                />
                {emailsToAdd.length > 1 && (
                  <div className="button-close" onClick={() => this.removeItem(key)}>
                    <i className="icon icon-cancel-1"></i>
                  </div>
                )}
              </div>
            );
          })}
          <div className="add-new-tester" onClick={() => this.addInputEmail()}>
            <i className="icon icon-plus-1"></i>
            <div className="add-new-tester-label">
              <T text="InviteAudience_Modal_Button_Add_Email" />
            </div>
          </div>
        </ModalDialog>
        <ModalDialog
          name="InviteAudience_ImportCsv"
          showModal={showImportCsvModal}
          loading={loadingModal}
          closeModal={e => { this.setState({ showImportCsvModal: false, emailsToAddCsv: [], columnHeadersOptionsCsv: [] }) }}
          onConfirm={e => { this.addEmails(true) }}
        >
          <p style={{marginTop: 0}}>
            <T
              text="InviteAudience_ImportCsv_Modal_Csv_Example"
              var1={encodeURI('data:text/csv;charset=utf-8,user1@example.com\nuser2@example.com\nuser3@example.com\n')}
              var2='sample-Alyze_testers.csv'
            />
          </p>
          <div className="add-testers-csv">
            <input type="file" id="csvFile" accept=".csv" onChange={() => { this.setState({columnHeadersOptionsCsv: []}); this.importTestersCsvValidation(false) }} />
          </div>
          {columnHeadersOptionsCsv?.length > 0 &&
            <form>
              <div style={{marginTop: '4rem'}}>
                <Select
                  name="columnHeadersCsv"
                  label={t('InviteAudience_ImportCsv_Modal_Csv_Select_Column')}
                  onChange={(val) => this.importTestersCsvValidation(val.columnHeadersCsv)}
                  options={columnHeadersOptionsCsv}
                />
              </div>
            </form>
          }
          {emailsToAddCsv.length > 0 &&
            <div className="invite-audience-users">
              <div className="invite-audience-table">
                <div className="invite-audience-table-head">
                  <div className="invite-audience-table-head-cell check">
                    <label className="container">
                      <input type="checkbox" id="selectAllModal" onChange={this.checkAll}/>
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="invite-audience-table-head-cell">Email</div>
                </div>
                {emailsToAddCsv.map((email, key) => {
                  return (
                    <div className="invite-audience-table-row" key={key}>
                      <div className="invite-audience-table-cell check">
                        <label className="container">
                          <input
                            value={email}
                            type="checkbox"
                            className="emailCheckModal"
                            onChange={this.onCheckboxChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="invite-audience-table-cell">{email}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          }
        </ModalDialog>
        <ModalDialog
          name="InviteAudience_DeleteTester"
          showModal={showDeleteTestersModal}
          loading={loadingModal}
          closeModal={e => { this.setState({ showDeleteTestersModal: false, deleteTester: '' }) }}
          onConfirm={e => {
            const emails = deleteTester !== '' ? [deleteTester] : this.selectedEmails();
            this.deleteTesters(emails)
          }}
        >
          {deleteTester !== ''
            ? <T text="InviteAudience_DeleteTester_Modal_Text_Only" var1={deleteTester} />
            : <T text="InviteAudience_DeleteTester_Modal_Text" var1={this.selectedEmails().length} />
          }
        </ModalDialog>
    
        <ModalDialog
          name="InviteAudience_First_Invitation_Notice"
          showModal={showFirstInvitationNotice}
          loading={loadingModal}
          closeModal={e => { this.setState({ showFirstInvitationNotice: false, emailsToSendOnConfirm: {}, showSettingsModal: false }) }}
          onCancel={e => { this.setState({ showFirstInvitationNotice: false, emailsToSendOnConfirm: {} }) }}
          onConfirm={e => { this.sendInvites(emailsToSendOnConfirm.emails, emailsToSendOnConfirm.selectedTester); this.setState({ showFirstInvitationNotice: false, emailsToSendOnConfirm: {} }) }}
        >
          {/* <div id="first-invitation-modal">
            <h3><T text="InviteAudience_Email_Configuration_Input_Test_Title" /></h3>
            <p>{campaignData?.data?.emailTestTitle || emailTestTitleDefault}</p>
            <h3><T text="InviteAudience_Email_Configuration_Input_Sender" /></h3>
            <p>{campaignData?.data?.emailSender || emailSenderDefault}</p>
          </div> */}
          <p><T text="InviteAudience_First_Invitation_Notice_Modal_Text" var1={campaignData?.data?.emailTestTitle || emailTestTitleDefault} var2={campaignData?.data?.emailSender || emailSenderDefault} /></p>
        </ModalDialog>

        {loading && (
          <div className="loading-cover have-topbar">
            <div>
              <Spinner speed={0.8} color="#00C7E7" size={20} />
            </div>
          </div>
        )}

        {!loading && (
          <>
            <PublishTest 
              onRefreshTestData={campaignData => this.setState({ campaignData })}
              onShowAdquisitionMethod={() => onShowAdquisitionMethod()} 
              campaignData={campaignData} 
            />
            <div className="content-wrapper recopilator medium two-columns">
              <div className="content-column">
                <div id="invite-audience">
                  {campaignData.published === true &&
                    <GlobalSummary
                      globalSummary={globalSummary}
                      campaignData={campaignData}
                    />
                  }
                
                  <div className="invite-audience-header">
                    <h2><T text="InviteAudience_Title" /></h2>
                  </div>
                  <p className="invite-audience-description"><T text="InviteAudience_Description" /></p>
                  <div className="invite-audience-users">
                    {loadingTesters && (
                      <div className="loading-cover">
                        <div>
                          <Spinner speed={0.8} color="#00C7E7" size={20} />
                        </div>
                      </div>
                    )}
                    {!loadingTesters && testers.length > 0 && (
                      <div className={`invite-audience-table ${disableButtonsTable}`}>
                        <div className="invite-audience-table-prehead">
                          <div className="audience-actions">
                            <Link secondary disabled={this.selectedEmails().length < 1} className="btn-open-actions" onClick={() => this.toggleActionOptions()}>
                              <div className="user-email"><T text="InviteAudience_Actions" /></div>
                              <i className="icon icon-down-open-1"></i>
                            </Link>
                            {showActionOptions && (
                              <div className="audience-actions-options" ref={this.wrapperRef}>
                                {/* <div><Link disabled={disableActionButton} inline onClick={() => { this.actionsChange('send') }}><T text="InviteAudience_Actions_Send" /></Link></div> */}
                                {this.getSelectedEmailsDisabled().length > 0 && (
                                  <div>
                                    <Link disabled={disableActionEnable} inline onClick={() => { this.actionsChange('enable') }}>
                                      <i className="icon icon-toggle-on"></i><T text="InviteAudience_Actions_Enable" var1={this.getSelectedEmailsDisabled().length} />
                                    </Link>
                                  </div>
                                )}
                                {this.getSelectedEmailsEnabled().length > 0 && (
                                  <div>
                                    <Link disabled={disableActionDisable} inline onClick={() => { this.actionsChange('disable') }}>
                                      <i className="icon icon-toggle-off"></i><T text="InviteAudience_Actions_Disable" var1={this.getSelectedEmailsEnabled().length} />
                                    </Link>
                                  </div>
                                )}
                                <div>
                                  <Link disabled={disableActionButton} inline onClick={() => { this.actionsChange('delete') }}>
                                    <i className="icon icon-trash-1"></i><T text="InviteAudience_Actions_Delete" var1={this.selectedEmails().length} />
                                  </Link>
                                </div>
                                <div className="separator"></div>
                                <div>
                                  <Link disabled={disableActionButton} inline onClick={() => { this.actionsChange('export') }}>
                                    <i className="icon icon-download-1"></i><T text="InviteAudience_Actions_ExportCsv" />
                                  </Link>
                                </div>
                              </div>
                            )}
                          </div>
                          <Link secondary className="only-desktop" disabled={loadingTable} onClick={() => this.setState({ showSettingsModal: true })}>
                            <i className="icon icon-cog"></i><T text="InviteAudience_Button_Configure_Email" />
                          </Link>
                          <Link secondary className="only-desktop" disabled={loadingTable} onClick={() => this.setState({ showImportCsvModal: true })}>
                            <i className="icon icon-upload-1"></i><T text="InviteAudience_Button_ImportCsv" />
                          </Link>
                          <Link secondary className="only-desktop" disabled={loadingTable} onClick={() => this.setState({ showInviteModal: true })}>
                            <i className="icon icon-plus-1"></i><T text="InviteAudience_Button_Add" />
                          </Link>
                          {/* <Link 
                            main 
                            className="only-desktop" 
                            disabled={loadingTable || this.getAllEmailsNotSend().length < 1 || (this.selectedEmails().length > 0 && this.getSelectedEmailsNotSend().length < 1)} 
                            onClick={() => this.sendAll()}
                          >
                            <i className="icon icon-paper-plane-1"></i>
                            {this.getSelectedEmailsNotSend().length > 0 
                              ? <span>SEND {this.getSelectedEmailsNotSend().length} INVITATIONS</span>
                              : <span>SEND ALL INVITATIONS</span>
                            }
                          </Link>   */}
                        </div>
                        {(haveInvitationToSend && campaignData.published === true) && (
                          <div className='have-invitation-waiting-send'>
                            <div className='have-invitation-waiting-send-content'>
                              <h3><T text="InviteAudience_Send_All_Unsent_Title" /></h3>
                              <p><T text="InviteAudience_Send_All_Unsent_Text" /></p>
                            </div>
                            <div className="have-invitation-waiting-send-buttons">
                              <Link 
                                secondary 
                                className="only-desktop" 
                                disabled={loadingTable} 
                                onClick={() => this.sendAll()}
                              >
                                <i className="icon icon-paper-plane-1"></i>
                                <T text="InviteAudience_Send_All_Unsent_Button" />
                              </Link>
                            </div>
                          </div>
                        )}
                        <div className="invite-audience-table-head">
                          <div className="invite-audience-table-head-cell check">
                            <label className="container">
                              <input type="checkbox" id="selectAll" onChange={this.checkAll}/>
                              <span className="checkmark"></span>
                            </label>
                          </div>
                          <div className="invite-audience-table-head-cell email"><T text="InviteAudience_Table_Column1" /></div>
                          <div className="invite-audience-table-head-cell"><T text="InviteAudience_Table_Column2" /></div>
                          <div className="invite-audience-table-head-cell only-desktop"><T text="InviteAudience_Table_Column3" /></div>
                          <div className="invite-audience-table-head-cell only-desktop"></div>
                        </div>
                        <div className="invite-audience-table-body">
                          {loadingTable && (
                            <div className="loading-cover">
                              <div className="cover"></div>
                              <div><Spinner speed={0.8} color="#00C7E7" size={20} /></div>
                            </div>
                          )}
                          {printTestersRows}
                        </div>
                      </div>
                    )}
                    {!loadingTesters && testers.length < 1 && (
                      <>
                        <div className="no-results-msg no-testers-msg">
                          <img src="../assets/img/no-campaigns-img.png" alt="No campaigns" />
                          <h2><T text="InviteAudience_No_Testers_Yet_Title" /></h2>
                          <p><T text="InviteAudience_No_Testers_Yet_Description" /></p>
                          <div className="no-testers-msg-buttons">
                            <Link secondary className="only-desktop" onClick={() => this.setState({ showImportCsvModal: true })}>
                              <i className="icon icon-upload-1"></i><T text="InviteAudience_Button_ImportCsv" />
                            </Link>
                            <Link main className="only-desktop" onClick={() => this.setState({ showInviteModal: true })}>
                              <i className="icon icon-plus-1"></i><T text="InviteAudience_Button_Add" />
                            </Link>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className='ads-column right-column'>
                <div className='content-widget contact-us'>
                  <div className='image'>
                    <img src="../../assets/img/contact-us.png" />
                  </div>
                  <h3><T text="ContactUsWidget_Title" /></h3>
                  <p><T text="ContactUsWidget_Text" /></p>

                  <Link main className="featured" onClick={() => window.Froged('open','contact')}>
                    <span><T text="ContactUsWidget_Button_Contact_Us" /></span>
                  </Link>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="fixed-bottom float-bottom only-mobile">
          <Link main className="full-width" onClick={() => this.setState({ showInviteModal: true })}>
            <i className="icon icon-plus-1"></i><T text="InviteAudience_Button_Add" />
          </Link>
        </div>
      </>
    );
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(InviteAudience);
