// COMPONENTS
import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { UserAccess, BillingAccess } from '@sounditi/ft2-api';
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next';
import TopBar from '../../components/TopBar';
import ModalDialog from '../../components/ModalDialog';
import T from '../../components/Translate';
import Link from '../../components/Link';
import Dialog from 'rc-dialog';
import { Spinner } from 'react-activity';

// ACTIONS
import { showNotification } from '../../reducers/notifications';
import { setRedirect } from '../../reducers/navigation';
import { postActionGTMUserData } from '../../utils/events';

// TYPES
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_CODE } from '../../config/global';
const stripePromise = loadStripe(STRIPE_CODE);

const mapStateToProps = (state, ownProps) => ({
  userData: state.user.userData,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setRedirect: val => dispatch(setRedirect(val)),
  showNotification: val => dispatch(showNotification(val)),
})

class PlansAndPrices extends React.Component {
  _mount = true;

  constructor(props) {
    super(props);
    this.state = {
      renovationTime: "m",
      subscriptionsListData: {},
      loading: false,
      loadingModal: false,
      showUnsuscribeModal: false
    };
  }

  componentWillMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this._mount = false;
  }

  async loadData() {
    const billingAccess = new BillingAccess();
    const subscriptionsListData = await billingAccess.getSubscriptionList();
    subscriptionsListData.sort((a, b) => (a.pricePerMonth > b.pricePerMonth) ? 1 : ((b.pricePerMonth > a.pricePerMonth) ? -1 : 0));

    this.setState({ subscriptionsListData });
  }

  onInputChange(val) {
    this.setState(val, () => {
      if (this._mount) {
        const { subscriptionsListData } = this.state;

        if (subscriptionsListData) {
          for (let i = 0; i <= subscriptionsListData.length; i++) {
            if (this.refs[`renovationTime${i}`])
              this.refs[`renovationTime${i}`].refresh()
          }
        }
      }
    });
  }

  getFormatDate(inputDate) {
    const date = new Date(inputDate);

    let month = date.getMonth() + 1;
    let day = date.getDate();
    const year = date.getFullYear();

    month = (month < 10 ? "0" : "") + month;
    day = (day < 10 ? "0" : "") + day;

    return `${day}/${month}/${year}`;
  }

  async subscriptionUpgrade(subscriptionId, planName) {
    if (this._mount) {
      const { showNotification, userData, onClickClose } = this.props;
      const { renovationTime } = this.state;
      const isYearPaid = renovationTime === "y" ? true : false;

      if (planName === "Free") {
        postActionGTMUserData("GTM_change_plan_downgrade_open_confirmation", userData);
        this.setState({ showUnsuscribeModal: true });
        return false;
      }

      this.setState({ loadingModal: true });

      postActionGTMUserData("GTM_change_plan_go_to_checkout", userData, planName)

      try {
        const billingAccess = new BillingAccess();
        // const { id: sessionId } = await billingAccess.subscriptionUpgrade(userData, subscriptionId, isYearPaid);
        const response = await billingAccess.subscriptionUpgrade(userData, subscriptionId, isYearPaid);

        if (response.id) {
          const sessionId = response.id;
          const stripe = await stripePromise;
          const { error } = await stripe.redirectToCheckout({ sessionId });

          this.setState({ loadingModal: false, showModalSubscriptions: false });

          if (error) {
            showNotification("genericError");
          }
        } else {
          this.setState({ loadingModal: false, showModalSubscriptions: false });
          showNotification("planChangedCorretly");

          this.goAccount();
        }
      } catch (error) {
        this.setState({ loading: false });
        showNotification("genericError");
      }
    }
  }

  async subscriptionCancel() {
    if (this._mount) {
      this.setState({ loadingModal: true });

      const { showNotification, userData } = this.props;
      postActionGTMUserData("GTM_change_plan_downgrade_confirm", userData, "FREE");

      try {
        const billingAccess = new BillingAccess();
        await billingAccess.subscriptionCancel(userData);

        this.setState({ loadingModal: false, showModalSubscriptions: false });
        showNotification("planChangedCorretly");

        this.goAccount();
      } catch (error) {
        this.setState({ loadingModal: false });
        showNotification("genericError");
      }
    }
  }

  goTests() {
    const { setRedirect } = this.props;
    const link = `/tests/`;
    setRedirect({ route: link });
  }

  goAccount() {
    const { setRedirect } = this.props;
    const link = `/account/`;
    setRedirect({ route: link });
  }

  onContactCLick() {
    const { userData } = this.props;
    postActionGTMUserData("GTM_change_plan_contact", userData);
    window.Froged('open','contact');
  }

  render() {
    const {
      userData,
      t,
      location
    } = this.props;
    const campaignId = this.props.campaignId || "";

    const {
      subscriptionsListData,
      renovationTime,
      loading,
      loadingModal,
      showUnsuscribeModal
    } = this.state;

    if (!userData.data || !subscriptionsListData.length)
      return false;

    const subscriptionStart = this.getFormatDate(userData.subscription.start_subscription);
    const subscriptionEnds = this.getFormatDate(userData.subscription.end_subscription);
    const subscriptionName = userData.subscription.name;
    const subscriptionIsActive = userData.subscription.status === "active";

    const isSubscribed = (
      subscriptionName === "Free" ||
      subscriptionName === ""
    ) ? false : true;

    const renderPlans = subscriptionsListData.map((plan, key) => {
      let classCol = "col";
      let price = renovationTime === "m" ? plan.pricePerMonth.toFixed(2) : plan.pricePerYearMonth.toFixed(2);

      if (plan.recommended === true && !isSubscribed)
        classCol = classCol + ' recommended';

      if (plan.name === "Free")
        classCol = classCol + ' free compact';

      if (subscriptionName === plan.name && plan.name !== "Free") {
        classCol = classCol + ' active';
        price = userData.subscription.is_year_paid === true ? plan.pricePerYearMonth.toFixed(2) : plan.pricePerMonth.toFixed(2);
      }

      price = (price + "").replace(",", ".").split(".");

      return (
        <div className={classCol} key={plan.provider_prodId}>
          {plan.name === "Free" && (
            <div className="push-down"></div>
          )}
          {(plan.recommended === true && !isSubscribed) && (
            <div className="plan-recommended-text"><T text="SubscriptionPlan_Featured_Badged" /></div>
          )}
          <div className="plan-box">
            <div className="plan-title">{plan.name}</div>
            <div className="plan-price-wrapper">
              <div className="plan-price">
                <div className="plan-price-currency">€</div>
                <div className="plan-price-value">{price[0]}</div>
                <div className="plan-price-month"><span className="decimals">.{price[1]}</span><T text="SubscriptionPlan_Price_Month" /></div>
              </div>
            </div>
            {plan.name !== "Free" && plan.testNumber >= 0 && (
              <div className="plan-description"><T text="SubscriptionPlan_Description" var1={plan.testNumber} var2={plan.testDuration} /></div>
            )}
            {plan.name !== "Free" && plan.testNumber < 0 && (
              <div className="plan-description"><T text="SubscriptionPlan_Description_Unlimited" var1={plan.testDuration} /></div>
            )}
            <div className="plan-pay-wrapper">
              {userData.subscription.subscriptionId === plan.subscriptionId && (
                <div className="plan-pay">
                  {subscriptionIsActive && (
                    <div className="plan-active-info"><T text="SubscriptionPlan_Active_From" var1={subscriptionStart} /></div>
                  )}
                  {!subscriptionIsActive && (
                    <div className="plan-active-info"><T text="SubscriptionPlan_Active_To" var1={subscriptionEnds} /></div>
                  )}
                </div>
              )}
              {userData.subscription.subscriptionId !== plan.subscriptionId && (
                <div className="plan-pay">
                  {(plan.recommended === true && !isSubscribed) && (
                    <Link main onClick={() => { this.subscriptionUpgrade(plan.subscriptionId, plan.name) }}>
                      <T text="SubscriptionPlan_Btn_Change_Plan" var1={plan.name.toUpperCase()} />
                    </Link>
                  )}
                  {(plan.recommended !== true || isSubscribed) && (
                    <Link secondary onClick={() => { this.subscriptionUpgrade(plan.subscriptionId, plan.name) }}>
                      <T text="SubscriptionPlan_Btn_Change_Plan" var1={plan.name.toUpperCase()} />
                    </Link>
                  )}
                </div>
              )}
            </div>
            <div className="plan-characteristics">
              <T text="SubscriptionPlan_Characteristics" var1={plan.testDuration} />
              {plan.testNumber >= 0 && (
                <T text="SubscriptionPlan_Montly_Tests" var1={plan.testNumber} />
              )}
              {plan.testNumber < 0 && (
                <T text="SubscriptionPlan_Unlimited_Tests" />
              )}
            </div>
          </div>
          <div className="plan-more-info-wrapper">
            {plan.name !== "Free" && (
              <a href="https://byalyze.net/tarifas#blank" target="_blank"><i className="icon icon-eye-6"></i><T text="SubscriptionPlan_Btn_More_Details" /></a>
            )}
          </div>
        </div>
      )}
    );

    return (
      <div className="app screen-campaigns white">
        <Helmet>
          <title>{t('SupportAndHelp_Browser_Title')}</title>
        </Helmet>
        <ModalDialog
          name="SubscriptionPlan_Downgrade"
          showModal={showUnsuscribeModal}
          loading={loadingModal}
          closeModal={e => {
            this.setState({ showUnsuscribeModal: false });
            postActionGTMUserData("GTM_change_plan_downgrade_cancel", userData);
          }}
          onConfirm={() => { this.subscriptionCancel() }}
          onCancel={() => { postActionGTMUserData("GTM_change_plan_downgrade_cancel", userData) }}
        >
          <p><T text="SubscriptionPlan_Downgrade_Text" /></p>
        </ModalDialog>
        <div className="main-wrapper">
          <TopBar location={location} />
          <div className="screen-content">
            <div className="content-wrapper large">
              {loading && (
                <div className="loading-cover">
                  <div>
                    <Spinner speed={0.8} color="#ffffff" size={20} />
                  </div>
                </div>
              )}
              {!loading && (
                <div className="subscriptions-modal">
                  <h1><T text="SubscriptionPlan_Title" /></h1>
                  <div className="subscriptions-modal-subtitle">
                    <p><T text="SubscriptionPlan_Subtitle_1" /></p>
                    <p><T text="SubscriptionPlan_Subtitle_2" /></p>
                  </div>
                  <div className="subscription-period-selector-wrapper">
                    <div className="subscription-period-selector">
                      <div
                        onClick={() => this.setState({ renovationTime: "m" })}
                        className={renovationTime === "m" ? "selector-button active" : "selector-button"}
                      >
                        <T text="SubscriptionPlan_Facturation_Montly" />
                      </div>
                      <div
                        onClick={() => this.setState({ renovationTime: "y" })}
                        className={renovationTime === "y" ? "selector-button active" : "selector-button"}
                      >
                        <T text="SubscriptionPlan_Facturation_Yearly" /> <span className="discount"><T text="SubscriptionPlan_Yearly_Discount" /></span>
                      </div>
                    </div>
                  </div>
                  <div className="subscription-plans">
                    {renderPlans}
                    <div className="col compact custom">
                      <div className="plan-box">
                        <div className="plan-title"><T text="SubscriptionPlan_Custom_Name" /></div>
                        <div className="plan-description"><b><T text="SubscriptionPlan_Custom_Description" /></b></div>
                        <Link secondary onClick={() => this.onContactCLick()}>
                          <T text="SubscriptionPlan_Btn_Contact" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(PlansAndPrices);
