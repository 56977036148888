import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { Spinner } from 'react-activity';
import { UserAccess } from '@sounditi/ft2-api';
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next';

import { setSession } from '../reducers/user';
import { showNotification } from '../reducers/notifications';
import { setRedirect } from '../reducers/navigation';

import T from '../components/Translate';
import Link from '../components/Link';
import Input from '../components/Input';

import { URL_CAMPAIGNS } from '../config/urls';

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth.api,
  tokenData: state.user.tokenData,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setSession: val => dispatch(setSession(val)),
  showNotification: val => dispatch(showNotification(val)),
  setRedirect: val => dispatch(setRedirect(val)),
  dispatch,
})

class SignupConfirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataValidated: false,
      betaCode: "",
      name: "",
      password: "",
      email: "",
      showPassword: false,
      loading: false,
    };
  }

  async onConfirmClick() {
    const { setSession, showNotification, auth, dispatch } = this.props;
    const { dataValidated, email, password, name, betaCode } = this.state;

    const userAccess = new UserAccess();

    if (dataValidated) {
      try {
        this.setState({ loading: true });
        await userAccess.singUp(name, email, password);
        /*
        const hydratedLogin = await userAccess.hydratedLogin(email, password, auth);
        dispatch(setSession(hydratedLogin)).then(()=>{
          this.goCampaigns();
        })
        */
      } catch(error) {
        this.setState({ loading: false });
        showNotification("genericError");
      }
    } else {
      if (!email) {
        this.refs.email.setInvalid();
      }

      if (!password) {
        this.refs.password.setInvalid();
      }

      if (!name) {
        this.refs.name.setInvalid();
      }

      if (!betaCode ||
        (betaCode !== "3467-4567-6789" &&
        betaCode !== "5684-5477-1545" &&
        betaCode !== "5845-7877-1557" &&
        betaCode !== "2745-9491-1186")
      ) {
        this.refs.betaCode.setInvalid();
      }

      showNotification("invalidData");
    }
  }

  validateData() {
    const { password, email, name, betaCode } = this.state;
    const dataValidated = (password !== '' && email !== '' && name !== '' && betaCode !== '' &&
    (betaCode === "3467-4567-6789" ||
     betaCode === "5684-5477-1545" ||
     betaCode === "5845-7877-1557" ||
     betaCode === "2745-9491-1186")
    );
    this.setState({ dataValidated });
  }

  onInputChange(val) {
    this.setState(val, ()=>{
      this.validateData();
    });
  }

  onCheckboxChange(val) {
    this.setState({ [val.target.name]: val.target.checked });
  }

  goCampaigns() {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_CAMPAIGNS });
  }

  render() {
    const { loading, email, password, name, betaCode } = this.state;
    const { t } = this.props;
    
    if (loading){
      return (
        <div className="app screen-login">
          <div className="login-box">
            <img className="logo" src="../assets/img/logo.png" alt="Company logo" />
            <div>
              <Spinner speed={0.8} color="#ffffff" size={20} />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="app screen-login">
        <Helmet>
          <title>{t('SingupConfirm_Browser_Title')}</title>
        </Helmet>
        <div className="login-box signup-confirm">
          <img className="logo" src="../assets/img/logo.png" alt="Company logo" />
          <h2><T text="SingupConfirm_Title" /></h2>
          {/* <p>You will be abble to login after<br/>your account is activated</p> */}
          {/* <p>We've sent an activation link<br/>to your email account</p> */}
          <p className="last"><T text="SingupConfirm_Text" /></p>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(SignupConfirm);
