import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { Spinner } from 'react-activity';
import { composeInitialProps, withTranslation } from 'react-i18next';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { loadStripe } from '@stripe/stripe-js';
import { BillingAccess } from '@sounditi/ft2-api';

import ModalDialog from '../../components/ModalDialog';
//import TopBar from '../../components/TopBar';
//import ContentHeader from '../../components/ContentHeader';
import Link from '../../components/Link';
import Input from '../../components/Input';
//import Select from '../../components/Select';
//import MobileTopBar from '../../components/MobileTopBar';
import T from '../../components/Translate';

import { URL_CAMPAIGN_RECOPILATOR_ID, URL_CAMPAIGNS } from '../../config/urls';
import { getCampaignDuration, getURLParameter } from '../../utils/global';
import { STRIPE_CODE } from '../../config/global';

import { setRedirect } from '../../reducers/navigation';
import { showNotification } from '../../reducers/notifications';

import '../../styles/components/BuyAudience.scss';

const mapStateToProps = (state, ownProps) => ({
  userData: state.user.userData,
  testsData: state.user.testsData,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setRedirect: val => dispatch(setRedirect(val)),
  showNotification: val => dispatch(showNotification(val)),
  dispatch
})

class BuyAudience extends React.Component {
  _mount = true;

  constructor(props) {
    super(props);
    this.state = {
      campaignData: [],
      showLocationForm: false,
      showGenreForm: false,
      showAgeForm: false,
      locationCountryForm: '',
      locationRegionForm: '',
      genreForm: '',
      ageMinForm: 18,
      ageMaxForm: 99,
      responsesForm: 250,
      linkName: '',
      newLinkName: '',
      showEditModal: false,
      pricePerResponse: 4.8,
      totalPrice: 0,
      testDuration: 0,
      loading: false,
      is_promo_active: true,
      initialEventsSended: false
    };
  }

  componentWillMount() {
    this.loadData();
  }

  componentDidMount() {

  }

  componentDidUpdate() {
    const { initialEventsSended } = this.state;

    if (this.props.topbar && !initialEventsSended) {
      this.props.topbar.sendEvent("control_enter_screen_buyAudience", "BuyAudience");
      this.setState({ initialEventsSended: true });
    }
  }

  componentWillUnmount() {
    this._mount = false;
  }

  async loadData() {
    if (this._mount) {
      const { testsData } = this.props;

      const campaignId = getURLParameter("id");
      const campaignData = testsData.filter(test => test.campaignId === campaignId)[0];

      if (!campaignId || !campaignData)
        this.goCampaigns();

      if (campaignData) {
        const testDuration = getCampaignDuration(campaignData, true);
        this.calculateTotalPrice();
        this.setState({ campaignData, testDuration });
      }
    }
  }

  goRecopilator() {
    const { setRedirect } = this.props;
    const { campaignData } = this.state;
    setRedirect({ route: URL_CAMPAIGN_RECOPILATOR_ID.replace('[var1]', campaignData.campaignId) });
  }

  goCampaigns() {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_CAMPAIGNS });
  }

  openEditLink(linkNameSelected) {
    this.setState({ showEditModal: true, newLinkName: linkNameSelected});
  }

  handleChangeLinkName(val) {
    this.setState({ newLinkName: val.newLinkName });
  }

  saveLink() {
    const { newLinkName } = this.state;
    this.setState({ linkName: newLinkName, showEditModal: false });
  }

  selectCountry (val) {
    this.setState({ locationCountryForm: val });
  }

  selectRegion (val) {
    this.setState({ locationRegionForm: val });
  }

  selectAgeMin (val) {
    this.setState({ ageMinForm: parseInt(val.ageMinForm) || 18 });
  }

  selectAgeMax (val) {
    this.setState({ ageMaxForm: parseInt(val.ageMaxForm) || 99 });
  }

  handleChangeResponses(e) {
    const min = 50;
    const max = 5000;
    let val = e.target.value;

    this.setState({responsesForm: parseInt(val)});

    document.querySelector('.range-responses-slider').style.backgroundSize = (val / max) * 100 + '% 100%';
    this.calculateTotalPrice(val);
  }

  calculateTotalPrice(val) {
    const {responsesForm, pricePerResponse} = this.state;
    const responses = val ? parseInt(val) : parseInt(responsesForm);
    const total = responses * pricePerResponse;
    this.setState({totalPrice: total});
  }

  validateData() {
    const { t } = this.props;
    const { locationCountryForm, locationRegionForm, genreForm, ageMinForm, ageMaxForm, linkName } = this.state;

    const genreFormSelected = genreForm === '' ? t('BuyAudience_Step1_Genre_Text_Both') : genreForm;

    const linkNameSelected = linkName === '' ? t('BuyAudience_Title') : linkName;

    let locationForm = '';
    if (locationCountryForm && !locationRegionForm) {
      locationForm = locationCountryForm;
    } else if (locationCountryForm && locationRegionForm) {
      locationForm = `${locationRegionForm}, (${locationCountryForm})`;
    } else {
      locationForm = t('BuyAudience_Step1_Location_Text_All');
    }

    if ( parseInt(ageMinForm) < 18 || parseInt(ageMaxForm) > 99 || (parseInt(ageMaxForm) < parseInt(ageMinForm)) ) {
      if (this._mount && this.refs.ageMinForm && this.refs.ageMaxForm) {
        this.refs.ageMinForm.setInvalid();
        this.refs.ageMaxForm.setInvalid();
      }
      return false;
    }

    const validData = {
      linkNameSelected,
      genreFormSelected,
      locationForm,
      ageMinForm,
      ageMaxForm
    };
    return validData;
  }

  onSubmit(e) {
    e.preventDefault();
    const { showNotification, userData } = this.props;
    const { responsesForm, totalPrice, campaignData } = this.state;

    const dataValidated = this.validateData();

    if (dataValidated) {
      const { linkNameSelected, genreFormSelected, locationForm, ageMinForm, ageMaxForm } = dataValidated;

      const payAudienceRequest = {
        userId: userData.id,
        userEmail: userData.email,
        campaignId: campaignData.campaignId,
        alias: linkNameSelected,
        totalAmount: totalPrice*100,
        paidAudienceSegments: [
          {
            numberOfPeople: responsesForm,
            genre: genreFormSelected,
            country: locationForm,
            fromAge: ageMinForm,
            toAge: ageMaxForm
          }
        ]
      }
      //console.log(payAudienceRequest)
      this.payAudience(payAudienceRequest);

      /*
      const campaignAccess = new CampaignAccess();
      if (!campaignData?.chosenAudienceType) {
        await campaignAccess.updateChosenAudienceType(campaignData.campaignId, 'alyze');
      }
      */
    } else {
      showNotification("invalidData");
    }
  }

  async payAudience(payAudienceRequest) {
    const { showNotification } = this.props;
    const { campaignData } = this.state;

    try {
      this.setState({ loading: true });
      const stripePromise = loadStripe(STRIPE_CODE);
      const billingAccess = new BillingAccess();
      const sessionId = await billingAccess.payAudience(payAudienceRequest);
      //console.log(sessionId);
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({ sessionId });
      this.setState({ loading: false });

      if (this.props.topbar)
        this.props.topbar.sendEvent("control_pay_audience", "BuyAudience", { campaignId: campaignData.campaignId })

      if (error) {
        showNotification("genericError");
      }
    } catch (error) {
      this.setState({ loading: false });
      showNotification("genericError");
    }
  }

  hideAllRules() {
    this.setState({showLocationForm: false});
    this.setState({showGenreForm: false});
    this.setState({showAgeForm: false});
  }

  toggleLocationForm() {
    const { showLocationForm } = this.state;
    this.setState({showLocationForm: !showLocationForm});
  }

  toggleGenreForm() {
    const { showGenreForm } = this.state;
    this.setState({showGenreForm: !showGenreForm});
  }

  toggleAgeForm() {
    const { showAgeForm } = this.state;
    this.setState({showAgeForm: !showAgeForm});
  }

  render() {
    const {
      campaignData,
      showLocationForm,
      showGenreForm,
      showAgeForm,
      locationCountryForm,
      locationRegionForm,
      genreForm,
      ageMinForm,
      ageMaxForm,
      responsesForm,
      linkName,
      newLinkName,
      showEditModal,
      pricePerResponse,
      totalPrice,
      testDuration,
      loading,
      is_promo_active
    } = this.state;
    const { t, location } = this.props;

    const chosenAudienceType = campaignData?.chosenAudienceType;
    const campaignName = campaignData.data ? campaignData.data.name : "";
    const linkNameSelected = linkName === '' ? t('BuyAudience_Title') : linkName;
    const ageForm = `${ageMinForm} - ${ageMaxForm}`
    const genreFormSelected = genreForm === '' ? t('BuyAudience_Step1_Genre_Text_Both') : genreForm;
    let locationForm = '';

    if (locationCountryForm && !locationRegionForm) {
      locationForm = locationCountryForm;
    } else if (locationCountryForm && locationRegionForm) {
      locationForm = `${locationRegionForm}, (${locationCountryForm})`;
    } else {
      locationForm = t('BuyAudience_Step1_Location_Text_All');
    }

    return (
      <>
        <ModalDialog
          name="Recopilator_Edit_Link"
          showModal={showEditModal}
          loading={false}
          closeModal={e => { this.setState({ showEditModal: false }) }}
          onConfirm={() => { this.saveLink() }}
        >
          <Input
            placeholder=""
            name="newLinkName"
            label={t('Recopilator_Edit_Link_Input_Name')}
            value={newLinkName}
            ref="newLinkName"
            last
            onChange={val => this.handleChangeLinkName(val)}
          />
        </ModalDialog>

        {loading && (
          <div className="loading-cover">
            <div className="cover white" ></div>
            <div>
              <Spinner speed={0.8} color="#00C7E7" size={20} />
            </div>
          </div>
        )}
        {/*
        <div className="main-wrapper">
          <TopBar ref="topbar" location={location} />
          <MobileTopBar
            subtitle={campaignName}
            title={linkNameSelected}
            onClickBackButton={() => this.refs.topbar.goMetrics(campaignData)}
          />
          <div className="fluid screen-content">
            <ContentHeader
              location={location}
              campaignData={campaignData}
            />
            <div className="content-wrapper">
              <Link className="no-border no-padding-left buy-audience-link only-desktop" onClick={() => { this.goRecopilator() }}>
                <i className="icon icon-left-open-1"></i><T text="BuyAudience_Go_Back" />
              </Link>
        */}
              <div className="buy-audience">
                {/*!chosenAudienceType &&
                  <div className="message-warning">
                    <i className="icon icon-warning-empty"></i>
                    <T text="BuyAudience_Warning_Message" />
                  </div>
                */}
                <div className="buy-audience-left">
                  <div className="buy-audience-left-description">
                    <h2 className="only-desktop">
                      <span onClick={ () => this.openEditLink(linkNameSelected) }>
                        {linkNameSelected}<i className="icon icon-pencil-1"></i>
                      </span>
                    </h2>
                    <p><T text="BuyAudience_Description" /></p>
                  </div>

                  <div className="content-widget paid-audience">
                    <h3><T text="BuyAudience_Step1_Title" /></h3>
                    {/*<p><T text="BuyAudience_Step1_Description" /></p>*/}
                    {/*
                    <Link className="no-border no-padding buy-audience-link">
                      <T text="BuyAudience_Step1_Link" />
                    </Link>
                    */}

                    <div className="paid-audience-rules">
                      <div className="col">
                        <div className="col-icon" onClick={ () => { this.hideAllRules(); this.toggleLocationForm(); }}>
                          <div className="title"><T text="BuyAudience_Step1_Location_Title" /></div>
                          <div className="rules-icon"><img src="/assets/img/icon-location.png" alt="Top location" /></div>
                        </div>
                        <div className="value"><T text="BuyAudience_Step1_Location_Text" />: {locationForm}</div>
                      </div>
                      <div className="col">
                        <div className="col-icon" onClick={ () => { this.hideAllRules(); this.toggleGenreForm(); }}>
                          <div className="title"><T text="BuyAudience_Step1_Genre_Title" /></div>
                          <div className="rules-icon"><img src="/assets/img/icon-gender.png" alt="Responses obtained" /></div>
                        </div>
                        <div className="value"><T text="BuyAudience_Step1_Genre_Text" />: {genreFormSelected}</div>
                      </div>
                      <div className="col">
                        <div className="col-icon" onClick={ () => { this.hideAllRules(); this.toggleAgeForm(); }}>
                          <div className="title"><T text="BuyAudience_Step1_Age_Title" /></div>
                          <div className="rules-icon"><img src="/assets/img/icon-age.png" alt="Top location" /></div>
                        </div>
                        <div className="value"><T text="BuyAudience_Step1_Age_Text" />: {ageForm}</div>
                      </div>
                    </div>
                    {(showLocationForm || showGenreForm || showAgeForm) && (
                      <div className="arrow-group">
                        <div className={showLocationForm ? 'arrow' : 'arrow invisible'}><div className="arrow-up"></div></div>
                        <div className={showGenreForm ? 'arrow' : 'arrow invisible'}><div className="arrow-up"></div></div>
                        <div className={showAgeForm ? 'arrow' : 'arrow invisible'}><div className="arrow-up"></div></div>
                      </div>
                    )}
                    {showLocationForm && (
                      <div className="paid-audience-rules-details">
                        <label><T text="BuyAudience_Step1_Location_Text" /></label>
                        <div className="input-row select-item">
                          <div className="field">
                            <div className="select">
                              <CountryDropdown
                                value={locationCountryForm}
                                //valueType="short"
                                onChange={(val) => this.selectCountry(val)}
                              />
                            </div>
                          </div>
                          <div className="field">
                            <div className="select">
                              <RegionDropdown
                                country={locationCountryForm}
                                value={locationRegionForm}
                                //countryValueType="short"
                                //valueType="short"
                                onChange={(val) => this.selectRegion(val)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {showGenreForm && (
                      <div className="paid-audience-rules-details">
                        <div className="input">
                          <label><T text="BuyAudience_Step1_Genre_Title" /></label>
                          <div className="input-row">
                            <div className={genreForm === t('BuyAudience_Step1_Genre_Text_Male') ? "item active" : "item"} onClick={() => this.setState({ genreForm: t('BuyAudience_Step1_Genre_Text_Male') })}>
                              <p><T text="BuyAudience_Step1_Genre_Text_Male" /></p>
                            </div>
                            <div className={genreForm === t('BuyAudience_Step1_Genre_Text_Female') ? "item active" : "item"} onClick={() => this.setState({ genreForm: t('BuyAudience_Step1_Genre_Text_Female') })}>
                              <p><T text="BuyAudience_Step1_Genre_Text_Female" /></p>
                            </div>
                            <div className={genreForm === t('BuyAudience_Step1_Genre_Text_Both') ? "item active" : "item"} onClick={() => this.setState({ genreForm: t('BuyAudience_Step1_Genre_Text_Both') })}>
                              <p><T text="BuyAudience_Step1_Genre_Text_Both" /></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {showAgeForm && (
                      <div className="paid-audience-rules-details">
                          <div className="input">
                            <label><T text="BuyAudience_Step1_Age_Title" /></label>
                            <div className="input-row">
                              <Input
                                type="numeric"
                                pattern="[0-9]*"
                                name="ageMinForm"
                                ref="ageMinForm"
                                maxLength="2"
                                value={ageMinForm}
                                onChange={(val) => this.selectAgeMin(val)}
                              />
                              <i className="icon icon-minus-1"></i>
                              <Input
                                type="numeric"
                                pattern="[0-9]*"
                                name="ageMaxForm"
                                ref="ageMaxForm"
                                maxLength="2"
                                value={ageMaxForm}
                                onChange={(val) => this.selectAgeMax(val)}
                              />
                            </div>
                          </div>
                      </div>
                    )}
                  </div>

                  <div className="content-widget paid-audience">
                    <h3><T text="BuyAudience_Step2_Title" /></h3>
                    <div className="paid-audience-responses">
                      <div className="number-responses">
                        {responsesForm}
                      </div>
                      <div className="range-responses">
                        <input type="range" min="50" max="5000" step="10" className="range-responses-slider"
                          onChange={e => this.handleChangeResponses(e)}
                          value={responsesForm}
                        />
                        <div className="range-responses-steps">
                          <span>50</span>
                          <span>1000</span>
                          <span>2000</span>
                          <span>3000</span>
                          <span>4000</span>
                          <span>5000</span>
                        </div>
                      </div>
                    </div>
                    {/*<p><T text="BuyAudience_Step2_Description" /></p>*/}
                  </div>
                </div>

                <div className="buy-audience-right">
                  {is_promo_active &&
                    <div className="promo">
                      <p><T text="BuyAudience_Promo"/></p>
                      <p><T text="BuyAudience_Promo_Name" var1="2X"/>, <T text="BuyAudience_Promo_Description"/></p>
                    </div>
                  }

                  <div className="content-widget">
                    <div className="buy-audience-right-total">
                      <div className="text-flex">
                        <h2><T text="BuyAudience_Total_Cost_Title" /></h2>
                        <h2>{totalPrice}€</h2>
                      </div>
                      <div className="text-flex">
                        <p><T text="BuyAudience_Total_Cost_Tax" /></p>
                        <p>EUR</p>
                      </div>
                    </div>

                    <div className="buy-audience-right-answers">
                      <div className="featured-text text-flex">
                        <p><T text="BuyAudience_Total_Cost_Responses_Total" /></p>
                        <p>{responsesForm*2}</p>
                      </div>
                      { is_promo_active &&
                        <div className="indent1">
                          <div className="text-flex">
                            <p><T text="BuyAudience_Total_Cost_Responses" /></p>
                            <p>{responsesForm}</p>
                          </div>
                          <div className="text-flex promo-text">
                            <p><T text="BuyAudience_Promo_Name" var1={t('BuyAudience_Promo_Text')}/></p>
                            <p>{responsesForm}</p>
                          </div>
                        </div>
                      }
                    </div>

                    <div className="buy-audience-right-summary">
                      <div className="featured-text text-flex">
                        <p><T text="BuyAudience_Total_Cost_Price" /></p>
                        <p>{pricePerResponse}€</p>
                      </div>
                      <div className="indent1">
                        <div className="text-flex">
                          <p><T text="BuyAudience_Total_Cost_Duration" /></p>
                          <p>{testDuration} min</p>
                        </div>
                        <p><T text="BuyAudience_Total_Cost_Rules" /></p>
                      </div>
                      <div className="indent2">
                        <p><T text="BuyAudience_Step1_Location_Text" />: {locationForm}</p>
                        <p><T text="BuyAudience_Step1_Genre_Text" />: {genreFormSelected}</p>
                        <p><T text="BuyAudience_Step1_Age_Text" />: {ageForm}</p>
                      </div>
                      <div className="buy-audience-custom">
                        <p>
                          <span onClick={() => { window.Froged('open','contact') }} className="buy-audience-link">
                            <T text="BuyAudience_Custom_Segments_Link" />
                          </span>
                          <T text="BuyAudience_Custom_Segments" />
                        </p>
                      </div>
                    </div>

                    <Link main className="featured buy-button" onClick={ (e) => this.onSubmit(e) }>
                      <T text="BuyAudience_Total_Cost_Button" />
                    </Link>

                  </div>
                </div>
              </div>
            {/*
            </div>
          </div>
        </div>
            */}
      </>
    );
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(BuyAudience);
