import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { FileAccess } from '@sounditi/ft2-api';
import { Spinner } from 'react-activity';
import { withTranslation } from 'react-i18next';
import { Circle } from 'rc-progress';

import T from '../Translate';
import Link from '../Link';
import Select from '../Select';
import TextArea from '../TextArea';
import DraggableList from '../DraggableList';
import ModuleTypeInfo from '../ModuleTypeInfo';

import { setDisableSaveButton } from '../../reducers/navigation';

import { URL_ENVIRONMENT } from '../../config/global';

import { generateModuleID, getVideoDimensions } from '../../utils/global';

const mapStateToProps = (state, ownProps) => ({
  loadingModule: state.navigation.loadingModule,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setDisableSaveButton: val => dispatch(setDisableSaveButton(val)),
})

class FilmModulePanel extends React.Component {
  _mount = true;
  _defaultState = {
    loading: false,
    tech: "recognition",
    randomize: "randomize",
    videos: [],
    name: "",
    filmName: "",
    moduleData: [],
    dataValidated: true,
    showScrollbar: false,
    invalid: false,
    invalidFilmName: false,
    uploadedPercent: 0,
    loadingTextAnimation: "",
    genre: "",
    cancelledFiles: [],
    currentUniqueFileName: '',
    showCancelButton: true,
  };
  _scrollbar = null;
  _animationInterval = undefined;

  constructor(props) {
    super(props);
    this.state = this._defaultState;
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState) {
    const { loading } = this.state;
    const { setDisableSaveButton } = this.props;
    if (prevState.loading !== loading) {
      setDisableSaveButton(loading);
    }
  }

  componentWillUnmount() {
    this._mount = false;
    setDisableSaveButton(false);
  }

  onInputChange(val) {
    const { onInputChange } = this.props;

    if (onInputChange)
      onInputChange(val);

    if (this._mount) {
      this.setState(val, () => {
        if (this._mount) {
          this.validateData();
        }
      });
    }
  }

  startTextAnimation() {
    this._animationInterval = setInterval(() => {
      let loadingTextAnimation = this.state.loadingTextAnimation + ".";

      if (loadingTextAnimation.length > 3)
        loadingTextAnimation = ""

      this.setState({ loadingTextAnimation });
    }, 500);
  }

  stopTextAnimation() {
    this.setState({ loading: false });
    clearInterval(this._animationInterval);
    this._animationInterval = undefined;
  }

  resetData() {
    if (this._mount) {
      this.setState(this._defaultState, () => {
        if (this.refs.tech && this.refs.tech.refresh)
          this.refs.tech.refresh();

        if (this.refs.filmName && this.refs.filmName.refresh)
          this.refs.filmName.refresh();

        if (this.refs.randomize && this.refs.randomize.refresh)
          this.refs.randomize.refresh();

        this.refs.videos.refresh();
      });
    }
  }

  validateData() {
    if (this._mount) {
      this.setState({ dataValidated: true });
    }
  }

  setVideoInvalid() {
    this.setState({ invalid: true });
    setTimeout(() => {
      this.setState({ invalid: false });
    }, 3000)
  }

  setFilmNameInvalid() {
    this.setState({ invalidFilmName: true });

    if (this.refs.filmName)
      this.refs.filmName.setInvalid();

    setTimeout(() => {
      this.setState({ invalidFilmName: false });
    }, 3000)
  }

  loadData(moduleData) {
    const { onShow } = this.props;

    this.resetData();

    if (this._mount && moduleData) {
      this.setState({ moduleData });

      if (moduleData.filmName)
        this.setState({ filmName: moduleData.filmName }, () => {
          if (this.refs.filmName && this.refs.filmName.refresh)
            this.refs.filmName.refresh()
        });

      if (moduleData.name)
        this.setState({ name: moduleData.name });

      if (moduleData.genre)
        this.setState({ genre: moduleData.genre }, () => this.refs.genre.refresh());

      if (moduleData.tech)
        this.setState({ tech: moduleData.tech }, () => {
          if (this.refs.tech && this.refs.tech.refresh)
            this.refs.tech.refresh()
        });

      if (moduleData.randomize)
        this.setState({ randomize: moduleData.randomize }, () => {
          if (this.refs.randomize && this.refs.randomize.refresh)
            this.refs.randomize.refresh()
        });

      if (moduleData.videos)
        this.setState({ videos: moduleData.videos }, () => {
          if (this._mount) {
            this.refs.videos.refresh();
            // this.refs.phonePreview.loadData();
          }
        });

      if (onShow)
        onShow();
    }
  }

  show(moduleData) {
    this.loadData(moduleData);
  }

  getScaleToFitParent(el, margin = 0) {
    if (!el || !el.parentElement)
      return 1;

    const parentSize = {
      height: el.parentElement.clientHeight - margin * 2
    };

    return Math.min(parentSize.height / el.clientHeight);
  }

  async uploadImage(event) {
    const { campaignData, showNotification, onChangeLoading } = this.props;
    const { videos, moduleData } = this.state;
    const { campaignId } = campaignData;

    try {
      if (this._mount) {
        this.setState({ loading: true, uploadedPercent: 0, showCancelButton: true });
        this.startTextAnimation();
        onChangeLoading(true);
      }

      const rawFile = event.target.files[0];
      const fileName = rawFile.name;

      // const fileSize = rawFile.size;
      const fileType = rawFile.type; /* image/png image/jpg image/jpeg */
      const fileBlob = rawFile.slice(0, rawFile.size, rawFile.type);

      const uniqueID = generateModuleID();
      const uniqueFileName = `${campaignId}-${uniqueID}`;
      const uniqueFile = new File([fileBlob], uniqueFileName, {type: fileType});
      this.setState({ currentUniqueFileName: uniqueFileName });

      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = async () => {
        window.URL.revokeObjectURL(video.src);
        const fileDuration = video.duration;

        if (fileDuration > 10800) {
          showNotification("filmDurationExceeded");
          if (this._mount) {
            this.fileInput.value = '';
            this.setState({ loading: false });
          }
          return false;
        } else if (rawFile.size > 7516192768) {
          showNotification("filmSizeExceeded");
          if (this._mount) {
            this.fileInput.value = '';
            this.setState({ loading: false });
          }
          return false;
        }

        const fileAccess = new FileAccess();

        await fileAccess.uploadFilm(uniqueFile, progress => {
          const isUploadCancelled = this.isUploadCancelled(uniqueFileName);
          if (!isUploadCancelled) {
            const percentage = parseInt((progress.loaded * 100) / progress.total);
            this.setState({ uploadedPercent: percentage });
            if (percentage > 99) {
              this.setState({ showCancelButton: false });
            }
          }
        });
        const isUploadCancelled = this.isUploadCancelled(uniqueFileName);
        if (!isUploadCancelled) {
          const userFiles = await fileAccess.listFilmFiles(uniqueFileName);
          const isFileFound = userFiles.length > 0;

          if (isFileFound) {
            const { filmName } = this.state;

            videos.push({
              name: fileName,
              fileName: uniqueFileName,
              duration: fileDuration
            });

            if (filmName === '') {
              this.onInputChange({ filmName: fileName });
              
              if (this.refs.filmName)
                this.refs.filmName.refresh();
            }

            this.onInputChange({ videos });
            if (this._mount) {
              this.refs.videos.refresh()
            }
          }

          if (this._mount) {
            this.fileInput.value = '';
            // this.refs.phonePreview.loadData();
            this.stopTextAnimation();
            onChangeLoading(false);
          }
        }
      }

      video.onerror = () => {
        if (this._mount) {
          this.stopTextAnimation();
          onChangeLoading(false);
          this.fileInput.value = '';
        }
        showNotification("invalidFile");
      }

      video.src = URL.createObjectURL(rawFile);
    } catch(error) {
      if (this._mount) {
        this.stopTextAnimation();
        onChangeLoading(false);
      }
      showNotification("genericError");
    }
  }

  getPreview() {
    const { campaignData } = this.props;
    const { moduleData } = this.state;
    const { campaignId, userId } = campaignData;

    const lang = localStorage.getItem('lang') || "en";

    const jwt = require('jwt-simple');
    const payload = {
      "sub": userId,
      "name": "",
      "iat": 0,
      "iss": "",
      "aud": "",
      "exp": 0,
      "forcePreview": true,
      "campaignId": campaignId
    };
    const secret = "super-tester-secret"
    const token = jwt.encode(payload, secret);

    return `${URL_ENVIRONMENT}?id=${campaignId}&token=${token}&s=${moduleData.id}&l=${lang}`;
  }

  isUploadCancelled(idFile) {
    const { cancelledFiles } = this.state;
    return cancelledFiles.includes(idFile);
  }

  cancelUpload(idFile) {
    const { cancelledFiles } = this.state;

    if (this._mount) {
      this.fileInput.value = '';
      this.setState({cancelledFiles: cancelledFiles.concat(idFile), loading: false });
      this.stopTextAnimation();
    }
  }

  render() {
    const { genre, filmName, tech, moduleData, randomize, videos, loading, invalid, invalidFilmName, uploadedPercent, loadingTextAnimation, currentUniqueFileName, showCancelButton } = this.state;
    const { onCancel, campaignData, t, goPreview, isCampaignPaid } = this.props;

    let videoClass;
    if (invalid)
      videoClass = 'error-color';

    let filmNameClass;
    if (invalidFilmName)
      filmNameClass = 'error-color';

    let moduleName = t("Panels_Large_Video_Capital_Letter");

    return (
      <div className="panel panel-film-screen">
        {loading && (
          <div className="loading-cover">
            <div className="cover"></div>
            <div className="loading-container">
              <div className="progress-text">
                <div className="loading-text">
                  <T text="Panels_Large_Video_Uploading_File" />
                  <p className="animated">{loadingTextAnimation}</p>
                </div>
                <Circle percent={uploadedPercent > 1 ? uploadedPercent : 1} strokeWidth="4" trailWidth="4" strokeColor="#00C7E7" />
              </div>
              <div className={showCancelButton ? "cancel-button" : "cancel-button cancel-button-hidden"}>
                <Link secondary inline onClick={() => this.cancelUpload(currentUniqueFileName) }>
                  <T text="Panels_Btn_Cancel_Upload" />
                </Link>
              </div>
            </div>
          </div>
        )}
        <div className="row flex">
          <div className="col">
            <h3 className="panel-title">
              <Link secondary className="no-border no-padding" onClick={() => { onCancel() }}>
                <i className="icon icon-right-open-1"></i>
              </Link>
              {moduleName}

              <div className="right">
                <Link secondary disabled={videos.length < 1} onClick={() => goPreview(this.getPreview())}>
                  <i className="icon icon-play-4"></i>
                  <T text="Panels_Btn_Preview" />
                </Link>
              </div>
            </h3>
            <div className="panel-inputs" id="panel-video-scrollview">
              <ModuleTypeInfo type={tech} />

              <div className="field">
                <label htmlFor="filmName" className={filmNameClass}>
                  <p><T text="FilmModulePanel_Film_Name" /></p>
                </label>
                <TextArea
                  name="filmName"
                  value={filmName}
                  ref="filmName"
                  onChange={val => this.onInputChange(val)}
                  placeholder={t("FilmModulePanel_Film_Name_Placeholder")}
                  disabled={isCampaignPaid}
                />
              </div>

              <div className="field">
                <Select
                  label={t('FilmModulePanel_Film_Genre')}
                  name="genre"
                  value={genre}
                  ref="genre"
                  onChange={val => this.onInputChange(val)}
                  options={[
                    { "value": "action", "label": t("Panels_Film_Genre_Action") },
                    { "value": "adventure", "label": t("Panels_Film_Genre_Adventure") },
                    { "value": "comedy", "label": t("Panels_Film_Genre_Comedy") },
                    { "value": "horror", "label": t("Panels_Film_Genre_Horror") },
                    { "value": "thriller", "label": t("Panels_Film_Genre_Thriller") },
                    { "value": "romance", "label": t("Panels_Film_Genre_Romance") },
                    { "value": "documentary", "label": t("Panels_Film_Genre_Documentary") }
                  ]}
                  disabled={isCampaignPaid}
                />
              </div>

              <div className="field">
                <label htmlFor="generalBackgroundType" className={videoClass}><T text="FilmModulePanel_Videos" /></label>

                <DraggableList
                  video
                  value={videos}
                  name="videos"
                  ref="videos"
                  onChange={val => this.onInputChange(val)}
                  disabled={isCampaignPaid}
                />

                <input
                  className="hidden"
                  name="file"
                  type="file"
                  onChange={file => this.uploadImage(file)}
                  ref={fileInput => this.fileInput = fileInput}
                  accept=".mp4, .mov, .mkv, .avi"
                />

                {/*testIsActive &&
                  <p className="featured-color"><T text="FilmModulePanel_Videos_Test_Has_Invited_Testers" /></p>
                */}

                {(videos.length < 1) && (
                  <div className="right-content">
                    <Link main className="full-width" onClick={() => this.fileInput.click()}>
                      <i className="icon icon-plus-1"></i>
                      <T text="FilmModulePanel_Add_Video_Button" />
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation('translations', { withRef: true }),
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(FilmModulePanel);