import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { Spinner } from 'react-activity';
import { ResponsiveLine } from '@nivo/line'
import { withTranslation } from 'react-i18next';

import T from '../Translate';
import { parseNivoEmotionalOverview, parseNivoInterestOverview } from '../../utils/metrics';


const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({})

class EmotionalOverview extends React.Component {
  _mount = true;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      dataFiltered: []
    };
  }

  componentWillMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this._mount = false;
  }

  async loadData() {
    const { activeModuleTimeline, activeModuleFileName, attention, comparativeModuleTimeline } = this.props;

    if (activeModuleFileName) {
      let dataFiltered = parseNivoEmotionalOverview(activeModuleFileName, activeModuleTimeline);
      dataFiltered[0].color = '#00C7E7';

      if (attention) {
        dataFiltered = parseNivoInterestOverview(activeModuleFileName, activeModuleTimeline);
        dataFiltered[0].color = '#68C53E';
      }

      if (comparativeModuleTimeline) {
        dataFiltered = parseNivoEmotionalOverview(activeModuleFileName, comparativeModuleTimeline);
        dataFiltered[0].color = '#00C7E7';

        if (attention) {
          dataFiltered = parseNivoInterestOverview(activeModuleFileName, comparativeModuleTimeline);
          dataFiltered[0].color = '#68C53E';
        }
      }


      this.setState({ dataFiltered, data: dataFiltered, loading: false });
    }
  }

  selectInteraction(id, time) {
    const { dataFiltered } = this.state;
    const { activeModule } = this.props;

    if (activeModule && activeModule.type === "video" || activeModule.type === "audio" || activeModule.type === "film") {
      const updatedDataFiltered = dataFiltered.map((dataInteraction) => {
        const re = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/;
        let subst = 'rgba($1, $2, $3, 0.2)';

        if (id === "" || dataInteraction.id === id)
          subst = 'rgba($1, $2, $3, 1)';

        dataInteraction.color = dataInteraction.color.replace(re, subst);

        return dataInteraction;
      });

      this.setState({ dataFiltered: updatedDataFiltered });
      this.props.onClick(id, time);
    }
  }

  toggleView(interaction) {
    const { dataFiltered } = this.state;
    const { activeInteraction } = this.props;
    let isShowing = false;

    if (activeInteraction !== interaction.id) {
      let updatedDataFiltered = dataFiltered;

      dataFiltered.filter(dataInteraction => {
        if (dataInteraction.id === interaction.id)
          isShowing = true;
      });

      if (isShowing) {
        updatedDataFiltered = dataFiltered.filter(dataInteraction => dataInteraction.id !== interaction.id);
      } else {
        updatedDataFiltered.push(interaction);
      }

      this.setState({ dataFiltered: updatedDataFiltered });
      this.props.onToggleView(interaction);
    }
  }

  activateView(interactionID, time) {
    const { dataFiltered, data } = this.state;
    let isShowing = false;
    let updatedDataFiltered = dataFiltered;

    if (interactionID) {
      dataFiltered.filter(dataInteraction => {
        if (dataInteraction.id === interactionID)
          isShowing = true;
      });

      const interaction = data.filter(interaction => interaction.id === interactionID)[0];

      if (!isShowing) {
        updatedDataFiltered.push(interaction);
      }

      this.selectInteraction(interactionID, time);
      this.setState({ dataFiltered: updatedDataFiltered });
      this.props.onToggleView(interaction);
    }
  }

  render() {
    const { attention, comparativeModuleTimeline } = this.props;
    const { data, loading, dataFiltered } = this.state;

    if (loading) {
      return (
        <div className="content-widget">
          <div className="responsive-chart emotional-overview">
            <div className="loading-cover">
              <div className="cover"></div>
              <div>
                <Spinner speed={0.8} color="#00C7E7" size={20} />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      data.length > 0 ? (
        <div className="responsive-chart emotional-overview">
          <h2>{attention ? <T text="MetricsFilm_Metrics_Timeline_Attention" /> : <T text="MetricsFilm_Metrics_Timeline_Activation" />}</h2>
          <div className="flex">
            <ResponsiveLine
              data={dataFiltered}
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              xScale={{ type: 'point' }}
              yScale={{ type: 'linear', min: 0, max: 100, stacked: false }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: 'bottom',
                tickSize: 0,
                tickPadding: 10,
                tickRotation: 0,
                //legend: t('EmotionalOverview_Bottom_Legend'),
                legendOffset: 0,
                legendPosition: 'middle',
                format: v => `${v}s`
              }}
              axisLeft={{
                orient: 'left',
                tickSize: 0,
                tickPadding: 0,
                tickRotation: 0,
                //legend: t('EmotionalOverview_Left_Legend'),
                legendOffset: 0,
                legendPosition: 'middle',
                //format: v => `${v}%`,
                format: v => ''
              }}
              enableGridY={false}
              colors={d => d.color}
              curve="monotoneX"
              enablePoints={false}
              useMesh={true}
              enableArea={true}
              theme={{
                fontSize: 11,
                textColor: '#7f7d86',
                axis: {
                  domain: {
                    line: {
                      stroke: "#D4D6D7",
                      strokeWidth: 0
                    }
                  },
                  ticks: {
                    line: {
                      stroke: "#7f7d86",
                    }
                  }
                },
                grid: {
                  line: {
                    stroke: "#D4D6D7",
                    strokeWidth: 0,
                  }
                },
                crosshair: {
                  line: {
                    stroke: '#ffffff',
                    strokeWidth: 2,
                    strokeOpacity: 0.45,
                  },
                },
                tooltip: {
                  container: {
                    transform: 'none',
                    border: '2px blue solid'
                  },
                },
              }}
              crosshairType="bottom-left"
              isInteractive={false}
              /*
              onClick={(point) => {
                this.selectInteraction(point.serieId, point.data.x);
              }}
              onMouseLeave={(point) => {
                this.props.onMouseLeave(point.serieId, point.data.x, point.data);
              }}
              onMouseMove={(point) => {
                this.props.onTooltip(point.serieId, point.data.x, point.data);
              }}
              tooltip={({ point }) => {
                const color = '#00C7E7';

                return (
                  <div className="metrics-tooltip">
                    <div className="interaction-name">
                      <span className="color" style={{ background: color }}></span>
                      {point.serieId} {/* <span className="sec">{point.data.x}s</span> *//*}
                    </div>
                    <div key={point.serieId} className="metrics-tooltip-interaction">
                      <div>
                        <p><T text="Metrics_Total_Activation" /> <b>{point.data.y > 0 ? "+" : ""}{Math.round(point.data.y)}%</b></p>
                        <hr />
                        <p><T text="Metrics_Engagement" /> <b>{point.data.engagement > 0 ? "+" : ""}{point.data.engagement}%</b></p>
                        <p><T text="Metrics_Satisfaction" /> <b>{point.data.satisfaction > 0 ? "+" : ""}{point.data.satisfaction}%</b></p>
                        <p><T text="Metrics_Interest" /> <b>{point.data.interest > 0 ? "+" : ""}{point.data.interest}%</b></p>
                      </div>
                    </div>
                  </div>
                )
              }}
              */
            />
          </div>
        </div>
      ) : <div></div>
    );
  }
}

export default compose(
  withTranslation('translations', { withRef: true }),
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(EmotionalOverview);
