import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ReactTooltip from "react-tooltip";
import { UserAccess } from '@sounditi/ft2-api';
import { CampaignAccess, BillingAccess } from '@sounditi/ft2-api';

import { showNotification } from '../reducers/notifications';
import { setRedirect } from '../reducers/navigation';


import T from './Translate';

import { getCampaignDuration, fancyTimeFormat } from '../utils/global';


const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setRedirect: val => dispatch(setRedirect(val)),
  showNotification: val => dispatch(showNotification(val)),
})

class DurationBar extends React.Component {
  _mount = true;

  constructor(props) {
    super(props);
    this.state = {
      userData: {},
      unitPrice: 0
    };
  }

  componentWillMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this._mount = false;
  }

  componentDidUpdate() {
    // this.getPrice();
  }

  async loadData() {
    const userAccess = new UserAccess();
    const userData = await userAccess.getUserData();
    
    // this.getPrice();

    if (this._mount)
      this.setState({ userData });
  }

  async getPrice() {
    const { campaignData } = this.props;
    

    if (campaignData?.campaignId) {
      const billingAccess = new BillingAccess();
      const requestTestersPrice = await billingAccess.requestTestersPrice(
        {
          "campaignId": campaignData.campaignId,
          "paidAudienceSegments": [
            {
              "numberOfPeople": 1,
              "country": "all",
              "toAge": 99,
              "gender": "all",
              "city": "all",
              "fromAge": 18,
              "segmentName": "New segment"
            }
          ]
        }
      )

      console.log(requestTestersPrice)

      const unitPrice = requestTestersPrice.pricePerTester / 100;

      this.setState({ unitPrice })
    }

  }

  render() {
    const { campaignData, testPrice, t } = this.props;
    const coreModulesPre = this.props.coreModulesPre.filter(item => item.type !== "empty");
    const campaignModules = this.props.campaignModules.filter(item => item.type !== "empty");
    const coreModulesPost = this.props.coreModulesPost.filter(item => item.type !== "empty");
    const { userData, unitPrice } = this.state;

    if (!userData.data || !campaignData.data || !coreModulesPre || !campaignModules || !coreModulesPost)
      return false

    const modules = {
      ...campaignData.data.modules,
      coreModulesPre,
      coreModulesPost,
      campaignModules
    };

    const newCampaignData = { data: { ...campaignData.data, modules } };

    // console.log(newCampaignData);

    //const planLimitSeconds = userData.subscription.test_duration * 60;
    const planLimitSeconds = campaignData.features.testDuration;
    const testDurationSeconds = Math.round(getCampaignDuration(newCampaignData, false));
    const durationPercentage = planLimitSeconds > 0 ? Math.round((testDurationSeconds / planLimitSeconds) * 100) : "100";
    const durationBarLegend =  planLimitSeconds > 0 ? `${fancyTimeFormat(testDurationSeconds)} / ${fancyTimeFormat(planLimitSeconds)}` : `${fancyTimeFormat(testDurationSeconds)}`;

    const classDurationBar = (durationPercentage <= 100 || planLimitSeconds < 0) ? "test-duration included" : "test-duration non-included";

    return (
      <div className="test-duration-wrapper">
        <ReactTooltip place="bottom" effect="solid" backgroundColor="#f08c1e" />
        <div className={classDurationBar}>
          <div className="title">
            <T text="DurationBar_Title" />
            {/*durationPercentage <= 100 && (
              <div className="legend-info"><T text="DurationBar_Legend_Included" /></div>
            )}
            {durationPercentage > 100 && (
              <div className="legend-info"><T text="DurationBar_Legend_Non_Included" /></div>
            )*/}
          </div>
          <div className="test-duration-bar">
            <div className="legend">{durationBarLegend}</div>
            {/* <div className="plan-limit" style={{ left: planLimitPositionPercentage }}></div> */}
            <div className="progress" style={{ width: durationPercentage + "%" }}></div>
          </div>
        </div>
        <div className={classDurationBar}>
          <p>
            <T text="DurationBar_Price_Response" var1={`$${testPrice}`} />
            <i className="icon icon-info-4" data-tip={t('DurationBar_Price_Min_Info')} data-place="bottom"></i>
          </p>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(DurationBar);
