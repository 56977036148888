import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { Spinner } from 'react-activity';
import { UserAccess, PanelAccess } from '@sounditi/ft2-api';
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next';

import { setSession } from '../reducers/user';
import { showNotification } from '../reducers/notifications';
import { setRedirect } from '../reducers/navigation';

import T from '../components/Translate';
import Link from '../components/Link';
import Input from '../components/Input';
import PolicyCheck from '../components/PolicyCheck';

import { getURLParameter } from '../utils/global';
import { postActionGTMRegister } from '../utils/events';

import { URL_CAMPAIGNS, URL_SIGNUP_CONFIRM, URL_ADD_WAITING_LIST } from '../config/urls';

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth.api,
  tokenData: state.user.tokenData,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setSession: val => dispatch(setSession(val)),
  showNotification: val => dispatch(showNotification(val)),
  setRedirect: val => dispatch(setRedirect(val)),
  dispatch,
})

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataValidated: false,
      signupCode: "",
      name: "",
      password: "",
      email: "",
      showPassword: false,
      loading: false,
      policy: false
    };
  }

  componentDidMount() {
    const { showNotification } = this.props;

    if (getURLParameter("email")) {
      this.setState({ email: getURLParameter("email") }, () =>
        this.refs.email.refresh()
      );
    }

    if (getURLParameter("beta-code")) {
      this.setState({ signupCode: getURLParameter("beta-code") }, () =>
        this.refs.signupCode.refresh()
      );
    }

    if (getURLParameter("l")) {
      this.setState({ signupCode: getURLParameter("l") }, () =>
        this.refs.signupCode.refresh()
      );
    }

    if (getURLParameter("c")) {
      this.setState({ signupCode: getURLParameter("c") }, () =>
        this.refs.signupCode.refresh()
      );
    }

    this.sendEvent("control_enter_screen_signup", "Signup")
  }

  async sendEvent(action, screen) {
    const panelAccess = new PanelAccess();

    try {
      const createdAt = new Date().getTime();
      const sessionId = localStorage.getItem('sessionID-info');
      const browser = localStorage.getItem('browser-info');
      const device = localStorage.getItem('device-info');
      // type, device, browser, screen, sessionId, createdAt
      await panelAccess.postAction(action, screen, device, browser, sessionId, createdAt);
      // console.log({ postAction: { type: action, screen, device, browser, sessionId, createdAt } });

    } catch(error) {
      // console.log(error);
    };
  }

  async onSignupClick() {
    const { setSession, showNotification, auth, dispatch, t } = this.props;
    const { dataValidated, email, password, name, signupCode, policy } = this.state;

    const userAccess = new UserAccess();
    // console.log("onSignupClick: ");

    if (dataValidated) {
      try {
        this.setState({ loading: true });
        await userAccess.singUp(name, email, password, signupCode);
        postActionGTMRegister("GTM_register", email, signupCode);
        // this.goSignupConfirm();

        const hydratedLogin = await userAccess.hydratedLogin(email, password, auth);
        hydratedLogin.user = await userAccess.getUserData();

        dispatch(setSession(hydratedLogin)).then(() => {
          const userID = hydratedLogin.user.id || undefined;
          const name = hydratedLogin.user.data.name || hydratedLogin.user.name || "";
          const email = hydratedLogin.user.data.email || hydratedLogin.user.email || "";

          if (userID) {
            window.Froged('set', {
              userId: userID,
              name,
              email
            });
          }
          window.Froged("track", "on_sign_up");
          this.goCampaigns();
        })
      } catch (error) {
        this.setState({ loading: false });

        if (error.message === "email taken") {
          showNotification("emailTaken");
        } else if (error.message === "Email not verified") {
          showNotification("emailNotVerified");
        } else if (error.message === "Invalid signup code") {
          showNotification("earlyAccessCodeInvalid");
          this.refs.signupCode.setInvalid();
        } else {
          showNotification("genericError");
        }
      }
    } else {
      if (!email) {
        this.refs.email.setInvalid();
      }

      if (!password) {
        this.refs.password.setInvalid();
      }

      if (!name) {
        this.refs.name.setInvalid();
      }

      if (!policy) {
        this.refs.policy.setInvalid();
      }

      /*
      if (!signupCode) {
        showNotification("earlyAccessCodeInvalid");
        this.refs.signupCode.setInvalid();
        return false;
      }
      */

      showNotification("invalidData");
    }
  }

  validateData() {
    const { password, email, name, policy } = this.state;
    const dataValidated = (password !== '' && email !== '' && name !== '' && policy === true);
    this.setState({ dataValidated });
  }

  onInputChange(val) {
    this.setState(val, () => {
      this.validateData();
    });
  }

  onCheckboxChange(val) {
    this.setState({ [val.target.name]: val.target.checked });
  }

  goCampaigns() {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_CAMPAIGNS });
  }

  goSignupConfirm() {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_SIGNUP_CONFIRM });
  }

  render() {
    const { loading, email, password, name, signupCode, policy } = this.state;
    const { t } = this.props;

    if (loading) {
      return (
        <div className="loading-cover">
          <div className="cover"></div>
          <div>
            <Spinner speed={0.8} color="#00C7E7" size={20} />
          </div>
        </div>
      );
    }

    return (
      <div className="app screen-login">
        <Helmet>
          <title>{t('Singup_Browser_Title')}</title>
        </Helmet>
        <div className="login-box signup">
          <img className="logo" src="../assets/img/main-logo-c.png" alt="Company logo" />
          {/* The user does not exist */}
          <h2><T text="Singup_Title" /></h2>
          <div className="hidden">
            {getURLParameter("beta-code") && (
              <Input
                noSelectable
                readOnly
                placeholder={t('Singup_Code_Placeholder')} 
                icon="icon-flash-3"
                name="signupCode"
                label={t('Singup_Code')} 
                value={signupCode}
                ref="signupCode"
                type="text"
                onChange={val => this.onInputChange(val)}
                onPressEnter={e => this.onSignupClick(e)}
              />
            )}
            {!getURLParameter("beta-code") && (
              <Input
                autoFocus
                placeholder={t('Singup_Code_Placeholder')}
                icon="icon-flash-3"
                name="signupCode"
                label={t('Singup_Code')}
                value={signupCode}
                ref="signupCode"
                type="text"
                onChange={val => this.onInputChange(val)}
                onPressEnter={e => this.onSignupClick(e)}
              />
            )}
          </div>
          {/*!getURLParameter("beta-code") && (
            <div className="early-access-text">If you don't have an early access code to start using Alyze, you can <Link inline to={URL_ADD_WAITING_LIST}>request it by clicking here</Link></div>
          )*/}
          {getURLParameter("beta-code") && (
            <Input
              autoFocus
              placeholder={t('Singup_Name_Placeholder')} 
              icon="icon-user-7"
              name="name"
              label={t('Singup_Name')} 
              value={name}
              ref="name"
              type="text"
              onChange={val => this.onInputChange(val)}
              onPressEnter={e => this.onSignupClick(e)}
            />
          )}
          {!getURLParameter("beta-code") && (
            <Input
              placeholder={t('Singup_Name_Placeholder')} 
              name="name" 
              label={t('Singup_Name')}
              value={name}
              ref="name"
              type="text"
              onChange={val => this.onInputChange(val)}
              onPressEnter={e => this.onSignupClick(e)}
            />
          )}
          <Input
            placeholder={t('Login_Email_Placeholder')} 
            name="email"
            label={t('Login_Email')} 
            value={email}
            ref="email"
            type="text"
            onChange={val => this.onInputChange(val)}
            onPressEnter={e => this.onSignupClick(e)}
          />
          <Input
            placeholder={t('Singup_Password_Placeholder')} 
            name="password"
            label={t('Singup_Password')} 
            value={password}
            ref="password"
            type="password"
            onChange={val => this.onInputChange(val)}
            onPressEnter={e => this.onSignupClick(e)}
          />
          <PolicyCheck
            name="policy"
            ref="policy"
            onChange={val => this.onInputChange(val)}
            checked={policy}
          />
          <Link main onClick={e => this.onSignupClick(e)}><T text="Singup_Create_Account_Button" /></Link>
          <Link secondary inline to="/login"><T text="RememberPassword_Back_To_Login_Button" /></Link>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(Signup);
