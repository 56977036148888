export function parseNivoEmotionalOverview(activeModuleFileName, activeModuleTimeline) {
  const total_data = activeModuleTimeline.map(timeline => {
    const seconds = timeline.second === '0.00' ? parseInt(timeline.second) : parseFloat(timeline.second).toFixed(1);
    return {
      x: seconds,
      y: parseInt(timeline.activation),
      attention: parseInt(timeline.attention),
      activation: parseInt(timeline.activation),
      emotions: timeline.emotions
    }
  })

  return [{
     id: activeModuleFileName,
     data: total_data,
     color: '' ,
  }];
}

export function parseNivoInterestOverview(activeModuleFileName, activeModuleTimeline) {
  const total_data = activeModuleTimeline.map(timeline => {
    const seconds = timeline.second === '0.00' ? parseInt(timeline.second) : parseFloat(timeline.second).toFixed(1);
    return {
      x: seconds,
      y: parseInt(timeline.attention),
      attention: parseInt(timeline.attention),
      activation: parseInt(timeline.activation),
      emotions: timeline.emotions
    }
  })

  return [{
     id: activeModuleFileName,
     data: total_data,
     color: '' ,
  }];
}

export function parseNivoEmotionalOverview_OLD(moduleEmotionalSummary, interactionInfo, dataPoints, fullRange = true) {
  if (interactionInfo === void 0)
    interactionInfo = { name: 'No name', duration: 50 }

  if (dataPoints === void 0)
    dataPoints = 30;

  let total_serie = moduleEmotionalSummary.total.serie;
  let engagement_serie = moduleEmotionalSummary.engagement.serie;
  let satisfaction_serie = moduleEmotionalSummary.satisfaction.serie;
  let interest_serie = moduleEmotionalSummary.interes.serie;

  if (fullRange) {
    total_serie = moduleEmotionalSummary.total.serie.map(value => (value * 2) - 100);
    engagement_serie = moduleEmotionalSummary.engagement.serie.map(value => (value * 2) - 100);
    satisfaction_serie = moduleEmotionalSummary.satisfaction.serie.map(value => (value * 2) - 100);
    interest_serie = moduleEmotionalSummary.interes.serie.map(value => (value * 2) - 100);
  }

  let frames = total_serie.length;
  let step = Math.round(frames / dataPoints);
  let duration = interactionInfo.duration;
  let total_data = [];
  let engagement_data = [];
  let satisfaction_data = [];
  let interest_data = [];

  for (let index = 0; index <= dataPoints; index++) {
    let serie_idx = Math.round(index * step);
    if (serie_idx >= frames) {
        serie_idx = frames - 1;
    }
    total_data[index] = {
        x: (duration / dataPoints * index).toFixed(2) + "s",
        y: total_serie[serie_idx],
        engagement: engagement_serie[serie_idx],
        satisfaction: satisfaction_serie[serie_idx],
        interest: interest_serie[serie_idx]
    };
    engagement_data[index] = {
        x: (duration / dataPoints * index).toFixed(2) + "s",
        y: engagement_serie[serie_idx]
    };
    satisfaction_data[index] = {
        x: (duration / dataPoints * index).toFixed(2) + "s",
        y: satisfaction_serie[serie_idx]
    };
    interest_data[index] = {
        x: (duration / dataPoints * index).toFixed(2) + "s",
        y: interest_serie[serie_idx]
    };
  }
  return {
    total: { id: interactionInfo.name, data: total_data, color: interactionInfo.color },
    engagement: { id: interactionInfo.name, data: engagement_data, color: interactionInfo.color },
    satisfaction: { id: interactionInfo.name, data: satisfaction_data, color: interactionInfo.color },
    interest: { id: interactionInfo.name, data: interest_data, color: interactionInfo.color },
  };
};

export function parseNivoEmotionalMetricsSummary(emotionalSummaries, interactions) {
  // console.log(emotionalSummaries);
  // console.log(interactions);
  let engagements = {};
  let satisfactions = {};
  let interests = {};
  let keys = [];
  interactions.map((interaction, key) => {
      keys.push( interaction.name );
      engagements[interaction.name] = Math.round(emotionalSummaries[key].engagement.global.positive);
      satisfactions[interaction.name] = Math.round(emotionalSummaries[key].satisfaction.global.positive);
      interests[interaction.name] = Math.round(emotionalSummaries[key].interes.global.positive);

      engagements[`${interaction.name} color`] = interactions[key].color;
      satisfactions[`${interaction.name} color`] = interactions[key].color;
      interests[`${interaction.name} color`] = interactions[key].color;
  });
  engagements['type'] = 'Engagement';
  satisfactions['type'] = 'Satisfaction';
  interests['type'] = 'Interest';

  // console.log([engagements, satisfactions, interests]);
  return {
    data: [engagements, satisfactions, interests],
    keys
  };
};

export function parseModule(moduleData) {
  var type = moduleData.type;
  if (type === 'video') {
      return moduleData.videos;
  } else if (type === 'audio') {
      return moduleData.audios;
  } else if (type === 'image') {
      return moduleData.images.map(function (i) {
        return {
          fileName: i.fileName,
          name: i.name,
          color: i.color,
          duration: parseInt(moduleData.duration)
        };
      });
  }
};

export function parseNivoMetricPeakSummary(emotionalSummaries, interactions) {
  // console.log('emotionalSummaries', emotionalSummaries);
  // console.log('interactions', interactions);

  const engagements = []
  const satisfactions = [];
  const interests = [];
  const keys = [];

  interactions.map((interaction, key) => {
    const total_duration = interaction.duration
    const frames = emotionalSummaries[key].engagement.serie.length;
    // const peak_idx = emotionalSummaries[key].engagement.peak.peak_idx
    // const location = (emotionalSummaries[key].engagement.peak.peak_idx / frames * total_duration).toFixed(2)

    keys.push(interaction.name)
    engagements.push({
      metric: "Engagement",
      name: interaction.name,
      color: interaction.color,
      emotionValue: emotionalSummaries[key].engagement.peak.peak,
      time: (emotionalSummaries[key].engagement.peak.peak_idx / frames * total_duration).toFixed(2),
      percent: emotionalSummaries[key].engagement.peak.peak_percent_duration,
      duration: emotionalSummaries[key].engagement.peak.peak_s_duration.toFixed(2)
    })
    satisfactions.push({
      metric: "Satisfaction",
      name: interaction.name,
      color: interaction.color,
      emotionValue: emotionalSummaries[key].satisfaction.peak.peak,
      time: (emotionalSummaries[key].satisfaction.peak.peak_idx / frames * total_duration).toFixed(2),
      percent: emotionalSummaries[key].satisfaction.peak.peak_percent_duration,
      duration: emotionalSummaries[key].satisfaction.peak.peak_s_duration.toFixed(2)
    })
    interests.push({
      metric: "Interest",
      name: interaction.name,
      color: interaction.color,
      emotionValue: emotionalSummaries[key].interes.peak.peak,
      time: (emotionalSummaries[key].interes.peak.peak_idx / frames * total_duration).toFixed(2),
      percent: emotionalSummaries[key].interes.peak.peak_percent_duration,
      duration: emotionalSummaries[key].interes.peak.peak_s_duration.toFixed(2)
    })
  })

  const max_engagement = engagements.reduce((init, p) => p.emotionValue > init.emotionValue ? p : init, { name: "", emotionValue: 0 })
  const max_satisfaction = satisfactions.reduce((init, p) => p.emotionValue > init.emotionValue ? p : init, { name: "", emotionValue: 0 })
  const max_interest = interests.reduce((init, p) => p.emotionValue > init.emotionValue ? p : init, { name: "", emotionValue: 0 })

  const response = [max_engagement, max_satisfaction, max_interest];

  return response
}

export function parseLinks(object, type) {
  let data = [];
  let typeName = '';

  switch (type) {
    case 'genders':
      typeName = 'gender'
      break;
    case 'ages':
      typeName = 'label'
      break;
    case 'city_locations':
      typeName = 'name'
      break;
    default:
      break;
  }

  if (object
    && object.linkStatistics
    && object.linkStatistics.end_w_correct_interactions_details
    && object.linkStatistics.end_w_correct_interactions_details[type]
    && object.linkStatistics.end_w_correct_interactions_details[type].length > 0
    && typeName !== ''
  ){
    let obj = object.linkStatistics.end_w_correct_interactions_details[type];
    if (type === 'city_locations') {
      obj = obj.slice(0,5);
    }
    data = obj.reverse().map(v => (
      {
        "id": v[typeName],
        "label": v[typeName],
        "value": parseInt(v.percentage),
        "number": v.number
      }
    ));
  }
  return data;
};

export function parseGlobalSummary(object, type) {
  let data = [];
  let typeName = '';

  switch (type) {
    case 'genders':
      typeName = 'gender'
      break;
    case 'ages':
      typeName = 'label'
      break;
    case 'city_locations':
      typeName = 'name'
      break;
    default:
      break;
  }

  if (object
    && object.end_w_correct_interactions_details
    && object.end_w_correct_interactions_details[type]
    && object.end_w_correct_interactions_details[type].length > 0
    && typeName !== ''
  ){
    let obj = object.end_w_correct_interactions_details[type];
    if (type === 'city_locations') {
      obj = obj.slice(0,5);
    }
    data = obj.reverse().map(v => (
      {
        "id": v[typeName],
        "label": v[typeName],
        "value": parseInt(v.percentage),
        "number": v.number
      }
    ));
  }
  return data;
};

export function parseAudienceAgeGender(campaignAudienceSummary) {
  var n_testers = campaignAudienceSummary.n_testers;
  var ages_percent = campaignAudienceSummary.ages_percent;
  var ages_bins = campaignAudienceSummary.ages_bins;
  var res = ages_bins.map(function (v, i) {
      return {
          id: v[1] ? v[0] + " - " + v[1] : v[0],
          label: v[1] ? v[0] + " - " + v[1] : v[0],
          value: Math.round(ages_percent[i] * n_testers / 100),
          percentage: ages_percent[i]
      };
  });
  return res;
};

export async function parseNivoRepliesQA(replies, activeModuleRaw, filters) {
  const activeModule = JSON.parse(JSON.stringify(activeModuleRaw));
  // console.log(replies)
  // console.log(activeModule);
  // console.log(filters);

  if (Object.values(replies).length < 1) {
    // Return empty
    let filter_key = 'answers';

    if (activeModule.type === "video") {
      filter_key = 'videos';
    } else if (activeModule.type === "audio") {
      filter_key = 'audios';
    } else if (activeModule.type === "image") {
      filter_key = 'images';
    }

    var total_data = activeModule[filter_key].map(function (answer, key) {
      if (!answer.name && answer.answer) {
        answer.name = answer.answer;
      }
        
      return { id: "" + key, answer: answer.name, percentage: 0, value: 0 };
    });

    return total_data;
  }

  // Return with data
  const segmentId = filters ? filters.segmentId : undefined;
  const questionID = segmentId && segmentId !== "false" ? `${activeModule.questionID}S#${segmentId}` : activeModule.questionID;
  const gender = filters ? filters.gender : undefined
  // var percentage = Object.values(replies)[1].total_replies[0].answers_percentage;
  // var number = Object.values(replies)[1].total_replies[0].answers_number;
  
  // console.log(replies[questionID]);

  var percentage = replies[questionID]?.total_replies[0]?.answers_percentage;
  var number = replies[questionID]?.total_replies[0]?.answers_number;

  if (gender === "male") {
    percentage = replies[questionID]?.male_replies[0]?.answers_percentage;
    number = replies[questionID]?.male_replies[0]?.answers_number;
  } else if (gender === "female") {
    percentage = replies[questionID]?.female_replies[0]?.answers_percentage;
    number = replies[questionID]?.female_replies[0]?.answers_number;
  }

  let filter_key = 'answers';

  // console.log(activeModule);

  if (activeModule.type === "video") {
    filter_key = 'videos';
  } else if (activeModule.type === "audio") {
    filter_key = 'audios';
  } else if (activeModule.type === "image") {
    filter_key = 'images';
  }

  // console.log(percentage);

  var total_data = activeModule[filter_key].map(function (answer, key) {
    if (!answer.name && answer.answer) {
      answer.name = answer.answer;
    }

    // return { id: "" + key, answer: answer.answer, percentage: answer.percentage || 0, value: Math.round(answer.value) || 0 };
    return { id: "" + key, answer: answer.name, percentage: percentage && percentage[answer.name] || 0, value: Math.round(number && number[answer.name]) || 0 };
  });

  return total_data;
};

export function parseNivoReplies(itemModuleStimuli, metricsChoose) {
  const total_data = itemModuleStimuli.map(function (stimuli, key) {
    const metricsChooseStimuli = metricsChoose.stimuli.filter(chooseStimuli => (chooseStimuli.id === stimuli.fileName))[0];

    const percentage = metricsChooseStimuli.metrics?.total_clicks_percentage || 0;
    const value = metricsChooseStimuli.metrics?.total_clicks || 0;

    return {
      ...stimuli,
      id: "" + key,
      percentage: parseInt(percentage),
      value: parseInt(value)
    };
  });

  return total_data;
};

export function getMetricsFilter(selectedSegment) {
  let filter = { segmentId: selectedSegment }

  if (selectedSegment === "female" || selectedSegment === "male")
    filter = { gender: selectedSegment }

  return filter;
};
