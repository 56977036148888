import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { CampaignAccess, BillingAccess } from '@sounditi/ft2-api';
import { Spinner } from 'react-activity';
import Slider from '@mui/material/Slider';
import { loadStripe } from '@stripe/stripe-js';
import Input from '../Input';

import T from '../Translate';
import Link from '../Link';

import { showNotification } from '../../reducers/notifications';
import { setTestsData } from '../../reducers/user';

import { STRIPE_CODE } from '../../config/global';
const stripePromise = loadStripe(STRIPE_CODE);

const mapStateToProps = (state, ownProps) => ({
  testsData: state.user.testsData,
  userData: state.user.userData,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setTestsData: val => dispatch(setTestsData(val)),
  showNotification: val => dispatch(showNotification(val)),
  dispatch
})

class PayTest extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: false,
      numberOfTesters: 0,
      totalPrice: 24,
      testPrice: 6.78,
    }
  }

  componentWillMount() {
    const { campaignData } = this.props;
    const totalResults = campaignData.globalSummary.end_w_correct_interactions_details.number_of_testers;
    
    this.setState({ numberOfTesters: totalResults });
  }

  async publishCampaign() {
    const { campaignData, testsData, setTestsData, showNotification, onRefreshTestData } = this.props;
    const { loading } = this.state;

    if (!loading) {
      this.setState({ loading: true });

      try {
        const campaignAccess = new CampaignAccess();
        const refreshedTest = await campaignAccess.publishCampaign(campaignData.campaignId);
        const refreshedTestsData = testsData.map(test => {
          if (test.campaignId === refreshedTest.campaignId)
            test = refreshedTest

          return test;
        })

        setTestsData(refreshedTestsData);
        this.setState({ campaignData: refreshedTest, loading: false });

        // this.getTesters(campaignData);
        onRefreshTestData(refreshedTest)
      } catch (error) { 
        console.log(error)
        this.setState({ loading: false });
        showNotification("genericError");
      }
    }
  }

  async getPrice() {
    const { campaignData, userData } = this.props;
    const totalResults = campaignData.globalSummary.end_w_correct_interactions_details.number_of_testers;
    const sessionId = localStorage.getItem('sessionID-info');

    const billingAccess = new BillingAccess();
    const requestTestersPrice = await billingAccess.requestTestersPrice(
      {
        "campaignId": campaignData.campaignId,
        "paidAudienceSegments": [
          {
            "numberOfPeople": totalResults,
            "country": "all",
            "toAge": 99,
            "gender": "all",
            "city": "all",
            "fromAge": 18,
            "segmentName": "New segment"
          }
        ]
      }
    )

    console.log(requestTestersPrice)

    const stripeSessionId = await billingAccess.payTesters(
      {
        "campaignId": campaignData.campaignId,
        "sessionId": sessionId,
        "email": userData.email,
        "userId": userData.id,
        "paidAudienceSegments": [
          {
            "numberOfPeople": totalResults,
            "country": "all",
            "toAge": 99,
            "gender": "all",
            "city": "all",
            "fromAge": 18,
            "segmentName": "New segment"
          }
        ]
      }
    )

    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({ sessionId: stripeSessionId });
  }

  render() {
    const { campaignData } = this.props;
    const { loading, numberOfTesters } = this.state;

    console.log(campaignData)

    const totalResults = campaignData.globalSummary.end_w_correct_interactions_details.number_of_testers;

    if (campaignData.payments.length > 0)
      return false;

    const marks = [
      {
        value: 0,
        label: '0',
      },
      {
        value: 25,
        label: '25',
      },
      {
        value: 50,
        label: '50',
      },
      {
        value: 75,
        label: '75',
      },
      {
        value: 100,
        label: '100',
      },
      {
        value: 125,
        label: '125',
      },
      {
        value: 150,
        label: '150',
      },
      {
        value: 175,
        label: '175',
      },
      {
        value: 200,
        label: '200',
      },
    ];

    // return (
    //   <div className='publish-test-box'>
    //     <div className='publish-test-wrapper content-wrapper'>
    //       <i className="icon icon-attention"></i>

    //       <div className='content-text'>
    //         {totalResults > 5 ? (
    //           <>
    //             <h3>Pending of paid!</h3>
    //             <p>We are showing the results of the first 5 from {totalResults} responses obtained</p>
    //           </>
    //         ) : (
    //           <>
    //             <h3>Pending of paid!</h3>
    //             <p>You can pay the test now or later</p>
    //           </>
    //         )}
    //       </div>

    //       <Link secondary disabled={loading} onClick={() => this.publishCampaign()}>
    //         {loading && (
    //           <Spinner speed={0.8} color="#ffffff" size={16} />
    //         )}
    //         {totalResults > 5 ? (
    //           <span>PAY AND GET ALL THE RESULTS</span>
    //         ) : (
    //           <span>PAY TEST</span>
    //         )}
            
    //       </Link>
    //     </div>  
    //   </div>
    // );

    return (
      <div className='publish-test-box pay'>
        <div className='publish-test-wrapper content-wrapper'>
          <div className='publish-test-image'>
            <img src="../../assets/img/get-results-b.png" />
          </div>
          <div className='publish-test-content'>
            <h2>Paga tu test y obtén todos los resultados al instante</h2>
            <p>Tu test tiene {totalResults} respuestas, <b>te regalamos el resultado de las 5 primeras</b>.<br/>Una vez pagado el test, seguirá activo hasta alcanzar las respuestas deseadas.</p>
            
            <h3>¿Cuántas respuestas necesitas?</h3>
            <div className='number-of-responses'>
              <div className="value">
                  <input
                    placeholder="0"
                    name="numberOfTesters"
                    value={numberOfTesters}
                    ref="numberOfTesters"
                    onChange={e => {
                      if (e.target.value < totalResults) {
                        this.setState({ numberOfTesters: totalResults })
                      } else if (e.target.value < 6) {
                        this.setState({ numberOfTesters: 6 })
                      } else {
                        this.setState({ numberOfTesters: e.target.value })
                      }
                    }}
                />
                <Slider 
                    aria-label="Custom marks"
                    defaultValue={100}
                    value={parseInt(numberOfTesters)}
                    min={0}
                    max={200}
                    valueLabelDisplay="auto"
                    marks={marks}
                    onChange={(e, value, activeThumb) => {
                      if (value < totalResults) {
                        this.setState({ numberOfTesters: totalResults })
                      } else if (value < 6) {
                        this.setState({ numberOfTesters: 6 })
                      } else {
                        this.setState({ numberOfTesters: value })
                      }
                    }}
                  />
              </div>
            </div>

            <p className='small'>El mínimo de respuestas es el número de respuestas ya recopiladas</p>

            <div className='pay-resume'>
              {/* Test con una duración de 4:45 -> 6,78 x 8 = 54,24
              - 5 test gratis -> 33,9

              Total a pagar 20,34 dolares


              8  Test (duración 04:46)  $6,78  $54,24
                    Descuento 5 test gratis   -$33,90
              
                                  Total a pagar $20,34  */}
              {/* <table cellspacing="0">
                <thead>
                  <tr>
                    <th data-type="numeric" className='qty-col'>Qty</th>
                    <th data-type="text-short" className='description-col'>Description</th>
                    <th data-type="text-short">Unit price</th>
                    <th data-type="text-short">Total Price</th>
                  </tr>
                </thead>
                <tbody>
                  // <tr>
                  //   <td className='qty-col with-input'>
                  //     <input
                  //       placeholder="0"
                  //       name="numberOfTesters"
                  //       value={0}
                  //       ref="numberOfTesters"
                  //       onChange={e => {
                  //         if (e.target.value < totalResults) {
                  //           this.setState({ numberOfTesters: totalResults })
                  //         } else if (e.target.value < 6) {
                  //           this.setState({ numberOfTesters: 6 })
                  //         } else {
                  //           this.setState({ numberOfTesters: e.target.value })
                  //         }
                  //       }}
                  //     />
                  //   </td>
                  //   <td>Prepaid Test (04:46)</td>
                  //   <td>$6,78</td>
                  //   <td>$0</td>
                  // </tr> 
                  <tr>
                    <td>
                      8
                    </td>
                    <td>Test (04:46)</td>
                    <td>$6,78</td>
                    <td>$54,24</td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      5 Free test discount
                    </td>
                    <td>-$33,90</td>
                  </tr>
                </tbody>
              </table> */}

            </div>

            <div className='publish-buttons pay'>
              {/* <div className='number-of-responses'>
                <div className="value">
                  <input
                    placeholder="0"
                    name="numberOfTesters"
                    value={67}
                    ref="numberOfTesters"
                    onChange={val => () => {}}
                  />
                  <Slider 
                    aria-label="Custom marks"
                    defaultValue={67}
                    min={67}
                    max={200}
                    valueLabelDisplay="auto"
                    marks={marks}
                    />
                </div>
              </div> */}
              <div className='total-price'>
                Total <span>${((numberOfTesters - 5) * 6.78).toFixed(2)}</span>
              </div>
              <Link main className="button-with-loader" disabled={loading} onClick={() => this.getPrice()}>
                {loading && (
                  <Spinner speed={0.8} color="#ffffff" size={16} />
                )}
                <span>PAGAR Y OBTENER RESULTADOS</span>
              </Link>
            </div>
          </div>
        </div>  
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(PayTest);
