// COMPONENTS
import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import Link from './Link';
import T from './Translate';
import ModalDialog from './ModalDialog';
import { CampaignAccess } from '@sounditi/ft2-api';
import _ from 'lodash';

// ACTIONS
import { showNotification } from '../reducers/notifications';
import { setRedirect, showVersionControl, setLoadingAutoSave, setHasContentHeader } from '../reducers/navigation';
import { setTestsData, setTemporalTestsData } from '../reducers/user';

// TYPES
import {
  URL_CAMPAIGN_MODULES,
  URL_CAMPAIGN_MODULES_ID,
  URL_CAMPAIGN_DESIGN,
  URL_CAMPAIGN_CONFIGURATION,
  URL_ACCOUNT,
  URL_SUPPORT_AND_HELP,
  URL_CAMPAIGN_PREVIEW_ID,
  URL_CAMPAIGN_PREVIEW,
  URL_CAMPAIGN_DEFINE_AUDIENCE,
  URL_CAMPAIGN_DEFINE_AUDIENCE_ID,
  URL_CAMPAIGN_REVIEW_TEST_AND_PAY,
  URL_CAMPAIGN_REVIEW_TEST_AND_PAY_ID,
  URL_CAMPAIGN_METRICS,
  URL_CAMPAIGN_METRICS_ID,
  URL_CAMPAIGN_PAY,
  URL_CAMPAIGN_RECOPILATOR,
  URL_CAMPAIGN_RECOPILATOR_ID,
  URL_CAMPAIGN_BUY_AUDIENCE,
  URL_CAMPAIGN_BUY_AUDIENCE_ID,
  URL_CAMPAIGN_INVITE_AUDIENCE,
  URL_CAMPAIGN_INVITE_AUDIENCE_ID
} from '../config/urls';

const mapStateToProps = (state, ownProps) => ({
  userData: state.user.userData,
  testsData: state.user.testsData,
  temporalTestsData: state.user.temporalTestsData,
  loadingModule: state.navigation.loadingModule,
  loadingMetrics: state.navigation.loadingMetrics,
  loadingAutoSave: state.navigation.loadingAutoSave,
  hasContentHeader: state.navigation.hasContentHeader,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setRedirect: val => dispatch(setRedirect(val)),
  showNotification: val => dispatch(showNotification(val)),
  showVersionControl: val => dispatch(showVersionControl(val)),
  setTestsData: val => dispatch(setTestsData(val)),
  setTemporalTestsData: val => dispatch(setTemporalTestsData(val)),
  setLoadingAutoSave: val => dispatch(setLoadingAutoSave(val)),
  setHasContentHeader: val => dispatch(setHasContentHeader(val)),
})

class ContentHeader extends React.Component {
  _mount = true;

  constructor(props) {
    super(props);
    this.state = {
      showConfirmSaveModel: false,
      showConfirmSaveStylesModal: false,
      showSavedMessage: false
    };
  }

  componentWillMount() {
    const { setHasContentHeader } = this.props;
    setHasContentHeader(true);
  }

  componentDidUpdate(prevProps, prevState) {
    const { hasContentHeader, setHasContentHeader } = this.props;

    if (!hasContentHeader) {
      setHasContentHeader(true);
    }
  }

  componentWillUnmount() {
    const { setHasContentHeader } = this.props;
    this._mount = false;
    setHasContentHeader(false);
  }

  goAccount() {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_ACCOUNT });
  }

  goSupportAndHelp() {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_SUPPORT_AND_HELP });
  }

  goModules(campaign) {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_CAMPAIGN_MODULES_ID.replace('[var1]', campaign.campaignId) });
  }

  goMetrics(campaign) {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_CAMPAIGN_METRICS_ID.replace('[var1]', campaign.campaignId) });
  }

  goRecopilator(campaign) {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_CAMPAIGN_RECOPILATOR_ID.replace('[var1]', campaign.campaignId) });
  }

  goInviteAudience(campaign) {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_CAMPAIGN_INVITE_AUDIENCE_ID.replace('[var1]', campaign.campaignId) });
  }

  goPreview(campaign) {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_CAMPAIGN_PREVIEW_ID.replace('[var1]', campaign.campaignId) });
  }

  goDefineAudience(campaign) {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_CAMPAIGN_DEFINE_AUDIENCE_ID.replace('[var1]', campaign.campaignId) });
  }

  goReviewTestAndPay(campaign) {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_CAMPAIGN_REVIEW_TEST_AND_PAY_ID.replace('[var1]', campaign.campaignId) });
  }

  onClickApply() {
    const { campaignData, changesToApply, t, onClickApply, haveDesignChanges } = this.props;

    if (campaignData.enrolledTesters < 1) {
      onClickApply();
    } else {
      if (haveDesignChanges === true) {
        this.setState({ showConfirmSaveStylesModal: true });
      } else {
        this.setState({ showConfirmSaveModel: true });
      }
    }
  }

  render() {
    const { location, account, title, campaignData, changesToApply, t, onClickApply, onClickRevert, showConfirmSaveModalFromPreview, callbackConfirmSaveModal, loadingModule, showVersionControl, haveDemo, onClickToggleDemoData, showDemoData, loadingMetrics, loadingAutoSave, temporalTestsData } = this.props;
    const { showConfirmSaveModel, showConfirmSaveStylesModal, showSavedMessage } = this.state;
    const isCampaignPaid = campaignData?.status === 'public' || (campaignData?.status === 'processing' && campaignData?.price !== undefined);
    const textSaveButton = changesToApply ? t('ContentHeader_Save_Changes_Button') : t('ContentHeader_Saved_Button');
    const temporalTest = temporalTestsData.find(test => test.campaignId === campaignData?.campaignId);
    const changesToSave = !_.isEqual(temporalTestsData, this.props.testsData);
    //const testIsActive = (campaignData?.data?.type === 'film') ? campaignData?.status === 'public' : campaignData?.globalSummary?.start_details?.number_of_testers > 0;
    const testIsActive = campaignData?.globalSummary?.start_details?.number_of_testers > 0;
    const versionControlDisabled = (loadingModule || testIsActive || isCampaignPaid) ? true : false;
    const saveButtonDisabled = (loadingModule || isCampaignPaid) ? true : false;

    let toggleClass = `button-toggle`;

    if (showDemoData) {
      toggleClass = `${toggleClass} active`;
    }
    if (loadingMetrics) {
      toggleClass = `${toggleClass} disabled`;
    }

    let autoSaveTooltip = "ContentHeader_AutoSave_Tooltip_Saved";
    let autoSaveIcon = "icon-ok-1";

    if (loadingAutoSave || changesToSave) {
      autoSaveTooltip = "ContentHeader_AutoSave_Tooltip_Saving";
      autoSaveIcon = "icon-spin5 animate-spin";

      if (!showSavedMessage)
        this.setState({ showSavedMessage: true });
    } else {
      setTimeout(() => {
        if (showSavedMessage)
          this.setState({ showSavedMessage: false });
      }, 2000);
    }

    const renderAutoSave = (
      // data-tip={t(autoSaveTooltip)} data-place="bottom"
      <div className="content-header-autosave">
        {(loadingAutoSave || changesToSave) ? (
          <div className='icon-with-text saving-message'>
            <i className="icon icon-arrows-ccw autosave-cloud-icon"></i>
            <p>Saving...</p>
          </div>
        ) : (
          <div className={`icon-with-text ${showSavedMessage ? 'saved-message' : ''}`}>
            <i className="icon icon-cloud-2 autosave-cloud-icon">
              <i className={`icon icon-ok-1 autosave-status-icon`}></i>
            </i>
            <p>
              {showSavedMessage && (
                <span>Saved</span>
              )}
            </p>
          </div>
        )}
      </div>
    );

    if (account) {
      return (
        <div className="content-header">
          <ReactTooltip place="bottom" effect="solid" backgroundColor="#f08c1e" />
          <div className="content-header-title">
            <h1>{title}</h1>
            {renderAutoSave}
            <div className="right-content">
              
            </div>
          </div>
          <div className="content-header-menu">
            <div className="content-menu">
              <Link
                inline
                className={location.pathname === URL_ACCOUNT ? "active" : ""}
                onClick={() => this.goAccount()}
              >
                <T text="ContentHeader_Account" />
              </Link>
              <Link
                inline
                className={location.pathname === URL_SUPPORT_AND_HELP ? "active" : ""}
                onClick={() => this.goSupportAndHelp()}
              >
                <T text="ContentHeader_Support_And_Help" />
              </Link>
              {/*
              <Link inline>
                Referidos
              </Link>
              <Link inline>
                Detalles de facturación
              </Link>
              <Link inline>
                Historial de transacciones y facturas
              </Link>
              */}
            </div>
          </div>
        </div>
      );
    }

    if (campaignData && campaignData.data) {
      return (
        <>
          <ModalDialog
            name="Save_Published_Campaign"
            showModal={showConfirmSaveModel || showConfirmSaveModalFromPreview}
            loading={false}
            closeModal={e => { this.setState({ showConfirmSaveModel: false }); callbackConfirmSaveModal() }}
            onCancel={() => { this.setState({ showConfirmSaveModel: false }); callbackConfirmSaveModal() }}
            onConfirm={() => { onClickApply(); this.setState({ showConfirmSaveModel: false }); callbackConfirmSaveModal() }}
          >
            <div className="save-warning-modal">
              <p className="warning-text"><T text="Save_Published_Campaign_Text" /></p>
              <p className="icon-text"><i className="icon icon-error"></i><T text="Save_Published_Campaign_Text_Error_Title"/></p>
              <p><T text="Save_Published_Campaign_Text_Error" /></p>
              <p className="icon-text"><i className="icon icon-warning-empty"></i><T text="Save_Published_Campaign_Text_Warning_Title"/></p>
              <p><T text="Save_Published_Campaign_Text_Warning" /></p>
              <p className="icon-text"><i className="icon icon-ok-squared"></i><T text="Save_Published_Campaign_Text_Ok_Title"/></p>
              <p><T text="Save_Published_Campaign_Text_Ok" /></p>
            </div>
          </ModalDialog>
          <ModalDialog
            name="Save_Styles_Published_Campaign"
            showModal={showConfirmSaveStylesModal}
            loading={false}
            closeModal={() => { this.setState({ showConfirmSaveStylesModal: false }) }}
            onCancel={() => { this.setState({ showConfirmSaveStylesModal: false }) }}
            onConfirm={() => { onClickApply(); this.setState({ showConfirmSaveStylesModal: false }) }}
          >
            <div className="save-warning-modal">
              <p><T text="Save_Styles_Published_Campaign_Text" /></p>
            </div>
          </ModalDialog>
          <ReactTooltip place="bottom" effect="solid" backgroundColor="#f08c1e" />
          <div className="content-header">
            <div className="content-header-title">
              <h1>{temporalTest.data.name}</h1>

              <div className="right-content">
                {/* {(campaignData.status === "draft" ||
                  campaignData.status === "preview") && ( */}
                {/*
                {onClickApply && onClickRevert && (
                  <>
                    <div data-tip={versionControlDisabled ? t('ContentHeader_Edit_Published_Test_Tooltip') : t('ContentHeader_Version_Control_Tooltip')} data-place="left">
                      <Link
                        disabled={versionControlDisabled}
                        className={!versionControlDisabled ? "no-border no-padding" : "disabled no-border no-padding"}
                        secondary
                        onClick={() => { showVersionControl(true) }}
                      >
                        <i className="icon icon-calendar"></i>
                      </Link>
                    </div>
                    <Link disabled={saveButtonDisabled} className={changesToApply && !saveButtonDisabled ? "" : "disabled"} secondary onClick={() => { onClickRevert() }}>
                      <i className="icon icon-ccw-1"></i>
                      <T text="CampaignsConfiguration_Revert_Changes_Button" />
                    </Link>
                    <div data-tip={t('ContentHeader_Edit_Published_Test_Tooltip')} data-place="left" data-tip-disable={!isCampaignPaid}>
                      <Link disabled={saveButtonDisabled} className={changesToApply && !saveButtonDisabled ? "" : "disabled"} main onClick={() => { this.onClickApply() }}>
                        {changesToApply && (
                          <i className="icon icon-upload-cloud-1"></i>
                        )}
                        {!changesToApply && (
                          <i className="icon icon-check-1"></i>
                        )}
                        {textSaveButton}
                      </Link>
                    </div>
                  </>
                )}
                */}
                {renderAutoSave}
              </div>
            </div>
            <div className="content-header-menu">
              <div className="content-menu">
                <Link
                  inline
                  className={
                    location.pathname === URL_CAMPAIGN_MODULES
                    || location.pathname === URL_CAMPAIGN_DESIGN
                    || location.pathname === URL_CAMPAIGN_CONFIGURATION
                    ? "active" : ""
                  }
                  onClick={() => this.goModules(campaignData)}
                >
                  <T text="ContentHeader_Design_Test" />
                </Link>
                <i className="icon icon-right-open-1"></i>
                <Link
                  inline
                  className={location.pathname === URL_CAMPAIGN_PREVIEW ? "active" : ""}
                  onClick={() => this.goPreview(campaignData)}
                >
                  <T text="ContentHeader_Preview_And_Share" />
                </Link>
                <i className="icon icon-right-open-1"></i>
                {/* <Link
                  inline
                  className={location.pathname === URL_CAMPAIGN_DEFINE_AUDIENCE ? "active" : ""}
                  onClick={() => this.goDefineAudience(campaignData)}
                >
                  <T text="ContentHeader_Define_Audience" />
                </Link>
                <i className="icon icon-right-open-1"></i>
                <Link
                  inline
                  className={location.pathname === URL_CAMPAIGN_REVIEW_TEST_AND_PAY ? "active" : ""}
                  onClick={() => this.goReviewTestAndPay(campaignData)}
                >
                  <T text="ContentHeader_Review_Test_And_Pay" />
                </Link>
                <i className="icon icon-right-open-1"></i> */}
                {/* campaignData.data.type && campaignData.data.type === 'film'
                  ? <Link
                      inline
                      className={location.pathname === URL_CAMPAIGN_INVITE_AUDIENCE ? "active" : ""}
                      onClick={() => this.goInviteAudience(campaignData)}
                    >
                      <T text="ContentHeader_Invite_Audience" />
                    </Link>
                  : */}
                <Link
                  inline
                  className={
                    location.pathname === URL_CAMPAIGN_RECOPILATOR
                    || location.pathname === URL_CAMPAIGN_BUY_AUDIENCE
                    ? "active" : ""}
                  onClick={() => this.goRecopilator(campaignData)}
                >
                  <T text="ContentHeader_Get_Responses" />
                </Link>
                <i className="icon icon-right-open-1"></i>
                <Link
                  inline
                  className={
                    location.pathname === URL_CAMPAIGN_METRICS 
                    || location.pathname === URL_CAMPAIGN_METRICS 
                    || location.pathname === URL_CAMPAIGN_PAY
                    ? "active" : ""}
                  onClick={() => this.goMetrics(campaignData)}
                >
                  <T text="ContentHeader_Get_Results" />
                </Link>
              </div>
            </div>
            {haveDemo && (
              <div className={showDemoData ? "content-header-demo show-demo-data" : "content-header-demo"}>
                <div className="btn-icon btn-toggle-media-preview">
                  <div className="label"><T text="ContentHeader_Enable_Demo" /></div>
                  <div className={toggleClass} onClick={ !loadingMetrics ? () => onClickToggleDemoData() : undefined }>
                    <div className="handle"></div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      );
    }

    return false;
  }
}

export default compose(
  withTranslation('translations', { withRef: true }),
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(ContentHeader);
