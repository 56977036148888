import React from 'react';
import { connect } from 'react-redux';
import Link from '../components/Link';
import { clearNotification } from '../reducers/notifications';

import T from '../components/Translate';

import '../styles/components/Notifications.scss';

const mapStateToProps = (state, ownProps) => ({
  showNotification: state.notifications.showNotification,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  clearNotification: val => dispatch(clearNotification(val)),
  dispatch,
})
class Notifications extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      visible: false,
      notification: undefined
    }
  }

  componentDidUpdate() {
    const { showNotification, clearNotification } = this.props;
    const notification = showNotification;

    if (showNotification) {
      this.props.dispatch(clearNotification()).then(()=>{
        this.show(notification);
      })
    }
  }

  show(notification) {
    this.setState({ visible: true, notification });

    let waitTime = 8000;
    let autoClose = true;

    if (
      notification === "userExist" ||
      notification === "filmDurationExceeded" ||
      notification === "videoDimensionsExceeded" ||
      notification === "videoDurationExceeded" ||
      notification === "videoSizeExceeded" ||
      notification === "invalidFile" ||
      notification === "filmSizeExceeded" ||
      notification === "audioDurationExceeded" ||
      notification === "audioSizeExceeded" ||
      notification === "imageDimensionsExceeded" ||
      notification === "imageSizeExceeded" ||
      notification === "testEmptyWarning" ||
      notification === "processingTest"
    ) {
      // autoClose = false;
      waitTime = 10000;
    }

    if (autoClose) {
      setTimeout(() => {
        this.setState({ visible: false });
      }, waitTime)
    }
  }

  renderNotificationText() {
    const { notification } = this.state;

    switch (notification) {
      case "testCreated": {
        return <p><T text="Notifications_Created_Test" /></p>
      }
      case "changesApplied": {
        return <p><T text="Notifications_Changes_Applied" /></p>
      }
      case "invalidData": {
        return <p><T text="Notifications_Check_Data" /></p>
      }
      case "invalidPlan": {
        return <p><T text="Notifications_Ivalid_Test_Plan" /></p>
      }
      case "invalidEmail": {
        return <p><T text="Notifications_Incorrect_Email" /></p>
      }
      case "connectionError": {
        return <p><T text="Notifications_Conexion_Error" /></p>
      }
      case "genericError": {
        return <p><T text="Notifications_Unexpected_Error" /></p>
      }
      case "invalidCode": {
        return <p><T text="Notifications_Invalid_Code" /></p>
      }
      case "invalidToken": {
        return <p><T text="Notifications_Session_Expired" /></p>
      }
      case "invalidLoginData": {
        return <p><T text="Notifications_Incorrect_Credentials" /></p>
      }
      case "emailNotVerified": {
        return <p><T text="Notifications_Check_Email" /></p>
      }
      case "earlyAccessCodeInvalid": {
        return <p><T text="Notifications_Invalid_Code" /></p>
      }
      case "emailTaken": {
        return <p><T text="Notifications_Registered_Email" /></p>
      }
      case "copyClipboard": {
        return <p><T text="Notifications_Link_Clipboard" /></p>
      }
      case "emailValidated": {
        return <p><T text="Notifications_Account_Activated" /></p>
      }
      case "passwordChanged": {
        return <p><T text="Notifications_Password_Updated" /></p>
      }
      case "newPasswordSended": {
        return <p><T text="Notifications_Check_Inbox" /></p>
      }
      case "addedWaitingList": {
        return <p><T text="Notifications_Contact_ASAP" /></p>
      }
      case "welcomeNewUser": {
        return <p><T text="Notifications_Create_Account" /></p>
      }
      case "needPublish": {
        return <p><T text="Notifications_Publish_Before_Proceed" /></p>
      }
      case "addedCredit": {
        return <p><T text="Notifications_Added_Credit" /></p>
      }
      case "userExist": {
        return <p><T text="Notifications_User_Exists" /></p>
      }
      case "emptyModules": {
        return <p><T text="Notifications_Empty_Modules" /></p>
      }
      case "videoDimensionsExceeded": {
        return (
          <div>
            <p><T text="Notifications_Video_Resolution" /></p>
          </div>
        )
      }
      case "filmDurationExceeded": {
        return (
          <div>
            <p><T text="Notifications_Film_Duration" /></p>
          </div>
        )
      }
      case "videoDurationExceeded": {
        return (
          <div>
            <p><T text="Notifications_Video_Duration" /></p>
          </div>
        )
      }
      case "videoSizeExceeded": {
        return (
          <div>
            <p><T text="Notifications_Video_Size" /></p>
          </div>
        )
      }
      case "invalidFile": {
        return <p><T text="Notifications_Video_Invalid_Format" /></p>
      }
      case "filmSizeExceeded": {
        return (
          <div>
            <p><T text="Notifications_Film_Size" /></p>
          </div> 
        )
      }
      case "audioDurationExceeded": {
        return (
          <div>
            <p><T text="Notifications_Audio_Duration" /></p>
          </div>
        )
      }
      case "audioSizeExceeded": {
        return (
          <div>
            <p><T text="Notifications_Audio_Size" /></p>
          </div>
        )
      }
      case "imageDimensionsExceeded": {
        return (
          <div>
            <p><T text="Notifications_Image_Resolution" /></p>
          </div>  
        )
      }
      case "imageSizeExceeded": {
        return (
          <div>
            <p><T text="Notifications_Image_Size" /></p>
          </div>
        )
      }
      case "invalidCodeError": {
        return (
          <div>
            <p>The promotional code is not valid</p>
          </div>
        )
      }
      case "planChangedCorretly": {
        return <p>Tu plan ha sido cambiado correctamente</p>
      }
      case "audiencePurchasedSuccesfully": {
        return <p><T text="Notifications_BuyAudience_Payment_Succesfull" /></p>
      }
      case "audiencePurchasedError": {
        return <p><T text="Notifications_BuyAudience_Payment_Error" /></p>
      }
      case "inviteAudienceAddEmailsSuccesfully": {
        return <p><T text="Notifications_InviteAudience_Emails_Added_Successfull" /></p>
      }
      case "inviteAudienceAddEmailsInvalid": {
        return <p><T text="Notifications_InviteAudience_Emails_Added_Invalid" /></p>
      }
      case "inviteAudienceAddEmailsEmpty": {
        return <p><T text="Notifications_InviteAudience_Emails_Added_Empty" /></p>
      }
      case "inviteAudienceAddEmailsMaxTestersReached": {
        return <p><T text="Notifications_InviteAudience_Emails_Added_Max_Testers_Reached" /></p>
      }
      case "inviteAudienceSendEmailsFilmSuccesfully": {
        return <p><T text="Notifications_InviteAudience_Emails_Sent_Film_Successfull" /></p>
      }
      case "inviteAudienceSendEmailsSuccesfully": {
        return <p><T text="Notifications_InviteAudience_Emails_Sent_Successfull" /></p>
      }
      case "inviteAudienceSendEmailsMaxFilmTestReached": {
        return <p><T text="Notifications_InviteAudience_Emails_Sent_Max_FilmTest_Reached" /></p>
      }
      case "inviteAudienceDeleteTestersSuccesfully": {
        return <p><T text="Notifications_InviteAudience_Testers_Deleted_Successfull" /></p>
      }
      case "linkDeletedSuccessfully": {
        return <p><T text="Notifications_Recopilator_Link_Deleted_Successfully" /></p>
      }
      case "defineAudienceSegmentDeletedSuccessfully": {
        return <p><T text="Notifications_DefineAudience_Segment_Deleted_Successfully" /></p>
      }
      case "defineAudienceAddSegmentMaxSegmentsReached": {
        return <p><T text="Notifications_DefineAudience_Segment_Added_Max_Segments_Reached" /></p>
      }
      case "accountDeletedSuccessfully": {
        return <p><T text="Notifications_Account_Deleted_Successfully" /></p>
      }
      case "paymentSuccesfully": {
        return <p><T text="Notifications_ReviewTestAndPay_Payment_Succesfull" /></p>
      }
      case "paymentError": {
        return <p><T text="Notifications_ReviewTestAndPay_Payment_Error" /></p>
      }
      case "testEmptyWarning": {
        return <p><T text="Notifications_PublishTest_Test_Empty" /></p>
      }
      case "processingTest": {
        return <p><T text="Notifications_PublishTest_Processing" /></p>
      }
      default:
        return ""
    }
  }

  render() {
    const { showNotification } = this.props;
    const { visible, notification } = this.state;
    let componentClass = visible ? "notification visible" : "notification";

    if (
      notification === "copyClipboard" ||
      notification === "emailValidated" ||
      notification === "passwordChanged" ||
      notification === "newPasswordSended" ||
      notification === "addedWaitingList" ||
      notification === "welcomeNewUser" ||
      notification === "addedCredit" ||
      notification === "planChangedCorretly" ||
      notification === "changesApplied" ||
      notification === "testCreated" ||
      notification === "audiencePurchasedSuccesfully" ||
      notification === "inviteAudienceAddEmailsSuccesfully" ||
      notification === "inviteAudienceSendEmailsSuccesfully" ||
      notification === "inviteAudienceSendEmailsFilmSuccesfully" ||
      notification === "inviteAudienceDeleteTestersSuccesfully" ||
      notification === "linkDeletedSuccessfully" ||
      notification === "defineAudienceSegmentDeletedSuccessfully" ||
      notification === "accountDeletedSuccessfully" ||
      notification === "paymentSuccesfully"
    ) {
      componentClass = `${componentClass} success`;
    } else if (
      notification === "userExist" ||
      notification === "filmDurationExceeded" ||
      notification === "videoDimensionsExceeded" ||
      notification === "videoDurationExceeded" ||
      notification === "videoSizeExceeded" ||
      notification === "invalidFile" ||
      notification === "filmSizeExceeded" ||
      notification === "audioDurationExceeded" ||
      notification === "audioSizeExceeded" ||
      notification === "imageDimensionsExceeded" ||
      notification === "imageSizeExceeded" ||
      notification === "testEmptyWarning" ||
      notification === "processingTest"
    ) {
      componentClass = `${componentClass} information`;
    }

    return (
      <div className={componentClass}>
        <div className="hide">{showNotification}</div>
        {this.renderNotificationText()}
        <Link inline onClick={() => this.setState({ visible: false })}><i className="icon icon-cancel-7"></i></Link>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)