import React from 'react';
import { connect } from 'react-redux';
import Scrollbar from 'smooth-scrollbar';
import { SounditiAuth } from '@sounditi/ft2-api';

import T from '../components/Translate';
import Link from '../components/Link';

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({})

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfigurationMenu: true,
      showResultsMenu: true
    };
  }

  loggout() {
    localStorage.clear();
    const auth = new SounditiAuth();
    auth.logOut();
    window.location.reload();
  }

  componentDidMount() {
    const { campaign } = this.props;

    if (document.querySelector('#sidebar-scrollview'))
      Scrollbar.init(document.querySelector('#sidebar-scrollview'), {
        alwaysShowTracks: true
      });

    if (
      campaign && (
        this.isPatchActive(`/tests/configuration?id=${campaign.campaignId}`) ||
        this.isPatchActive(`/tests/design?id=${campaign.campaignId}`) ||
        this.isPatchActive(`/tests/modules?id=${campaign.campaignId}`) ||
        this.isPatchActive(`/tests/preview-and-publish?id=${campaign.campaignId}`))
    ) {
      this.setState({ showConfigurationMenu: true });
    }

    if (
      campaign && (
        this.isPatchActive(`/tests/metrics?id=${campaign.campaignId}`) ||
        this.isPatchActive(`/tests/audience?id=${campaign.campaignId}`))
    ) {
      this.setState({ showResultsMenu: true });
    }
  }

  isPatchActive(to) {
    const pathname = window.location.pathname;
    const toParsed = to ? to.split("?")[0] : "";
    return pathname === toParsed || pathname === `${toParsed}/`;
  }

  toggleConfigurationMenu() {
    const { showConfigurationMenu } = this.state;
    // this.setState({ showConfigurationMenu: !showConfigurationMenu });
  }

  toggleResultsMenu() {
    const { showResultsMenu } = this.state;
    // this.setState({ showResultsMenu: !showResultsMenu });
  }

  render() {
    const { showConfigurationMenu, showResultsMenu } = this.state;
    const { campaign } = this.props;

    const classConfigurationMenu = showConfigurationMenu ? "submenu" : "submenu fold";
    const classResultsMenu = showResultsMenu ? "submenu" : "submenu fold";

    {/*
      <Link sidebar to={`/campaigns/configuration?id=${campaign.campaignId}`} icon="icon-cog-6">General<span className="step">1</span></Link>
      <Link sidebar to={`/campaigns/design?id=${campaign.campaignId}`} icon="icon-brush-2">Look & Feel<span className="step">2</span></Link>
      <Link sidebar to={`/campaigns/modules?id=${campaign.campaignId}`} icon="icon-popup-3">Modules<span className="step">3</span></Link>
      <Link sidebar to={`/campaigns/preview-and-publish?id=${campaign.campaignId}`} icon="icon-rocket-1">Preview & Publish<span className="step">4</span></Link>
      <Link sidebar to={`/campaigns/audience?id=${campaign.campaignId}`} icon="icon-users-2">Audience</Link>
      <Link sidebar to={`/campaigns/metrics?id=${campaign.campaignId}`} icon="icon-chart-bar-4">Metrics</Link>
    */}

    if (campaign)
      return (
        <div className="sidebar">
          <div className="logo-wrapper">
            <img className="logo" src="/assets/img/logo.png" alt="Sounditi logo" />
          </div>
          <div className="sidebar-content" id="sidebar-scrollview">
            <div className="menu">
              <div className="submenu">
                <Link sidebar to="/tests" icon="icon-home-5"><T text="SideBar_Tests" /></Link>
                <div className="menu">
                  <div className={classConfigurationMenu}>
                    <Link sidebar onClick={() => this.toggleConfigurationMenu()} icon="icon-down-open-3"><T text="Breadcrumb_Configuration" /></Link>
                    <div className="menu group">
                      <Link sidebar to={`/tests/configuration?id=${campaign.campaignId}`} step="1"><T text="SideBar_General" /></Link>
                      <Link sidebar to={`/tests/design?id=${campaign.campaignId}`} step="2"><T text="SideBar_Look_Feel" /></Link>
                      <Link sidebar to={`/tests/modules?id=${campaign.campaignId}`} step="3"><T text="SideBar_Modules" /></Link>
                      <Link sidebar to={`/tests/audience?id=${campaign.campaignId}`} step="4"><T text="SideBar_Audience" /></Link>
                      <Link sidebar to={`/tests/preview-and-publish?id=${campaign.campaignId}`} step="5"><T text="SideBar_Preview_Publish" /></Link>
                    </div>
                  </div>
                </div>
                <div className="menu">
                  <div className={classResultsMenu}>
                    <Link sidebar onClick={() => this.toggleResultsMenu()} icon="icon-down-open-3"><T text="SideBar_Results" /></Link>
                    <div className="menu group">
                      <Link sidebar to={`/tests/status?id=${campaign.campaignId}`} step="6"><T text="SideBar_Status" /></Link>
                      <Link sidebar to={`/tests/metrics?id=${campaign.campaignId}`} step="7"><T text="SideBar_Metrics" /></Link>
                    </div>
                  </div>
                </div>
              </div>
              <Link sidebar to="/account" icon="icon-user-7"><T text="SideBar_User" /></Link>
              {/* <Link className="disabled" sidebar to="/subscription" icon="icon-flash-3"><T text="SideBar_Subscription" /><span className="soon"><T text="SideBar_Span_Soon" /></span></Link> */}
              {/* <Link className="disabled" sidebar to="/billings" icon="icon-archive-1"><T text="SideBar_Billing" /><span className="soon"><T text="SideBar_Span_Soon" /></span></Link> */}
              <Link sidebar to="/support-and-help" icon="icon-help-3"><T text="SideBar_Support_Help" /></Link>
            </div>
          </div>
          <div className="sidebar-bottom">
            <div className="menu">
              <Link sidebar icon="icon-logout-3" onClick={() => { this.loggout() }}><T text="SideBar_Close_Session" /></Link>
            </div>
          </div>
        </div>
      )

    return (
      <div className="sidebar">
        <div className="logo-wrapper">
          <img className="logo" src="/assets/img/logo.png" alt="Sounditi logo" />
        </div>
        <div className="sidebar-content" id="sidebar-scrollview">
          <div className="menu">
            <Link sidebar to="/tests" icon="icon-home-5"><T text="SideBar_Tests" /></Link>
            <Link sidebar to="/account" icon="icon-user-7"><T text="SideBar_User" /></Link>
            {/* <Link className="disabled" sidebar to="/subscription" icon="icon-flash-3"><T text="SideBar_Subscription" /><span className="soon"><T text="SideBar_Span_Soon" /></span></Link> */}
            {/* <Link className="disabled" sidebar to="/billings" icon="icon-archive-1"><T text="SideBar_Billing" /><span className="soon"><T text="SideBar_Span_Soon" /></span></Link> */}
            <Link sidebar to="/support-and-help" icon="icon-help-3"><T text="SideBar_Support_Help" /></Link>
          </div>
        </div>
        <div className="sidebar-bottom">
          <div className="menu">
            <Link sidebar icon="icon-logout-3" onClick={() => { this.loggout() }}><T text="SideBar_Close_Session" /></Link>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)