import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { FileAccess } from '@sounditi/ft2-api';
import { Spinner } from 'react-activity';
import { withTranslation } from 'react-i18next';
import { Circle } from 'rc-progress';
import ReactTooltip from 'react-tooltip';

import T from '../Translate';
import Link from '../Link';
import Select from '../Select';
import DraggableList from '../DraggableList';
import ModuleTypeInfo from '../ModuleTypeInfo';

import { setDisableSaveButton } from '../../reducers/navigation';

import { URL_ENVIRONMENT } from '../../config/global';

import { generateModuleID } from '../../utils/global';

const mapStateToProps = (state, ownProps) => ({
  loadingModule: state.navigation.loadingModule,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setDisableSaveButton: val => dispatch(setDisableSaveButton(val)),
})

class VideoModulePanel extends React.Component {
  _mount = true;
  _defaultState = {
    loading: false,
    tech: "recognition",
    randomize: "randomize",
    videos: [],
    name: "",
    moduleData: [],
    dataValidated: true,
    showScrollbar: false,
    invalid: false,
    uploadedPercent: 0,
    loadingTextAnimation: "",
    cancelledFiles: [],
    currentUniqueFileName: '',
    showCancelButton: true,
  };
  _scrollbar = null;

  constructor(props) {
    super(props);
    this.state = this._defaultState;
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState) {
    const { loading } = this.state;
    const { setDisableSaveButton } = this.props;
    if (prevState.loading !== loading) {
      setDisableSaveButton(loading);
    }
  }

  componentWillUnmount() {
    this._mount = false;
    setDisableSaveButton(false);
  }

  onInputChange(val) {
    const { onInputChange } = this.props;

    if (onInputChange)
      onInputChange(val);

    if (this._mount) {
      this.setState(val, () => {
        if (this._mount) {
          this.validateData();

          if (this.refs.phonePreview)
            setTimeout(() => {
              if (this._mount) {
                // this.refs.phonePreview.loadData();
              }
            }, 200);
        }
      });
    }
  }

  startTextAnimation() {
    this._animationInterval = setInterval(() => {
      let loadingTextAnimation = this.state.loadingTextAnimation + ".";

      if (loadingTextAnimation.length > 3)
        loadingTextAnimation = ""

      this.setState({ loadingTextAnimation });
    }, 500);
  }

  stopTextAnimation() {
    this.setState({ loading: false });
    clearInterval(this._animationInterval);
    this._animationInterval = undefined;
  }

  resetData() {
    if (this._mount) {
      this.setState(this._defaultState, () => {
        this.refs.tech.refresh();
        this.refs.randomize.refresh();
        this.refs.videos.refresh();
      });
    }
  }

  stop() {
    // this.refs.phonePreview.stop();
  }

  validateData() {
    if (this._mount) {
      this.setState({ dataValidated: true });
    }
  }

  setVideoInvalid() {
    this.setState({ invalid: true });
    setTimeout(() => {
      this.setState({ invalid: false });
    }, 3000)
  }

  loadData(moduleData) {
    const { onShow } = this.props;

    this.resetData();

    if (this._mount && moduleData) {
      this.setState({ moduleData });

      // console.log(moduleData);

      if (moduleData.name)
        this.setState({ name: moduleData.name });

      if (moduleData.tech)
        this.setState({ tech: moduleData.tech }, () => this.refs.tech.refresh());

      if (moduleData.randomize)
        this.setState({ randomize: moduleData.randomize }, () => this.refs.randomize.refresh());

      if (moduleData.videos)
        this.setState({ videos: moduleData.videos }, () => {
          if (this._mount) {
            this.refs.videos.refresh();
            // this.refs.phonePreview.loadData();
          }
        });

      if (onShow)
        onShow();
    }
  }

  show(moduleData) {
    this.loadData(moduleData);
    this.fitMockupPhoneSize();
  }

  getScaleToFitParent(el, margin = 0) {
    if (!el || !el.parentElement)
      return 1;

    const parentSize = {
      height: el.parentElement.clientHeight - margin * 2
    };

    return Math.min(parentSize.height / el.clientHeight);
  }

  fitMockupPhoneSize() {
    if (
      this._mount &&
      document.querySelector('.phone-mockup-size') &&
      document.querySelector('.phone-preview.text')
    ) {
      document.querySelector('.phone-mockup-size').style.opacity = "0";

      setTimeout(() => {
        if (this._mount) {
          document.querySelector('.phone-mockup-size').style.opacity = "1";

          const element = document.querySelector('.phone-preview.video');
          const margin = 30;
          let scale = this.getScaleToFitParent(element, margin);

          if (scale > 1)
            scale = 1;

          if (element) {
            element.style.transformOrigin = "0 0";
            element.style.transform = `scale(${scale})`;
          }

          setTimeout(() => {
            if (this._mount) {
              const showScrollbar = this._scrollbar.size.container.height < this._scrollbar.size.content.height;
              this.setState({ showScrollbar });
            }
          }, 500);
        }
      }, 10);
    }
  }

  async uploadImage(event) {
    const { showNotification, campaignData } = this.props;
    const { videos } = this.state;
    const { campaignId } = campaignData;

    try {
      if (this._mount) {
        this.setState({ loading: true, uploadedPercent: 0, showCancelButton: true });
        this.startTextAnimation();
      }

      const rawFile = event.target.files[0];
      const fileName = rawFile.name;

      // const fileSize = rawFile.size;
      const fileType = rawFile.type; /* image/png image/jpg image/jpeg */
      const fileBlob = rawFile.slice(0, rawFile.size, rawFile.type);

      const uniqueID = generateModuleID();
      const uniqueFileName = `${campaignId}-${uniqueID}`;
      const uniqueFile = new File([fileBlob], uniqueFileName, {type: fileType});
      this.setState({ currentUniqueFileName: uniqueFileName });

      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = async () => {
        // console.log("onloadedmetadata");
        window.URL.revokeObjectURL(video.src);
        const fileDuration = video.duration;

        if (fileDuration > 300) {
          showNotification("videoDurationExceeded");
          if (this._mount) {
            this.fileInput.value = '';
            this.setState({ loading: false });
          }
          return false;
        } else if (rawFile.size > 524288000) {
          showNotification("videoSizeExceeded");
          if (this._mount) {
            this.fileInput.value = '';
            this.setState({ loading: false });
          }
          return false;
        }

        // if (video.videoHeight > 1280 || video.videoWidth > 1280) {
        //   showNotification("videoDimensionsExceeded");
        //   if (this._mount) {
        //     this.fileInput.value = '';
        //     this.setState({ loading: false });
        //   }
        //   return false;
        // } else if (fileDuration > 720) {
        //   showNotification("videoDurationExceeded");
        //   if (this._mount) {
        //     this.fileInput.value = '';
        //     this.setState({ loading: false });
        //   }
        //   return false;
        // }
        // else if (rawFile.size > 220000000) {
        //   showNotification("videoSizeExceeded");
        //   if (this._mount) {
        //     this.fileInput.value = '';
        //     this.setState({ loading: false });
        //   }
        //   return false;
        // }

        const fileAccess = new FileAccess();

        await fileAccess.upload(uniqueFile, progress => {
          const isUploadCancelled = this.isUploadCancelled(uniqueFileName);
          if (!isUploadCancelled) {
            const percentage = parseInt((progress.loadedBytes * 100) / rawFile.size);
            // console.log("Uploaded: " + percentage + "%");
            this.setState({ uploadedPercent: percentage });
            if (percentage > 99) {
              this.setState({ showCancelButton: false });
            }
          }
        });

        const isUploadCancelled = this.isUploadCancelled(uniqueFileName);
        if (!isUploadCancelled) {
          const userFiles = await fileAccess.listFiles();
          const isFileFound = userFiles.blobs.filter(blob => blob.name === uniqueFileName).length > 0;

          if (isFileFound) {
            videos.push({
              name: fileName,
              fileName: uniqueFileName,
              duration: fileDuration
            });

            this.onInputChange({ videos });
            if (this._mount) {
              this.refs.videos.refresh()
            }
          }

          if (this._mount) {
            this.fileInput.value = '';
            // this.refs.phonePreview.loadData();
            this.setState({ loading: false });
            this.stopTextAnimation();
          }
        }
      }

      video.src = URL.createObjectURL(rawFile);
    } catch(error) {
      if (this._mount) {
        this.setState({ loading: false });
        this.stopTextAnimation();
      }
      showNotification("genericError");
    }
  }

  getPreview() {
    const { campaignData } = this.props;
    const { moduleData } = this.state;
    const { campaignId, userId } = campaignData;

    const lang = localStorage.getItem('lang') || "en";

    const jwt = require('jwt-simple');
    const payload = {
      "sub": userId,
      "name": "",
      "iat": 0,
      "iss": "",
      "aud": "",
      "exp": 0,
      "forcePreview": true,
      "campaignId": campaignId
    };
    const secret = "super-tester-secret"
    const token = jwt.encode(payload, secret);

    return `${URL_ENVIRONMENT}?id=${campaignId}&token=${token}&s=${moduleData.id}&l=${lang}`;
  }

  isUploadCancelled(idFile) {
    const { cancelledFiles } = this.state;
    return cancelledFiles.includes(idFile);
  }

  cancelUpload(idFile) {
    const { cancelledFiles } = this.state;

    if (this._mount) {
      this.fileInput.value = '';
      this.setState({cancelledFiles: cancelledFiles.concat(idFile), loading: false });
      this.stopTextAnimation();
    }
  }

  render() {
    const { name, tech, showScrollbar, randomize, videos, loading, invalid, uploadedPercent, loadingTextAnimation, currentUniqueFileName, showCancelButton } = this.state;
    const { onCancel, campaignData, t, goPreview, isCampaignPaid } = this.props;

    const maxStimulus = campaignData?.features?.numberOfVideoStimulusPerModule || 3;
    const addButtonDisabled = maxStimulus < 0 ? false : videos.length >= maxStimulus;
    const addButtonTooltipDisabled = maxStimulus < 0 ? true : videos.length < maxStimulus;

    let videoClass;
    if (invalid)
      videoClass = 'error-color';

    let moduleName = t("Panels_Audio_Capital_Letter");

    if (name === "Video"){
      moduleName = t("Panels_Video_Capital_Letter");
    } else if (name === "Image"){
      moduleName = t("Panels_Image_Capital_Letter");
    } else if (name === "Q&A"){
      moduleName = t("Panels_QA_Capital_Letter");
    }

    return (
      <div className="panel panel-video-screen">
        <ReactTooltip place="bottom" effect="solid" backgroundColor="#ccc" />
        {loading && (
          <div className="loading-cover">
            <div className="cover"></div>
            <div className="loading-container">
              <div className="progress-text">
                <div className="loading-text">
                  <T text="Panels_Video_Uploading_File" />
                  <p className="animated">{loadingTextAnimation}</p>
                </div>
                <Circle percent={uploadedPercent > 1 ? uploadedPercent : 1} strokeWidth="4" trailWidth="4" strokeColor="#00C7E7" />
              </div>
              <div className={showCancelButton ? "cancel-button" : "cancel-button cancel-button-hidden"}>
                <Link secondary inline onClick={() => this.cancelUpload(currentUniqueFileName) }>
                  <T text="Panels_Btn_Cancel_Upload" />
                </Link>
              </div>
            </div>
          </div>
        )}
        <div className="row flex">
          <div className="col">
            <h3 className="panel-title">
              <Link secondary className="no-border no-padding" onClick={() => { onCancel() }}>
                <i className="icon icon-right-open-1"></i>
              </Link>
              {moduleName}

              <div className="right">
                <Link secondary disabled={videos.length < 1} onClick={() => goPreview(this.getPreview())}>
                  <i className="icon icon-play-4"></i>
                  <T text="Panels_Btn_Preview" />
                </Link>
              </div>
            </h3>
            <div className="panel-inputs" id="panel-video-scrollview">
              <Select
                name="tech"
                label={t("Panels_Technology")}
                value={tech}
                ref="tech"
                onChange={val => this.onInputChange(val)}
                options={[
                  { "value": "recognition", "label": t("Panels_Facial_Recognition"), "disabled": !campaignData.features.recognitionModules },
                  { "value": "choose", "label": t("Panels_Choose_One"), "disabled": !campaignData.features.chooseModules },
                  { "value": "play", "label": t("Panels_Just_Play"), "disabled": !campaignData.features.playModules }
                ]}
                disabled={isCampaignPaid}
              />
              <ModuleTypeInfo type={tech} />

              <div className="field">
                <label htmlFor="generalBackgroundType" className={videoClass}><T text="VideoModulePanel_Videos" /></label>
                <Select
                  small
                  name="randomize"
                  value={randomize}
                  ref="randomize"
                  onChange={val => this.onInputChange(val)}
                  options={[
                    { "value": true, "label": t("Panels_Randomize_Order") },
                    { "value": false, "label": t("Panels_Play_Order") },
                  ]}
                  disabled={isCampaignPaid}
                />
                <DraggableList
                  video
                  value={videos}
                  name="videos"
                  ref="videos"
                  onChange={val => this.onInputChange(val)}
                  disabled={isCampaignPaid}
                />

                <input
                  className="hidden"
                  name="file"
                  type="file"
                  onChange={file => this.uploadImage(file)}
                  ref={fileInput => this.fileInput = fileInput}
                  accept="video/*"
                />

                <div className={`right-content ${isCampaignPaid ? "hidden" : ""}`} data-tip={t('VideoModulePanel_Add_Video_Button_Tooltip').replace("[var1]", maxStimulus)} data-place="left" data-tip-disable={addButtonTooltipDisabled}>
                  <Link main className="full-width" disabled={addButtonDisabled} onClick={() => this.fileInput.click()}>
                    <i className="icon icon-plus-1"></i>
                    <T text="VideoModulePanel_Add_Video_Button" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation('translations', { withRef: true }),
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(VideoModulePanel);