import React, { useEffect } from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { Spinner } from 'react-activity';
import { UserAccess, CampaignAccess, FileAccess, SounditiAuth } from '@sounditi/ft2-api';
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next';

import { setSession, setTestsData } from '../../reducers/user';
import { showNotification } from '../../reducers/notifications';
import { setRedirect } from '../../reducers/navigation';

import T from '../../components/Translate';
import Link from '../../components/Link';
import Input from '../../components/Input';
import PolicyCheck from '../../components/PolicyCheck';
import { Circle } from 'rc-progress';

import { generateModuleID } from '../../utils/global';
import { postActionGTMRegister } from '../../utils/events';
import { newCampaignTemplateExpress } from '../../config/campaignTemplates';
import { newCampaignTemplateEN } from '../../config/campaignTemplates';
import { URL_CAMPAIGNS, URL_WIZARD_LINK } from '../../config/urls';

const uuidv4 = require("uuid/v4");

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth.api,
  tokenData: state.user.tokenData,
  userData: state.user.userData,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setSession: val => dispatch(setSession(val)),
  showNotification: val => dispatch(showNotification(val)),
  setRedirect: val => dispatch(setRedirect(val)),
  setTestsData: val => dispatch(setTestsData(val)),
  dispatch,
})

class WizardFile extends React.Component {
  _mount = true;
  _loadingInterval = undefined;
  _animationInterval = undefined;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadedPercent: 1,
      loadingTextAnimation: "",
      showProgress: false,
      iniatedLead: false,
      files: [],
      filesType: undefined,
      campaignData: {}
    };
  }

  componentDidMount() {
    const { userData } = this.props;
    if (userData) {
      const userID = userData.id || undefined;

      if (userID) {
        window.Froged('set', {
          userID,
          hasUsedWizard: true
        });
      }
    }

    // this.initCampaign();
    document.getElementById('body').className='wizard'
  }

  isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  loggout() {
    localStorage.clear();
    const auth = new SounditiAuth();
    auth.logOut();
    window.Froged('logout');
    window.location.reload();
  }

  initLoadedPercent() {
    const duration = 15000;

    this.setState({ loadedPercent: 1, showProgress: true });

    this._loadingInterval = setInterval(() => {
      let loadedPercent = this.state.loadedPercent + 1;

      if (loadedPercent > 100) {
        loadedPercent = 0;
      }

      this.setState({ loadedPercent });
    }, duration / 100);

    this._animationInterval = setInterval(() => {
      let loadingTextAnimation = this.state.loadingTextAnimation + ".";

      if (loadingTextAnimation.length > 3)
        loadingTextAnimation = ""

      this.setState({ loadingTextAnimation });
    }, 500);
  }

  stopLoadedPercent() {
    this.setState({ loadedPercent: 100, showProgress: false });
    clearInterval(this._loadingInterval);
    clearInterval(this._animationInterval);
    this._loadingInterval = undefined;
  }

  async initCampaign() {
    if (this._mount) {
      const { setSession, auth, dispatch, t, setTestsData } = this.props;
      const { campaignData } = this.state;

      if (this.isEmpty(campaignData)) {
        const email = `${uuidv4().replaceAll("-", "")}@alyze.lead`;
        const password = "1234";
        const signupCode = "";
        const name = "";

        try {
          const userAccess = new UserAccess();
          await userAccess.singUp(name, email, password, signupCode);

          const hydratedLogin = await userAccess.hydratedLogin(email, password, auth);
          hydratedLogin.user = await userAccess.getUserData();
          setSession(hydratedLogin)

          const campaignTemplates = newCampaignTemplateExpress;
          campaignTemplates.name = "Test autogenerado";
          campaignTemplates.lang = "es";

          const campaignAccess = new CampaignAccess();
          const campaignData = await campaignAccess.createCampaign(campaignTemplates);

          const testsData = [];
          testsData.push(campaignData);

          setTestsData(testsData);

          this.setState({ campaignData });

          return campaignData;
        } catch(error) {
          if (this._mount) {
            this.setState({ loading: false });
            this.loggout();
          }
          showNotification("genericError");
        }
      } else {
        return campaignData;
      }
    }
  }

  async uploadImage(event) {
    if (this._mount) {
      event.persist();

      const { showNotification } = this.props;
      const { files } = this.state;

      try {
        this.initLoadedPercent();

        const campaignData = await this.initCampaign();

        const rawFile = event.target.files[0];
        const fileName = rawFile.name;
        // const fileSize = rawFile.size;
        const fileType = rawFile.type; /* image/png image/jpg image/jpeg */
        const fileBlob = rawFile.slice(0, rawFile.size, rawFile.type);

        const uniqueID = generateModuleID();
        const uniqueFileName = `${campaignData.campaignId}-${uniqueID}`;
        const uniqueFile = new File([fileBlob], uniqueFileName, {type: fileType});

        const image = document.createElement('img');

        image.onload = async () => {
          if (image.height > 6000 || image.width > 6000) {
            showNotification("imageDimensionsExceeded");
            if (this._mount) {
              this.fileInputImage.value = '';
              this.setState({ loading: false });
            }
            return false;
          } else if (rawFile.size > 10000000) {
            showNotification("imageSizeExceeded");
            if (this._mount) {
              this.fileInputImage.value = '';
              this.setState({ loading: false });
            }
            return false;
          }

          const fileAccess = new FileAccess();
          await fileAccess.upload(uniqueFile);

          const userFiles = await fileAccess.listFiles();
          const isFileFound = userFiles.blobs.filter(blob => blob.name === uniqueFileName).length > 0;

          if (isFileFound) {
            files.push({
              name: fileName,
              fileName: uniqueFileName
            });

            if (campaignData.data.modules.campaignModules.length < 1) {
              campaignData.data.modules.campaignModules.push({
                id: generateModuleID(),
                images: files,
                duration: 6,
                name: "Image",
                randomize: "true",
                tech: "recognition",
                type: "image",
                icon: "icon-picture-1",
                img: "card-image.png",
              });
            } else {
              campaignData.data.modules.campaignModules[0].images = files;
            }

            const campaignAccess = new CampaignAccess();
            await campaignAccess.updateCampaign(campaignData.campaignId, campaignData.data);
          }

          this.fileInputImage.value = '';
          if (this._mount) {
            this.setState({ loading: false, files, filesType: "image" });
            this.stopLoadedPercent();
          }
        }
        image.src = URL.createObjectURL(rawFile);
      } catch(error) {
        if (this._mount) {
          this.setState({ loading: false });
          this.stopLoadedPercent();
          this.loggout();
        }
        showNotification("genericError");
      }
    }
  }

  async uploadVideo(event) {
    if (this._mount) {
      event.persist();

      const { showNotification } = this.props;
      const { files } = this.state;

      try {
        this.initLoadedPercent();

        const campaignData = await this.initCampaign();

        const rawFile = event.target.files[0];
        const fileName = rawFile.name;

        // const fileSize = rawFile.size;
        const fileType = rawFile.type; /* image/png image/jpg image/jpeg */
        const fileBlob = rawFile.slice(0, rawFile.size, rawFile.type);

        const uniqueID = generateModuleID();
        const uniqueFileName = `${campaignData.campaignId}-${uniqueID}`;
        const uniqueFile = new File([fileBlob], uniqueFileName, {type: fileType});

        const video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = async () => {
          window.URL.revokeObjectURL(video.src);
          const fileDuration = video.duration;

          if (video.videoHeight > 1280 || video.videoWidth > 1280) {
            showNotification("videoDimensionsExceeded");
            if (this._mount) {
              this.fileInputVideo.value = '';
              this.setState({ loading: false });
            }
            return false;
          } else if (fileDuration > 720) {
            showNotification("videoDurationExceeded");
            if (this._mount) {
              this.fileInputVideo.value = '';
              this.setState({ loading: false });
            }
            return false;
          } else if (rawFile.size > 220000000) {
            showNotification("videoSizeExceeded");
            if (this._mount) {
              this.fileInputVideo.value = '';
              this.setState({ loading: false });
            }
            return false;
          }

          const fileAccess = new FileAccess();
          await fileAccess.upload(uniqueFile);

          const userFiles = await fileAccess.listFiles();
          const isFileFound = userFiles.blobs.filter(blob => blob.name === uniqueFileName).length > 0;

          if (isFileFound) {
            files.push({
              name: fileName,
              fileName: uniqueFileName,
              duration: fileDuration
            });

            if (campaignData.data.modules.campaignModules.length < 1) {
              campaignData.data.modules.campaignModules.push({
                id: generateModuleID(),
                videos: files,
                name: "Video",
                randomize: "true",
                tech: "recognition",
                type: "video",
                icon: "icon-video-1",
                img: "card-video.png",
              });
            } else {
              campaignData.data.modules.campaignModules[0].videos = files;
            }

            const campaignAccess = new CampaignAccess();
            await campaignAccess.updateCampaign(campaignData.campaignId, campaignData.data);
          }

          this.fileInputVideo.value = '';
          if (this._mount) {
            this.setState({ loading: false, files, filesType: "video" });
            this.stopLoadedPercent();
          }
        }
        video.src = URL.createObjectURL(rawFile);
      } catch(error) {
        if (this._mount) {
          this.setState({ loading: false });
          this.stopLoadedPercent();
          this.loggout();
        }
        showNotification("genericError");
      }
    }
  }

  goWizardLink() {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_WIZARD_LINK });
  }

  render() {
    const { loading, files, filesType, loadedPercent, showProgress, campaignData, loadingTextAnimation } = this.state;
    const { t } = this.props;

    const renderFiles = files.map(file => {
      return (
        <div className="file" key={file.fileName}>
          <i className={filesType === "video" ? 'icon icon-video' : 'icon icon-picture'}></i>
          {file.name}
        </div>
      )
    });

    return (
      <div className="app lite">
        <Helmet>
          <title>{t('Lite_File_Browser_Title')}</title>
        </Helmet>
        {showProgress && (
          <div className="loading-cover">
            <div className="cover"></div>
            <div>
              {this.isEmpty(campaignData) && (
                <>
                  <div className="loading-text">
                    <T text="WizardFile_Creating_Test" />
                    <p className="animated">{loadingTextAnimation}</p>
                  </div>
                </>
              )}
              {!this.isEmpty(campaignData) && (
                <>
                  <div className="loading-text">
                    <T text="WizardFile_Uploading_File" />
                    <p className="animated">{loadingTextAnimation}</p>
                  </div>
                </>
              )}
              <Circle percent={loadedPercent} strokeWidth="4" trailWidth="4" strokeColor="#00C7E7" />
            </div>
          </div>
        )}
        {loading && (
          <div className="loading-cover">
            <div className="cover"></div>
            <div>
              <Spinner speed={0.8} color="#00C7E7" size={20} />
            </div>
          </div>
        )}
        <div className="lite-topbar">
          <img className="logo" src="../assets/img/main-logo-c.png" alt="Company logo" />
        </div>
        <div className="screen-description">
          {files.length < 1 && (
            <>
              <h2><T text="WizardFile_Upload_Content_Title" /></h2>
              <p><T text="WizardFile_Upload_Content_Description" /></p>
            </>
          )}
          {files.length > 0 && (
            <>
              <h2>{filesType === "video" ? <T text="WizardFile_Upload_Other_Content_Title_Video" /> : <T text="WizardFile_Upload_Other_Content_Title_Image" />}</h2>
              <p><T text="WizardFile_Upload_Other_Content_Description" /></p>
            </>
          )}
        </div>
          {files.length < 1 && (
            <div className="fluid white"></div>
          )}
          {files.length > 0 && (
            <div className="fluid">
              <div className="fluid-wrapper">
                <h3><T text="WizardFile_Test_Content" /></h3>
                {renderFiles}
                <div className="button-add-file" onClick={() => {
                  if (filesType === "video") {
                    this.fileInputVideo.click()
                  } else {
                    this.fileInputImage.click()
                  }
                }}>
                  <div className="circle-wrapper">
                    <i className="icon icon-plus-1"></i>
                  </div>
                  <div className="text"><T text="WizardFile_Optional_Upload_Other_File" /></div>
                </div>
              </div>
            </div>
          )}
        <div className="fixed-bottom">
          {files.length < 1 && (
            <>
              <div className="row">
                <Link main className="full-width" onClick={() => this.fileInputVideo.click()}>
                  <T text="WizardFile_Upload_Video_Button" />
                </Link>
                <Link main className="full-width" onClick={() => this.fileInputImage.click()}>
                  <T text="WizardFile_Upload_Image_Button" />
                </Link>
              </div>
            </>
          )}
          {files.length > 0 && (
            <>
              <Link main className="full-width" onClick={() => this.goWizardLink()}>
                <T text="WizardFile_Finish_Button" />
              </Link>
            </>
          )}
        </div>

        <input
          className="hidden"
          name="file"
          type="file"
          onChange={file => this.uploadVideo(file)}
          ref={fileInputVideo => this.fileInputVideo = fileInputVideo}
          accept="video/*"
        />
        <input
          className="hidden"
          name="file"
          type="file"
          onChange={file => this.uploadImage(file)}
          ref={fileInputImage => this.fileInputImage = fileInputImage}
          accept="image/*"
        />
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(WizardFile);
