import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import T from '../Translate';
import Link from '../Link';
import TextArea from '../TextArea';
import Select from '../Select';
import DraggableList from '../DraggableList';

import { URL_ENVIRONMENT } from '../../config/global';

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({})

class QAModulePanel extends React.Component {
  _mount = true;
  _defaultState = {
    questions: [],
    name: "",
    moduleData: [],
    dataValidated: true,
    showScrollbar: false,
    activeQuestion: 0,
    invalidQuestion: false,
    invalidAnswer: false,
    invalidQuestionNum: 0,
    invalidAnswerNum: 0
  };
  _scrollbar = null;

  constructor(props) {
    super(props);
    this.state = this._defaultState;
  }

  componentDidMount() {

  }

  componentWillUnmount() {
    this._mount = false;
  }

  onInputChange(val) {
    const { questions, activeQuestion } = this.state;
    const { onInputChange } = this.props;

    // console.log(val);

    if (!val.questions && questions && questions[activeQuestion]) {
      questions[activeQuestion] = { ...questions[activeQuestion], ...val }

      if (onInputChange)
        onInputChange({ questions });

      if (this._mount) {
        this.setState(questions, () => {
          this.validateData();
          this.refs.randomize.refresh();
          this.refs.answers.refresh();
          // this.refs.phonePreview.loadData();
        });
      }
    } else if (val.questions) {
      if (onInputChange)
        onInputChange(val);

      if (this._mount) {
        this.setState(val, () => {
          this.validateData();
          // this.refs.phonePreview.loadData();
        });
      }
    }
  }

  resetData() {
    if (this._mount) {
      this.setState(this._defaultState, () => {
        this.refs.randomize.refresh();
        this.refs.answers.refresh();
        this.refs.name.refresh();
        // this.refs.phonePreview.loadData();
      });
    }
  }

  validateData() {
    if (this._mount) {
      this.setState({ dataValidated: true });
    }
  }

  setQuestionInvalid(num) {
    this.setState({ invalidQuestion: true });
    this.setState({ invalidQuestionNum: num });
    /*setTimeout(() => {
      this.setState({ invalidQuestion: false });
    }, 3000);*/
  }

  setAnswerInvalid(num) {
    this.setState({ invalidAnswer: true });
    this.setState({ invalidAnswerNum: num });
    /*setTimeout(() => {
      this.setState({ invalidAnswer: false });
    }, 3000)*/
  }

  loadData(moduleData) {
    const { onShow } = this.props;

    this.resetData();

    if (this._mount && moduleData) {
      this.setState({ moduleData });

      if (moduleData.name)
        this.setState({ name: moduleData.name });

      if (moduleData.questions)
        this.setState({ questions: moduleData.questions }, () => {
          if (this._mount) {
            this.refs.answers.refresh();
            this.refs.randomize.refresh();
            this.refs.name.refresh();
            // this.refs.phonePreview.loadData();
            /*
            if (moduleData.questions.length <= 1 && !moduleData.questions[0].name) {
              setTimeout(() => this.refs.name.focus(), 400);
            }
            */
          }
        });

      if (onShow)
        onShow();
    }
  }

  show(moduleData) {
    this.loadData(moduleData);
  }

  addAnswer() {
    const { questions, activeQuestion } = this.state;

    const newAnswer = {
      name: "",
      id: Date.now()
    };

    const answers = questions[activeQuestion].answers;
    answers.push(newAnswer);

    this.onInputChange({ answers });
    if (this._mount) {
      this.refs.answers.refresh(() => this.refs.answers.focusLastItem());
      // this.refs.phonePreview.loadData();
    }
  }

  addQuestion() {
    const { questions } = this.state;

    const newQuestion = {
      name: "",
      answers: [],
      questionID: Date.now()
    };

    questions.push(newQuestion);

    this.changeActiveQuestion(questions.length - 1);

    this.onInputChange({ questions });
    if (this._mount) {
      this.refs.randomize.refresh();
      this.refs.answers.refresh();
      // this.refs.phonePreview.loadData();
      this.refs.name.focus();
    }
  }

  removeQuestion() {
    const { activeQuestion } = this.state;
    const questions = this.state.questions.filter((question, key) => key !== activeQuestion);
    let nextActiveQuestion = activeQuestion - 1;

    if (nextActiveQuestion < 0)
      nextActiveQuestion = 0;

    if (this._mount) {
      this.setState({ questions }, () => {
        this.onInputChange({ questions });
        this.refs.randomize.refresh();
        this.refs.answers.refresh();
        this.changeActiveQuestion(nextActiveQuestion);
        // this.refs.phonePreview.loadData();
      });
    }
  }

  changeActiveQuestion(question_key) {
    if (this._mount) {
      this.setState({ activeQuestion: question_key }, () => {
        this.refs.randomize.refresh();
        this.refs.answers.refresh();
        this.refs.name.refresh();
        // this.refs.phonePreview.loadData();
      });
    }
  }

  prevActiveQuestion() {
    let activeQuestion = this.state.activeQuestion - 1;

    if (activeQuestion < 0)
      activeQuestion = 0

    if (this._mount) {
      this.setState({ activeQuestion }, () => {
        this.refs.randomize.refresh();
        this.refs.answers.refresh();
        this.refs.name.refresh();
        // this.refs.phonePreview.loadData();
      });
    }
  }

  nextActiveQuestion() {
    const { questions } = this.state;
    let activeQuestion = this.state.activeQuestion + 1;

    if (activeQuestion > (questions.length - 1))
      activeQuestion = (questions.length - 1)

    if (this._mount) {
      this.setState({ activeQuestion }, () => {
        this.refs.randomize.refresh();
        this.refs.answers.refresh();
        this.refs.name.refresh();
        // this.refs.phonePreview.loadData();
      });
    }
  }

  getPreview() {
    const { campaignData } = this.props;
    const { moduleData } = this.state;
    const { campaignId, userId } = campaignData;

    const lang = localStorage.getItem('lang') || "en";

    const jwt = require('jwt-simple');
    const payload = {
      "sub": userId,
      "name": "",
      "iat": 0,
      "iss": "",
      "aud": "",
      "exp": 0,
      "forcePreview": true,
      "campaignId": campaignId
    };
    const secret = "super-tester-secret"
    const token = jwt.encode(payload, secret);

    return `${URL_ENVIRONMENT}?id=${campaignId}&token=${token}&s=${moduleData.id}&l=${lang}`;
  }

  render() {
    const { name, showScrollbar, questions, activeQuestion, invalidQuestion, invalidAnswer, invalidQuestionNum, invalidAnswerNum } = this.state;
    const { onCancel, campaignData, t, goPreview, isCampaignPaid } = this.props;

    let questionClass, answerClass;

    if (invalidQuestion && invalidQuestionNum === activeQuestion)
      questionClass = 'error-color';

    if (invalidAnswer && invalidAnswerNum === activeQuestion)
      answerClass = 'error-color';

    let moduleName = t("Panels_Audio_Capital_Letter");

    if (name === "Video"){
      moduleName = t("Panels_Video_Capital_Letter");
    } else if (name === "Image"){
      moduleName = t("Panels_Image_Capital_Letter");
    } else if (name === "Q&A"){
      moduleName = t("Panels_QA_Capital_Letter");
    }

    const questionName =
      questions[activeQuestion] && questions[activeQuestion].name
      ? questions[activeQuestion].name
      : "";

    const questionAnswers =
      questions[activeQuestion] && questions[activeQuestion].answers
      ? questions[activeQuestion].answers
      : "";

    const questionRandomize =
      questions[activeQuestion] && questions[activeQuestion].hasOwnProperty('randomize')
      ? questions[activeQuestion].randomize
      : true;

    const printPaginationItems = questions.map((question, key) => {
      const classItem = activeQuestion === key ? "pagination-item active" : "pagination-item";
      return (
        <div
          key={key}
          className={classItem}
          onClick={() => this.changeActiveQuestion(key)}
        ></div>
      );
    });

    const maxStimulus = campaignData?.features?.numberOfQAAnswersPerQuestion || 6;
    const addButtonDisabled = maxStimulus < 0 ? false : questionAnswers.length >= maxStimulus;
    const addButtonTooltipDisabled = maxStimulus < 0 ? true : questionAnswers.length < maxStimulus;

    return (
      <div className="panel panel-qa-screen">
        <ReactTooltip place="bottom" effect="solid" backgroundColor="#ccc" />
        <div className="row flex">
          <div className="col">
            <div className="row">
              <h3 className="panel-title flex">
                <Link secondary className="no-border no-padding" onClick={() => { onCancel() }}>
                  <i className="icon icon-right-open-1"></i>
                </Link>
                {moduleName}

                <div className="right">
                  <Link secondary disabled={questions.length < 1 || questionAnswers.length < 1} onClick={() => goPreview(this.getPreview())}>
                    <i className="icon icon-play-4"></i>
                    <T text="Panels_Btn_Preview" />
                  </Link>
                </div>

                {questions.length > 1 && (
                  <div className="qa-buttons">
                    <Link main onClick={() => { this.addQuestion() }}>
                      <T text="QAModulePanel_Add_Question_Button" />
                    </Link>
                  </div>
                )}
              </h3>
            </div>
            <div className="panel-inputs" id="panel-qa-scrollview">
              {/*
              <div className="center-content">
                <div className="questions-pagination">
                  <Link inline onClick={() => { this.prevActiveQuestion() }}>
                    <i className="icon icon-left-open-4"></i>
                  </Link>
                  {printPaginationItems}
                  <Link inline onClick={() => { this.nextActiveQuestion() }}>
                    <i className="icon icon-right-open-4"></i>
                  </Link>
                </div>
              </div>
              */}
              <div className="field">
                <label htmlFor="name" className={questionClass}>
                  <p>{t("QAModulePanel_Question")}</p>
                  {questions.length > 1 && (
                    <Link secondary onClick={() => { this.removeQuestion() }}>
                      <T text="QAModulePanel_Remove_Question_Button" />
                    </Link>
                  )}
                </label>
                <TextArea
                  name="name"
                  value={questionName}
                  ref="name"
                  onChange={val => this.onInputChange(val)}
                  placeholder={t("QAModulePanel_Write_Question")}
                  disabled={isCampaignPaid}
                />
              </div>

              <div className="field qa-draggable">
                <label htmlFor="randomize" className={answerClass}><T text="QAModulePanel_Answers" /></label>
                <Select
                  small
                  name="randomize"
                  value={questionRandomize}
                  ref="randomize"
                  onChange={val => this.onInputChange(val)}
                  options={[
                    { "value": true, "label": t("Panels_Randomize_Order") },
                    { "value": false, "label": t("Panels_Play_Order") },
                  ]}
                  disabled={isCampaignPaid}
                />
                <DraggableList
                  value={questionAnswers}
                  name="answers"
                  ref="answers"
                  editable={true}
                  onChange={val => this.onInputChange(val)}
                  placeholder={t("QAModulePanel_Write_Answer")}
                  disabled={isCampaignPaid}
                />
                <div className={`right-content ${isCampaignPaid ? "hidden" : ""}`} data-tip={t('QAModulePanel_Add_Answer_Button_Tooltip').replace("[var1]", maxStimulus)} data-place="left" data-tip-disable={addButtonTooltipDisabled}>
                  <Link main className="full-width" disabled={addButtonDisabled} onClick={() => { this.addAnswer() }}>
                    <T text="QAModulePanel_Add_Answer_Button" />
                  </Link>
                </div>
              </div>
            </div>
            {questions.length > 1 && (
              <div className="panel-bottom">
                <div className="center-content">
                  <div className="questions-pagination">
                    <Link inline onClick={() => { this.prevActiveQuestion() }}>
                      <i className="icon icon-left-open-1"></i>
                    </Link>
                    {printPaginationItems}
                    <Link inline onClick={() => { this.nextActiveQuestion() }}>
                      <i className="icon icon-right-open-1"></i>
                    </Link>
                  </div>
                </div>
                {/*
                <div className="buttons-wrapper">
                  <Link secondary onClick={() => this.showAddModulePanel()}>
                    Cancel
                  </Link>
                  <Link main className="full-width" onClick={() => this.showAddModulePanel()}>
                    Save changes
                  </Link>
                </div>
                */}
              </div>
            )}
          </div>
          {/*
          <div className={classColPhone}>
            <PhonePreviewQA
              ref="phonePreview"
              questions={questions}
              activeQuestion={activeQuestion}
              campaignData={campaignData}
            />
          </div>
          */}
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation('translations', { withRef: true }),
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(QAModulePanel);
