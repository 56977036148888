import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { Spinner } from 'react-activity';
import { withTranslation } from 'react-i18next';
import { CampaignAccess } from '@sounditi/ft2-api';
import InfiniteScroll from 'react-infinite-scroll-component';

import { showNotification } from '../reducers/notifications';
import { showVersionControl } from '../reducers/navigation';

import T from './Translate';
import Link from './Link';

const mapStateToProps = (state, ownProps) => ({
  openVersionControl: state.navigation.openVersionControl,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  showNotification: val => dispatch(showNotification(val)),
  showVersionControl: val => dispatch(showVersionControl(val)),
  dispatch,
})

class VersionControl extends React.Component {
  _mount = true;

  constructor(props) {
    super(props);
    this.state = {
      loadingVersions: false,
      campaignVersions: [],
      currentPage: 1,
      pageLimit: 30,
      totalVersions: 0,
      hasMore: true
    };
  }

  componentWillMount() {

  }

  componentDidMount() {

  }

  componentWillUnmount() {
    this._mount = false;
  }

  componentDidUpdate(prevProps, prevState) {
    const { openVersionControl } = this.props;
    if (openVersionControl && !prevProps.openVersionControl) {
      this.getCampaignVersions();
    }
  }

  async getCampaignVersions() {
    const { currentPage, pageLimit, hasMore, campaignVersions } = this.state;
    const { campaignData } = this.props;
    const { campaignId } = campaignData;

    this.setState({ loadingVersions: true });
    try {
      const campaignAccess = new CampaignAccess();
      const versions = await campaignAccess.getCampaignVersions(campaignId, currentPage, pageLimit);
      const { numberOfVersions, campaignVersions: newCampaignVersions } = versions;

      if (hasMore) {
        if (campaignVersions.length > 0) {
          this.setState({ campaignVersions: campaignVersions.concat(newCampaignVersions) });
        } else {
          this.setState({ campaignVersions: newCampaignVersions });
        }
      } else {
        this.setState({ campaignVersions: newCampaignVersions, hasMore: true });
      }

      this.setState({ totalVersions: numberOfVersions });
    } catch (error) { }
    this.setState({ loadingVersions: false });
  }

  changeCampaignVersion(data) {
    this.props.changeCampaignVersionCallback(data);
  }

  loadMoreVersions() {
    const { currentPage, campaignVersions, totalVersions } = this.state;

    if (campaignVersions.length < totalVersions) {
      this.setState({ currentPage: currentPage+1 });
      this.getCampaignVersions();
    } else {
      this.setState({ hasMore: false });
    }
  }

  resetVersions() {
    this.setState({ campaignVersions: [], currentPage: 1 });
  }

  render() {
    const {
      loadingVersions,
      campaignVersions,
      hasMore
    } = this.state;
    const { showVersionControl } = this.props;

    const disableMask = loadingVersions ? 'disabled' : '';

    const renderVersionControl = campaignVersions.map(version => {
      const {createdAt, numberOfVersion, data} = version;
      const date = new Date(createdAt);
      const formattedDate =
      `${date.getDate()} ${date.toLocaleString('default', { month: 'long' })}, ${date.getFullYear()}
      ${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}:${date.getSeconds()}`;

      return (
        <div key={createdAt} className="versions">
          <div className="version">
            <p><T text="VersionControl_Version_Name" /> {numberOfVersion}</p>
            <p className="version-date" onClick={() => this.changeCampaignVersion(data)}>
              <span><i className="icon icon-back-in-time"></i>{formattedDate}</span>
            </p>
          </div>
        </div>
      )
    });

    return (
      <>
        <div className={`version-panel-mask ${disableMask}`} onClick={() => { showVersionControl(false); this.resetVersions() }}></div>
        <div className="version-panel-inner" id="scrollableVersions">
          <h3 className="panel-title">
            <Link secondary className="no-border no-padding" onClick={() => { showVersionControl(false); this.resetVersions() }}>
              <i className="icon icon-right-open-1"></i>
            </Link>
            <T text="VersionControl_Title" />
          </h3>

          {loadingVersions && campaignVersions.length < 1 && (
            <div className="loading-cover">
              <div className="cover design"></div>
              <div>
                <Spinner speed={0.8} color="#00C7E7" size={20} />
              </div>
            </div>
          )}

          {renderVersionControl.length > 0 &&
            <InfiniteScroll
              dataLength={campaignVersions.length}
              next={() => this.loadMoreVersions()}
              hasMore={hasMore}
              loader={
                <div className="loader-scroll">
                  <Spinner speed={0.8} color="#00C7E7" size={20} />
                </div>
              }
              scrollableTarget="scrollableVersions"
              endMessage={<span></span>}
            >
              {renderVersionControl}
            </InfiniteScroll>
          }

          {!loadingVersions && renderVersionControl.length < 1 &&
            <p><T text="VersionControl_Versions_Empty" /></p>
          }
        </div>
      </>
    );
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(VersionControl);
