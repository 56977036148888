import React from 'react';
import { connect } from 'react-redux';

import { setRedirect } from '../reducers/navigation';
import { closeTab } from '../reducers/tabs';

import Link from '../components/Link';

import { getURLParameter } from '../utils/global';

const mapStateToProps = (state, ownProps) => ({
  tabs: state.tabs.tabs || [],
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  closeTab: val => dispatch(closeTab(val)),
  setRedirect: val => dispatch(setRedirect(val)),
})

class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTabKey: '',
      activeTab: {}
    };
  }

  componentWillMount() {
    this.loadTabActive();
  }

  componentDidUpdate() {
    const campaignId = getURLParameter("id");
    const { activeTab } = this.state;

    if (activeTab && activeTab.id && activeTab.id !== campaignId)
      this.loadTabActive();
  }

  loadTabActive() {
    const { tabs } = this.props;
    const campaignId = getURLParameter("id");

    const activeTabKey = tabs.findIndex(function(tab) {
      return tab.id === campaignId
    });

    const activeTab = tabs.filter(tab => {
      return tab.id === campaignId
    })[0];

    this.setState({ activeTabKey, activeTab });
  }

  goCampaigns() {
    const { setRedirect } = this.props;
    const link = `/tests/`;
    setRedirect({ route: link });
  }

  changeCampaign(campaign_id) {
    const { setRedirect, location, onTabChange } = this.props;
    const path = (
      location.pathname === "/tests/" ||
      location.pathname === "/tests" ||
      location.pathname === "/account/" ||
      location.pathname === "/account" ||
      location.pathname === "/support-and-help/" ||
      location.pathname === "/support-and-help"
    ) ? "/tests/configuration" : location.pathname;
    const link = `${path}?id=${campaign_id}`;
    setRedirect({ route: link });

    if (onTabChange)
      onTabChange();
  }

  closeTab(tab_id) {
    const { closeTab } = this.props;
    const { activeTabKey } = this.state;
    const campaignId = getURLParameter("id");
    const tabs = this.props.tabs.filter(tab => tab.id !== tab_id);

    closeTab(tab_id);

    if (campaignId === tab_id){
      const prevTab = tabs[(activeTabKey - 1)];
      if (activeTabKey <= 0 || !prevTab) {
        this.goCampaigns();
      } else {
        this.changeCampaign(prevTab.id);
      }
    }
  }

  render() {
    const { tabs, changesToApply, location } = this.props;
    const campaignId = getURLParameter("id");
    const classCampaignsTab = !campaignId && (location.pathname === "/tests/" || location.pathname === "/tests") ? "tab only-icon active" : "tab only-icon";

    const renderTabs = tabs.map(tab => {
      const classTab = tab.id === campaignId ? "tab active" : "tab";
      const changes = (tab.id === campaignId && changesToApply) ? "*" : ""

      return (
        <div key={tab.id} className={classTab}>
          <div className="tab-inner">
            <Link inline onClick={() => { this.changeCampaign(tab.id) }}>{tab.name}{changes}</Link>
            <Link inline onClick={() => { this.closeTab(tab.id) }} className="button-close-tab">
              <i className="icon icon-cancel-6"></i>
            </Link>
          </div>
        </div>
      )
    });

    return (
      <div className="tabs">
        <div className={classCampaignsTab}>
          <div className="tab-inner">
            <Link inline onClick={() => {this.goCampaigns()}}><i className="icon icon-home-5"></i></Link>
          </div>
        </div>
        {renderTabs}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tabs)