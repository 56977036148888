import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { CampaignAccess } from '@sounditi/ft2-api';
import { Spinner } from 'react-activity';

import T from '../Translate';
import Link from '../Link';

import { setRedirect } from '../../reducers/navigation';
import { showNotification } from '../../reducers/notifications';
import { setTestsData } from '../../reducers/user';

import { getURLParameter } from '../../utils/global';
import { URL_CAMPAIGN_MODULES_ID } from '../../config/urls';

const mapStateToProps = (state, ownProps) => ({
  testsData: state.user.testsData,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setTestsData: val => dispatch(setTestsData(val)),
  showNotification: val => dispatch(showNotification(val)),
  setRedirect: val => dispatch(setRedirect(val)),
  dispatch
})

class PublishTest extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: false,
      loadingData: false
    }
  }

  componentWillMount() {
    // this.refreshTestsData();
  }

  async refreshTestsData() {
    const { setTestsData } = this.props;
    const campaignAccess = new CampaignAccess();
    const refreshedTestsData = await campaignAccess.getCampaigns();
    setTestsData(refreshedTestsData);

    this.setState({ loadingData: false })

    return refreshedTestsData;
  }

  async publishCampaign() {
    const { campaignData, testsData, setTestsData, showNotification, onRefreshTestData } = this.props;
    const { loading } = this.state;
    const isEmpty = campaignData?.data?.modules?.campaignModules.length < 1;

    if (!loading && !isEmpty) {
      this.setState({ loading: true });

      try {
        const campaignAccess = new CampaignAccess();
        const refreshedTest = await campaignAccess.publishCampaign(campaignData.campaignId);
        // console.log(refreshedTest)
        const refreshedTestsData = testsData.map(test => {
          if (test.campaignId === refreshedTest.campaignId)
            test = refreshedTest

          return test;
        })

        setTestsData(refreshedTestsData);
        this.setState({ campaignData: refreshedTest, loading: false });

        // this.getTesters(campaignData);
        onRefreshTestData(refreshedTest)
      } catch (error) { 
        console.log(error)
        this.setState({ loading: false });
        showNotification("genericError");
      }
    } else if (isEmpty) {
      this.goModules();
      showNotification("testEmptyWarning");
    } 
  }

  goModules() {
    const { setRedirect } = this.props;
    const campaignId = getURLParameter("id");
    setRedirect({ route: URL_CAMPAIGN_MODULES_ID.replace('[var1]', campaignId) });
  }

  render() {
    const { onShowAdquisitionMethod, campaignData } = this.props;
    const { loading, loadingData } = this.state;

    // console.log(campaignData)
    if (!['draft', 'ready', 'processing'].includes(campaignData.status) || campaignData.published === true)
      return false;

    return (
      <div className='publish-test-box'>
        <div className='publish-test-wrapper content-wrapper medium'>
          {loadingData && (
            <div className="loading-cover">
              <div className="cover"></div>
              <div>
                <Spinner speed={0.8} color="#ffffff" size={20} />
              </div>
            </div>
          )}
          <div className='publish-test-image'>
            <img src="../../assets/img/launching.gif" />
          </div>
          <div className='publish-test-content'>
            <h2><T text="PublishTest_Title" /></h2>
            <p><T text="PublishTest_Text" /></p>
            {/* <p>Método de adquisición de respuestas: invitación por email</p> */}
            {/* {['processing'].includes(campaignData.status) && (
              <div className='processing-warning'>
                <p><T text="Notifications_PublishTest_Processing" /></p>
              </div>  
            )} */}
            <div className='publish-buttons'>
              <Link secondary className="adquisiton-method-button" onClick={() => onShowAdquisitionMethod()}>
                <div className="icon">
                  <i className="icon icon-left-open-1"></i> 
                </div>
                <div className="text">
                  <div className="title"><T text="PublishTest_Button_Recopilation_Method" /></div>
                  <div className="value">
                    {campaignData.chosenAudienceType === "email" 
                      ? <T text="PublishTest_Button_Recopilation_Method_Email" /> 
                      : <T text="PublishTest_Button_Recopilation_Method_Link" />} 
                  </div>
                </div>
              </Link>
              <Link main className={`button-with-loader`} disabled={loading} onClick={() => this.publishCampaign()}>
                {loading && (
                  <Spinner speed={0.8} color="#ffffff" size={16} />
                )}
                <T text="PublishTest_Button_Publish" />
              </Link>
            </div>
          </div>
        </div>  
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(PublishTest);
