export const preloadImages = [
    '/assets/img/phone-mock.png',
    '/assets/img/phone-mock-size.png',
    '/assets/img/logo.png',
    '/assets/img/no-campaigns-img.png',
    '/assets/img/modules/card-audio.png',
    '/assets/img/modules/card-video.png',
    '/assets/img/modules/card-image.png',
    '/assets/img/modules/card-qa.png',
    '/assets/img/modules/card-login.png',
    '/assets/img/modules/card-text.png',
    '/assets/img/modules/type-choose.png',
    '/assets/img/modules/type-face-recognition.png',
    '/assets/img/modules/type-play.png',
    '/assets/img/incompatible-screen.png',
    '/assets/img/icon-age.png',
    '/assets/img/icon-gender.png',
    '/assets/img/icon-location.png',
    '/assets/img/icon-responses.png',
    '/assets/img/launching.gif',
    '/assets/img/recopilator-options-alyze.png',
    '/assets/img/recopilator-options-email.png',
    '/assets/img/recopilator-options-link.png'
];