export default {
    translations: {
        'Inputs_Action_Copy_Clipboard': 'Copiar al portapapeles',
        /* PolicyCheck */
        'PolicyCheck_Text_1': 'He leído y acepto la ',
        'PolicyCheck_Link_Privacy': 'política de privacidad',
        'PolicyCheck_Text_2': ' y los ',
        'PolicyCheck_Link_Terms': 'términos y condiciones',
        /* Configuration */
        'Configuration_Browser_Title': 'Alyze | Configuración del Test - General',
        'Configuration_Title': 'CONFIGURACIÓN',
        'Configuration_Subtitle': 'Configura el nombre de tu test y el idioma de acceso de tu audiencia',
        'Configuration_Btn_Revert_Changes': 'Revertir cambios',
        'Configuration_Btn_Save_Changes': 'Guardar cambios',
        'Configuration_Btn_Saved': 'Guardado',
        'Configuration_Btn_Next_Step': 'Próximo paso',
        'Configuration_Widget_Title_General': 'General',
        'Configuration_Input_Label_Name': 'Nombre',
        'Configuration_Input_Label_Audience_Limit': 'Límite de Audiencia',
        'Configuration_Input_Label_Language': 'Idioma de la audiencia',
        'Configuration_Input_Label_Custom_Campaign_Link': 'Enlace del Test personalizado',
        'Configuration_Input_Label_Schedule': 'Calendario',
        'Configuration_Input_Label_Starts': 'Inicio',
        'Configuration_Input_Label_Ends': 'Fin',
        'Configuration_Input_Label_Language_ES': 'Español',
        'Configuration_Input_Label_Language_EN': 'Inglés',
        'Configuration_Change_Lang_Modal_Title': 'Cambiar el idioma de tu audiencia',
        'Configuration_Change_Lang_Modal_Subtitle': 'Se sobreescribirán los textos actuales',
        'Configuration_Change_Lang_Modal_Cancel': 'Cancelar',
        'Configuration_Change_Lang_Modal_Confirm': 'Sí, cambiar idioma',
        'Configuration_Change_Lang_Modal_Text': 'Los textos de los siguientes módulos serán reemplazados:',
        'Configuration_Change_Lang_Modal_Text2': 'Estos textos serán sustituidos por los textos predefinidos del idioma que elijas.',
        /* AgeRange */
        'AgeRange_Title': 'Rango de edad',
        'AgeRange_Left_Legend': 'interacciones',
        'AgeRange_Bottom_Legend': 'Rangos de edad',
        'AgeRange_Modal_Info_Title': 'Edad media',
        'AgeRange_Modal_Info_Subtitle': 'Porcentaje de usuarios por rango de edad',
        /* Gender */
        'Gender_Title': 'Género',
        'Gender_Bottom_Legend': 'interacciones',
        'Gender_Left_Legend': 'rango de identidad de género',
        'Gender_Male': 'Masculino',
        'Gender_Female': 'Femenino',
        'Gender_No_binaire': 'Prefiero no contestar',
        'Gender_Modal_Info_Title': 'Género predominante',
        'Gender_Modal_Info_Subtitle': 'Porcentaje de usuarios por género',
        /* GlobalSummary */
        'GlobalSummary_Title': 'Resumen Global',
        'GlobalSummary_Label_Interactions': 'Interacciones',
        'GlobalSummary_Label_Users': 'Usuarios',
        'GlobalSummary_Label_Top_Location': 'Localización predominante',
        'GlobalSummary_Label_Top_Genre': 'Género predominante',
        'GlobalSummary_Label_Average_Age': 'Edad Media',
        'GlobalSummary_Label_Audience_Percentage': 'de la edad de tu audiencia está entre',
        /* MonthInteractions */
        'MonthInteractions_Title': 'Interacciones en los últimos 30 días',
        'MonthInteractions_Bottom_Legend': 'días del mes',
        'MonthInteractions_Left_Legend': 'interacciones',
        /* TopLocations */
        'TopLocations_Title': 'Localizaciones Predominantes',
        'TopLocations_Left_Legend': 'interacciones',
        'TopLocations_Bottom_Legend': 'Localizaciones',
        'TopLocations_Modal_Info_Title': 'Localización predominante',
        'TopLocations_Modal_Info_Subtitle': 'Porcentaje de usuarios por ciudad',
        /* WeekInteractions */
        'WeekInteractions_Title': 'Interacciones Semanales',
        'WeekInteractions_Bottom_Legend': 'días de la semana',
        'WeekInteractions_Left_Legend': 'interacciones',
        /* Responses obtained */
        'Responses_Modal_Info_Title': 'Respuestas obtenidas',
        'Responses_Modal_Info_Subtitle': 'Interacciones de los usuarios por semana y por mes',
        /* Metrics */
        'Metrics_Total_Activation': 'Activación total',
        'Metrics_Activation': 'Activación',
        'Metrics_Engagement': 'Engagement',
        'Metrics_Interest': 'Interés',
        'Metrics_Satisfaction': 'Satisfacción',
        'Metrics_Audio_Lowercase': 'audio',
        'Metrics_vídeo_Lowercase': 'vídeo',
        'Metrics_Image_Lowercase': 'imagen',
        'Metrics_QA_Lowercase': 'encuesta',
        'Metrics_Audio_Capital_Letter': 'Audio',
        'Metrics_Video_Capital_Letter': 'Vídeo',
        'Metrics_Large_Video_Capital_Letter': 'Película',
        'Metrics_Image_Capital_Letter': 'Imagen',
        'Metrics_QA_Capital_Letter': 'Encuesta',
        'Metrics_All_Capital_Letter': 'Todos',
        'Metrics_Time_Point': 'tiempo',
        'Metrics_Duration': 'duración',
        'Metrics_Legend_Time': 'tiempo (segundos)',
        'Metrics_Legend_Answers': 'respuestas',
        'Metrics_Legend_Answered': 'conTestadas',
        'Metrics_Cancel_Selection_Button': 'Cancelar la selección',
        'Metrics_Test_Without_Modules_Title': 'Este test no tiene módulos aún',
        'Metrics_Test_Without_Modules_Text': 'Por favor, ve a "Diseña tu test" para añadir tu primer módulo.',
        'Metrics_Test_Without_Modules_Link': 'Ir a diseña tu test',
        'Metrics_Test_Without_Responses_Title': 'Este test no tiene resultados aún',
        'Metrics_Test_Without_Responses_Text': 'Por favor, invita a testers o espera a que participe al menos uno de ellos.',
        'Metrics_Test_Without_Responses_Link': 'Actualizar',
        'Metrics_Test_Unpaid_Title': 'Este test no está pagado aún',
        'Metrics_Test_Unpaid_Text': 'Tienes que pagar tu test para poder invitar audiencia y obtener resultados.',
        'Metrics_Test_Unpaid_Link': 'Ir a Revisa tu test y págalo',
        'Metrics_Details_Emotions_Title': 'Emociones',
        'Metrics_Details_Emotions_Text': 'Aquí puedes ver el nivel general de activación de cada emoción que ha generado este contenido',
        'Metrics_Details_Activation_Title': 'Activación',
        'Metrics_Details_Activation_Text': 'Sumatorio de todas las emociones positivas y negativas a lo largo de tu contenido',
        'MetricsModule_Score_Label_No_Results': 'Sin respuestas',
        'MetricsModule_Score_Label_Poor': 'Pobre',
        'MetricsModule_Score_Label_Regular': 'Regular',
        'MetricsModule_Score_Label_Good': 'Bueno',
        'MetricsModule_Score_Label_Very_Good': 'Muy bueno',
        'MetricsModule_Score_Legend': 'Media de puntuación<br/>basada en <b>[var1] respuestas</b>',
        'MetricsModule_Responses_Count': 'respuestas',
        'Metrics_Desktop_Promo_Title': '¿Sabes ya todo lo que puede hacer Alyze?',
        'Metrics_Desktop_Promo_List1_Title': 'Personaliza tu test por completo',
        'Metrics_Desktop_Promo_List1_Text': 'Puedes poner tu logo y los colores que quieras a tu test así como personalizar los textos de introducción y fin.',
        'Metrics_Desktop_Promo_List2_Title': '¡Haz preguntas!',
        'Metrics_Desktop_Promo_List2_Text1': 'Añade cuestionarios con preguntas y respuestas a tu test.',
        'Metrics_Desktop_Promo_List2_Text2': 'Accede desde un ordenador para utilizar la <b>versión completa</b>',
        'Metrics_Share_Link_Title': 'Compartir enlace',
        'Metrics_Share_Link_Button_Copy': 'Copiar',
        'Metrics_Share_Link_Text': 'Este es el enlace para participar en tu test, compártelo con quien tú quieras. Cuantas más personas completen el test, más precisos serán tus resultados.',
        'Metrics_Share_Link_Buy_Audience_Title': 'Elige tu audiencia y nos encargaremos de todo por ti',
        'Metrics_Share_Link_Buy_Audience_Button': 'Comprar audiencia',
        'Metrics_Download_Button': 'Descargar métricas',
        'Metrics_Download_Button_Averages_Csv': 'Exportar promedio de los testers (CSV)',
        'Metrics_Download_Button_Aggregate_Csv': 'Exportar agregado (CSV)',
        'Metrics_Warning_Pay_Test_Title_A': 'Mostrando 5 de [var1] resultados',
        'Metrics_Warning_Pay_Test_Text_A': 'Te regalamos las 5 primeras respuestas totalmente GRATIS',
        'Metrics_Warning_Pay_Test_Button_A': 'Paga y obtén los resultados completos ahora',
        'Metrics_Warning_Pay_Test_Title_B': 'Pruébalo antes de pagar',
        'Metrics_Warning_Pay_Test_Text_B': 'Paga tu test cuando quieras y obtén los resultados completos',
        'Metrics_Warning_Pay_Test_Button_B': 'Pagar ahora',
        /* PublishTest */
        'PublishTest_Title': 'Publica tu test para empezar a recopilar respuestas',
        'PublishTest_Text': 'Revisa la vista previa de tu test con atención y publícalo cuando estés listo/a para empezar a recopilar respuestas. <b>Cuando se haya publicado ya no podrás editar su contenido.</b>',
        'PublishTest_Button_Publish': 'PUBLICAR TEST',
        'PublishTest_Button_Recopilation_Method': 'Método de recopilación',
        'PublishTest_Button_Recopilation_Method_Email': 'Invitación por email',
        'PublishTest_Button_Recopilation_Method_Link': 'Acceso por enlaces',
        /* PayTest */
        'PayTest_Title': 'Paga tu test y obtén todos los resultados al instante',
        'PayTest_Text': 'Tu test tiene [var1] respuestas, <b>te regalamos el resultado de las 5 primeras</b>.<br/>Una vez pagado el test, seguirá activo hasta alcanzar las respuestas deseadas.',
        'PayTest_Responses_Selector_Title': '¿Cuántas respuestas necesitas?',
        'PayTest_Responses_Selector_Subtitle': 'El mínimo de respuestas es el número de respuestas ya recopiladas',
        'PayTest_Invoice_Test': 'x Test',
        'PayTest_Invoice_Unit_Duration': 'Duración unidad',
        'PayTest_Invoice_Unit_Price': 'Precio unidad',
        'PayTest_Invoice_Free_Test': '5 x Test GRATIS',
        'PayTest_Invoice_Total': 'Total',
        'PayTest_Invoice_VAT': 'IVA incluido',
        'PayTest_Invoice_Button_Pay': 'Paga y obtén los resultados',
        'PayTest_Featured_Clients_Title': 'Clientes destacados',
        'PayTest_Link_Back': 'Volver a los resultados',
        /* MetricsFilms */
        'MetricsFilm_Flashes_Title': 'Destellos',
        'MetricsFilm_Flashes_max-activation-peak': 'Pico máximo emocional',
        'MetricsFilm_Flashes_max-activation-bin': 'Momento más emocionante',
        'MetricsFilm_Flashes_max-interest-bin': 'Momento de más interés',
        'MetricsFilm_Flashes_little-activation': 'Poco emocionante',
        'MetricsFilm_Flashes_loss-of-attention': 'Perdida de atención',
        'MetricsFilm_Flashes_a-lot-of-activation': 'Muy emocionante',
        'MetricsFilm_Flashes_no-data': 'Sin datos',
        'MetricsFilm_Audience_Title': 'Audiencia',
        'MetricsFilm_Audience_RealizedTests': 'Tests completados',
        'MetricsFilm_Audience_MostInterestedAges': 'Edades más interesadas',
        'MetricsFilm_Audience_MostInterestedGender': 'Género más interesado',
        'MetricsFilm_Audience_MostInterestedLocation': 'Localidad más interesada',
        'MetricsFilm_Audience_LessInterestedAges': 'Edades menos interesadas',
        'MetricsFilm_Emotions_Title': 'Emociones',
        'MetricsFilm_Emotions_EmotionOverPercentage': 'Emoción > 65%',
        'MetricsFilm_Emotions_LossOfAttention': 'Pérdida de atención',
        'MetricsFilm_Emotions_DataAccuracy': 'Calidad de los Datos',
        'MetricsFilm_Emotions_PredominantEmotion': 'Emoción predominante',
        'MetricsFilm_Emotions_SecondaryEmotion': 'Emoción secundaria',
        'MetricsFilm_Emotions_DataQuality_Legend_Excellent': '¡Enhorabuena! La calidad de tus datos es excelente',
        'MetricsFilm_Emotions_DataQuality_Legend_Get_Excellent': 'Obtén [var1] respuestas más para lograr una calidad excelente',
        'MetricsFilm_Emotions_DataQuality_Legend_Get_High': 'Obtén [var1] respuestas más para lograr una calidad alta',
        'MetricsFilm_Emotions_DataQuality_Legend_Get_Medium': 'Obtén [var1] respuestas más para lograr una calidad media',
        'MetricsFilm_Metrics_Title': 'Métricas',
        'MetricsFilm_Metrics_Play_Message': 'Click para reproducir',
        'MetricsFilm_Metrics_Timeline_Attention': 'Atención',
        'MetricsFilm_Metrics_Timeline_Activation': 'Activación Emocional',
        'MetricsFilm_Metrics_Timeline_Emotion_Happiness': 'Alegría',
        'MetricsFilm_Metrics_Timeline_Emotion_Surprise': 'Sorpresa',
        'MetricsFilm_Metrics_Timeline_Emotion_Sadness': 'Tristeza',
        'MetricsFilm_Metrics_Timeline_Emotion_Fear': 'Miedo',
        'MetricsFilm_Metrics_Timeline_Emotion_Anger': 'Ira',
        'MetricsFilm_Metrics_Timeline_Emotion_Disgust': 'Asco',
        'MetricsFilm_Survey_Title': 'Encuesta',
        /* AverageEmotions */
        'AverageEmotions_Emotion_Happiness': 'Alegría',
        'AverageEmotions_Emotion_Surprise': 'Sorpresa',
        'AverageEmotions_Emotion_Sadness': 'Tristeza',
        'AverageEmotions_Emotion_Fear': 'Miedo',
        'AverageEmotions_Emotion_Anger': 'Ira',
        'AverageEmotions_Emotion_Disgust': 'Asco',
        /* EmotionalOverview */
        'EmotionalOverview_Title': 'Resumen emocional',
        'EmotionalOverview_Bottom_Legend': 'tiempo (segundos)',
        'EmotionalOverview_Left_Legend': 'activación emocional (%)',
        'EmotionalOverview_Total_Activation': 'Resumen emocional',
        /* EmotionalPeaks */
        'EmotionalPeaks_Title': 'Pico emocional',
        'EmotionalPeaks_Emotion_Reached': 'emoción (% alcanzado)',
        /* EngagementOverview */
        'EngagementOverview_Title': 'Resumen de Engagement',
        'EngagementOverview_Left_Legend': 'nivel de engagement (%)',
        /* InterestOverview */
        'InterestOverview_Title': 'Resumen de Interés',
        'InterestOverview_Left_Legend': 'nivel de interés (%)',
        /* MetricsPeaks */
        'MetricsPeaks_Title': 'Picos de Métricas',
        'MetricsPeaks_Emotion_Reached': '% alcanzado',
        /* MetricsSummary */
        'MetricsSummary_Title': 'Resumen de Métricas',
        'MetricsSummary_Left_Legend': 'nivel de activación (%)',
        /* SatisfactionOverview */
        'SatisfactionOverview_Title': 'Resumen de satisfacción',
        'SatisfactionOverview_Left_Legend': 'nivel de satisfacción (%)',
        /* Panels */
        'Panels_Audio_Lowercase': 'audio',
        'Panels_vídeo_Lowercase': 'vídeo',
        'Panels_Image_Lowercase': 'imagen',
        'Panels_QA_Lowercase': 'qa',
        'Panels_Audio_Capital_Letter': 'Audio',
        'Panels_Video_Capital_Letter': 'Vídeo',
        'Panels_Large_Video_Capital_Letter': 'Película',
        'Panels_Image_Capital_Letter': 'Imagen',
        'Panels_QA_Capital_Letter': 'Pregunta',
        'Panels_Audio_Uploading_File': 'Subiendo audio',
        'Panels_Video_Uploading_File': 'Subiendo vídeo',
        'Panels_Image_Uploading_File': 'Subiendo imagen',
        'Panels_Large_Video_Uploading_File': 'Subiendo película',
        'Panels_Randomize_Order': 'Orden aleatorio',
        'Panels_Play_Order': 'Reproducir en orden',
        'Panels_Show_Order': 'Mostrar en orden',
        'Panels_Technology': 'Tecnología',
        'Panels_Facial_Recognition': 'Reconocimiento facial',
        'Panels_Choose_One': 'Elección',
        'Panels_Just_Play': 'Sólo reproducir',
        'Panels_Landing_Title': 'Landing',
        'Panels_Introduction_Title': 'Introducción',
        'Panels_Finish_Title': 'Finalizar',
        'Panels_Btn_Preview': 'Previsualizar',
        'Panels_Btn_Cancel_Upload': 'Cancelar subida',
        'Panels_Film_Genre_Action': 'Acción',
        'Panels_Film_Genre_Adventure': 'Aventuras',
        'Panels_Film_Genre_Comedy': 'Comedia',
        'Panels_Film_Genre_Horror': 'Horror',
        'Panels_Film_Genre_Thriller': 'Thriller',
        'Panels_Film_Genre_Romance': 'Romántica',
        'Panels_Film_Genre_Documentary': 'Documental',
        'Panels_Module_Not_Included': 'No incluido en este test',
        'Panels_Only_One_Film_Module': 'Sólo puede haber un módulo de película',
        'Panels_Max_Number_Of_Modules_Reached': 'Máximo de módulos alcanzado',
        'Panels_Max_Number_Of_Type_Modules_Reached': 'Máximo de módulos de [var1] alcanzado',
        /* ModuleTypeInfo */
        'ModuleTypeInfo_Recognition_Text': 'Analiza la cara del usuario con nuestra tecnología de inteligencia artificial y reconocimiento emocional',
        'ModuleTypeInfo_Choose_Text': 'Pide al usuario que elija un solo clip de entre todos los disponibles',
        'ModuleTypeInfo_Play_Text': 'Reproduce el contenido sin recoger ninguna información del tester',
        /* AddModulePanel */
        'AddModulePanel_Title': 'Agregar módulo',
        'AddModulePanel_Subtitle': 'Haz click o arrastra <br /> para agregar un nuevo módulo',
        /* AudioModulePanel */
        'AudioModulePanel_Add_Audio_Button': 'Agregar audio',
        'AudioModulePanel_Add_Audio_Button_Tooltip': 'Máximo [var1] audios',
        'AudioModulePanel_Audios': 'Audios',
        /* ImageModulePanel */
        'ImageModulePanel_Image_Duration': 'Mostrar cada imagen durante',
        'ImageModulePanel_Time': 'segundos',
        'ImageModulePanel_Add_Image_Button': 'Agregar imagen',
        'ImageModulePanel_Add_Image_Button_Tooltip': 'Máximo [var1] imágenes',
        'ImageModulePanel_Audios': 'Imágenes',
        /* QAModulePanel */
        'QAModulePanel_Add_Question_Button': 'Agregar pregunta',
        'QAModulePanel_Remove_Question_Button': 'Eliminar pregunta',
        'QAModulePanel_Question': 'Pregunta',
        'QAModulePanel_Write_Question': 'Escribe tu pregunta aquí...',
        'QAModulePanel_Answers': 'Respuestas',
        'QAModulePanel_Write_Answer': 'Escribe una respuesta aquí...',
        'QAModulePanel_Add_Answer_Button': 'Agregar respuesta',
        'QAModulePanel_Add_Answer_Button_Tooltip': 'Máximo [var1] respuestas',
        /* videoModulePanel */
        'VideoModulePanel_Add_Video_Button': 'Agregar vídeo',
        'VideoModulePanel_Add_Video_Button_Tooltip': 'Máximo [var1] vídeos',
        'VideoModulePanel_Videos': 'vídeos',
        /* filmModulePanel */
        'FilmModulePanel_Film_Name': 'Nombre del contenido',
        'FilmModulePanel_Film_Name_Placeholder': 'Nombre',
        'FilmModulePanel_Film_Genre': 'Género',
        'FilmModulePanel_Videos': 'Archivo',
        'FilmModulePanel_Videos_Test_Has_Invited_Testers': 'No es posible cambiar el archivo una vez que se han enviado invitaciones.',
        'FilmModulePanel_Add_Video_Button': 'Subir contenido',
        /* TextModulePanel */
        'TextModulePanel_Title': 'Título',
        'TextModulePanel_Text': 'Texto',
        'TextModulePanel_Button': 'Botón',
        'TextModulePanel_ExternalLink_Title': 'Añadir enlace externo',
        'TextModulePanel_ExternalLink_Button': 'Botón',
        'TextModulePanel_ExternalLink_Link': 'Enlace',
        /* PhonePreview */
        'PhonePreview_Press_Here_Text': 'Pulsa aquí para<br/>iniciar la previsualización',
        /* PhonePreviewDesign */
        'PhonePreviewDesign_Title': 'Lorem Ipsum',
        'PhonePreviewDesign_Text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat',
        'PhonePreviewDesign_Button': 'Lorem Ipsum',
        /* CampaignsTable */
        'CampaignsTable_Column_1': 'Nombre',
        'CampaignsTable_Column_2': 'Estado',
        'CampaignsTable_Column_3': 'Respuestas',
        'CampaignsTable_Column_4': 'Analizados',
        'CampaignsTable_Status_paid': 'Pagado',
        'CampaignsTable_Status_draft': 'Borrador',
        'CampaignsTable_Status_preview': 'Previsualización',
        'CampaignsTable_Status_inreview': 'En revisión',
        'CampaignsTable_Status_public': 'Publicado',
        'CampaignsTable_Status_processing': 'Procesando',
        'CampaignsTable_Status_finished': 'Finalizado',
        'CampaignsTable_Status_finish': 'Finalizado',
        'CampaignsTable_Button_1_OnMouseOver': 'Copiar enlace del Test',
        'CampaignsTable_Button_2_OnMouseOver': 'Ver métricas del Test',
        'CampaignsTable_Button_3_OnMouseOver': 'Editar Test',
        'CampaignsTable_Button_4_OnMouseOver': 'Clonar Test',
        'CampaignsTable_Button_5_OnMouseOver': 'Eliminar Test',
        'CampaignsTable_Button_Share': 'Compartir / Invitar audiencia',
        'CampaignsTable_Button_Edit': 'Editar test',
        'CampaignsTable_Button_More': 'Otras acciones',
        'CampaignsTable_Responses_Info': 'Tests finalizados / Tests empezados',
        /* InputUploadFile_Upload_Image*/
        'InputUploadFile_Upload_Image': 'Cambiar',
        /* Notifications */
        'Notifications_PublishTest_Processing': 'Tu test está procesando algunos archivos ahora, espera a que termine para publicarlo',
        'Notifications_PublishTest_Test_Empty': 'El test necesita tener como mínimo 1 módulo para ser publicado',
        'Notifications_Created_Test': 'Test creado correctamente!',
        'Notifications_Changes_Applied': 'Los cambios han sido guardados correctamente',
        'Notifications_Check_Data': 'Por favor revisa toda la información antes de continuar',
        'Notifications_Ivalid_Test_Plan': 'Por favor elige un tipo de plan para el test antes de continuar',
        'Notifications_Incorrect_Email': 'El correo electrónico que has introducido es incorrecto',
        'Notifications_Conexion_Error': 'No hay conexión a internet. Por favor vuelve a intentarlo en unos minutos',
        'Notifications_Unexpected_Error': 'Error inesperado. Por favor vuelve a intentarlo en unos minutos',
        'Notifications_Invalid_Code': 'Código de acceso inválido',
        'Notifications_Session_Expired': 'La sesión ha caducado',
        'Notifications_Incorrect_Credentials': 'Correo electrónico o contraseña incorrecta',
        'Notifications_Check_Email': 'Revisa tu correo electrónico para verificar tu cuenta antes de iniciar sesión',
        'Notifications_Registered_Email': 'Este correo electrónico ya está registrado',
        'Notifications_Link_Clipboard': 'Enlace copiado al portapapeles!',
        'Notifications_Account_Activated': 'Tu cuenta ha sido activada correctamente!',
        'Notifications_Password_Updated': 'Tu contraseña ha sido actualizada correctamente!',
        'Notifications_Check_Inbox': 'Por favor, revisa tu bandeja de entrada para recuperar tu contraseña',
        'Notifications_Contact_ASAP': 'Te contactaremos en breve',
        'Notifications_Create_Account': 'Por favor, crea una cuenta',
        'Notifications_Publish_Before_Proceed': 'Debes publicar tu Test antes de continuar',
        'Notifications_Empty_Modules': 'Uno o más módulos están vacíos',
        'Notifications_Film_Duration': 'La duración del vídeo debe ser inferior a 180 minutos',
        'Notifications_Film_Size': 'El tamaño de la película debe ser inferior a 7gb',
        'Notifications_Video_Resolution': 'La resolución del vídeo debe ser inferior a 1280px',
        'Notifications_Video_Duration': 'La duración del vídeo debe ser inferior a 5 minutos',
        'Notifications_Video_Cutter': 'online-vídeo-cutter.com',
        'Notifications_Video_Size': 'El tamaño del vídeo debe ser inferior a 500MB',
        'Notifications_Video_Invalid_Format': 'El archivo está corrupto o es incompatible',
        'Notifications_Audio_Duration': 'La duración del audio debe ser menor a 5 minutos',
        'Notifications_Audio_Size': 'El tamaño del audio debe ser inferior a 50MB',
        'Notifications_Image_Resolution': 'El ancho y alto de la imagen no puede exceder los 6000px',
        'Notifications_Image_Size': 'El tamaño de imagen debe ser inferior a 10MB',
        'Notifications_Added_Credit': 'Se ha añadido crédito a tu billetera',
        'Notifications_User_Exists': 'Ya existe una cuenta con este email. Introduce tu contraseña para continuar',
        'Notifications_BuyAudience_Payment_Succesfull': 'Tu audiencia ha sido añadida',
        'Notifications_BuyAudience_Payment_Error': 'No ha sido posible procesar el pago. Por favor, inténtalo de nuevo en unos minutos',
        'Notifications_InviteAudience_Emails_Added_Successfull': 'Los emails se han añadido correctamente',
        'Notifications_InviteAudience_Emails_Added_Invalid': 'Hay emails incorrectos',
        'Notifications_InviteAudience_Emails_Added_Empty': 'Debe haber al menos un email válido',
        'Notifications_InviteAudience_Emails_Added_Max_Testers_Reached': 'Ha alcanzado el número máximo de testers',
        'Notifications_InviteAudience_Emails_Sent_Film_Successfull': 'Las invitaciones serán enviadas cuando los archivos tengan marca de agua',
        'Notifications_InviteAudience_Emails_Sent_Successfull': 'Las invitaciones han sido enviadas correctamente',
        'Notifications_InviteAudience_Emails_Sent_Max_FilmTest_Reached': 'Has alcanzado el máximo de test de tipo película',
        'Notifications_InviteAudience_Testers_Deleted_Successfull': 'Tester(s) eliminado(s) correctamente',
        'Notifications_Recopilator_Link_Deleted_Successfully': 'Link eliminado correctamente',
        'Notifications_Account_Deleted_Successfully': 'Su cuenta de Alyze ha sido eliminada',
        'Notifications_DefineAudience_Segment_Deleted_Successfully': 'Segmento eliminado correctamente',
        'Notifications_DefineAudience_Segment_Added_Max_Segments_Reached': 'Ha alcanzado el número máximo de segmentos de audiencia',
        'Notifications_ReviewTestAndPay_Payment_Succesfull': 'Pago realizado con éxito. Tu test se ha publicado.',
        'Notifications_ReviewTestAndPay_Payment_Error': 'No ha sido posible procesar el pago. Por favor, inténtalo de nuevo en unos minutos',
        /* Account */
        'Account_Browser_Title': 'Alyze | Cuenta',
        'Account_Title': 'Mi cuenta',
        'Account_Title_Plan_Details': 'Detalles de tu cuenta',
        'Account_Title_Session_Details': 'Detalles de inicio de sesión',
        'Account_Title_General_Options': 'Preferencias generales',
        'Account_Input_Label_Name': 'Nombre',
        'Account_Input_Label_Username': 'Nombre de usuario',
        'Account_Input_Label_Password': 'Contraseña',
        'Account_Input_Label_Email': 'Correo electrónico',
        'Account_Input_Label_Language': 'Idioma',
        'Account_Button_Edit': 'Editar',
        'Account_General_Name': 'Nombre',
        'Account_General_Name_Modal_Title': 'Cambia tu nombre',
        'Account_General_Name_Modal_Subtitle': 'Respetamos tu privacidad.',
        'Account_General_Name_Modal_Cancel': 'Cancelar',
        'Account_General_Name_Modal_Confirm': 'Cambiar nombre',
        'Account_General_Lang': 'Idioma',
        'Account_General_Lang_Modal_Title': 'Cambiar idioma',
        'Account_General_Lang_Modal_Subtitle': '',
        'Account_General_Lang_Modal_Cancel': 'Cancelar',
        'Account_General_Lang_Modal_Confirm': 'Cambiar idioma',
        'Account_General_Lang_ES': 'Español',
        'Account_General_Lang_EN': 'Inglés',
        'Account_General_Username': "Nombre de usuario",
        'Account_General_Username_Modal_Title': 'Cambia tu nombre de usuario',
        'Account_General_Username_Modal_Subtitle': 'Respetamos tu privacidad.',
        'Account_General_Username_Modal_Cancel': 'Cancelar',
        'Account_General_Username_Modal_Confirm': 'Cambiar nombre de usuario',
        'Account_Change_Password': 'Contraseña',
        'Account_Change_Password_Subtitle': 'Cambiar contraseña',
        'Account_Change_Password_Current_Password': 'Contraseña actual',
        'Account_Change_Password_New_Password': 'Nueva contraseña',
        'Account_Change_Password_Retype_New_Password': 'Confirma la nueva contraseña',
        'Account_Change_Password_Modal_Title': 'Cambia tu contraseña',
        'Account_Change_Password_Modal_Subtitle': 'Respetamos tu privacidad.',
        'Account_Change_Password_Modal_Cancel': 'Cancelar',
        'Account_Change_Password_Modal_Confirm': 'Cambiar contraseña',
        'Account_DeleteAccount': 'Eliminar mi cuenta',
        'Account_DeleteAccount_Modal_Title': 'Eliminar mi cuenta',
        'Account_DeleteAccount_Modal_Subtitle': 'Esta acción es <b>irreversible</b>',
        'Account_DeleteAccount_Modal_Content_Title': '¿Estás seguro de que quieres <b>eliminar tu cuenta permanentemente</b>?',
        'Account_DeleteAccount_Modal_Content_List_1': 'Todos tus datos personales serán eliminados.',
        'Account_DeleteAccount_Modal_Content_List_2': 'Perderás toda la información relacionada con tus tests.',
        'Account_DeleteAccount_Modal_Content_List_3': 'Ya no podrás acceder a Alyze.',
        'Account_DeleteAccount_Modal_Content_List_4': 'Esta acción no se puede deshacer.',
        'Account_DeleteAccount_Modal_Content_Label': 'Escribe <b>DELETE</b> para confirmar',
        'Account_DeleteAccount_Modal_Cancel': 'Cancelar',
        'Account_DeleteAccount_Modal_Confirm': 'Sí, eliminar mi cuenta',
        /* Campaigns */
        'Campaigns_Browser_Title': 'Alyze | Tests',
        'Campaigns_Title': 'Tests',
        'Campaigns_Review_Campaign_Text_1': 'Tu Test "',
        'Campaigns_Button_View_Metrics': 'VER MÉTRICAS',
        'Campaigns_Status_Active': 'Activo',
        'Campaigns_Status_Paused': 'Pausado',
        'Campaigns_Status_Processing': 'Procesando película',
        'Campaigns_Status_Ready': 'Listo para enviar',
        'Campaigns_Creation_Date': 'Creado el',
        'Campaigns_Review_Campaign_Text_2': '" será revisado por nuestro equipo y publicado en unos minutos',
        'Campaigns_Close_Button': 'Cerrar',
        'Campaigns_No_Campaigns_Found': 'No tienes ningún test.',
        'Campaigns_Start_First_Campaig': '¿Qué tal si creas tu primer test ahora?',
        'Campaigns_New_Campaign_Button': 'Crear Test',
        'Campaigns_Remove_Modal_Title': 'Borrar test',
        'Campaigns_Remove_Modal_Subtitle': 'Esta acción es <b>irreversible</b>',
        'Campaigns_Remove_Modal_Cancel': 'Cancelar',
        'Campaigns_Remove_Modal_Confirm': 'Borrar test',
        'Campaigns_Remove_Campaign_Text_1': '¿Estás seguro de que quieres eliminar <b>[var1]</b> de forma permanente?',
        /* SupportAndHelp */
        'SupportAndHelp_Browser_Title': 'Alyze | Soporte & Ayuda',
        'SupportAndHelp_Title': 'Soporte & Ayuda',
        'SupportAndHelp_Tutorials': 'Tutoriales',
        'SupportAndHelp_FAC': 'Preguntas Frecuentes',
        'SupportAndHelp_Contact': 'Contáctanos',
        'SupportAndHelp_Email': 'Correo electrónico',
        'SupportAndHelp_Email_Address': 'support@byalyze.com',
        'SupportAndHelp_Realtime_Chat': 'Chat en tiempo real',
        'SupportAndHelp_Open_Chat_Button': 'Abrir chat',
        /* CampaignsConfiguration */
        'CampaignsConfiguration_Revert_Changes_Button': "Revertir cambios",
        'CampaignsConfiguration_Saved_Button': "Guardado",
        'CampaignsConfiguration_Save_Changes_Button': "Guardar cambios",
        'CampaignsConfiguration_Back_Button': "Volver",
        'CampaignsConfiguration_Next_Step_Button': "Siguiente",
        /* Design */
        'Design_Browser_Title': 'Alyze | Configuración del Test - Diseño',
        'Design_Title': 'EDITA LA APARIENCIA',
        'Design_Subtitle': 'Puedes personalizar tu test con el estilo de tu marca',
        'Design_Tab_Cover': 'LANDING',
        'Design_Tab_General': 'GENERAL',
        'Design_General_Subtitle': 'General',
        'Design_Colors': 'Colores',
        'Design_Colors_Title': 'Título',
        'Design_Text': 'Texto',
        'Design_Background': 'Color',
        'Design_Logo': 'Logo',
        'Design_Dropdown_Image': 'Imagen',
        'Design_Dropdown_Text': 'Texto',
        'Design_Dropdown_None': 'Ninguno',
        'Design_Dropdown_Solid': 'Sólido',
        'Design_Dropdown_Border': 'Bordes',
        'Design_Background_Image': 'Fondo',
        'Design_Primary_Button': 'Botones',
        'Design_Border_Radius': 'Esquinas',
        'Design_Secondary_Button': 'Botón secundario',
        'Design_Landing_Subtitle': 'Presentación',
        'Design_Not_Included': 'No incluido en este test',
        /* Metrics */
        'Metrics_Browser_Title': 'Alyze | Resultados del Test - Métricas',
        'Metrics_Title': 'Métricas',
        'Metrics_Realtime_Stats': 'Estadísticas en tiempo real',
        'Metrics_Realtime_Stats_Text': 'Pulsa sobre el contenido para ver<br/> las estadísticas en tiempo real',
        'Metrics_No_Results': 'Todavía no hay respuestas',
        'Metrics_No_Results_Text': 'Regresa más tarde para consultar tus respuestas en tiempo real',
        'Metrics_No_Results_Lite': 'Aún no hay respuestas',
        'Metrics_No_Results_Text_Lite': 'Para conseguir tus resultados, recuerda compartir el enlace que te hemos enviado a tu email. Cuantas más respuestas obtengas, ¡mejores resultados tendrás!',
        /* Modules */
        'Modules_Click_To_Edit': 'Pulsa aquí para editar',
        'Modules_Editing': 'Editando...',
        'Modules_Empty_Tag': 'Módulo vacío',
        'Modules_Browser_Title': 'Alyze | Configuración del Test - Módulos',
        'Modules_Title': 'AGREGA MÓDULOS',
        'Modules_Subtitle': 'Haz click o arrastra para agregar un nuevo módulo',
        'Modules_Drag_To_Remove': 'Arrastra el módulo aquí para eliminarlo',
        'Modules_Drag_To_Remove_Tooltip': 'Arrastra un módulo aquí para borrarlo',
        'Modules_Title_Finish': 'Finalizar',
        'Modules_Title_Landing': 'Landing',
        'Modules_Title_Introduction': 'Introducción',
        'Modules_Title_Login': 'Login',
        'Modules_Save_Campaign_To_Preview_Modal_Title': 'Previsualizar módulo',
        'Modules_Save_Campaign_To_Preview_Modal_Subtitle': 'Guardar e ir a la vista previa',
        'Modules_Save_Campaign_To_Preview_Modal_Cancel': 'Cancelar',
        'Modules_Save_Campaign_To_Preview_Modal_Confirm': 'Guardar y previsualizar',
        'Modules_Save_Campaign_To_Preview_Text': 'Es necesario guardar los cambios para poder previsualizar el módulo.',
        'Modules_Max_Duration_Test_Modal_Title': 'Advertencia',
        'Modules_Max_Duration_Test_Modal_Subtitle': 'Duración máxima del test alcanzada',
        'Modules_Max_Duration_Test_Modal_Confirm': 'Seguir editando',
        //'Modules_Max_Duration_Test_Text': 'Has excedido el tiempo máximo por test de tu plan.<br/>Modifica la duración de los módulos para continuar.<br/><br/>Para aumentar el límite, mejora tu ',
        'Modules_Max_Duration_Test_Text': 'Has excedido el tiempo máximo por test.<br/>Modifica la duración de los módulos para continuar.',
        'Modules_Duration_Film_Test_Modal_Title': 'Advertencia',
        'Modules_Duration_Film_Test_Modal_Subtitle': 'El archivo no llega a la duración mínima',
        'Modules_Duration_Film_Test_Modal_Confirm': 'Seguir editando',
        'Modules_Duration_Film_Test_Text': 'El archivo debe tener al menos <strong>3 minutos de duración</strong>.<br/><br/> Puedes crear un test de tipo multimedia para testear vídeos de<br/> menor duración, utilizando el <strong>módulo vídeo</strong>.',
        /* Preview */
        'Preview_Browser_Title': 'Alyze | Vista previa',
        'Preview_Title': 'VISTA PREVIA',
        'Preview_Subtitle': 'Puedes probar y compartir una vista previa del test antes de publicarlo',
        'Preview_Try_Test_Title': 'Prueba tu test',
        'Preview_Try_Test_Text': 'Prueba tu test exáctamente igual que lo verán tus usuarios',
        'Preview_Try_Test_CTA': 'Ver mi test',
        'Preview_Publish_Title': 'Publica la vista previa',
        'Preview_Publish_Text_A': 'Activa esta opción para publicar tu vista previa. Activarás el enlace que se puede compartir a continuación.',
        'Preview_Publish_Text_B': 'Desactívala en cualquier momento y nadie tendrá acceso al enlace',
        'Preview_Share_Title': 'Comparte la vista previa',
        'Preview_Share_CTA': 'Copiar enlace',
        'Preview_Share_Text': '<b>Copia y envía este enlace para permitir el acceso</b> a la vista previa mientras la tengas publicada',
        /* Define audience */
        'DefineAudience_Browser_Title': 'Alyze | Configuración del Test - Define tu audiencia objetivo',
        'DefineAudience_Choose_Audience_Type_Title': '¿Cómo quieres recopilar las respuestas de tu test?',
        'DefineAudience_Choose_Audience_Type_Description': 'Una vez que invites al primer tester, no podrás cambiar el método de obtención de audiencia.',
        'DefineAudience_Choose_Audience_Type_Box_Links_Tag': 'Enlaces públicos',
        'DefineAudience_Choose_Audience_Type_Box_Links_Title': 'Crea enlaces públicos y compártelos',
        'DefineAudience_Choose_Audience_Type_Box_Emails_Tag': 'Invitaciones por email',
        'DefineAudience_Choose_Audience_Type_Box_Emails_Title': 'Envía invitaciones por email con links únicos',
        'DefineAudience_Choose_Audience_Type_Box_Emails_Description': 'Se generará un enlace único para cada uno de ellos y se enviará por email, dándoles acceso a tu contenido.',
        'DefineAudience_Choose_Audience_Type_Box_Alyze_Audience_Tag': 'Audiencia de Alyze',
        'DefineAudience_Choose_Audience_Type_Box_Alyze_Audience_Title': 'Enfócate en las personas ideales para tu encuesta',
        'DefineAudience_Choose_Audience_Type_Note': 'NOTA: No ofrecemos audiencia de Alyze a los test que incluyen el módulo de película.',
        'DefineAudience_Alyze_Audience_Title': 'Tamaño de la muestra de la audiencia de Alyze',
        'DefineAudience_Alyze_Audience_Description': 'El tamaño de la muestra condicionará la calidad de los datos.',
        'DefineAudience_Alyze_Audience_Data_Quality': 'Calidad de datos esperada: ',
        'DefineAudience_Alyze_Audience_Data_Quality_Value': 'Media (rango de error ≤ [var1]%)',
        'DefineAudience_Add_Audience_Title': 'Define tu audiencia',
        'DefineAudience_Add_Audience_Description': 'Selecciona tu muestra en función de la edad, el sexo y la ubicación.<br/> Ten en cuenta que puedes añadir una muestra o comparar entre dos o más grupos, según tu plan. Si necesitas alguna característica de segmentación adicional, contacta con nosotros.',
        'DefineAudience_Add_Audience_Add_Button': 'Añadir otro grupo de muestra',
        'DefineAudience_Add_Audience_New_Group': 'NUEVO GRUPO: Alias',
        'DefineAudience_Add_Audience_Sample_Name': 'Nueva muestra',
        'DefineAudience_Add_Audience_Sample_Size': 'Tamaño de la muestra',
        'DefineAudience_Delete_Segment_Modal_Title': 'Eliminar muestra',
        'DefineAudience_Delete_Segment_Modal_Subtitle': 'Estás a punto de eliminar una muestra',
        'DefineAudience_Delete_Segment_Modal_Text': '¿Seguro que quieres borrar <b>[var1]</b>?',
        'DefineAudience_Delete_Segment_Modal_Cancel': 'No, cancelar',
        'DefineAudience_Delete_Segment_Modal_Confirm': 'Sí, eliminar',
        'DefineAudience_Edit_Segment_Modal_Title': 'Editar nombre de la muestra',
        'DefineAudience_Edit_Segment_Modal_Subtitle': 'El nombre de la muestra te ayudará a identificar mejor tu grupo de audiencia',
        'DefineAudience_Edit_Segment_Modal_Cancel': 'Cancelar',
        'DefineAudience_Edit_Segment_Modal_Confirm': 'Guardar cambios',
        'DefineAudience_Edit_Segment_Input_Name': 'Nombre de la muestra',
        'DefineAudience_Add_Segment_Modal_Title': 'Añadir muestra',
        'DefineAudience_Add_Segment_Modal_Subtitle': 'Etiqueta tus grupos para poder identificarlos durante el test',
        'DefineAudience_Add_Segment_Modal_Cancel': 'Cancelar',
        'DefineAudience_Add_Segment_Modal_Confirm': 'Añadir muestra',
        'DefineAudience_Add_Segment_Input_Name': 'Nombre de la muestra',
        'DefineAudience_Segment_Alias': 'GRUPO [var1]:',
        'DefineAudience_Segment_Location_Title': 'Ubicación',
        'DefineAudience_Segment_Location_Text': 'Ubicación',
        'DefineAudience_Segment_Location_Text_All': 'Todas',
        'DefineAudience_Segment_Gender_Title': 'Género',
        'DefineAudience_Segment_Gender_Text': 'Sexo',
        'DefineAudience_Segment_Gender_Text_Male': 'Hombre',
        'DefineAudience_Segment_Gender_Text_Female': 'Mujer',
        'DefineAudience_Segment_Gender_Text_Both': 'Ambos',
        'DefineAudience_Segment_Age_Title': 'Edad',
        'DefineAudience_Segment_Age_Text': 'Edad',
        /* Review test and pay */
        'Review_Test_And_Pay_Browser_Title': 'Alyze | Revisa tu test y págalo',
        'Review_Test_And_Pay_Audience_Groups_Title': 'Grupos de audiencia',
        'Review_Test_And_Pay_Audience_Groups_Total_Responses': 'Total respuestas',
        'Review_Test_And_Pay_Audience_Groups_Acquisition_Method': 'Método de adquisición',
        'Review_Test_And_Pay_Audience_Groups_Acquisition_Method_UnChosen': '-',
        'Review_Test_And_Pay_Audience_Groups_Acquisition_Method_UnChosen_Warning': 'Debes elegir un método de adquisición de audiencia',
        'Review_Test_And_Pay_Audience_Groups_Acquisition_Method_No_Segment_Created_Warning': 'Debes crear al menos un segmento de audiencia',
        'Review_Test_And_Pay_Test_Summary_Title': 'Resumen de tu test',
        'Review_Test_And_Pay_Test_Summary_Estimated_Duration': 'Duración estimada del test',
        'Review_Test_And_Pay_Test_Summary_Estimated_Duration_Disclaimer': 'Tu test puede durar adicionalmente entre 2-4 min. por eventos fuera de nuestro control (calidad de conexión a internet, procesamiento de respuestas, etc.).',
        'Review_Test_And_Pay_Pay_And_Publish_Button': 'Pagar y publicar',
        'Review_Test_And_Pay_Free_Publish_Button': 'Publicar',
        'Review_Test_And_Pay_Missing_Options_Modal_Title': 'Faltan opciones por configurar',
        'Review_Test_And_Pay_Missing_Options_Modal_Subtitle': '',
        'Review_Test_And_Pay_Missing_Options_Modal_Confirm': 'Cerrar',
        'Review_Test_And_Pay_Missing_Options_Modal_Missing_Text': 'Para continuar, por favor revisa las siguientes opciones:',
        'Review_Test_And_Pay_Missing_Options_Modal_Missing_Policy': 'Aceptar la <strong>política de privacidad</strong>',
        'Review_Test_And_Pay_Missing_Options_Modal_Missing_Modules': 'Al menos un <strong>módulo</strong> en el test',
        'Review_Test_And_Pay_Missing_Options_Modal_Missing_Segments': 'Al menos un <strong>segmento de audiencia</strong>',
        'Review_Test_And_Pay_Missing_Options_Modal_Missing_Acquisition_Method': 'Elegir un <strong>método de adquisición de audiencia</strong>',
        'Review_Test_And_Pay_Notice_Before_Payment_Modal_Title': 'Aviso importante',
        'Review_Test_And_Pay_Notice_Before_Payment_Modal_Subtitle': '',
        'Review_Test_And_Pay_Notice_Before_Payment_Modal_Text1': 'Los módulos del test no podrán ser modificados.',
        'Review_Test_And_Pay_Notice_Before_Payment_Modal_Text2': 'Serás redirigido a Stripe para proceder al pago.',
        'Review_Test_And_Pay_Notice_Before_Payment_Modal_Confirm': 'Continuar con el pago',
        'Review_Test_And_Pay_Notice_Before_Payment_Modal_Confirm_Free': 'Publicar',
        'Review_Test_And_Pay_Notice_Before_Payment_Modal_Cancel': 'Cancelar',
        /* Recopilator */
        'Recopilator_Browser_Title': 'Alyze | Recopiladores de respuestas',
        'Recopilator_GlobalSummary_Title': 'Resumen general',
        'Recopilator_Links_Title': 'Envía encuestas a tu manera',
        'Recopilator_Links_InnerText': 'Crea enlaces públicos y distribúyelos a tu manera. Mientras el test esté activo podrás crear tantos links como necesites para segmentar tu audiencia entre diferentes métodos de adquisición.<br/><br/><b>¿Te cuesta obtener respuestas? <a href="javascript:[var1]">Contáctanos</a> y nos encargaremos de todo por ti.</b>',
        'Recopilator_Links_Text': 'Envía tus encuestas por correo electrónico, insértalas en tu sitio web, comparte un enlace en tus redes sociales o donde quieras.',
        'Recopilator_Links_Add': 'Añadir audiencia propia',
        'Recopilator_Links_Copy': 'Copiar',
        'Recopilator_Links_Toggle': 'Activo',
        'Recopilator_Links_Paid_Title': 'Contrata a personas reales para tu encuesta',
        'Recopilator_Links_Paid_Text': 'Compra respuestas de tu público objetivo. Personas reales de todo el mundo, cuidadosamente clasificadas para que coincidan con tu perfil deseado.',
        'Recopilator_Links_Paid_Add': 'Contrata audiencia',
        'Recopilator_Links_Paid_Button': 'Ver características',
        'Recopilator_Metrics_Responses': 'Respuestas obtenidas',
        'Recopilator_Links_Paid_Location': 'Ubicación',
        'Recopilator_Links_Paid_Genre': 'Género',
        'Recopilator_Links_Paid_Age': 'Edad',
        'Recopilator_Metrics_Location': 'Localización predominante',
        'Recopilator_Metrics_Genre': 'Género predominante',
        'Recopilator_Metrics_Age': 'Edad media',
        'Recopilator_Edit_Link_Modal_Title': 'Editar link',
        'Recopilator_Edit_Link_Modal_Subtitle': 'El alias te ayudará a identificar mejor tu audiencia',
        'Recopilator_Edit_Link_Modal_Cancel': 'Cancelar',
        'Recopilator_Edit_Link_Modal_Confirm': 'Guardar cambios',
        'Recopilator_Edit_Link_Input_Name': 'Alias',
        'Recopilator_Add_Link_Modal_Title': 'Crear link',
        'Recopilator_Add_Link_Modal_Subtitle': 'Crea tantos links como necesites para segmentar tu audiencia. (Ej: Crea 3 links diferentes para hacer un seguimiento de tus redes sociales, correo electrónico y tu página web)',
        'Recopilator_Add_Link_Modal_Cancel': 'Cancelar',
        'Recopilator_Add_Link_Modal_Confirm': 'Crear link',
        'Recopilator_Add_Link_Input_Name': 'Alias',
        'Recopilator_Add_Link_Warning_Message': 'Si creas tu primer link, este será tu método de obtención de audiencia y no podrá ser cambiado.',
        'Recopilator_Delete_Link_Modal_Title': 'Eliminar link',
        'Recopilator_Delete_Link_Modal_Subtitle': 'Estás a punto de eliminar un link',
        'Recopilator_Delete_Link_Modal_Text_1': '<b>Advertencia:</b> este link ya tiene respuestas.',
        'Recopilator_Delete_Link_Modal_Text_2': 'Al eliminar el link, perderás todas las respuestas asociadas al mismo.',
        'Recopilator_Delete_Link_Modal_Text_3': 'Ya no será posible acceder al test desde este link.',
        'Recopilator_Delete_Link_Modal_Text_4': '¿Seguro que quieres borrar <b>[var1]</b>?',
        'Recopilator_Delete_Link_Modal_Cancel': 'No, cancelar',
        'Recopilator_Delete_Link_Modal_Confirm': 'Sí, eliminar',
        /*
        'Recopilator_User_Max_Tests_Reached_Modal_Title': 'Amplia tu plan para continuar',
        'Recopilator_User_Max_Tests_Reached_Modal_Subtitle': 'Has superado el límite de test de este mes',
        'Recopilator_User_Max_Tests_Reached_Modal_Cancel': 'Cerrar',
        'Recopilator_User_Max_Tests_Reached_Modal_Confirm': 'Ver planes',
        'Recopilator_User_Max_Tests_Reached_Modal_Text_1': 'Tu plan [var1] te permite publicar hasta [var2] tests cada mes.',
        'Recopilator_User_Max_Tests_Reached_Modal_Text_2': 'Para publicar este test debes esperar a que se renueve el periodo mensual o ampliar tu cuenta a un plan superior',
        */
        'Recopilator_User_Max_Tests_Reached_Modal_Title': 'Límite alcanzado',
        'Recopilator_User_Max_Tests_Reached_Modal_Subtitle': 'Has superado el límite de test de este mes',
        'Recopilator_User_Max_Tests_Reached_Modal_Confirm': 'Ok',
        'Recopilator_User_Max_Tests_Reached_Modal_Text_1': 'Has alcanzado el máximo de test mensuales.',
        'Recopilator_User_Max_Tests_Reached_Modal_Text_2': 'Para publicar este test debes esperar a que se renueve el periodo mensual',

        'Recopilator_Choose_Audience_Type_Title': '¿Cómo quieres recopilar las respuestas? ',
        'Recopilator_Choose_Audience_Type_Description': 'Once you invite the first tester, you will not be able to change the audience acquisition method.',
        'Recopilator_Choose_Audience_Type_Box_Links_Tag': 'Acceso por enlace',
        'Recopilator_Choose_Audience_Type_Box_Links_Title': 'Crea enlaces públicos y distribúyelos a tu manera',
        'Recopilator_Choose_Audience_Type_Box_Emails_Tag': 'Invitación por email',
        'Recopilator_Choose_Audience_Type_Box_Emails_Title': 'Envia invitaciones por email con enlaces únicos',
        'Recopilator_Choose_Audience_Type_Box_Emails_Description': 'Se creará un enlace único para acceder al test por cada invitación y se enviará por email.',
        'Recopilator_Choose_Audience_Type_Box_Alyze_Audience_Tag': 'Alyze audience',
        'Recopilator_Choose_Audience_Type_Box_Alyze_Audience_Title': 'Obtén la audiencia ideal para tu test.',
        /*
        'Recopilator_Choose_Audience_Type_Title': '¿Cómo quieres recopilar las respuestas de tu test?',
        'Recopilator_Choose_Audience_Type_Description': 'Una vez que invites al primer tester, no podrás cambiar el método de obtención de audiencia.',
        'Recopilator_Choose_Audience_Type_Box_Links_Tag': 'Enlaces',
        'Recopilator_Choose_Audience_Type_Box_Links_Title': 'Crea enlaces públicos y compártelos',
        'Recopilator_Choose_Audience_Type_Box_Emails_Tag': 'Invitaciones por email',
        'Recopilator_Choose_Audience_Type_Box_Emails_Title': 'Envía invitaciones por email con links únicos',
        'Recopilator_Choose_Audience_Type_Box_Emails_Description': 'Se generará un enlace único para cada uno de ellos y se enviará por email, dándoles acceso a tu contenido.',
        'Recopilator_Choose_Audience_Type_Box_Alyze_Audience_Tag': 'Audiencia de Alyze',
        'Recopilator_Choose_Audience_Type_Box_Alyze_Audience_Title': 'Enfócate en las personas ideales para tu encuesta',
        */
        'Recopilator_Status_Processing_Warning_Title':'Aviso: Se está procesando el test',
        'Recopilator_Status_Processing_Warning_Text':'El link no funcionará hasta que el test haya terminado de procesarse.',
        'Recopilator_Interactions_Processing_Warning_Title':'Procesando datos',
        'Recopilator_Interactions_Processing_Warning_Text':'Estamos procesando algunos datos. Esto puede causar algunos resultados inconsistentes. Espere a que se calculen todos los datos para ver los resultados reales.',
        'Recopilator_Interactions_Processing_Warning_Button':'Actualizar datos',
        /* Buy audience */
        'BuyAudience_Go_Back': 'Volver a recopiladores del test',
        'BuyAudience_Title': 'Audiencia de pago',
        'BuyAudience_Description': '¿No tienes tu propia lista de posibles encuestados? Alyze Audience te permite enviar tu test a las personas correctas. Elige tu público objetivo y enviaremos tu test a personas reales de nuestro panel mundial que coincidan con tus criterios.',
        'BuyAudience_Warning_Message': 'Si contratas audiencia, este será tu método de obtención de audiencia y no podrá ser cambiado.',
        'BuyAudience_Step1_Title': 'PASO 1: ¿De quién quieres obtener respuestas?',
        'BuyAudience_Step1_Description': 'Alyze se basa en datos confiables y de alta calidad.',
        'BuyAudience_Step1_Link': 'Averigua cómo la calidad de nuestros datos te ayuda a tener éxito.',
        'BuyAudience_Step1_Location_Title': 'Ubicación',
        'BuyAudience_Step1_Location_Text': 'Ubicación',
        'BuyAudience_Step1_Location_Text_All': 'Todas',
        'BuyAudience_Step1_Genre_Title': 'Género',
        'BuyAudience_Step1_Genre_Text': 'Sexo',
        'BuyAudience_Step1_Genre_Text_Male': 'Hombre',
        'BuyAudience_Step1_Genre_Text_Female': 'Mujer',
        'BuyAudience_Step1_Genre_Text_Both': 'Ambos',
        'BuyAudience_Step1_Age_Title': 'Edad',
        'BuyAudience_Step1_Age_Text': 'Edad',
        'BuyAudience_Step2_Title': 'PASO 2: ¿Cuántas respuestas completas necesitas?',
        'BuyAudience_Step2_Description': 'Margen de error ≤ 4%',
        'BuyAudience_Total_Cost_Title': 'Coste total',
        'BuyAudience_Total_Cost_Tax': 'IVA incluido',
        'BuyAudience_Total_Cost_Responses': 'Respuestas a pagar',
        'BuyAudience_Total_Cost_Responses_Total': 'Respuestas totales',
        'BuyAudience_Total_Cost_Price': 'Precio por respuesta',
        'BuyAudience_Total_Cost_Duration': 'Duración del test',
        'BuyAudience_Total_Cost_Rules': 'Reglas de segmentación',
        'BuyAudience_Total_Cost_Button': 'Proceder al pago',
        'BuyAudience_Promo': 'Promoción',
        'BuyAudience_Promo_Name': 'Promoción [var1]',
        'BuyAudience_Promo_Text': '2X (GRATIS)',
        'BuyAudience_Promo_Description': 'duplicamos el número de respuestas pagadas de manera gratuita.',
        'BuyAudience_Custom_Segments_Link': 'Contacta con nosotros ',
        'BuyAudience_Custom_Segments': 'para obtener una segmentación más detallada.',
        /* Invite audience */
        'Settings_Modal_Title': 'Configurar invitaciones',
        'Settings_Modal_Subtitle': 'Cambiar el título y el remitente que se mostrará en las invitaciones',
        'Settings_Modal_Confirm': 'Aplicar cambios',
        'Settings_Modal_Cancel': 'Cancelar',
        'InviteAudience_Send_All_Unsent_Title': '!Atención!',
        'InviteAudience_Send_All_Unsent_Text': 'Hay una o más invitaciones sin enviar',
        'InviteAudience_Send_All_Unsent_Button': 'Enviar invitaciones pendientes',
        'InviteAudience_Email_Configuration_Title': 'Configuración',
        'InviteAudience_Email_Configuration_Description': 'Elige el título del test y el remitente que verán los testers en el email de invitación. Una vez que se invite al primer tester, estos campos no podrán ser cambiados.',
        'InviteAudience_Email_Configuration_Input_Test_Title': 'Título del test',
        'InviteAudience_Email_Configuration_Input_Sender': 'Remitente del email',
        'InviteAudience_Email_Configuration_Button_Save': 'Aplicar cambios',
        'InviteAudience_Title': 'Comparte tu test',
        'InviteAudience_Description': 'Envía invitaciones por email a las personas que quieras testear.<br/> Se generará un enlace único para cada uno de ellos y se enviará por email, dándoles acceso a tu contenido.<br/> Podrás bloquear a cualquier tester, en cualquier momento.',
        'InviteAudience_Button_Add': 'Añadir testers',
        'InviteAudience_Button_Reactivate': 'Reenviar',
        'InviteAudience_Button_Invite': 'Enviar',
        'InviteAudience_Button_Delete': 'Borrar',
        'InviteAudience_Button_ImportCsv': 'Importar CSV',
        'InviteAudience_Button_Configure_Email': 'Configurar invitaciones',
        'InviteAudience_Actions': 'Acciones',
        'InviteAudience_Actions_Send': 'Enviar test',
        'InviteAudience_Actions_Disable': 'Deshabilitar link',
        'InviteAudience_Actions_Enable': 'Habilitar link',
        'InviteAudience_Actions_Delete': 'Borrar testers',
        'InviteAudience_Actions_ExportCsv': 'Exportar a CSV',
        'InviteAudience_Table_Column1': 'Email',
        'InviteAudience_Table_Column2': 'Estado',
        'InviteAudience_Table_Column3': 'Habilitado',
        'InviteAudience_Table_Column4': 'Acciones',
        'InviteAudience_Table_Date': 'Añadido el [var1]',
        'InviteAudience_Table_Status_NoTracking': 'No incluido en este test',
        'InviteAudience_Table_Status_Finished': 'Finalizado',
        'InviteAudience_Table_Status_Sent': 'Enviado',
        'InviteAudience_Table_Status_Started': 'Empezado',
        'InviteAudience_Table_Status_Watermarking': 'Generando marca de agua...',
        'InviteAudience_Table_Status_Processing': 'Procesando...',
        'InviteAudience_Table_Status_Added': 'Añadido',
        'InviteAudience_Table_Status_Left': 'Abandonado',
        'InviteAudience_Table_Status_Discarded': 'Descartado',
        'InviteAudience_Table_Loading_Sending': 'Enviando email...',
        'InviteAudience_Table_Loading_Enabling': 'Habilitando link...',
        'InviteAudience_Table_Loading_Disabling': 'Deshabilitando link... ',
        'InviteAudience_ImportCsv_Modal_Title': 'Invita a testers de confianza para participar',
        'InviteAudience_ImportCsv_Modal_Subtitle': 'Añade tantas direcciones de email como quieras, después podrás invitarlos y nosotros les enviaremos un enlace por ti con los datos de acceso privados.',
        'InviteAudience_ImportCsv_Modal_Csv_Example': 'Puedes descargar nuestra <a href=[var1] download=[var2]>lista de emails</a> en formato CSV de ejemplo',
        'InviteAudience_ImportCsv_Modal_Csv_Select_Column': 'Selecciona la columna email',
        'InviteAudience_ImportCsv_Modal_Confirm': 'Añadir testers',
        'InviteAudience_Modal_Title': 'Invita a testers de confianza para participar',
        'InviteAudience_Modal_Subtitle': 'Añade tantas direcciones de email como quieras y nosotros les enviaremos un enlace por ti con los datos de acceso privados.',
        'InviteAudience_Modal_Input_Label_Email': 'Email',
        'InviteAudience_Modal_Button_Add_Email': 'Añadir otro email',
        'InviteAudience_Modal_Confirm': 'Añadir testers',
        'InviteAudience_Modal_Warning_Message': 'Si añades uno o más emails, este será tu método de obtención de audiencia y no podrá ser cambiado.',
        'InviteAudience_Empty_Test_Error_Modal_Title': 'Tu test no tiene ninguna película',
        'InviteAudience_Empty_Test_Error_Modal_Subtitle': 'Para compartir tu test debes añadir una película primero.',
        'InviteAudience_Empty_Test_Error_Modal_Confirm': 'Continuar',
        'InviteAudience_No_Films_Error_Modal_Title': 'Límite de peliculas sobrepasado',
        'InviteAudience_No_Films_Error_Modal_Subtitle': 'Para testear más películas contacta con nosotros.',
        'InviteAudience_No_Films_Error_Modal_Confirm': 'Continuar',
        // 'InviteAudience_First_Invitation_Notice_Modal_Title': 'Aviso: No se podrá cambiar la película',
        // 'InviteAudience_First_Invitation_Notice_Modal_Subtitle': 'El test quedará publicado una vez se envíe la primera invitación y la película ya no se podrá editar.',
        'InviteAudience_First_Invitation_Notice_Modal_Title': 'Aviso: No se podrá cambiar el título del test ni el remitente',
        'InviteAudience_First_Invitation_Notice_Modal_Subtitle': 'Estos campos quedarán deshabilitados una vez se envíe la primera invitación y ya no se podrán editar.',
        'InviteAudience_First_Invitation_Notice_Modal_Text': 'The test will be send with the default test title (<b>[var1]</b>) and the default test sender (<b>[var2]</b>).<br/><br/>This settings can be changed before the invitation has been send.',
        'InviteAudience_First_Invitation_Notice_Modal_Confirm': 'Ok, enviar',
        'InviteAudience_First_Invitation_Notice_Modal_Cancel': 'Cancelar',
        'InviteAudience_DeleteTester_Modal_Title': 'Eliminar testers',
        'InviteAudience_DeleteTester_Modal_Subtitle': 'Puedes volver a añadirlo más tarde',
        'InviteAudience_DeleteTester_Modal_Text_Only': '¿Seguro que quieres borrar al tester <strong>[var1]</strong>?',
        'InviteAudience_DeleteTester_Modal_Text': '¿Seguro que quieres borrar a <strong>[var1]</strong> testers?',
        'InviteAudience_DeleteTester_Modal_Confirm': 'Sí, borrar',
        'InviteAudience_No_Testers_Yet_Title': 'No hay testers aún',
        'InviteAudience_No_Testers_Yet_Description': 'Puedes invitar testers manualmente o subir un archivo CSV.',
        /* Status */
        'Status_Browser_Title': 'Alyze | Resultados del Test - Audiencia',
        'Status_Title': 'Audiencia',
        'Status_No_Audience': 'Este Test todavía no tiene <br/> resultados de audiencia',
        /* ContactUsWidget */
        'ContactUsWidget_Title': '¿Tienes alguna duda o sugerencia?',
        'ContactUsWidget_Text': 'Si necesitas ayuda o algo específico, contáctanos y déjanos ayudarte.',
        'ContactUsWidget_Button_Contact_Us': 'Contáctanos',
        /* Login */
        'Login_Browser_Title': 'Alyze | Iniciar sesión',
        'Login_Email': 'Correo electrónico',
        'Login_Email_Placeholder': 'Escribe tu correo electrónico',
        'Login_Password': 'Contraseña',
        'Login_Password_Placeholder': 'Escribe tu contraseña',
        'Login_Log_In_Button': 'Iniciar sesión',
        'Login_Forgotten_Password': '¿Has olvidado tu contraseña?',
        'Login_Dont_Have_Account': '¿Aún no tienes una cuenta?',
        'Login_Create_Test_Button': 'CREA TU PRIMER TEST',
        'Login_Register_Button': 'REGISTRARSE AQUÍ',
        /* RememberPassword */
        'RememberPassword_Browser_Title': 'Alyze | Inicio de Sesión',
        'RememberPassword_Title': '¿Has olvidado tu contraseña?',
        'RememberPassword_Send_Email_Button': 'Enviar',
        'RememberPassword_Back_To_Login_Button': 'Volver a inicio de sesión',
        /* Singup */
        'Singup_Browser_Title': 'Alyze | Registro',
        'Singup_Title': 'Crear nueva cuenta',
        'Singup_Name': 'Nombre completo',
        'Singup_Name_Placeholder': 'Escribe tu nombre completo',
        'Singup_Code': 'Código promocional (opcional)',
        'Singup_Code_Placeholder': '0000-0000-0000',
        'Singup_Create_Account_Button': 'CREAR NUEVA CUENTA',
        'Singup_Password': 'Contraseña',
        'Singup_Password_Placeholder': 'Crea tu contraseña',
        /* SingupConfirm */
        'SingupConfirm_Browser_Title': 'Alyze | Confirmación de registro',
        'SingupConfirm_Title': 'Activa tu cuenta!',
        'SingupConfirm_Text': 'Revisa tu bandeja de entrada para <br/><b>activar tu cuenta</b>',
        /* SubscriptionWidget */
        'SubscriptionWidget_Plan_Type': 'Tipo de cuenta',
        'SubscriptionWidget_Plan_Usage': 'Uso de tu cuenta',
        'SubscriptionWidget_Consumed_Tests': '<b>[var1]</b> / [var2] este mes',
        'SubscriptionWidget_Consumed_Film_Tests': '<b>[var1]</b> / [var2]',
        'SubscriptionWidget_Btn_Subscribe_Plan': 'Ampliar plan',
        'SubscriptionWidget_Btn_Change_Plan': 'Ampliar plan',
        'SubscriptionWidget_Yearly': 'Anual',
        'SubscriptionWidget_Montly': 'Mensual',
        'SubscriptionWidget_Tests_Number': 'tests',
        'SubscriptionWidget_Tests_Unlimited': 'Tests ILIMITADOS',
        'SubscriptionWidget_Plan_Renovation': 'Se restablecerán el [var1]',
        'SubscriptionWidget_Plan_Info_Renovation': 'Tu suscripción [var1] se renovará automaticamente el [var2]',
        'SubscriptionWidget_Plan_Info_Ends': 'Tu suscripción [var1] terminará el [var2]',
        'SubscriptionWidget_Legend_Tests': 'Tests',
        'SubscriptionWidget_Legend_Films': 'Film tests',
        /* SubscriptionPlan */
        'SubscriptionPlan_Featured_Badged': 'EL MÁS POPULAR',
        'SubscriptionPlan_Price_Month': '/ MES',
        'SubscriptionPlan_Description': 'Publica <b>[var1] test</b> de hasta <b>[var2] minutos</b> cada mes',
        'SubscriptionPlan_Description_Unlimited': 'Publica <b>todos los tests que necesites</b> de hasta <b>[var1] minutos</b>',
        'SubscriptionPlan_Active_From': 'Suscripción activa<br/>desde el [var1]',
        'SubscriptionPlan_Active_To': 'Tu subscripción<br/>finalizará el [var1]',
        'SubscriptionPlan_Pay_Yearly': 'Pago anual',
        'SubscriptionPlan_Pay_Montly': 'Pago mensual',
        'SubscriptionPlan_Btn_Change_Plan': 'Cambiar a [var1]',
        'SubscriptionPlan_Characteristics': '<p>Test de hasta <b>[var1] minutos</b></p>',
        'SubscriptionPlan_Montly_Tests': '<p><b>[var1] Tests</b> al mes</p>',
        'SubscriptionPlan_Unlimited_Tests': '<p><b>Tests ILIMITADOS</b></p>',
        'SubscriptionPlan_Btn_More_Details': ' Ver detalles',
        'SubscriptionPlan_Title': 'Elige tu plan',
        'SubscriptionPlan_Subtitle_1': 'Te ofrecemos diferentes planes para que elijas el que más se ajuste a tus necesidades',
        'SubscriptionPlan_Subtitle_2': 'Podrás cambiarlo en cualquier momento',
        'SubscriptionPlan_Custom_Name': 'custom',
        'SubscriptionPlan_Custom_Description': '¿Necesitas un servicio personalizado?',
        'SubscriptionPlan_Btn_Contact': 'Contactar',
        'SubscriptionPlan_Downgrade_Modal_Title': 'Cancelar suscripción',
        'SubscriptionPlan_Downgrade_Modal_Subtitle': 'Tu subscripción actual seguirá activa durante el periodo pagado',
        'SubscriptionPlan_Downgrade_Text': '¿Estás seguro de que deseas cancelar tu suscripción?',
        'SubscriptionPlan_Downgrade_Modal_Cancel': 'Cancelar',
        'SubscriptionPlan_Downgrade_Modal_Confirm': 'Confirmar',
        'SubscriptionPlan_Facturation_Montly': 'Facturación mensual',
        'SubscriptionPlan_Facturation_Yearly': 'Facturación anual',
        'SubscriptionPlan_Yearly_Discount': 'Ahorra 16%',
        /* TopBar */
        'TopBar_My_Tests': 'Mis tests',
        'TopBar_Plans_And_Prices': 'Planes y precios',
        'TopBar_Change_Plan': 'Ampliar plan',
        'TopBar_Create_Test': 'Crear test',
        'TopBar_Submenu_My_Account': 'Mi cuenta',
        'TopBar_Submenu_Support_And_Help': 'Soporte y ayuda',
        'TopBar_Submenu_Close_Session': 'Cerrar sesión',
        'TopBar_Add_New_Modal_Title': 'Crear nuevo test',
        'TopBar_Add_New_Modal_Subtitle': '',
        'TopBar_Add_New_Modal_Cancel': 'Cancelar',
        'TopBar_Add_New_Modal_Confirm': 'Crear test',
        'TopBar_Add_New_Film_Modal_Title': 'Crear test',
        'TopBar_Add_New_Film_Modal_Subtitle': '',
        'TopBar_Add_New_Film_Modal_Cancel': 'Cancelar',
        'TopBar_Add_New_Film_Modal_Confirm': 'Crear test',
        'TopBar_Add_New_Input_Name': 'Nombre',
        'TopBar_Add_New_Input_Lang': 'Idioma de la audiencia',
        'TopBar_Add_New_Select_Type': '¿Cuál es el contenido del test?',
        'TopBar_Add_New_Select_Type_Generic': 'Anuncios y contenido de marketing',
        'TopBar_Add_New_Select_Type_Generic_Description': 'Testea cualquier tipo de contenido multimedia (imagen, audio y video) y obtiene métricas comparativas orientadas a ventas.<br/><br/>Ideal para <b>marketing y publicidad</b>.',
        'TopBar_Add_New_Select_Type_Film': 'Peliculas o series con necesidades de seguridad',
        'TopBar_Add_New_Select_Type_Film_Description': 'Testea videos largos y obtiene métricas detalladas de la emoción y la atención bajo los más <b>avanzados protocolos anti pirateria</b>.<br/><br/>Ideal para la <b>industria del entretenimiento</b>.',
        'TopBar_Download_Box_Header': 'Descargando archivo(s)',
        'TopBar_Download_Box_File': 'Archivo [var1]:',
        'TopBar_AutoSave_Title': 'Guardando datos',
        'TopBar_AutoSave_Text': 'Por favor, no cierre la ventana hasta que terminen de guardarse los datos.',
        /* DurationBar */
        'DurationBar_Title': 'Duración',
        'DurationBar_Legend_Included': 'Incluido en tu plan',
        'DurationBar_Legend_Non_Included': 'No incluido en tu plan',
        'DurationBar_Price_Response': '<b>[var1]</b> por respuesta',
        'DurationBar_Price_Min_Info': 'Debido a costes técnicos y de gestión, el precio mínimo por respuesta es de 1$',
         /* ContentHeader */
        'ContentHeader_Account': 'Resumen de la cuenta',
        'ContentHeader_Support_And_Help': 'Soporte y ayuda',
        'ContentHeader_Design_Test': 'Diseña tu test',
        'ContentHeader_Preview_And_Share': 'Genera una vista previa',
        'ContentHeader_Define_Audience': 'Define tu audiencia objetivo',
        'ContentHeader_Review_Test_And_Pay': 'Revisa tu test y págalo',
        'ContentHeader_Get_Responses': 'Recopila respuestas',
        'ContentHeader_Invite_Audience': 'Invitar audiencia',
        'ContentHeader_Get_Results': 'Obten los resultados',
        'ContentHeader_Saved_Button': "Guardado",
        'ContentHeader_Save_Changes_Button': "Guardar cambios",
        'ContentHeader_Version_Control_Tooltip': 'Mostrar control de versiones',
        'ContentHeader_Edit_Published_Test_Tooltip': 'No se puede editar un test publicado',
        'ContentHeader_Enable_Demo': 'Activar demostración',
        'ContentHeader_AutoSave_Tooltip_Saving': 'Guardando...',
        'ContentHeader_AutoSave_Tooltip_Saved': 'Guardado',
        /* Version control */
        'VersionControl_Title': 'Control de versiones',
        'VersionControl_Version_Name': 'Versión',
        'VersionControl_Versions_Empty': 'No hay un historial de versiones aún',
        /* Save_Published_Campaign */
        'Save_Published_Campaign_Modal_Title': '¡Un momento!',
        'Save_Published_Campaign_Modal_Subtitle': 'Este test ya está obteniendo resultados',
        'Save_Published_Campaign_Modal_Cancel': 'No, cancelar',
        'Save_Published_Campaign_Modal_Confirm': 'Sí, editar',
        'Save_Published_Campaign_Text': '<b>Advertencia:</b> estás editando uno o varios módulos que ya tienen respuestas.',
        'Save_Published_Campaign_Text_Error_Title': 'Editar un módulo',
        'Save_Published_Campaign_Text_Error': 'Al modificar el contenido de un módulo perderás todas las respuestas asociadas al mismo.',
        'Save_Published_Campaign_Text_Warning_Title': 'Reordenar contenido',
        'Save_Published_Campaign_Text_Warning': 'Si cambias el orden de los módulos o de los estímulos no perderás tus respuestas, pero puede alterar los resultados.',
        'Save_Published_Campaign_Text_Ok_Title': 'Editar la apariencia',
        'Save_Published_Campaign_Text_Ok': 'Puedes modificar los textos de las pantallas de portada, introducción y finalizar, así como la apariencia de tu test sin que afecte a tus respuestas.',
        'Save_Styles_Published_Campaign_Modal_Title': 'Cambiar la apariencia del test',
        'Save_Styles_Published_Campaign_Modal_Subtitle': 'Este test ya está obteniendo resultados',
        'Save_Styles_Published_Campaign_Modal_Cancel': 'Cancelar',
        'Save_Styles_Published_Campaign_Modal_Confirm': 'Guardar cambios',
        'Save_Styles_Published_Campaign_Text':'Estos cambios no afectarán a los resultados obtenidos.',
        /* Save_Published_Campaign */
        'Lite_User_Email_Modal_Title': '¡Un momento!',
        'Lite_User_Email_Modal_Subtitle': 'Para guardar tus resultados, necesitamos una dirección de email. (Te enviaremos ahí tu contraseña para poder ver los resultados)',
        'Lite_User_Email_Modal_Confirm': 'Guardar',
        'Lite_User_Email_Input_Email': 'Email',
        'Lite_User_Email_Input_Email_Placeholder': 'Introduce tu email',
        'Lite_User_Email_Input_Password': 'Contraseña',
        'Lite_User_Email_Input_Password_Placeholder': 'Introduce tu contraseña',
        'Lite_File_Browser_Title': 'Alyze Wizard | Selecciona el contenido',
        'Lite_Link_Browser_Title': 'Alyze Wizard | Comparte el test',
        'Lite_Metrics_Browser_Title': 'Alyze Wizard | Ver resultados',
        'Lite_User_Login_Modal_Title': 'Introduce tu contraseña',
        'Lite_User_Login_Modal_Subtitle': 'Introduce tu contraseña para ver los resultados de tu test',
        'Lite_User_Login_Modal_Confirm': 'Ver resultados',
        'Lite_Test_Edit_Modal_Title': 'Editar test',
        'Lite_Test_Edit_Modal_Subtitle': 'Pon un título identificativo a tu test',
        'Lite_Test_Edit_Modal_Confirm': 'Guardar',
        'Lite_Test_Edit_Modal_Cancel': 'Cancelar',
        'Lite_Test_Edit_Modal_Input_Name': 'Título',
        'Lite_Test_Edit_Modal_Input_Name_Placeholder': 'Introduce un título ...',
        /*
        'Lite_User_Max_Tests_Reached_Modal_Title': 'Amplia tu plan para continuar',
        'Lite_User_Max_Tests_Reached_Modal_Subtitle': 'Has superado el límite de test de este mes',
        'Lite_User_Max_Tests_Reached_Modal_Confirm': 'Ok',
        'Lite_User_Max_Tests_Reached_Modal_Text_1': 'Tu plan [var1] te permite publicar hasta [var2] tests cada mes.',
        'Lite_User_Max_Tests_Reached_Modal_Text_2': 'Para publicar este test debes esperar a que se renueve el periodo mensual o ampliar tu cuenta a un plan superior',
        */
        'Lite_User_Max_Tests_Reached_Modal_Title': 'Límite alcanzado',
        'Lite_User_Max_Tests_Reached_Modal_Subtitle': 'Has superado el límite de test de este mes',
        'Lite_User_Max_Tests_Reached_Modal_Confirm': 'Ok',
        'Lite_User_Max_Tests_Reached_Modal_Text_1': 'Has alcanzado el máximo de test mensuales.',
        'Lite_User_Max_Tests_Reached_Modal_Text_2': 'Para publicar este test debes esperar a que se renueve el periodo mensual',
        /* Wizard Login */
        'WizardLogin_Title': 'Accede a tu cuenta y consulta tus tests',
        'WizardLogin_Login_Button': 'Entrar en mi cuenta',
        'WizardLogin_Register_Button': 'Aún no tengo cuenta',
        /* Wizard Start */
        'WizardStart_Welcome': '¡Bienvenido a Alyze!',
        'WizardStart_Description': 'La primera herramienta de neurociencia que te permite analizar la respuesta emocional de tu contenido para ayudarte a lograr tus objetivos',
        'WizardStart_Account_Button': 'Tengo una cuenta',
        'WizardStart_Guest_Button': 'Crear mi primer test',
        /* Wizard File */
        'WizardFile_Creating_Test': 'Creando test',
        'WizardFile_Uploading_File': 'Subiendo archivo',
        'WizardFile_Upload_Content_Title': 'Sube el contenido que quieres testear',
        'WizardFile_Upload_Content_Description': 'Alyze evalúa la respuesta emocional de cualquier vídeo o imagen',
        'WizardFile_Upload_Other_Content_Title_Video': '¿Quieres comparar con otro vídeo?',
        'WizardFile_Upload_Other_Content_Title_Image': '¿Quieres comparar con otra imagen?',
        'WizardFile_Upload_Other_Content_Description': 'Puedes analizar solo tu archivo o subir otro para compararlos',
        'WizardFile_Test_Content': 'Contenido del test',
        'WizardFile_Optional_Upload_Other_File': 'Sube otro archivo para comparar (Opcional)',
        'WizardFile_Upload_Video_Button': 'Subir Vídeo',
        'WizardFile_Upload_Image_Button': 'Subir Imagen',
        'WizardFile_Finish_Button': 'Terminar',
        /* Wizard Link */
        'WizardLink_Publishing_Test': 'Publicando test',
        'WizardLink_Share_Title': '¡Listo para compartir!',
        'WizardLink_Share_Text': 'Comparte este enlace con quien quieras y obtén resultados en tiempo real',
        'WizardLink_Link_Title': 'Enlace de tu test',
        'WizardLink_Copy_Button': 'Copiar',
        'WizardLink_Metrics_Button': 'Ver resultados en tiempo real',
        /* NewTest */
        'NewTest_Title': 'Crear nuevo test',
        'NewTest_Button_Contact': 'Contáctanos',
        'NewTest_Button_Select_This': 'Seleccionar plan',
        'NewTest_Button_Selected': 'Seleccionado',
        'NewTest_Button_Starts_With': 'Desde',
        'NewTest_Note': 'NOTA: No es necesario el pago del test hasta el momento de su publicación. Siéntete libre de explorar y probar nuestros diferentes planes.'
    }
}