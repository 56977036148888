import React from 'react';
import { Switch, Route } from "react-router-dom";
import { connect } from 'react-redux';
import { Spinner } from 'react-activity';
import { isIOS, isChrome, isMobile, browserName, fullBrowserVersion, osName, deviceType, deviceDetect } from "react-device-detect";
import Dialog from 'rc-dialog';
import { PanelAccess, CampaignAccess } from '@sounditi/ft2-api';
import _ from 'lodash';

import Link from './components/Link';

import { clearRedirect, setLoadingAutoSave } from './reducers/navigation';
import { clearSession, checkTokenExpiration, setTemporalTestsData, setTestsData } from './reducers/user';

import { generateRandomNumber } from './utils/global';

import {
  URL_WIZARD,
  URL_WIZARD_LOGIN,
  URL_WIZARD_FILE,
  URL_WIZARD_LINK,
  URL_LOGIN,
  URL_SIGNUP,
  URL_SIGNUP_CONFIRM,
  URL_CAMPAIGNS,
  URL_CREATE_NEW,
  URL_ACCOUNT,
  URL_CAMPAIGN_CONFIGURATION,
  URL_CAMPAIGN_DESIGN,
  URL_CAMPAIGN_MODULES,
  URL_CAMPAIGN_PREVIEW,
  URL_CAMPAIGN_DEFINE_AUDIENCE,
  URL_CAMPAIGN_REVIEW_TEST_AND_PAY,
  URL_CAMPAIGN_METRICS,
  URL_CAMPAIGN_AUDIENCE,
  URL_CAMPAIGN_RECOPILATOR,
  URL_CAMPAIGN_BUY_AUDIENCE,
  URL_CAMPAIGN_INVITE_AUDIENCE,
  URL_SUPPORT_AND_HELP,
  URL_REMEMBER_PASSWORD,
  URL_PRICING,
  URL_CAMPAIGN_PAY
} from './config/urls';
import { preloadImages } from './config/preloadImages';

import Notifications from './components/Notifications';

import Login from './containers/Login';
import Signup from './containers/Signup';
import SignupConfirm from './containers/SignupConfirm';
import RememberPassword from './containers/RememberPassword';
import Campaigns from './containers/account/Campaigns';
import NewTest from './containers/account/NewTest';
import Account from './containers/account/Account';
import SupportAndHelp from './containers/account/SupportAndHelp';
import Configuration from './containers/campaign/Configuration';
import Design from './containers/campaign/Design';
import Modules from './containers/campaign/Modules';
import Preview from './containers/campaign/Preview';
import Metrics from './containers/campaign/Metrics';
import PayTest from './containers/campaign/PayTest';
import InviteAudience from './containers/InviteAudience';
import BuyAudience from './containers/campaign/BuyAudience';
import Recopilator from './containers/campaign/Recopilator';
// import ReviewTestAndPay from './containers/campaign/ReviewTestAndPay';
// import DefineAudience from './containers/campaign/DefineAudience';
import PlansAndPrices from './containers/account/PlansAndPrices';
import WizardStart from './containers/wizard/WizardStart';
import WizardFile from './containers/wizard/WizardFile';
import WizardLink from './containers/wizard/WizardLink';
import WizardLogin from './containers/wizard/WizardLogin';

import 'react-activity/dist/react-activity.css';
import './assets/fonts/fontello/css/sounditi.css';
import './styles/Normalize.css';
import './styles/App.scss';

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth.api,
  redirect: state.navigation.redirect,
  tokenData: state.user.tokenData,
  tokenExpiration: state.user.tokenExpiration,
  testsData: state.user.testsData,
  temporalTestsData: state.user.temporalTestsData,
  loadingAutoSave: state.navigation.loadingAutoSave,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  clearRedirect: () => dispatch(clearRedirect()),
  clearSession: val => dispatch(clearSession(val)),
  checkTokenExpiration: () => dispatch(checkTokenExpiration()),
  setTestsData: val => dispatch(setTestsData(val)),
  setTemporalTestsData: val => dispatch(setTemporalTestsData(val)),
  setLoadingAutoSave: val => dispatch(setLoadingAutoSave(val)),
})

class App extends React.Component {
  _intervalAutoSave = undefined;

  constructor(props) {
    super(props);
    this.state = {
      redirecting: false,
      loaded: false,
      incompatibleScreen: false,
      isChrome: false
    };
  }

  componentWillMount() {
    this.initializeApp();
  }

  componentDidMount() {
    preloadImages.forEach((preloadImage) => {
      const img = new Image();
      img.src = preloadImage;
    });

    // window.addEventListener('resize', () => this.checkScreen());

    // const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    this.setState({ isChrome: isChrome });

    const now = new Date().getTime();
    const sessionId = `${now}-${generateRandomNumber()}`;
    const browser = `${browserName}-${fullBrowserVersion}-${osName}`;
    const device = deviceType;

    localStorage.setItem('sessionID-info', sessionId);
    localStorage.setItem('browser-info', browser);
    localStorage.setItem('device-info', device);

    if (!this._intervalAutoSave) {
      this._intervalAutoSave = setInterval(() => {
        this.saveCampaign();
      }, 1000);
    }
  }

  componentWillUnmount() {
    // window.removeEventListener('resize', () => this.checkScreen()); 
    clearInterval(this._intervalAutoSave);
    this._intervalAutoSave = undefined;
  }

  componentWillUpdate(nextProps) {
    const { redirect } = nextProps;
    const { clearRedirect } = this.props;

    if (redirect) {
      const redirectValue = redirect;
      clearRedirect().then(()=>{
        this.redirectTo(redirectValue);
      })
    }
  }

  async sendEvent(action, screen) {
    const panelAccess = new PanelAccess();

    try {
      const createdAt = new Date().getTime();
      const sessionId = localStorage.getItem('sessionID-info');
      const browser = localStorage.getItem('browser-info');
      const device = localStorage.getItem('device-info');
      // type, device, browser, screen, sessionId, createdAt
      await panelAccess.postAction(action, screen, device, browser, sessionId, createdAt);
      // console.log({ postAction: { type: action, screen, device, browser, sessionId, createdAt } });

    } catch(error) {
      // console.log(error);
    };
  }

  saveCampaign() {
    const { temporalTestsData, testsData, setTestsData, setTemporalTestsData, setLoadingAutoSave, loadingAutoSave } = this.props;
    const changesToSave = !_.isEqual(temporalTestsData, testsData);

    if (changesToSave && !loadingAutoSave) {
      setLoadingAutoSave(true);

      temporalTestsData.map(testTemp => {
        testsData.map(async test => {
          if (test.campaignId === testTemp.campaignId) {
            if (!_.isEqual(test, testTemp)) {
              const newData = { ...testTemp.data, updatedAt: Date.now() };

              try {
                const campaignAccess = new CampaignAccess();
                const response = await campaignAccess.updateCampaign(testTemp.campaignId, newData);
                const refreshedTestsData = await campaignAccess.getCampaigns();

                const temporalTestRealTime = this.props.temporalTestsData.find(test => test.campaignId === testTemp.campaignId);
                const refreshedTemporalTestsData = refreshedTestsData.map(test => {
                  const testCopy = Object.assign({}, test);
                  if (test.campaignId === temporalTestRealTime.campaignId) {
                    testCopy.data = { ...temporalTestRealTime.data, updatedAt: response.data.updatedAt };
                  }
                  return testCopy;
                });

                setTemporalTestsData(refreshedTemporalTestsData);
                setTestsData(refreshedTestsData);
                setLoadingAutoSave(false);
              } catch (error) {
                setLoadingAutoSave(false);
              }
            }
          }
        })
      });
    }
  }

  redirectTo = (redirect, tracking) => {
    const { history, temporalTestsData, testsData, setTestsData, setTemporalTestsData, setLoadingAutoSave, loadingAutoSave } = this.props;
    const { redirecting } = this.state;
    const { route } = redirect;

    if (!redirecting) {
      this.saveCampaign();

      this.setState({ navigationTransition: '', redirecting: true }, () => {
        // this.sendEvent("control_navigate_to", redirect.route)
        document.getElementById('body').className='';
        history.push(route);
        setTimeout(() => this.setState({ redirecting: false, loaded: true }), 500);
      });
    }
  }

  initializeApp = async () => {
    const { clearSession, auth, temporalTestsData } = this.props;

    // console.log(temporalTestsData)
    // this.checkScreen();

    const tokenExpirated = await auth.checkTokenExpiration();

    if (tokenExpirated) {
      try {
        const renewalToken = await auth.getRenewalToken();
        const tokenRenew = await auth.renewToken(renewalToken);

        if (tokenRenew === false || (tokenRenew.message && tokenRenew.message === "Token empty")) {
          clearSession();
        } else {
          this.goToAuthorizedHome();
        }
      } catch (error) {
        clearSession();
      }
    };

    this.setState({ tokenExpirated }, () => this.checkStartPage());
  }

  checkStartPage = () => {
    const { tokenExpirated } = this.state;
    const { history } = this.props;
    const { pathname } = history.location;

    if (
      tokenExpirated &&
      pathname !== URL_LOGIN &&
      pathname !== URL_SIGNUP &&
      pathname !== URL_WIZARD &&
      pathname !== URL_WIZARD_LOGIN &&
      pathname !== URL_WIZARD_FILE &&
      pathname !== URL_WIZARD_LINK &&
      pathname !== URL_CAMPAIGN_METRICS &&
      pathname !== URL_CAMPAIGN_PAY &&
      pathname !== URL_SIGNUP_CONFIRM
    ) {
      this.goToUnauthorizedHome();
      return false;
    } else if (
      !tokenExpirated &&
      pathname !== URL_CAMPAIGN_MODULES &&
      pathname !== URL_CAMPAIGN_CONFIGURATION &&
      pathname !== URL_CAMPAIGN_DESIGN &&
      pathname !== URL_CAMPAIGN_PREVIEW &&
      pathname !== URL_CAMPAIGN_DEFINE_AUDIENCE &&
      pathname !== URL_CAMPAIGN_REVIEW_TEST_AND_PAY &&
      pathname !== URL_CAMPAIGNS &&
      pathname !== URL_CREATE_NEW &&
      pathname !== URL_CAMPAIGN_METRICS &&
      pathname !== URL_CAMPAIGN_PAY &&
      pathname !== URL_CAMPAIGN_RECOPILATOR &&
      pathname !== URL_CAMPAIGN_BUY_AUDIENCE &&
      pathname !== URL_CAMPAIGN_INVITE_AUDIENCE &&
      pathname !== URL_CAMPAIGN_AUDIENCE &&
      pathname !== URL_ACCOUNT &&
      pathname !== URL_SUPPORT_AND_HELP &&
      pathname !== URL_PRICING &&
      pathname !== URL_WIZARD &&
      pathname !== URL_WIZARD_LOGIN &&
      pathname !== URL_WIZARD_FILE &&
      pathname !== URL_WIZARD_LINK
    ) {
      this.goToAuthorizedHome();
      return false;
    }

    this.setState({ loaded: true });
  }

  goToAuthorizedHome() {
    this.redirectTo({ route: URL_CAMPAIGNS });
  }

  goToUnauthorizedHome() {
    this.redirectTo({ route: URL_LOGIN });
  }

  goToAuthorizedHomeWizard() {
    this.redirectTo({ route: URL_CAMPAIGNS });
  }

  goToUnauthorizedHomeWizard() {
    this.redirectTo({ route: URL_WIZARD });
  }

  render() {
    const { loaded, incompatibleScreen, isChrome } = this.state;
    const { redirectTo } = this.props;

    /*
    if (isMobile)
      return (
        <div className="app screen-login">
          <div className="login-box incompatible-screen-message">
            <img className="incompatible-screen" src="../assets/img/incompatible-screen.png" alt="Incompatible screen" />
            <div>
              <h2>Incompatible device</h2>
              <p>Alyze needs a screen with a<br/>minimun size of 1200x700px</p>
            </div>
          </div>
        </div>
      )
    */

    if (!loaded)
      return (
        <div className="loading-cover">
          <div className="cover"></div>
          <div>
            <Spinner speed={0.8} color="#00C7E7" size={20} />
          </div>
        </div>
      )

    if (incompatibleScreen)
      return (
        <div className="app screen-login">
          <div className="login-box incompatible-screen-message">
            <img className="incompatible-screen" src="../assets/img/incompatible-screen.png" alt="Incompatible screen" />
            <div>
              <h2>We need a computer!</h2>
              <p>To grant the best user experience the use of a computer is a must</p>
              <Link main className="full-width" onClick={() => {
                this.setState({ loaded: false });
                this.goToUnauthorizedHome();
                localStorage.clear();

                setTimeout(() => {
                  // this.checkScreen();
                  this.setState({ loaded: true });
                }, 2000);
              }}>
                Go home
              </Link>
            </div>
          </div>
        </div>
      )

    return (
      <Route
        render={({ location }) => (
          <>
            <div style={{display: 'none'}}>
              {JSON.stringify(redirectTo)}
            </div>
            <Notifications />
            {(!isChrome && !isMobile) && (
              <Dialog
                title="Browser warning!"
                visible={true}
              >
                <p>We're <b>optimized for <a target="_blank" href="https://www.google.com/intl/es_es/chrome/">Google Chrome</a></b></p>
                <p>We can't grant a good experience in other browsers</p>
                <div className="dialog-buttons">
                  <Link inline className="button-red" onClick={() => {
                    this.setState({ isChrome: true });
                  }}>
                    Continue with my browser
                  </Link>
                  <Link main onClick={() => {
                    window.open('https://www.google.com/intl/es_es/chrome/', '_blank');
                  }}>
                    Download Google Chrome
                  </Link>
                </div>
              </Dialog>
            )}
            <Switch location={location}>
              <Route path={URL_WIZARD_LOGIN}>
                <WizardLogin location={location} />
              </Route>
              <Route path={URL_WIZARD_FILE}>
                <WizardFile location={location} />
              </Route>
              <Route path={URL_WIZARD_LINK}>
                <WizardLink location={location} />
              </Route>
              <Route path={URL_WIZARD}>
                <WizardStart location={location} />
              </Route>
              <Route path={URL_PRICING}>
                <PlansAndPrices location={location} />
              </Route>
              <Route path={URL_SUPPORT_AND_HELP}>
                <SupportAndHelp location={location} />
              </Route>
              <Route path={URL_CAMPAIGN_METRICS}>
                <Metrics location={location} />
              </Route>
              <Route path={URL_CAMPAIGN_PAY}>
                <PayTest location={location} />
              </Route>  
              <Route path={URL_CAMPAIGN_BUY_AUDIENCE}>
                <BuyAudience location={location} />
              </Route>
              <Route path={URL_CAMPAIGN_INVITE_AUDIENCE}>
                <InviteAudience location={location} />
              </Route>
              <Route path={URL_CAMPAIGN_RECOPILATOR}>
                <Recopilator location={location} />
              </Route>
              {/* <Route path={URL_CAMPAIGN_REVIEW_TEST_AND_PAY}>
                <ReviewTestAndPay location={location} />
              </Route>
              <Route path={URL_CAMPAIGN_DEFINE_AUDIENCE}>
                <DefineAudience location={location} />
              </Route> */}
              <Route path={URL_CAMPAIGN_MODULES}>
                <Modules location={location} />
              </Route>
              <Route path={URL_CAMPAIGN_CONFIGURATION}>
                <Configuration location={location} />
              </Route>
              <Route path={URL_CAMPAIGN_DESIGN}>
                <Design location={location} />
              </Route>
              <Route path={URL_CAMPAIGN_PREVIEW}>
                <Preview location={location} />
              </Route>
              <Route path={URL_CAMPAIGNS}>
                <Campaigns location={location} />
              </Route>
              <Route path={URL_CREATE_NEW}>
                <NewTest location={location} />
              </Route>
              <Route path={URL_ACCOUNT}>
                <Account location={location} />
              </Route>
              <Route path={URL_LOGIN}>
                <Login location={location} />
              </Route>
              <Route path={URL_SIGNUP_CONFIRM}>
                <SignupConfirm location={location} />
              </Route>
              <Route path={URL_SIGNUP}>
                <Signup location={location} />
              </Route>
              <Route path={URL_REMEMBER_PASSWORD}>
                <RememberPassword location={location} />
              </Route>
              <Route render={() => <div>Not Found</div>} />
            </Switch>
          </>
        )}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
