import React from 'react';

import '../styles/components/Input.scss';

class TextArea extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      value: '',
      invalid: false,
      rows: 99
    }
    this.handleChange = this.handleChange.bind(this);
  }

  replaceNewline(input) {
    return input.replace(/(?:\r\n|\r|\n)/g, '<br />');
  }

  setInvalid() {
    // console.log("setInvalid");
    this.setState({ invalid: true });
    setTimeout(() => {
      this.setState({ invalid: false });
    }, 3000)
  }

  componentWillMount() {
    const { value } = this.props;

    if (value)
      this.setState({ value });
  }

  componentDidMount() {
    this.calculateRows();
  }

  handleChange(e) {
    const { onChange } = this.props;
    const returnObj = {};

    returnObj[e.target.name] = e.target.value;

  	this.calculateRows();

    this.setState({ value: e.target.value, invalid: false });

    if (onChange)
      onChange(returnObj);
  }

  calculateRows() {
    const textareaLineHeight = 14;

    this.refs.textArea.rows = 1;
    let rows = Math.floor(this.refs.textArea.scrollHeight / textareaLineHeight);

    if (rows < 1)
      rows = 1;

    this.refs.textArea.rows = rows;

    this.setState({ rows });
  }

  handleFocus() {
    const { onFocus } = this.props;

    if (onFocus)
      onFocus();
  }

  refresh() {
    const { value } = this.props;
    this.setState({ value }, () => this.calculateRows());
  }

  focus() {
    this.refs.textArea.focus();
  }

  render() {
    const { placeholder, name, label, last, disabled } = this.props;
    const { value, invalid, rows } = this.state;
    let inputClass = "field";

    if (invalid)
      inputClass = `${inputClass} invalid`;

    if (last)
      inputClass = `${inputClass} last`;

    if (disabled)
      inputClass = `${inputClass} disabled`;

    return (
      <div className={inputClass}>
        {label && (
          <label htmlFor={name}>{label}</label>
        )}
        <div className="input-wrapper">
          <div className="textarea-wrapper">
            <textarea
              ref="textArea"
              rows={rows}
              value={value}
              onChange={this.handleChange}
              name={name}
              placeholder={placeholder}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default TextArea