import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { Spinner } from 'react-activity';
import { UserAccess, CampaignAccess, FileAccess, SounditiAuth } from '@sounditi/ft2-api';
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next';

import { showNotification } from '../../reducers/notifications';
import { setRedirect } from '../../reducers/navigation';
import { setTestsData, setUserData, setSession } from '../../reducers/user';

import T from '../../components/Translate';
import Link from '../../components/Link';
import ModalDialog from '../../components/ModalDialog';
import Input from '../../components/Input';
import PolicyCheck from '../../components/PolicyCheck';
import { Circle } from 'rc-progress';

import { URL_CAMPAIGN_METRICS_ID, URL_WIZARD, URL_CAMPAIGNS } from '../../config/urls';
import { URL_ENVIRONMENT, URL_CONTROL_ENVIRONMENT } from '../../config/global';

const uuidv4 = require("uuid/v4");

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth.api,
  campaignsData: state.user.testsData,
  userData: state.user.userData,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setUserData: val => dispatch(setUserData(val)),
  setTestsData: val => dispatch(setTestsData(val)),
  setSession: val => dispatch(setSession(val)),
  showNotification: val => dispatch(showNotification(val)),
  setRedirect: val => dispatch(setRedirect(val)),
  dispatch,
})

class WizardFile extends React.Component {
  _mount = true;
  _loadingInterval = undefined;
  _animationInterval = undefined;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadedPercent: 1,
      loadingTextAnimation: "",
      showProgress: false,
      link: undefined,
      userEmail: "",
      showEmailModal: false,
      modalLoading: false,
      policy: false,
      showPasswordInput: false,
      userPassword: "",
      ShowTestsReachedModal: false
    };
  }

  componentWillMount() {
    this.initLink();
  }

  componentDidMount() {
    document.getElementById('body').className='wizard'
  }

  initLoadedPercent() {
    const duration = 10000;

    this.setState({ loadedPercent: 1, showProgress: true });

    this._loadingInterval = setInterval(() => {
      let loadedPercent = this.state.loadedPercent + 1;

      if (loadedPercent > 100) {
        loadedPercent = 0;
      }

      this.setState({ loadedPercent });
    }, duration / 100);

    this._animationInterval = setInterval(() => {
      let loadingTextAnimation = this.state.loadingTextAnimation + ".";

      if (loadingTextAnimation.length > 3)
        loadingTextAnimation = ""

      this.setState({ loadingTextAnimation });
    }, 500);
  }

  stopLoadedPercent() {
    this.setState({ loadedPercent: 100, showProgress: false });
    clearInterval(this._loadingInterval);
    clearInterval(this._animationInterval);
    this._loadingInterval = undefined;
  }

  onInputChange(val) {
    this.setState(val);
  }

  loggout() {
    localStorage.clear();
    const auth = new SounditiAuth();
    auth.logOut();
    window.Froged('logout');
    this.goWizard();
  }

  async initLink() {
    const { campaignsData } = this.props;
    const campaignData = campaignsData[0];

    try {
      this.initLoadedPercent();
      const campaignAccess = new CampaignAccess();
      const links = await campaignAccess.getLinks(campaignData.campaignId);

      if (links.length < 1) {
        const newLink = {
          active: true,
          alias: "Link autogenerado",
          campaignId: campaignData.campaignId,
          isLink: true
        };

        const link = await campaignAccess.createLink(newLink);
        this.setState({ link, loading: false });
        this.stopLoadedPercent();
      }
    } catch (error) {
      showNotification("genericError");
      this.stopLoadedPercent();
      this.loggout();
    }
  }

  async refreshTestsData() {
    const { setTestsData } = this.props;
    const campaignAccess = new CampaignAccess();
    const refreshedTestsData = await campaignAccess.getCampaigns();
    setTestsData(refreshedTestsData);
  }

  async getEmail() {
    const { userData, setUserData, campaignsData, auth, dispatch, showNotification } = this.props;
    const { userEmail, userPassword, policy, showPasswordInput, link } = this.state;

    this.setState({ modalLoading: true });

    if (showPasswordInput) {
      if (userEmail !== "" && userPassword !== "" && policy === true) {
        try {
          let campaignData = campaignsData[campaignsData.length - 1];

          const wizardLoginRequest = {
            email: userEmail,
            password: userPassword,
            campaignId: campaignData.campaignId,
            linkUrl: link.linkUrl
          }

          const userAccess = new UserAccess();
          const hydratedLogin = await userAccess.wizardLogin(wizardLoginRequest);
          // const hydratedLogin = await userAccess.hydratedLogin(userEmail, userPassword, auth);
          const numberOfTestsReached = hydratedLogin.numberOfTestsReached;
          hydratedLogin.user = await userAccess.getUserData();
          dispatch(setSession(hydratedLogin)).then(() => {
            const userID = hydratedLogin.user.id || undefined;
            const name = hydratedLogin.user.data.name || hydratedLogin.user.name || "";
            const email = hydratedLogin.user.data.email || hydratedLogin.user.email || "";

            if (userID) {
              const campaignLink = link ? `${URL_ENVIRONMENT}/${link.linkUrl}` : "";
              const metricsLink = link ? `${URL_CONTROL_ENVIRONMENT}${URL_CAMPAIGN_METRICS_ID.replace('[var1]', link.linkUrl)}&email=${email}` : "";

              window.Froged('set', {
                userId: userID,
                name,
                email,
                hasFinishedWizard: true,
                hasUsedWizard: true,
              });
              window.Froged("track", "on_login");
              window.Froged("track", "bcknd_test_lite_old", {
                campaignLink,
                metricsLink
              });
            }

            if (!numberOfTestsReached) {
              this.goMetrics();
            } else {
              this.setState({ showEmailModal: false });
              this.setState({ ShowTestsReachedModal: true });
            }
          })
        } catch (error) {
          this.setState({ modalLoading: false });

          if (error.message === "Error: Password dont match") {
            showNotification("invalidLoginData");
            this.refs.userEmail.setInvalid();
            this.refs.userPassword.setInvalid();
          } else {
            showNotification("genericError");
          }
        }
      } else {
        this.setState({ modalLoading: false });

        if (!userEmail) {
          this.refs.userEmail.setInvalid();
        }

        if (!userPassword) {
          this.refs.userPassword.setInvalid();
        }

        if (!policy) {
          this.refs.policy.setInvalid();
        }
      }
      return false;
    }

    if (userEmail !== "" && policy === true) {
      try {
        const userAccess = new UserAccess();
        const response = await userAccess.updateUserEmailAndResetPassword(userData.id, userEmail, link.linkUrl);
        setUserData(response.updatedUser);
        await this.refreshTestsData();
        const userID = response.updatedUser.id || undefined;
        const name = response.updatedUser.data.name || response.updatedUser.name || "";
        const email = response.updatedUser.data.email || response.updatedUser.email || "";

        if (userID) {
          window.Froged('set', {
            userId: userID,
            name,
            email,
            hasFinishedWizard: true,
            hasUsedWizard: true,
            signupOnWizard: true
          });
        }
        window.Froged("track", "on_sign_up");
        this.goMetrics();
      } catch (error) {
        if (error.message === "Error: Email in use") {
          this.setState({ showPasswordInput: true, modalLoading: false });
          showNotification("userExist");
        } else {
          showNotification("genericError");
        }
      }
    } else {
      this.setState({ modalLoading: false });

      if (!userEmail) {
        this.refs.userEmail.setInvalid();
      }

      if (!policy) {
        this.refs.policy.setInvalid();
      }
    }
  }

  goMetrics() {
    const { setRedirect } = this.props;
    const { link } = this.state;

    setRedirect({ route: URL_CAMPAIGN_METRICS_ID.replace('[var1]', link.linkUrl) });
  }

  goCampaigns() {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_CAMPAIGNS });
  }

  copyToClipboard(text) {
    const { showNotification } = this.props;

    let tempInput = document.createElement("input");
    tempInput.value = text;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);

    window.Froged("track", "onCopyLink");

    showNotification("copyClipboard");
  }

  goWizard() {
    const { setRedirect } = this.props;
    setRedirect({ route: URL_WIZARD });
  }

  render() {
    const { loading, link, userEmail, userPassword, showEmailModal, showPasswordInput, modalLoading, policy, showProgress, loadingTextAnimation, loadedPercent, ShowTestsReachedModal } = this.state;
    const { t , userData} = this.props;

    const url = link ? `${URL_ENVIRONMENT}/${link.linkUrl}` : "";


    return (
      <div className="app lite">
        <Helmet>
          <title>{t('Lite_Link_Browser_Title')}</title>
        </Helmet>
        {showProgress && (
          <div className="loading-cover">
            <div className="cover"></div>
            <div>
              <div className="loading-text">
                <T text="WizardLink_Publishing_Test" />
                <p className="animated">{loadingTextAnimation}</p>
              </div>
              <Circle percent={loadedPercent} strokeWidth="4" trailWidth="4" strokeColor="#00C7E7" />
            </div>
          </div>
        )}
        {loading && (
          <div className="loading-cover">
            <div className="cover"></div>
            <div>
              <Spinner speed={0.8} color="#00C7E7" size={20} />
            </div>
          </div>
        )}

        <ModalDialog
          name="Lite_User_Email"
          showModal={showEmailModal}
          loading={modalLoading}
          closeModal={() => this.setState({ showEmailModal: false })}
          onConfirm={() => { this.getEmail() }}
          fullCTA={true}
        >
          <Input
            readOnly={modalLoading}
            placeholder={t('Lite_User_Email_Input_Email_Placeholder')}
            name="userEmail"
            label={t('Lite_User_Email_Input_Email')}
            value={userEmail}
            ref="userEmail"
            onChange={val => this.onInputChange(val)}
          />
          <Input
            className={showPasswordInput ? "" : "hidden"}
            readOnly={modalLoading}
            type="password"
            placeholder={t('Lite_User_Email_Input_Password_Placeholder')}
            name="userPassword"
            label={t('Lite_User_Email_Input_Password')}
            value={userPassword}
            ref="userPassword"
            onChange={val => this.onInputChange(val)}
          />
          <PolicyCheck
            name="policy"
            ref="policy"
            onChange={val => this.onInputChange(val)}
            checked={policy}
          />
        </ModalDialog>

        <ModalDialog
          name="Lite_User_Max_Tests_Reached"
          showModal={ShowTestsReachedModal}
          closeModal={() => {
            this.setState({ ShowTestsReachedModal: false })
            this.goCampaigns()
          }}
          onConfirm={() => this.goCampaigns()}
          fullCTA={true}
        >
          <p>
            <T
              text="Lite_User_Max_Tests_Reached_Modal_Text_1" 
              var1={userData.subscription.name}
              var2={userData.subscription.total_test}
            />
          </p>
          <p><T text="Lite_User_Max_Tests_Reached_Modal_Text_2" /></p>
        </ModalDialog>

        <div className="lite-topbar">
          <img className="logo" src="../assets/img/main-logo-c.png" alt="Company logo" />
        </div>
        <div className="screen-description">
          <h2><T text="WizardLink_Share_Title" /></h2>
          <p><T text="WizardLink_Share_Text" /></p>
        </div>
        <div className="fluid">
          <div className="fluid-wrapper">
            <h3><T text="WizardLink_Link_Title" /></h3>
            <div className="file margin-bottom" onClick={() => this.copyToClipboard(url)}>
              <i className="icon icon-link-1"></i>
              <div className="url-wrapper">{url}</div>
              <Link main>
                <T text="WizardLink_Copy_Button" />
              </Link>
            </div>
          </div>
        </div>
        <div className="fixed-bottom">
          <Link main className="full-width" onClick={() => this.setState({ showEmailModal: true })}>
            <T text="WizardLink_Metrics_Button" />
          </Link>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(WizardFile);
