import { CampaignAccess } from '@sounditi/ft2-api';
import { UserAccess } from '@sounditi/ft2-api';

export async function postActionGTMRegister(action, email = "default@mail.com") {
  window.dataLayer.push({
    'event': action,
    attributes: {
      userEmail: email,
    }
  });
}

export async function postActionGTMCampaing(action, campaignId, userData) {

  const campaignAccess = new CampaignAccess();
  let campaignData = null;

  if (campaignId && campaignId !== "") {
    campaignData = await campaignAccess.getCampaignbyID(campaignId);
  }

  let numOfVideoModules = 0;
  let numOfAudioModules = 0;
  let numOfImageModules = 0;
  let numOfQAModules = 0;
  let numOfVideos = 0;
  let numOfAudios = 0;
  let numOfImages = 0;
  let numOfQuestions = 0;
  let numOfModules = 0;

  let userId = "userId";
  let userEmail = "userEmail"
  let enrolledTesters = 0;
  let campaignModules = null;
  let audience = 0;
  let status = "status";
  let subscriptionTag = "subscriptionTag"

  if (campaignData) {

    campaignModules = campaignData.data.modules.campaignModules;

    campaignModules.forEach(module => {
      if (module.name === "Video") {
        numOfVideos += module.videos.length;
        numOfVideoModules += 1;
      } else if (module.name === "Audio") {
        numOfAudios += module.audios.length;
        numOfAudioModules += 1;
      } else if (module.name === "Image") {
        numOfImages += module.images.length;
        numOfImageModules += 1;
      } else if (module.name === "Q&A") {
        numOfQuestions += module.questions.length;
        numOfQAModules += 1;
      }
    });

    userId = campaignData.userId
    enrolledTesters = campaignData.enrolledTesters
    audience = campaignData.data.audience
    status = campaignData.status
    numOfModules = campaignModules.length
  }

  if (
    userData &&
    userData.email &&
    userData.subscription &&
    userData.subscription.tag
  ) {
    userEmail = userData.email
    subscriptionTag = userData.subscription.tag
  }

  window.dataLayer.push({
    'event': action,
    attributes: {
      campaignId: campaignId,
      userId: userId,
      enrolledTesters: enrolledTesters,
      audience: audience,
      status: status,
      numOfVideos: numOfVideos,
      numOfAudios: numOfAudios,
      numOfImages: numOfImages,
      numOfQuestions: numOfQuestions,
      numOfModules: numOfModules,
      numOfVideoModules: numOfVideoModules,
      numOfAudioModules: numOfAudioModules,
      numOfImageModules: numOfImageModules,
      numOfQAModules: numOfQAModules,
      userEmail: userEmail,
      subscriptionTag: subscriptionTag
    }
  });
}

export async function postActionGTMUserData(action, userData, planName = null) {

  let subscriptionTag = "subscriptionTag"
  let subscriptionTagTo = "subscriptionTagTo"
  let userId = "userId"
  let userEmail = "userEmail"

  let attributes = null

  if(userData){
    userId = userData.id
    userEmail = userData.email
    subscriptionTag = userData.subscription.tag

    attributes = {
      userId: userId,
      userEmail: userEmail,
      subscriptionTag: subscriptionTag,
    }
  }

  if (planName) {
    subscriptionTagTo = planName.toUpperCase()

    attributes = {
      userId: userId,
      userEmail: userEmail,
      subscriptionTag: subscriptionTag,
      subscriptionTagTo: subscriptionTagTo
    }
  }

  window.dataLayer.push({
    'event': action,
    attributes: attributes
  });
}
