const initState = {
  redirect: undefined,
  fullWidth: false,
  loadingModule: false,
  openVersionControl: false,
  loadingMetrics: false,
  downloadPercent: {},
  downloadToggleBox: false,
  downloadShowMsg: false,
  loadingAutoSave: false,
  hasContentHeader: false,
};

export const setFullWidth = payload => ({
  type: 'SET_FULLWIDTH',
  payload,
});
export const setRedirect = payload => ({
  type: 'SET_REDIRECT',
  payload,
});
export const clearRedirect = () => Promise.resolve({
  type: 'CLEAR_REDIRECT',
});
export const setDisableSaveButton = payload => ({
  type: 'SET_DISABLE_SAVE_BUTTON',
  payload,
});
export const showVersionControl = payload => ({
  type: 'SHOW_VERSIONS_CONTROL',
  payload,
});
export const setDisableToggleDemo = payload => ({
  type: 'SET_DISABLE_TOGGLE_DEMO',
  payload,
});
export const setDownloadPercent = payload => ({
  type: 'SET_DOWNLOAD_PERCENT',
  payload,
});
export const setDownloadToggleBox = payload => ({
  type: 'SET_DOWNLOAD_TOGGLE_BOX',
  payload,
});
export const setDownloadShowMsg = payload => ({
  type: 'SET_DOWNLOAD_SHOW_MSG',
  payload,
});
export const clearDownload = () => ({
  type: 'CLEAR_DOWNLOAD',
});
export const setLoadingAutoSave = payload => ({
  type: 'SET_LOADING_AUTO_SAVING',
  payload,
});
export const setHasContentHeader = payload => ({
  type: 'SET_HAS_CONTENTHEADER',
  payload,
});

const navigation = (state = initState, action) => {
  switch (action.type) {
    case 'SET_FULLWIDTH':
      const fullWidth = action.payload;
      return { ...state, fullWidth }
    case 'SET_REDIRECT':
      const redirect = action.payload;
      return { ...state, redirect }
    case 'CLEAR_REDIRECT':
      return { ...state, redirect: undefined }
    case 'SET_DISABLE_SAVE_BUTTON':
      const loadingModule = action.payload;
      return { ...state, loadingModule }
    case 'SHOW_VERSIONS_CONTROL':
      const openVersionControl = action.payload;
      return { ...state, openVersionControl }
    case 'SET_DISABLE_TOGGLE_DEMO':
      const loadingMetrics = action.payload;
      return { ...state, loadingMetrics }
    case 'SET_DOWNLOAD_PERCENT':
      const downloadPercent = action.payload;
      return { ...state, downloadPercent }
    case 'SET_DOWNLOAD_TOGGLE_BOX':
      const downloadToggleBox = action.payload;
      return { ...state, downloadToggleBox }
    case 'SET_DOWNLOAD_SHOW_MSG':
      const downloadShowMsg = action.payload;
      return { ...state, downloadShowMsg }
    case 'CLEAR_DOWNLOAD':
      return { ...state, downloadPercent: {}, downloadShowMsg: false }
    case 'SET_LOADING_AUTO_SAVING':
      const loadingAutoSave = action.payload;
      return { ...state, loadingAutoSave }
    case 'SET_HAS_CONTENTHEADER':
      const hasContentHeader = action.payload;
      return { ...state, hasContentHeader }
    default:
      return state
  }
}

export default navigation