// COMPONENTS
import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { Spinner } from 'react-activity';
import { UserAccess, SounditiAuth } from '@sounditi/ft2-api';
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next';
import TopBar from '../../components/TopBar';
import ContentHeader from '../../components/ContentHeader';
import T from '../../components/Translate';
import ModalDialog from '../../components/ModalDialog';
import Link from '../../components/Link';
import Select from '../../components/Select';
import Input from '../../components/Input';
import SubscriptionWidget from '../../components/SubscriptionWidget';

// ACTIONS
import { showNotification } from '../../reducers/notifications';
import { setRedirect } from '../../reducers/navigation';
import { setUserData } from '../../reducers/user';
import { removeURLParameter, getURLParameter } from '../../utils/global';

const mapStateToProps = (state, ownProps) => ({
  userData: state.user.userData,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setUserData: val => dispatch(setUserData(val)),
  setRedirect: val => dispatch(setRedirect(val)),
  showNotification: val => dispatch(showNotification(val)),
})

class Account extends React.Component {
  _mount = true;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      userID: "",
      name: "",
      email: "",
      company: "",
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
      dataValidate: true,
      userData: {},
      changesToApply: false,
      lang: "en",
      showModalSubscriptions: false,
      showModalName: false,
      showModalUsername: false,
      showModalPassword: false,
      showModalEmail: false,
      showModalLang: false,
      modifyAccountLoading: false,
      showModalDeleteAccount: false,
      deleteAccountLoading: false,
      confirmDelete: ''
    };
  }

  componentWillMount() {
    this.loadData();
  }

  componentDidMount() {
    if (this.refs.topbar)
      this.refs.topbar.sendEvent("control_enter_screen_account", "Account")
  }

  componentWillUnmount() {
    this._mount = false;
  }

  async loadData() {
    if (this._mount) {
      const { showNotification, userData } = this.props;
      const planChanged = getURLParameter("planChanged");

      if (planChanged) {
        if (planChanged === "true") {
          showNotification("planChangedCorretly");
        } else {
          showNotification("genericError");
        }
        removeURLParameter("planChanged")
      }

      this.refreshUserData();

      if (userData){
        const userID = userData?.id || "";
        const name = userData?.data?.name || userData?.name || "";
        const email = userData?.email || "";
        const lang = localStorage.getItem('lang') || "en";
        const username = userData?.data?.username ? userData?.data?.username : userData?.email;

        this.setState({
          userData,
          userID,
          name,
          username,
          email,
          lang,
          loading: false
        }, () => {
          if (this.refs.name)
            this.refs.name.refresh();

          if (this.refs.email)
            this.refs.email.refresh();

          if (this.refs.lang)
            this.refs.lang.refresh();

          if (this.refs.username)
            this.refs.username.refresh();
        });
      }
    }
  }

  async refreshUserData() {
    const { setUserData } = this.props;
    const userAccess = new UserAccess();
    const refreshedUserData = await userAccess.getUserData();
    setUserData(refreshedUserData);
    // console.log(refreshedUserData);
  }

  changeLanguage(lang) {
    const { i18n } = this.props;
    localStorage.setItem('lang', lang);
    i18n.changeLanguage(`${lang}`);
  }

  async modifyAccount() {
    const { showNotification, userData } = this.props;
    const { name, email, username, lang } = this.state;
    const { data } = userData;

    const newData = { ...data, name, email, username };

    this.setState({ modifyAccountLoading: true });

    if (lang !== localStorage.getItem('lang'))
      this.changeLanguage(lang);

    try {
      const userAccess = new UserAccess();
      await userAccess.updateUserData(newData);

      showNotification("changesApplied");
    } catch(error) {
      showNotification("genericError");
    }

    this.setState({
      modifyAccountLoading: false,
      showModalUsername: false,
      showModalLang: false,
      showModalEmail: false,
      showModalName: false,
      showModalPassword: false
    });

    this.refreshUserData();
  }

  async cancelModify() {
    const { userData } = this.props;
    const name = userData.data.name || userData.name || "";
    const email = userData.email || "";
    const lang = localStorage.getItem('lang') || "en";
    const username = userData.data.username ? userData.data.username : userData.email;

    this.setState({
      name,
      username,
      email,
      lang
    }, () => {
      if (this.refs.name)
        this.refs.name.refresh();

      if (this.refs.email)
        this.refs.email.refresh();

      if (this.refs.lang)
        this.refs.lang.refresh();

      if (this.refs.username)
        this.refs.username.refresh();
    });
  }

  onInputChange(val) {
    this.setState(val);
  }

  async changePassword() {
    const { showNotification } = this.props;
    const {
      oldPassword,
      newPassword,
      newPasswordConfirm
    } = this.state;

    if (oldPassword !== "" && newPassword !== "" && newPasswordConfirm !== "" && newPasswordConfirm === newPassword) {
      this.setState({ modifyAccountLoading: true });

      try {
        const userAccess = new UserAccess();
        const response = await userAccess.passwordUpdate(oldPassword, newPassword);

        showNotification("passwordChanged");
      } catch(error) {
        showNotification("genericError");
      }

      this.setState({
        oldPassword: "",
        newPassword: "",
        newPasswordConfirm: "",
        modifyAccountLoading: false,
        showModalPassword: false
      }, () => {
        if (this.refs.oldPassword)
          this.refs.oldPassword.refresh();

        if (this.refs.newPassword)
          this.refs.newPassword.refresh();

        if (this.refs.newPasswordConfirm)
          this.refs.newPasswordConfirm.refresh();
      });
    } else {
      if (!oldPassword && this.refs.oldPassword)
        this.refs.oldPassword.setInvalid();

      if (!newPassword && this.refs.newPassword)
        this.refs.newPassword.setInvalid();

      if (!newPasswordConfirm && this.refs.newPasswordConfirm)
        this.refs.newPasswordConfirm.setInvalid();

      if (
        newPasswordConfirm !== newPassword
        && this.refs.newPassword
        && this.refs.newPasswordConfirm
      ) {
        this.refs.newPassword.setInvalid();
        this.refs.newPasswordConfirm.setInvalid();
      }
    }

    this.refreshUserData();
  }

  loggout() {
    localStorage.clear();
    const auth = new SounditiAuth();
    auth.logOut();
    window.Froged('logout');
    window.location.reload();
  }

  async deleteAccount() {
    const { confirmDelete } = this.state;
    const { userData, showNotification } = this.props;

    if (confirmDelete === 'DELETE') {
      this.setState({ deleteAccountLoading: true });
      try {
        const userAccess = new UserAccess();
        await userAccess.deleteUser(userData.id, userData.email);
        this.loggout();
        showNotification('accountDeletedSuccessfully');
      } catch (error) {
        showNotification('genericError');
      }
      this.setState({ deleteAccountLoading: false });
    } else {
      showNotification('invalidData');
      if (this.refs && this.refs.typeDelete) {
        this.refs.typeDelete.setInvalid();
      }
    }
  }

  render() {
    const {
      loading,
      name,
      email,
      username,
      lang,
      oldPassword,
      newPassword,
      newPasswordConfirm,
      showModalSubscriptions,
      showModalName,
      showModalUsername,
      showModalPassword,
      showModalEmail,
      showModalLang,
      modifyAccountLoading,
      showModalDeleteAccount,
      deleteAccountLoading,
      confirmDelete
    } = this.state;
    const { location, t, userData } = this.props;

    const langRendered = lang === "en" ? t(`Account_General_Lang_EN`) : t(`Account_General_Lang_ES`);

    // console.log(userData);

    return (
      <div className="app screen-campaigns">
        <Helmet>
          <title>{t('Account_Browser_Title')}</title>
        </Helmet>
        {/*loading && (
          <div className="loading-cover">
            <div className="cover"></div>
            <div>
              <Spinner speed={0.8} color="#00C7E7" size={20} />
            </div>
          </div>
        )*/}

        <ModalDialog
          name="Account_General_Name"
          showModal={showModalName}
          loading={modifyAccountLoading}
          closeModal={e => { this.setState({ showModalName: false }) }}
          onConfirm={() => this.modifyAccount()}
          onCancel={() => this.cancelModify()}
        >
          <Input
            readOnly={modifyAccountLoading}
            last
            placeholder=""
            name="name"
            label={t('Account_General_Name')}
            value={name}
            ref="name"
            onChange={val => this.onInputChange(val)}
          />
        </ModalDialog>
        <ModalDialog
          name="Account_General_Lang"
          showModal={showModalLang}
          loading={modifyAccountLoading}
          closeModal={e => { this.setState({ showModalLang: false }) }}
          onConfirm={() => this.modifyAccount()}
          onCancel={() => this.cancelModify()}
        >
          <Select
            readOnly={modifyAccountLoading}
            name="lang"
            label={t("Account_General_Lang")}
            value={lang}
            ref="lang"
            onChange={val => this.onInputChange(val)}
            options={[
              { "value": "es", "label": t("Account_General_Lang_ES") },
              { "value": "en", "label": t("Account_General_Lang_EN") },
            ]}
          />
        </ModalDialog>
        <ModalDialog
          name="Account_General_Username"
          showModal={showModalUsername}
          loading={modifyAccountLoading}
          closeModal={e => { this.setState({ showModalUsername: false }) }}
          onConfirm={() => this.modifyAccount()}
          onCancel={() => this.cancelModify()}
        >
          <Input
            readOnly={modifyAccountLoading}
            last
            placeholder=""
            name="username"
            label={t('Account_General_Username')}
            value={username}
            ref="username"
            onChange={val => this.onInputChange(val)}
          />
        </ModalDialog>
        <ModalDialog
          name="Account_Change_Password"
          showModal={showModalPassword}
          loading={modifyAccountLoading}
          closeModal={e => { this.setState({ showModalPassword: false }) }}
          onConfirm={() => this.changePassword()}
          onCancel={() => this.cancelModify()}
        >
          <Input
            readOnly={modifyAccountLoading}
            placeholder=""
            name="oldPassword"
            label={t('Account_Change_Password_Current_Password')}
            ref="oldPassword"
            value={oldPassword}
            type="password"
            onChange={val => this.onInputChange(val)}
          />

          <Input
            readOnly={modifyAccountLoading}
            placeholder=""
            name="newPassword"
            label={t('Account_Change_Password_New_Password')}
            ref="newPassword"
            value={newPassword}
            type="password"
            onChange={val => this.onInputChange(val)}
          />

          <Input
            placeholder=""
            name="newPasswordConfirm"
            label={t('Account_Change_Password_Retype_New_Password')}
            ref="newPasswordConfirm"
            value={newPasswordConfirm}
            type="password"
            onChange={val => this.onInputChange(val)}
          />
        </ModalDialog>
        <ModalDialog
          name="Account_DeleteAccount"
          showModal={showModalDeleteAccount}
          loading={deleteAccountLoading}
          invertButtons={true}
          closeModal={() => { this.setState({ showModalDeleteAccount: false, confirmDelete: '' }) }}
          onCancel={() => this.setState({ showModalDeleteAccount: false, confirmDelete: '' }) }
          onConfirm={() => this.deleteAccount()}
        >
          <div className="delete-account-modal">
            <p className="warning-text"><T text="Account_DeleteAccount_Modal_Content_Title" /></p>
            <p className="icon-text"><i className="icon icon-error"></i><T text="Account_DeleteAccount_Modal_Content_List_1" /></p>
            <p className="icon-text"><i className="icon icon-error"></i><T text="Account_DeleteAccount_Modal_Content_List_2" /></p>
            <p className="icon-text"><i className="icon icon-error"></i><T text="Account_DeleteAccount_Modal_Content_List_3" /></p>
            <p className="icon-text"><i className="icon icon-error"></i><T text="Account_DeleteAccount_Modal_Content_List_4" /></p>
            <p className="last-text"><T text="Account_DeleteAccount_Modal_Content_Label" /></p>
            <Input
              placeholder=""
              name="confirmDelete"
              ref="typeDelete"
              value={confirmDelete}
              type="text"
              onChange={val => this.onInputChange(val)}
            />
          </div>
        </ModalDialog>

        <div className="main-wrapper">
          <TopBar ref="topbar" location={location} />
          <div className="screen-content">
            <ContentHeader location={location} title={<T text= "Account_Title" />} account />
            <div className="content-wrapper small">
              {loading && (
                <div className="loading-cover">
                  <div>
                    <Spinner speed={0.8} color="#00C7E7" size={20} />
                  </div>
                </div>
              )}

              {!loading && (
                <>
                  {/*
                  <h2><T text="Account_Title_Plan_Details" /></h2>
                  <SubscriptionWidget userData={userData} />
                  */}

                  <h2><T text="Account_Title_Session_Details" /></h2>
                  <div className="content-widget">
                    <div className="row">
                      <div className="account-item">
                        <div className="title"><T text="Account_Input_Label_Name" /></div>
                        <div className="value">
                          {name}
                          <Link inline className="link" onClick={() => this.setState({ showModalName: true })}>
                            <T text="Account_Button_Edit" />
                          </Link>
                        </div>
                      </div>
                      <div className="account-item">
                        <div className="title"><T text="Account_Input_Label_Username" /></div>
                        <div className="value">
                          {username}
                          <Link inline className="link" onClick={() => this.setState({ showModalUsername: true })}>
                            <T text="Account_Button_Edit" />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="account-item">
                        <div className="title"><T text="Account_Input_Label_Password" /></div>
                        <div className="value">
                          **********
                          <Link inline className="link" onClick={() => this.setState({ showModalPassword: true })}>
                            <T text="Account_Button_Edit" />
                          </Link>
                        </div>
                      </div>
                      <div className="account-item">
                        <div className="title"><T text="Account_Input_Label_Email" /></div>
                        <div className="value">
                          {email}
                          {/*
                          <Link inline className="link">
                            <T text="Account_Button_Edit" />
                          </Link>
                          */}
                        </div>
                        {/*
                        <div className="subtitle no-verificated">
                          NO VERIFICADO
                          <Link inline className="link">
                            Reenviar correo electrónico
                          </Link>
                        </div>
                        */}
                      </div>
                    </div>
                  </div>

                  <h2><T text="Account_Title_General_Options" /></h2>
                  <div className="content-widget">
                    <div className="row">
                      <div className="account-item">
                        <div className="title"><T text="Account_Input_Label_Language" /></div>
                        <div className="value">
                          {langRendered}
                          <Link inline className="link" onClick={() => this.setState({ showModalLang: true })}>
                            <T text="Account_Button_Edit" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <p className="delete-account">
                    <Link inline onClick={() => this.setState({showModalDeleteAccount: true}) }>
                      <T text="Account_DeleteAccount" />
                    </Link>
                  </p>

                  {/*
                  <SubscriptionWidget
                    userData={userData}
                    onClickChangePlan={() => {
                      postActionGTMCampaing("GTM_change_plan_popup_from_account", "", userData)
                      this.setState({ showModalSubscriptions: true })
                    }}
                  />
                  */}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(Account);
