import React from 'react';

import { SketchPicker } from 'react-color';

import '../styles/components/Input.scss';

class InputColor extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      value: '',
      invalid: false,
      showPicker: false
    }
    this.handleChange = this.handleChange.bind(this);
  }

  setInvalid() {
    this.setState({ invalid: true });
    setTimeout(() => {
      this.setState({ invalid: false });
    }, 3000)
  }

  componentWillMount() {
    const { value } = this.props;
    if (value)
      this.setState({value});
  }

  handleChange(e) {
    const { onChange, name } = this.props;
    const returnObj = {};

    if (e.target) {
      returnObj[name] = e.target.value;
      this.setState({ value: e.target.value, invalid: false });
    } else if (e.hex) {
      returnObj[name] = e.hex;
      this.setState({ value: e.hex, invalid: false });
    }

    if (onChange)
      onChange(returnObj);
  }

  refresh() {
    const { value } = this.props;
    if (value)
      this.setState({value});
  }

  togglePicker() {
    const { onShowPicker } = this.props;
    const { showPicker } = this.state;

    if (onShowPicker)
      onShowPicker();

    this.setState({ showPicker: !showPicker });
  }

  hidePicker() {
    const { onhidePicker } = this.props;

    this.setState({ showPicker: false });

    if (onhidePicker)
      onhidePicker();
  }

  render() {
    const { name, label, last, disabled, readOnly } = this.props;
    const { value, invalid, showPicker } = this.state;
    let inputClass = "field picker";

    if (invalid)
      inputClass = `${inputClass} invalid`;

    if (last)
      inputClass = `${inputClass} last`;

    if (disabled)
      inputClass = `${inputClass} disabled`;

    if (showPicker)
      inputClass = `${inputClass} show-picker`;

    if (readOnly)
      inputClass = `${inputClass} read-only`;

    return (
      <div className={inputClass}>
        <label htmlFor={name}>{label}</label>
        <div className="input-wrapper">
          <input
            value={value}
            onChange={this.handleChange}
            name={name}
            type="text"
            readOnly={readOnly}
          />
          <div className="input-icon" onClick={() => { this.togglePicker() }}>
            <div className="color-picker" style={{ backgroundColor: value }}></div>
          </div>
        </div>
        {showPicker && (
          <>
            <SketchPicker
              color={ value }
              onChange={ this.handleChange }
            />
            <div className="color-picker-popover" onClick={() => this.togglePicker()}></div>
          </>
        )}
      </div>
    );
  }
}

export default InputColor