// COMPONENTS
import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { Spinner } from 'react-activity';
import { CampaignAccess, SounditiAuth } from '@sounditi/ft2-api';
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next';
import T from '../../components/Translate';
import Link from '../../components/Link';
import MobileTopBar from '../../components/MobileTopBar';
import CampaignsTableV2 from '../../components/CampaignsTableV2';
import TopBar from '../../components/TopBar';
import SubscriptionPlans from '../../components/SubscriptionPlans';
import ModalDialog from '../../components/ModalDialog';

// ACTIONS
import { showNotification } from '../../reducers/notifications';
import { setRedirect } from '../../reducers/navigation';
import { setTestsData, setTemporalTestsData } from '../../reducers/user';
import { removeURLParameter } from '../../utils/global';

const mapStateToProps = (state, ownProps) => ({
  userData: state.user.userData,
  campaignsData: state.user.testsData,
  loadingAutoSave: state.navigation.loadingAutoSave,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setTestsData: val => dispatch(setTestsData(val)),
  setRedirect: val => dispatch(setRedirect(val)),
  showNotification: val => dispatch(showNotification(val)),
  setTemporalTestsData: val => dispatch(setTemporalTestsData(val)),
})

class Campaigns extends React.Component {
  _mount = true;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loadingModal: false,
      showModalSubscriptions: false,
      showRemoveModal: false,
      selectedCampaign: "",
      campaignsData: []
    };
  }

  componentWillMount() {
    this.loadData();
    removeURLParameter("id");
  }

  componentDidMount() {
    if (this.refs.topbar)
      this.refs.topbar.sendEvent("control_enter_screen_campaigns", "Campaigns")
  }

  componentWillUnmount() {
    this._mount = false;
  }

  async loadData() {
    const { userData } = this.props;

    if (this._mount) {
      this.setState({ loading: true });

      if (userData && userData.email && userData.email.includes("@alyze.lead")) {
        this.loggout();
      }

      // window.Froged("track", "bcknd_sign_up");

      /*
      if (userData.subscription.name === "Free") {
        const savedDate = new Date(localStorage.getItem('plans-modal-show-date')) || undefined;
        const nowDate = new Date();

        if (!savedDate || nowDate > savedDate) {
          this.setState({ showModalSubscriptions: true })
          nowDate.setDate(nowDate.getDate() + 1);
          localStorage.setItem('plans-modal-show-date', nowDate);
        }
      }
      */

      const campaignsData = await this.refreshTestsData();

      this.setState({ campaignsData, loading: false });
    }
  }

  loggout() {
    localStorage.clear();
    const auth = new SounditiAuth();
    auth.logOut();
    window.Froged('logout');
    window.location.reload();
  }

  async refreshTestsData() {
    const { setTestsData, setTemporalTestsData, loadingAutoSave } = this.props;
    const campaignAccess = new CampaignAccess();
    const refreshedTestsData = await campaignAccess.getCampaigns();

    if (!loadingAutoSave) {
      setTemporalTestsData(refreshedTestsData);
    }
    setTestsData(refreshedTestsData);

    return refreshedTestsData;
  }

  async cloneCampaign(campaign) {
    try {
      this.setState({ loading: true });

      const campaignAccess = new CampaignAccess();
      await campaignAccess.cloneCampaign(campaign.campaignId);

      setTimeout(() => this.loadData(), 5000);
    } catch (error) {
      this.setState({ loading: false });
      showNotification("genericError");
    }
  }

  showRemoveModal(selectedCampaign) {
    this.setState({ selectedCampaign, showRemoveModal: true });
  }

  async removeCampaign(campaign) {
    const { showNotification } = this.props;
    const { campaignId } = campaign;

    try {
      this.setState({ loadingModal: true });

      const campaignAccess = new CampaignAccess();
      await campaignAccess.deleteCampaign(campaignId);

      this.setState({ loadingModal: false, showRemoveModal: false });
      await this.loadData();
    } catch (error) {
      this.setState({ loadingModal: false });
      showNotification("genericError");
    }
  }

  render() {
    const {
      loading,
      loadingModal,
      campaignsData,
      showRemoveModal,
      selectedCampaign,
      showModalSubscriptions
    } = this.state;
    const { location, t, userData } = this.props;

    // console.log(campaignsData);

    return (
      <div className="app screen-campaigns">
        <Helmet>
          <title>{t("Campaigns_Browser_Title")}</title>
        </Helmet>
        {/* <SubscriptionPlans
          userData={userData}
          showModalSubscriptions={showModalSubscriptions}
          onClickClose={() => { this.setState({ showModalSubscriptions: false }) }}
        /> */}
        {selectedCampaign && selectedCampaign.data && selectedCampaign.data.name && (
          <ModalDialog
            name="Campaigns_Remove"
            showModal={showRemoveModal}
            loading={loadingModal}
            closeModal={e => { this.setState({ showRemoveModal: false }) }}
            onConfirm={() => { this.removeCampaign(selectedCampaign); }}
          >
            <p><T text="Campaigns_Remove_Campaign_Text_1" var1={selectedCampaign.data.name} /></p>
          </ModalDialog>
        )}
        <div className="main-wrapper">
          <TopBar ref="topbar" location={location} />
          <MobileTopBar title="Mis tests" />
          <div className="fluid screen-content add-padding-top">
            <div className="content-wrapper small full-height">
              {loading && (
                <div className="loading-cover">
                  <div>
                    <Spinner speed={0.8} color="#00C7E7" size={20} />
                  </div>
                </div>
              )}
              {!loading && campaignsData.length > 0 && (
                <>
                  <div className="row only-desktop">
                    <div className="left-content one-line">
                      <h1><T text="Campaigns_Title" /></h1>
                    </div>
                    <div className="right-content">
                      <Link main onClick={() => { this.refs.topbar.addNewTest() }}>
                        <i className="icon icon-plus-1"></i>
                        <T text="Campaigns_New_Campaign_Button" />
                      </Link>
                    </div>
                  </div>
                  <div className="tests-wrapper">
                    <CampaignsTableV2
                      campaignsData={campaignsData}
                      onClickSend={campaign => {
                        /*if (campaign.data.type && campaign.data.type === 'film') {
                          this.refs.topbar.goInviteAudience(campaign);
                        } else {*/
                          this.refs.topbar.goRecopilator(campaign);
                        //}
                      }}
                      onClickEdit={campaign => this.refs.topbar.goCampaignModules(campaign)}
                      onClickClone={campaign => this.cloneCampaign(campaign)}
                      onClickRemove={campaign => this.showRemoveModal(campaign)}
                      onClickMetrics={campaign =>  this.refs.topbar.goMetrics(campaign)}
                    />
                  </div>
                </>
              )}
              {!loading && campaignsData.length < 1 && (
                <div className="no-campaigns-msg">
                  <img src="../assets/img/no-campaigns-img.png" alt="No campaigns" />
                  <h2><T text="Campaigns_No_Campaigns_Found" /></h2>
                  <p><T text="Campaigns_Start_First_Campaig" /></p>
                  <Link className="only-desktop" main onClick={() => { this.refs.topbar.addNewTest() }}>
                    <T text="Campaigns_New_Campaign_Button" />
                  </Link>
                </div>
              )}
            </div>
          </div>
          {/* <div className="fixed-bottom float-bottom only-mobile">
            <Link main className="full-width" onClick={() => { this.refs.topbar.goWizardFile() }}>
              Añadir nuevo test
            </Link>
          </div> */}
        </div>
      </div>
    );
  }
}


export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(Campaigns);
