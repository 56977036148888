import { ANSWER_TIME } from '../config/global';

export function getURLParameter(parameterName) {
  let result = null;
  let tmp = [];

  window.location.search
    .substr(1)
    .split("&")
    .forEach(function (item) {
      tmp = item.split("=");
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });

  return result;
}

export function removeURLParameter(parameter) {
  var url=document.location.href;
  var urlparts= url.split('?');

  if (urlparts.length>=2) {
    var urlBase=urlparts.shift();
    var queryString=urlparts.join("?");

    var prefix = encodeURIComponent(parameter)+'=';
    var pars = queryString.split(/[&;]/g);
    for (var i= pars.length; i-->0;)
      if (pars[i].lastIndexOf(prefix, 0)!==-1)
        pars.splice(i, 1);
    url = urlBase+'?'+pars.join('&');
    if (pars.length < 1)
      url = urlBase;
    window.history.pushState('',document.title,url); // added this line to push the new url directly to url bar .
  }
  return url;
}

export function checkImageURL(url) {
  return(url.match(/\.(jpeg|jpg|gif|png)$/) != null);
}

export function shuffle(array) {
  return array.sort(function() {
      return .5 - Math.random();
  });
}

export function generateRandomNumber() {
  return Math.round(Math.random() * 1000000000);
}

export async function getImageAspect(src) {
  let orientation,
  img = new Image();
  img.src = src;

  return new Promise(resolve => {
    img.onload = () => {
      if (img.naturalWidth > img.naturalHeight) {
        orientation = 'landscape';
      } else {
        orientation = 'portrait';
      }

      resolve(orientation);
    };
  });
}

export async function getVideoAspect(src) {
  let orientation,
  video = document.createElement("video");
  video.src = src;

  return new Promise(resolve => {
    video.onloadeddata = () => {
      if (video.videoWidth > video.videoHeight) {
        orientation = 'landscape';
      } else {
        orientation = 'portrait';
      }

      resolve(orientation);
    };
  });
}

export async function getVideoDimensions(src) {
  let video = document.createElement('video');
  video.src = src;

	return new Promise(resolve => {
		video.onloadeddata = () => {
			let height = this.videoHeight;
			let width = this.videoWidth;

			resolve({
				height : height,
				width : width
			});
		};
	});
}


export function isLocalhost() {
  const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
  );

  return isLocalhost;
}

export function fancyTimeFormat(duration) {
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
}

export function getCampaignDuration(campaignData, formated = true) {
  let totalDuration = 0;

  campaignData.data.modules.campaignModules.forEach(module => {
    // console.log(module);
    if (module.videos) {
      module.videos.forEach(video => {
        totalDuration = totalDuration + video.duration;
      });

      // if (module.tech === "choose")
      //   totalDuration = totalDuration + ANSWER_TIME;
    } else if (module.audios) {
      module.audios.forEach(audio => {
        totalDuration = totalDuration + audio.duration;
      });

      // if (module.tech === "choose")
      //   totalDuration = totalDuration + ANSWER_TIME;
    } else if (module.images) {
      if (module.tech === "choose") {
        const moduleDuration = ANSWER_TIME * module.images.length;
        totalDuration = totalDuration + moduleDuration;
      } else {
        const moduleDuration = module.duration * module.images.length;
        totalDuration = totalDuration + moduleDuration;
      }
    } else if (module.questions) {
      const moduleDuration = ANSWER_TIME * module.questions.length;
      totalDuration = totalDuration + moduleDuration;
    }
  });

  if (formated)
    return fancyTimeFormat(totalDuration);

  return totalDuration
}

export function copyLinkToClipboard(campaign) {
  const { showNotification } = this.props;

  const link = `${campaign.previewUrl}?id=${campaign.campaignId}`;
  let hiddenInput = document.getElementById("clipboard-input");

  if (!hiddenInput) {
    const input = document.createElement('input');
    input.id = "clipboard-input";
    input.style.position = 'fixed';
    input.style.top = 0;
    input.style.left = 0;
    input.style.width = '1px';
    input.style.height = '1px';
    input.style.padding = 0;
    input.style.border = 'none';
    input.style.outline = 'none';
    input.style.boxShadow = 'none';
    input.style.background = 'transparent';
    document.querySelector("body").appendChild(input);
    hiddenInput = document.getElementById("clipboard-input");
  }

  hiddenInput.value = link;

  hiddenInput.select();
  document.execCommand("copy");
  window.getSelection().removeAllRanges();

  showNotification("copyClipboard");
}

export function generateModuleID() {
  return `${Date.now()}-${generateRandomNumber()}`;
}

export function getFormatTime(seconds) {
  let h = parseInt(seconds/3600);
  let m = parseInt(seconds%3600/60);
  let s = parseInt(seconds%60);

  let formatTime = '';
  if (h > 0) formatTime += `${h}h`;
  if (m > 0) formatTime += ` ${m}m`;
  if (s > 0) formatTime += ` ${s}s`;

  return formatTime === '' ? '0s' : formatTime;
}
