import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import ReactTooltip from "react-tooltip";
import { ReactSortable } from "react-sortablejs";
import { withTranslation } from 'react-i18next';

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({})

class AddModulePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newModulesList: [
        {
          type: "audio",
          name: "Audio",
          icon: "icon-sound",
          img: "card-audio.png",
        },
        {
          type: "video",
          name: "Video",
          icon: "icon-video-1",
          img: "card-video.png",
        },
        {
          type: "film",
          name: "Film",
          icon: "icon-video",
          img: "card-video.png",
        },
        {
          type: "image",
          name: "Image",
          icon: "icon-picture-1",
          img: "card-image.png",
        }, {
          type: "qa",
          name: "Q&A",
          icon: "icon-chat",
          img: "card-qa.png",
        }
      ]
    };
  }

  componentDidMount() {

  }

  addThis(card) {
    const { onClickAdd } = this.props;

    if (onClickAdd)
      onClickAdd(card);
  }

  render() {
    const { newModulesList } = this.state;
    const { onChoose, onUnchoose, t, testType, existModuleFilm, features, isCampaignPaid, maxModulesFeatures } = this.props;
    const { maxModulesReached, maxFilmModulesReached, maxAudioModulesReached, maxVideoModulesReached, maxImageModulesReached, maxQaModulesReached } = maxModulesFeatures;

    let newModulesListFiltered = [];
    newModulesListFiltered = newModulesList;
    /*
    if (testType === 'film') {
      newModulesListFiltered = newModulesList.filter(modules => {
        return modules.type === 'qa' || modules.type === 'film';
      });
    } else {
      newModulesListFiltered = newModulesList.filter(modules => {
        return modules.type !== 'film';
      });
    }
    */
    /*
    newModulesList.forEach(modules => {
      if ((features.filmModule && modules.type === 'film')
        || (features.audioModule && modules.type === 'audio')
        || (features.imageModule && modules.type === 'image')
        || (features.videoModule && modules.type === 'video')
        || (features.qaModule && modules.type === 'qa')) {
          newModulesListFiltered.push(modules);
        }
    });
    */
    const renderNewModulesList = newModulesListFiltered.map((card, key) => {
      let cardName = t("Panels_Audio_Capital_Letter");

      if (card.type === "video"){
        cardName = t("Panels_Video_Capital_Letter");
      } else if (card.type === "film"){
        cardName = t("Panels_Large_Video_Capital_Letter");
      } else if (card.type === "image"){
        cardName = t("Panels_Image_Capital_Letter");
      } else if (card.type === "qa"){
        cardName = t("Panels_QA_Capital_Letter");
      }

      let disabledClass = '';
      let notIncludedTooltip = '';
      if ((card.type === 'film' && (!features?.filmModule || (features?.filmModule && maxFilmModulesReached)))
        || (card.type === 'audio' && (!features?.audioModule || (features?.audioModule && maxAudioModulesReached)))
        || (card.type === 'image' && (!features?.imageModule || (features?.imageModule && maxImageModulesReached)))
        || (card.type === 'video' && (!features?.videoModule || (features?.videoModule && maxVideoModulesReached)))
        || (card.type === 'qa' && (!features?.qaModule || (features?.qaModule && maxQaModulesReached))) ) {
        disabledClass = 'disabled';
        notIncludedTooltip = t("Panels_Max_Number_Of_Type_Modules_Reached").replace("[var1]", card.type);
      }

      // if (
      //   card.type === 'film' && (existModuleFilm || !features.filmModule)
      //   || (card.type === 'audio' && !features.audioModule)
      //   || (card.type === 'image' && !features.imageModule)
      //   || (card.type === 'video' && !features.videoModule)
      //   || (card.type === 'qa' && !features.qaModule)
      // ) {

      if ((card.type === 'film' && !features?.filmModule)
        || (card.type === 'audio' && !features?.audioModule)
        || (card.type === 'image' && !features?.imageModule)
        || (card.type === 'video' && !features?.videoModule)
        || (card.type === 'qa' && !features?.qaModule)) {
          notIncludedTooltip = t("Panels_Module_Not_Included");
      }

      if (isCampaignPaid || maxModulesReached) {
        disabledClass = 'disabled';
        notIncludedTooltip = t("Panels_Max_Number_Of_Modules_Reached").replace("[var1]", card.type);
      }

      return (
        <li key={key} className={`screen-card ${card.type} ${disabledClass}`}
          data-tip={notIncludedTooltip} data-place="right" data-background-color="#ccc" data-tip-disable={isCampaignPaid || disabledClass === ''}
          onClick={() => disabledClass === '' && this.addThis(card)}
        >
          <div className={`module ${card.type}`}>
            <i className={`icon ${card.icon} module-type-icon`}></i>
            <h4>{cardName}</h4>
            <i className={`icon icon-plus-1 ${isCampaignPaid ? "hidden" : ""}`}></i>
          </div>
          <div className="card-header">{card.name}</div>
          <div className="card-body"><img src={`/assets/img/modules/${card.img}`} alt={`card ${card.type} icon`} /></div>
        </li>
      )
    })

    return (
      <div className="panel panel-add-screen">
        <div className="screen-modules">
          <ReactTooltip place="right" effect="solid" backgroundColor="#f08c1e" />
          <ReactSortable
            id="sortable-b"
            tag="ul"
            list={newModulesListFiltered}
            setList={newState => {}}
            group={{
              name: "shared",
              pull: "clone",
              put: false
            }}
            filter= ".disabled"
            sort={false}
            onChoose={e => onChoose()}
            onUnchoose={e => onUnchoose()}
            onChange={e => this.checkCampaignModulesEmpty()}
          >
            {renderNewModulesList}
          </ReactSortable>
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(AddModulePanel);
