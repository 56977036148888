import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import default_en from "./config/strings/default_en";
import default_es from "./config/strings/default_es";

// console.log("------ Not found in Spanish ------")
// Object.keys(default_en?.translations).map(key => {
//   if (Object.keys(default_es?.translations).indexOf(key) < 0) {
//     console.log(key)
//   }
// })

// console.log("------ Not found in English ------")
// Object.keys(default_es?.translations).map(key => {
//   if (Object.keys(default_en?.translations).indexOf(key) < 0) {
//     console.log(key)
//   }
// })

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: default_en,
      es: default_es,
    },
    lng: "en-US",
    fallbackLng: "en",
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
